import React from 'react';
// import moment from 'moment-timezone';

import './PreviewNotification.css';

export default function PreviewNotification (props) {
  return (
    <div className='PreviewNotification'>
      <h3>Preview</h3>

      <div className='android'>
        <p style={{ marginBottom: '10px' }}>Android</p>

        <div className='android-example'>
          <div className='row top'>
            <img src='/img/logo.png' className='Logo' alt='PackageRoute Logo' />
            <p style={{ fontSize: '12px', marginLeft: '10px' }}>PackageRoute</p>
          </div>
          <div className='row'>
            <p style={{ fontSize: '12px', fontWeight: '400' }}>{props.title}</p>
          </div>
          <div className='row'>
            <p style={{ fontSize: '12px' }}>{props.body}</p>
          </div>
        </div>
      </div>

      <div className='ios'>
        <p style={{ marginBottom: '10px' }}>iOS</p>

        <div className='ios-example'>
          <div className='row top'>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20px', width: '20px', backgroundColor: 'white' }}>
              <img src='/img/logo.png' className='Logo' alt='PackageRoute Logo' />
            </div>
            <p style={{ fontSize: '12px', color: '#4C4C4C', marginLeft: '10px' }}>PackageRoute</p>
          </div>

          <div className='row bottom'>
            <div className='row'>
              <p style={{ padding: '3px 0', fontSize: '14px', fontWeight: '400' }}>{props.title}</p>
            </div>

            <div className='row'>
              <p style={{ padding: '3px 0', fontSize: '12px' }}>{props.body}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
