import React from "react";
import { OperationTransfer } from "@models";
import SatisfiedCondition from './SatisfiedCondition';
import StripeChanges from './StripeChanges';
import "./TransferOwnershipModal.css";

type Props = {
  isPreview: boolean;
  transfer: OperationTransfer | null | undefined;
};

export default function TransferOverview({
  isPreview,
  transfer,
}: Props) {
  if (!transfer) return null;

  const userExists = !!transfer.newOwner.uid;
  const userHasCustomerAcct = !!transfer.newOwner.uid;
  return (
    <div className="TransferOverview" style={{ padding: 20 }}>
      <SatisfiedCondition
        label={userExists ? "User Exists" : "User record does not exist"}
        required
        satisfied={userExists}
        error={"User must exist to initiate transfer"}
      />

      {userExists && (
        <React.Fragment>
          <SatisfiedCondition
            label="User has Stripe Customer account"
            satisfied={userHasCustomerAcct}
            error={"Stripe account will be added"}
          />
          <SatisfiedCondition
            label={
              transfer.subscription.isUnderAnnualContract
                ? "Subscription is annual contract"
                : "Subscription is monthly no-contract"
            }
            satisfied
          />

          <p className="TransferChangesTitle">Transfer will:</p>
          <ul className="TransferChanges">
            {userHasCustomerAcct && <li>Create new Stripe customer account</li>}
            <li>Create new subscription</li>
            {transfer.subscription.isUnderAnnualContract ? (
              <li>
                Create new subscription with same billing period as existing
                subscription
              </li>
            ) : (
              <li>
                Create new subscription with billing period starting today
              </li>
            )}
            <li>
              Give new subscription 2 day trial to give new owner time to enter
              default payment method
            </li>
            <li>Give new owner billing permissions</li>
            <li>Make new owner king of operation</li>
            <li>Cancel the existing subscription</li>
            <li>
              Refund existing subscription remaining time in billing period
            </li>
          </ul>
        </React.Fragment>
      )}

      {!isPreview && (
        <StripeChanges transfer={transfer} />
      )}
    </div>
  );
}
