import { SubscriptionPlanType } from '@models';

/**
 * Annual contract discount price
 * 
 * Dollars as cents
 */
const FIFTEEN_DOLLARS = 1500;
/**
 * Current default price for per route
 * 
 * Dollars as cents
 */
const TWENTY_DOLLARS = 2000;
/**
 * Thirty dollars will be the new MONTH to MONTH plan in v2 of the subscription changes
 * 
 * Dollars as cents
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const THIRTY_DOLLARS = 3000;

/**
 * Gives per-route pricing (in cents) for each plan type
 */
export function getPerRoutePricingByPlanType (planType: SubscriptionPlanType): number {
  switch (planType) {
    case SubscriptionPlanType.MONTH_TO_MONTH_V1: {
      return TWENTY_DOLLARS;
    }

    case SubscriptionPlanType.ANNUAL_CONTRACT_MINIMUM_ROUTES_REQUIRED_V1:
    case SubscriptionPlanType.ANNUAL_CONTRACT_PREPAID_V1:
    case SubscriptionPlanType.ANNUAL_NOT_PREPAID_ROUTE_MINIMUM_V1: {
      return FIFTEEN_DOLLARS;
    }

    default: {
      throw new Error(`Unrecognized subscription plan type: ${planType}`);
    }
  }
}