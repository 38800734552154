import React from 'react';
import './IspEntry.css';
// import { Link } from 'react-router-dom';

import './OwnerEntry.css';
import Icon from '../Icon/Icon';

export default function OwnerEntry (props) {
  const { owner, profile = {}, matches, onClick } = props;
  const { activeRoutes, totalRoutes } = (() => {
    let totalRoutes = 0;
    let activeRoutes = 0;

    for (const key in (profile.WorkAreas || {})) {
      totalRoutes++;

      if (profile.WorkAreas[key].paid) activeRoutes++;
    }

    return { totalRoutes, activeRoutes };
  })();

  return (
    <div
      onClick={onClick}
      className={`OwnerEntry`}>
      <div className='iconWrap'>
        <Icon name='account' size={30} color='#006DAB' />
      </div>

      <div className='entryBody'>
        <div classNme='identity'>
          <h2 className='nameTitle'>
            <span className={matches && (matches.firstName || matches.lastName) ? 'match' : ''}>
              {owner.firstName} {owner.lastName}
            </span>
          </h2>

          <div className='detailsWrap'>
            <div className='detail'>
              <Icon name='truck' size={15} color='#666' />
              <h4>
                <span className={matches && matches.entityName ? 'match' : ''}>
                  { owner.entityName || profile.CSPName }
                </span>
              </h4>
            </div>

            <div className='detail'>
              <Icon name='phone' size={15} color='#666' />
              <h4>
                <span className={matches && matches.phone ? 'match' : ''}>
                  { owner.phone }
                </span>
              </h4>
            </div>

            <div className='detail'>
              <Icon name='email' size={15} color='#666' />
              <h4>
                <span className={matches && matches.email ? 'match' : ''}>
                  { owner.email }
                </span>
              </h4>
            </div>
          </div>
        </div>

        <div className='actions'>
          {props.children}
        </div>

        <div className='isp-status'>
          {
            Object.keys(profile).length
              ? <div className='fbEntry'>
                <h2 className='routes'>
                  <span>
                    { activeRoutes }
                  </span>
                  {` / `}{ totalRoutes }
                </h2>

                {
                  activeRoutes > 0
                    ? <h4 className='paidStatus'>PAID</h4>
                    : <h4 className='unpaidStatus'>UNPAID</h4>
                }
              </div>
              : null
          }

          {
            owner.firebaseId
              ? <div className='crmEntry'>
                {
                  owner.firebaseId
                    ? <h4 className='prevAssoc'>Associated</h4>
                    : null
                }
              </div>
              : null
          }
        </div>
      </div>
    </div>
  );
}
