import {RootState} from '@reducers';
import { createSelector } from "reselect";
import { ConsoleStation } from '@reducers/stations/consoleStations';

const selectConsoleStations = (state: RootState) => state.stations.consoleStations.data;
const selectConsoleStationsList = createSelector([selectConsoleStations], (stations) => {
  return Object.values(stations);
});

const selectConsoleStationsBySqlId = createSelector([selectConsoleStations], (stations) => {
  const stationsBySqlId: Record<number, ConsoleStation> = {};

  for (const stationId in stations) {
    const station = stations[stationId];

    stationsBySqlId[station.id] = station;
  }

  return stationsBySqlId;
})

export {
  selectConsoleStations,
  selectConsoleStationsList,
  selectConsoleStationsBySqlId,
}