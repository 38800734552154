import React from 'react';
import './Search.css';

import OwnerEntry from './OwnerEntry';
import UserSearchEntry from './UserSearchEntry';
import SearchInput from './SearchInput';
import SearchResults from './SearchResults';
import StationEntry from './StationEntry';
import IspEntry from './IspEntry';
import api from '../../utils/api';

export default class Search extends React.Component {
  constructor (props) {
    super(props);

    const { usersOnly, stationsOnly, ispsOnly } = props;

    const searchAll = !usersOnly && !stationsOnly && !ispsOnly;

    this.state = {
      searchText: '',
      results: {},
      searching: false,
      searchOptions: {
        isps: {
          enabled: searchAll || ispsOnly,
          options: [
            {
              key: 'isp',
              label: 'ISP Name',
              enabled: true,
            },
            {
              key: 'owner',
              label: 'ISP Owner',
              enabled: false,
            }
          ],
        },
        stations: {
          enabled: searchAll || stationsOnly,
          options: [
            {
              key: 'station',
              label: 'Station Name & ID',
              enabled: true,
            }
          ],
        },
        users: {
          enabled: searchAll || usersOnly,
          options: [
            {
              key: 'FedExId',
              label: 'FedExId',
              enabled: true,
            },
            {
              key: 'email',
              label: 'Email',
              enabled: true,
            },
            {
              key: 'phone',
              label: 'Phone',
              enabled: true,
            },
            {
              key: 'first',
              label: 'First Name',
              enabled: false,
            },
            {
              key: 'last',
              label: 'Last Name',
              enabled: false,
            },
          ]
        }
      }
    };

    this.onChange = this.onChange.bind(this);
    this.enterSearch = this.enterSearch.bind(this);
    this.search = this.search.bind(this);
    this.toggleSearchOption = this.toggleSearchOption.bind(this);

    if (this.props.setRef) {
      this.props.setRef(this);
    }
  }

  onChange ({ target }) {
    const { searchTimeout } = this.state;

    if (searchTimeout) {
      clearInterval(searchTimeout);
    }

    const timeoutID = setTimeout(this.search, 1000);

    this.setState({ searchTimeout: timeoutID, results: {}, searchText: target.value, searching: true });
  }

  enterSearch (searchText) {
    this.setState({ searchText });
  }

  async search () {
    try {
      const { searchText } = this.state;

      if (!searchText) {
        this.setState({ results: {}, searching: false });

        return;
      };

      const sterilizedText = searchText.toString().replace(/\//g, '');

      if (this.props.ispsOnly === true) {
        await this.searchISPS(sterilizedText);
      }
      else if (this.props.stationsOnly === true) {
        await this.searchStations(sterilizedText);
      }
      else if (this.props.usersOnly === true) {
        await this.searchUsers(sterilizedText);
      }
      else {
        await Promise.all([
          this.searchISPS(sterilizedText), 
          this.searchUsers(sterilizedText), 
          this.searchStations(sterilizedText)
        ]);
      }

      this.setState({ searching: false });
    }
    catch(err) {
      console.error(err);
      this.setState({ searching: false });
    }
  }

  addSearchResults (results) {
    this.setState(prevState => {
      return {
        ...prevState,
        results: {
          ...prevState.results,
          ...results,
        }
      }
    })
  }

  async searchISPS (searchText) {
    const { searchOptions } = this.state;
    const { isps } = searchOptions;
    const { enabled, options } = isps;

    if (!enabled) return;
    
    const { key, enabled: enabledOption } = options[0];
    
    if (!enabledOption) return;

    let results = { isps: [], owners: [] };

    for (const option of options) {
      const { key, enabled } = option;

      if (!enabled) continue;

      if (key === 'isp') {
        const { isps } = await api.searchISPs(searchText);

        results = {
          ...results,
          isps
        };

      } else if (key === 'owner') {
        const { data } = await api.request(`search/contacts/owners/${searchText}`);

        results = {
          ...results,
          owners: data
        }
      }
    }

    this.addSearchResults(results);
  }

  async searchStations (searchText) {
    const { searchOptions } = this.state;
    const { stations } = searchOptions;
    const { enabled, options } = stations;
    
    if (!enabled) return;
    
    const { enabled: enabledOption } = options[0];
    
    if (!enabledOption) return;
      
    const results = await api.searchStations(searchText);

    this.addSearchResults(results);
  }

  async searchUsers (searchText) {
    const { searchOptions } = this.state;
    const { users } = searchOptions;
    const { enabled, options } = users;


    if (!enabled) return;

    let results = { users: {} };

    for (const option of options) {
      const { key, enabled } = option;

      if (!enabled) continue;

      const { users } = await api.searchUserByProperty(key, searchText);

      results = {
        ...results,
        users: {
          ...results.users,
          ...users
        }
      }
    }

    this.addSearchResults(results);
  }

  toggleSearchOption (setKey, optionKey) {
    const { searchOptions } = this.state;

    const options = searchOptions[setKey].options;

    const toggledOptions = options.map(option => {
      if (option.key === optionKey) {
        option.enabled = !option.enabled;
      }
      
      return option;
    });

    const newOptions = {
      ...searchOptions,
      [setKey]: {
        ...searchOptions[setKey],
        options: toggledOptions,
      }
    }

    this.setState({ searchOptions: newOptions, results: {} }, () => this.search());
  }

  render () {
    const { title, placeholder, usersOnly, stationsOnly, ispsOnly, history, liveISPList = {} } = this.props;
    const { searchText, searchOptions } = this.state;

    const searchAll = !usersOnly && !stationsOnly && !ispsOnly;

    return (
      <div className='Search'>
        <SearchInput
          value={searchText}
          onChange={this.onChange}
          sectionTitle={title || 'Search'}
          placeholder={placeholder || 'ISP Name, Owner name, Station ID & Name, User name, phone number, email and FedExId'}
          showOptions={searchAll || usersOnly || ispsOnly}
          searchOptions={searchOptions}
          toggleOption={this.toggleSearchOption}
        />

        { !!searchText &&
          <SearchResults searching={this.state.searching}>
            {(() => {
              const searchResults = [];
              const { stations = [], isps = [], owners = [], users = {} } = this.state.results;
              const sterilizedText = searchText.toString().replace(/\//g, '');

              // const exp = new RegExp(sterilizedText, 'i');
              
              for (const station of stations) {
                const matches = {};
                const matchFields = ['terminal', 'city', 'state', 'terminalName'];

                for (const field of matchFields) {
                  if (station[field] && station[field].includes(sterilizedText)) {
                    matches[field] = true;
                  }
                }

                searchResults.push(
                  <StationEntry
                    station={station}
                    matches={matches}
                    onClick={() => history.push(`/stations/${station.id}`)}
                    key={'station' + station.id}
                  />
                );
              }

              for (const isp of isps) {
                const matches = {};

                if (isp.entityName && isp.entityName.includes(sterilizedText)) {
                  matches.entityName = true;
                }

                if (isp.ownerName && isp.ownerName.includes(sterilizedText)) {
                  matches.ownerName = true;
                }

                if (isp.firebaseId && (liveISPList || {}).hasOwnProperty(isp.firebaseId)) {
                  isp.profile = liveISPList[isp.firebaseId];
                }

                searchResults.push(
                  <IspEntry
                    key={`isp ${isp.id}`}
                    to={`/isps/${isp.id}`}
                    isp={isp}
                    profile={isp.profile}
                    matches={matches}
                  />
                );
              }

              for (const owner of owners) {
                const matches = {};
                const matchFields = ['entityName', 'firstName', 'lastName', 'phone', 'email'];

                for (const field of matchFields) {
                  if (owner[field] && owner[field].includes(sterilizedText.replace(/()-/g, ''))) {
                    matches[field] = true;
                  }
                }

                const profile = (liveISPList || {})[owner.firebaseId];

                searchResults.push(
                  <OwnerEntry
                    key={`owner ${owner.id}`}
                    onClick={() => history.push(`/isps/${owner.ispId}`)}
                    owner={owner}
                    profile={profile}
                    matches={matches}
                  />
                );
              }
              
              // cast to String and downcase for comparison
              const downcase = val => String(val).toLowerCase();

              // Users results section
              const lowercaseSterileText = downcase(sterilizedText);
              // Need to determine if a search result might be matching a user's name or phone number
              // as well as sorting searches based upon match strength, so we follow a slightly different
              // pattern here.
              const isName = / |, /.test(lowercaseSterileText); // "John Smith" || "Smith, John"
              let first, last;

              if (isName) {
                if (lowercaseSterileText.includes(',')) {
                  const name = lowercaseSterileText.split(',');
                  first = name[1].trim();
                  last = name[0].trim();
                } else {
                  const name = lowercaseSterileText.split(' ');
                  first = name[0].trim();
                  last = name[1].trim();
                }
              }

              // Apply weighted matching to returned users so that we can sort
              // based upon closest match. 
              // Special case for firstname + lastname
              const weightedUsers = [];

              for (const userId in users) {
                const user = users[userId];
                const matches = {};
                const matchFields = ['fedExId', 'first', 'last', 'phone', 'email'];

                for (const field of matchFields) {
                  if (isName &&
                    ((field === 'first' && downcase(user[field]).includes(first)) || 
                     (field === 'last' && downcase(user[field]).includes(last)))) {  
                      matches[field] = true;
                  } 
                  
                  else if (user[field] && downcase(user[field]).includes(lowercaseSterileText.replace(/()-/g, ''))) {
                    matches[field] = true;
                  }
                }

                const profile = user.fedExId;

                function propertyWeight(user, field, lowercaseSterileText) {
                  const fieldVal = downcase(user[field]);
                  const remaining = fieldVal.replace(lowercaseSterileText.replace(/()-/g, ''), '');
                    
                  return (fieldVal.length - remaining.length) / fieldVal.length;
                }

                const weight = (() => {
                  let highestWeight = 0;

                  if (isName) { // if "John Smith" or "Smith, John"
                    if (!downcase(user['first']).includes(first) ||
                        !downcase(user['last']).includes(last)) {
                      return 0; // but first or last doesn't match fullname
                    }           // then filter out of results

                    else {
                      const firstWeight = propertyWeight(user, 'first', first);
                      const lastWeight = propertyWeight(user, 'last', last);

                      return (firstWeight + lastWeight) / 2;
                    } 
                  } else {
                    for (const field of matchFields) {
                      highestWeight = Math.max(highestWeight, propertyWeight(user, field, lowercaseSterileText));
                    }
                    return highestWeight;
                  }
                })()

                weightedUsers.push({weight, userId, user, profile, matches});
              }
              
              const sortedUsers = weightedUsers
                                    .filter(user => user.weight > 0)
                                    .sort((a, b) => {
                                      if (a.weight === b.weight) {
                                        return (`${a.user.first + a.user.last}` < 
                                                `${b.user.first + b.user.last}` ? -1 : 1);
                                      }

                                      else return b.weight - a.weight;
                                    });


              sortedUsers.forEach(({userId, user, profile, matches}) => {
                searchResults.push(
                  <UserSearchEntry
                    key={`user ${userId}`}
                    onClick={() => history.push(`/users/${user.fedExId}`)}
                    user={user}
                    profile={profile}
                    matches={matches}
                  />
                );
              })

              return searchResults.length ? searchResults : null;
            })()}
          </SearchResults>
        }
      </div>
    );
  }
}
