import React from 'react';

import './StationChange.css';

import Spinner from '../../common/Spinner';
import Modal from '../../Page/Modal';
import Change from '../../ServiceManagement/Buttons/Change';
import InputText from '../../Page/InputText';
import api from '../../../utils/api';

export default class StationChange extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      modalOpen: false,
      stationNum: null,
      error: null
    }

    this.submitStationChange = this.submitStationChange.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  async submitStationChange () {
    const { profile } = this.props;
    const { stationNum } = this.state;
    const { ISPID } = profile;

    if (!ISPID) {
      this.setState({ errorMessage: 'Could not submit your request because there was no operationId' });

      return;
    }

    if (!stationNum) {
      this.setState({ errorMessage: 'Please submit a valid station number' });

      return;
    }

    if (Number.isNaN(parseInt(stationNum))) {
      this.setState({ errorMessage: 'Please submit a numeric station number' });

      return;
    }

    try {
      this.setState({ loading: true });

      const res = await api.post(`service-management/station-change`, { operationId: profile.ISPID, stationNum: stationNum });

      await this.props.loadLatestProfile();
      
      this.setState({ loading: false });
      this.closeModal();
    }
    catch (err) {
      const message = err.response ? err.response.data : 'An error occurred';

      this.setState({ loading: false, errorMessage: message });
    }
  }

  openModal () {
    this.setState({ modalOpen: true });
  }

  closeModal () {
    this.setState({ modalOpen: false });
  }

  render () {
    const { profile } = this.props;
    
    return (
      <React.Fragment>
        <div 
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <div>
            <p>Fedex Station</p>
          </div>

          <div 
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}>
              <p style={{ marginRight: '20px', fontSize: '20px' }}>{profile.station.stationID}</p>

              <Change onChange={this.openModal} />
            </div>
          </div>
        </div>

        {
          this.state.modalOpen &&
          <Modal
            onBackClick={!this.state.loading && this.closeModal}>
            <div
              class='StationChange-form'
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '400px',
                width: '500px',
                backgroundColor: 'white',
                zIndex: 25,
                justifyContent: 'space-between',
                borderRadius: '5px',
                position: 'relative',
                padding: '10px'
              }}>
              <div>
                <p style={{ fontWeight: '500', fontSize: '20px', marginBottom: '10px' }}>Station Change</p>

                <p style={{ color: '#666', marginTop: '30px' }}>WARNING: changing the station number will immediately impact the manifest data being represented in the app. Please validate that the station you are submitting is correct.</p>

                <p style={{ color: '#666', marginTop: '15px' }}>1. Is this a co-location (i.e. 3980)? If yes, submit the primary station (i.e. 980).</p>

                <p style={{ color: '#666', marginTop: '15px' }}>2. Is this an hd-only station? If yes, then submit the hd-only station as-is.</p>

                <p style={{ color: '#666', marginTop: '15px' }}>3. Is this a ground-only or the number refers to the "ground" portion of the station? If yes, then submit the station as is.</p>
              </div>

              {
                <p style={{ color: 'red' }}>{this.state.errorMessage || null}</p>
              }

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}>

                <InputText
                  onChange={(e) => this.setState({ stationNum: e.target.value })}
                />

                {
                  !this.state.loading
                    ? <div
                      class='submit-btn'
                      style={{
                        height: '100%',
                        marginLeft: '10px',
                        borderRadius: '5px',
                        padding: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgb(22, 142, 50)'
                      }}
                      onClick={this.submitStationChange}>
                      <p style={{ color: 'white', fontWeight: '500' }}>Submit</p>
                    </div>
                    : <div style={{ width: '30px' }}>
                      <Spinner />
                    </div>
                }
              </div>
            </div>
          </Modal>
        }
      </React.Fragment>
    )
  }
} 