import React from 'react';
import './AddISP.css';

import api from '../../utils/api';

import InputText from '../Page/InputText';
import AssociateStation from './AssociateStation';
// import InputLocation from '../Page/InputLocation';
import Section from '../Page/Section';
import Button from '../Page/Button';
import StationEntry from '../Search/StationEntry';

export default class AddISP extends React.Component {
  constructor (props) {
    super(props);

    const old = props.isp || {};

    this.state = {
      entityId: old.entityId || '',
      entityName: old.entityName || '',
      newStation: null,
      stations: props.stations || [],
      old,
      error: null,
      showStationResults: true
    };

    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
    this.updateISP = this.updateISP.bind(this);
    this.saveISP = this.saveISP.bind(this);
    this.selectStation = this.selectStation.bind(this);
    this.removeStation = this.removeStation.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidUpdate (prevProps) {
    if (prevProps.stations !== this.props.stations) {
      this.setState({ stations: this.props.stations });
    }

    if (prevProps.isp !== this.props.isp) {
      this.setState({ isp: this.props.isp });
    }
  }

  reset (e) {
    e.preventDefault();

    const old = this.props.isp || {};

    this.setState({
      stations: (this.props.stations || []),
      entityId: old.entityId || '',
      entityName: old.entityName || ''
    });
  }

  submit (e) {
    e.preventDefault();

    if (this.props.edit) {
      this.updateISP();
    } else {
      this.saveISP();
    }
  }

  saveISP () {
    const { history } = this.props;

    this.setState({ error: null }, () => {
      const {
        entityId,
        entityName,
        stations
      } = this.state;

      if (!entityName) {
        return this.setState({ error: 'Must include an entity name.' });
      }

      const isp = {
        entityId,
        entityName
      };

      if (!stations.length) {
        return this.setState({ error: 'Must select at least one station.' });
      }

      let ispId;

      api.submitNewISP(isp)
        .then((data) => {
          const promises = [];

          ispId = data[0].id;

          for (const station of stations) {
            const stationId = station.id;

            promises.push(api.createStationISP({ stationId, ispId }));
          }

          return Promise.all(promises);
        })
        .then((data) => {
          history.push(`isps/${ispId}`);
        })
        .catch(err => {
          const { response } = err;
          const errorMessage = response && response.data
            ? response.data
            : null;
          const error = errorMessage || 'An unidentified error occurred';

          if (!errorMessage) {
            console.log(err);
          }

          this.setState({ error });
        });
    });
  }

  updateISP () {
    const { isp } = this.props;
    const { id } = isp;

    this.setState({ error: null }, () => {
      const {
        entityId,
        entityName,
        stations,
        old
      } = this.state;
      const updates = {
        entityId,
        entityName
      };

      if (!stations.length) {
        return this.setState({ error: 'At least 1 station must be applied.' });
      }

      for (const key in updates) {
        if (updates[key] === old[key]) {
          delete updates[key];
        }
      }

      const originalStationIds = (this.props.stations || []).reduce((acc, station) => {
        acc[station.id] = true;

        return acc;
      }, {});
      const removedStations = [];
      const addedStations = [];

      for (const station of stations) {
        if (!originalStationIds.hasOwnProperty(station.id)) {
          addedStations.push(station.id);
        }
        else {
          delete originalStationIds[station.id];
        }
      }

      for (const stationId in originalStationIds) {
        removedStations.push(stationId);
      }

      if (Object.keys(updates).length || removedStations.length || addedStations.length) {
        const promises = [];

        for (const stationId of addedStations) {
          promises.push(api.createStationISP({ stationId, ispId: isp.id }));
        }

        for (const stationId of removedStations) {
          promises.push(api.deleteStationISP(isp.id, stationId));
        }

        if (Object.keys(updates).length) {
          promises.push(api.updateISP(id, updates));
        }

        if (!!updates.entityName && isp.firebaseId) {
          promises.push(api.updateISPName(isp.firebaseId, updates.entityName))
        }

        Promise.all(promises)
          .then(data => {
            window.location.reload();
          })
          .catch(err => {
            if (err.response && err.response.data) {
              this.setState({ error: err.response.data });
            } else {
              console.log(err);
            }
          });
      }
      else {
        this.setState({
          error: 'Nothing changed'
        });
      }
    });
  }

  onChange (name, value) {
    this.setState({ [name]: value });
  }

  selectStation (station) {
    this.setState(prevState => {
      const nextStations = [];

      for (const prevStation of prevState.stations) {
        if (station.id !== prevStation.id) {
          nextStations.push(prevStation);
        }
      }

      nextStations.push(station);

      return { stations: nextStations };
    });
  }

  removeStation (station) {
    this.setState(prevState => {
      const nextStations = [];

      for (const prevStation of prevState.stations) {
        if (station.id !== prevStation.id) {
          nextStations.push(prevStation);
        }
      }

      return { stations: nextStations };
    });
  }

  render () {
    const { edit } = this.props;
    const {
      error,
      entityId,
      entityName,
      old
    } = this.state;

    return (
      <Section title={edit ? 'Update ISP' : 'Add ISP'}>
        <div className='AddStation'>
          <form onSubmit={this.submit}>
            <div style={{ display: 'flex', marginBottom: 30 }}>
              <InputText
                label='Entity ID'
                name='entityId'
                value={entityId}
                onChange={(e) => this.onChange('entityId', e.target.value)}
                placeholder='V0000000'
                disabled={edit || false}
              />

              <div style={{ width: 40 }} />

              <InputText
                label='Entity Name'
                name='entityName'
                value={entityName}
                onChange={(e) => this.onChange('entityName', e.target.value)}
                placeholder={`Not Your Fathers Trucking Inc.`}                inputStyle={
                  edit && entityName !== old.entityName
                    ? {color: '#006DAB'}
                    : null
                }
                required
              />
            </div>

            <div>
              {
                this.state.stations && this.state.stations.length
                  ? <div style={{ marginBottom: '10px', borderTop: '1px solid #DDD' }}>
                    {
                      this.state.stations.map((station, i) =>
                        <StationEntry
                          key={i}
                          station={station}
                          selected
                          deselect={this.removeStation}
                          onClick={() => {}}
                        />
                      )
                    }
                  </div>
                  : null
              }

              <AssociateStation
                selectStation={this.selectStation}
              />
            </div>

            <div style={{ height: 30 }} />

            { !!error &&
              <div className='error'>{ error }</div>
            }

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {
                edit
                  ? <Button style={{ margin: 0 }} type='button' onClick={this.reset} color='#EA7D14'>Cancel</Button>
                  : null
              }

              {
                edit
                  ? <Button style={{ margin: 0, marginLeft: '5px' }} onClick={this.submit} color='#006DAB'>Update</Button>
                  : <Button onClick={this.submit} color='#168E32'>Submit</Button>
              }
            </div>
          </form>
        </div>
      </Section>
    );
  }
}
