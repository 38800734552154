import React from 'react';

import Icon from '../Icon/Icon';
import './EmailOption.css';

export default function EmailOption (props) {
  const { template } = props;
  let activeVersion;

  for (const version of template.versions) {
    if (version.active) {
      activeVersion = version;

      break;
    }
  }

  return (
    <div className='EmailOption' onClick={props.onClick}>
      <div className='details'>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p>{template.name}</p>

          {
            props.selected === true
              ? <p style={{ color: 'rgb(22, 142, 50)', fontSize: '12px', marginLeft: '10px' }}><Icon name='check' color='rgb(22, 142, 50)' size={12} /> Selected</p>
              : null
          }
        </div>
      </div>

      <div className='identity'>
        <p><span>Subject:</span> {activeVersion.subject}</p>
        <p>{template.id}</p>
      </div>
    </div>
  )
}
