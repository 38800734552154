import React from "react";
import { Stage as eStage, StageConfig } from "./stages";
import "./LifecycleOptions.css";

type Props = {
  stages: StageConfig[];
  setActiveStage: (stage: eStage) => void;
  activeStageId: eStage;
};

export default function LifecycleOptions(props: Props) {
  const { setActiveStage, activeStageId, stages } = props;

  return (
    <div className="LifecycleOptions">
      {stages.map((stage, i) => {
        return (
          <Stage
            key={`${stage.id ?? 'no_id'}-${i}`}
            setActiveStage={setActiveStage}
            isActive={stage.id === activeStageId}
            stage={stage}
          />
        );
      })}
    </div>
  );
}

type StageProps = {
  setActiveStage: (stage: eStage) => void;
  stage: StageConfig;
  isActive: boolean;
};

function Stage(props: StageProps) {
  const { setActiveStage, stage, isActive } = props;
  const { label, color } = stage;

  return (
    <div
      onClick={() => setActiveStage(stage.id)}
      style={{ backgroundColor: isActive ? color : "inherit" }}
      className={`Group ${isActive ? "active" : ""}`}
    >
      <p>{label}</p>
    </div>
  );
}
