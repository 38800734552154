import { useCallback } from "react";
import Stripe from "stripe";
import { useIsActiveIsp, useLiveIspList } from "../liveIspList";
import { isCancellation, isConversion } from "@utils/subscriptions";

type Subscription = Stripe.subscriptions.ISubscription;

export function useIsConversion() {
  const liveIspList = useLiveIspList();

  return useCallback(
    (subscription: Subscription, timeLimit: number) => {
      return isConversion(subscription, liveIspList, timeLimit);
    },
    [liveIspList]
  );
}
