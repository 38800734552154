import React, {useState} from 'react';
import Section from '../Page/Section';
import PageWrapper from '../Page/PageWrapper';
import Emails from './Emails/Emails';
import Notifications from './Notifications/Notifications';
import MsgOptionBar from './MsgOptionBar';
import {
  useUsers,
  useSalesCampaignCollections,
  useLiveIspList,
  useFirebaseUser,
  useConsoleUsers
} from '@hooks/redux';
import withPermissions from '../Permissions/withPermissions';
import './Messaging.css';

type Props = {
  fetchUsers: (userIds: string[]) => void;
}

enum ActiveOptions {
  EMAIL = 'EMAIL',
  NOTIFICATIONS = 'NOTIFICATIONS',
}

function Messaging ({ fetchUsers }: Props) {
  const [activeOption, setActiveOption] = useState<ActiveOptions>(ActiveOptions.EMAIL)
  const user = useFirebaseUser()
  const users = useUsers()
  const consoleUsers = useConsoleUsers();
  const collections = useSalesCampaignCollections()
  const liveISPList = useLiveIspList()

  return (
    <PageWrapper>
      <MsgOptionBar
        onClick={(opt: ActiveOptions) => setActiveOption(opt)}
        options={[
          { name: 'Email', id: ActiveOptions.EMAIL },
          { name: 'Notifications', id: ActiveOptions.NOTIFICATIONS }
        ]}
        activeOption={activeOption}
      />

      <Section>
        {
          activeOption === 'EMAIL'
            ? <Emails
              consoleUsers={consoleUsers}
              user={user}
              collections={collections}
              profiles={liveISPList}
              users={users}
              fetchUsers={fetchUsers}
            />
            : null
        }

        {
          activeOption === 'NOTIFICATIONS'
            ? <Notifications
              consoleUsers={consoleUsers}
              user={user}
              collections={collections}
              profiles={liveISPList}
              users={users}
              fetchUsers={fetchUsers}
            />
            : null
        }
      </Section>
    </PageWrapper>
  )
}

export default withPermissions(Messaging);
