import React from 'react';
import { InputText, SectionTitle, Button } from "../../Page";
import { LiveIspProfile } from '@reducers/liveIspList';
import { LytxAuthorizationCredentials } from '@services';
import { useColorPack } from '@hooks/redux';
import { isEventFileStreamingUrl } from './hooks';

const DEFAULT_EVENTS_URL = 'https://services-sd08.drivecam.com/dcsubmission/SubmissionServiceV5.svc';
const DEFAULT_DISPATCH_URL = 'https://services-sd08.drivecam.com/DCSubmission/SubmissionService.svc';

type Props = {
  liveIspProfile: LiveIspProfile;
  credentials: LytxAuthorizationCredentials;
  credentialsAreValid: boolean;
  setCredentials: (callback: (credentials: LytxAuthorizationCredentials) => LytxAuthorizationCredentials) => void;
  submitLytxCredentials: () => void;
  onCancel: () => void;
}

export default function VedrLytxCredentialsForm ({ 
  liveIspProfile,
  credentials,
  setCredentials,
  credentialsAreValid,
  submitLytxCredentials,
  onCancel,
 }: Props) { 
  const colorPack = useColorPack();

  if (!liveIspProfile) return null;

  return (
    <div style={{ flexDirection: 'column', justifyContent: 'flex-start', padding: 10, width: '100%', }}>
        <SectionTitle title="Lytx Events Credentials" style={{ flexDirection: 'column' }}>
            <InputText
              label='URL'
              placeholder={DEFAULT_EVENTS_URL}
              value={credentials.eventsSoapApiUrl}
              required
              valid={credentials.eventsSoapApiUrl.length > 5  && !isEventFileStreamingUrl(credentials.eventsSoapApiUrl)}
              invalidMessage={isEventFileStreamingUrl(credentials.eventsSoapApiUrl) ? 'EventFileStreaming url is invalid' : undefined}
              onChange={({ target }) => {
                setCredentials((creds) => ({
                  ...creds,
                  eventsSoapApiUrl: target.value,
                }));
              }}
            />
            <InputText
              label='Username'
              placeholder="MyIspEventsAPI"
              value={credentials.eventsSoapApiUsername}
              required
              valid={credentials.eventsSoapApiUsername.length > 5}
              onChange={({ target }) => {
                setCredentials((creds) => ({
                  ...creds,
                  eventsSoapApiUsername: target.value,
                }));
              }}
            />
            <InputText
              label='Password'
              placeholder="myRand0mPas$w0rd"
              value={credentials.eventsSoapApiPassword}
              required
              valid={credentials.eventsSoapApiPassword.length > 5}
              onChange={({ target }) => {
                setCredentials((creds) => ({
                  ...creds,
                  eventsSoapApiPassword: target.value,
                }));
              }}
            />
        </SectionTitle>

        <SectionTitle title="Lytx Driver Dispatch Credentials" style={{ flexDirection: 'column' }}>
          <InputText
            label='URL'
            placeholder={DEFAULT_DISPATCH_URL}
            value={credentials.driverSoapApiDispatchUrl}
            required
            valid={credentials.driverSoapApiDispatchUrl.length > 5 && !isEventFileStreamingUrl(credentials.driverSoapApiDispatchUrl)}
            invalidMessage={isEventFileStreamingUrl(credentials.driverSoapApiDispatchUrl) ? 'EventFileStreaming url is invalid' : undefined}
            onChange={({ target }) => {
              setCredentials((creds) => ({
                ...creds,
                driverSoapApiDispatchUrl: target.value,
              }));
            }}
          />
          <InputText
            label='Username'
            placeholder="MyIspDispatchAPI"
            value={credentials.driverSoapApiDispatchUsername}
            required
            valid={credentials.driverSoapApiDispatchUsername.length > 5}
            onChange={({ target }) => {
              setCredentials((creds) => ({
                ...creds,
                driverSoapApiDispatchUsername: target.value,
              }));
            }}
          />
          <InputText
            label='Password'
            placeholder="myRand0mPas$w0rd"
            value={credentials.driverSoapApiDispatchPassword}
            required
            valid={credentials.driverSoapApiDispatchPassword.length > 5}
            onChange={({ target }) => {
              setCredentials((creds) => ({
                ...creds,
                driverSoapApiDispatchPassword: target.value,
              }));
            }}
          />
        </SectionTitle>

        <SectionTitle title="Lytx REST Credentials" style={{ flexDirection: 'column' }}>
          <InputText
            placeholder="Api Token"
            value={credentials.apiKey}
            required
            valid={credentials.apiKey.length > 5}
            onChange={({ target }) => {
              setCredentials((creds) => ({
                ...creds,
                apiKey: target.value,
              }));
            }}
          />
        </SectionTitle>

        <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button 
            onClick={onCancel} 
            color={colorPack.red}
            style={{ margin: 10 }}
          >
            Cancel
          </Button>

          <Button 
            onClick={submitLytxCredentials} 
            disabled={!credentialsAreValid} 
            color={colorPack.blue}
            style={{ margin: 10 }}
          >
            Submit
          </Button>
        </div>
    </div>
  );
}
