import React from 'react';

import './InputRadio.css';
import InputText from './InputText';

export default function InputRadio (props) {
  return (
    <div className='InputRadio'>
      <fieldset name={props.name}>
        <legend>{props.label}</legend>
        {
          props.options.map(option => {
            return (
              option.withText
                ? <InputText
                  onChange={props.onCheckedChange}
                  checked={props.checkedItem === option.value}
                  type='radio'
                  label={option.name}
                  value={option.value}
                  style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', height: '40px' }}
                  inputStyle={{ width: '25px', height: '100%', margin: '0' }}
                  labelStyle={{ margin: '0', marginLeft: '5px', marginRight: '15px' }}>
                  <InputText
                    inputStyle={{ margin: '0', padding: '5px', fontSize: '14px' }}
                    disabled={props.checkedItem !== option.value}
                    onChange={props.onTextChanged}
                    value={props.checkedItem === option.value ? props.text : ''}
                  />
                </InputText>
                : <InputText
                  onChange={props.onCheckedChange}
                  checked={props.checkedItem === option.value}
                  type='radio'
                  label={option.name}
                  value={option.value}
                  style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-end', alignItems: 'center', height: '40px'  }}
                  inputStyle={{ width: '25px', margin: '0', height: '100%' }}
                  labelStyle={{ marginLeft: '5px', margin: '0' }}
                />
            )
          })
        }
      </fieldset>
    </div>
  )
}
