import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type DateString = string;

/**
 * Daily Active Users is a trailing measurement of how many users are active
 * on a given date versus the previous 7 days.
 *
 * (# unique users on date) / (# unique users over last 7 days)
 */
export type DailyActiveUsersMetric = number;
/**
 * Weekly Active Users is a trailing measurement of how many users are active
 * in a given 7 day period versus the previous 28 days.
 *
 * (# unique users over 7 days) / (# unique users over 28 days)
 */
export type WeeklyActiveUsersMetric = number;
export type ActiveUsersMetrics = {
  daily: DailyActiveUsersMetric;
  weekly: WeeklyActiveUsersMetric;
};
export type DailyActiveUsers = Record<DateString, ActiveUsersMetrics>;
export type DailyActiveUsersData = {
  data: DailyActiveUsers;
  metadata: {
    updatedAt: number;
  };
};

export enum Status {
  INITIAL = "INITIAL",
  UPDATED = "UPDATED",
  FAILED = "FAILED",
}

type DailyActiveUsersState = {
  status: Status;
  data: DailyActiveUsers;
  updatedAt: number | null;
};

const initialState: DailyActiveUsersState = {
  status: Status.INITIAL,
  data: {},
  updatedAt: null,
};

const dailyActiveUsersSlice = createSlice({
  name: "dailyActiveUsers",
  initialState: initialState,
  reducers: {
    updateDateActiveUsers: (
      state,
      action: PayloadAction<{ date: string,  activeUsersMetrics: ActiveUsersMetrics | null }>
    ) => {
      const { date, activeUsersMetrics } = action.payload;

      state.data = {
        ...state.data,
        [date]: activeUsersMetrics || { daily: 0, weekly: 0 },
      };

      state.status = Status.UPDATED;
    },
    updateDailyActiveUsers: (
      state,
      action: PayloadAction<{ dailyActiveUsers: DailyActiveUsers }>
    ) => {
      const { dailyActiveUsers } = action.payload;

      state.data = {
        ...state.data,
        ...dailyActiveUsers,
      };

      state.status = Status.UPDATED;
    },
    updateDailyActiveUsersUpdatedAt: (
      state,
      action: PayloadAction<{ updatedAt: number | null }>
    ) => {
      const { updatedAt } = action.payload;

      state.updatedAt = updatedAt;
    },
    resetDailyActiveUsers: (state) => {
      state.data = {};
      state.updatedAt = null;
      state.status = Status.INITIAL;
    },
  },
});

export const {
  updateDateActiveUsers,
  updateDailyActiveUsers,
  updateDailyActiveUsersUpdatedAt,
  resetDailyActiveUsers,
} = dailyActiveUsersSlice.actions;

export default dailyActiveUsersSlice.reducer;
