import React from 'react';
import PageWrapper from '../../Page/PageWrapper';
import Section from '../../Page/Section';
import CollectionsList from './CollectionsList';
import CreateCollection from './CreateCollection';
import withPermissions from '../../Permissions/withPermissions';
import './Collections.css';

type Props = {}

function Collections ({}: Props) {

  return (
    <PageWrapper>
      <Section title='Create Collection'>
        <CreateCollection />
      </Section>

      <Section title='Collections'>
        <CollectionsList editable />
      </Section>
    </PageWrapper>
  );
}

export default withPermissions(Collections);