import React from 'react';
import './Stations.css';
import { RouteComponentProps } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import PageWrapper from '../Page/PageWrapper';
import PageTitle from '../Page/PageTitle';
import Section from '../Page/Section';
import Search from '../Search/Search';
import StationsSummary from './StationsSummary';
import AddStation from './AddStation';
import StationLists from './StationLists';
import StationAdvocatesList from './StationAdvocatesList';
import api from '../../utils/api';
import withPermissions from '../Permissions/withPermissions';
import bugsnagClient from '@utils/bugsnag';
import {RootState} from '@reducers';
import {ConsoleStation} from '@reducers/stations/consoleStations';
import * as actions from '@actions';
import * as selectors from '@selectors';

type Props = {
  history: RouteComponentProps['history'];
} & PropsFromRedux;

type State = {

}

class Stations extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      // hdOnlyStations: null,
      // coLocationStations: null,
      // stationsWithAdvocates: null
    };

    this.enterSearch = this.enterSearch.bind(this);
    this.goToStationPage = this.goToStationPage.bind(this);
  }

  componentDidMount() {
    this.fetchStationData();
  }

  fetchStationData() {
    const { dispatch } = this.props;

    actions.stations.productStations.fetchHdOnlyStations(dispatch);
    actions.stations.productStations.fetchCoLocationStations(dispatch);
    actions.stations.consoleStations.fetchConsoleStations(dispatch);
  }

  enterSearch(text: string) {
    // @ts-ignore
    this.searchRef.enterSearch(text);
  }

  async goToStationPage(stationId: string) {
    const { stations, dispatch } = this.props;
    try {
      let station: ConsoleStation | undefined;
      if (stations[stationId]) {
        station = stations[stationId];
      } else {
        const stations =
          await actions.stations.consoleStations.fetchConsoleStationByTerminalId(
            dispatch,
            stationId
          );
        station = stations?.[stationId];
      }

      if (!station || !station.id) return;

      this.props.history.push(`/stations/${station.id}`);
    } catch (err) {
      console.error(err);
      bugsnagClient.notify(err as Error);
    }
  }

  render() {
    const {} = this.state;
    const { history, coLocationStations, stations } = this.props;

    return (
      <div className="Stations">
        <PageWrapper>
          <PageTitle>Stations</PageTitle>

          <Section>
            <StationsSummary
              // coLocationStations={coLocationStations}
              // activeStations={this.props.activeStations}
              // activity={this.props.activity}
              // liveISPList={this.props.liveISPList}
              // stations={stations}
              // enterSearch={this.enterSearch}
            />
          </Section>

          <Search
            history={this.props.history}
            title="Search Stations"
            stationsOnly
            placeholder="Station ID, station name, city, state"
            // @ts-ignore
            setRef={(ref) => this.searchRef = ref}
          />

          <Section title="Advocates">
            <StationAdvocatesList goToStationPage={this.goToStationPage} />
          </Section>

          <StationLists goToStationPage={this.goToStationPage} />

          <AddStation history={history} />
        </PageWrapper>
      </div>
    );
  }
}

function mapStateToProps (state: RootState) {
  return {
    activeStations: selectors.stations.activeStations.selectActiveStations(state),
    activity: selectors.metrics.dailyOperationsActivity.selectDailyOperationsActivity(state),
    liveISPList: selectors.liveIspList.selectLiveIspList(state),
    referrals: selectors.referrals.selectReferrals(state),
    stationsWithAdvocates: selectors.stations.productStations.selectStationsWithAdvocates(state),
    hdOnlyStations: selectors.stations.productStations.selectHdOnlyStations(state),
    coLocationStations: selectors.stations.productStations.selectCoLocationStations(state),
    users: selectors.users.selectUsers(state),
    stations: selectors.stations.consoleStations.selectConsoleStations(state),
  }
}

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withPermissions(Stations));
