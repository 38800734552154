import {combineReducers} from 'redux';

import activeStationsReducer from './activeStations';
import stationsIspsReducer from './stationsIsps';
import consoleStationsReducer from './consoleStations';
import stationsSalesListsReducer from './stationSalesLists';
import productStationsReducer from './productStations'

const subscriptionsReducer = combineReducers({
  activeStations: activeStationsReducer,
  stationsIsps: stationsIspsReducer,
  consoleStations: consoleStationsReducer,
  stationsSalesLists: stationsSalesListsReducer,
  productStations: productStationsReducer,
});

export default subscriptionsReducer;
