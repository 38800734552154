import React from "react";
import Collection from "./Collection";
import ScrollView from "../../Page/ScrollView";
import { useSalesCampaignCollections, useLiveIspList } from '@hooks/redux';
import "./CollectionsList.css";

type Props = {
  selectable?: boolean;
  select?: (tag: string) => void;
  selectedCollections?: Record<string, any>;
  editable: boolean;
};
export default function CollectionsList({
  selectable,
  select,
  selectedCollections,
  editable,
}: Props) {
  const profiles = useLiveIspList();
  const collections = useSalesCampaignCollections();

  return (
    <div className="CollectionsList">
      <ScrollView direction="vertical" height="520px">
        {
          <Collection
            key={"ALL_ISP"}
            selectable={!!selectable}
            select={() => selectable && select?.("ALL_ISP")}
            selected={
              !!(selectable &&
              selectedCollections?.includes("ALL_ISP"))
            }
            collection={{
              collectionId: "ALL_ISP",
              name: "All ISPs",
              createdAt: Date.now(),
              createdByUID: '',
              isps: [],
              stations: [],
            }}
            profiles={profiles}
          />
        }

        {collections
          ? Object.keys(collections)
              .sort((a, b) => {
                const aCol = collections[a];
                const bCol = collections[b];

                return bCol.createdAt - aCol.createdAt;
              })
              .map((pushKey) => {
                const collection = collections[pushKey];

                const { collectionId } = collection;
                const selected =
                  selectable &&
                  selectedCollections?.includes(collectionId);

                return (
                  <Collection
                    key={collectionId}
                    selectable={!!selectable}
                    select={() =>
                      selectable && select?.(collectionId)
                    }
                    editable={editable}
                    collection={collection}
                    selected={selected}
                    profiles={profiles}
                  />
                );
              })
          : null}
      </ScrollView>
    </div>
  );
}
