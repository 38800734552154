import {IConsoleApiInitializer} from "@services/ConsoleApiInitializer";
import {SubscriptionData} from "@api";
import ConsoleApiService from "@services/ConsoleApiService";


export class ConsoleIspsService {
  private api: ConsoleApiService;
  constructor(consoleApiInitializer: IConsoleApiInitializer) {
    this.api = consoleApiInitializer.initialize();
  }

  async getISPSubscription (id: string): Promise<SubscriptionData | null> {
    return await this.api.get<SubscriptionData | null>(`isps/${id}/subscription`);
  }

  async getIspUsageStatistics (id: string): Promise<{ monthly_usage: number }> {
    return await this.api.get<{monthly_usage: number}>(`isps/${id}/usage`);
  }

  async getIspsByMinimumUsageThreshold (threshold: number): Promise<any> {
    return await this.api.get(`isps`, {
      daysUsedThreshold: threshold.toString()
    });
  }

  async getISPCustomerData (id: string): Promise<any> {
    return await this.api.get(`isps/${id}/customer`);
  }

  async updateISP (id: number, updates: Record<string, any>) {
    return await this.api.patch(`isps/${id}`, updates);
  }

  /**
   * Updates CSPName in Firebase operation profile
   */
  async updateISPName (firebaseId: string, updatedName: string) {
    return await this.api.patch(`isps/${firebaseId}/name/${updatedName}`, null);
  }
  async deleteISP (id: string) {
    return await this.api.delete(`isps/${id}`);
  }
}
