import React from "react";
import SignupTotals from "./SignupTotals";
import SignupsChart from "./SignupsChart";
import {
  useSignupsDailyTotals,
  useTrialDailyTotals,
  useConvertedDailyTotals,
} from "@hooks/redux";
import "./SignupMetricsSummaries.css";

type Props = {
  // partials: any;
};

export default function SignupMetricsSummaries({}: Props) {
  const signupTotals = useSignupsDailyTotals();
  const trialTotals = useTrialDailyTotals();
  const conversionTotals = useConvertedDailyTotals();

  return (
    <div className="SignupMetricsSummaries">
      <div className="top">
        <div className="totals-wrapper">
          <SignupTotals label="Signups" color="#006DAB" totals={signupTotals} />
        </div>

        <div className="chart-wrapper">
          <SignupsChart
            totals={signupTotals}
            color="#006DAB"
            dataKey="Signups"
          />
        </div>
      </div>

      <div
        style={{
          width: "100%",
          height: 1,
          borderBottom: "solid 1px #ddd",
          margin: "3 0",
        }}
      />

      <div className="top">
        <div className="totals-wrapper">
          <SignupTotals label="Trials" color="#EA7D14" totals={trialTotals} />
        </div>

        <div className="chart-wrapper">
          <SignupsChart totals={trialTotals} color="#EA7D14" dataKey="Trials" />
        </div>
      </div>

      <div
        style={{
          width: "100%",
          height: 1,
          borderBottom: "solid 1px #ddd",
          margin: "5 0",
        }}
      />

      <div className="top">
        <div className="totals-wrapper">
          <SignupTotals
            label="Conversions"
            color="rgb(22, 142, 50)"
            totals={conversionTotals}
          />
        </div>

        <div className="chart-wrapper">
          <SignupsChart
            totals={conversionTotals}
            color="rgb(22, 142, 50)"
            dataKey="Conversions"
          />
        </div>
      </div>
    </div>
  );
}
