import React from 'react';
import './Button.css';

type Props = {
  onClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  color?: string;
  children?: any;
  style?: React.CSSProperties;
  type?: 'submit' | 'button' | 'reset';
  disabled?: boolean;
}
export default function Button (props: Props) {
  const { onClick, color, children, style = {}, type, disabled } = props;
  const addProps = type ? { type } : {};

  return (
    <button
      className='Button'
      onClick={onClick}
      style={{
        backgroundColor: color || '#222',
        opacity: disabled ? 0.2 : 1,
        ...style,
        cursor: disabled ? 'none' : 'pointer'
      }}
      disabled={disabled}
      {...addProps}
    >
      { children }
    </button>
  );
}
