import React from 'react';
import moment from 'moment-timezone';
import Map from '../Map/Map';
import pr from '@packageroute/types-firebase';
import WorkAreaMarker from '../Markers/WorkAreaMarker';
import {
  useDailyOperationsUsage,
} from '@hooks/redux';
import './IspSummary.css';

type Profile = pr.isp.profile.Profile;

type Props = {
  isp: { firebaseId: string };
  profile: Profile;
  usageStats: number | null;
}

function renderIspUsageData(usage: any, lastXDays: string[], isp: { firebaseId: string }, usageStats: number | null) {
  return <>
    {
      usage
        ? (
          <>
            <div className='history'>
              <div className='history-title'>
                <p style={{fontSize: '14px', color: '#999'}}>Daily Activity</p>
              </div>

              <div className='history-table'>

                <div className='history-row'>
                  <div className='history-header'/>
                  {
                    lastXDays.sort((a, b) => {
                      const aMom = moment(a, 'YYYY-MM-DD');
                      const bMom = moment(b, 'YYYY-MM-DD');

                      if (aMom.isBefore(bMom)) return -1;
                      if (bMom.isBefore(aMom)) return 1;

                      return 0;
                    }).map(date => {
                      return <div className='history-cell border-right'>
                        <p style={{fontSize: '12px', fontWeight: 500}}>
                          {moment(date, 'YYYY-MM-DD').format('dd')}
                        </p>
                      </div>
                    })
                  }
                </div>

                {
                  usage
                    ? <DownloadRow
                      data={lastXDays.reduce<Record<string, boolean>>((acc, date) => {
                        const usageDate = usage[date];

                        if (!usageDate) {
                          acc[date] = false;
                        } else {
                          const activity = (usageDate.activeISPs || {})[isp.firebaseId];

                          acc[date] = !!(activity || null);
                        }

                        return acc;
                      }, {})}
                      label='Active'
                    />
                    : null
                }
              </div>

            </div>
            {usageStats && (
              <div className='history'>
                <div className='history-title left' style={{borderRight: 0}}>
                  <p style={{fontSize: '14px', color: '#999'}}>30-day Activity</p>
                  <h2 style={{fontWeight: 300}}>{usageStats} / 30</h2>
                </div>
              </div>)
            }
          </>
        )
        : <div className='noData'>
          No download history available.
        </div>
    }
  </>;
}

export default function IspSummary({isp, profile, usageStats}: Props) {
  const usage = useDailyOperationsUsage();
  const {activeRoutes, totalRoutes} = (() => {
    let totalRoutes = 0;
    let activeRoutes = 0;

    for (const key in profile.WorkAreas) {
      totalRoutes++;

      if (profile.WorkAreas[key].paid) activeRoutes++;
    }

    return {totalRoutes, activeRoutes};
  })();
  const lastXDays = getLastXDates(7);

  return (
    <div className='IspSummary'>
      <div className='left'>
        <p className='fraction'>
          {activeRoutes} / {totalRoutes}
        </p>
        <p className='fractionTitle'>Paid Routes</p>

        {!isNaN(activeRoutes) && totalRoutes && !isNaN(totalRoutes) &&
          <p className='fractionPercent'>{((activeRoutes / totalRoutes) * 100).toFixed(2)} %</p>
        }
      </div>

      <div className='middle'>
        {renderIspUsageData(usage, lastXDays, isp, usageStats)}
      </div>

      <div className='right'>
        <Map
          // @ts-ignore
          height='100%'
          snapToChildren
          defaultCenter={(() => {
            let latAcc = 0;
            let lngAcc = 0;
            let count = 0;

            for (const key in profile.WorkAreas) {
              const workArea = profile.WorkAreas[key];

              if (workArea.location) {
                const {lat, lng} = workArea.location;

                count++;
                latAcc += lat;
                lngAcc += lng;
              }
            }

            const center = {
              lat: latAcc / count,
              lng: lngAcc / count
            };
            return center;
          })()}
        >
          {(() => {
            const result = [];
            for (const key in profile.WorkAreas) {
              const workArea = profile.WorkAreas[key];
              result.push(
                <WorkAreaMarker workArea={workArea} key={key}/>
              );
            }

            return result;
          })()}
        </Map>
      </div>
    </div>
  );
}


type DownloadRowProps = {
  data: Record<string, boolean>;
  label: string;
}

function DownloadRow({data, label}: DownloadRowProps) {
  if (!data) return null;

  const keys = Object.keys(data || {});

  return (
    <div className='history-row' style={{borderTop: '1px solid #DDD'}}>
      <div className='history-header'>
        <p style={{fontSize: '12px', fontWeight: 500}}>{label}</p>
      </div>

      {
        keys
          .sort((a, b) => {
            const aMom = moment(a, 'YYYY-MM-DD');
            const bMom = moment(b, 'YYYY-MM-DD');

            if (aMom.isBefore(bMom)) return -1;
            if (bMom.isBefore(aMom)) return 1;

            return 0;
          })
          .map(date => {
            const wasDownload = !!data[date];

            return (
              <div className='history-cell'>
                {
                  wasDownload
                    ? <div className='circle'/>
                    : null
                }
              </div>
            );
          })
      }
    </div>
  );
}

function getLastXDates(numDates: number) {
  const days = [];

  for (let i = numDates; i >= 0; i--) {
    const today = moment();

    days.push(today.subtract(i, 'days').format('YYYY-MM-DD'));
  }

  return days;
}

export {DownloadRow};
