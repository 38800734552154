import React from 'react';
import './ScrollView.css';

type Props = {
  direction?: 'vertical' | 'horizontal';
  height?: number | string;
  width?: number | string;
  children?: any;
}

export default function ScrollView ({ direction = 'vertical', height = '100%', width = '100%', children }: Props) {
  return (
    <div className='ScrollView'>
      <div
        style={{
          height: direction === 'vertical' ? height : '100%',
          width: direction === 'horizontal' ? width : '100%'
        }}
        className={`scroll ${direction || 'horizontal'}`}>
        <div style={{ flex: 1 }}>
          {children}
        </div>
      </div>
    </div>
  );
}
