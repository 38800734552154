import React, { useState } from "react";
import { Notice } from "../../Page";
import { Card } from "@layoutComponents";
import { useColorPack } from "@hooks/redux";
import { useVedrFeatureManagement } from "./hooks";
import VedrLytxCredentialsForm from "./VedrLytxCredentialsForm";
import VedrSubscriptionStatus from "./VedrSubscriptionStatus";
import { Section, Button } from "../../Page";

type Props = {
  operationId: string;
};

export default function IspVedrFeatureManagement({ operationId }: Props) {
  const colorPack = useColorPack();
  const [showCredentialsForm, setShowCredentialsForm] = useState(false);
  const [showConfirmAction, setShowConfirmAction] = useState(false);
  const {
    loaded,
    loading,
    liveIsp,
    subscription,
    errorMessage,
    credentials,
    credentialsAreValid,
    setCredentials,
    submitLytxCredentials,
    cancelLytxService,
    vendorAuthorizationSettings,
    markAuthorizationRequestAsSent,
  } = useVedrFeatureManagement(operationId);

  if (!liveIsp) return null;

  return (
    <Section title="Vedr Management" loading={loading}>
      <Card
        style={{
          flexDirection: "column",
          justifyContent: "flex-start",
          padding: 10,
        }}
      >
        <VedrSubscriptionStatus
          subscription={subscription}
          timeZone={liveIsp.timeZone}
        />

        {!!(loaded && errorMessage) && (
          <Notice title="Error" severity="error" description={errorMessage} />
        )}

        {!loading && loaded && !showCredentialsForm && !showConfirmAction && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              padding: 10,
            }}
          >
            {!subscription &&
              (!vendorAuthorizationSettings ||
                !vendorAuthorizationSettings?.authorizationRequestHasBeenSent) && (
                <Button
                  style={{ margin: 5 }}
                  onClick={markAuthorizationRequestAsSent}
                  color={colorPack.blue}
                >
                  Authorization Request Sent
                </Button>
              )}

            <Button
              style={{ margin: 5 }}
              onClick={() => setShowCredentialsForm(true)}
              color={subscription ? colorPack.green : colorPack.blue}
            >
              {subscription ? "Update Credentials" : "Activate Integration"}
            </Button>

            {!!subscription && !subscription?.cancelled_at && (
              <Button
                style={{ margin: 5 }}
                onClick={() => setShowConfirmAction(true)}
                color={colorPack.red}
              >
                {"Cancel Integration"}
              </Button>
            )}
          </div>
        )}

        {showConfirmAction && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              padding: 10,
            }}
          >
            <p style={{ color: colorPack.red, fontWeight: 400 }}>
              Are you sure you want to do that?
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                padding: 10,
              }}
            >
              <Button
                style={{ margin: 5 }}
                onClick={() => setShowConfirmAction(false)}
                color={colorPack.green}
              >
                No, Undo
              </Button>
              <Button
                style={{ margin: 5 }}
                onClick={cancelLytxService}
                color={colorPack.red}
              >
                Terminate Integration
              </Button>
            </div>
          </div>
        )}

        {showCredentialsForm && (
          <VedrLytxCredentialsForm
            liveIspProfile={liveIsp}
            credentials={credentials}
            credentialsAreValid={credentialsAreValid}
            setCredentials={setCredentials}
            submitLytxCredentials={() => {
              submitLytxCredentials();
              setShowCredentialsForm(false);
            }}
            onCancel={() => setShowCredentialsForm(false)}
          />
        )}
      </Card>
    </Section>
  );
}
