import { Subscription, Schedule, Vendor } from '@packageroute/vedr-service-model-typescript';
import ConsoleApiService from './ConsoleApiService';
import {IConsoleApiInitializer} from "./ConsoleApiInitializer";
import pr from '@packageroute/types-firebase';

export type VedrSettings = pr.isp.vedr.VedrSettings;
export type VendorAuthorization = pr.isp.vedr.vendor_authorization.LytxAuthorization;

export type VedrServiceStateResponse = {
  subscription: Subscription;
  schedules: Schedule[];
}

export type VedrSubscriptionResponse = {
  subscription: Subscription;
}

export type LytxAuthorizationCredentials = {
  eventsSoapApiUrl: string;
  eventsSoapApiUsername: string;
  eventsSoapApiPassword: string;
  driverSoapApiDispatchUrl: string;
  driverSoapApiDispatchUsername: string;
  driverSoapApiDispatchPassword: string;
  apiKey: string;
};

export class ConsoleVedrManagementService {
  private api: ConsoleApiService;

  constructor(consoleApiInitializer: IConsoleApiInitializer) {
    this.api = consoleApiInitializer.initialize();
  }

  async getVendorAuthorization (operationId: string, vendor: Vendor): Promise<{ vendor_authorization: VendorAuthorization | null }> {
    const response = await this.api.get<{ vendor_authorization: VendorAuthorization | null }>(`vedr/settings/authorization/${operationId}`, { vendor });

    return {
      vendor_authorization: response.vendor_authorization
    }
  }

  async setVendorAuthorizationAsRequested (operationId: string, vendor: Vendor): Promise<{ vendor_authorization: VendorAuthorization | null }> {
    const body = {
      vendor,
    }

    const response = await this.api.patch<{ vendor_authorization: VendorAuthorization | null }>(`vedr/settings/authorization/${operationId}/requested`, body);

    return {
      vendor_authorization: response.vendor_authorization
    }
  }

  async initializeService (operationId: string, timeZone: string, vendor: Vendor, credentials: LytxAuthorizationCredentials): Promise<VedrServiceStateResponse> {
    const body = {
      operationId,
      timeZone,
      vendor,
      credentials,
    }
    const response = await this.api.post<VedrServiceStateResponse>(`vedr/service`, body);

    return {
      subscription: response.subscription,
      schedules: response.schedules,
    }
  }

  async getServiceStatus (operationId: string, vendor: Vendor): Promise<VedrServiceStateResponse> {
    return await this.api.get<VedrServiceStateResponse>(`vedr/service/${operationId}`, { vendor });
  }

  async updateSubscriptionCredentials (operationId: string, vendor: Vendor, credentials: LytxAuthorizationCredentials): Promise<VedrSubscriptionResponse> {
    const body = {
      vendor,
      credentials,
    }

    const response = await this.api.put<VedrSubscriptionResponse>(`vedr/service/credentials/${operationId}`, body);

    return {
      subscription: response.subscription,
    }
  }

  async cancelService (operationId: string, vendor: Vendor): Promise<void> {
    return await this.api.delete<void>(`vedr/service/${operationId}`, undefined, { vendor });
  }
}
