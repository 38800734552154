import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type OperationId = string;
export type StationId = string;
export type ActiveStationOperations = OperationId[];
export type ActiveStations = Record<StationId, ActiveStationOperations>;

export enum Status {
  INITIAL = 'INITIAL',
  PENDING = 'PENDING',
  UPDATED = 'UPDATED',
  FAILED = 'FAILED'
}

export type State = {
  data: ActiveStations;
  status: Status;
};

const initialState: State = {
  data: {},
  status: Status.INITIAL,
};

const activeStationsSlice = createSlice({
  name: 'activeStations',
  initialState: initialState,
  reducers: {
    updateActiveStations: (state, action: PayloadAction<{ activeStations: ActiveStations }>) => {
      const { activeStations } = action.payload;

      state.status = Status.UPDATED;
      state.data = activeStations;
    },
    resetActiveStations: (state) => {
      state.status = Status.INITIAL;
      state.data = {};
    }
  }
})

export const { 
  updateActiveStations,
  resetActiveStations
} = activeStationsSlice.actions

export default activeStationsSlice.reducer;
