import React from 'react';

import './PipelineStatus.css';

export default function PipelineStatus (props) {
  const { fontSize = '16px', backgroundColor } = props;
  const color = backgroundColor !== 'transparent' ? 'white' : 'black';
  const style = { backgroundColor };

  if (props.dot) {
    style.height = '12px';
    style.width = '12px';
    style.borderRadius = '6px';
    style.padding = 0;
  }

  return (
    <div
      className='PipelineStatus'
      style={style}>
      {
        !props.dot && props.text
          ? <p className='status' style={{ color, fontSize }}>{props.text}</p>
          : null
      }
    </div>
  )
}
