import React from "react";
import { mergeStyles, Styles } from './utils';

import "./FlatList.css";

type Child = JSX.Element | JSX.Element[] | number | string | string[] | undefined | null;
type Children = (string | JSX.Element | null)[] | Child;

type Props<T> = {
  className?: string;
  style?: Styles;
  data?: T[];
  renderItem: ({ item, index }: { item: T, index: number }) => Child;
  children?: Children
  // keyExtractor?: (item?: object, index?: number) => string;
  ListEmptyComponent?: Children | (() => Children);
  ListHeaderComponent?: Children | (() => Children);
}

function FlatList<T>({
  children = null,
  className = '',
  data = [],
  ListEmptyComponent = [],
  ListHeaderComponent = [],
  renderItem,
  style = {},
}: Props<T>) {

  const renderedItems = (data || []).map((item, index) => renderItem({ item, index }));

  return (
    <div className={`FlatList ${className}`} style={mergeStyles(style)}>
      { ListHeaderComponent }
      <div className="FlatListContents">
        { renderedItems.length
          ? renderedItems
          : typeof ListEmptyComponent === 'function'
            ? ListEmptyComponent()
            : ListEmptyComponent
        }
        { children }
      </div>
    </div>
  );
}

export default FlatList;
