import React, { CSSProperties } from 'react';

import './ScrollView.css';

interface NativeLayout {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface NativeEvent {
  layout: NativeLayout;
}

interface MockNativeEvent {
  nativeEvent: NativeEvent;
}

interface Props {
  id?: string;
  onLayout?: (nativeEvent?: MockNativeEvent) => void;
  className?: string;
  scrollRef?: (ref: React.RefObject<HTMLElement>) => void;
  style?: CSSProperties;
  wrapStyle?: CSSProperties;
  contentContainerStyle?: CSSProperties;
  children?: any;
  isVertical?: boolean;
  scrollable?: boolean;
}

interface State {
  onLayoutCalled: boolean;
  refSet: boolean;
}



export default class ScrollView extends React.Component<Props, State> {
  settingRef: boolean;
  settingLayout: boolean;
  ScrollViewRef?: React.RefObject<HTMLDivElement>;

  constructor (props: Props) {
    super(props);

    this.state = {
      onLayoutCalled: false,
      refSet: false
    };

    this.settingRef = false;
    this.settingLayout = false;
    this.ScrollViewRef = React.createRef();
  }

  componentDidMount () {
    if (this.props.scrollRef && !this.settingRef) this.setRef();

    if (this.props.onLayout && !this.settingLayout) this.onLayout();
  }

  componentDidUpdate () {
    if (this.props.scrollRef && !this.settingRef) this.setRef();

    if (this.props.onLayout && !this.settingLayout) this.onLayout();
  }

  setRef = () => {
    this.settingRef = true;
    if (!this.state.refSet && this.ScrollViewRef) {

      this.setState({ refSet: true }, () => {
        this.settingRef = false;
        this.props.scrollRef && this.ScrollViewRef && this.props.scrollRef(this.ScrollViewRef);
      })
    }
  }


  onLayout () {
    this.settingLayout = true;

    if (!this.state.onLayoutCalled && this.ScrollViewRef && this.ScrollViewRef.current) {
      const el = this.ScrollViewRef.current;

      const height = el.scrollHeight;
      const width = el.scrollWidth;
      const x = el.offsetLeft - el.scrollLeft + el.clientLeft;
      const y = el.offsetTop - el.scrollTop + el.clientTop;

      const mockNativeEvent = {
        nativeEvent: {
          layout: {
            x,
            y,
            width,
            height
          }
        }
      }
  
      this.setState({ onLayoutCalled: true }, () => {
        this.settingLayout = false;
        this.props.onLayout && this.props.onLayout(mockNativeEvent);
      })
    }
  }
  
  render() {
    const {
      className = '',
      style = {},
      wrapStyle = {},
      contentContainerStyle = {},
      children = [],
      isVertical = true,
      scrollable = true,
    } = this.props;

    return (
      <div 
        className={`ScrollView ${className}`}
        ref={this.ScrollViewRef}
        style={{
          ...contentContainerStyle,
          ...style,
          flexDirection: isVertical ? 'column' : 'row',
          [`overflow${isVertical ? 'Y' : 'X'}`]: scrollable ? 'scroll' : 'auto'
        }}
      >
        <div className='ScrollViewWrap' style={wrapStyle}>
          {children}
        </div>
      </div>
    )
  }
}
