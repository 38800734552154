import React from 'react';
import {useHistory} from 'react-router-dom';
import PageWrapper from '../Page/PageWrapper';
import PageTitle from '../Page/PageTitle';
import Section from '../Page/Section';
import Search from '../Search/Search';
import DashboardSummary from './DashboardSummary';
import withPermissions from '../Permissions/withPermissions';
import {useLiveIspList, useLoadMetricsOnDateRangeChange} from '@hooks/redux';
import DateRangePicker from '@reporting/sub-components/DateRangePicker/DateRangePicker';
import './Dashboard.css';

type Props = {}

function Dashboard ({}: Props) {
  const liveISPList = useLiveIspList();
  const history = useHistory();
  const {updateDateRange} = useLoadMetricsOnDateRangeChange();

  return (
    <div className='Dashboard'>
      <PageWrapper>
        <PageTitle
          rightContent={
            <div className='dateRangePickerWrap'>
              <DateRangePicker onDateRangeChange={updateDateRange} />
            </div>
          }
        >Dashboard</PageTitle>

        <Section>
          <DashboardSummary />
        </Section>

        <Search
          liveISPList={liveISPList}
          history={history}
        />
      </PageWrapper>
    </div>
  );
}

export default withPermissions(Dashboard);

