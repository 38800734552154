import React, {useState} from 'react';
import moment from 'moment-timezone';
import LightLineChart from './LightLineChart';
import {metricSpecs, SpecGroup, DashboardData} from './metricSpecs';
import {connect, ConnectedProps} from 'react-redux';
import { StyleSheet } from 'react-native';
import { RootState } from '@reducers';
import * as selectors from '@selectors';
import { useReportsDateRange, useTimeZone, useDashboardMetricData, useDailyOperationsActivityUpdatedAt } from '@hooks/redux';
import { 
  GroupBy,
  mapSpecGroupToSpecProps,
  combineSpecPropsAsGraphableData,
} from './utils';
import { simplifyNumber, formatNumberAsCommaSeparated } from '@utils/numbers';
import './DashboardSummary.css';



type Props = {} & PropsFromRedux;

function DashboardSummary ({}: Props) {
  const activityUpdatedAt = useDailyOperationsActivityUpdatedAt();
  const metricsData = useDashboardMetricData();
  const { dates } = useReportsDateRange();
  const [groupBy, setGroupBy] = useState(GroupBy.DAILY);
  const updatedAt = activityUpdatedAt 
    ? moment.tz(activityUpdatedAt, 'America/Los_Angeles').format('h:mm a') 
    : '';

  return (
    <div className='DashboardSummary'>
      <div className='top'>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            justifyContent: 'space-between'
          }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flex: 1
            }}>
            <div
              className={`option ${groupBy === GroupBy.DAILY ? 'active' : ''}`}
              onClick={() => setGroupBy(GroupBy.DAILY)}>
              <p>Daily</p>
            </div>

            <div
              className={`option ${groupBy === GroupBy.WEEKLY ? 'active' : ''}`}
              onClick={() => setGroupBy(GroupBy.WEEKLY)}>
              <p>Weekly</p>
            </div>

            <p style={styles.groupBy}>last {groupBy === GroupBy.WEEKLY ? `${simplifyNumber(dates.length / 7)} weeks` : `${dates.length} days`}</p>
          </div>

          <div>
            <p style={styles.updatedAt}>Updated at: {updatedAt}</p>
          </div>
        </div>
      </div>

      <div className='bottom'>
        <div className='rightSide'>
          {
            metricSpecs.map((specGroup, i) => {
              return (
                <SpecGroupRow
                  key={i}
                  specGroup={specGroup}
                  data={metricsData}
                  groupBy={groupBy}
                />
              )
            })
          }
        </div>
      </div>
    </div>
  );
}


function mapStateToProps (state: RootState) {

  return {
    activity: selectors.metrics.dailyOperationsActivity.selectDailyOperationsActivity(state),
    activityUpdatedAt: selectors.metrics.dailyOperationsActivity.selectDailyOperationsActivityUpdatedAt(state),
  };
}

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(DashboardSummary);

const styles = StyleSheet.create({
  updatedAt: { fontSize: '12px' },
  groupBy: { fontSize: '12px', fontStyle: 'italics' },
  statNum: { fontSize: '20px', fontWeight: 400, marginRight: '5px' },
});

type SpecGroupRowProps = {
  specGroup: SpecGroup;
  data: DashboardData;
  groupBy: GroupBy; 
}

function SpecGroupRow ({ specGroup, groupBy, data }: SpecGroupRowProps) {
  const { dates } = useReportsDateRange();
  const timeZone = useTimeZone();
  const { id, title, shared } = specGroup;
  const { perDayOnly, perWeekAverage } = shared;

  const specProps = mapSpecGroupToSpecProps(data, specGroup, groupBy, dates, !!perWeekAverage, timeZone);
  const graphableData = combineSpecPropsAsGraphableData(specProps);

  return (
    <div className='metric'>
      <div className='stats'>
        <div className='labelBar'>
          <p>{title}</p>
        </div>

        {specProps.map(({ total, average, color, totalLabel, averageLabel }, i) => {
          return (
            <SpecStats
              key={i}
              average={average}
              averageLabel={averageLabel}
              color={color}
              groupBy={groupBy}
              perDayOnly={!!perDayOnly}
              perWeekAverage={!!perWeekAverage}
              total={total}
              totalLabel={totalLabel}
            />
          )}
        )}
      </div>

      <div className='chartWrap'>
        <LightLineChart
          key={id}
          height='80%'
          width='100%'
          specs={
            specProps.map(({ dataKey, strokeColor }, i) => {
              return {
                key: i,
                dataKey,
                activeDot: shared.activeDot,
                stroke: strokeColor,
                strokeWidth: shared.strokeWidth,
                dot: shared.dot,
                type: shared.type
              }
            })
          }
          data={graphableData}
        />
      </div>
    </div>
  );
}

type SpecStatsProps = {
  perDayOnly: boolean;
  perWeekAverage: boolean;
  color: string;
  total: number;
  totalLabel?: string;
  average: number;
  averageLabel?: string;
  groupBy: GroupBy;
}

function SpecStats ({
  average,
  averageLabel,
  color,
  groupBy,
  perDayOnly,
  perWeekAverage,
  total,
  totalLabel
}: SpecStatsProps) {
  return (
    <div className='statsBar'>
      {
        !perWeekAverage &&
        <div className='statWrap'>
          <p>
            <span style={{...styles.statNum, color }}>
              {formatNumberAsCommaSeparated(total!)}
            </span>
            {` ${totalLabel ?? 'total'}`}
          </p>
        </div>
      }

      <div className='statWrap'>
        <p>
          <span style={{...styles.statNum, color: color }}>
            {formatNumberAsCommaSeparated(average!)}
          </span>
          {` ${averageLabel ?? 'avg per'}`} {groupBy === GroupBy.WEEKLY && !perDayOnly ? 'week' : 'day'}
        </p>
      </div>
    </div>
  )
}
