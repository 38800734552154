import { Dispatch } from "@reduxjs/toolkit";
import { AppGetState, AppThunkDispatch } from '@store';
import {
  updateDateActiveUsers,
  updateDailyActiveUsers,
  updateDailyActiveUsersUpdatedAt,
  resetDailyActiveUsers,
} from "@reducers/metrics/dailyActiveUsers";
import {ConsoleDailyActiveUsersService, consoleApiInitializer} from '@services';
import firebaseProvider from '@utils/firebase';
import bugsnagClient from "@utils/bugsnag";
import {getToday} from '@utils/dates';
import {createBatches} from '@utils/batch';

export function subscribeTodaysDailyActiveUsers (dispatch: Dispatch) {
  return new Promise((resolve, reject) => {
    try {
      const consoleDailyActiveUsersService = new ConsoleDailyActiveUsersService(firebaseProvider, consoleApiInitializer);

      const today = getToday();
      
      consoleDailyActiveUsersService.subscribeDate(today, (activeUsersMetrics) => {
        dispatch(updateDateActiveUsers({ date: today, activeUsersMetrics }));
  
        resolve(activeUsersMetrics);
      });
    } catch (err) {
      console.error(err);
      bugsnagClient.notify(err as Error);

      reject(err);
    }
  });
}

export function unsubscribeTodaysDailyActiveUsers () {
  const consoleDailyActiveUsersService = new ConsoleDailyActiveUsersService(firebaseProvider, consoleApiInitializer);
  
  const today = getToday();
  consoleDailyActiveUsersService.unsubscribeDate(today);
}

export function subscribeDailyActiveUsersUpdatedAt (dispatch: Dispatch) {
  return new Promise((resolve, reject) => {
    try {
      const consoleDailyActiveUsersService = new ConsoleDailyActiveUsersService(firebaseProvider, consoleApiInitializer);
    
      consoleDailyActiveUsersService.subscribeUpdatedAt(updatedAt => {
        dispatch(updateDailyActiveUsersUpdatedAt({ updatedAt }));

        resolve(updatedAt);
      });      
    } catch (err) {
      console.error(err);
      bugsnagClient.notify(err as Error);

      reject(err);
    }
  });
}

export async function fetchDailyActiveUsersUpdatedAt (dispatch: Dispatch) {
  try {
    const consoleDailyActiveUsersService = new ConsoleDailyActiveUsersService(firebaseProvider, consoleApiInitializer);
  
    const updatedAt = await consoleDailyActiveUsersService.getUpdatedAt();

    dispatch(updateDailyActiveUsersUpdatedAt({ updatedAt }));
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err as Error);
  }
}

function unsubscribeDailyActiveUsersUpdatedAt () {
  const consoleDailyActiveUsersService = new ConsoleDailyActiveUsersService(firebaseProvider, consoleApiInitializer);

  consoleDailyActiveUsersService.unsubscribeUpdatedAt();
}

export function fetchPreviousDaysDailyActiveUsers (dates: string[], forceUpdate?: boolean) {
  return async (dispatch: AppThunkDispatch, getState: AppGetState) => {
    const consoleDailyActiveUsersService = new ConsoleDailyActiveUsersService(firebaseProvider, consoleApiInitializer);

    const state = getState();

    const currentDailyActiveUsers = state.metrics.dailyActiveUsers.data;

    const filteredDays = dates.filter(date => !currentDailyActiveUsers[date]);
  
    const datesToFetch = forceUpdate ? dates : filteredDays;

    const MAX_PER_BATCH = 20;
    const batchedDates = createBatches(datesToFetch, MAX_PER_BATCH);

    batchedDates.map(async (dates) => {
      const dailyActiveUsers = await consoleDailyActiveUsersService.getDates(dates);

      dispatch(updateDailyActiveUsers({ dailyActiveUsers }));
    });
  }
}

export function clearDailyActiveUsers(dispatch: Dispatch) {
  unsubscribeTodaysDailyActiveUsers();
  unsubscribeDailyActiveUsersUpdatedAt();
  
  dispatch(resetDailyActiveUsers());
}
