import React from 'react';
import './ConsoleLink.css';
import { Link } from 'react-router-dom';

type Props = {
  to: string;
  replace?: boolean;
  text: string;
}

export default function ConsoleLink ({ to, text, replace }: Props) {
  return (
    <div className='ConsoleLink'>
      <Link
        replace={replace || false}
        to={to}>
        <div className='icon'>
          <img height='15px' src='/img/logo-red.png' />
        </div>
        <p>{text}</p>
      </Link>
    </div>
  );
}
