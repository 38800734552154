import React from 'react';

import './TopArticles.css';
import ArticleCard from '../Cards/ArticleCard';

export default class TopArticles extends React.Component {
  render () {
    const { data } = this.props;
    const { top_articles, articles } = data

    return (
      <div className='TopArticles knowledge-section'>
        <div className='list'>
          <div className='header'>
            <p>{top_articles.length} Top Article{top_articles.length !== 1 ? 's' : ''}</p>
          </div>

          {
            top_articles.length
              ? top_articles.map(articleId => {
                const article = articles[articleId];

                return (
                  <ArticleCard article={article} />
                )
              })
              : <div style={{ padding: '10px' }}>
                <p>No articles have been added to the top articles list yet.</p>
              </div>
          }
        </div>
      </div>
    );
  }
}
