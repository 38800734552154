import React from "react";
import "./InputSelect.css";

export type SelectOption = {
  name: string;
  value: string | number;
};

type Props = {
  id?: string;
  label?: string;
  labelStyle?: React.CSSProperties;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  options: SelectOption[];
  optionStyle?: React.CSSProperties;
  selectStyle?: React.CSSProperties;
  value: string | number | null | undefined;
};

export default function InputSelect({
  id,
  label,
  labelStyle,
  onChange,
  options,
  optionStyle,
  selectStyle,
  value,
}: Props) {
  return (
    <div className="InputSelect">
      {label && (
        <label htmlFor={id} style={labelStyle}>
          {label}
        </label>
      )}

      <select id={id} onChange={onChange} style={selectStyle}>
        {(options || []).map((option, i) => {
          return (
            <option
              key={`option-${i}`}
              style={optionStyle}
              selected={option.value === value}
              value={option.value}
            >
              {option.name}
            </option>
          );
        })}
      </select>
    </div>
  );
}
