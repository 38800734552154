import React from 'react';
import moment from 'moment-timezone';
import CalendarList from './CalendarList';
import SignupItem from './SignupItem';
import ScrollView from '../Page/ScrollView';
import {TrialSubscription} from '@reducers/subscriptions/trials';
import {
  useSignups,
  useTrialSubscriptions,
  useLiveIspList,
  useTotalSignups
} from '@hooks/redux';
import {formatCalendarDate} from '@utils/dates';
import './AllSignups.css';

type Props = {
  setFocusedISP: (activeIsp: string | null) => void;
  lastNDays: number;
}

export default function AllSignups ({ setFocusedISP, lastNDays }: Props) {
  const totalSignups = useTotalSignups();
  const signups = useSignups();
  const trials = useTrialSubscriptions();
  const liveISPList = useLiveIspList();

  const trialsLT = (trials || []).reduce<Record<string, TrialSubscription>>((acc, subscription) => {
    const ISPID = subscription && subscription.metadata && subscription.metadata.ISPID;

    if (ISPID) {
      acc[ISPID] = subscription;
    }

    return acc;
  }, {});

  return (
    <React.Fragment>
      <div className='cohort'>
        <p style={{ fontSize: '12px' }}>{`Signups in the last ${lastNDays} days.`}</p>

        <p style={{ fontSize: '12px' }}>
          <span style={{ margin: '0 5px' }}>{totalSignups} ISP</span>
        </p>
      </div>

      <ScrollView direction='vertical' height='700px'>
        <CalendarList
          labelColor='#006DAB'
          data={Object.keys(signups).map(date => {
            return {
              label: formatCalendarDate(date),
              items: Object.keys(signups[date] || {})
                .map(ISPID => {
                  const profile = (liveISPList || {})[ISPID];
                  const trial = trialsLT[ISPID] || null;

                  return { id: ISPID, ISPID, profile, trial };
                })
                .filter(entry => !!entry.profile)
                .sort((a, b) => {
                  const aStartedAt = a.profile.metadata.createdAt || 0;
                  const bStartedAt = b.profile.metadata.createdAt || 0;

                  if (moment(aStartedAt).isBefore(moment(bStartedAt))) {
                    return 1;
                  }
                  else {
                    return -1;
                  }
                })
            };
          })
          }
          onItemClick={setFocusedISP}
          ListItem={SignupItem}
        />
      </ScrollView>
    </React.Fragment>
  );
}
