import React from "react";
import moment from "moment-timezone";
import DriversFraction from "./DriversFraction";
import WorkAreasFraction from "./WorkAreasFraction";
import { LiveIspProfile } from "@reducers/liveIspList";
import { IspCancellationReason } from "@reducers/cancellationReasons";
import "./CancellationSignupInfo.css";

type Props = {
  profile: LiveIspProfile | null;
  cancellationReason: IspCancellationReason;
};

export default function CancellationReasonInfo(props: Props) {
  const { profile, cancellationReason } = props;
  const { cancelledAt, subscribedRoutes } = cancellationReason;

  const ending = moment(cancelledAt!).calendar(null, {
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    nextWeek: "MMM Do",
    lastDay: "[Yesterday]",
    lastWeek: "MMM Do",
    sameElse: "MMM Do",
  });

  return (
    <div className="CancellationReasonInfo">
      <div className="info trial" style={{ minWidth: "50px", flex: 1, display: "flex", alignItems: 'flex-end' }}>
        <p style={{ color: "#EA7D14" }}>
          {`Subscription cancelled `}
          {ending}
        </p>
      </div>

      <div className="info subscriptionQty" style={{ minWidth: "35px" }}>
        <p style={{ color: "rgb(22, 142, 50)", margin: 0 }}>
          ${(subscribedRoutes ?? 0) * 20}
        </p>
      </div>

      {!!profile && (
        <div className="info workAreas" style={{ minWidth: "70px" }}>
          <WorkAreasFraction profile={profile} />
        </div>
      )}

      {!!profile && (
        <div
          className="info driversAndAuthorizedUsers"
          style={{ minWidth: "90px" }}
        >
          <DriversFraction profile={profile} />
        </div>
      )}
    </div>
  );
}
