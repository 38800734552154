import { createSelector } from "reselect";
import { RootState } from "@reducers";
import { getToday } from "@utils/dates";

const selectDailyActiveUsers = (state: RootState) => state.metrics.dailyActiveUsers.data;

const selectTodaysDailyActiveUsersMetric = createSelector(
  [selectDailyActiveUsers],
  (dailyActiveUsers) => {
    const today = getToday();

    return dailyActiveUsers[today]?.weekly ?? 0;
  }
);

export {
  selectDailyActiveUsers,
  selectTodaysDailyActiveUsersMetric,
};
