import { Dispatch } from "@reduxjs/toolkit";
import {
  updatePastDue,
  updatePastDueFailed,
  resetPastDue,
  PastDueSubscriptions,
} from "@reducers/subscriptions/pastDue";
import api from "@api";
import bugsnagClient from "@utils/bugsnag";

export async function fetchPastDueSubscriptions(dispatch: Dispatch) {
  try {
    const response = await api.request<PastDueSubscriptions>(`subscriptions/past-due`);

    const subscriptions = response.data ?? [];

    dispatch(updatePastDue({ subscriptions }));
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err as Error);
    
    dispatch(updatePastDueFailed());
  }
}

export function clearPastDueSubscriptions(dispatch: Dispatch) {
  dispatch(resetPastDue());
}
