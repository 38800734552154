import React from 'react';

import './Categories.css';
import Category from './Category';
import InputText from '../../Page/InputText';
import Button from '../../Page/Button';
import Spinner from '../../common/Spinner';

export default class Categories extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      newCategory: '',
      openCategory: null
    };

    this.submitCategory = this.submitCategory.bind(this);
    this.openCategory = this.openCategory.bind(this);
  }

  async submitCategory (e) {
    e.preventDefault();

    try {
      this.setState({ addingCategory: true })

      if (this.state.newCategory.includes('/')) {
        throw new Error('Category name cannot include any "/"');
      }

      await this.props.addCategory(this.state.newCategory);
    }
    catch(err) {
      this.props.addError(err.message);
    }

    this.setState({ newCategory: '', addingCategory: false });
  }

  async removeCategory (category) {
    const { articles } = this.props.data;

    for (const id in articles) {
      const article = articles[id];

      if (article.categories.includes(category)) {
        return this.props.addError('Detach all the articles from the category first.');
      }
    }

    try {
      this.setState({ operatingOn: category });

      await this.props.deleteCategory(category);
    }
    catch(err) {
      this.props.addError(err.message);
    }

    this.setState({ operatingOn: null });
  }

  openCategory (category) {
    this.setState(prevState => {
      return {
        openCategory: prevState.openCategory === category ? null : category
      };
    });
  }

  render () {
    const { data = {} } = this.props;
    const { categories, articles } = data;

    console.log(this.props);

    return (
      <div className='Categories knowledge-section'>
        <div className='list'>
          <div className='header'>
            <p>
              {categories.length} Categor{categories.length !== 1 ? 'ies' : 'y'}
            </p>

            <div className='new'>
              <form>
                <InputText
                  id='new-category'
                  type='text'
                  value={this.state.newCategory}
                  onChange={(e) => this.setState({ newCategory: e.target.value })}
                  placeholder='Add a new category'
                />

                <Button
                  color='#168E32'
                  onClick={this.state.newCategory.length
                    ? this.submitCategory
                    : null
                  }>
                  {
                    !this.state.addingCategory
                      ? 'Add'
                      : <Spinner color='white' />
                  }
                </Button>
              </form>
            </div>
          </div>

          {
            categories.length
              ? categories.map(category => {
                return (
                  <Category
                    history={this.props.history}
                    openCategory={this.openCategory}
                    open={this.state.openCategory === category}
                    operatingOn={this.state.operatingOn === category}
                    category={category}
                    articles={Object.values(articles).filter(article => {
                      return article.categories.includes(category);
                    })}
                    deleteCategory={() => this.removeCategory(category)}
                  />
                );
              })
              : <div style={{ padding: '10px' }}>
                <p>No categories added yet.</p>
              </div>
          }
        </div>
      </div>
    );
  }
}
