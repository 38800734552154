import moment from 'moment-timezone';

import { Range, DateRange } from './calendarTypes';

export function rangeBounds (date: moment.Moment, range: Range) {
  let start, end;

  if (range === "year") {

    start = date.clone().month(0).day(15);
    end = date.clone().endOf("year");
    
  } else {

    start = date.clone().startOf("month").startOf("week");
    end = date.clone().endOf("month").endOf("week");

  }
  return { start, end, current: date.clone() };
}

export function series (start: number, end: number) {
  const span = [];

  let current = start;
  while (current <= end) {
    span.push(current);
    current += 1;
  }

  return span;
}

export function inRange (date: moment.Moment, dateRange: DateRange) {
  if (date.isSameOrAfter(dateRange.start, 'day')) {
    if (dateRange.end) {
      if (date.isSameOrBefore(dateRange.end, 'day')) {
        return true;
      } else { 
        return false;
      }
    } else if (date.isSame(dateRange.start, 'day')) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function getDimensions(ref: React.RefObject<HTMLElement>) {
  if (ref && ref.current) {
    return {
      height: ref.current.scrollHeight,
      width: ref.current.scrollWidth
    };
  } else {
    return { height: 0, width: 0 };
  }
}