import { createSelector } from "reselect";
import moment from 'moment-timezone';
import { RootState } from "@reducers";
import { ConvertedSubscription } from "@reducers/subscriptions/converted";
import { selectSignups } from "../signups";
import { getLastNDates } from "@utils/dates";
import { Status } from "@reducers/subscriptions/converted";
import {selectTimeZone} from '../baseSelectors';

const selectConvertedSubscriptions = (state: RootState) =>
  state.subscriptions.converted.data;

const selectConvertedSubscriptionsLoaded = (state: RootState) =>
  state.subscriptions.converted.status === Status.UPDATED;

const selectTrialConversionRate = createSelector(
  [selectConvertedSubscriptions, selectSignups],
  (conversions, signups) => {
    const FOUR_WEEKS = 27; // days
    const days = getLastNDates(FOUR_WEEKS);
    const previous14Days = days.slice(-21, -7);

    const conversionsByOperation = conversions.reduce<
      Record<string, ConvertedSubscription>
    >((acc, sub) => {
      if (sub.metadata.ISPID) {
        acc[sub.metadata.ISPID] = sub;
      } else {
        console.log(`subscription is missing ISPID`, sub);
      }

      return acc;
    }, {});

    const profilesCreatedPrevious14Days = previous14Days
      .map((date) => {
        const daySignups = signups[date];

        if (daySignups) return Object.keys(daySignups);
        else {
          return [];
        }
      })
      .flat();

    const operationConversions = profilesCreatedPrevious14Days.filter(
      (operationId) => {
        return conversionsByOperation[operationId];
      }
    );

    return operationConversions.length / profilesCreatedPrevious14Days.length;
  }
);

const selectConvertedDailyTotals = createSelector(
  [selectConvertedSubscriptions, selectTimeZone],
  (converted, timeZone) => {
    const conversionsByDate = converted.map<[string, ConvertedSubscription]>(conversion => {
      const { trial_end, created } = conversion;
      const time = (trial_end || created) * 1000;
      const date = moment.tz(time, timeZone).format(DATE_KEY);

      return [date, conversion];
    });

    const dailyTotals = conversionsByDate.reduce<Record<string, number>>((acc, [date, sub]) => {
      if (!acc[date]) acc[date] = 0;

      acc[date] += 1;

      return acc;
    }, {});

    return dailyTotals;
  }
);

export { selectConvertedSubscriptions, selectConvertedDailyTotals, selectTrialConversionRate, selectConvertedSubscriptionsLoaded };
