import React from 'react';
import {
  getThisWeek,
  getLastFourWeeks,
} from '@utils/dates';
import {useToday, useTimeZone} from '@hooks/redux';
import './SignupTotals.css';

type DailyTotals = Record<string, number>;

type Props = {
  label: string;
  color: string;
  totals: DailyTotals;
}

export default function SignupTotals ({ label, totals, color }: Props) {
  const today = useToday();
  const timeZone = useTimeZone();
  const thisWeekTotal = totalDays(getThisWeek(today, timeZone), totals);
  const lastFourWeeksTotal = totalDays(getLastFourWeeks(today, timeZone), totals);

  return (
    <div className='SignupTotals'>
      <p style={{ fontSize: '22px', fontWeight: 300, marginBottom: 5 }}>{label}</p>
      <p style={{ fontSize: '16px', fontWeight: 400, color }}>This week: {thisWeekTotal}</p>
      <p style={{ fontSize: '16px', color }}>Last 4 weeks: {lastFourWeeksTotal}</p>
    </div>
  )
}

function totalDays (dates: string[], dailyTotals: DailyTotals) {
  let total = 0;

  for (const date of dates) {
    const dailyTotal = dailyTotals[date];

    total += dailyTotal ?? 0;
  }

  return total;
}