import { Dispatch } from "@reduxjs/toolkit";
import {
  updateStations,
  updateStation,
  resetStations,
  Stations,
} from "@reducers/stations/productStations";
import bugsnagClient from '@utils/bugsnag';
import api from "@api";

export async function fetchStationsWithAdvocates(dispatch: Dispatch): Promise<Stations> {
  try {
    const response = await api.request<Stations>(`stations/advocate`);

    const stations = response.data ?? {};

    dispatch(updateStations({ stations }));

    return stations;
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err as Error);

    return {};
  }
}

export async function fetchHdOnlyStations(dispatch: Dispatch): Promise<Stations> {
  try {
    const response = await api.request<Stations>(`stations/hd-only`);

    const stations = response.data ?? {};

    dispatch(updateStations({ stations }));

    return stations;
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err as Error);

    return {};
  }
}

export async function fetchCoLocationStations(dispatch: Dispatch): Promise<Stations> {
  try {
    const response = await api.request<Stations>(`stations/co-location`);

    const stations = response.data ?? {};

    dispatch(updateStations({ stations }));

    return stations;
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err as Error);

    return {};
  }
}

export function clearProductStations(dispatch: Dispatch) {
  dispatch(resetStations());
}
