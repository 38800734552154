import React from 'react';
import './AddStation.css';

import api from '../../utils/api';

import InputText from '../Page/InputText';
import InputTime from '../Page/InputTime';
import InputLocation from '../Page/InputLocation';
import Section from '../Page/Section';
import Button from '../Page/Button';

export default class AddStation extends React.Component {
  constructor (props) {
    super(props);

    const old = props.station || {};

    this.state = {
      terminal: old.terminal || '',
      terminalName: old.terminalName || '',
      address1: old.address1 || '',
      city: old.city || '',
      state: old.state || '',
      sortTime: old.sortTime || null,
      dispatchTime: old.dispatchTime || null,
      flexTime: old.flexTime || null,
      postalCode: old.postalCode || '',
      latitude: old.latitude || null,
      longitude: old.longitude || null,
      old
    };

    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
    this.updateStation = this.updateStation.bind(this);
    this.updateTime = this.updateTime.bind(this);
  }

  submit (e) {
    e.preventDefault();

    if (this.props.edit) {
      this.updateStation();
    } else {
      this.saveStation();
    }
  }

  updateTime (name, moment) {
    let value = null

    if (moment) {
      value = moment.toISOString();
    }

    this.setState({ [name]: value });
  }

  saveStation () {
    const { history } = this.props;

    this.setState({ error: null }, () => {
      const {
        terminal,
        terminalName,
        address1,
        city,
        state,
        postalCode,
        latitude,
        longitude,
        dispatchTime,
        flexTime,
        sortTime
      } = this.state;

      const station = {
        terminal,
        terminalName,
        address1,
        city,
        state,
        postalCode,
        latitude,
        longitude,
        dispatchTime,
        flexTime,
        sortTime
      };

      api.submitNewStation(station)
        .then((data) => {
          const id = data[0].id;

          history.push(`stations/${id}`);
        })
        .catch(err => {
          const { response } = err;
          const errorMessage = response && response.data
            ? response.data
            : null;
          const error = errorMessage || 'An unidentified error occurred';

          if (!errorMessage) {
            console.log(err);
          }

          this.setState({ error });
        });
    });
  }

  updateStation () {
    const { station } = this.props;
    const { id } = station;

    this.setState({ error: null }, () => {
      const {
        terminal,
        terminalName,
        address1,
        city,
        state,
        postalCode,
        latitude,
        longitude,
        flexTime,
        dispatchTime,
        sortTime,
        old
      } = this.state;
      const updates = {
        terminal,
        terminalName,
        address1,
        city,
        state,
        postalCode,
        longitude,
        latitude,
        flexTime,
        dispatchTime,
        sortTime
      };

      for (const key in updates) {
        if (updates[key] === old[key]) {
          delete updates[key];
        }
      }

      if (Object.keys(updates).length) {
        api.updateStation(id, updates)
          .then(res => {
            window.location.reload();
          })
          .catch(err => {
            if (err.response && err.response.data) {
              this.setState({ error: err.response.data });
            } else {
              console.log(err);
            }
          });
      } else {
        this.setState({
          error: 'Nothing changed'
        });
      }
    });
  }

  onChange ({ target }) {
    const { name, value } = target;

    if (name === 'location' && value.longitude) {
      return this.setState({
        latitude: value.latitude,
        longitude: value.longitude
      });
    }

    this.setState({ [name]: value });
  }

  render () {
    const { edit } = this.props;
    const {
      error,
      terminal,
      terminalName,
      address1,
      city,
      state,
      postalCode,
      latitude,
      longitude,
      old,
      sortTime,
      dispatchTime,
      flexTime
    } = this.state;

    return (
      <Section title={edit ? null : 'Add Station'}>
        <div className='AddStation'>
          <form onSubmit={this.submit}>
            <div style={{ display: 'flex', marginBottom: 30 }}>
              <InputText
                label='Station ID'
                name='terminal'
                value={terminal}
                onChange={this.onChange}
                placeholder='980'
                disabled={edit || false}
              />

              <div style={{ width: 40 }} />

              <InputText
                label='Station Name'
                name='terminalName'
                value={terminalName}
                onChange={this.onChange}
                placeholder='Everett'
                inputStyle={
                  edit && terminalName !== old.terminalName
                    ? {color: '#006DAB'}
                    : null
                }
                required
              />
            </div>

            <InputText
              label='Address'
              name='address1'
              value={address1}
              onChange={this.onChange}
              placeholder='422 N Stafford St.'
              inputStyle={
                edit && address1 !== old.address1
                  ? {color: '#006DAB'}
                  : null
              }
              required
            />

            <div style={{ display: 'flex', marginTop: 20, marginBottom: 30 }}>
              <InputText
                label='City'
                name='city'
                value={city}
                onChange={this.onChange}
                placeholder='Seattle'
                inputStyle={
                  edit && city !== old.city
                    ? {color: '#006DAB'}
                    : null
                }
                required
              />

              <div style={{ width: 40 }} />

              <InputText
                label='State'
                name='state'
                value={state}
                onChange={this.onChange}
                placeholder='WA'
                inputStyle={
                  edit && state !== old.state
                    ? {color: '#006DAB'}
                    : null
                }
                required
              />

              <div style={{ width: 40 }} />

              <InputText
                label='Postal Code'
                name='postalCode'
                value={postalCode}
                onChange={this.onChange}
                placeholder='45387'
                inputStyle={
                  edit && postalCode !== old.postalCode
                    ? {color: '#006DAB'}
                    : null
                }
                required
              />
            </div>

            <div style={{ display: 'flex', marginTop: 20, marginBottom: 30 }}>
              <InputTime
                label='Flex Time'
                name='flexTime'
                value={flexTime}
                onChange={(e) => this.updateTime('flexTime', e)}
                placeholder='11 pm'
              />

              <div style={{ width: 40 }} />

              <InputTime
                label='Sort time'
                name='sortTime'
                value={sortTime}
                onChange={(e) => this.updateTime('sortTime', e)}
                placeholder='4:30 am'
              />

              <div style={{ width: 40 }} />

              <InputTime
                label='Dispatch Time'
                name='dispatchTime'
                value={dispatchTime}
                onChange={(e) => this.updateTime('dispatchTime', e)}
                placeholder='8:30 am'
              />
            </div>

            <InputLocation
              onChange={this.onChange}
              value={{
                latitude,
                longitude
              }}
              name='location'
            />

            <div style={{ height: 30 }} />

            { !!error &&
              <div className='error'>{ error }</div>
            }

            {
              edit
                ? <Button onClick={this.submit} color='#006DAB'>Update</Button>
                : <Button onClick={this.submit} color='#168E32'>Submit</Button>
            }
          </form>
        </div>
      </Section>
    );
  }
}
