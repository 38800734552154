import React from "react";
import "./CalendarList.css";

type ListItemRequiredProps = { id: string | null, onClick: () => void };

type Entry<P> = {
  label: string;
  items: P[];
};

type Props<P extends ListItemRequiredProps> = {
  data: Entry<Omit<P, 'onClick'>>[];
  labelColor: string;
  ListItem: (props: P) => JSX.Element;
  onItemClick: (id: string | null) => void;
};

export default class CalendarList<P extends ListItemRequiredProps> extends React.Component<Props<P>> {
  render() {
    const { data, labelColor, ListItem, onItemClick } = this.props;

    return (
      <div className="CalendarList">
        <div className="calendar-wrapper">
          {data.map((entry, i) => {
            const { label, items } = entry;

            if (!items || !items.length) return null;

            return (
              <div className="calendar-date" key={`${label ?? 'no-label'}-${i}`}>
                <div className="label">
                  <p style={{ color: labelColor }}>{label}</p>
                </div>

                <div className="items">
                  {items.map((item, j) => (
                    <div
                      key={`${item.id ?? 'no_id'}-${j}`}
                      className={`item-wrapper ${
                        i === data.length - 1 && j === items.length - 1
                          ? "last-item"
                          : ""
                      }`}
                    >
                      {/* @ts-ignore */}
                      <ListItem
                        onClick={() => onItemClick(item.id)}
                        {...item}
                      />
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
