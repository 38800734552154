import moment from 'moment';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {getPrecedingDateRange, enumerateDateRange} from '@reporting/utils';
import {getLastFourWeeks} from '@utils/dates';

export type DateYYYYMMDD = string;

export enum DateRangeType {
  CUSTOM = 'CUSTOM',
  // YESTERDAY = 'YESTERDAY',
  WEEK = 'WEEK',
  WEEK_TO_DATE = 'WEEK_TO_DATE',
  FOUR_WEEKS = 'FOUR_WEEKS',
  FOUR_WEEKS_TO_DATE = 'FOUR_WEEKS_TO_DATE',
  MONTH = 'MONTH',
  MONTH_TO_DATE = 'MONTH_TO_DATE',
}

export type ReportDateRange = {
  startDate: DateYYYYMMDD;
  endDate: DateYYYYMMDD;
  dates: DateYYYYMMDD[];
  comparisonDates: DateYYYYMMDD[];
  type: DateRangeType;
}

const DATE_KEY = 'YYYY-MM-DD';

const timeZone = 'America/Los_Angeles';
const yesterdayMoment = moment().subtract(1, 'day');
const yesterday = yesterdayMoment.format(DATE_KEY);
const twentyEightDaysAgo = yesterdayMoment.subtract(27, 'days').format(DATE_KEY);

const {
  startDate: comparisonStartDate,
  endDate: comparisonEndDate
} = getPrecedingDateRange(twentyEightDaysAgo, yesterday);

const initialState: ReportDateRange = {
  startDate: twentyEightDaysAgo,
  endDate: yesterday,
  dates: enumerateDateRange(twentyEightDaysAgo, yesterday),
  comparisonDates: enumerateDateRange(comparisonStartDate, comparisonEndDate),
  type: DateRangeType.FOUR_WEEKS_TO_DATE,
}

const dateRangeSlice = createSlice({
  name: 'dateRange',
  initialState,
  reducers: {
    receiveReportDateRange: (state, action: PayloadAction<{ startDate: string, endDate: string, type: DateRangeType }>) => {
      const { startDate, endDate, type } = action.payload;
      
      const { startDate: newStartDate } = getPrecedingDateRange(startDate, endDate);
      const dayBeforeStartDate = moment(startDate).subtract(1, 'day').format(window.DATE_KEY);
      const dates = enumerateDateRange(startDate, endDate);
      const comparisonDates = enumerateDateRange(newStartDate, dayBeforeStartDate);

      state.startDate = startDate;
      state.endDate = endDate;
      state.type = type;
      state.dates = dates;
      state.comparisonDates = comparisonDates;
    },
    resetDateRange: (state) => {
      state.startDate = twentyEightDaysAgo;
      state.endDate = yesterday;
      state.dates = enumerateDateRange(twentyEightDaysAgo, yesterday);
      state.comparisonDates = enumerateDateRange(comparisonStartDate, comparisonEndDate);
      state.type = DateRangeType.FOUR_WEEKS_TO_DATE;
    }
  }
});

export const {
  receiveReportDateRange,
  resetDateRange,
} = dateRangeSlice.actions;

export default dateRangeSlice.reducer;