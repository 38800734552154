import React, { CSSProperties } from 'react';
import moment from 'moment-timezone';

import { RenderDateComponent, DateRange, DateClickHandler, DateMouseOverHandler, ViewMode, ClickOrTouch } from './calendarTypes';
import { rangeBounds } from './calendarUtils';
import { MonthOfYear, DaysOfWeek } from './Labels';
import Day from './Day';
import { DATE_KEY } from '../../globalConstants';

export interface RangeProps {
  className?: string;
  style?: CSSProperties;
  selectedRange: DateRange;
  highlightedRange?: DateRange;
  highlightedStyle?: CSSProperties;
  start: moment.Moment;
  current: moment.Moment;
  end: moment.Moment;
  timeZone: string;
  onClick?: DateClickHandler;
  onMouseOver?: DateMouseOverHandler;
  onDateChange?: (event: React.SyntheticEvent, date: moment.Moment) => void;
  onRangeChange?: (event: React.SyntheticEvent, range: Range) => void;
  onDateClick?: (event: React.SyntheticEvent, date: moment.Moment) => void;
  onPeriodClick?: (event: React.SyntheticEvent, start: moment.Moment, end: moment.Moment) => void;
  onMouseDown?: DateClickHandler;
  onMouseEnter?: DateClickHandler;
  onMouseUp?: DateClickHandler;
  
  viewMode?: ViewMode;
}

const Year: React.FC<RangeProps> = ({
  end,
  onClick,
  onMouseDown,
  onMouseEnter,
  onMouseOver,
  onMouseUp,
  viewMode,
  selectedRange,
  start,
  style = {},
  timeZone,
}) => {
  const months = [];
  const startDate = start.clone();

  for (let i = 0; i < 12; i++) {
    months.push(rangeBounds(startDate, 'month'))

    startDate.add(1, 'month');
  }

  return (
    <section className="Year" style={style}>
      {months.map(({start, current, end}, i) => {

        return (
          <div className="Months" key={i}>
            { viewMode !== 'picker' && 
              <MonthOfYear
                dateOfMonth={current}
                compact
              />
            }
            <Month
              key={i}
              selectedRange={selectedRange}
              month={current}
              timeZone={timeZone}
              start={start}
              current={current}
              end={end}
              onClick={onClick}
              onMouseOver={onMouseOver}
              onMouseEnter={onMouseEnter}
              onMouseUp={onMouseUp}
              onMouseDown={onMouseDown}
              viewMode={viewMode || "year"}
            />
          </div>
        );
      })}
    </section>
  );
};
export { Year };



interface MonthProps<BodyProps, HeaderProps, OverlayProps> extends RangeProps {
  month?: moment.Moment;
  renderDateHeader?: RenderDateComponent<HeaderProps>;
  renderDateHeaderProps?: HeaderProps;
  renderDate?: RenderDateComponent<BodyProps>;
  renderDateProps?: BodyProps;
  renderDateOverlay?: RenderDateComponent<OverlayProps>;
  renderDateOverlayProps?: OverlayProps;
}

interface MonthState {
  weeks: { start: moment.Moment, end: moment.Moment }[];
}

class Month<BodyProps, HeaderProps, OverlayProps> extends React.PureComponent<MonthProps<BodyProps, HeaderProps, OverlayProps>, MonthState> {
  constructor (props: MonthProps<BodyProps, HeaderProps, OverlayProps>) {
    super(props);

    this.state = {
      weeks: generateWeeks(props.start, props.end)
    };
  }

  componentDidUpdate (prevProps: MonthProps<BodyProps, HeaderProps, OverlayProps>) {
    const { start: prevStart, end: prevEnd } = prevProps;
    const { start, end } = this.props;

    if (!start.isSame(prevStart) || !end.isSame(prevEnd)) {
      this.setState({ weeks: generateWeeks(start, end )});
    }
  }  

  render () {
    const {
      current,
      onClick,
      onMouseDown,
      onMouseEnter,
      onMouseOver,
      onMouseUp,
      renderDate,
      renderDateProps,
      renderDateHeader,
      renderDateHeaderProps,
      renderDateOverlay,
      renderDateOverlayProps,
      selectedRange,
      style = {},
      timeZone,
      viewMode = 'month',
    } = this.props;

    const { weeks } = this.state;

    return (
      <section className="Month" style={style}>
        { viewMode !== 'picker' && <DaysOfWeek compact={viewMode !== 'month'} /> }
  
        {weeks.map(({ start, end }, i, arr) => {
          return (
            <Week
              key={i}
              selectedRange={selectedRange}
              start={start}
              end={end}
              current={current}
              timeZone={timeZone}
              onClick={onClick}
              onMouseOver={onMouseOver}
              onMouseEnter={onMouseEnter}
              onMouseUp={onMouseUp}
              onMouseDown={onMouseDown}
              toolTipFloatDown={i < arr.length / 2}
              viewMode={viewMode}
              renderDate={renderDate}
              renderDateProps={renderDateProps}
              renderDateHeader={renderDateHeader}
              renderDateHeaderProps={renderDateHeaderProps}
              renderDateOverlay={renderDateOverlay}
              renderDateOverlayProps={renderDateOverlayProps}
            />
          );
        })}
      </section>
    );
  }
}

export { Month };

function generateWeeks (start: moment.Moment, end: moment.Moment) {
  const weeks = [];
  const startDate = start.clone();
  let endOfMonthlyCalendar = end;

  // Monthly calendar is sized for 6 weeks
  // however a month may fit into 5 weeks. 
  // To keep calendar size stable, we always render 6.
  // Check for case and add additional week if necessary.
  if (end.clone().diff(start, "days") < 41) {
    endOfMonthlyCalendar = end.clone().add(7, "days");
  }

  while (startDate.isBefore(endOfMonthlyCalendar)) {
    weeks.push({
      start: startDate.clone(),
      end: startDate
        .clone()
        .add(6, "days")
        .endOf("day")
    });

    startDate.add(7, "days");
  }

  return weeks;
}

interface WeekProps<BodyProps, HeaderProps, OverlayProps> extends RangeProps {
  toolTipFloatDown?: boolean;
  viewMode?: ViewMode;
  month?: moment.Moment;
  renderDateHeader?: RenderDateComponent<HeaderProps>;
  renderDateHeaderProps?: HeaderProps;
  renderDate?: RenderDateComponent<BodyProps>;
  renderDateProps?: BodyProps;
  renderDateOverlay?: RenderDateComponent<OverlayProps>;
  renderDateOverlayProps?: OverlayProps;
}

interface WeekState {
  days: moment.Moment[];
}

class Week<BodyProps, HeaderProps, OverlayProps> extends React.PureComponent<WeekProps<BodyProps, HeaderProps, OverlayProps>, WeekState> {
  constructor (props: WeekProps<BodyProps, HeaderProps, OverlayProps>) {
    super(props);

    this.state = {
      days: generateDays(props.start, props.end)
    }
  }

  componentDidUpdate (prevProps: WeekProps<BodyProps, HeaderProps, OverlayProps>) {
    const { start: prevStart, end: prevEnd } = prevProps;
    const { start, end } = this.props;

    if (!start.isSame(prevStart) || !end.isSame(prevEnd)) {
      this.setState({ days: generateDays(start, end )});
    }
  }

  render () {
    const {
      style = {},
      selectedRange,
      timeZone,
      start,
      current,
      end,
      onClick,
      onMouseOver,
      onMouseDown,
      onMouseEnter,
      onMouseUp,
      toolTipFloatDown = false,
      viewMode = 'month',
      renderDate,
      renderDateProps,
      renderDateHeader,
      renderDateHeaderProps,
      renderDateOverlay,
      renderDateOverlayProps,
    } = this.props;

    const { days } = this.state;

    return (
      <section className="Week" style={style}>
        {days.map((date, i) => {
          return (
            <Day
              key={date.format(DATE_KEY)}
              selectedRange={selectedRange}
              date={date}
              month={current}
              timeZone={timeZone}
              onClick={onClick}
              onMouseOver={onMouseOver}
              onMouseEnter={onMouseEnter}
              onMouseUp={onMouseUp}
              onMouseDown={onMouseDown}
              toolTipFloatDown={toolTipFloatDown}
              viewMode={viewMode}
              renderBody={renderDate}
              renderBodyProps={renderDateProps}
              renderHeader={renderDateHeader}
              renderHeaderProps={renderDateHeaderProps}
              renderOverlay={renderDateOverlay}
              renderOverlayProps={renderDateOverlayProps}
            />
          );
        })}
      </section>
    );
  }
}

export { Week };

function generateDays (start: moment.Moment, end: moment.Moment) {
  const days = [];
  const startDate = start.clone();

  while (startDate.isBefore(end)) {
    days.push(startDate.clone());

    startDate.add(1, "days");
  }

  return days;
} 
