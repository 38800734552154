import React from 'react';

import TagDueDate from '../../TagDueDate/TagDueDate';

export default class StationCard extends React.Component {

  render () {
    const station = this.props.item.station ? this.props.item.station[0] : {};

    return (
      <div className={`ISPItem ${this.props.cardClasses}`} onClick={() => this.props.setActiveItem(this.props.list, this.props.item, 'station')}>
        <div className='section'>
          <div className='isp-name'>
            <p style={{ marginRight: '5px' }}>
              {station.terminal}
              <span style={{ marginLeft: '5px' }}>
                {
                  station.city
                    ? station.city
                    : null
                }
                {station.city && station.state ? `,` : ''}
                {
                  station.state
                    ? ` ${station.state}`
                    : null
                }
              </span>
            </p>
          </div>
        </div>

        <div className='section'>
          <div className='isp-activity'>
            <p style={{ color: '#666' }}>{station.numIsps} ISPs</p>
          </div>

          <div className='isp-status'>
            <TagDueDate
              tag={this.props.item}
              onClick={(e) => {
                e.stopPropagation();

                this.props.setActiveItem(this.props.list, this.props.item, 'tag');
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}
