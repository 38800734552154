import { Dispatch } from "@reduxjs/toolkit";
import {
  updateStationsIsps,
  removeStationsIsps as _removeStationsIsps,
  resetStationsIsps,
  StationIsp,
} from "@reducers/stations/stationsIsps";
import BugsnagClient from '@utils/bugsnag';
import api from "@api";

export async function fetchStationsIsps(dispatch: Dispatch, stationId: string): Promise<StationIsp[]> {
  try {
    const response = await api.request<StationIsp[]>(`stations/isps/${stationId}`)

    const stationIsps = response.data ?? [];

    dispatch(updateStationsIsps({ stationId, stationIsps }));

    return stationIsps;
  } catch (err) {
    console.error(err);
    BugsnagClient.notify(err as Error);

    return [];
  }
}

export function removeStationsIsps (dispatch: Dispatch, stationId: string) {
  dispatch(_removeStationsIsps({ stationId }));
}

export function clearStationsWithAdvocates(dispatch: Dispatch) {
  dispatch(resetStationsIsps());
}
