import React, { CSSProperties } from 'react';
import { View, StyleSheet, SafeAreaView } from 'react-native';

import { CardGroupWrapper } from './Card';
import { TabMenuState, Tabs, TabContent } from './TabMenu';
import ScrollViewDisappearingBorder from './ScrollViewDisappearingBorder';
import { Viewport } from '@reducers/viewport';

type FullHeightTabs<C extends TabContent> = { useFullHeight?: boolean } & Tabs<C>;

interface Props<T, C extends TabContent> {
  startingTabId?: string;
  viewport?: Viewport;
  tabs: FullHeightTabs<C>;
  tabContent: C;
  forceContent?: (string | JSX.Element | null)[] | JSX.Element | JSX.Element[] | number | string | string[] | undefined | null;
  useFullHeight?: boolean;
  onTabChange: (tabId: keyof C) => void;
  style?: CSSProperties;
}

interface State<C> {
  activeTabId: keyof C | 'grid';
}

export default class TopDrawer<T extends {}, C extends TabContent> extends React.Component<Props<T, C>, State<C>> {
  constructor (props: Props<T, C>) {
    super(props);

    this.state = {
      activeTabId: props.startingTabId || 'grid'
    };

    this.setActiveTab = this.setActiveTab.bind(this);
  }

  setActiveTab (activeTabId: keyof C) {
    const { onTabChange } = this.props;

    if (onTabChange) {
      onTabChange(activeTabId);
    }

    this.setState({ activeTabId });
  }

  render () {
    const {
      viewport,
      tabs,
      tabContent,
      forceContent,
      useFullHeight,
      style,
    } = this.props;
    const { activeTabId } = this.state;

    const maxHeight = ((viewport || {}).height);
    const addtlStyles = maxHeight
      // @ts-ignore
      ? useFullHeight || ((tabs || {})[activeTabId] || {}).useFullHeight
        ? { height: maxHeight }
        : { maxHeight }
      : {}

    return (
      <View className='TopDrawer' style={[styles.bodyContainer, addtlStyles, style]}>
        <View style={{ flex: 1, flexDirection: 'column' }}>
          <SafeAreaView>
            <View style={{height: 50}} />

            <CardGroupWrapper>
              <TabMenuState
                tabs={tabs}
                activeTabId={activeTabId}
                onTabPress={this.setActiveTab}
              />
            </CardGroupWrapper>
          </SafeAreaView>

          <ScrollViewDisappearingBorder style={{flex: 1}}>
            <CardGroupWrapper>
              { forceContent || (tabContent || {})[activeTabId] || null }
            </CardGroupWrapper>
          </ScrollViewDisappearingBorder>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  bodyContainer: {
    backgroundColor: '#f5f5f5',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 99,
    borderColor: '#DDD',
    borderBottomWidth: 1
  }
});
