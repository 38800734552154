import { Dispatch } from "@reduxjs/toolkit";
import {
  updateLiveIspList,
  resetLiveIspList,
  LiveIspList,
} from "@reducers/liveIspList";
import firebaseProvider from '@utils/firebase';

export async function subscribeLiveIspList(dispatch: Dispatch) {
  return new Promise((resolve, reject) => {
    try {
      const database = firebaseProvider.getConsoleDefaultDB();
  
      const ref = database.ref('profiles/data');
  
      ref.on('value', snap => {
        const liveIspList: LiveIspList = snap.val() ?? {};
  
        dispatch(updateLiveIspList({ liveIspList }));

        resolve(liveIspList);
      });
    } catch (err) {
      console.error(err);

      reject(err);
    }
  });
}

export function unsubscribeLiveIspList (dispatch: Dispatch) {
  try {
    const database = firebaseProvider.getConsoleDefaultDB();

    const ref = database.ref('profiles/data');

    ref.off();

    dispatch(resetLiveIspList());
  } catch (err) {
    console.error(err);

    throw err;
  }
}
