import React, { CSSProperties } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';

import Icon from '../Icon/Icon';

import Card from './Card';
import { CheckButton } from '../common/ButtonRow';

export interface Props {
  id: string;
  title: string;
  titleColor?: string;
  text: string;
  text2?: string;
  text2Color: string;
  isActive: boolean;
  onPress?: (id: string) => void;
  style?: CSSProperties;
  iconName?: string;
  iconColor?: string;
  iconSize?: number;
  checkable?: boolean;
  disabled?: boolean;
}

export default function DetailedListCard (props: Props) {
  const {
    id,
    title,
    text,
    isActive,
    onPress,
    titleColor,
    style,
    checkable,
    iconName,
    iconColor,
    iconSize,
    text2,
    text2Color,
    disabled
  } = props;

  const pressProps = onPress && !disabled
    ? {onPress: () => onPress(id)}
    : {};

    return (
      <TouchableOpacity {...pressProps}>
        <Card
          padded
          style={[styles.choiceCard, style, disabled ? styles.disabled : null]}
        >
          <React.Fragment>
            {!!iconName && (
              <View style={styles.iconWrap}>
                <Icon
                  name={iconName}
                  size={iconSize || 25}
                  color={iconColor || titleColor}
                />
              </View>
            )}

            <View style={styles.choiceCard}>
              <View style={{ flex: 1 }}>
                <Text
                  style={[styles.choiceTitle, { color: titleColor || "black" }]}
                >
                  {title}
                </Text>

                <Text style={styles.choiceText}>{text}</Text>

                <React.Fragment>
                  {!!text2 && (
                    <Text
                      style={[
                        styles.choiceText,
                        styles.choiceText2,
                        {
                          color: disabled
                            ? "#B82025"
                            : text2Color || titleColor || "#444",
                        },
                      ]}
                    >
                      {text2}
                    </Text>
                  )}
                </React.Fragment>
              </View>
            </View>

            {checkable && (
              <CheckButton isChecked={isActive} style={{ marginLeft: 10 }} />
            )}
          </React.Fragment>
        </Card>
      </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
  choiceTitle: {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: 3
  },
  choiceCard: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center'
  },
  disabled: {
    backgroundColor: '#f5f5f5'
  },
  choiceText2: {
    fontWeight: 500,
    marginTop: 5
  },
  iconWrap: {
    paddingRight: 10
  }
});
