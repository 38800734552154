import React from 'react';

import './CreateList.css';
import SectionButton from '../Page/Button';
import InputText from '../Page/InputText';
// import Icon from '../Icon/Icon';
import api from '../../utils/api';

export default class CreateList extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      creating: false,
      name: null
    }

    this.createList = this.createList.bind(this);
    this.reset = this.reset.bind(this);
  }

  createList () {
    const { name } = this.state;

    if (!name) return;

    api.post(`sales-lists`, { name })
      .then(res => {
        this.setState({ creating: false }, () => {
          this.props.updateListOrder(res.data.id);
          this.props.fetchSalesLists();
        })
      });
  }

  reset () {
    this.setState({ creating: false });
  }

  render () {
    return (
      <div className='CreateList'>
        {
          this.state.creating === false
            ? <div className='default'>
              <SectionButton
                style={{ backgroundColor: 'white', border: '1px solid #DDD', padding: '5px 10px' }}
                onClick={() => this.setState({ creating: true })}>
                <p style={{ color: 'black' }}>New List</p>
              </SectionButton>
            </div>
            : <div className='input'>
              <InputText
                placeholder='Add a name...'
                inputStyle={{ padding: '5px 10px' }}
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />

              <SectionButton
                style={{ backgroundColor: 'rgb(22, 142, 50)', border: '1px solid #DDD', padding: '5px 10px', marginLeft: '5px' }}
                onClick={() => this.createList()}>
                <p style={{ color: 'white' }}>Create</p>
              </SectionButton>

              <SectionButton
                style={{ backgroundColor: 'rgb(185, 12, 15)', border: '1px solid #DDD', padding: '5px 10px', marginLeft: '5px' }}
                onClick={() => this.setState({ creating: false })}>
                <p style={{ color: 'white' }}>Cancel</p>
              </SectionButton>
            </div>
        }
      </div>
    )
  }
}
