import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Card from './Card';

interface Props {
  message?: string;
}

export default function NoDataCard ({ message }: Props) {
  return (
    <Card style={styles.card}>
      <View style={styles.missingMessage}>
        <Text style={styles.missingText}>{ message || 'No data found'}</Text>
      </View>
    </Card>
  );
}

const styles = StyleSheet.create({
  card: {
    width: '100%',
    padding: 0,
    margin: 0,
    border: 'solid 1px #ddd',
  },
  missingMessage: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 50,
  },
  missingText: {
    fontSize: 24,
    color: '#444',
    marginBottom: 10
  }
});
