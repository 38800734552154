import React from 'react';

import './CategoryCard.css';

export default function CategoryCard (props) {
  const { category, articles } = props;
  return (
    <div className='CategoryCard'>
      <p className='name'>{category}</p>

      {
        articles
          ? <p className='numArticles'>{articles.length} article{articles.length !== 1 ? 's' : ''}</p>
          : null
      }
    </div>
  );
}
