import React from 'react';

import './MultiToggle.css';

/**
 * type Toggle = {
 *   key: string;
 *   label: string;
 *   color: string;
 *   disabled?: boolean;
 *   onClick: () => void;
 * }
 */

 /**
  * type Toggles = (Toggle | null)[];
  */
export default class MultiToggle extends React.Component {
  constructor (props) {
    super(props);
  }

  render () {
    const { toggles = [], activeKey } = this.props;

    return (
      <div className='MultiToggle'>
        {
          toggles.map(toggle => {
            if (!toggle) return null;

            return (
              <MultiToggleButton toggle={toggle} activeKey={activeKey} />
            )
          })
        }
      </div>
    )
  }
}

function MultiToggleButton ({ toggle, activeKey }) {
  const { label, key, color, disabled, onClick } = toggle;

  const on = key === activeKey;

  return (
    <div
      className={`toggle-btn btn ${on ? 'active' : 'hover'}`}
      style={{
        marginRight: '2px',
        backgroundColor: disabled ? '#bbb' : on ? color : '#f5f5f5',
        color: on ? 'white' : disabled ? '#888' : '#555'
      }}
      onClick={(disabled || on) ? null : onClick}>
      <p>{label}</p>
    </div>
  )
}