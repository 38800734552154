import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/remote-config';

export type FirebaseApp = firebase.app.App;
export type FirebaseUser = firebase.User;
export type DataSnapShot = firebase.database.DataSnapshot;
export type FirebaseDatabase = firebase.database.Database;
export type FirebaseAuth = firebase.auth.Auth;

export default firebase;