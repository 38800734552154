import React from 'react';
import moment from 'moment-timezone';

import './ISPCard.css';
import salesCycleStatus, { getDisplayName, getDisplayColor } from '../../../utils/salesCycleStatus';
import { getLastActiveDate } from '../../../utils/isp';
import PipelineStatus from '../../PipelineStatus/PipelineStatus';
import TagDueDate from '../../TagDueDate/TagDueDate';

export default class ISPCard extends React.Component {

  render () {
    const isp = this.props.item.isp ? this.props.item.isp[0] : {};
    const status = salesCycleStatus(isp);
    const statusName = getDisplayName(status);
    const color = getDisplayColor(status);

    isp.profile = (this.props.liveISPList || {})[isp.firebaseId] || null;

    const { profile } = isp;
    const city = profile && profile.city ? profile.city : isp.city;
    const state = profile && profile.state ? profile.state : isp.state;
    const stationID = profile && profile.station ? profile.station.stationID : null;
    const { groundRoutes = 0, hdRoutes = 0, suppRoutes = 0, additionalRoutes = 0 } = isp;
    const projectedConversionRate = !Number.isNaN(parseInt(isp.projectedConversionRate, 10))
      ? isp.projectedConversionRate
      : 100;
    // console.log(projectedConversionRate);
    const projectedAccountValue = (groundRoutes + hdRoutes + suppRoutes + additionalRoutes) * (projectedConversionRate / 100) * 20;
    const today = moment.tz('America/Los_Angeles').format('YYYY-MM-DD');
    const lastActiveDate = getLastActiveDate(isp.firebaseId, this.props.usage);

    return (
      <div className={`ISPItem ${this.props.cardClasses}`} onClick={() => this.props.setActiveItem(this.props.list, this.props.item, 'isp')}>
        <div className='section'>
          <div className='isp-name'>
            <p style={{ marginRight: '5px' }}>
              {isp.entityName}
            </p>

            {
              city || state || stationID
                ? <p style={{ color: '#999', fontSize: '10px' }}>
                  {city}
                  {city && state ? ' ' : ''}
                  {state}
                  {stationID ? <span>{stationID}</span> : null}
                </p>
                : null
            }
          </div>

          <div className='account-value'>
            <p>${projectedAccountValue || 0}</p>
          </div>
        </div>

        <div className='section'>
          <div className='isp-activity'>
            {
              lastActiveDate && lastActiveDate === today
                ? <div style={{ height: '10px', width: '10px', borderRadius: '5px', backgroundColor: 'green' }}/>
                : lastActiveDate
                  ? <p style={{ color: '#EA7D14' }}>{moment(lastActiveDate, 'YYYY-MM-DD').format('MM-DD-YYYY')}</p>
                  : null
            }
          </div>

          <div className='isp-status'>
            {
              statusName && statusName !== 'No Status'
                ? <PipelineStatus
                  backgroundColor={color}
                  text={statusName}
                  fontSize='9px'
                />
                : null
            }

            <TagDueDate
              tag={this.props.item}
              onClick={(e) => {
                e.stopPropagation();

                this.props.setActiveItem(this.props.list, this.props.item, 'tag')
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}
