import React from 'react';

import './SectionButton.css';

export default function SectionButton (props) {
  return (
    <button className='SectionButton' onClick={props.onClick} style={props.style}>
      {props.children}
    </button>
  )
}
