/**
 * Capitalizes first letter of each word
 * @param {string} words
 * @returns {string}
 */
const titleize = (words: string) => {
  if (typeof words === "string" && words.length > 1) {
    return words
      .trim()
      .split(" ")
      .map(word => (word ? word[0].toUpperCase() + word.slice(1) : word))
      .join(" ");
  } else {
    return words;
  }
};

/**
 * Converts a Firebase style collection of keyed records to an iterable
 * array and inserts the key as a property of the object within the collection
 * { keyName: { ... }} -> [{ ..., keyName: key}]
 * @param {Object} obj Firebase-formatted object collection
 * @param {string} keyName name of key inserted into object, defaults to 'firebaseId'
 * @returns {Array} [{ ..., keyName}]
 */
const arrayify = (obj: Record<string, any>, keyName = "firebaseId") => {
  if (typeof obj === "object" && obj !== null) {
    return Object.keys(obj).map(key => ({ ...obj[key], [keyName]: key }));
  } else {
    return [];
  }
};

export { titleize, arrayify };
