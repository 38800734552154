import React, {useState, useEffect} from 'react';
import {RouteComponentProps} from 'react-router';
import PageWrapper from '../Page/PageWrapper';
import PageTitle from '../Page/PageTitle';
import Section from '../Page/Section';
import LifecycleMetrics from './LifecycleMetrics';
import ISPModal from '../ISPModal/ISPModal';
import Lifecycle from './Lifecycle';
import Referrals from './Referrals/Referrals';
import withPermissions from '../Permissions/withPermissions';
import api from '../../utils/api';
import SignupMetricsSummaries from './SignupMetricsSummaries';
import { useLiveIspList } from '@hooks/redux';
import './Signups.css';

type Props = {
  history: RouteComponentProps['history'];
  fetchUsers: (userIds: string[]) => void;
  partials: any;
};

function Signups ({ partials, history }: Props) {
  const liveISPList = useLiveIspList();
  const [lastNDays, setLastNDays] = useState(30);
  const [focusedIsp, setFocusedISP] = useState<null | string>(null);
  const trialingTags = useTrialingTagsOnLoad();

  return (
    <div className='Signups'>
      <PageWrapper>
        <PageTitle>Signups</PageTitle>

        <Section>
          <SignupMetricsSummaries />
        </Section>

        <Section>
          <LifecycleMetrics lastNDays={lastNDays} />

          <Lifecycle
            lastNDays={lastNDays}
            trialingTags={trialingTags}
            setFocusedISP={setFocusedISP}
          />
        </Section>

        <Section title='Referrals'>
          <Referrals history={history} />
        </Section>

        {
          focusedIsp
            ? <ISPModal
                profile={liveISPList[focusedIsp]}
                setActiveItem={setFocusedISP}
              />
            : null
        }
      </PageWrapper>
    </div>
  );
}

export default withPermissions(Signups);

function useTrialingTagsOnLoad () {
  const [trialingTags, setTrialingTags] = useState<Record<string, string[]>>({});

  useEffect(() => {  
    api.request<Record<string, string[]>>(`tags/trialing`)
      .then(res => setTrialingTags(res.data));
  }, []);

  return trialingTags;
}