import React, { CSSProperties, useRef } from 'react';
import moment from 'moment-timezone';

import { RenderDateComponent, DateRange, DateClickHandler, DateMouseOverHandler, ViewMode, ClickOrTouch } from './calendarTypes';
import { inRange } from './calendarUtils';

import './Day.css';

interface DayProps <BodyProps, HeaderProps, OverlayProps> {
  className?: string;
  style?: CSSProperties;
  date: moment.Moment;
  selectedRange: DateRange;
  highlightedRange?: DateRange;
  highlightedStyle?: CSSProperties;
  month?: moment.Moment;
  timeZone: string;
  renderHeaderProps?: any;
  renderHeader?: RenderDateComponent<HeaderProps>;
  renderBodyProps?: any;
  renderBody?: RenderDateComponent<BodyProps>;
  renderOverlayProps?: any;
  renderOverlay?: RenderDateComponent<OverlayProps>;
  onClick?: DateClickHandler;
  onMouseOver?: DateMouseOverHandler;
  toolTipFloatDown?: boolean;
  viewMode?: ViewMode;
  onDateChange?: (event: React.SyntheticEvent, date: moment.Moment) => void;
  onRangeChange?: (event: React.SyntheticEvent, range: Range) => void;
  onDateClick?: (event: React.SyntheticEvent, date: moment.Moment) => void;
  onPeriodClick?: (event: React.SyntheticEvent, start: moment.Moment, end: moment.Moment) => void;
  onMouseDown?: (event: ClickOrTouch, date: moment.Moment) => void;
  onMouseEnter?: (event: ClickOrTouch, date: moment.Moment) => void;
  onMouseUp?: (event: ClickOrTouch, date: moment.Moment) => void;
}

class Day<BodyProps, HeaderProps, OverlayProps> extends React.Component<DayProps<BodyProps, HeaderProps, OverlayProps>> {
  constructor (props: DayProps<BodyProps, HeaderProps, OverlayProps>) {
    super(props);

    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
  }

  shouldComponentUpdate (nextProps: DayProps<BodyProps, HeaderProps, OverlayProps>) {
    const { timeZone, date, month, selectedRange, renderHeaderProps, renderBodyProps, renderOverlayProps } = nextProps;
    const { 
      timeZone: prevTimeZone,
      date: prevDate,
      month: prevMonth,
      selectedRange: prevSelectedRange,
      renderHeaderProps: prevRenderHeaderProps, 
      renderBodyProps: prevRenderBodyProps, 
      renderOverlayProps: prevRenderOverlayProps, 
    } = this.props;

    return (
      timeZone !== prevTimeZone ||
      !date.isSame(prevDate) ||
      !month?.isSame(prevMonth) ||
      propsHaveChanged(prevSelectedRange, selectedRange) ||
      propsHaveChanged(prevRenderBodyProps, renderBodyProps) ||
      propsHaveChanged(prevRenderHeaderProps, renderHeaderProps) ||
      propsHaveChanged(prevRenderOverlayProps, renderOverlayProps)
    )
  }

  handleMouseDown (event: ClickOrTouch) {
    this.props.onMouseDown?.(event, this.props.date)
  }

  handleMouseEnter (event: ClickOrTouch) {
    this.props.onMouseEnter?.(event, this.props.date)
  }

  handleMouseUp (event: ClickOrTouch) {
    this.props.onMouseUp?.(event, this.props.date)
  }

  handleTouchStart (event: ClickOrTouch) {
    this.props.onMouseDown?.(event, this.props.date)
  }

  handleTouchMove (event: ClickOrTouch) {
    this.props.onMouseEnter?.(event, this.props.date)
  }

  handleTouchEnd (event: ClickOrTouch) {
    this.props.onMouseUp?.(event, this.props.date)
  }

  render () {
    const { 
      style = {},
      renderBodyProps,
      renderBody = () => null,
      renderHeaderProps,
      renderHeader = () => null,
      renderOverlayProps,
      renderOverlay,
      date,
      selectedRange,
      highlightedRange,
      highlightedStyle,
      month,
      timeZone,
      viewMode = 'month'
    } = this.props;

    const isToday = date.isSame(moment().tz(timeZone), "day");
    const selected = inRange(date, selectedRange);
    const activeMonth = date.isSame(month || selectedRange.start, "month");
    const highlighted = highlightedRange ? inRange(date, highlightedRange) : false;

    const backgroundColor = isToday
      ? selected
        ? '#88B9D4'
        : "#A9D4B3"
      : activeMonth
      ? selected
        ? '#D9E9F2'
        : "#F9F9F9"
      : selected
        ? '#E6EEF2'
        : "#F2F2F2";

    const color = isToday 
      ? selected 
        ? "#0477B4"
        : "#009B23" 
      : activeMonth 
        ? "#888" 
        : "#bbb";

    const activeStyle = {
      backgroundColor,
      color,
      ...style,
      ...(highlighted && highlightedStyle ? highlightedStyle : {}),
    };

    const activeDateStyle = {
      color,
      fontWeight: isToday ? 500 : 300,
      fontSize: viewMode === 'month' ? 14 : viewMode === 'year' ? 10 : 8
    };
    
    return (
      <div
        className={`Day ${viewMode}`}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseUp={this.handleMouseUp}
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
        onTouchEnd={this.handleTouchEnd}
      >
        <div className="DayWrap" style={activeStyle}>
          { renderOverlay && renderOverlay(date, renderOverlayProps) }
          <header>
            {viewMode === 'month' && <React.Fragment>{renderHeader(date, renderHeaderProps)}</React.Fragment> }
            <p style={activeDateStyle}>
              <span className='today'>{isToday ? "Today " : ""}</span>
              {date.date() === 1 ? date.format('MMM D') : date.format("D")}
            </p>
          </header>
  
          { viewMode === 'month' && <div className='bodyWrap'>{renderBody(date, renderBodyProps)}</div> }
        </div>
      </div>
    );
  }
}

export default Day;

function propsHaveChanged (previous: any, current: any) {
  if (typeof previous !== typeof current) return true;

  if (typeof previous === 'object') {
    if (previous === null) {
      return previous !== current;
    } else {
      if (Array.isArray(previous) !== Array.isArray(current)) {
        return true;
      } else if (Array.isArray(previous) && Array.isArray(current)) {
        if (previous.length !== current.length) return true;

        return previous.every((val, i) => current[i] === val);
      } else {
        if (Object.keys(previous).length !== Object.keys(current).length) return true;

        for (const key in previous) {
          if (current[key] !== previous[key]) return true;
        }

        return false;
      }
    }
  } else {
    return previous !== current;
  }
}

interface BlankDayProps {
  style?: CSSProperties;
}

const BlankDay: React.FC<BlankDayProps> = ({style = {}}) => {
  return (
    <div className='Day Blank' style={style} />
  )
}

export {BlankDay};