import React from 'react';

import './RecipientItem.css';
import Icon from '../../Icon/Icon';

export default function RecipientItem (props) {
  const { item, selected } = props;
  const icon = {
    'STATION': 'city',
    'ISP': 'truck'
  }[item.type];
  const color = {
    'STATION': '#006DAB',
    'ISP': '#168E32'
  }[item.type];

  return (
    <div
      className={`ListItem ${selected ? 'active' : ''}`}
      onClick={props.onClick || null}>
      <Icon
        name={icon}
        color={color}
        size={15}
      />
      <p>{item.label}</p>
    </div>
  );
}
