import React, { useState } from 'react';
import { useHistory } from 'react-router';
import PageWrapper from '../Page/PageWrapper';
import PageTitle from '../Page/PageTitle';
import Search from '../Search/Search';
import Section from '../Page/Section';
import AddISP from './AddISP';
import UsageSummary from './UsageSummary';
import ISPModal from '../ISPModal/ISPModal';
import './ISPsDash.css';
import withPermissions from '../Permissions/withPermissions';
import { useLiveIspList } from '@hooks/redux';

type Props = {}

function ISPsDash ({}: Props) {
  const liveISPList = useLiveIspList();
  const history = useHistory();
  const [activeISP, setActiveISP] = useState<string | null>(null);

  return (
    <div className='ISPsDash'>
      <PageWrapper>
        <PageTitle>ISPs</PageTitle>

        <Section>
          <UsageSummary
            setActiveISP={setActiveISP}
          />
        </Section>

        <Search
          history={history}
          ispsOnly
          liveISPList={liveISPList}
          title='Search ISPs'
          placeholder='ISP name, owner name'
        />

        {
          activeISP
            ? <ISPModal
                profile={liveISPList[activeISP]}
                setActiveItem={setActiveISP}
              />
            : null
        }

        <AddISP history={history} />
      </PageWrapper>
    </div>
  );
}

export default withPermissions(ISPsDash);
