import React from "react";

import "./TooltipWrapper.css";

function Tooltip(props) {
  const { label, message, onClick, onMouseOver } = props;
  
  return (
    <div className="Tooltip" onClick={onClick} onMouseOver={onMouseOver}>
      {label && <header className="label">{label}</header>}
      <p className="message">{message}</p>
      <div className="arrow" />
    </div>
  );
}

// Note: incomplete, state, will see further work. Not ready for deployment!!!
// FIXME: address onMouseOver/Out infinite loop which occurs when the tooltip
// is displayed upon hover, but triggers as off when the mouse transfers from
// the component to the tooltip
// Maybe use actual CSS instead of react?

// FIXME: address z-index issue when inside of an overflow: hidden/scroll container
// The container will cutoff the tooltip, regardless of the z-index
export default class TooltipWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = { display: true, stayVisible: true };

    this.toggleTooltip = this.toggleTooltip.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
  }

  toggleTooltip(e) {
    e.preventDefault();
    const { stayVisible } = this.state;
    console.log('click!', stayVisible);
    this.setState({ display: !stayVisible, stayVisible: !stayVisible });
  }

  handleMouseOver(e) {
    console.log('hover enter!');
    this.setState({ display: true })
  }

  handleMouseOut(e) {
    console.log('hover leave!');
    this.setState({ display: false })
  }

  render() {
    const { className, label, message, children} = this.props;
    const { display, stayVisible } = this.state;
  
    return (
      <button 
        className={`TooltipWrapper ${className}`}
        onClick={this.toggleTooltip}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
      >
        {stayVisible || display ? <Tooltip onClick={this.toggleTooltip} onMouseOver={this.handleMouseOver} message={'Test'} label={'test'} /> : null}
        {children}
      </button>
    );
  }
}

