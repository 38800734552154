import React, { CSSProperties } from 'react';

import './View.css';

interface NativeLayout {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface NativeEvent {
  layout: NativeLayout;
}

interface MockNativeEvent {
  nativeEvent: NativeEvent;
}

interface Source {
  html: string;
}

interface Props {
  style?: CSSProperties,
  pointerEvents?: string | null;
  className?: string;
  children?: (string | JSX.Element)[] | JSX.Element | JSX.Element[] | string | string[] | undefined | null;
  onPress?: (event?: React.SyntheticEvent) => void;
  onClick?: (event?: React.SyntheticEvent) => void;
  snapOnScroll?: boolean;
  scrollSnapAlign?: string;
  scrollRef?: any;
  onLayout?: (nativeEvent?: MockNativeEvent) => void;
  originWhiteList?: string[];
  source: Source;
}

interface State {
  onLayoutCalled: boolean;
  refSet: boolean;
}

export default class WebView extends React.Component<Props, State> {
  ViewRef: React.RefObject<HTMLDivElement>; 
  settingRef: boolean;
  settingLayout: boolean;

  constructor (props: Props) {
    super(props);

    this.state = {
      onLayoutCalled: false,
      refSet: false
    };

    this.settingRef = false;
    this.settingLayout = false;
    this.ViewRef = React.createRef<HTMLDivElement>();
  }

  componentDidMount () {
    if (this.props.scrollRef && !this.settingRef) this.setRef();

    if (this.props.onLayout && !this.settingLayout) this.onLayout();
  }

  componentDidUpdate () {
    if (this.props.scrollRef && !this.settingRef) this.setRef();

    if (this.props.onLayout && !this.settingLayout) this.onLayout();
  }

  setRef = () => {
    this.settingRef = true;
    if (!this.state.refSet && this.ViewRef) {

      this.setState({ refSet: true }, () => {
        this.settingRef = false;
        this.props.scrollRef(this.ViewRef);
      })
    }
  }

  onLayout () {
    this.settingLayout = true;

    if (!this.state.onLayoutCalled && this.ViewRef && this.ViewRef.current) {
      const el = this.ViewRef.current;
      const height = el.scrollHeight;
      const width = el.scrollWidth;
      const x = el.offsetLeft - el.scrollLeft + el.clientLeft;
      const y = el.offsetTop - el.scrollTop + el.clientTop;

      const mockNativeEvent: MockNativeEvent = {
        nativeEvent: {
          layout: {
            x,
            y,
            width,
            height
          }
        }
      }
  
      this.setState({ onLayoutCalled: true }, () => {
        this.settingLayout = false;
        this.props.onLayout && this.props.onLayout(mockNativeEvent);
      })
    }
  }

  // onPointerDown = (e) => {
  //   e.preventDefault();

  //   this.setState({ pressStartTime: Date.now() })
  // }

  // onPointerUp = (e) => {
  //   const { onClick, onPress, onLongPress, delayLongPress } = this.props;

  //   e.preventDefault();

  //   if (
  //     onLongPress &&
  //     Date.now - this.state.pressStartTime >= delayLongPress
  //   ) {
  //     onLongPress();
  //   } else if (onPress) {
  //     onPress();
  //   } else if (onClick) {
  //     onClick();
  //   }

  // }
    
    
  render () {
    const {
      style = {},
      pointerEvents = null,
      className = "",
      children = null,
      onPress = () => null,
      onClick = () => null,
      snapOnScroll = false,
      scrollSnapAlign = 'center',
      source
    } = this.props;

    return (
      <div 
        className={`View ${className}`}
        // @ts-ignore
        style={{
          ...style,
          ...(pointerEvents ? { pointerEvents: pointerEvents } : {}),
          ...(snapOnScroll ? { scrollSnapAlign } : {})
        }}
        onClick={onPress || onClick}
        ref={this.ViewRef}
      >
        {
          !!source && !!source.html && <div dangerouslySetInnerHTML={{ __html: source.html}} />
        }
      </div>
    );
  }
}