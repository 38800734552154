import React from 'react';
import './CurrencyIcon.css';

const currencies = {
  usd: 'currency-usd',
  gbp: 'currency-gbp',
  eur: 'currency-eur',
};

export default function CurrencyIcon ({ currency }) {
  return currencies[currency]
    ? <div className='CurrencyIcon icon'>
        <i className={`mdi mdi-${currencies[currency]}`} />
        { currency.toUpperCase() }
      </div>
    : <div className='currencyWrap'>{currency}</div>
}
