import moment from 'moment-timezone';

const DATE_KEY = 'YYYY-MM-DD';

export default function getDateLabel (
  startDate: string, 
  endDate: string, 
  timeZone: string
) {
  const todayMoment = moment.tz(timeZone);
  const startMoment = moment.tz(startDate, DATE_KEY, timeZone);
  const endMoment = moment.tz(endDate, DATE_KEY, timeZone);

  // const isSingleDay = selectedRangeType === DateRangeType.YESTERDAY;

  let startFormat = 'MMM Do';
  let endFormat = 'MMM Do';

  if (startMoment.isSame(endMoment, 'month')) {
    endFormat = 'Do';
  }

  if (!startMoment.isSame(todayMoment, 'year')) {
    startFormat += ', YYYY';
  }

  if (!endMoment.isSame(todayMoment, 'year')) {
    endFormat += ', YYYY';
  }

  const humanizedStartDate = startMoment.format(startFormat);
  const humanizedEndDate = endMoment.format(endFormat);

  return `${humanizedStartDate}-${humanizedEndDate}`;
}