import {RootState} from '@reducers';

export function selectColorPack(state: RootState) {
  return state.colorMode.colorPack;
}

export function selectTimeZone(state: RootState) {
  return state.timeZone.data;
}

export function selectFeatureFlags(state: RootState) {
  return state.featureFlags.data;
}
