import React, { CSSProperties, useState } from 'react';
import {Styles, mergeStyles} from '@native/utils';
// @ts-ignore
import Markdown from 'react-remarkable';
import './Icon.css';

type Props = {
  className?: string;
  name: string | undefined;
  size?: number;
  color?: string;
  style?: React.CSSProperties;
  /**
   * unused prop, only for react-native compatibility
   */
  isCommunity?: boolean;
}

export default function Icon ({ className, name, size = 20, color = 'red', style }: Props) {
  const iconColor = color ? { color } : {}
  const iconStyle = { fontSize: size, ...iconColor, ...style }

  return (
    <i className={`${className} mdi mdi-${name}`} style={mergeStyles(iconStyle)} />
  );
}

type ButtonProps = {
  onClick: () => void;
  style?: React.CSSProperties;
} & Props;

export function IconButton ({ name, size = 20, color, onClick, style }: ButtonProps) {
  return (
    <div className='IconButton' style={{ padding: 5, width: size + 14, backgroundColor: color, ...style }} onClick={onClick}>
      <Icon name={name} size={size} color={'#fff'} />
    </div>
  )
}


interface WrappedStyle extends Props {
  wrapStyle?: CSSProperties;
  wrapSize?: number;
  onPress?: (event?: React.SyntheticEvent) => void;
}
export function WrappedIcon ({ className = '', name = '', style = {}, size = 20, color = '', wrapStyle = {}, wrapSize, onPress }: WrappedStyle) {
  return (
    <div 
      className={`WrappedIcon ${className}`} 
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '100%',
        height: wrapSize || Math.floor(size * 1.2),
        width: wrapSize || Math.floor(size * 1.2),
        ...wrapStyle
      }}
      onClick={onPress}
    >
      <Icon name={name} style={style} size={size} color={color} />
    </div>
  )
}

type Side = 
| 'top' 
| 'right' 
| 'bottom' 
| 'left' 
| 'topRight'
| 'bottomRight' 
| 'bottomLeft' 
| 'topLeft';

interface HoverIconProps {
  className?: string;
  disableClickaway?: boolean;
  icon: string;
  size?: number;
  color?: string;
  hoverColor?: string;
  hoverIcon?: string;
  hoverContentSide?: Side;
  hoverContentStyle?: CSSProperties;
  iconStyle?: CSSProperties;
  hoverIconStyle?: CSSProperties;
  children?: (string | JSX.Element | null)[] | JSX.Element | JSX.Element[] | number | string | string[] | undefined | null;
  wrapStyle?: CSSProperties;
  hoverWrapStyle?: CSSProperties;
  hoverContentOffset?: number;
  hoverContentHorizontalOffset?: number;
  hoverContentVerticalOffset?: number;
}

const HoverIcon: React.FC<HoverIconProps> = ({
  className = '', 
  disableClickaway = false,
  icon = '', 
  hoverIcon = '',
  wrapStyle = {}, 
  hoverWrapStyle = {},
  iconStyle = {},
  hoverIconStyle = {},
  size = 20, 
  color = '',
  hoverColor,
  children,
  hoverContentStyle = {},
  hoverContentSide = 'right',
  hoverContentOffset = 0,
  hoverContentHorizontalOffset = 0,
  hoverContentVerticalOffset = 0,
}) => {
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);

  const sideStyle = (() => {
  const side = hoverContentSide;
  const offset = hoverContentOffset;
  const halfOffset = Math.floor(offset / 2);

  switch (side) {
    case ('top'): return { bottom: (hoverContentVerticalOffset || offset) + size };
    case ('bottom'): return { top: (hoverContentVerticalOffset || offset) + size };
    case ('right'): return { left: (hoverContentHorizontalOffset || offset) + size};
    case ('left'): return { right: (hoverContentHorizontalOffset || offset) + size };
    case ('topRight'): return { left: (hoverContentHorizontalOffset || halfOffset) + size, bottom: (hoverContentVerticalOffset || halfOffset) + size };
    case ('bottomRight'): return { left: (hoverContentHorizontalOffset || halfOffset) + size, top: (hoverContentVerticalOffset || halfOffset) + size };
    case ('bottomLeft'): return { right: (hoverContentHorizontalOffset || halfOffset) + size, top: (hoverContentVerticalOffset || halfOffset) + size };
    case ('topLeft'): return { right: (hoverContentHorizontalOffset || halfOffset) + size, bottom: (hoverContentVerticalOffset || halfOffset) + size };
  }
  })()

  return (
    <React.Fragment>
      { !disableClickaway && (hover || open) && 
        <div 
          className='HoverIconClickaway' 
          onClick={(event) => {
            event.stopPropagation();
            setHover(false)
            setOpen(false);
          }}
        />
      }
      <div 
        className={`HoverIcon ${className}`} 
        style={{ 
          height: size + 2, 
          width: size + 2, 
          ...wrapStyle, 
          ...((hover || open) ? hoverWrapStyle : {})
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={(event) => {
          event.stopPropagation();

          if (open) {
            setHover(false);
            setOpen(false);
          } else {
            setOpen(true);
          }
        }}
      >
        <Icon 
          name={
            (hover || open)
            ? !!hoverIcon
              ? hoverIcon
              : icon 
            : icon
          } 
          size={size} 
          color={
            (hover || open)
            ? !!hoverColor
              ? hoverColor
              : color 
            : color
          } 
          style={{
            ...iconStyle, 
            ...(hover ? hoverIconStyle : {})
          }}
        />

        {
          (hover || open) && children &&
          <div className='hoverContent' style={{...sideStyle, ...hoverContentStyle}}>
            { children }
          </div>
        }
      </div>
    </React.Fragment>
  )
}

export {HoverIcon};

interface TooltipIcon {
  className?: string;
  style?: CSSProperties;
  hoverContentStyle?: CSSProperties;
  children?: (string | JSX.Element | null)[] | JSX.Element | JSX.Element[] | number | string | string[] | undefined | null;
  markdown?: string;
  size?: number;
  side?: Side;
  hide?: boolean;
  offset?: number;
  verticalOffset?: number;
  horizontalOffset?: number;
}

const TooltipIcon: React.FC<TooltipIcon> = ({className = '', size = 20, side = 'bottomRight', style = {}, children = [], markdown = '', hide = false, offset = 0, verticalOffset = 0, horizontalOffset = 0, hoverContentStyle }) => {
  if (hide) return null;
  
  return (
    <HoverIcon 
      className={`TooltipIcon ${className || ''} ${markdown ? 'markdown' : ''}`}
      icon='help-circle-outline'
      hoverIcon='help-circle'
      color='#0477B4'
      size={size}
      wrapStyle={style}
      hoverContentStyle={{
        color: 'white',
        backgroundColor: '#0477B4',
        width: 200,
        zIndex: 55,
        ...hoverContentStyle,
      }}
      hoverContentSide={side}
      hoverContentOffset={offset}
      hoverContentVerticalOffset={verticalOffset}
      hoverContentHorizontalOffset={horizontalOffset}
    >
      {markdown
        ? <Markdown source={markdown} />
        : children
      }
    </HoverIcon>
  )
}

export {TooltipIcon};