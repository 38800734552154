import { Dispatch } from "@reduxjs/toolkit";
import {
  updateConverted,
  updateConvertedFailed,
  resetConverted,
  ConvertedSubscriptions,
} from "@reducers/subscriptions/converted";
import api from "@api";
import bugsnagClient from "@utils/bugsnag";

export async function fetchTrialSubscriptions(dispatch: Dispatch) {
  try {
    const response = await api.request<ConvertedSubscriptions>(`subscriptions/converted`);

    const subscriptions = response.data ?? [];

    dispatch(updateConverted({ subscriptions }));
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err as Error);

    dispatch(updateConvertedFailed());
  }
}

export function clearTrialSubscriptions(dispatch: Dispatch) {
  dispatch(resetConverted());
}
