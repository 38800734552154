import React, { Component } from "react";
import { mergeStyles } from './utils';
import "./TouchableHighlight.css";

export default class TouchableHighlight extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pressed: false,
      pressStartTime: null
    };
  }

  click = () => {
    const { onClick, onPress } = this.props;

    if (onPress) onPress();
    else if (onClick) onClick();
  }
  

  render() {
    const {
      className = "",
      style = {},
      children = null,
    } = this.props;

    const { click, state } = this;

    return (
      <div
        className={`TouchableHighlight ${className} ${
          state.pressed ? "pressed" : ""
        }`}
        style={mergeStyles(style)}
        onClick={click}
      >
        {children}
      </div>
    );
  }
}