import React from 'react';
import './SearchResults.css';

type Props = {
  children: any;
  searching?: boolean;
  style?: React.CSSProperties;
}

export default function SearchResults ({ children, searching, style }: Props) {
  return (
    <div className='SearchResults' style={style}>
      {
        children && children.length
          ? children
          : searching === false
            ? <div className='noData'>
              No Results
            </div>
            : <div className='searching'>
              Searching...
            </div>
      }
    </div>
  );
}
