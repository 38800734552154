import { Dispatch } from "@reduxjs/toolkit";
import { AppGetState, AppThunkDispatch } from '@store';
import {
  updateTodayUsage,
  updateUsage,
  updateUsageUpdatedAt,
  updateDailyUsage,
  resetUsage,
  DailyUsage,
  Usage,
  DailyCompleteUsage,
} from "@reducers/metrics/dailyOperationsUsage";
import {ConsoleUsageService, consoleApiInitializer} from '@services';
import firebaseProvider from '@utils/firebase';
import bugsnagClient from "@utils/bugsnag";
import {getToday} from '@utils/dates';
import {createBatches} from '@utils/batch';

export function subscribeTodaysUsage (dispatch: Dispatch) {
  return new Promise((resolve, reject) => {
    try {
      const consoleUsageService = new ConsoleUsageService(firebaseProvider, consoleApiInitializer);

      const today = getToday();
      
      consoleUsageService.subscribeDate(today, (completeDailyUsage) => {
        dispatch(updateTodayUsage({ todayUsage: completeDailyUsage }));
        
        dispatch(updateDailyUsage({ date: today, dailyUsage: completeDailyUsageToDailyUsage(completeDailyUsage) }));
  
        resolve(completeDailyUsage);
      });
    } catch (err) {
      console.error(err);
      bugsnagClient.notify(err as Error);

      reject(err);
    }
  });
}

export function unsubscribeTodaysUsage () {
  const consoleUsageService = new ConsoleUsageService(firebaseProvider, consoleApiInitializer);
  
  const today = getToday();
  consoleUsageService.unsubscribeDate(today);
}

export function subscribeUsageUpdatedAt (dispatch: Dispatch) {
  return new Promise((resolve, reject) => {
    try {
      const consoleUsageService = new ConsoleUsageService(firebaseProvider, consoleApiInitializer);
    
      consoleUsageService.subscribeUpdatedAt(updatedAt => {
        dispatch(updateUsageUpdatedAt({ updatedAt }));

        resolve(updatedAt);
      });      
    } catch (err) {
      console.error(err);
      bugsnagClient.notify(err as Error);

      reject(err);
    }
  });
}

export async function fetchUsageUpdatedAt (dispatch: Dispatch) {
  const consoleUsageService = new ConsoleUsageService(firebaseProvider, consoleApiInitializer);

  const updatedAt = await consoleUsageService.getUpdatedAt();

  dispatch(updateUsageUpdatedAt({ updatedAt }));
}

function unsubscribeUsageUpdatedAt () {
  const consoleUsageService = new ConsoleUsageService(firebaseProvider, consoleApiInitializer);

  consoleUsageService.unsubscribeUpdatedAt();
}

export function fetchPreviousDaysUsage (dates: string[], forceUpdate?: boolean) {
  return async (dispatch: AppThunkDispatch, getState: AppGetState) => {
    const consoleUsageService = new ConsoleUsageService(firebaseProvider, consoleApiInitializer);

    const state = getState();

    const currentUsage = state.metrics.dailyOperationsUsage.data;

    const filteredDays = dates.filter(date => !currentUsage[date]);
  
    const datesToFetch = forceUpdate ? dates : filteredDays;

    const MAX_PER_BATCH = 20;
    const batchedDates = createBatches(datesToFetch, MAX_PER_BATCH);

    batchedDates.map(async (dates) => {
      const usage = await consoleUsageService.getDates(dates);

      dispatch(updateUsage({ usage }));
    });
  }
}

export function clearDailyUsage(dispatch: Dispatch) {
  unsubscribeTodaysUsage();
  unsubscribeUsageUpdatedAt();
  
  dispatch(resetUsage());
}

function completeDailyUsageToDailyUsage (usage: DailyCompleteUsage | null): DailyUsage {
  return {
    activeISPs: Object.keys(usage?.activeISPs ?? {}).reduce<Record<string, true>>((acc, ispId) => {
      acc[ispId] = true;

      return acc;
    }, {}),
    activeUsers: Object.keys(usage?.activeUsers ?? {}).reduce<Record<string, true>>((acc, uid) => {
      acc[uid] = true;

      return acc;
    }, {}),
    summary: {
      totalBCs: usage?.summary?.totalBCs ?? 0,
      totalDrivers: usage?.summary?.totalDrivers ?? 0,
      totalISPs: usage?.summary?.totalISPs ?? 0,
      totalUsers: usage?.summary?.totalUsers ?? 0,
    }
  }
}