import React from 'react';

import './SelectTemplate.css';
import TemplateList from './TemplateList';

export default function SelectTemplate (props) {
  return (
    <div className='SelectTemplate'>
      <h3>Select an Email Template</h3>

      <TemplateList
        activeTemplate={props.activeTemplate}
        selectTemplate={props.selectTemplate}
        templates={props.templates}
      />
    </div>
  );
}
