import React from 'react';
import './StationLink.css';
import { Link } from 'react-router-dom';

import Icon from '../Icon/Icon';
import api from '../../utils/api';

export default class ConsoleLink extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      stationLinkId: null
    }
  }

  async componentDidMount () {
    const res = await api.request(`stations/firebase/${this.props.station}`);
    const station = res.data;

    this.setState({ stationLinkId: station ? station.id : null });
  }

  render () {
    return (
      <div className={`StationLink ${this.state.stationLinkId ? 'clickable' : ''}`}>
        {
          this.state.stationLinkId
            ? <Link to={`/stations/${this.state.stationLinkId}`}>
              <div className='icon'>
                <Icon name='city' size={20} />
              </div>

              <p>{this.props.station}</p>
            </Link>
            : <div className='descriptive'>
              <Icon name='city' size={20} color='#006DAB' />

              <p>{this.props.station}</p>
            </div>
        }
      </div>
    );
  }
}
