import { Dispatch } from "@reduxjs/toolkit";
import {
  updateCanceled,
  updateCanceledFailed,
  resetCanceled,
  CanceledSubscriptions,
} from "@reducers/subscriptions/canceled";
import api from "@api";
import bugsnagClient from "@utils/bugsnag";

export async function fetchCanceledSubscriptions(dispatch: Dispatch) {
  try {
    const response = await api.request<CanceledSubscriptions>(`subscriptions/canceled`);

    const subscriptions = response.data ?? [];

    dispatch(updateCanceled({ subscriptions }));
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err as Error);

    dispatch(updateCanceledFailed());
  }
}

export function clearCanceledSubscriptions(dispatch: Dispatch) {
  dispatch(resetCanceled());
}
