export const role = {
  '0': 'None',
  '1': 'AO',
  '2': 'BC',
  '3': 'Driver'
};

export const method = {
  '0': 'None',
  '1': 'Text message',
  '2': 'Phone call',
  '3': 'Email'
}

export const time = {
  '0': 'None',
  '1': 'Morning',
  '2': 'Late morning',
  '3': 'Afternoon',
  '4': 'Evening'
}
