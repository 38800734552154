import React, { useState } from "react";
import { PortalModal } from "../Page/Modal";
import { InputText, Notice } from "@components/Page";
import { Spinner } from '@common';
import { LiveIspProfile } from "@reducers/liveIspList";
import { Button } from "@common";
import "./IspDeletionModal.css";

type Props = {
  close: () => void;
  deleteFirebaseIsp: () => void;
  deleting: boolean;
  error: string | null;
  profile: LiveIspProfile;
};

export function IspDeletionModal({ close, deleteFirebaseIsp, deleting, profile, error }: Props) {
  return (
    <PortalModal visible centerFullScreen onRequestClose={close}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: 20,
          backgroundColor: "white",
          borderRadius: 10,
          maxWidth: 400
        }}
      >
        <IspDeletionWarning />

          {error ? (
            <Notice
                severity="error"
                show
                title="Error"
                description={error}
            />
            ) : null}

        <IspDeletionConfirmation
          confirm={deleteFirebaseIsp}
          cancel={close}
          profile={profile}
          deleting={deleting}
        />
      </div>
    </PortalModal>
  );
}

function IspDeletionWarning({}) {
  return (
    <div className='IspDeletionWarning'>
      <Notice
        severity="error"
        show
        title="Warning!"
        description={"Deletion CANNOT be undone"}
      />

      <div className='WarningText'>
        <ul>
          <li>
            <p className='UserImpact'>
              Deleting a PackageRoute ISP in Firebase will remove{" "}
              <span>ALL</span> customer access to that ISP.
            </p>
          </li>

          <li>
            <p>ALL access to historical manifest data will be lost FOREVER.</p>
          </li>

          <li>
            <p>EVERY user will lose access to this ISP.</p>
          </li>

          <li>
            <p>User accounts will not be deleted.</p>
          </li>

          <li>
            <p>
              The ISP's subscription must be canceled prior to deleting. Otherwise this will error.
            </p>
          </li>

          <li>
            <p>
              If the ISP is trialing, no charges will be applied to their
              subscription.
            </p>
          </li>
        </ul>

        <p style={{ fontStyle: 'oblique', fontSize: 14, color: '#888' }}>
          Note: this tool is primarily intended to remove duplicate signups.
        </p>
          
        <p style={{ fontStyle: 'oblique', fontSize: 14, color: '#888' }}>
          Deletion will not remove the Console ISP record. To delete the Console ISP record,
          go to the ISP page, scroll down to the bottom, and select "Delete from Console"
        </p>
      </div>
    </div>
  );
}

type ConfirmProps = {
  confirm: () => void;
  cancel: () => void;
  profile: LiveIspProfile;
  deleting: boolean;
};
function IspDeletionConfirmation({ confirm, cancel, deleting, profile }: ConfirmProps) {
  const [ispName, setIspName] = useState("");
  const [confirmed, setConfirmed] = useState(false);

  const profileName = profile?.CSPName || "Unknown Operation Name";

  return (
    <div style={{ width: '100%' }}>
      <div style={{ marginBottom: 10 }}>
        <p>
          {`Confirm deletion by typing ISP name:`}
        </p>
        <p style={{ color: '#888', marginBottom: 10 }}>{profileName}</p>
        <InputText onChange={(e) => setIspName(e.target.value)} />
      </div>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {deleting
          ? <Spinner />
          : (
            <React.Fragment>
              <Button color="#178E32" onPress={cancel}>Cancel</Button>
      
              {profileName === ispName ? (
                !confirmed ? (
                  <Button color="#B90C0E" onPress={() => setConfirmed(true)}>Confirm Deletion</Button>
                ) : (
                  <Button color="#B90C0E" onPress={() => confirm()}>Delete</Button>
                )
              ) : null
              }
            </React.Fragment>
          )
        }
      </div>
    </div>
  );
}
