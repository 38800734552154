import {combineReducers} from 'redux';

import cancellationReasons from './cancellationReasons';
import consoleUsers from './consoleUsers';
import colorMode from './colorMode';
import firebaseUser from './firebaseUser';
import featureFlags from './featureFlags';
import liveIspList from './liveIspList';
import metrics from './metrics';
import permissions from './permissions';
import referrals from './referrals';
import reports from './reports';
import salesCampaignCollections from './salesCampaignCollections';
import salesLists from './salesLists';
import signups from './signups';
import stations from './stations';
import subscriptions from './subscriptions';
import timeZone from './timeZone';
import users from './users';
import viewport from './viewport';

const rootReducer = combineReducers({
  cancellationReasons,
  consoleUsers,
  colorMode,
  firebaseUser,
  featureFlags,
  liveIspList,
  metrics,
  permissions,
  referrals,
  reports,
  salesCampaignCollections,
  salesLists,
  signups,
  stations,
  subscriptions,
  timeZone,
  users,
  viewport,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
