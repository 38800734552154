import React, { CSSProperties } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import Icon from '../Icon/Icon';

interface Props {
  onPress: () => void;
  darkVersion?: boolean; 
  style?: CSSProperties;
}

export default function DrawerCloseButton (props: Props) {
  const { onPress, darkVersion, style } = props;
  const darkStyles = darkVersion ? styles.menuContainerDark : null;

  return (
    <TouchableOpacity className='DrawerCloseButton' pointerEvents='auto' onPress={onPress} style={[styles.menuContainer, darkStyles, {width: 36, marginTop: 4, paddingLeft: 1}, style]}>
      <Icon name='close' size={28} color={darkVersion ? 'white' : '#444'} />
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  menuContainer: {
    height: 36,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row'
  },
  menuContainerDark: {
    borderRadius: 18,
    backgroundColor: '#444',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#DDD'
  }
});
