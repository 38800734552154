import { Dispatch } from "@reduxjs/toolkit";
import {
  updateTrials,
  updateTrialsFailed,
  resetTrials,
  TrialSubscriptions,
} from "@reducers/subscriptions/trials";
import api from "@api";
import bugsnagClient from "@utils/bugsnag";

export async function fetchTrialSubscriptions(dispatch: Dispatch) {
  try {
    const response = await api.request<TrialSubscriptions>(`subscriptions/trialing`);

    const subscriptions = response.data ?? [];

    dispatch(updateTrials({ subscriptions }));
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err as Error);

    dispatch(updateTrialsFailed());
  }
}

export function clearTrialSubscriptions(dispatch: Dispatch) {
  dispatch(resetTrials());
}
