import React, { CSSProperties } from 'react';
import { ScrollView2 as ScrollView, View } from 'react-native';

interface Props {
  children?: (string | JSX.Element | null)[] | JSX.Element | JSX.Element[] | number | string | string[] | undefined | null;
  style?: CSSProperties;
  wrapStyle?: CSSProperties;
  scrollViewStyle?: CSSProperties;
}

interface State {
  showTopBorder: boolean;
}

export default class ScrollViewDisappearingBorder extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props);

    this.state = {
      showTopBorder: false
    };
  }


  render () {
    const { children = [], style = {}, scrollViewStyle = {}, wrapStyle } = this.props;
    const { showTopBorder } = this.state;

    return (
      <View style={{ height: '100%', width: '100%', borderColor: showTopBorder ? '#DDD' : '#F5f5f5', borderTopWidth: 1, flex: 1 , ...style}}>
        <ScrollView
          style={{...scrollViewStyle, flex: 1}}
          wrapStyle={wrapStyle}
        >
          { children }
        </ScrollView>
      </View>
    );
  }
}
