import React from 'react';
import moment from 'moment-timezone';

import { DownloadRow } from '../Isp/IspSummary';
import { useDailyOperationsUsage } from '@hooks/redux';
import './DownloadHistory.css';

type Props = {
  dates: string[];
  operationId: string;
}

export default function DownloadHistory({ dates = [], operationId }: Props) {
  const usage = useDailyOperationsUsage();

  return (
    <div className='DownloadHistory'>
      <div className='history-row'>
        <div className='history-header' />
        {
          dates.sort((a, b) => {
            const aMom = moment(a, 'YYYY-MM-DD');
            const bMom = moment(b, 'YYYY-MM-DD');

            if (aMom.isBefore(bMom)) return -1;
            if (bMom.isBefore(aMom)) return 1;

            return 0;
          }).map(date => {
            return <div className='history-cell'>
              <p style={{ fontSize: '12px', fontWeight: 500 }}>
                {moment(date, 'YYYY-MM-DD').format('dd')}
              </p>
            </div>
          })
        }
      </div>

      <DownloadRow
        data={dates.reduce<Record<string, boolean>>((acc, date) => {
          const dailyUsage = usage[date];
          const operationUsage = dailyUsage?.activeISPs[operationId];

          acc[date] = !!operationUsage;
          
          return acc;
        }, {})
        }
        label='Active'
      />
    </div>
  )
}
