import React, {useState} from 'react';
import Icon from '../Icon/Icon';
import { NavItemWithPermissions } from './NavItem';
import './Nav.css';
import { PermissionSetKey } from '@components/Permissions/withPermissions';

const navItems = [
  {
    to: '/',
    icon: 'speedometer',
    title: 'Dashboard',
    permissionType: PermissionSetKey.DASHBOARD,
  },
  {
    to: '/stations',
    icon: 'city',
    title: 'Stations',
    permissionType: PermissionSetKey.STATIONS_DASH,
  },
  {
    to: '/isps',
    icon: 'truck',
    title: 'ISPs',
    permissionType: PermissionSetKey.ISPS_DASHBOARD,
  },
  {
    to: '/subscriptions',
    icon: 'credit-card',
    title: 'Subscriptions',
    permissionType: PermissionSetKey.SUBSCRIPTIONS,
  },
  {
    to: '/users',
    icon: 'account',
    title: 'Users',
    permissionType: PermissionSetKey.USERS_DASH,
  },
];

const salesItems = [
  {
    to: '/signups',
    icon: 'new-box',
    title: 'Signups',
    permissionType: PermissionSetKey.SIGNUPS,
  },
  {
    to: '/sales',
    icon: 'radar',
    title: 'Sales Dev',
    permissionType: PermissionSetKey.SALES_LISTS,
  },
  {
    to: '/pipeline',
    icon: 'currency-usd',
    title: 'Pipeline',
    permissionType: PermissionSetKey.PIPELINE,
  },
  {
    to: '/customers_at_risk',
    icon: 'alert',
    title: 'Customers at Risk',
    permissionType: PermissionSetKey.SALES_LISTS,
  },
];

const mgtItems = [
  {
    to: '/ghostaccounts',
    icon: 'ghost',
    title: 'Manage Ghost Accounts',
    permissionType: PermissionSetKey.GHOST_ACCOUNTS,
  },
  {
    to: '/emails',
    icon: 'email',
    title: 'Send Emails',
    permissionType: PermissionSetKey.EMAILS,
  },
  {
    to: '/knowledge',
    icon: 'lightbulb',
    title: 'Knowledge',
    permissionType: PermissionSetKey.KNOWLEDGE,
  },
  {
    to: '/messaging',
    icon: 'forum',
    title: 'Messaging',
    permissionType: PermissionSetKey.MESSAGING,
  },
  {
    to: '/collections',
    icon: 'account-box',
    title: 'Collections',
    permissionType: PermissionSetKey.COLLECTIONS,
  },
  {
    to: '/service_management',
    icon: 'wrench',
    title: 'Service Management',
    permissionType: PermissionSetKey.SERVICE_MANAGEMENT,
  },
  {
    to: '/service_status',
    icon: 'alert',
    title: 'Service Status',
    permissionType: PermissionSetKey.SERVICE_STATUS,
  },
  {
    to: '/feature_flags',
    icon: 'flag',
    title: 'Feature Flags',
    permissionType: PermissionSetKey.FEATURE_FLAGS,
  },
];

type Props = {
  logout: () => void;
}
export default function Nav ({ logout }: Props) {
  const [open, setOpen] = useState(false);

    return (
      <div>
        <div className='TopBar' onClick={() => setOpen(true)}>
          <i className='mdi mdi-menu' />
        </div>

        {
          open &&
          <div className='Shade' onClick={() => setOpen(false)} />
        }

        <nav className={`Nav${open ? ' open' : ''}`}>
          <img src='/img/logo.png' className='Logo' alt='PackageRoute Logo' />

          <div className='content'>
            <div className='first'>
              {
                navItems.map(({ to, icon, title, permissionType }, idx) => {
                  return (
                    <NavItemWithPermissions
                      permissionsSetKey={permissionType}
                      key={idx}
                      to={to}
                      icon={icon}
                      title={title}
                    />
                  )
                })
              }
            </div>

            <div className='section-border' />

            <div className='second'>
              {
                salesItems.map(({ to, icon, title, permissionType }, idx) => {
                  return (
                    <NavItemWithPermissions
                      permissionsSetKey={permissionType}
                      key={idx}
                      to={to}
                      icon={icon}
                      title={title}
                    />
                  )
                })
              }
            </div>

            <div className='section-border' />

            <div className='second'>
              {
                mgtItems.map(({ to, icon, title, permissionType }, idx) => {
                  return (
                    <NavItemWithPermissions
                      permissionsSetKey={permissionType}
                      key={idx}
                      to={to}
                      icon={icon}
                      title={title}
                    />
                  )
                })
              }
            </div>
          </div>

          <div className='logout' onClick={logout}>
            <Icon name='logout' color='white' />
          </div>
        </nav>
      </div>
    );
  }

