import React, { useState } from 'react';
import LifecycleOptions from './LifecycleOptions';
import AllSignups from './AllSignups';
import TrialWeek from './TrialWeek';
import Conversions from './Conversions';
import Cancellations from './Cancellations';
import PastDue from './PastDue';
import CancelAtPeriodEnd from './CancelAtPeriodEnd';
import CancellationReasons from './CancellationReasons';
import {Stage, stages} from './stages';
import './Lifecycle.css';

type Props = {
  setFocusedISP: (ispId: string | null) => void;
  trialingTags: any;
  lastNDays: number;
}

export default function Lifecycle ({ setFocusedISP, trialingTags, lastNDays }: Props) {
  const [activeStageId, setActiveStageId] = useState(Stage.ALL);

  return (
    <div className='Lifecycle'>
      <LifecycleOptions
        activeStageId={activeStageId}
        stages={stages}
        setActiveStage={setActiveStageId}
      />

      {
        activeStageId === Stage.ALL
          ? <AllSignups setFocusedISP={setFocusedISP} lastNDays={lastNDays} />
          : null
      }

      {
        activeStageId === Stage.WEEK_1
          ? <TrialWeek
              setFocusedISP={setFocusedISP}
              trialingTags={trialingTags}
              week='Week 1'
              minDays={8}
              maxDays={15}
            />
          : null
      }

      {
        activeStageId === Stage.WEEK_2
          ? <TrialWeek
              setFocusedISP={setFocusedISP}
              trialingTags={trialingTags}
              week='Week 2'
              minDays={0}
              maxDays={7}
            />
          : null
      }

      {
        activeStageId === Stage.PAST_DUE
          ? <PastDue setFocusedISP={setFocusedISP} lastNDays={lastNDays} />
          : null
      }

      {
        activeStageId === Stage.CONVERTED
          ? <Conversions setFocusedISP={setFocusedISP} lastNDays={lastNDays} />
          : null
      }

      {
        activeStageId === Stage.CANCELED
          ? <Cancellations setFocusedISP={setFocusedISP} lastNDays={lastNDays} />
          : null
      }

      {
        activeStageId === Stage.CANCEL_AT_PERIOD_END
          ? <CancelAtPeriodEnd setFocusedISP={setFocusedISP} lastNDays={lastNDays} />
          : null
      }

      {
        activeStageId === Stage.CANCELLATION_REASONS
          ? <CancellationReasons setFocusedISP={setFocusedISP} lastNDays={lastNDays} />
          : null
      }
    </div>
  );
}
