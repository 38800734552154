import React from 'react';
import Spinner from '../common/Spinner';
import Icon from '../Icon/Icon';
import InputText from '../Page/InputText';
import './SendEmail.css';
import api from '../../utils/api';

export default class SendEmail extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      sendingEmail: false,
      sendSuccess: false,
      emailAddresses: {},
      activeEmail: null,
      error: null
    };

    this.addEmail = this.addEmail.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }

  async addEmail (e) {
    e.preventDefault();

    const { activeEmail } = this.state;

    if (!activeEmail || !activeEmail.length || !activeEmail.match(/.+@.+\..+/)) {
      return this.setState({ error: 'Must be a valid email.' });
    }

    this.setState(prevState => {
      const nextEmailAddresses = { ...prevState.emailAddresses };

      if (!nextEmailAddresses.hasOwnProperty(activeEmail)) {
        nextEmailAddresses[activeEmail] = true;
      }

      this.setState({ emailAddresses: nextEmailAddresses, error: null, activeEmail: '' });
    });
  }

  async sendEmail (e) {
    e.stopPropagation();
    e.preventDefault();

    this.setState({ sendingEmail: true });

    try {
      const email = {
        template_id: this.props.template.id,
        from: {
          email: 'support@packageroute.com',
          name: 'PackageRoute Inc.'
        },
        reply_to: {
          email: 'support@packageroute.com',
          name: 'PackageRoute Support'
        },
        personalizations: [
          {
            to: Object.keys(this.state.emailAddresses).map(email => {
              return { email };
            })
          }
        ]
      }

      await api.post('emails', email);

      this.setState({ sendSuccess: true });
    }
    catch(err) {
      this.setState({ error: err.message, sendingEmail: false });
    }
  }

  render () {
    const { template } = this.props;
    let activeVersion;

    if (template) {
      for (const version of template.versions || []) {
        if (version.active) {
          activeVersion = version;

          break;
        }
      }
    }

    return (
      <div className='SendEmail'>
        <div className='form'>
          <div className='addresses'>
            <div className='field'>
              <div className='label'>
                <h3>Subject:</h3>
              </div>

              <div className='value'>
                <p>{activeVersion ? activeVersion.subject : ''}</p>
              </div>
            </div>

            <div className='field'>
              <div className='label'>
                <h3>From:</h3>
              </div>

              <div className='value'>
                <p>support@packageroute.com</p>
              </div>
            </div>

            <div className='field'>
              <div className='label'>
                <h3>Reply-To:</h3>
              </div>

              <div className='value'>
                <p>support@packageroute.com</p>
              </div>
            </div>

            <div className='field to'>
              <div className='label'>
                <h3>To:</h3>
              </div>

              <div className='recipients'>
                {
                  this.state.emailAddresses && Object.keys(this.state.emailAddresses).length
                    ? Object.keys(this.state.emailAddresses).map(email => {
                      return (
                        <div className='to-email'>
                          <p>{email}</p>

                          <div onClick={() => {
                            this.setState(prevState => {
                              const nextEmailAddresses = { ...prevState.emailAddresses };

                              delete nextEmailAddresses[email];

                              return { emailAddresses: nextEmailAddresses };
                            })
                          }}>
                            <Icon
                              name='close'
                              color='#666'
                            />
                          </div>
                        </div>
                      );
                    })
                    : null
                }

                <form className='value' onSubmit={this.addEmail}>
                  <InputText
                    name='emailAddress'
                    value={this.state.activeEmail}
                    placeholder={`Email address`}
                    onChange={e => this.setState({ activeEmail: e.target.value, error: null })}
                    inputStyle={{
                      border: 'none',
                      borderBottom: '1px solid #DDD'
                    }}
                    style={{
                      display: 'flex',
                      flexDirection: 'row-reverse'
                    }}
                  >
                    <button
                      type='submit'
                      className='add-btn'
                      onClick={this.addEmail}>
                      <Icon name='plus' color='black' size={25} />
                    </button>
                  </InputText>

                </form>

                {
                  this.state.error
                    ? <div style={{ padding: '5px', display: 'flex', justifyContent: 'center' }}>
                      <p style={{ color: 'red' }}>{this.state.error}</p>
                    </div>
                    : null
                }
              </div>
            </div>

            <div className='send-wrapper'>
              {
                this.state.sendingEmail && this.state.sendSuccess
                  ? <button
                    className='reset-btn'
                    onClick={() => this.setState({
                      error: null,
                      sendingEmail: false,
                      sendSuccess: false,
                      emailAddresses: {},
                      activeEmail: null
                    }, this.props.resetTemplate)}>
                    <p>Reset</p>
                  </button>
                  : null
              }
              <button
                className={`send-btn ${Object.keys(this.state.emailAddresses).length && this.props.template ? 'valid' : ''}`}
                onClick={Object.keys(this.state.emailAddresses).length && this.props.template && !this.state.sendSuccess && !this.state.sendingEmail
                  ? this.sendEmail
                  : null
                }>
                {
                  this.state.sendingEmail
                    ? !this.state.sendSuccess
                      ? <Spinner size='25' color='white' />
                      : <Icon name='check' color='white' />
                    : <p>Send Email</p>
                }
              </button>
            </div>
          </div>

          <div className='preview'>
            {
              activeVersion
                ? <iframe srcdoc={activeVersion.html_content} title="preview" />
                : <p>Select a template.</p>
            }
          </div>
        </div>
      </div>
    )
  }
}
