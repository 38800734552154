import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/**
 * Station sales lists is a record of the sales lists associated with
 * an individual station.
 */
export type StationsSalesLists = Record<StationId, StationSalesListItem[]>;
export type StationSalesListItem = {
  id: number;
  salesListId: number;
  name: string;
  reason: string;
  dueDate: string;
  createdAt: string;
};

export type StationId = string;

export enum Status {
  INITIAL = "INITIAL",
  PENDING = "PENDING",
  UPDATED = "UPDATED",
  FAILED = "FAILED",
}

export type State = {
  data: StationsSalesLists;
  status: Status;
};

const initialState: State = {
  data: {},
  status: Status.INITIAL,
};

const stationsSalesListsSlice = createSlice({
  name: "stationsSalesLists",
  initialState: initialState,
  reducers: {
    updateStationSalesLists: (
      state,
      action: PayloadAction<{
        stationId: string;
        stationsSalesLists: StationSalesListItem[];
      }>
    ) => {
      const { stationId, stationsSalesLists } = action.payload;

      state.data[stationId] = stationsSalesLists;
      state.status = Status.UPDATED;
    },
    resetStationSalesLists: (state) => {
      state.status = Status.INITIAL;
      state.data = {};
    },
  },
});

export const { updateStationSalesLists, resetStationSalesLists } =
  stationsSalesListsSlice.actions;

export default stationsSalesListsSlice.reducer;
