import React, { useState } from "react";
import { StyleSheet, View, Text, Modal, TouchableOpacity } from "react-native";
import { connect, ConnectedProps } from "react-redux";
import moment from "moment-timezone";
import { Calendar } from "@components/Calendar";
import { RootState } from "@reducers/index";
import { Button } from '@common';
import { DayOfWeek } from "@reporting/Reporting.Types";

type ComponentProps = {
  visible: boolean;
  onDismiss: () => void;
  onDateRangeSelection: (startDate: string, endDate: string) => void;
  weekStartDay: DayOfWeek;
};

type Props = ComponentProps & PropsFromRedux;

function CustomDateRangePicker({
  visible,
  onDismiss,
  onDateRangeSelection,
  colorPack,
  timeZone,
  startDate,
  endDate,
}: Props) {
  const [selectStart, setSelectStart] = useState(true);
  const [start, setStart] = useState<string | null>(null);
  const [end, setEnd] = useState<string | null>(null);

  return (
    <Modal centerFullScreen visible={visible} onRequestClose={onDismiss}>
      <View
        style={[
          styles.container,
          {
            backgroundColor: colorPack.baseHighlight,
            borderColor: colorPack.border,
          },
        ]}
      >
        <View style={styles.datePickerWrap}>
          <Text>Select Date Range</Text>
          <View style={styles.startEndSelectors}>
            <TouchableOpacity
              onClick={() => setSelectStart(true)}
              style={[
                styles.selector,
                {
                  backgroundColor: selectStart
                    ? colorPack.blue
                    : colorPack.baseHighlight,
                  borderColor: colorPack.blue
                },
              ]}
            >
              <Text style={{ color: selectStart ? colorPack.baseHighlight : colorPack.blue }}>
                {!start
                  ? "Start"
                  : moment.tz(start, DATE_KEY, timeZone).format("M/D/YY")}
              </Text>
            </TouchableOpacity>
            <View style={styles.spacer}>
              <Text>to</Text>
            </View>
            <TouchableOpacity
              onClick={() => setSelectStart(false)}
              style={[
                styles.selector,
                {
                  backgroundColor: !selectStart
                    ? colorPack.blue
                    : colorPack.baseHighlight,
                  borderColor: colorPack.blue
                },
              ]}
            >
              <Text style={{ color: !selectStart ? colorPack.baseHighlight : colorPack.blue }}>
                {!end
                  ? "End"
                  : moment.tz(end, DATE_KEY, timeZone).format("M/D/YY")}
              </Text>
            </TouchableOpacity>
          </View>
          <View>
            <Calendar
              initialRange='month'
              initialDate={moment.tz(startDate, DATE_KEY, timeZone)}
              initialDateRange={{
                start: moment.tz(startDate, DATE_KEY, timeZone),
                end: moment.tz(endDate, DATE_KEY, timeZone)
              }}
              onDateClick={(_e, dateMoment) => {
                const startMoment = moment.tz(start || startDate, DATE_KEY, timeZone);
                const endMoment = moment.tz(end || endDate, DATE_KEY, timeZone);

                if (selectStart) {
                  if (!start) setSelectStart(false);
                  if (dateMoment.isAfter(endMoment)) {
                    setEnd(dateMoment.format(DATE_KEY));
                  }

                  setStart(dateMoment.format(DATE_KEY));
                } else {
                  if (dateMoment.isBefore(startMoment)) {
                    setStart(dateMoment.format(DATE_KEY));
                  }

                  setEnd(dateMoment.format(DATE_KEY));
                }
              }}
              timeZone={timeZone}
              onPeriodClick={(_event, startMoment, endMoment) => {
                console.log(startMoment.format(DATE_KEY), endMoment.format(DATE_KEY))
                setStart(startMoment.format(DATE_KEY));
                setEnd(endMoment.format(DATE_KEY));
              }}
              
              picker
            />
          </View>
        </View>

        {(!!start && !!end) &&
          <View style={styles.buttonWrap}>
            <Button 
              onClick={() => onDateRangeSelection(start, end)}
              color={colorPack.green}
            >
              Start
            </Button>
          </View>
        }
      </View>
    </Modal>
  );
}

function mapStateToProps(state: RootState) {
  const { startDate, endDate } = state.reports.dateRange;
  return {
    ...state.colorMode,
    timeZone: state.timeZone.data,
    startDate,
    endDate,
  };
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CustomDateRangePicker);

const styles = StyleSheet.create({
  container: {
    opacity: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    borderWidth: 1,
    borderRadius: 10,
    padding: 5,
    boxShadow: '10px 5px 20px rgba(0, 0, 0, .15)',
    maxWidth: 350,
  },
  startEndSelectors: {
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    height: 40,
    alignItems: "center",
  },
  selector: {
    flex: 1,
    height: "100%",
    color: "white",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 5,
  },
  spacer: {
    marginHorizontal: 20,
  },
  datePickerWrap: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginVertical: 10,
    flex: 1,
    padding: 10,
  },
  datePickerText: {
    textAlign: "center",
  },
  buttonWrap: {
    marginBottom: 10,
  }
});
