import React from 'react';

import './RecipientsSearch.css';
import Icon from '../../Icon/Icon';
import api from '../../../utils/api';
import RecipientsList from './RecipientsList';

export default class RecipientsSearch extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      results: [],
      searchText: ''
    }

    this.onChange = this.onChange.bind(this);
    this.search = this.search.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
  }

  onChange ({ target }) {
    const { searchTimeout } = this.state;

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const timeoutID = setTimeout(this.search, 1500);

    this.setState({
      searchTimeout: timeoutID,
      results: [],
      searchText: target.value,
      searching: true
    });
  }

  async search () {
    try {
      const { searchText } = this.state;

      if (!searchText) {
        this.setState({ results: [], searching: false });

        return;
      };

      const sterilizedText = searchText.toString().replace(/\//g, '');
      const res = await api.request(`search/recipients/${sterilizedText}`);

      this.setState({ results: res.data || [], searching: false });
    }
    catch(err) {
      this.setState({ searching: false });
    }
  }

  clearSearch () {
    const { searchTimeout } = this.state;

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    this.setState({
      searchText: '',
      results: [],
      searching: false
    });
  }

  render () {
    const { results } = this.state;

    return (
      <div className='RecipientsSearch'>
        <div className='search-bar'>
          <input
            type='text'
            placeholder='Search by isp name or station id'
            onChange={this.onChange}
            value={this.state.searchText}
          />

          <div className='clear' onClick={this.clearSearch}>
            <Icon name='close' size={20} color='black' />
          </div>
        </div>

        {
          results && results.length
            ? <RecipientsList
              list={results}
              activeList={this.props.addRecipients}
              onItemClick={this.props.toggleRecipient}
            />
            : this.state.searching
              ? <p className='label'>Searching...</p>
              : <p className='label'>
                {
                  this.state.searchText
                    ? 'No results'
                    : ''
                }
              </p>
        }
      </div>
    )
  }
}
