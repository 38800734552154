import React from "react";
import Spinner from "../common/Spinner";
import Icon from "../Icon/Icon";
import "./Notice.css";

export type Severity = "error" | "warning" | "info";

export type NoticeObject = {
  severity: Severity;
  title: string;
  description?: string;
  loading?: boolean;
}

type Props = {
  severity?: Severity;
  show?: boolean;
  title: string;
  description?: string;
  clear?: () => void;
  loading?: boolean;
};

const icons = {
  error: 'alert',
  warning: 'alert-circle',
  info: 'message-alert',
}

export default function Notice({
  severity = "error",
  show = true,
  title,
  description = "",
  clear,
  loading = false,
}: Props) {
  if (!show) return null;

  return (
    <div className={`Notice ${severity}`} onClick={clear}>
      <div className="label">
        {loading
          ? <Spinner color='white' />
          : <Icon 
              color='white'
              name={icons[severity]}
              size={25}
            />
        }
      </div>

      <div className="content">
        <p className="title">{title}</p>
        {!!description && <p className="description">{description}</p>}
      </div>
    </div>
  );
}
