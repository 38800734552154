import {RootState} from '@reducers';
import { createSelector } from "reselect";
import { StationIsp } from '@reducers/stations/stationsIsps';

const selectStationIsps = (state: RootState) => state.stations.stationsIsps.data.stations;
const selectStationIspMappings = (state: RootState) => state.stations.stationsIsps.data.ispStations;

const selectStationIspsLists = createSelector([selectStationIsps], (stationsIsps) => {
  const stationsIspsLists: Record<string, StationIsp[]> = {};

  for (const stationId in stationsIsps) {
    stationsIspsLists[stationId] = Object.values(stationsIsps[stationId]);
  }

  return stationsIspsLists;
})

export {
  selectStationIsps,
  selectStationIspMappings,
  selectStationIspsLists,
}