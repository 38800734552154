import React from 'react';
import { ScrollView } from 'react-native';

import './CrashPlaceHolder.css';

export default function CrashPlaceHolder() {
  return (
    <ScrollView className='CrashPlaceHolder'>
      <div className='ErrorMessage'>
        <h1>That wasn't supposed to happen...</h1>
        <h3>An error has occurred!</h3>
        <h4>See dev console for error.</h4>
        <p>Refresh the page to restart the App</p>
      </div>
    </ScrollView>
  )
}