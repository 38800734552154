import {RootState} from '@reducers';

const selectTodayOperationsActivity = (state: RootState) => state.metrics.dailyOperationsActivity.today;
const selectDailyOperationsActivity = (state: RootState) => state.metrics.dailyOperationsActivity.data;
const selectDailyOperationsActivityUpdatedAt = (state: RootState) => state.metrics.dailyOperationsActivity.updatedAt;

export {
  selectTodayOperationsActivity,
  selectDailyOperationsActivity,
  selectDailyOperationsActivityUpdatedAt,
}