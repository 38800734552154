import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentStatus } from "@packageroute/biz/lib/manifest/payloads/SummaryPayload";

/**
 * Activity data is fetched on a recurring basis (every 2 hours atm), and draws from
 * operation profile and manifest data.
 */
export type Activity = Record<DateString, DailyActivity | null>;

export type WorkAreas = pr.isp.profile.work_areas.WorkAreas;
export type WorkAreaId = string;
export type DateString = string;
export type OperationId = string;
export type StationId = string;

export type WorkAreaStatus = { 
  /**
   * Work Area exists if in Profile.WorkAreas collection
   * 
   * A work area may not exist if it is not in Profile.WorkAreas because
   * Profile.WorkAreas has changed.
   */
  exists: boolean;
  /**
   * Work Area exists if there is manifest data for it
   */
  live: boolean;
  /**
   * Work Area marked Paid in Profile.WorkAreas
   */
  paid: boolean;
  /**
   * Work Area payment status.
   * 
   * * PAID = most recent data is that the work area is marked paid on given date
   * 
   * * WAS_PAID = most recent data is that the work area formerly was marked paid
   * on given date and now no longer is.
   * 
   * * UNPAID = most recent data is that the work area was not marked paid at any
   * point on given date.
   * 
   * * DEFAULT = payment status unknown
   */
  paymentStatus: PaymentStatus
};

export type LiveIspSummary = {
  /**
   * Total routes which Profile.WorkAreas collection on the day queried.
   * 
   * Note: because this value relies upon the current Profile.WorkAreas state
   * and *not* the historical data, backfilling data will produce incorrect values
   */
  ispRoutes: number;
  /**
   * Routes which have manifest data, regardless of payment status
   */
  liveRoutes: number;
  /**
   * Routes which have manifest data and a payment status of paid on given date
   */
  paidRoutes: number;
  /**
   * Includes routes which have been paid but which are not active on given date.
   * 
   * Note: because this value relies upon the current Profile WorkAreas payment status
   * changes to that payment status will produce incorrect data when backfilling previous
   * days data, *or* upon cancellation.
   */
  totalPaidRoutes: number;
  /**
   * Totals for all routes, regardless of payment status
   */
  totalPickups: number;
  totalDeliveries: number;
  totalPickupPkgs: number;
  totalDeliveryPkgs: number;
  /**
   * Totals for only paid routes
   */
  totalPaidDeliveries: number;
  totalPaidPickups: number;
  totalPaidDeliveryPkgs: number;
  totalPaidPickupPkgs: number;
  /**
   * ISP is valid if a Profile record exists in product database
   */
  valid: boolean;
  stationId: null | StationId;
  ispid: null | OperationId;
  /**
   * WorkAreas record based upon Profile.WorkAreas
   */
  workAreas: Record<WorkAreaId, WorkAreaStatus>;
  updatedAt: number | null;
};

export type LiveIspSummaries = Record<OperationId, LiveIspSummary>;
export type StationsActivity = Record<StationId, OperationId[]>;
export type DailyCompleteActivity = {
  /**
   * Total routes which Profile.WorkAreas collection on the day queried.
   * 
   * Note: because this value relies upon the current Profile.WorkAreas state
   * and *not* the historical data, backfilling data will produce incorrect values
   */
  totalRoutes: number;
  /**
   * Routes which have manifest data, regardless of payment status
   */
  liveRoutes: number;
  /**
   * Routes which have manifest data and a payment status of paid on given date
   */
  paidRoutes: number;
  /**
   * Includes routes which have been paid but which are not active on given date.
   * 
   * Note: because this value relies upon the current Profile WorkAreas payment status
   * changes to that payment status will produce incorrect data when backfilling previous
   * days data, *or* upon cancellation.
   */
  totalPaidRoutes: number;
  /**
   * Totals for all routes, regardless of payment status
   */
  totalDeliveries: number;
  totalPickups: number;
  totalDeliveryPkgs: number;
  totalPickupPkgs: number;
  /**
   * Totals for only paid routes
   */
  totalPaidDeliveries: number;
  totalPaidPickups: number;
  totalPaidDeliveryPkgs: number;
  totalPaidPickupPkgs: number;
  /**
   * Summaries for individual ISPs
   */
  liveISPs: LiveIspSummaries;
  /**
   * Lookup table for ISP summary data for operations with paid routes
   */
  paidISPs: Record<OperationId, true>;
  /**
   * Stations with operations with manifest data, regardless of payment status
   */
  liveStations: StationsActivity;
  /**
   * Stations with paid operations with manifest data
   */
  paidStations: StationsActivity;
  updatedAt: number | null;
};

export type DailyActivity = {
  /**
   * Total routes which Profile.WorkAreas collection on the day queried.
   * 
   * Note: because this value relies upon the current Profile.WorkAreas state
   * and *not* the historical data, backfilling data will produce incorrect values
   */
  totalRoutes: number;
  /**
   * Routes which have manifest data, regardless of payment status
   */
  liveRoutes: number;
  /**
   * Routes which have manifest data and a payment status of paid on given date
   */
  paidRoutes: number;
  /**
   * Includes routes which have been paid but which are not active on given date.
   * 
   * Note: because this value relies upon the current Profile WorkAreas payment status
   * changes to that payment status will produce incorrect data when backfilling previous
   * days data, *or* upon cancellation.
   */
  totalPaidRoutes: number;
  /**
   * Totals for all routes, regardless of payment status
   */
  totalDeliveries: number;
  totalPickups: number;
  totalDeliveryPkgs: number;
  totalPickupPkgs: number;
  /**
   * Totals for only paid routes
   */
  totalPaidDeliveries: number;
  totalPaidPickups: number;
  totalPaidDeliveryPkgs: number;
  totalPaidPickupPkgs: number;
  updatedAt: number | null;
};

export type NumericActivityPropertyKeys = keyof Omit<DailyActivity, 'liveStations' | 'liveISPs' | 'paidStations' | 'paidISPs'>;

export enum Status {
  INITIAL = 'INITIAL',
  PENDING = 'PENDING',
  UPDATED = 'UPDATED',
  FAILED = 'FAILED'
}

export type State = {
  data: Activity;
  today: DailyCompleteActivity;
  updatedAt: number | null;
  status: Status;
};

const defaultCompleteActivity: DailyCompleteActivity = {
  totalRoutes: 0,
  liveRoutes: 0,
  paidRoutes: 0,
  totalPaidRoutes: 0,
  totalDeliveries: 0,
  totalPickups: 0,
  totalDeliveryPkgs: 0,
  totalPickupPkgs: 0,
  totalPaidDeliveries: 0,
  totalPaidPickups: 0,
  totalPaidDeliveryPkgs: 0,
  totalPaidPickupPkgs: 0,
  liveISPs: {},
  paidISPs: {},
  liveStations: {},
  paidStations: {},
  updatedAt: null,
}

const initialState: State = {
  data: {},
  today: {...defaultCompleteActivity},
  updatedAt: null,
  status: Status.INITIAL,
};

const dailyOperationsActivitySlice = createSlice({
  name: 'dailyOperationsActivityMetrics',
  initialState: initialState,
  reducers: {
    updateTodayActivity: (state, action: PayloadAction<{ todayActivity: DailyCompleteActivity | null }>) => {
      const { todayActivity } = action.payload;

      state.today = todayActivity || {...defaultCompleteActivity};
      state.status = Status.UPDATED;
    },
    updateActivityUpdatedAt: (state, action: PayloadAction<{ updatedAt: number | null }>) => {
      const { updatedAt } = action.payload;

      state.updatedAt = updatedAt;
    },
    updateActivity: (state, action: PayloadAction<{ activity: Activity }>) => {
      const { activity } = action.payload;

      state.status = Status.UPDATED;
      state.data = {
        ...state.data,
        ...activity,
      }
    },
    updateDailyActivity: (state, action: PayloadAction<{ date: DateString, dailyActivity: DailyActivity | null }>) => {
      const { date, dailyActivity } = action.payload;

      state.status = Status.UPDATED;
      state.data[date] = dailyActivity;
    },
    resetDailyActivity: (state) => {
      state.status = Status.INITIAL;
      state.updatedAt = null;
      state.data = {};
      state.today = {...defaultCompleteActivity};
    }
  }
})

export const { 
  updateTodayActivity,
  updateActivityUpdatedAt,
  updateActivity,
  updateDailyActivity,
  resetDailyActivity,
} = dailyOperationsActivitySlice.actions

export default dailyOperationsActivitySlice.reducer;