import React from 'react';
import moment from 'moment-timezone';

import './TagDueDate.css';

export default function TagDueDate (props) {
  const date = props.tag.dueDate ? moment(props.tag.dueDate) : null;
  const today = moment();
  let diff;
  let diffColor = 'transparent';

  if (date) {
    diff = date.diff(today, 'days');

    if (diff <= 1) {
      diffColor = 'red';
    }
    else if (diff <= 3) {
      diffColor = '#EA7D14'
    }
  }

  return (
    <div className='TagDueDate' onClick={props.onClick}>
      {
        date
          ? <div className='due-date'>
            {
              diff <= 3
                ? <div className='warning' style={{ backgroundColor: diffColor }} />
                : null
            }

            <p>{date.format('M-DD-YYYY')}</p>
          </div>
          : <div className='due-date'>
            <p>Due Date</p>
          </div>
      }
    </div>
  )
}
