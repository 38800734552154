import React from 'react';
import moment from 'moment-timezone';
import Icon from '../Icon/Icon';
import AddNote from '../Isp/CRM/AddNote';
import { ISPNote } from '@api';
import './Note.css';

type Editable = {
  editable: true;
  edit?: () => void;
  isActive: boolean;
  finishNote?: () => void;
  createNote?: (note: Partial<ISPNote>) => void;
  updateNote?: (noteId: number, noteUpdates: Partial<ISPNote>) => void;
  deleteNote?: () => void;
}

type DisplayOnly = {
  isActive?: never;
  editable?: never;
  edit?: never;
  finishNote?: never;
  createNote?: never;
  updateNote?: never;
  deleteNote?: never;
}

type Props = {
  note: ISPNote;
} & (Editable | DisplayOnly)

export default function Note ({
  edit,
  note,
  isActive,
  editable,
  finishNote,
  createNote,
  updateNote,
  deleteNote,
}: Props) {
  const { username, createdAt } = note;
  const [firstName, lastName] = (username || ',,,').split(',');

  return (
    <div className='Note'>
      <div className='details'>
        { username
          ? <div className='user'>
            <p>{firstName} {lastName}</p>
          </div>
          : null
        }

        <div className='time'>
          <p>{moment(createdAt).format('h:mm a, MMM DD, YYYY')}</p>
        </div>

        {
          edit
            ? <div
                className='edit'
                onClick={edit}>
                <Icon name='pencil' size={15} color='black' />
              </div>
            : null
        }
      </div>

      {
        isActive === true && editable === true
          ? <AddNote
              note={note}
              editable
              // @ts-ignore
              finishNote={finishNote}
              // @ts-ignore
              createNote={createNote}
              // @ts-ignore
              updateNote={updateNote}
              // @ts-ignore
              deleteNote={deleteNote}
            />
          : <div className='text'>
            <p>{note.note}</p>
          </div>
      }
    </div>
  )
}
