import React from "react";
import UserIsp from "./UserIsp";
import pr from '@packageroute/types-firebase';
import {RouteComponentProps} from 'react-router-dom';

type AppUser = pr.users.User;
type Profile = pr.isp.profile.Profile & { id: string };

type Props = {
  user: AppUser;
  isps: Profile[];
  lastActiveIsp?: string;
  history: RouteComponentProps['history'];
}

const UserIsps = ({ user, isps, lastActiveIsp, history }: Props) => {
  const ispCards = isps
    ? isps.map(isp => (
        <UserIsp
          key={isp.ISPID}
          user={user}
          isp={isp}
          isLastActive={lastActiveIsp === isp.ISPID}
          history={history}
        />
      ))
    : null;

  return <div>{ispCards}</div>;
};

export default UserIsps;
