import React from 'react';
import './style/add-note.css';
import { ISPNote } from '@api';

type Props = {
  editable?: boolean;
  note?: ISPNote;
  finishNote: () => void;
  createNote: (note: Partial<ISPNote>) => void;
  updateNote: (noteId: number, noteUpdates: Partial<ISPNote>) => void;
  deleteNote: (noteId: number) => void;
}

type State = {
  noteId: number | null;
  text: string | null;
}

export default class AddNote extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props);

    const { note } = props;

    this.state = {
      noteId: note ? note.id : null,
      text: note ? note.note : null
    };

    this.submit = this.submit.bind(this);
  }

  async submit (e: React.SyntheticEvent) {
    e.preventDefault();

    const { updateNote, createNote, note, finishNote } = this.props;
    const { noteId, text } = this.state;

    let newNote = null;

    if (note) {
      newNote = await updateNote(noteId!, { note: text || '' });
    }
    else {
      newNote = await createNote({ note: text || '' });
    }

    this.setState({ noteId: null, text: null }, () => {
      finishNote();
    });
  }

  render () {
    const { note, editable, deleteNote } = this.props;
    const { text } = this.state;

    const newNote = !note && text;
    const editedNote = note && (note.note !== text);
    return (
      <div className='AddNote'>
        <form onSubmit={(newNote || editedNote) ? this.submit : undefined}>
          <input
            type='text'
            placeholder={'Write a note...'}
            onBlur={note ? this.submit : () => {}}
            onChange={e => this.setState({ text: e.target.value })}
            value={text ?? ''}
          />

          <div className='btns'>
            {
              newNote || editedNote
                ? <button className='submit' onClick={this.submit}>
                  {
                    newNote
                      ? 'Add'
                      : 'Update'
                  }
                </button>
                : null
            }

            {
              note && editable
                ? <button className='delete' onClick={() => deleteNote(note.id)}>
                  Delete
                </button>
                : null
            }
          </div>
        </form>
      </div>
    );
  }
}
