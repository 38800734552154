import {combineReducers} from 'redux';

import canceledSubscriptionsReducer from './canceled';
import cancellingAtPeriodEndSubscriptionsReducer from './cancellingAtPeriodEnd';
import convertedSubscriptionsReducer from './converted';
import pastDueSubscriptionsReducer from './pastDue';
import trialSubscriptionsReducer from './trials';

const subscriptionsReducer = combineReducers({
  canceled: canceledSubscriptionsReducer,
  cancellingAtPeriodEnd: cancellingAtPeriodEndSubscriptionsReducer,
  converted: convertedSubscriptionsReducer,
  pastDue: pastDueSubscriptionsReducer,
  trials: trialSubscriptionsReducer,
});

export default subscriptionsReducer;
