import {Dispatch} from '@reduxjs/toolkit';
import * as canceled from './canceled';
import * as cancellingAtPeriodEnd from './cancellingAtPeriodEnd';
import * as converted from './converted';
import * as pastDue from './pastDue';
import * as trials from './trials';

export {
  canceled,
  cancellingAtPeriodEnd,
  converted,
  pastDue,
  trials,
}

export function clearSubscriptions (dispatch: Dispatch) {
  canceled.clearCanceledSubscriptions(dispatch);
  cancellingAtPeriodEnd.clearCancellingAtPeriodEndSubscriptions(dispatch);
  converted.clearTrialSubscriptions(dispatch);
  pastDue.clearPastDueSubscriptions(dispatch);
  trials.clearTrialSubscriptions(dispatch);
}