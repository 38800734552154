import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

import './Knowledge.css';
import PageTabs from '../Page/PageTabs';
import Articles from './Articles/Articles';
import Article from './Articles/Article';
import Categories from './Categories/Categories';
import TopArticles from './TopArticles/TopArticles';
import PageSpinner from '../Page/PageSpinner';
import Assets from './Assets/Assets';
import api from '../../utils/api';
import withPermissions from '../Permissions/withPermissions';

const tabs = [
  { name: 'Categories', to: '/knowledge/categories' },
  { name: 'Articles', to: '/knowledge/articles' },
  { name: 'Top Articles', to: '/knowledge/top-articles' },
  { name: 'Assets', to: '/knowledge/assets' }
];


type KnowledgeJSON = {
  categories: string[];
  articles: {
    [articleId: string]: {
      id: string;
      title: string;
      categories: string[];
      relatedArticles: string[];
      readingTime: string;
    }
  };
  topArticles: string[];
}

type Props = {
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
}

type State = {
  data: null | KnowledgeJSON;
  assets: any[];
  error?: null | string;
}

class Knowledge extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props);

    this.state = {
      data: null,
      assets: []
    };

    this.addCategory = this.addCategory.bind(this);
    this.deleteCategory = this.deleteCategory.bind(this);
    this.addError = this.addError.bind(this);
    this.updateData = this.updateData.bind(this);
    this.fetchAssets = this.fetchAssets.bind(this);
    this.addAsset = this.addAsset.bind(this);
  }

  async componentDidMount () {

    this.fetchKnowledgeData();

    this.shouldShowCategories();
  }

  componentDidUpdate () {
    console.log('componentDidUpdate', this.props.location.pathname)
    this.shouldShowCategories();
  }

  shouldShowCategories () {
    if (this.props.location.pathname === '/knowledge') {
      this.props.history.push('/knowledge/categories');
    }
  }

  async fetchKnowledgeData () {
    const res = await api.request<KnowledgeJSON>('knowledge/data');

    this.setState({ data: res.data || null });
  }

  async addCategory (category: string) {
    const res = await api.post(`knowledge/category/${category}`);

    this.setState({ data: res.data });
  }

  async deleteCategory (category: string) {
    console.log('deleteCategory', category);
    const res = await api.deleteRequest(`knowledge/category/${category}`);

    this.setState({ data: res.data });
  }

  addError (message: any) {
    if (!message || typeof message !== 'string') return;

    this.setState({ error: message }, () => {
      setTimeout(() => {
        this.setState({ error: null });
      }, 3000);
    });
  }

  updateData (data: any) {
    this.setState({ data });
  }

  async fetchAssets () {
    const res = await api.request<any>('knowledge/assets');

    this.setState({ assets: res.data });
  }

  addAsset (asset: any) {
    console.log('addAsset', asset);
    this.setState(prevState => {
      const nextAssets = [...prevState.assets];

      nextAssets.push(asset);

      return { assets: nextAssets };
    });
  }

  render () {
    if (!this.state.data) return (<PageSpinner />);

    return (
      <div className='Knowledge'>
        <PageTabs tabs={tabs} location={this.props.location} />

        <Route
          exact
          path='/knowledge/categories'
          render={match => {
            return (
              <Categories
                {...match}
                data={this.state.data}
                addCategory={this.addCategory}
                deleteCategory={this.deleteCategory}
                addError={this.addError}
              />
            );
          }}
        />

        <Route
          exact
          path='/knowledge/articles'
          render={match => {
            return (
              <Articles
                {...match}
                data={this.state.data}
              />
            );
          }}
        />

        <Route
          path='/knowledge/articles/:articleId'
          render={match => {
            return (
              <Article
                {...match}
                data={this.state.data}
                addError={this.addError}
                updateData={this.updateData}
              />
            );
          }}
        />

        <Route
          exact
          path='/knowledge/top-articles'
          render={match => {
            return (
              <TopArticles data={this.state.data} />
            );
          }}
        />

        <Route
          exact
          path='/knowledge/assets'
          render={match => {
            return (
              <Assets
                assets={this.state.assets}
                fetchAssets={this.fetchAssets}
                addAsset={this.addAsset}
                {...match}
              />
            )
          }}
        />

        {
          this.state.error
            ? <div className='error'>
              <p>{this.state.error}</p>
            </div>
            : null
        }
      </div>
    );
  }
}

export default withPermissions(Knowledge);
