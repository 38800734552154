import React from "react";
import moment from 'moment-timezone';
import {
  Subscription,
  SubscriptionStatus,
} from "@packageroute/vedr-service-model-typescript";
import { useColorPack } from "@hooks/redux";

type Props = {
  subscription: Subscription | null;
  timeZone: string,
};

export default function VedrSubscriptionStatus({ subscription, timeZone = 'America/Los_Angeles' }: Props) {
  const colorPack = useColorPack();

  const statusColor = {
    [SubscriptionStatus.Initial]: colorPack.blue,
    [SubscriptionStatus.Active]: colorPack.green,
    [SubscriptionStatus.Inactive]: colorPack.orange,
    Default: colorPack.lowCont,
  };

  const color = statusColor[subscription?.subscription_status || "Default"];

  const createdAt = subscription?.created_at ? moment.tz(subscription.created_at, timeZone).format('M/D/YY, HH:mm a') : null;
  const cancelledAt = subscription?.cancelled_at ? moment.tz(subscription.created_at, timeZone).format('M/D/YY, HH:mm a') : null;

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        margin: 10,
        paddingLeft: 20
      }}
    >
      <p
        style={{
          color: color,
          fontSize: 20,
        }}
      >
        Status: {!subscription ? "NOT ACTIVATED" : subscription.subscription_status}
      </p>
      {!!createdAt &&
        <p
          style={{
            marginTop: 5,
            color: colorPack.medCont,
            fontSize: 16,
          }}
        >
          Activated: {createdAt}
        </p>
      }
      {!!cancelledAt &&
        <p
          style={{
            marginTop: 5,
            color: colorPack.medCont,
            fontSize: 16,
          }}
        >
          Cancelled: {cancelledAt}
        </p>
      }
    </div>
  );
}
