import React from "react";
import { PortalModal } from "./Modal";
import Notice, {NoticeObject} from './Notice';

type Props = {
  notice: NoticeObject | null | undefined;
  clear: () => void;
};

export default function ConfirmationModal ({
  notice,
  clear,
}: Props) {
  return (
    <PortalModal visible={!!notice} onRequestClose={clear}>
      {notice && (
        <Notice
          title={notice.title}
          description={notice.description}
          severity={notice.severity}
          loading={notice.loading}
        />
      )}
    </PortalModal>
  );
}


