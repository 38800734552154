import React from 'react';

import './InputSwitch.css';

export default function InputSwitch (props) {
  const {
    enabled,
    onYes,
    onNo,
    yesLabel,
    noLabel,
    locked
  } = props;

  return (
    <div className='InputSwitch'>
      <button
        name='onYes'
        onClick={onYes}
        disabled={locked}
        className={`switch on ${!locked && enabled ? 'enabled' : ''} ${locked ? 'locked' : ''}`}>
        <p>{yesLabel || 'On'}</p>
      </button>

      <button
        name='onNo'
        onClick={onNo}
        disabled={locked}
        className={`switch off ${!locked && !enabled ? 'enabled' : ''} ${locked ? 'locked' : ''}`}>
        <p>{noLabel || 'Off'}</p>
      </button>
    </div>
  );
}
