import React from 'react';
import moment from 'moment-timezone';

import './TagPopout.css';
import Modal from '../Page/Modal';
import Icon from '../Icon/Icon';
import InputText from '../Page/InputText';
import InputDate from '../Page/InputDate';
import Button from '../Page/Button';
import api from '../../utils/api';

export default class TagPopout extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      tag: props.tag,
      reason: props.tag.reason || null,
      dueDate: props.tag.dueDate ? moment(props.tag.dueDate).format('YYYY-MM-DD') : null,
      editingReason: false,
      editingDueDate: false
    }

    this.createReason = this.createReason.bind(this);
    this.createDueDate = this.createDueDate.bind(this);
  }

  createReason () {
    const { reason, tag } = this.state;
    const { type } = this.props;

    if (reason === this.props.tag.reason) return;

    api.patch(`sales-lists/${type}/${tag.id}`, { reason })
      .then(res => {
        this.setState({ tag: res.data, editingReason: false }, () => {
          this.props.updateProps();
        });
      })
  }

  createDueDate () {
    const { dueDate, tag } = this.state;
    const { type } = this.props;

    api.patch(`sales-lists/${type}/${tag.id}`, { dueDate })
      .then(res => {
        this.setState({ tag: res.data, editingDueDate: false }, () => {
          this.props.updateProps();
        })
      })
  }

  render () {
    const { item, type } = this.props;
    const { tag } = this.state;

    return (
      <Modal>
        <div className='TagPopout'>
          <div className='popout-header'>
            <div className='title'>
              <span>
                <span style={{ padding: '5px 10px', backgroundColor: '#E8E8E8', borderRadius: '20px', color: 'black', fontSize: '14px' }}>
                  {this.props.tag.name}
                </span>

                <span style={{ marginLeft: '5px', fontSize: '14px' }}>
                  {type === 'isps' ? item.entityName : null}
                  {type === 'stations' ? item.terminal : null}
                </span>
              </span>
            </div>

            <div className='close' onClick={() => this.props.setActiveTag(null)}>
              <Icon name='close' color='black' size={20} />
            </div>
          </div>

          <div className='details'>
            <div className='details-wrapper'>
              {
                !tag.dueDate || this.state.editingDueDate
                  ? <React.Fragment>
                    <InputDate
                      label='Due Date'
                      onChange={(e) => this.setState({ dueDate: e.target.value })}
                      value={this.state.dueDate}
                    />

                    <div style={{ marginLeft: '10px', alignSelf: 'flex-end', height: '42px', display: 'flex', alignItems: 'center' }}>
                      <Button
                        color={!tag.dueDate ? 'rgb(22, 142, 50)' : '#006DAB'}
                        onClick={this.createDueDate}>
                        <p>{!tag.dueDate ? 'Add' : 'Update'}</p>
                      </Button>
                    </div>
                  </React.Fragment>
                  : <React.Fragment>
                    <div className='data'>
                      <p className='label'>Due Date</p>
                      <p>{moment(tag.dueDate).format('MM-DD-YYYY')}</p>
                    </div>

                    <div onClick={() => this.setState({ editingDueDate: true })}>
                      <Icon name='pencil' size={15} color='#666' />
                    </div>
                  </React.Fragment>
              }
            </div>

            <div className='details-wrapper'>
              {
                !tag.reason || this.state.editingReason
                  ? <React.Fragment>
                    <InputText
                      label='Reason'
                      value={this.state.reason}
                      onChange={(e) => this.setState({ reason: e.target.value })}
                    />

                    <div style={{ marginLeft: '10px', alignSelf: 'flex-end', height: '42px', display: 'flex', alignItems: 'center' }}>
                      <Button
                        color={!tag.reason ? 'rgb(22, 142, 50)' : '#006DAB'}
                        onClick={this.createReason}>
                        <p>{!tag.reason ? 'Add' : 'Update'}</p>
                      </Button>
                    </div>
                  </React.Fragment>
                  : <React.Fragment>
                    <div className='data'>
                      <p className='label'>Reason</p>
                      <p>{tag.reason}</p>
                    </div>

                    <div onClick={() => this.setState({ editingReason: true })}>
                      <Icon name='pencil' size={15} color='#666' />
                    </div>
                  </React.Fragment>
              }
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}
