import React from 'react';
import pr from '@packageroute/types-firebase';
import Icon from '../Icon/Icon';
import Map from '../Map/Map';
import WorkAreaMarker from '../Markers/WorkAreaMarker';
import {ClientSummary} from '../../ConsoleApi.Types';
import './WorkAreaEntry.css';

type Profile = pr.isp.profile.Profile;
type WorkArea = pr.isp.profile.work_areas.WorkArea;

type Props = {
  profile: Profile;
  workArea: WorkArea;
  summary?: ClientSummary;
  activeWorkArea?: string | null;
  openWorkArea: (workAreaId: string) => void;
}

export default class WorkAreaEntry extends React.Component<Props> {
  render () {
    const { profile, workArea, summary, activeWorkArea, openWorkArea } = this.props;

    const assignedDriver = (profile.drivers || {})[workArea.driver || ''];
    const assignedVehicle = (profile.vehicles || {})[workArea.vehicle || ''];
    const isManual = summary && (summary.source === 'Pickup Manifest' || summary.source === 'Delivery Manifest');

    return (
      <div className='WorkAreaEntry' style={(activeWorkArea && activeWorkArea !== workArea.workArea) ? {backgroundColor: '#f5f5f5'} : {}}>
        <div className='top' onClick={() => openWorkArea(workArea.workArea)}>
          <div className='activeLightWrap'>
            <div className={`activeLight${summary ? ' active' : ''}`} />
          </div>

          <h3 className='id'>{ workArea.workArea }</h3>

          {
            isManual
              ? <div className='manual'>
                MANUAL
              </div>
              : null
          }

          <div className={workArea.paid ? 'paid' : 'unpaid'}>
            { workArea.paid ? 'PAID' : 'UNPAID' }
          </div>
        </div>

        {
          activeWorkArea === workArea.workArea &&
          <div className='body'>
            <div className='row border-top' style={{ flex: 4 }}>
              <div className='section border-right'>
                <div className='section column pad'>
                  <div className='data-point'>
                    <Icon name='arrow-up-bold' size={30} color='#006DAB' />

                    <h4>
                      <span>
                        {
                          summary
                            ? summary.pickupsCompleted || 0
                            : 'X'
                        }
                      </span>

                      /

                      {
                        summary
                          ? summary.totalPickups || 0
                          : 'X'
                      }
                    </h4>
                  </div>

                  <div className='data-point'>
                    <Icon name='arrow-down-bold' size={30} color='#168E32' />

                    <h4>
                      <span>
                        {
                          summary
                            ? summary.deliveriesCompleted || 0
                            : 'X'
                        }
                      </span>

                      /

                      {
                        summary
                          ? summary.totalDeliveries || 0
                          : 'X'
                      }
                    </h4>
                  </div>

                  <div className='data-point'>
                    <Icon name='alert-circle' size={30} color='#B90C0F' />

                    <h4>
                      <span style={{ color: '#B90C0F' }}>
                        {
                          summary
                            ? (summary.deliveryExceptions || 0) + (summary.pickupExceptions || 0)
                            : 'X'
                        }
                        {` exceptions`}
                      </span>
                    </h4>
                  </div>
                </div>

                <div className='section column pad'>
                  <div className='data-point'>
                    <Icon name='package-variant-closed' size={25} color='#006DAB' />

                    <h4>
                      <span>
                        {
                          summary
                            ? summary.pickedUpPkgs || 0
                            : 'X'
                        }
                        {` pkgs`}
                      </span>
                    </h4>
                  </div>

                  <div className='data-point'>
                    <Icon name='package-variant-closed' size={25} color='#168E32' />

                    <h4>
                      <span>
                        {
                          summary
                            ? summary.deliveredPkgs || 0
                            : 'X'
                        }
                        {` pkgs`}
                      </span>
                    </h4>
                  </div>
                </div>
              </div>

              <div className='section'>
                <Map
                  defaultCenter={workArea.location}
                  options={{
                    disableDefaultUI: true,
                    zoomControl: true
                  }}>
                  <WorkAreaMarker workArea={workArea} key={workArea.workArea} />
                </Map>
              </div>
            </div>

            <div className='row border-top' style={{ flex: 2 }}>
              <div className='section pad border-right column'>
                <div className='data-point'>
                  <h4 style={{ width: 175 }}>SCANNER DRIVER</h4>

                  <h4><span style={{ width: 50, textAlign: 'center' }}>
                    {
                      summary && summary.driverName
                        ? summary.driverName
                        : 'N/A'
                    }
                  </span></h4>
                </div>

                <div className='data-point'>
                  <h4 style={{ width: 175 }}>ASSIGNED DRIVER</h4>

                  <h4><span style={{ width: 50, textAlign: 'center' }}>
                    {
                      summary && assignedDriver
                        ? assignedDriver.first + ' ' + assignedDriver.last
                        : 'None'
                    }
                  </span></h4>
                </div>
              </div>

              <div className='section pad column'>
                <div className='data-point'>
                  <h4 style={{ width: 175 }}>SCANNER VEHICLE</h4>

                  <h4><span style={{ width: 50, textAlign: 'center' }}>
                    {
                      summary && summary.vehicleID
                        ? summary.vehicleID
                        : 'N/A'
                    }
                  </span></h4>
                </div>

                <div className='data-point'>
                  <h4 style={{ width: 175 }}>ASSIGNED VEHICLE</h4>

                  <h4><span style={{ width: 50, textAlign: 'center' }}>
                    {
                      summary && assignedVehicle
                        ? `${assignedVehicle.name} / ${assignedVehicle.number}`
                        : 'None'
                    }
                  </span></h4>
                </div>
              </div>
            </div>

            <div className='row pad border-top space' style={{ flex: 1 }}>
              <div className='data-point'>
                <h4 style={{ width: 175 }}>FIRST CLOSE</h4>

                <h4><span style={{ width: 50, textAlign: 'center' }}>
                  {
                    summary && summary.startTime
                      ? summary.startTime
                      : 'XX:XX'
                  }
                </span></h4>
              </div>

              <div className='data-point'>
                <h4 style={{ width: 175 }}>FINAL STOP</h4>

                <h4><span style={{ width: 50, textAlign: 'center' }}>
                  {
                    summary && summary.closeTime
                      ? summary.closeTime
                      : 'XX:XX'
                  }
                </span></h4>
              </div>

              <div className='data-point'>
                <h4 style={{ width: 175 }}>LAST TRANSMISSION</h4>

                <h4><span style={{ width: 50, textAlign: 'center' }}>
                  {
                    summary && summary.lastTransmission
                      ? summary.lastTransmission
                      : 'XX:XX'
                  }
                </span></h4>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}
