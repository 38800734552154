import React from "react";
import pr from "@packageroute/types-firebase";
import EditUserIsp from "./EditUserIsp";
import SearchInput from '../Search/SearchInput';
import IspEntry from '../Search/IspEntry';
import Section from '../Page/Section';
import ScrollView from '../Page/ScrollView';
import Button from '../Page/Button';
import Notice from '../Page/Notice';
import api from "../../utils/api";
import './EditUserISPs.css';

type Profile = pr.isp.profile.Profile & { id: string };
type AppUser = pr.users.User;

type Props = {
  liveISPList: Record<string, Profile>;
  user: AppUser;
  onEdit: () => void;
};

type State = {
  searchText: string;
  ispIdToAuthorize: string | null;
  notice: null | { severity?: 'error' | 'warning' | 'info', title: string, description?: string };
}

export default class EditUserISPs extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchText: '',
      ispIdToAuthorize: null,
      notice: null,
    }

    this.setFocusedISP = this.setFocusedISP.bind(this);
    this.setISPToAuthorize = this.setISPToAuthorize.bind(this);
    this.clearISPToAuthorize = this.clearISPToAuthorize.bind(this);
    this.authorizeISP = this.authorizeISP.bind(this);
    this.deAuthorizeISP = this.deAuthorizeISP.bind(this);
    this.addNotice = this.addNotice.bind(this);
    this.clearNotice = this.clearNotice.bind(this);
  }

  async setFocusedISP (ispId: string) {
    const {liveISPList, user} = this.props;

    if (user.ISP === ispId) {
      return this.addNotice(
        'User is already viewing operation',
        'Cannot switch to operation already in focus',
        'info'
      )
    }
    const profile = liveISPList[ispId];

    if (!profile) {
      return this.addNotice(
        'Cannot switch focused operation', 
        'No matching ISP profile found'
      )
    }

    await api.patch(`users/${user.uid}/focus/${ispId}`);

    this.props.onEdit();
  }

  setISPToAuthorize (ispId: string) {
    this.setState({ ispIdToAuthorize: ispId })
  }

  clearISPToAuthorize () {
    this.setState({ ispIdToAuthorize: null, notice: null })
  }

  async authorizeISP () {
    const {user, liveISPList} = this.props;
    const {ispIdToAuthorize} = this.state;

    if (!ispIdToAuthorize) {
      return this.addNotice(
        'Cannot authorize operation',
        'No operation selected',
      )
    }

    if (user?.ISPS?.[ispIdToAuthorize]) {
      return this.addNotice(
        'User is already authorized with operation',
        '',
        'warning',
      )
    }

    const profile = liveISPList[ispIdToAuthorize];

    if (!profile) {
      return this.addNotice(
        'Cannot authorize operation', 
        'No matching ISP profile found'
      )
    }

    try {
      const requestBody = {
        operationName: profile.CSPName || '',
        role: 'Driver',
        allowBilling: false,
      }
      
      await api.post(`/users/${user.uid}/authorize/${ispIdToAuthorize}`, requestBody);

      this.props.onEdit();
    } catch (err) {
      console.error(err);

      return this.addNotice(
        `Unable to authorize user with ${profile.CSPName}`,
        // @ts-ignore
        err?.message || '',
      )
    }
  }

  async deAuthorizeISP (ispId: string) {
    const {user, liveISPList} = this.props;

    const profile = liveISPList[ispId];

    if (!profile) {
      return this.addNotice(
        'Cannot de-authorize operation', 
        'No matching ISP profile found'
      )
    }

    if (profile.ownerUID === user.uid) {
      return this.addNotice(
        'Cannot de-authorize operation',
        'User is operation owner',
      )
    }
    
    await api.post(`/users/${user.uid}/deauthorize/${ispId}`);

    this.props.onEdit();
  }

  addNotice (title: string, description?: string, severity?: 'error' | 'warning' | 'info') {
    this.setState({ notice: { title, description, severity }});
  }

  clearNotice () {
    this.setState({ notice: null })
  }

  render() {
    const { user, liveISPList } = this.props;
    const { searchText, ispIdToAuthorize, notice } = this.state;

    const operations = Object.keys(user.ISPS || {}).sort((ispidA, ispidB) => {
      const profileA = liveISPList[ispidA];
      const profileB = liveISPList[ispidB];

      if (!profileA) return 1;
      if (!profileB) return -1;

      return profileA.CSPName < profileB.CSPName ? -1 : 1;
    });

    return (
      <div
        className='EditUserISPs'
        style={{
          minWidth: 560,
          padding: 20,
          borderRadius: 10,
          backgroundColor: "white",
        }}
      >
        {!!notice &&
          <Notice
            severity={notice.severity}
            title={notice.title}
            description={notice.description}
            clear={this.clearNotice}
          />
        }
        
        {!ispIdToAuthorize && 
          !Object.keys(liveISPList).length 
            ? <Notice
                severity='info'
                loading
                title='No Operations loaded'
                description='This sometimes takes a while...'
              />
            : <Section title='Authorized ISPs'>
                {!!operations.length 
                  ? (operations.map((ispId) => {
                    const isp = liveISPList[ispId];

                    if (!isp) return (
                      <div
                        style={{
                          minHeight: 50,
                          padding: 10,
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {`Operation ${ispId} not found}`}
                      </div>
                    );

                    return (
                      <EditUserIsp
                        user={user}
                        isLastActive={user.ISP === ispId}
                        onSelect={this.setFocusedISP}
                        onDelete={this.deAuthorizeISP}
                        isp={liveISPList[ispId]}
                      />
                    );
                  })
                ) : (
                  <div
                    style={{
                      minHeight: 50,
                      padding: 10,
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    User has no operations
                  </div>
              )
            }
            </Section>
        }

        <div style={{ marginTop: 10 }}>
          {!ispIdToAuthorize &&
            <SearchInput 
              sectionTitle='Add ISP'
              placeholder='Enter ISP Name, Station ID, or ISPID...'
              onChange={(event) => this.setState({ searchText: event.target.value })}
              value={searchText}
            />
          }

          {!!searchText && !ispIdToAuthorize &&
            <ScrollView 
              direction='vertical'
              height={300}
            >
              {Object.keys(liveISPList || {}).reduce<JSX.Element[]>((acc, ispid) => {
                const profile = liveISPList[ispid];

                if (!searchText) return acc;
                if (!profile) return acc;
                
                if (
                  (profile.CSPName ?? '').toLowerCase().includes(searchText.toLowerCase()) ||
                  (profile.ISPID ?? '').toLowerCase().includes(searchText.toLowerCase()) ||
                  (profile?.station?.stationID ?? '').toLowerCase().includes(searchText.toLowerCase())
                ) {
                  acc.push(
                    <IspEntry
                      key={`isp ${ispid}`}
                      isp={{}}
                      profile={profile}
                      matches={{}}
                      onClick={() => this.setState({ispIdToAuthorize: ispid })}
                    />
                  );
                }


                return acc;
                }, [])
              }
            </ScrollView>
          }

          {!!ispIdToAuthorize &&
            <div className='authorize-operation-wrap'>
              <IspEntry
                key={`isp ${ispIdToAuthorize}`}
                isp={{}}
                profile={liveISPList[ispIdToAuthorize]}
                matches={{}}
                onClick={() => {}}
              />
              <div className='authorize-operation-button-wrap'>
                <Button onClick={this.clearISPToAuthorize} color='#B90C0E'>Cancel</Button>
                <Button onClick={this.authorizeISP} color='#0477B4'>Authorize</Button>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}
