import ConsoleApiService, {ConsoleApiError} from './ConsoleApiService';
import {IConsoleApiInitializer} from "./ConsoleApiInitializer";

export class UserDeletionFailedError extends Error {
  name = 'UserDeletionFailedError';
}

export class UserDeletionFailedWithoutSendingEmailError extends Error {
  name = 'UserDeletionFailedWithoutSendingEmailError';
}

export default class ConsoleUserService {
  private api: ConsoleApiService;

  constructor(consoleApiInitializer: IConsoleApiInitializer) {
    this.api = consoleApiInitializer.initialize();
  }

  async deleteUser(userUid: string, sendEmailNotices: boolean): Promise<void> {
    try {
      const path = this._getApiUsePath(userUid);
  
      await this.api.delete(path, { 'prevent_email_from_being_sent': !sendEmailNotices});
    } catch (err) {
      console.error(err);

      if (err instanceof ConsoleApiError) {
        if (err.statusCode === 503) {
          throw new UserDeletionFailedWithoutSendingEmailError(err.message);
        } else {
          throw new UserDeletionFailedError(err.message);
        }
      } else if (err instanceof Error) {
        throw new UserDeletionFailedError(err.message);
      } else {
        throw new UserDeletionFailedError(`Unknown cause: ${err}`)
      }
    }
  }

  private _getApiUsePath(userUid: string) {
    return `users/${userUid}`;
  }
}
