import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TimeZoneState {
  status: Status;
  data: string;
}

export enum Status {
  INITIAL = 'INITIAL',
  UPDATED = 'UPDATED',
}

const DEFAULT_TIMEZONE = 'America/Los_Angeles';

const initialState: TimeZoneState = {
  status: Status.INITIAL,
  data: DEFAULT_TIMEZONE,
};

const timeZoneSlice = createSlice({
  name: 'timeZone',
  initialState: initialState,
  reducers: {
    receiveTimeZone: (state, action: PayloadAction<{ timeZone: string }>) => {
      const { timeZone } = action.payload;

      state.data = timeZone;
      state.status = Status.UPDATED;
    },
    resetTimeZone: (state) => {
      state.data = DEFAULT_TIMEZONE;
      state.status = Status.INITIAL;
    }
  }
});

export const { 
  receiveTimeZone,
  resetTimeZone,
} = timeZoneSlice.actions

export default timeZoneSlice.reducer;
