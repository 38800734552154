import React from 'react';
import './InputDate.css';

export default function InputDate (props) {
  const {
    label,
    value,
    onChange,
    style,
    name,
    id,
    required,
    min
  } = props;
  return (
    <div className='InputDate' style={style}>
      {
        !!label &&
        <label htmlFor={id}>{ label }</label>
      }

      <input
        name={name}
        type='date'
        value={value}
        min={min}
        onChange={onChange}
        required={required}
      />
    </div>
  );
}
