import React from 'react';
import moment from 'moment-timezone';
import Icon from '../../Icon/Icon';
import { LiveIspProfile } from '@reducers/liveIspList';
import { User } from '@reducers/users';
import './ReferredUser.css';

type Props = {
  user: User | null;
  referrer?: User | null;
  statusLabel: string;
  referredByAdvocate?: boolean;
  isps: LiveIspProfile[];
  goToUserPage: (userId: string) => void;
  color: string;
  completedAt?: number;
}

export default function ReferredUser ({
  user, 
  referrer, 
  referredByAdvocate, 
  isps, 
  statusLabel, 
  goToUserPage, 
  color, 
  completedAt
}: Props) {

  return (
    <div className='Referee' onClick={() => goToUserPage(user!.uid)}>
      <div className='info'>
        <Icon name='account' size={25} color='#006DAB' />

        <p style={{ marginLeft: '5px' }}>{user!.first} {user!.last}</p>

        {
          isps.map((profile, i) => {
            return (
              <p
                key={i}
                style={{ fontSize: '12px', padding: '5px', color: '#999' }}
              >
                {profile.CSPName} - {profile.station.stationID}
              </p>
            );
          })
        }
      </div>

      <div className='status'>
        {
          !!referrer &&
          <div
            className={`referrer ${referredByAdvocate ? 'advocate' : ''}`} onClick={(e) => {
            e.stopPropagation();

            goToUserPage(referrer && referrer.uid);
          }}>
            <p style={{ fontSize: '12px' }} >
              <span style={{ fontSize: '8px' }}>Referred by: </span>
              {`${referrer.first} ${referrer.last}`}
            </p>
          </div>
        }

        {
          completedAt
            ? <p style={{ marginRight: '10px', fontSize: '12px' }}>
              {moment(completedAt).tz('America/Los_Angeles').format('MMM, Do, YYYY')}
            </p>
            : null
        }

        <p style={{ color: color, fontSize: '16px', fontWeight: 400 }}>
          {statusLabel}
        </p>
      </div>
    </div>
  );
}
