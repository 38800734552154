import React from "react";
import {connect, ConnectedProps} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import PageWrapper from "../Page/PageWrapper";
import PageTitle from "../Page/PageTitle";
import Section from '../Page/Section';
import ManualManifest from "./ManualManifest/ManualManifest";
import HighQualityGeocodingList from './HighQualityGeocoding/HighQualityGeocodingList';
import withPermissions from '../Permissions/withPermissions';
import {Status} from '@reducers/liveIspList';
import {RootState} from '@reducers';
import * as selectors from '@selectors';
import "./ServiceManagement.css";

type Props = {
  history: RouteComponentProps['history'];
  manualManifestList: any;
  fetchManualManifestList: any;
} & PropsFromRedux;

class ServiceManagement extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div className="ServiceManagement">
        <PageWrapper>
          <PageTitle>Service Management</PageTitle>

          <Section title='High Quality Geocoding' loading={this.props.listISPListStatus !== Status.UPDATED}>
            <HighQualityGeocodingList
              liveISPList={this.props.liveISPList}
            />
          </Section>

          <ManualManifest
            history={this.props.history}
            liveISPList={this.props.liveISPList}
            manualManifestList={this.props.manualManifestList}
            fetchManualManifestList={this.props.fetchManualManifestList}
          />
        </PageWrapper>
      </div>
    );
  }
}

function mapStateToProps (state: RootState) {

  return {
    liveISPList: selectors.liveIspList.selectLiveIspList(state),
    listISPListStatus: state.liveIspList.status,
  };
}

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(withPermissions(ServiceManagement));