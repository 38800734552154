import React from 'react';
import moment from 'moment-timezone';

import './ActiveISPList.css';
import ActiveISPItem from './ActiveISPItem';

export default class ActiveISPList extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      searchText: null
    };
  }

  render () {
    const { usage, profiles, activeDate } = this.props;
    const usageDate = (usage || {})[activeDate];
    const activeISPs = usageDate && usageDate.activeISPs;

    return (
      <div className='ActiveISPList'>
        <div className='search'>
          <input
            type='text'
            placeholder={`By ISP name or station (${moment(activeDate, 'YYYY-MM-DD').calendar(null, {
              sameDay: '[Today]',
              nextDay: '[Tomorrow]',
              nextWeek: 'dddd',
              lastDay: '[Yesterday]',
              lastWeek: '[Last] dddd',
              sameElse: 'M-DD-YYYY'
            })})`}
            onChange={e => this.setState({ searchText: e.target.value })}
            value={this.state.searchText || ''}
          />
        </div>

        <div className='scrollable'>
          {
            !!activeISPs && Object.keys(activeISPs)
              .filter(ISPID => {
                const profile = (profiles || {})[ISPID];

                if (!profile) return false;

                const stationId = profile.station ? profile.station.stationID : 'No station Id';
                const name = (profile.CSPName || '').toLowerCase();

                const text = (this.state.searchText || '').toLowerCase();

                if (!text) return true;
                if (text.includes((stationId || '').toLowerCase())) return true;
                if (name.includes(text)) return true;

                return false;
              })
              .map((ISPID) => {
                const profile = (profiles || {})[ISPID];

                return (
                  <div className='ActiveListItem'>
                    <ActiveISPItem
                      key={ISPID}
                      profile={profile}
                      onClick={() => this.props.setActiveISP(ISPID)}
                    />
                  </div>
                );
              })
          }
        </div>
      </div>
    );
  }
}
