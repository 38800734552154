import React from "react";
import "./UserTrialControl.css";

import moment from 'moment';
import Button from "../Page/Button";
import api from "../../utils/api";


class UserTrialControl extends React.Component {
  constructor (props) {
    super(props);

    this.state = { setAnway: false };

    this.submit = this.submit.bind(this);
  }

  onChange (name, value) {
    this.setState({
      [name]: value
    });
  }

  submit (fieldName) {
    const { user } = this.props;

    const fields = {
      trialStartedAt: 'newTrialStartedAt',
      trialEndAt: 'newTrialEndAt',
    };
    const fieldToUpdate = fields[fieldName];
    const value = this.state[fieldToUpdate];

    const timestamp = moment(value + ' 05:00', 'YYYY-MM-DD HH:mm').valueOf() / 1000;
    
    api.post(`Users/updateTrialValue/${user.FedExId}`, { fieldName: fieldName, timestamp })
      .then((res) => {
        this.props.loadUser();
      })
      .catch(err => console.log(err));
  }

  render () {
    const { setAnway, newTrialEndAt, newTrialStartedAt } = this.state;
    const { user } = this.props;

    if (!user) return null;

    const { xidNodeData } = user;
    const { trialEndAt, trialStartedAt } = (xidNodeData || {});

    const trialEndMoment = trialEndAt
      ? moment(trialEndAt * 1000)
      : null;

    const trialStartedMoment = trialStartedAt
      ? moment(trialStartedAt * 1000)
      : null;

    return (
      <div className='UserTrialControl'>
        { (!!trialEndAt && !!trialStartedAt) || setAnway
          ? <React.Fragment>
            <TrialControl
              name='trialStartedAt'
              momObj={trialStartedMoment}
              value={newTrialStartedAt}
              submit={() => this.submit('trialStartedAt')}
              onChange={e => this.onChange('newTrialStartedAt', e.target.value)}
            />

            <TrialControl
              name='trialEndAt'
              momObj={trialEndMoment}
              value={newTrialEndAt}
              submit={() => this.submit('trialEndAt')}
              onChange={e => this.onChange('newTrialEndAt', e.target.value)}
            />
          </React.Fragment>
          : <div className='noDataWrap'>
            <p style={{ marginBottom: 10 }}>No trial data found, this person may not have 'customer' data.</p>

            <Button color='#006DAB' onClick={() => this.setState({ setAnway: true })}>Set Anyway</Button>
          </div>
        }
      </div>
    );
  }
}

function TrialControl ({ name, momObj, value, onChange, submit }) {
  return (
    <div className='TrialControl'>
      <strong>{ name }</strong>

      <div style={{flex: 1}}>
        { momObj
          ? <p className='fromNow'>{ momObj.fromNow() }</p>
          : <p className='fromNow grey'> NO DATA</p>
        }
      </div>

      <input
        type='date'
        value={value || (momObj ? momObj.format('YYYY-MM-DD') : null)}
        onChange={onChange}
      />

      <div
        className={`changeButton${value ? ' available' : ''}`}
        onClick={value ? submit : () => {}}
      >CHANGE</div>
    </div>
  );
}

export default UserTrialControl;
