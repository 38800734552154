import React from 'react';
import Stripe from 'stripe';
import moment from 'moment';
import {PortalModal} from '../../Page/Modal';
import Notice, {Severity} from '../../Page/Notice';
import './Bill.css';
import LineItems from "@components/Payment/Bill/LineItems";

/**
 * Not entirely sure what this is matching. Thought I knew.
 * Doesn't match: Route 1043 after 30 Sep 2021
 * Doesn't match: 3 × Routes (at $20.00 / month)
 *
 */
const dataExtractExp = (/(\d+) (×|new) .+ (\d{1,2}) (\w{1,4}) (\d{4})$/);

type StripeInvoice = Stripe.invoices.IInvoice;
type StripeSubscription = Stripe.subscriptions.ISubscription;
type StripeInvoiceLineItem = Stripe.invoices.IInvoiceLineItem;
export type StripeDiscount = Stripe.coupons.ICoupon;


type Props = {
  operationId: string;
  nextInvoice: StripeInvoice | undefined | null;
  nextInvoiceWithChanges?: StripeInvoice;
  newBill?: any;
  subscription?: StripeSubscription;
}

type State = {
  notice: null | {
    severity: Severity;
    title: string;
    description?: string;
  },
}

export default class Bill extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props);

    this.state = {
      notice: null,
    }

    this.closeNotice = this.closeNotice.bind(this);
  }


  closeNotice () {
    this.setState({ notice: null });
  }

  render() {
    const { nextInvoice, nextInvoiceWithChanges, newBill, subscription } = this.props;
    const { notice } = this.state;

    if (!nextInvoice) return null;

    const { lines, date, amount_due, period_start, period_end } = nextInvoice;
    const { data } = lines || {};
    // @ts-ignore date may be undefined
    const paymentDateMoment = moment(date * 1000);
    const periodStartDate = moment(period_start * 1000).format('MM/DD/YYYY');
    const periodEndDate = moment(period_end * 1000).format('MM/DD/YYYY');
    // const nextBillDue = paymentDateMoment.format('MM/DD/YYYY');
    const oldBill = nextInvoiceWithChanges && !newBill;

    const { subscriptionLineItems, prorationItems } =
        getSubscriptionLineItemsAndProrations(
            data,
            paymentDateMoment?.format('D MMM YYYY') || 'payment date'
        );

    const { customer } = subscription || {};
    const customerObject = !!customer && typeof customer !== 'string'
      ? customer
      : { discount: null };
    const { discount } = customerObject;
    const { coupon } = discount || {};


    const actualDue = getAmountDueIncludingDiscount(
        amount_due,
        nextInvoice,
        coupon
    );

    const totalDue = getDisplayCurrency(actualDue);

    return (
      <div className={`Bill${newBill ? ' preview' : ''}${oldBill ? ' old' : ''}`}>
        <PortalModal
          visible={!!notice}
          centerFullScreen
          isMobile={false}
          onRequestClose={this.closeNotice}
        >
          {!!notice
            ? <Notice
                severity={notice.severity}
                title={notice.title}
                description={notice.description}
              />
            : null
          }
        </PortalModal>

        <div className='dates'>Billing period {periodStartDate} - {periodEndDate}</div>

        {subscriptionLineItems && subscriptionLineItems.length > 0 && (
            <LineItems title={'Subscriptions'} items={subscriptionLineItems} />
        )}

        {prorationItems && prorationItems.length > 0 && (
            <LineItems title={'Prorated Subscription'} items={prorationItems} />
        )}


        <div className='LineItem total'>
          <div className='description'>TOTAL</div>
          <div className='cost' style={{ marginRight: 30 }}>${totalDue}</div>
        </div>
      </div>
    );
  }
}


type LineItemsAndProrations = {
  subscriptionLineItems: StripeInvoiceLineItem[];
  prorationItems: ProratedItem[];
};

type ProratedItem = {
  id: string;
  proration: boolean;
  description: string;
  amount: number;
  type: string;
};

type ProratedItemAccumulator = Record<string, { qty: string; value: number }[]>;

function getSubscriptionLineItemsAndProrations(
    invoices: StripeInvoiceLineItem[],
    endDate: string
): LineItemsAndProrations {
  const proItemAcc: ProratedItemAccumulator = {};
  const proItems: ProratedItem[] = [];
  const miscItems = [];
  const subItems: StripeInvoiceLineItem[] = [];

  if (!invoices)
    return { subscriptionLineItems: subItems, prorationItems: proItems };

  for (const line of invoices) {
    const dataMatch = line.description.match(dataExtractExp);
    if (line.type === 'subscription') {
      subItems.push(line);
    } else if (dataMatch) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_match, qty, _multiplier, day, month, year] = dataMatch;
      const date = `${day} ${month} ${year}`;

      if (!proItemAcc[date]) {
        proItemAcc[date] = [];
      }

      proItemAcc[date].push({
        qty,
        value: line.amount,
      });
    } else {
      miscItems.push(line);
    }
  }

  for (const date in proItemAcc) {
    const entry = proItemAcc[date];
    let totalDelta = 0;

    for (const line of entry) {
      totalDelta += line.value;
    }

    proItems.push({
      id: date,
      proration: true,
      description: `New routes from ${date} to ${endDate}`,
      amount: totalDelta,
      type: 'proration',
    });
  }

  return {
    subscriptionLineItems: subItems,
    prorationItems: [...proItems, ...miscItems],
  };
}

function getAmountDueIncludingDiscount(
    amountDue: number,
    nextInvoice: StripeInvoice,
    customerDiscount: StripeDiscount | null | undefined
) {
  if (customerDiscount && customerDiscount.valid && !nextInvoice.discount) {
    // @ts-ignore precent_off_precise does not exist in type
    return amountDue * (1 - customerDiscount.percent_off_precise / 100);
  } else {
    return amountDue;
  }
}

/**
 * Stripe stores currency as an integer. Representing as dollars and cents
 * requires representing as decimal with two decimal places.
 * 1000 => 10.00
 */
export function getDisplayCurrency(currency: number): string {
  return (currency / 100).toFixed(2);
}
