import axios from "axios";
import ConsoleApiService, { ConsoleApiConstructor } from "./ConsoleApiService";
import bugsnagClient, { BugsnagClient } from "@utils/bugsnag";
import firebaseProvider, { FirebaseProvider } from "@utils/firebase";

export interface IConsoleApiInitializer {
  initialize: () => ConsoleApiService;
}

class ConsoleApiInitializer implements IConsoleApiInitializer {
  private client: typeof axios;
  private firebaseProvider: typeof firebaseProvider;
  private apiConstructor: ConsoleApiConstructor;
  private errorReportingClient: BugsnagClient;
  private api?: ConsoleApiService;

  constructor(
    client: typeof axios,
    firebaseProvider: FirebaseProvider,
    apiConstructor: ConsoleApiConstructor,
    errorReportingClient: BugsnagClient
  ) {
    this.client = client;
    this.firebaseProvider = firebaseProvider;
    this.apiConstructor = apiConstructor;
    this.errorReportingClient = errorReportingClient;
  }

  initialize(): ConsoleApiService {
    if (this.api) return this.api;
    
    const api = new this.apiConstructor(
      this.client,
      this.firebaseProvider,
      this.errorReportingClient
    );

    this.api = api;

    return api;
  }
}

export default new ConsoleApiInitializer(
  axios,
  firebaseProvider,
  ConsoleApiService,
  bugsnagClient
);
