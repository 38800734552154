import React from 'react';

import './style/note-tags.css';
import api from '../../../utils/api';

export default class NoteTags extends React.Component {
  constructor (props) {
    super(props);

    this.state = {

    }

    this.addTag = this.addTag.bind(this);
    this.removeTag = this.removeTag.bind(this);
  }

  async addTag (tagId) {
    try {
      const { noteId } = this.props;
      await api.post(`notes/tag`, { noteId, tagId });

      this.props.loadNoteTags();
    }
    catch (err) {
      console.log(err);
    }
  }

  async removeTag (tagId) {
    try {
      const { noteId } = this.props;
      await api.deleteRequest(`notes/tag`, { noteId, tagId });

      this.props.loadNoteTags();
    }
    catch (err) {
      console.log(err);
    }
  }

  render () {
    const { tags, noteTags } = this.props;

    return (
      <div className='NoteTags'>
        {
          tags && tags.map(tag => {
            const tagged = noteTags && noteTags.includes(tag.id);

            return (
              <div
                onClick={
                  noteTags && noteTags.includes(tag.id)
                    ? () => this.removeTag(tag.id)
                    : () => this.addTag(tag.id)
                }
                className={`tag ${tagged ? 'active' : ''}`}>
                <p>{tag.name}</p>
              </div>
            );
          })
        }
      </div>
    );
  }
}
