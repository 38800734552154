import { Dispatch } from "@reduxjs/toolkit";
import {
  updateActiveStations,
  resetActiveStations,
  ActiveStations,
} from "@reducers/stations/activeStations";
import firebaseProvider from '@utils/firebase';

export async function fetchActiveStations(dispatch: Dispatch) {
  try {
    const database = firebaseProvider.getConsoleDefaultDB();

    const ref = database.ref('stations/data');

    const snap = await ref.once('value');
    
    const activeStations: ActiveStations = snap.val() ?? {};

    dispatch(updateActiveStations({ activeStations }));
  } catch (err) {
    console.error(err);
  }
}

export async function clearActiveStations (dispatch: Dispatch) {
  dispatch(resetActiveStations());
}
