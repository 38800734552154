import React from 'react';

import './CoLocation.css';
import Icon from '../Icon/Icon';
import InputText from '../Page/InputText';
// import InputSwitch from '../Page/InputSwitch';
import api from '../../utils/api';

export default class CoLocation extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      locked: true,
      coLocationNumber: props.station.coLocationNumber || null
    }

    this.updateCoLocationNum = this.updateCoLocationNum.bind(this);
    this.enableCoLocation = this.enableCoLocation.bind(this);
    this.disableCoLocation = this.disableCoLocation.bind(this);
  }

  async updateCoLocationNum () {
    const { coLocationNumber } = this.state;
    const { station } = this.props;
    const { terminal } = station;

    if (coLocationNumber === station.coLocationNumber) return;

    if (!terminal) return null;

    await api.patch(`stations/co-location/${terminal}`, { coLocationNumber });

    this.props.fetchStation();
  }

  async enableCoLocation () {
    const { station } = this.props;
    const { terminal } = station;

    if (!terminal) return null;

    await api.patch(`stations/co-location/${terminal}`, { hasCoLocation: true });

    this.props.fetchStation();
  }

  async disableCoLocation () {
    const { station } = this.props;
    const { terminal } = station;

    if (!terminal) return null;

    await api.patch(`stations/co-location/${terminal}`, { hasCoLocation: false });

    this.props.fetchStation();
  }

  render () {
    const { station, editable = true } = this.props;

    return (
      <div className='CoLocation'>
        {
          editable
            ? <div
              className='locked'
              onClick={() => this.setState(prevState => {
                return { locked: !prevState.locked };
              })}>
              <Icon
                color='black'
                name={this.state.locked ? 'lock-outline' : 'lock-open-outline'}
                size='18px'
              />
            </div>
            : null
        }

        <div className='switch-wrapper'>
          <p
            onClick={editable && !this.state.locked
              ? station && station.hasCoLocation
                ? this.disableCoLocation
                : this.enableCoLocation
              : null
            }
            className={`text ${station.hasCoLocation === true ? 'active' : ''} ${!this.state.locked ? 'unlocked' : ''}`}>
            Co-Lo Station

            {
              station.hasCoLocation === true
                ? ` / ${station.coLocationNumber}`
                : null
            }
          </p>
        </div>

        {
          editable && !this.state.locked
            ? <div className='input-wrapper'>
              <InputText
                type='number'
                min='10'
                max='9999'
                disabled={this.state.locked}
                value={this.state.coLocationNumber}
                onBlur={!this.state.locked && this.updateCoLocationNum}
                onChange={!this.state.locked && ((e) => this.setState({ coLocationNumber: e.target.value }))}
                inputStyle={{
                  padding: '2px 5px'
                }}
              />
            </div>
            : null
        }
      </div>
    );
  }
}
