import { Dispatch } from '@reduxjs/toolkit';
import { FeatureFlagService } from '@packageroute/biz/lib/featureFlags';
import firebaseProvider from '@utils/firebase';
import { receiveFeatureFlags, remoteConfigFeatureFlags, firebaseFeatureFlags, resetFeatureFlags as _resetFeatureFlags } from '@reducers/featureFlags';


/**
 * Fetches global and operation level feature flag settings
 * Uses dispatch injection to allow for awaiting.
 * @param dispatch Dispatch
 * @param featureFlags string[]
 * @param operationId string - optional
 */
export async function fetchFeatureFlags (dispatch: Dispatch, operationId?: string) {
  const featureFlags = Object.keys(firebaseFeatureFlags);
  const fetchedFlags = await _fetchFeatureFlags(featureFlags, operationId);

  const payload = {
    featureFlags: fetchedFlags
  };

  dispatch(receiveFeatureFlags(payload));
}

async function _fetchFeatureFlags (featureFlags: string[], operationId?: string): Promise<Record<string, boolean>> {
  const featureFlagService = new FeatureFlagService(firebaseProvider.getConsoleFeatureFlagDB());

  const flagRequests = featureFlags.map(flag => featureFlagService.isEnabled(flag, operationId));
        
  const resolvedRequests = await Promise.all(flagRequests);

  return resolvedRequests.reduce<Record<string, boolean>>((acc, enabledState, i) => {
    const featureFlagKey = featureFlags[i];

    acc[featureFlagKey] = enabledState;

    return acc;
  }, {})
}

/**
 * Fetches Firebase remote config feature flags
 * Uses dispatch injection to allow for awaiting.
 * @param dispatch Dispatch
 * @param featureFlags string[]
 */
export async function fetchRemoteConfigFeatureFlags (dispatch: Dispatch) {
  const featureFlags = Object.keys(remoteConfigFeatureFlags);
  const fetchedFlags = await _fetchRemoteConfigFeatureFlags(featureFlags);

  const payload = {
    featureFlags: fetchedFlags
  };

  dispatch(receiveFeatureFlags(payload));
}

async function _fetchRemoteConfigFeatureFlags (featureFlags: string[]): Promise<Record<string, boolean>> {
  const flagRequests = featureFlags.map(flag => firebaseProvider.getConsoleRemoteConfig().getValue(flag));

  const resolvedRequests = await Promise.all(flagRequests);

  return resolvedRequests.reduce<Record<string, boolean>>((acc, config, i) => {
    const featureFlagKey = featureFlags[i];

    // @ts-ignore _value is correct typing
    acc[featureFlagKey] = !!config._value;

    return acc;
  }, {});
}

export function resetFeatureFlags (dispatch: Dispatch) {
  dispatch(_resetFeatureFlags);
}