import moment from 'moment';
import {DayOfWeek} from '@reporting/Reporting.Types';
import {DateRangeType} from '@reducers/reports';

const DATE_KEY = 'YYYY-MM-DD';

/**
 * Get start and end date of date range based upon DateRangeType input
 */
export default function getRangeFromType(
  type: DateRangeType,
  currentStartDate: string,
  currentEndDate: string,
  todayDate: string,
  weekStartDay: DayOfWeek,
): {startDate: string; endDate: string} {
  const today = moment(todayDate, DATE_KEY);
  const yesterday = today.clone().subtract(1, 'day');
  const weekStartDate = getStartOfWeek(yesterday, weekStartDay);
  const weekEndDate = getEndOfWeek(yesterday, weekStartDay);
  const fourWeeksAgo = weekEndDate.clone().subtract(27, 'days');
  const fourWeeksAgoFromYesterday = yesterday.clone().subtract(27, 'days');
  const monthStartDate = yesterday.clone().startOf('month');
  const monthEndDate = yesterday.clone().endOf('month');

  switch (type) {
    case DateRangeType.WEEK: {
      return {
        startDate: weekStartDate.format(DATE_KEY),
        endDate: weekEndDate.format(DATE_KEY),
      };
    }
    case DateRangeType.WEEK_TO_DATE: {
      return {
        startDate: weekStartDate.format(DATE_KEY),
        endDate: yesterday.format(DATE_KEY),
      };
    }
    case DateRangeType.FOUR_WEEKS: {
      return {
        startDate: fourWeeksAgo.format(DATE_KEY),
        endDate: weekEndDate.format(DATE_KEY),
      };
    }
    case DateRangeType.FOUR_WEEKS_TO_DATE: {
      return {
        startDate: fourWeeksAgoFromYesterday.format(DATE_KEY),
        endDate: yesterday.format(DATE_KEY),
      };
    }
    case DateRangeType.MONTH: {
      return {
        startDate: monthStartDate.format(DATE_KEY),
        endDate: monthEndDate.format(DATE_KEY),
      };
    }
    case DateRangeType.MONTH_TO_DATE: {
      return {
        startDate: monthStartDate.format(DATE_KEY),
        endDate: yesterday.format(DATE_KEY),
      };
    }
    case DateRangeType.CUSTOM:
    default: {
      return {
        startDate: currentStartDate,
        endDate: currentEndDate,
      };
    }
  }
}

export function getStartOfWeek(
  currentDate: moment.Moment,
  weekStartDay: DayOfWeek,
): moment.Moment {
  const date = currentDate.clone();
  const startDate = date.clone().isoWeekday(weekStartDay);

  if (startDate.diff(date, 'day') <= -7) startDate.add(7, 'days');

  if (date.isBefore(startDate)) return startDate.subtract(1, 'week');
  else return startDate;
}

export function getEndOfWeek(
  currentDate: moment.Moment,
  weekStartDay: DayOfWeek,
): moment.Moment {
  const date = currentDate.clone();
  const endDate = date.clone().isoWeekday(weekStartDay).subtract(1, 'day');

  if (endDate.diff(date) <= -7) endDate.add(7, 'days');

  if (date.isAfter(endDate)) return endDate.add(1, 'week');
  else return endDate;
}
