import { useAppSelector } from "../store";
import {
  selectConsoleUsers,
  selectConsoleUser,
  selectConsoleUsersLoaded,
} from "@selectors/consoleUsers";

export function useConsoleUsers() {
  return useAppSelector(selectConsoleUsers);
}

export function useConsoleUser() {
  return useAppSelector(selectConsoleUser);
}

export function useConsoleUsersLoaded() {
  return useAppSelector(selectConsoleUsersLoaded);
}
