import React from 'react';

import PageWrapper from '../Page/PageWrapper';
import Section from '../Page/Section';
import Icon from '../Icon/Icon';
import './NoPermission.css';

export default function NoPermission (props) {
  return (
    <PageWrapper>
      <Section>
        <div className='no-permission'>
          <Icon size={150} color='#B90C0F' name='cancel' />

          <h1>You do not have permission to access this page.</h1>
        </div>
      </Section>
    </PageWrapper>
  );
}
