import { useCallback } from 'react';
import { useThunkAppDispatch } from '../store';
import * as actions from '@actions';
import { DateRangeType } from '@reducers/reports/dateRange';
import { enumerateDateRange } from '@reporting/utils';
import { fetchPreviousDaysSignups } from '@actions/signups';
import { fetchPreviousDaysActivity } from '@actions/metrics/dailyOperationsActivity';
import { fetchPreviousDaysUsage } from '@actions/metrics/dailyOperationsUsage';
import { fetchPreviousDaysDailyActiveUsers } from '@actions/metrics/dailyActiveUsers';
export {useReportsDateRange} from './dateRange';

export function useLoadMetricsOnDateRangeChange () {
  const dispatch = useThunkAppDispatch();


  const updateDateRange = useCallback((startDate: string, endDate: string, type: DateRangeType) => {
    const dates = enumerateDateRange(startDate, endDate);

    dispatch(actions.reports.updateDateRange(startDate, endDate, type));

    dispatch(fetchPreviousDaysDailyActiveUsers(dates));
    dispatch(fetchPreviousDaysSignups(dates));
    dispatch(fetchPreviousDaysUsage(dates));
    dispatch(fetchPreviousDaysActivity(dates));
  }, [dispatch]);
  
  return {
    updateDateRange,
  }
}
