import React, {useState} from 'react';
import './Login.css';

import PageWrapper from '../Page/PageWrapper';
import PageTitle from '../Page/PageTitle';
import Section from '../Page/Section';
import Button from '../Page/Button';
import {FirebaseUser} from '../../utils/firebase';
import PageSpinner from '../Page/PageSpinner';
import firebase from "firebase";
import GoogleAuthProvider = firebase.auth.GoogleAuthProvider;


type Props = {
  login: (firebaseUser: FirebaseUser) => void;
}

const provider = new GoogleAuthProvider();
provider.setCustomParameters({
  prompt: "select_account",
});
const Login = ({login}: Props) => {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const loginWithGoogle = async () => {
    setIsLoggingIn(true);
    firebase.auth().signInWithPopup(provider).then((result) => {
      const user = result.user;
      login(user!);
    }).catch((e: Error) => {
      console.error(e);
      setError(e);
    }).finally(() => {
      setIsLoggingIn(false);
    });
  }

  if (isLoggingIn) {
    return (
        <PageSpinner/>
    )
  }

  return (
      <div className='Login'>
        <PageWrapper>
          <PageTitle>Login</PageTitle>

          <Section>
            <div style={{padding: 20}}>

              <div style={{height: 20}}/>


              <Button color='#168E32' onClick={loginWithGoogle}>Login via Google</Button>'

              {error && <div className='error'>{error.message}</div>}
            </div>
          </Section>
        </PageWrapper>
      </div>
  );
}

export default Login;