import React from 'react';
import { mergeStyles, Styles } from './utils';
import './View.css';

interface NativeLayout {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface NativeEvent {
  layout: NativeLayout;
}

export interface LayoutChangeEvent {
  nativeEvent: NativeEvent;
}

interface Props {
  style?: Styles;
  className?: string;
  children?: any;
  pointerEvents?: 'box-none' | 'none' | 'auto' | null;
  onPress?: ((event?: React.SyntheticEvent) => void) | null;
  onClick?: ((event?: React.SyntheticEvent) => void) | null;
  onHoverEnter?: ((event?: React.SyntheticEvent) => void) | null;
  onHover?: ((event?: React.SyntheticEvent) => void) | null;
  onHoverLeave?: ((event?: React.SyntheticEvent) => void) | null;
  snapOnScroll?: boolean;
  scrollSnapAlign?: string;
  scrollRef?: (ref: React.RefObject<HTMLDivElement>) => void;
  onLayout?: (nativeEvent: LayoutChangeEvent) => void;
}

interface State {
  onLayoutCalled: boolean;
  refSet: boolean;
}

export default class View extends React.PureComponent<Props, State> {
  ViewRef!: React.RefObject<HTMLDivElement>; 
  settingRef: boolean;
  settingLayout: boolean;

  constructor (props: Props) {
    super(props);

    this.state = {
      onLayoutCalled: false,
      refSet: false
    };

    this.settingRef = false;
    this.settingLayout = false;
    this.ViewRef = React.createRef<HTMLDivElement>();
  }

  componentDidMount () {
    if (this.props.scrollRef && !this.settingRef) this.setRef();

    if (this.props.onLayout && !this.settingLayout) this.onLayout();
  }

  componentDidUpdate () {
    if (this.props.scrollRef && !this.settingRef) this.setRef();

    if (this.props.onLayout && !this.settingLayout) this.onLayout();
  }

  setRef = () => {
    this.settingRef = true;
    if (!this.state.refSet && this.ViewRef) {

      this.setState({ refSet: true }, () => {
        this.settingRef = false;
        this.props.scrollRef?.(this.ViewRef);
      })
    }
  }

  onLayout () {
    this.settingLayout = true;

    if (!this.state.onLayoutCalled && this.ViewRef && this.ViewRef.current) {
      const el = this.ViewRef.current;
      const height = el.scrollHeight;
      const width = el.scrollWidth;
      const x = el.offsetLeft - el.scrollLeft + el.clientLeft;
      const y = el.offsetTop - el.scrollTop + el.clientTop;

      const layoutChangeEvent: LayoutChangeEvent = {
        nativeEvent: {
          layout: {
            x,
            y,
            width,
            height
          }
        }
      }
  
      this.setState({ onLayoutCalled: true }, () => {
        this.settingLayout = false;
        this.props.onLayout && this.props.onLayout(layoutChangeEvent);
      })
    }
  } 
    
  render () {
    const {
      style,
      pointerEvents = null,
      className = "",
      children = null,
      onPress,
      onClick = () => null,
      snapOnScroll = false,
      scrollSnapAlign = 'center'
    } = this.props;

    return (
      <div 
        className={`View ${className}`}
        style={{
          ...mergeStyles(style),
          ...(pointerEvents ? { pointerEvents: pointerEvents === 'box-none' ? 'none' : pointerEvents } : {}),
          ...(snapOnScroll ? { scrollSnapAlign } : {})
        }}
        onClick={onPress || onClick || undefined}
        onMouseEnter={this.props.onHoverEnter || undefined}
        onMouseOver={this.props.onHover || undefined}
        onMouseLeave={this.props.onHoverLeave || undefined}
        ref={this.ViewRef}
      >
        {children}
      </div>
    );
  }
}