import React from 'react';
import { Link } from 'react-router-dom';

import './StationISPListItem.css';
import DriversFraction from '../Signups/DriversFraction';
import WorkAreasFraction from '../Signups/WorkAreasFraction';

export default function StationISPListItem (props) {
  const { isp, profile, stationNum } = props;

  return (
    <Link className='StationISPListItem' to={`/isps/${isp.id}`}>
      <p className='cspName'>
        { profile ? profile.CSPName : isp.entityName }
      </p>

      <div className='info'>
        <div className='infoWrap'>
          <WorkAreasFraction profile={profile} />
        </div>
        <div className='infoWrap'>
          <DriversFraction profile={profile} />
        </div>
      </div>
    </Link>
  );
}
