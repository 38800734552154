import React from "react";

import "./Details.css";

export default function Details({ className = "", display = false, item }) {
  const { response, recipients, message, error, errorStack } = item;

  return (
    <div className={`Details ${display ? "" : "hide"} ${className}`}>
      {response && response.recipients && <p className='detail'>Sent to {response.recipients.length} Users</p>}
      <div className='sent-to'>
        {message.messageFilters && <p className='detail'>Message Filters:</p>}
        {message.messageFilters.hasOwnProperty("excludeInactives") && (
          <p className='detail'>
            {" "}{message.messageFilters.excludeInactives ? "Exclude" : "Include"}{" "}
            Inactive Users
          </p>
        )}
        {message.messageFilters && message.messageFilters.userType && (
          <p className='detail'>{" "}User Type: {message.messageFilters.userType}</p>
        )}
      </div>
      {error && <p className='detail'>Error Cause: {error}</p>}
      {errorStack && <p className='errorStack'><code>{errorStack}</code></p>}
    </div>
  );
}

// const messageDetails = {
//   error: "Empty recipients list",

//   message: {
//     messageName: "Service_Note_v1",
//     sentAt: 1554595356737,
//     sentByUID: "TgUn6nMR1dbHOpzOwrlnBJd0kLT2",
//     success: true
//   },
//   messageFilters: {
//     excludeInactives: true,
//     userType: "owner"
//   },
//   excludeInactives: true,
//   userType: "owner",
//   messageSpecs: {
//     templateId: "d-c6f11d88f776493d821e2e5038acaa27"
//   },
//   messageType: "EMAIL",
//   recipients: [
//     {
//       id: "-Lb4q_Et3FhxYwGgdkta",
//       recipientType: "ISP"
//     }
//   ],
//   messageName: "Service_Note_v1",
//   sentAt: 1554595356737,
//   sentByUID: "TgUn6nMR1dbHOpzOwrlnBJd0kLT2",
//   success: true
// }
