import React, { CSSProperties } from 'react';
import { Text, TouchableOpacity, View, StyleSheet } from 'react-native';
import Icon from '../Icon/Icon';

interface Props {
  checkable?: boolean;
  checkColor?: string;
  checked?: boolean;
  deleteable?: boolean;
  disabled?: boolean;
  flipY?: boolean;
  indentColor?: string;
  indentLevel?: number;
  leftSideContent?: (string | JSX.Element | null)[] | JSX.Element | JSX.Element[] | number | string | string[] | undefined | null;
  noBorder?: boolean;
  onDelete?: ((event?: React.SyntheticEvent<Element, Event> | undefined) => void) | null;
  onPress?: ((event?: React.SyntheticEvent<Element, Event> | undefined) => void) | null;
  paddingRight?: number;
  rightSideContent?: (string | JSX.Element | null)[] | JSX.Element | JSX.Element[] | number | string | string[] | undefined | null;
  secondLineContent?: (string | JSX.Element | null)[] | JSX.Element | JSX.Element[] | number | string | string[] | undefined | null;
  text?: string;
  style?: CSSProperties;
  textContainerStyle?: CSSProperties;
}

const ButtonRow: React.FC<Props> = ({
  checkable,
  checkColor,
  checked,
  deleteable,
  disabled,
  indentColor,
  indentLevel,
  leftSideContent,
  noBorder,
  onDelete,
  onPress,
  paddingRight,
  rightSideContent,
  secondLineContent,
  text,
  flipY,
  style = {},
  textContainerStyle = {}
}) => {

  const touchProps = !disabled ? { onPress } : {};
  const Element = !disabled ? TouchableOpacity : View;

  return (
    <Element
      {...touchProps}
    >
      <View 
        style={{
          ...styles.body,
          ...{
            borderTopWidth: noBorder ? 0 : 1,
            opacity: disabled ? 0.5 : 1,
            flexDirection: flipY ? 'row-reverse' : 'row'
          },
          // @ts-ignore
          ...(!Number.isNaN(parseInt(paddingRight)) ? { paddingRight } : {}),
          ...style
        }}
      >
        <React.Fragment>
          { indentLevel &&
            <View style={{
              width: indentLevel * 15,
              backgroundColor: indentColor || 'transparent',
              height: '100%',
              marginRight: 10
            }} />
          }

          { leftSideContent || null }

          { checkable &&
            <CheckButton
              checkColor={checkColor}
              isChecked={checked}
              style={{
                marginRight: flipY ? 0 : 15,
                marginLeft: flipY ? 15 : 0
              }}
            />
          }

          { deleteable && !disabled &&
            <TouchableOpacity style={styles.deleteWrapper} onPress={onDelete}>
              <Icon
                name='close'
                size={25}
                color='#B90C0F'
              />
            </TouchableOpacity>
          }

          <View style={{flex: 1, paddingTop: 15, paddingBottom: 15, ...textContainerStyle }}>
            <Text style={styles.text}>
              {text}
            </Text>

            <React.Fragment>
              { secondLineContent || null }
            </React.Fragment>
          </View>

          { rightSideContent || null }
        </React.Fragment>
      </View>
    </Element>
  );
}

export default ButtonRow;

interface CheckButtonProps {
  style: CSSProperties;
  checkColor?: string;
  isChecked?: boolean;
}

export function CheckButton (props: CheckButtonProps) {
  const {
    style,
    checkColor,
    isChecked,
  } = props;

  return (
    <View style={{...styles.checkWrapper, ...style}}>
      <React.Fragment>
        { isChecked &&
          <View style={{...styles.checkInner, ...(checkColor ? { backgroundColor: checkColor } : null)}} />
        }
      </React.Fragment>
    </View>
  );
}

const styles = StyleSheet.create({
  body: {
    alignItems: 'center',
    borderTopWidth: 1,
    borderColor: '#DDD',
    paddingHorizontal: 15
  },
  text: {
    fontSize: 16,
    fontWeight: 300
  },
  checkWrapper: {
    width: 20,
    height: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#DDD',
    marginVertical: 15
  },
  deleteWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 35,
    height: 35,
    paddingTop: 3,
    paddingRight: 5
  },
  checkInner: {
    margin: 1,
    width: 16,
    height: 16,
    borderRadius: 9,
    backgroundColor: '#006DAB'
  }
});
