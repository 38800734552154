import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import './PageTabs.css';

type Tab = {
  name: string;
  to: string;
}

type Props = {
  tabs: Tab[];
  location: RouteComponentProps['location'];
}
export default function PageTabs (props: Props) {
  const { tabs = [], location } = props;

  return (
    <div className='PageTabs'>
      {
        tabs.map((tab, i) => {
          return (
            <Link key={i} className={`tab ${!!location?.pathname?.includes(tab.to) ? 'active' : ''}`} to={tab.to}>
              <p>{tab.name}</p>
            </Link>
          )
        })
      }
    </div>
  )
}
