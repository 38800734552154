import React from 'react';

import './Users.css';
import Section from '../Page/Section';
import UserEntry from './UserEntry';
import Refresh from '../Page/Refresh';

export default class Users extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      activeUser: null,
      refreshedAt: null,
      loading: false
    };

    this.openUser = this.openUser.bind(this);
    this.refreshUsers = this.refreshUsers.bind(this);
  }

  async refreshUsers () {
    try {
      this.setState({ loading: true });
      await this.props.loadUsers();
      this.setState({ loading: false, refreshedAt: Date.now() });
    }
    catch (err) {
      console.log(err);
    }
  }

  openUser (uid) {
    this.setState(prevState => {
      let nextActiveUser = uid;

      if (prevState.activeUser === uid) {
        nextActiveUser = null;
      }

      return { activeUser: nextActiveUser };
    });
  }

  render () {
    const { liveISPList, users, profile, usage, allowBillingForUser, removeBillingForUser, stations } = this.props;
    const { activeUser, loading, refreshedAt } = this.state;

    const subtitle =
      profile && profile.authorizedUsersCount
      && users && users.length > 0
        ? `  ${profile.authorizedUsersCount} / ${users.length}`
        : null;

    return (
      <Section
        title='Users'
        subtitle={ subtitle }
        empty={!users}
        topContent={(
          <Refresh loading={loading} refresh={this.refreshUsers} refreshedAt={refreshedAt} />
        )}>
        {
          !!users && users.map(user => {
            const currentOperation = liveISPList ? liveISPList[user.ISP] : null;
            const isAdvocate = stations && stations.reduce((acc, station) => {
              const advocates = station && station.advocates;
              const advocate = advocates && advocates[user.uid];

              if (advocate && advocate.isAdvocate === true) {
                return true;
              }

              return acc;
            }, false);

            return (
              <UserEntry
                isAdvocate={isAdvocate}
                user={user}
                key={user.uid || user.fedexId}
                isAccountOwner={profile && user.uid === profile.ownerUID}
                openUser={this.openUser}
                activeUser={activeUser}
                currentOperation={currentOperation}
                profile={profile}
                usage={usage}
                allowBillingForUser={allowBillingForUser}
                removeBillingForUser={removeBillingForUser}
              />
            );
          })
        }
      </Section>
    )
  }
}
