import React from "react";
import Device, { WebAppDevice } from "./Device";
import "./UserDevices.css";

const UserDevices = ({ devices, lastActiveDevice }) => {
  const lastActiveId = lastActiveDevice ? lastActiveDevice.lastDeviceId : null;

  if (!devices) return null;

  const withDeviceId = Object.keys(devices).map(deviceId => {
    const device = devices[deviceId];

    return {
      ...device,
      deviceId
    }
  });

  const { webApp, app } = withDeviceId.reduce((acc, device) => {
    const { metadata } = device;
    const isWebApp = ('platform' in metadata || 'browser' in metadata || 'os' in metadata);

    if (isWebApp) acc.webApp.push(device);
    else acc.app.push(device);

    return acc;
  }, { webApp: [], app: [] })

  const dedupeWebAppDevices = webApp.reduce((acc, device) => {    
    if (!device) return acc;
    
    const { browser, browserVersion, lastAppVersion, os, osVersion, platform } = device.metadata || {};
    const newKey = `${browser}-${browserVersion}-${lastAppVersion}-${os}-${osVersion}-${platform}`;
    
    acc[newKey] = device

    return acc;
  }, {});

  const mostRecentWebApp = Object.values(dedupeWebAppDevices).sort((a, b) => {
    return a.setAt < b.setAt ? 1 : -1;
  }).slice(0, 10)

  const mostRecentApp = app.sort((a, b) => {
    return a.setAt < b.setAt ? 1 : -1;
  }).slice(0, 10)

  const appDeviceCards = mostRecentApp.map(device => {
    return (
      <Device
        key={device.firebaseId}
        deviceInfo={device}
        active={device.firebaseId === lastActiveId}
      />
    );
  });

  const webAppDeviceCards = mostRecentWebApp.map(device => {

    return (
      <WebAppDevice
        key={device.firebaseId}
        deviceInfo={device}
        active={device.firebaseId === lastActiveId}
      />
    );
  });

  return (
    <div className="UserDevices">
      <div className='app devices'>
        {appDeviceCards}
      </div>
      <div className='webApp devices'>
        {webAppDeviceCards}
      </div>
    </div>
  );
};

export default UserDevices;
