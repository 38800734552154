import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ConsoleUser = {
  uid: string;
  email: string;
  firstName: string;
  lastName: string;
  permissions: string[];
}

export type ConsoleUsers = Record<string, ConsoleUser>;

export enum Status {
  INITIAL = 'INITIAL',
  PENDING = 'PENDING',
  UPDATED = 'UPDATED',
  FAILED = 'FAILED'
}

export type State = {
  data: ConsoleUsers;
  status: Status;
};

const initialState: State = {
  data: {},
  status: Status.INITIAL,
};

const consoleUsersSlice = createSlice({
  name: 'consoleUsers',
  initialState: initialState,
  reducers: {
    updateConsoleUsers: (state, action: PayloadAction<{ consoleUsers: ConsoleUsers }>) => {
      const { consoleUsers } = action.payload;

      state.status = Status.UPDATED;
      state.data = consoleUsers;
    },
    resetConsoleUsers: (state) => {
      state.status = Status.INITIAL;
      state.data = {};
    }
  }
})

export const { 
  updateConsoleUsers,
  resetConsoleUsers
} = consoleUsersSlice.actions

export default consoleUsersSlice.reducer;