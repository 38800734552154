import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SalesListItem = {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
};

export enum Status {
  INITIAL = 'INITIAL',
  PENDING = 'PENDING',
  UPDATED = 'UPDATED',
  FAILED = 'FAILED'
}

export type State = {
  data: SalesListItem[];
  status: Status;
};

const initialState: State = {
  data: [],
  status: Status.INITIAL,
};

const salesListsSlice = createSlice({
  name: 'salesLists',
  initialState: initialState,
  reducers: {
    updateSalesLists: (state, action: PayloadAction<{ salesLists: SalesListItem[] }>) => {
      const { salesLists } = action.payload;

      state.data = salesLists;
      state.status = Status.UPDATED;
    },
    updateSalesListsFailed: (state) => {
      state.status = Status.FAILED;
      state.data = [];
    },
    resetSalesLists: (state) => {
      state.status = Status.INITIAL;
      state.data = [];
    }
  }
})

export const { 
  updateSalesLists,
  updateSalesListsFailed,
  resetSalesLists
} = salesListsSlice.actions

export default salesListsSlice.reducer;