import React from 'react';
import moment from 'moment';
import Section from '../../Page/Section';
import Note from '../../Note/Note';
import NoteTags from './NoteTags';
import AddNote from './AddNote';
import api, { ISPNote } from '../../../utils/api';
import bugsnagClient from '@utils/bugsnag';
import { ConsoleUser } from '@reducers/consoleUsers';
import './style/notes.css';

type ISPNoteTag = {
  id: number;
  noteId: number;
  tagId: number;
  createdAt: string;
  updatedAt: string;
};

type Tag = {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
}

type Props = {
  consoleUser: ConsoleUser;
  ispId: number;
}

type State = {
  activeNote: null | ISPNote;
  notes: ISPNote[];
  tags: Tag[];
  noteTags: Record<number, number[]>;
}

export default class Notes extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props);

    this.state = {
      activeNote: null,
      notes: [],
      tags: [],
      noteTags: {}
    };

    this.updateNote = this.updateNote.bind(this);
    this.createNote = this.createNote.bind(this);
    this.deleteNote = this.deleteNote.bind(this);
    this.loadNotes = this.loadNotes.bind(this);
    this.loadNoteTags = this.loadNoteTags.bind(this);
    this.loadTags = this.loadTags.bind(this);
    this.finishNote = this.finishNote.bind(this);
  }

  componentDidMount () {
    this.loadTags();
    this.loadNotes();
    this.loadNoteTags();
  }

  async loadNoteTags () {
    try {
      const { ispId } = this.props;
      const res = await api.request<ISPNoteTag[]>(`notes/tags/${ispId}`);

      const tagList = res.data || [];
      const noteTags: Record<number, number[]> = {};

      for (const item of tagList) {
        const noteId = item.noteId;
        const tagId = item.tagId;

        if (!noteTags.hasOwnProperty(noteId)) {
          noteTags[noteId] = [];
        }

        if (!noteTags[noteId].includes(tagId)) {
          noteTags[noteId].push(tagId);
        }
      }

      this.setState({ noteTags: noteTags });
    }
    catch (err) {
      console.log(err);
    }
  }

  async loadTags () {
    try {
      const res = await api.request<Tag[]>(`tags`);

      this.setState({ tags: res.data });
    }
    catch (err) {
      console.log(err);
    }
  }

  async loadNotes () {
    try {
      const { ispId } = this.props;
  
      const notes = await api.getISPNotes(ispId);

      this.setState({ notes });
    } catch (err) {
      console.error(err);
      bugsnagClient.notify(err as Error);
    }
  }

  async createNote (note: Partial<ISPNote>) {
    try {
      const { consoleUser, ispId } = this.props;

      if (!consoleUser) return;

      note.ispId = ispId;
      note.username = `${consoleUser.firstName},${consoleUser.lastName},${consoleUser.email}`;

      const newNote = await api.createNote(note);

      return newNote
    }
    catch (err) {
      console.log(err);
      throw err;
    }
  }

  async updateNote (noteId: number, updates: Partial<ISPNote>) {
    try {
      const newNote = await api.updateNote(noteId, updates);

      return newNote;
    }
    catch (err) {
      throw err;
    }
  }

  async deleteNote (noteId: number) {
    try {
      await api.deleteNote(noteId);
      await this.loadNotes();
    }
    catch (err) {
      throw err;
    }
  }

  async finishNote () {
    await this.loadNotes();

    this.setState({ activeNote: null });
  }

  render () {
    const { activeNote, notes, noteTags } = this.state;

    return (
      <Section title='Notes'>
        <div className='Notes'>
          <AddNote
            finishNote={this.finishNote}
            createNote={this.createNote}
            updateNote={this.updateNote}
            deleteNote={this.deleteNote}
          />

          {
            notes && notes.length
              ? <div className='list'>
                <div className='scroll'>
                  {
                    notes
                      .sort((a, b) => {
                        const aMom = moment(a.createdAt);
                        const bMom = moment(b.createdAt);

                        if (aMom.isBefore(bMom)) return 1;
                        if (bMom.isBefore(aMom)) return -1;

                        return 0;
                      })
                      .map(item => {
                        const { id } = item;
                        const isActive = activeNote && activeNote.id === id;

                        return (
                          <React.Fragment>
                            <Note
                              key={id}
                              isActive={!!isActive}
                              editable
                              edit={() => this.setState({ activeNote: isActive ? null : item })}
                              note={item}
                              finishNote={this.finishNote}
                              createNote={this.createNote}
                              updateNote={this.updateNote}
                              deleteNote={() => this.deleteNote(id)}
                            />

                            <NoteTags
                              noteId={id}
                              tags={this.state.tags}
                              noteTags={(this.state.noteTags || {})[id]}
                              loadNoteTags={this.loadNoteTags}
                            />

                            <div style={{ padding: '5px 0', borderBottom: '1px solid #DDD' }} />
                          </React.Fragment>
                        )
                      })
                  }
                </div>
              </div>
              : <div className='default'>
                <p>No notes have been added for this ISP.</p>
              </div>
          }
        </div>
      </Section>
    )
  }
}
