import React from 'react';

import ISPCard from './ISPCard';
import './PipelineList.css';
import { getDisplayName, getDisplayColor } from '../../utils/salesCycleStatus'
import ListOptions from './ListOptions';
import { Spinner } from '@common';
import Icon from '../Icon/Icon';

export default class PipelineList extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      viewOptions: false
    };
  }

  render () {
    const {loadingData, data, status} = this.props;
    const items = [];
    const isps = data || [];
    let totalOpportunity = 0;
    const numberOfDeals = isps.length;
    const title = getDisplayName(status);
    const displayColor = getDisplayColor(status);

    for (const isp of (isps || [])) {
      const operation = {...isp}; // Redux immutability work around
      operation.type = 'isps';
      operation.profile = (this.props.liveISPList || {})[operation.firebaseId] || null;

      const { groundRoutes = 0, hdRoutes = 0, suppRoutes = 0, additionalRoutes = 0 } = isp;
      const projectedConversionRate = !Number.isNaN(parseInt(operation.projectedConversionRate, 10))
        ? operation.projectedConversionRate
        : 100;
      const projectedAccountValue = (groundRoutes + hdRoutes + suppRoutes + additionalRoutes) * (projectedConversionRate / 100) * 20;

      totalOpportunity += projectedAccountValue;

      items.push(operation);
    }

    return (
      <div className='PipelineList'>
        <div className='container'>
          <div className='summary'>
            <div className='info'>
              <div className='title'>
                <h1>{title}</h1>

                <div className='circle' style={{ backgroundColor: displayColor === 'transparent' ? 'white' : displayColor }}/>
              </div>

              <div className='deals'>
                <p>${totalOpportunity || 0}</p>
                <p>{numberOfDeals} {numberOfDeals > 1 ? 'ISPs' : 'ISP'}</p>
              </div>

              <div
                onClick={() => this.setState(prevState => {
                  return { viewOptions: !prevState.viewOptions };
                })}
                className='options-trigger'>
                <Icon name='view-agenda' size={15} color='#999' />
              </div>
            </div>
          </div>

          <div className='cards'>
            {
              items.length
                ? items.map((item, i) => {
                  if (item.type === 'isps') {
                    const classes = items.length - i <= this.props.offset
                      ? 'border-left'
                      : '';
                    return (
                      <ISPCard
                        isp={item}
                        key={item && item.entityId ? `${title}-${item.entityId}-${i}` : `${title}-${i}`}
                        setActiveItem={this.props.setActiveItem}
                        usage={this.props.usage}
                        cardClasses={classes}
                      />
                    )
                  }

                  return null;
                })
                : <div className='default'>
                    {loadingData
                      ? <div className='spinner-wrap'><Spinner size={35} /></div>
                      : <p>Nothing has been added yet...</p>
                    }
                  </div>
            }
          </div>

          {
            this.state.viewOptions
              ? <ListOptions
                user={this.props.user}
                ispItems={items}
                deleteList={(id) => {
                  this.props.deleteList(id);
                  this.setState({ viewOptions: false });
                }}
                left={status}
                name={title}
              />
              : null
          }
        </div>
      </div>
    )
  }
}