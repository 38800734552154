import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import pr from '@packageroute/types-firebase';

export type Referral = pr.invite_codes.InviteCode;
export type Referrals = pr.invite_codes.InviteCodes;

export enum Status {
  INITIAL = 'INITIAL',
  PENDING = 'PENDING',
  UPDATED = 'UPDATED',
  FAILED = 'FAILED'
}

export type State = {
  data: Referrals;
  status: Status;
};

const initialState: State = {
  data: {},
  status: Status.INITIAL,
};

const referralsSlice = createSlice({
  name: 'referrals',
  initialState: initialState,
  reducers: {
    updateReferrals: (state, action: PayloadAction<{ referrals: Referrals }>) => {
      const { referrals } = action.payload;

      state.data = referrals;
      state.status = Status.UPDATED;
    },
    updateReferralsFailed: (state) => {
      state.status = Status.FAILED;
      state.data = {};
    },
    resetReferrals: (state) => {
      state.status = Status.INITIAL;
      state.data = {};
    }
  }
})

export const { 
  updateReferrals,
  updateReferralsFailed,
  resetReferrals
} = referralsSlice.actions

export default referralsSlice.reducer;