import React, { Fragment } from "react";
import pr from '@packageroute/types-firebase';
import {RouteComponentProps} from 'react-router-dom';
import Icon from "../Icon/Icon";
import { titleize } from "../../utils/formatting";
import "./UserIsp.css";

type AppUser = pr.users.User;
type Profile = pr.isp.profile.Profile & { id: string };

type Props = {
  user: AppUser;
  isp: Profile;
  isLastActive?: boolean;
  onDelete: (ispId: string) => void;
  onSelect: (ispId: string) => void;
}

type State = {

}

class EditUserIsp extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { user, isp, isLastActive } = this.props;

    const assignedWorkAreas = [];
    const workAreas = Object.values(isp.WorkAreas || {});

    for (const workArea of workAreas) {
      if (workArea.driver === user.FedExId) {
        assignedWorkAreas.push(workArea.workArea);
      }
      else if (workArea.alternateDrivers && workArea.alternateDrivers[user.FedExId]) {
        assignedWorkAreas.push(workArea.workArea);
      }
    }

    const isAccountOwner = user.uid === isp.ownerUID;
    
    const { type } = (isp.drivers || {})[user.FedExId] || {
      isOwner: false,
      type: "",
      dotExpirationDate: ""
    };

    return (
      <div className={`UserIsp ${isLastActive ? "active" : ""}`}>
        <div className="top" onClick={() => this.props.onSelect(isp.ISPID)}>
          <div className="identity">
            <div className="activeLightWrap">
              <div className={`activeLight${isLastActive ? " active" : ""}`} />
            </div>

            <div className="nameWrap">
              <h3 className="userName" style={{ marginRight: "5px" }}>
                {titleize(isp.CSPName)}
              </h3>
              {isp && isp.station && isp.station.stationID ? (
                <Fragment>
                  <Icon name="city" size={16} color="#999" />
                  <div className="userId">{isp.station.stationID}</div>
                </Fragment>
              ) : null}
            </div>
          </div>
          <div className="misc">
            {
              assignedWorkAreas.map(workAreaId => (
                <div className="waWrap">
                  <p>{workAreaId}</p>
                </div>
              ))
            }

            <div
              className="iconWrap"
              style={{ marginLeft: "10px", marginRight: "10px" }}
              onClick={() => {
                if (!isAccountOwner) {
                  this.props.onDelete(isp.ISPID)
                }
              }}
            >
              <Icon
                name={isAccountOwner ? 'crown' : "delete"}
                color={isAccountOwner ? "#168E32" : "#B90C0E" }
                size={25}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditUserIsp;
