import React, { CSSProperties } from 'react';
import './SectionTitle.css';

type Props = {
  title: string;
  titleContent?: React.ReactNode;
  children: React.ReactNode;
  style?: CSSProperties;
}

export default function SectionTitle ({ title, titleContent, children, style }: Props) {
  return (
    <div className='SectionTitle' style={style}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h3 style={{ marginRight: '10px' }}>{title}</h3>
        {titleContent ?? null}
      </div>

      {children}
    </div>
  );
}
