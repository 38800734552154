import React, { useState } from "react";
import { PortalModal } from "../Page/Modal";
import styled from "styled-components";
import { InputText, Notice,  } from "@components/Page";
import { Spinner } from "@common";
import { Button } from "@common";

type Props = {
  close: () => void;
  deleteUser: (shouldSendEmailNotices: boolean) => void;
  deleting: boolean;
};

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  max-width: 400px;
`

const EmailSendConfirmationWrap = styled.div`
  display: flex;
  flex-direction: column;
  marginBottom: 10px;
  marginTop: 10px;
`

const WarningText = styled.p`
  margin-bottom: 10px;
`;
const TitleWarningText = styled.p`
  font-size: 20px;
  margin-bottom: 10px;
`;

const NoteText = styled.p`
  margin-bottom: 10px;
  font-style: oblique;
  font-size: 14px;
  color: #555;
`;

const ConfirmSendEmailCheckboxWrap = styled.div`
  display: flex;
`
const ConfirmSendEmailCheckboxLabel = styled.label`
`;

const ConfirmSendEmailCheckbox = styled.input`
  margin-right: 2ch;
`;

export function DeleteUserConfirmationModal({
  close,
  deleteUser,
  deleting,
}: Props) {
  const [shouldSendEmailNotices, setShouldSendEmailNotices] = useState<boolean>(true);

  return (
    <PortalModal visible centerFullScreen onRequestClose={close}>
      <ModalBody
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: 20,
          backgroundColor: "white",
          borderRadius: 10,
          maxWidth: 400,
        }}
      >
        <UserDeletionWarning />

        <EmailSendConfirmationWrap>
          <WarningText>
            Uncheck to prevent sending confirmation emails to user and authorized account owners.
          </WarningText>
          <ConfirmSendEmailCheckboxWrap>
            <ConfirmSendEmailCheckbox
              type='checkbox'
              id='shouldSendEmailNotices'
              name='shouldSendEmailNotices'
              checked={shouldSendEmailNotices}
              onChange={(event) => setShouldSendEmailNotices(event.target.checked)}
            />
            <ConfirmSendEmailCheckboxLabel htmlFor='shouldSendEmailNotices'>Send email notice</ConfirmSendEmailCheckboxLabel>
          </ConfirmSendEmailCheckboxWrap>
          <NoteText>
            Not sending emails may be desirable if a previous delete attempt failed but the email successfully sent.
          </NoteText>
        </EmailSendConfirmationWrap>

        <UserDeletionConfirmation
          onConfirm={() => deleteUser(shouldSendEmailNotices)}
          onCancel={close}
          deleting={deleting}
        />
      </ModalBody>
    </PortalModal>
  );
}

function UserDeletionWarning() {
  return (
    <div className="DeleteUserConfirmationModal">
      <Notice
        severity="error"
        show
        title="Warning!"
        description={"Deletion CANNOT be undone"}
      />

      <div className="WarningText">
        <TitleWarningText>
          Deleting a PackageRoute User will remove the following information:
        </TitleWarningText>
        <ul>
          <li>
            <WarningText>
              Identifying information from user record, including name, contact
              information, metadata and settings
            </WarningText>
          </li>

          <li>
            <WarningText>
              User access to any ISP the user is authorized with
            </WarningText>
          </li>
        </ul>

        <NoteText>
          An email informing the user of the deletion will be sent.
        </NoteText>

        <NoteText>
          The account owner of any ISP which the user is still authorized for
          will also be notified via email of the deletion.
        </NoteText>
      </div>
    </div>
  );
}

const REQUIRED_CONFIRMATION_TEXT = 'DELETE';

const EmphasizeText = styled.em`
  font-weight: 500;
`

type ConfirmProps = {
  onConfirm: () => void;
  onCancel: () => void;
  deleting: boolean;
};
function UserDeletionConfirmation({
  onConfirm,
  onCancel,
  deleting,
}: ConfirmProps) {
  const [confirmationText, setConfirmationText] = useState("");
  const [confirmed, setConfirmed] = useState(false);

  return (
    <div style={{ width: "100%" }}>
      <div style={{ marginBottom: 10 }}>
        <p>{`Confirm deletion by typing `}<EmphasizeText>{REQUIRED_CONFIRMATION_TEXT}</EmphasizeText>{` name:`}</p>

        <InputText placeholder={`type '${REQUIRED_CONFIRMATION_TEXT}' here to remove`} onChange={(e) => setConfirmationText(e.target.value)} />
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        {deleting ? (
          <Spinner />
        ) : (
          <React.Fragment>
            <Button color="#178E32" onPress={onCancel}>
              Cancel
            </Button>

            {confirmationText === REQUIRED_CONFIRMATION_TEXT ? (
              !confirmed ? (
                <Button color="#B90C0E" onPress={() => setConfirmed(true)}>
                  Confirm
                </Button>
              ) : (
                <Button color="#B90C0E" onPress={() => onConfirm()}>
                  Delete
                </Button>
              )
            ) : null}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
