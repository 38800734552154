import React from 'react';
import pr from '@packageroute/types-firebase';
import Icon from '../Icon/Icon';

type Profile = pr.isp.profile.Profile;

type Props = {
  profile?: Profile;
}

export default function WorkAreasFraction (props: Props) {
  const { profile } = props;

  if (!profile || !profile.WorkAreas) return null;

  let enabledWA = 0;

  for (const workAreaId in profile.WorkAreas) {
    const workArea = profile.WorkAreas[workAreaId];

    if (workArea.paid === true) {
      enabledWA += 1;
    }
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <p>
        <span style={{ color: '#006DAB' }}>{enabledWA}</span>
        {` / `}
        <span style={{ marginRight: '5px' }}>{Object.values(profile.WorkAreas || {}).length}</span>
      </p>  

      <Icon name='map' color='#999' size={15} />
    </div>
  );
}
