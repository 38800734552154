import React from 'react';
import { View, TouchableOpacity, Text, StyleSheet } from 'react-native';

interface Props {
  value?: string | number;
  size?: 'small' | string;
  onPress?: () => void;
}

export default function ExceptionIcon (props: Props) {
  const { value, size, onPress } = props;

  const BodyComponent = onPress ? TouchableOpacity : View;
  const sizeStyles = size === 'small'
    ? [styles.smallContainer, styles.smallText]
    : [styles.largeContainer, styles.largeText];

  return (
    <BodyComponent
      onPress={onPress}
      style={[styles.container, sizeStyles[0]]}
    >
      <Text allowFontScaling={false} style={[styles.text, sizeStyles[1]]}>{ value || 'X'}</Text>
    </BodyComponent>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#B90C0F'
  },
  smallContainer: {
    width: 16,
    height: 16,
    borderRadius: 8
  }, 
  largeContainer: {
    width: 24,
    height: 24,
    borderRadius: 12
  },
  text: {
    color: 'white',
    textAlign: 'center',
    fontWeight: 500,
  },
  smallText: {
    fontSize: 10,
  },
  largeText: {
    fontSize: 14
  }
})