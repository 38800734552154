import React from 'react';
import Button from './Button';
import Icon from '../Icon/Icon';
import { useColorPack } from '@hooks/redux';
import './InputText.css';

type FalseyInteger = 0;
type TruthyInteger = 
| 1 | 2 | 3 | 4 | 5 
| 6 | 7 | 8 | 9 | 10 
| 11 | 12 | 13 | 14 
| 15 | 16 | 17 | 18 | 19 
| 20 | 21 | 22 | 23 | 24;

type WithMultiline = {
  multiline: TruthyInteger;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
}

type WithSingleLine = {
  multiline?: FalseyInteger | never;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

type Props = {
  label?: string;
  value?: string | number;
  type?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  name?: string;
  id?: string;
  required?: boolean;
  valid?: boolean;
  invalidMessage?: string;
  disabled?: boolean;
  inputStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  checked?: boolean;
  pattern?: string;
  min?: string | number;
  max?: string | number;
  children?: any;
} & (WithMultiline | WithSingleLine)

export default function InputText (props: Props) {
  const {
    label,
    value,
    onChange,
    onFocus = () => {},
    onBlur = () => {},
    type,
    placeholder,
    style,
    name,
    id,
    required,
    valid,
    invalidMessage,
    disabled,
    inputStyle,
    labelStyle,
    checked,
    pattern,
    min,
    max,
    multiline,
  } = props;

  const colorPack = useColorPack();

  return (
    <div className='InputText' style={style}>
      { props.children }
      {
        !!label &&
        <label htmlFor={id} style={labelStyle}>
          { label }

          {
            required === true && valid !== undefined
              ? <div className='invalid-wrap'>
                <Icon
                  name={valid ? 'checkbox-marked-circle-outline' : 'close-circle-outline'}
                  size={12}
                  color={valid ? colorPack.green : colorPack.red}
                />

                {!!invalidMessage && !valid && <p className='invalid-message' style={{ color: colorPack.red }}>{invalidMessage}</p>}
              </div>
              : null
          }
        </label>
      }

      {!!multiline
        ? <textarea
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange as (React.ChangeEventHandler<HTMLTextAreaElement> | undefined)}
            onFocus={onFocus as (React.FocusEventHandler<HTMLTextAreaElement> | undefined)}
            onBlur={onBlur as (React.FocusEventHandler<HTMLTextAreaElement> | undefined)}
            required={required}
            disabled={disabled}
            style={inputStyle}
            rows={multiline}
          />
        : <input
          checked={checked}
          name={name}
          type={type || 'text'}
          placeholder={placeholder}
          value={value}
          onChange={onChange as (React.ChangeEventHandler<HTMLInputElement> | undefined)}
          onFocus={onFocus as (React.FocusEventHandler<HTMLInputElement> | undefined)}
          onBlur={onBlur as (React.FocusEventHandler<HTMLInputElement> | undefined)}
          required={required}
          disabled={disabled}
          style={inputStyle}
          pattern={pattern}
          min={min}
          max={max}
        />
      }
    </div>
  );
}

type WithSaveProps = {
  startingValue?: number | string;
  submit: (value: number | string) => void;
  placeholder?: string;
  label?: string;
  type?: string;
}

type WithSaveState = {
  value: number | string;
}

export class InputTextWithSave extends React.Component<WithSaveProps, WithSaveState> {
  constructor (props: WithSaveProps) {
    super(props);

    this.state = { value: this.props.startingValue || '' };

    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  onChange ({ target }: React.ChangeEvent<{ value: string | number }>) {
    const { value } = target;

    this.setState({ value });
  }

  submit (e: React.FormEvent) {
    e.preventDefault();

    this.props.submit(this.state.value);
  }

  render () {
    const { placeholder, label, type } = this.props;
    return (
      <form className='InputTextWithSave' onSubmit={this.submit}>
        <InputText
          placeholder={placeholder}
          value={this.state.value}
          onChange={this.onChange}
          type={type}
        />

        <Button color='#168E32'>{label || 'Save'}</Button>
      </form>
    );
  }
}
