import { DailyActiveUsers, ActiveUsersMetrics } from "@reducers/metrics/dailyActiveUsers";
import { FirebaseDatabase, FirebaseProvider } from "@utils/firebase";
import ConsoleApiService from './ConsoleApiService';
import {IConsoleApiInitializer} from "./ConsoleApiInitializer";

export default class ConsoleDailyActiveUsersService {
  private db: FirebaseDatabase;
  private api: ConsoleApiService;

  constructor(firebaseProvider: FirebaseProvider, consoleApiInitializer: IConsoleApiInitializer) {
    this.db = firebaseProvider.getConsoleDefaultDB();
    this.api = consoleApiInitializer.initialize();
  }

  async getDate(date: string): Promise<DailyActiveUsers | null> {
    try {
      const path = this._getApiDatePath(date);
  
      const dailyActiveUsers = await this.api.get<DailyActiveUsers>(path);

      return dailyActiveUsers;
    } catch (err) {
      console.error(err);

      return null;
    }
  }

  async getDates(dates: string[]): Promise<DailyActiveUsers> {
    try {
      const path = this._getApiDatesPath(dates);

      const activity = await this.api.get<DailyActiveUsers>(path);

      return activity;
    } catch (err) {
      console.error(err);

      return {};
    }
  }

  subscribeDate(date: string, callback: (activeUsersMetrics: ActiveUsersMetrics | null) => void) {
    const path = this._getFirebaseDatePath(date);

    const ref = this.db.ref(path);

    ref.on("value", (snap) => {
      const activeUsersMetrics: ActiveUsersMetrics | null = snap.val();

      callback(activeUsersMetrics);
    });
  }

  unsubscribeDate(date: string) {
    const path = this._getFirebaseDatePath(date);

    const ref = this.db.ref(path);

    ref.off();
  }

  subscribeUpdatedAt(callback: (updatedAt: number | null) => void) {
    const path = this._getFirebaseUpdatedAtPath();

    const ref = this.db.ref(path);

    ref.on("value", (snap) => {
      const updatedAt: number | null = snap.val();

      callback(updatedAt);
    });
  }

  async getUpdatedAt(): Promise<number> {
    const path = this._getFirebaseUpdatedAtPath();

    const ref = this.db.ref(path);

    const snap = await ref.once("value");

    const updatedAt: number | null = snap.val();

    if (!updatedAt) throw new Error('No activity updatedAt value found');

    return updatedAt;
  }

  unsubscribeUpdatedAt() {
    const path = this._getFirebaseUpdatedAtPath();

    const ref = this.db.ref(path);

    ref.off();
  }

  private _getFirebaseDatePath(date: string) {
    return `metrics/dailyActiveUsers/data/${date}`;
  }

  private _getFirebaseUpdatedAtPath () {
    return `metrics/dailyActiveUsers/metadata/updatedAt`;
  }

  private _getApiDatePath(date: string) {
    return `metrics/dashboard/daily-active-users?date=${date}`;
  }

  private _getApiDatesPath(dates: string[]) {
    return `metrics/dashboard/daily-active-users?dates=${dates.join(',')}`;
  }
}
