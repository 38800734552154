import React from 'react';
import _ from 'lodash';

const layerEvents = {
  mouseenter: 'onMouseEnterLayer',
  mouseleave: 'onMouseLeaveLayer',
  mousedown: 'onMouseDownLayer',
  touchstart: 'onTouchStartLayer',
  click: 'onClickLayer'
};

export default class Geometries extends React.Component {
  constructor (props) {
    super(props);

    this.init = this.init.bind(this);
  }

  init () {
    const { map, source, layers } = this.props;

    if (!map.getSource(source.id)) {
      map.addSource(source.id, source.data);
    }

    if (Array.isArray(layers)) {
      for (const layerProps of layers) {
        if (!map.getLayer(layerProps.id)) {
          map.addLayer(layerProps);

          for (const event in layerEvents) {
            const propName = layerEvents[event];
            const eventHandler = this.props[propName];

            if (eventHandler && typeof eventHandler === 'function') {
              map.on(event, layerProps.id, eventHandler);
            }
          }
        }
      }
    }
  }

  componentDidMount () {
    this.init();
  }

  componentDidUpdate (prevProps) {
    const { map, layers } = this.props;

    if (Array.isArray(layers)) {
      for (const layerProps of layers) {
        for (const event in layerEvents) {
          const propName = layerEvents[event];
          const eventHandler = this.props[propName];
          const prevEventHandler = prevProps[propName];

          if (eventHandler !== prevEventHandler) {
            if (prevEventHandler) {
              map.off(event, layerProps.id, prevEventHandler);
            }

            if (eventHandler) {
              map.on(event, layerProps.id, eventHandler);
            }
          }
        }
      }
    }
  }

  componentWillUnmount () {
    const { map, layers, source } = this.props;

    for (const layerProps of layers) {
      for (const event in layerEvents) {
        const propName = layerEvents[event];
        const eventHandler = this.props[propName];

        if (typeof eventHandler === 'function') {
          map.off(event, layerProps.id, eventHandler);
        }
      }

      if (map.getLayer(layerProps.id)) {
        map.removeLayer(layerProps.id);
      }
    }

    if (map.getSource(source.id)) {
      map.removeSource(source.id);
    }
  }

  render () {
    return (
      null
    );
  }
}
