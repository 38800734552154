import { createSlice } from '@reduxjs/toolkit';

export const remoteConfigFeatureFlags = {
} as const;

export const firebaseFeatureFlags = {
  CONSOLE_USE_ISP_MODAL_DELETE_BUTTON: false
} as const;

export type RemoteConfigFeatureFlagKeys = keyof typeof remoteConfigFeatureFlags;
export type FirebaseFeatureFlagKeys = keyof typeof firebaseFeatureFlags;
export type AllFeatureFlagKeys = RemoteConfigFeatureFlagKeys | FirebaseFeatureFlagKeys;

export type FeatureFlags = Record<AllFeatureFlagKeys, boolean>;

export type FeatureFlagsState = {
  status: Status;
  data: FeatureFlags;
}

export enum Status {
  INITIAL = 'INITIAL',
  PENDING = 'PENDING',
  UPDATED = 'UPDATED',
  FAILED = 'FAILED'
}

const initialState: FeatureFlagsState = {
  status: Status.INITIAL,
  data: {
    ...remoteConfigFeatureFlags,
    ...firebaseFeatureFlags,
  }
}

const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    receiveFeatureFlags: (state, action) => {
      const { featureFlags } = action.payload;

      const combined = {
        ...state.data,
        ...featureFlags
      }
      
      state.data = combined;
      state.status = Status.UPDATED;
    },
    resetFeatureFlags: (state) => {
      state.status = Status.INITIAL;
      state.data = {
        ...remoteConfigFeatureFlags,
        ...firebaseFeatureFlags,
      }
    }
  }
})

export const {
  receiveFeatureFlags,
  resetFeatureFlags
} = featureFlagsSlice.actions;

export default featureFlagsSlice.reducer;
