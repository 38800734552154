import { __DEV__ } from '@utils/environment';

const DATE_KEY = 'YYYY-MM-DD';

export {
  __DEV__,
  DATE_KEY,
}

window.__DEV__ = __DEV__;
window.DATE_KEY = DATE_KEY;
