import React from 'react';

import './UserStationItem.css';
import HDOnly from '../Station/HDOnly';
import CoLocation from '../Station/CoLocation';
import StationAdvocate from './StationAdvocate';
import Icon from '../Icon/Icon';

export default class UserStationItem extends React.Component {
  constructor (props) {
    super(props);
  }

  render () {
    const { station, user, editable } = this.props;
    const advocate = station && station.advocates && station.advocates[user && user.uid];
    const isAdvocate = advocate && advocate.isAdvocate === true;

    return (
      <div className='UserStationItem'>
        <div className='iconWrap'>
          <Icon name='city' size={30} color='#006DAB' />
        </div>

        <h3 className={`id`}>{ station.terminal }</h3>

        <h3 className={`city`}>
          {station.city}, {station.state}
        </h3>

        <div className='options'>
          <StationAdvocate
            editable={editable}
            user={user}
            station={station}
            updateStationData={this.props.fetchStations}
            isAdvocate={isAdvocate}
          />
          <CoLocation editable={false} station={station} />
          <HDOnly editable={false} station={station} />
        </div>
      </div>
    );
  }
}
