import React from 'react';
import TimePicker from 'rc-time-picker';
import moment from 'moment-timezone';

import 'rc-time-picker/assets/index.css'
import './InputTime.css';

export default function InputTime (props) {
  return (
    <div className='InputTime'>
      {
        props.label
          ? <label htmlFor={props.name}>{props.label}</label>
          : null
      }

      <TimePicker
        className='time'
        use12Hours
        onChange={props.onChange}
        value={props.value ? moment(props.value) : null}
        format='h:mm a'
        placeholder='i.e. 8:00 am'
        minuteStep={15}
        showSecond={false}
        inputReadOnly
        defaultOpenValue={props.default || moment().hours(8).minutes(0)}
      />
    </div>
  );
}
