import {lightenHexColor} from '@utils/colors';

export type DateString = string;
export type DashboardDateData = {
  dailyActiveUsers: number;
  weeklyActiveUsers: number;
  totalRoutes: number;
  liveRoutes: number;
  paidRoutes: number;
  totalPaidRoutes: number;
  totalDeliveries: number;
  totalPickups: number;
  totalDeliveryPkgs: number;
  totalPickupPkgs: number;
  totalPaidDeliveries: number;
  totalPaidPickups: number;
  totalPaidDeliveryPkgs: number;
  totalPaidPickupPkgs: number;
  totalBCs: number;
  totalDrivers: number;
  totalISPs: number;
  totalUsers: number;
}
export type DashboardDataKey = keyof DashboardDateData;
export type DashboardData = Record<DateString, DashboardDateData>;

export type Spec = {
  key: DashboardDataKey;
  dataKey: string;
  color: string;
  strokeColor: string;
  totalLabel?: string;
  averageLabel?: string;
}
export type SpecGroup = {
  id: string;
  title: string;
  keys: DashboardDataKey[];
  specs: Partial<Record<DashboardDataKey, Spec>>;
  shared: {
    activeDot: { stroke: string, strokeWidth: number, r: number };
    strokeWidth: number;
    strokeOpacity?: number;
    dot: boolean;
    type: string;
    perDayOnly?: boolean;
    perWeekAverage?: boolean;
  }
}

const activeUsers = '#FF7702';
const routeColor = '#FF9200';
const deliveryPkgsColor = '#A87E4F';
const stopsColor = '#168E32';
const pickupsColor = '#006DAB';

export const metricSpecs: SpecGroup[] = [
  {
    id: 'dailyActiveUsers',
    title: 'Daily Active Users',
    keys: ['dailyActiveUsers', 'weeklyActiveUsers'],
    specs: {
      'dailyActiveUsers': {
        key: 'dailyActiveUsers',
        dataKey: 'Daily Active Users % (per week)',
        color: activeUsers,
        strokeColor: activeUsers,
        averageLabel: '% per day'
      },
      'weeklyActiveUsers': {
        key: 'weeklyActiveUsers',
        dataKey: 'Weekly Active Users % (per month)',
        color: lightenHexColor(activeUsers, .4),
        strokeColor: lightenHexColor(activeUsers, .4),
        averageLabel: '% per day'
      },
    },
    shared: {
      activeDot: { stroke: routeColor, strokeWidth: 3, r: 4 },
      strokeWidth: 1,
      dot: false,
      type: 'monotone',
      perDayOnly: true,
      perWeekAverage: true,
    }
  },
  {
    id: 'routes',
    title: 'Routes',
    keys: ['totalPaidRoutes', 'paidRoutes', 'liveRoutes'],
    specs: {
      'totalPaidRoutes': {
        key: 'totalPaidRoutes',
        dataKey: 'Paid Routes',
        color: routeColor,
        strokeColor: routeColor,
        totalLabel: 'total paid',
        averageLabel: 'avg paid per',
      },
      'paidRoutes': {
        key: 'paidRoutes',
        dataKey: 'Paid Live Routes',
        color: lightenHexColor(routeColor, .4),
        strokeColor: lightenHexColor(routeColor, .4),
        totalLabel: 'total live paid',
        averageLabel: 'avg live paid per',
      },
      'liveRoutes': {
        key: 'liveRoutes',
        dataKey: 'Live Routes',
        color: lightenHexColor(routeColor, .7),
        strokeColor: lightenHexColor(routeColor, .7),
        totalLabel: 'total live',
        averageLabel: 'avg live per',
      }
    },
    shared: {
      activeDot: { stroke: routeColor, strokeWidth: 3, r: 4 },
      strokeWidth: 1,
      dot: false,
      type: 'monotone',
      perDayOnly: true,
      perWeekAverage: true,
    }
  },
  {
    id: 'pkgs',
    title: 'Packages',
    keys: ['totalPaidDeliveryPkgs', 'totalDeliveryPkgs'],
    specs: {
      'totalPaidDeliveryPkgs': {
        key: 'totalPaidDeliveryPkgs',
        dataKey: 'Paid Packages',
        color: deliveryPkgsColor,
        strokeColor: deliveryPkgsColor,
        totalLabel: 'total paid',
      },
      'totalDeliveryPkgs': {
        key: 'totalDeliveryPkgs',
        dataKey: 'All Packages',
        color: lightenHexColor(deliveryPkgsColor, .4),
        strokeColor: lightenHexColor(deliveryPkgsColor, .4),
      }
    },
    shared: {
      activeDot: { stroke: deliveryPkgsColor, strokeWidth: 3, r: 4 },
      strokeWidth: 1,
      strokeOpacity: 0.7,
      dot: false,
      type: 'monotone'
    }
  },
  {
    id: 'stops',
    title: 'Deliveries',
    keys: ['totalPaidDeliveries', 'totalDeliveries'],
    specs: {
      'totalPaidDeliveries': {
        key: 'totalPaidDeliveries',
        dataKey: 'Paid Deliveries',
        color: stopsColor,
        strokeColor: stopsColor,
        totalLabel: 'total paid',
      },
      'totalDeliveries': {
        key: 'totalDeliveries',
        dataKey: 'All Deliveries',
        color: lightenHexColor(stopsColor, .4),
        strokeColor: lightenHexColor(stopsColor, .4),
      }
    },
    shared: {
      activeDot: { stroke: stopsColor, strokeWidth: 3, r: 4 },
      strokeWidth: 1,
      dot: false,
      type: 'monotone'
    }
  },
  {
    id: 'pickups',
    title: 'Pickups',
    keys: ['totalPaidPickups', 'totalPickups'],
    specs: {
      'totalPaidPickups': {
        key: 'totalPaidPickups',
        dataKey: 'Paid Pickups',
        color: pickupsColor,
        strokeColor: pickupsColor,
        totalLabel: 'total paid',
      },
      'totalPickups': {
        key: 'totalPickups',
        dataKey: 'All Pickups',
        color: lightenHexColor(pickupsColor, .4),
        strokeColor: lightenHexColor(pickupsColor, .4),
      }
    },
    shared: {
      activeDot: { stroke: pickupsColor, strokeWidth: 3, r: 4 },
      strokeWidth: 1,
      dot: false,
      type: 'monotone'
    }
  }
];
