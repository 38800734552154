import firebaseProvider from "./FirebaseProvider";
export { FirebaseProvider } from './FirebaseProvider';
export type {
  FirebaseApp,
  FirebaseUser,
  DataSnapShot,
  FirebaseDatabase,
  FirebaseAuth,
} from './firebase';

export default firebaseProvider;
