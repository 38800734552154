import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import pr from '@packageroute/types-firebase';


/**
 * The LiveIsp list is a set of ISP profiles populated from the ISPList table
 * in the product database. However, it is *not* just a record of the current ISPs
 * which are subscribed to the FXG manifest data cycle.
 */
export type LiveIspList = Record<string, LiveIspProfile>;
export type LiveIspProfile = pr.isp.profile.Profile & { authorizedUsersCount: number };

export enum Status {
  INITIAL = 'INITIAL',
  PENDING = 'PENDING',
  UPDATED = 'UPDATED',
  FAILED = 'FAILED'
}

export type State = {
  data: LiveIspList;
  status: Status;
};

const initialState: State = {
  data: {},
  status: Status.INITIAL,
};

const liveIspListSlice = createSlice({
  name: 'liveIspList',
  initialState: initialState,
  reducers: {
    updateLiveIspList: (state, action: PayloadAction<{ liveIspList: LiveIspList }>) => {
      const { liveIspList } = action.payload;

      state.status = Status.UPDATED;
      state.data = liveIspList;
    },
    resetLiveIspList: (state) => {
      state.status = Status.INITIAL;
      state.data = {};
    }
  }
})

export const { 
  updateLiveIspList,
  resetLiveIspList
} = liveIspListSlice.actions

export default liveIspListSlice.reducer;