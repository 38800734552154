import { createSelector } from "reselect";
import { RootState } from "@reducers";
import { Status } from "@reducers/signups";
import { selectLiveIspList } from "../liveIspList";

const selectSignups = (state: RootState) => state.signups.data;
const selectSignupsLoaded = (state: RootState) => state.signups.status === Status.UPDATED;

const selectTotalSignups = createSelector(
  [selectLiveIspList, selectSignups],
  (liveISPList, signups) => {
    return Object.keys(signups || {}).reduce<string[]>((acc, date) => {
      const entry = signups[date];

      if (!entry) return acc;

      for (const ISPID in entry) {
        if (liveISPList && liveISPList[ISPID] && !acc.includes(ISPID)) {
          acc.push(ISPID);
        }
      }

      return acc;
    }, []).length;
  }
);

const selectSignupsDailyTotals = createSelector(
  [selectSignups],
  (signups) => {
    return Object.keys(signups).reduce<Record<string, number>>((acc, date) => {
      const dailySignups = signups[date];

      acc[date] = Object.keys(dailySignups).length;
      
      return acc;
    }, {})
  }
)

export { selectSignups, selectTotalSignups, selectSignupsLoaded, selectSignupsDailyTotals };
