import React from 'react';

import './Articles.css';
import Button from '../../Page/Button';
import ArticleCard from '../Cards/ArticleCard';

export default class Articles extends React.Component {
  render () {
    const { data } = this.props;
    const { articles } = data;

    const numArticles = Object.keys(articles || {}).length;

    return (
      <div className='Articles knowledge-section'>
        <div className='list'>
          <div className='header'>
            <p>
              {numArticles} Article{numArticles !== 1 ? 's' : ''}
            </p>

            <Button
              color='#168E32'
              onClick={() => this.props.history.push('/knowledge/articles/new')}>
              New
            </Button>
          </div>

          {
            numArticles > 0
              ? Object.keys(articles).map((id, i) => {
                const article = articles[id];

                return (
                  <ArticleCard
                    key={i}
                    article={article}
                    onClick={() => this.props.history.push(`/knowledge/articles/${id}`)}
                  />
                );
              })
              : <div style={{ padding: '10px' }}>
                <p>No articles added yet.</p>
              </div>
          }
        </div>
      </div>
    );
  }
}
