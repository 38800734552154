import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { FirebaseProvider } from "@utils/firebase";
import { BugsnagClient } from "@utils/bugsnag";

const url = "/api/";

export class ConsoleApiError extends Error {
  name = 'ConsoleApiError';
  statusCode: number;
  statusMessage: string;

  constructor (errorMessage: string, statusCode?: number, statusMessage?: string) {
    super(errorMessage);

    this.statusCode = statusCode ?? 500;
    this.statusMessage = statusMessage ?? '';
  }
}

enum Method {
  GET = "get",
  POST = "post",
  PATCH = "patch",
  PUT = "put",
  DELETE = "delete",
}

export interface ConsoleApiConstructor {
  new (
    client: typeof axios,
    firebaseProvider: FirebaseProvider,
    errorReportingClient: BugsnagClient
  ): ConsoleApiService;
}

export default class ConsoleApiService {
  private client: typeof axios;
  private firebaseProvider: FirebaseProvider;
  private errorReportingClient: BugsnagClient;

  constructor(
    client: typeof axios,
    firebaseProvider: FirebaseProvider,
    errorReportingClient: BugsnagClient
  ) {
    this.client = client;
    this.firebaseProvider = firebaseProvider;
    this.errorReportingClient = errorReportingClient;
  }

  /**
   * Makes GET request to given url
   * 
   * Note: will throw if no data returned.
   * @param path string
   * @returns Response
   */
  async get<Response extends any>(
    path: string, 
    queryParams?: Record<string, string>
  ): Promise<Response> {
    const response = await this._request<Response, void>(Method.GET, path, undefined, queryParams);

    if (!response) throw new ConsoleApiError(`No record found for requested data at ${path}`);

    return response;
  }

  async post<Response extends any, Body = any>(
    path: string,
    body: Body,
    queryParams?: Record<string, string>,
  ): Promise<Response> {
    return await this._request<Response, Body>(Method.POST, path, body, queryParams);
  }

  async patch<Response extends any, Body = any>(
    path: string,
    body: Body,
    queryParams?: Record<string, string>,
  ): Promise<Response> {
    return await this._request<Response, Body>(Method.PATCH, path, body, queryParams);
  }

  async put<Response extends any, Body = any>(
    path: string,
    body: Body,
    queryParams?: Record<string, string>,
  ): Promise<Response> {
    return await this._request<Response, Body>(Method.PUT, path, body, queryParams);
  }

  async delete<Response extends any, Body = any>(
    path: string,
    body?: Body,
    queryParams?: Record<string, string>,
  ): Promise<Response> {
    return await this._request<Response, Body>(Method.DELETE, path, body, queryParams);
  }

  private async _request<Response extends any, Body = any>(
    method: Method,
    path: string,
    body?: Body,
    queryParams?: Record<string, string>,
  ): Promise<Response> {
    try {
      const authToken = await this._getAuthToken();

      const request: AxiosRequestConfig = {
        method,
        url: url + path,
        data: body ?? undefined,
        params: queryParams ?? undefined,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };

      const response = await this.client(request);

      const data = response.data;

      return data;
    } catch (err) {
      console.error(err);
      this.errorReportingClient.notify(err as Error);

      if (axios.isAxiosError(err)) {
        this._handleAxiosError(err, method, path);
      }

      throw new ConsoleApiError(`${method} request to ${path} failed`);
    }
  }

  private async _getAuthToken() {
    try {
      const auth = this.firebaseProvider.getConsoleAuth();
      const currentUser = auth.currentUser;

      const token = await currentUser?.getIdToken();

      if (!token)
        throw new ConsoleApiError("Failed to fetch Firebase Authorization token");

      return token;
    } catch (err) {
      console.error(err);
      this.errorReportingClient.notify(err as Error);

      throw err;
    }
  }

  private _handleAxiosError(
    err: AxiosError,
    requestMethod: Method,
    path: string
  ) {
    const { code, config, response, request } = err;
    const { url, method } = config;
    
    if (response) {
      const { status, statusText } = response;

      throw new ConsoleApiError(
        `Response to ${method} :: ${url} failed with status ${status} ${statusText}`,
        status,
        statusText
      );
    } else if (request) {
      throw new ConsoleApiError(
        `Request to ${method} :: ${url} failed, ${
          code ? `code ${code}` : "unknown code"
        }`,
        undefined,
        code
      );
    } else {
      throw new ConsoleApiError(
        `Request to ${requestMethod} :: ${path} failed`
      );
    }
  }
}
