import React, { Fragment } from "react";
import pr from '@packageroute/types-firebase';
import {RouteComponentProps} from 'react-router-dom';
import Icon from "../Icon/Icon";
import { titleize } from "../../utils/formatting";
import "./UserIsp.css";

type AppUser = pr.users.User;
type Profile = pr.isp.profile.Profile & { id: string };

type Props = {
  history: RouteComponentProps['history'];
  user: AppUser;
  isp: Profile;
  isLastActive?: boolean;
}

type State = {

}

class UserIsp extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.openIsp = this.openIsp.bind(this);
  }

  openIsp(ispId: string) {
    this.props.history.push(`/isps/${ispId}`);
  }

  render() {
    const { user, isp, isLastActive } = this.props;

    const assignedWorkAreas = [];
    const workAreas = Object.values(isp.WorkAreas || {});

    for (const workArea of workAreas) {
      if (workArea.driver === user.FedExId) {
        assignedWorkAreas.push(workArea.workArea);
      }
      else if (workArea.alternateDrivers && workArea.alternateDrivers[user.FedExId]) {
        assignedWorkAreas.push(workArea.workArea);
      }
    }

    const isAccountOwner = user.uid === isp.ownerUID;
    
    const { type } = (isp.drivers || {})[user.FedExId] || {
      isOwner: false,
      type: "",
      dotExpirationDate: ""
    };

    return (
      <div className={`UserIsp ${isLastActive ? "active" : ""}`}>
        <div className="top" onClick={() => this.openIsp(isp.id)}>
          <div className="identity">
            <div className="activeLightWrap">
              <div className={`activeLight${isLastActive ? " active" : ""}`} />
            </div>

            <div className="nameWrap">
              <h3 className="userName" style={{ marginRight: "5px" }}>
                {titleize(isp.CSPName)}
              </h3>
              {isp && isp.station && isp.station.stationID ? (
                <Fragment>
                  <Icon name="city" size={16} color="#999" />
                  <div className="userId">{isp.station.stationID}</div>
                </Fragment>
              ) : null}
            </div>
          </div>
          <div className="misc">
            {
              assignedWorkAreas.map(workAreaId => (
                <div className="waWrap">
                  <p>{workAreaId}</p>
                </div>
              ))
            }

            {isAccountOwner && (
              <div
                className="iconWrap"
                style={{ marginLeft: "10px", marginRight: "10px" }}
              >
                <Icon name="crown" color="#168E32" size={25} />
              </div>
            )}

            <div
              className="iconWrap"
              style={{ marginLeft: "10px", marginRight: "10px" }}
            >
              {/* @ts-ignore */}
              {type === "owner" || type === "bc" ? (
                <div className="bcLabel">BC</div>
              ) : (
                <Icon
                  name="truck"
                  color="#FF9200"
                  size={25}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserIsp;
