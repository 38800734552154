import moment from 'moment-timezone';
import { NumericActivityPropertyKeys } from '@reducers/metrics/dailyOperationsActivity';
import { SpecGroup, DashboardData, DashboardDataKey } from './metricSpecs';
import { groupDatesByWeek } from '@utils/dates';

export enum GroupBy {
  DAILY = 'daily',
  WEEKLY = 'weekly',
}

export function getLastXDates (numDates: number, offset = 0) {
  const days = [];

  for (let i = offset + numDates; i >= offset; i--) {
    const today = moment().tz('America/Los_Angeles');

    days.push(today.subtract(i, 'days').format('YYYY-MM-DD'));
  }

  return days;
}

export function getLastXWeeks (numWeeks: number) {
  const weeks = [];

  for (let i = numWeeks; i >= 0; i--) {
    const days = getLastXDates(6, i * 7);

    weeks.push(days);
  }

  return weeks;
}

export function getDailyLabel (date: string) {
  return moment(date, 'YYYY-MM-DD').format('MMM-D');
}

export function getAvg (total: number, num: number) {
  return parseInt(`${total / num}`);
}

export function getWeeklyLabel (dates: string[]) {
  const startDate = moment(dates[0], 'YYYY-MM-DD').format('MMM-D');
  const endDate = moment(dates[dates.length - 1], 'YYYY-MM-DD').format('MMM-D');

  return `${startDate} - ${endDate}`;
};

export function getDailyTotal (data: DashboardData, date: string, key: DashboardDataKey) {
  const dataOnDate = data[date];


  return dataOnDate?.[key] || 0;
};

export function getWeeklyTotal (data: DashboardData, dates: string[], key: DashboardDataKey) {
  let total = 0;

  for (const date of dates) {
    const dailyTotal = getDailyTotal(data, date, key);

    total += dailyTotal;
  }

  return total;
};

export function getWeeklyAverage (data: DashboardData, dates: string[], key: DashboardDataKey) {
  let total = 0;

  for (const date of dates) {
    const dailyTotal = getDailyTotal(data, date, key);

    total += dailyTotal;
  }

  return parseInt(`${total / dates.length}`);
};


export type SpecData = {
  [x: string]: string | number;
  name: string;
}[];

export function getSpecData (
  data: DashboardData,
  key: DashboardDataKey, 
  dataKey: string, 
  groupBy: GroupBy, 
  perDayOnly: boolean, 
  lastXWeeks: string[][], 
  lastXDays: string[], 
  perWeekAverage: boolean,
): SpecData {
  if (groupBy === GroupBy.WEEKLY && !perDayOnly) {
    if (perWeekAverage) {
      return lastXWeeks.map(dates => {
        const total = getWeeklyAverage(data, dates, key);
        const label = getWeeklyLabel(dates);
  
        return {
          name: label,
          [dataKey]: total
        }
      })
    } else {
      return lastXWeeks.map(dates => {
        const total = getWeeklyTotal(data, dates, key);
        const label = getWeeklyLabel(dates);
  
        return {
          name: label,
          [dataKey]: total
        }
      })
    }
  } else {
    return lastXDays.map(date => {
      const total = getDailyTotal(data, date, key);
      const label = getDailyLabel(date);

      return {
        name: label,
        [dataKey]: total
      };
    });
  }
}

export function getSpecTotal (
  data: DashboardData,
  key: DashboardDataKey, 
  groupBy: GroupBy, 
  perDayOnly: boolean, 
  lastXWeeks: string[][], 
  lastXDays: string[], 
  perWeekAverage: boolean,
) {
  if (groupBy === GroupBy.WEEKLY && !perDayOnly) {
    if (perWeekAverage) {
      return lastXWeeks.reduce((acc, dates) => {
        const weeklyTotal = getWeeklyAverage(data, dates, key);
  
        acc += weeklyTotal;
  
        return acc;
      }, 0)
    } else {
      return lastXWeeks.reduce((acc, dates) => {
        const weeklyTotal = getWeeklyTotal(data, dates, key);
  
        acc += weeklyTotal;
  
        return acc;
      }, 0)
    }
  } else {
    return lastXDays.reduce((acc, date) => {
      const total = getDailyTotal(data, date, key);

      acc += total;

      return acc;
    }, 0);
  }
}

export type SpecProps = {
  total: number;
  average: number;
  data: SpecData;
  color: string;
  strokeColor: string;
  dataKey: string;
  totalLabel: string | undefined;
  averageLabel: string | undefined;
}

export function mapSpecGroupToSpecProps (
  specGroupData: DashboardData, 
  specGroup: SpecGroup, 
  groupBy: GroupBy, 
  dates: string[],
  perWeekAverage: boolean,
  timeZone: string,
): SpecProps[] {
  const { keys, specs, shared } = specGroup;
  const { perDayOnly } = shared;
  const weeks = groupDatesByWeek(dates, timeZone);

  return keys.map(key => {
    const { dataKey, color, strokeColor, totalLabel, averageLabel } = specs[key]!;

    const total = getSpecTotal(specGroupData, key, groupBy, !!perDayOnly, weeks, dates, perWeekAverage);
    const data = getSpecData(specGroupData, key, dataKey, groupBy, !!perDayOnly, weeks, dates, perWeekAverage);
    const average = getAvg(total, data.length);

    return { total, data, average, color, strokeColor, dataKey, totalLabel, averageLabel }
  });
}

export function combineSpecPropsAsGraphableData (specProps: SpecProps[]) {
  return specProps.reduce<Record<string, string | number>[]>((acc, { data }) => {
    acc = data.map((d, i) => ({ ...acc[i], ...d }));

    return acc;
  }, [])
}