import React from 'react';

import './Emails.css';
import Section from '../Page/Section';
import PageWrapper from '../Page/PageWrapper';
import EmailOption from './EmailOption';
import SendEmail from './SendEmail';
import EmailLists from './EmailLists';
import api from '../../utils/api';
import withPermissions from '../Permissions/withPermissions';

const downloadTemplateIds = [
  'd-9b9b3d52e6a1482c8816448251aaa894',
  'd-a7b28c8037dc4664b04f8a9ff5d368e4'
];

class Emails extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      sendingEmail: false,
      emailTemplate: null,
      emailAddresses: []
    };
  }

  async componentDidMount () {
    const downloadTemplates = [];

    for (const templateId of downloadTemplateIds) {
      const res = await api.request(`emails/template/${templateId}`);

      downloadTemplates.push(res.data);
    }

    this.setState({ downloadTemplates });
  }

  render () {
    return (
      <PageWrapper>
        <Section title='Email Lists'>
          <EmailLists
            endpoint={`emails/list`}
          />
        </Section>

        <Section title='Send Email'>
          <SendEmail template={this.state.emailTemplate} resetTemplate={() => this.setState({ emailTemplate: null })} />
        </Section>

        <Section title='Download Instruction Emails'>
          {
            this.state.downloadTemplates && this.state.downloadTemplates.map(template => {
              return (
                <EmailOption
                  selected={this.state.emailTemplate && template.id === this.state.emailTemplate.id}
                  template={template}
                  onClick={() => this.setState({ emailTemplate: template })}
                />
              )
            })
          }
        </Section>
      </PageWrapper>
    );
  }
}

export default withPermissions(Emails);
