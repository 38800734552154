import React from 'react';
import moment from 'moment-timezone';
import Spinner from '../common/Spinner';
import {
  useTrialSubscriptions,
  useCancelledSubscriptions,
  useCancellingAtPeriodEndSubscriptions,
  useConvertedSubscriptions,
  usePastDueSubscriptions,
  useTotalSignups,
  useIsConversion,
  useTrialConversionRate,
  useSignupsLoaded,
  useTrialSubscriptionsLoaded,
  usePastDueSubscriptionsLoaded,
  useCancelledSubscriptionsLoaded,
  useCancellingAtPeriodEndSubscriptionsLoaded,
  useConvertedSubscriptionsLoaded,
} from '@hooks/redux';
import { isCancellation } from "@utils/subscriptions";
import './LifecycleMetrics.css';

type Props = {
  lastNDays: number;
}

export default function LifecycleMetrics ({
  lastNDays
}: Props) {
  const totalSignups = useTotalSignups();
  const signupsLoaded = useSignupsLoaded();
  const trials = useTrialSubscriptions();
  const trialsLoaded = useTrialSubscriptionsLoaded();
  const pastDue = usePastDueSubscriptions();
  const pastDueLoaded = usePastDueSubscriptionsLoaded();
  const canceled = useCancelledSubscriptions();
  const canceledLoaded = useCancelledSubscriptionsLoaded();
  const cancelAtEndOfPeriod = useCancellingAtPeriodEndSubscriptions();
  const cancelAtEndOfPeriodLoaded = useCancellingAtPeriodEndSubscriptionsLoaded();
  const conversions = useConvertedSubscriptions();
  const conversionsLoaded = useConvertedSubscriptionsLoaded();
  const trialConversionRate = useTrialConversionRate();

  const isConversion = useIsConversion();

  const timeLimit = moment.tz('America/Los_Angeles').subtract(lastNDays, 'days').startOf('day').valueOf();
  const converted = conversions && conversions.filter(entry => isConversion(entry, timeLimit));
  const cancelations = canceled && canceled.filter(subscription => isCancellation(subscription, {timeLimit}));

  return (
    <div className='LifecycleMetrics'>
      <Metric
        label='All Signups'
        value={totalSignups}
        loading={!signupsLoaded}
        color='#006DAB'
        scope={`Last ${lastNDays} days`}
      />

      <Metric
        label='Trialing'
        value={trials.length}
        loading={!trialsLoaded}
        color='#EA7D14'
        scope='Current'
      />

      <Metric
        label='Past Due'
        value={pastDue.length}
        loading={!pastDueLoaded}
        color='rgb(185, 12, 15)'
        scope='Current'
      />

      <Metric
        label='Converted'
        value={converted.length}
        loading={!conversionsLoaded}
        color='rgb(22, 142, 50)'
        scope={`Last ${lastNDays} days`}
      />

      <Metric
        label='Conversion Rate'
        loading={!conversionsLoaded}
        value={!isNaN(trialConversionRate) ? `${Math.round(trialConversionRate * 100)} %` : 0}
        color='rgb(22, 142, 50)'
        scope={`Trailing 2 weeks`}
      />

      <Metric
        label='Canceled'
        value={cancelations.length}
        loading={!canceledLoaded}
        color='#666'
        scope={`Last ${lastNDays} days`}
      />

      <Metric
        label='Cancel At Period End'
        value={cancelAtEndOfPeriod.length}
        loading={!cancelAtEndOfPeriodLoaded}
        color='#666'
        scope='Next 30 days'
      />
    </div>
  );
}

type MetricProps = {
  label: string;
  value: number | string;
  valuePlaceholder?: number | string;
  loading: boolean;
  color: string;
  scope: string;
}

function Metric ({ label, value, scope, loading, color, valuePlaceholder = 0 }: MetricProps) {
  return (
    <div className='metric'>
      <div style={{ height: 50, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', flexDirection: 'column' }}>
        <p className='metric-title'>{label}</p>
      </div>

      <p className='number' style={{ color }}>
        {loading 
          ? <Spinner size={30} />
          : value ?? valuePlaceholder
        }
      </p>

      <p className='scope'>{scope}</p>
    </div>
  )
}