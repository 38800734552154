const { creds, shards } = require('../../config.json');

if (!creds || !shards) {
  throw new Error('Invalid Firebase configuration');
}

const config = {
  creds,
  shards,
}

export default config;