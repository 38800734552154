import React from 'react';
import { FlatList } from 'react-native';
import DetailedListCard, { Props as ListCardProps } from './DetailedListCard';

interface Props {
  activeItemId: string;
  defaultItemId: string;
  listItems: Omit<ListCardProps, 'isActive'>[];
}

interface State {
  activeItemId: string;
}

export default class DetailedList extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props);

    this.state = {
      activeItemId: props.defaultItemId
    };
  }

  render () {
    const { listItems } = this.props;
    const { activeItemId } = this.props;

    return (
      <FlatList
        data={listItems}
        renderItem={({ item }) => {
          const { id, onPress, ...rest } = item;

          return (
            <DetailedListCard
              id={id}
              onPress={onPress}
              isActive={!!(id && id === activeItemId)}

              { ...rest }
            />
          )
        }}
      />
    );
  }
}