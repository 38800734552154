import React from 'react';
import { HighQualitySetting } from '@packageroute/biz/lib/service-settings/geocoding';

import HighQualityGeocodingCSP from './HighQualityGeocodingCSP';
import Spinner from '../../common/Spinner';
import api from '../../../utils/api';

export default class HighQualityGeocodingList extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      loading: false,
      highQualitySetting: new HighQualitySetting()
    }
  }

  async componentDidMount () {
    await this.fetchHighQualitySetting();
  }

  async fetchHighQualitySetting () {
    try {
      this.setState({ loading: true });

      const res = await api.request('service-management/high-quality-geocoding');
      const data = res.data;

      console.log(data);

      this.setState({ highQualitySetting: new HighQualitySetting(data), loading: false });
    }
    catch (err) {
      console.error(err);
    }
  }

  render () {
    const { loading, highQualitySetting } = this.state;
    const csps = highQualitySetting.getCSPSList();

    return (
      <div style={{ padding: '5px 10px', minHeight: '50px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        {
          !loading && csps
            .sort((a, b) => {
              return a.expiresAt > b.expiresAt;
            })
            .map((highQualityCSPSetting, i) => {
            console.log(this.props.liveISPList, highQualityCSPSetting);
            const profile = this.props.liveISPList && this.props.liveISPList[highQualityCSPSetting.operationId];

            return (
              <React.Fragment>
                {
                  i !== 0 && <div style={{ width: '100%', height: '1px', backgroundColor: '#ddd', margin: '5px 0' }} />
                }
                <HighQualityGeocodingCSP
                  profile={profile}
                  operationId={highQualityCSPSetting.operationId}
                  highQualityCSP={highQualityCSPSetting}
                />
              </React.Fragment>
            )
          })
        }   

        {
          !loading && !csps.length &&
          <p>No CSPs are currently receiving high quality geocodes across their operation</p>
        } 

        {
          loading && <Spinner />
        }
      </div>
    )
  }
}