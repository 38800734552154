import React from 'react';
import pr from '@packageroute/types-firebase';
import './IspEntry.css';
import { Link } from 'react-router-dom';
import Icon from '../Icon/Icon';
import salesCycleStatus, { getDisplayName, getDisplayColor } from '../../utils/salesCycleStatus';
import PipelineStatus from '../PipelineStatus/PipelineStatus';
import FirebaseLink from '../common/FirebaseLink';

type Profile = pr.isp.profile.Profile;

type Props = {
  isp: {
    entityName?: string;
    firebaseId?: string;
    terminal?: string;
    ownerName?: string;
  };
  profile?: Profile;
  matches?: {
    entityName?: string;
    ownerName?: string;
  };
  inactive?: boolean;
  children?: any;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  to?: string;
}

export default function IspEntry (props: Props) {
  const { isp, profile, matches, onClick, to } = props;
    
  const { activeRoutes, totalRoutes } = (() => {
    let totalRoutes = 0;
    let activeRoutes = 0;

    for (const key in (profile?.WorkAreas || {})) {
      totalRoutes++;

      if (profile?.WorkAreas[key].paid) activeRoutes++;
    }

    return { totalRoutes, activeRoutes };
  })();

  const status = salesCycleStatus(isp);
  const displayName = getDisplayName(status);
  const displayColor = getDisplayColor(status);
  const Element = onClick 
    ? (subProps: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
        return <div {...subProps} onClick={onClick} /> 
      }
    : Link;

  return ( // @ts-ignore
    <Element
      to={to}
      className={`IspEntry ${props.inactive ? 'inactive' : ''}`}>
      <div className='iconWrap'>
        <Icon name='truck' size={30} color='#168E32' />
      </div>

      <div className='entryBody'>
        <div className='identity'>
          <h2 className='ispTitle'>
            <span className={matches && matches.entityName ? 'match' : ''}>
              { isp?.entityName || profile?.CSPName || 'No CSP Name'}
            </span>

            {
              isp?.firebaseId &&
              <FirebaseLink path={`ISP/${isp?.firebaseId}`} />
            }
          </h2>

          <div className='stationWrap'>
            <Icon name='city' size={20} color='#999' />

            <h4>
              <span>
                { isp?.terminal || profile?.station?.stationID || 'No station id' }
              </span>
            </h4>
          </div>

          {
            isp?.ownerName
              ? <div className='owner'>
                  <Icon name='account' size={20} color='#999' />
                  <h4><span className={matches && matches.ownerName ? 'match' : ''}>{isp?.ownerName}</span></h4>
                </div>
              : null
          }
        </div>

        <div className='actions'>
          {props.children}
        </div>

        <div className='isp-status'>
          { (profile && Object.keys(profile).length)
              ? <div className='fbEntry'>
                <h2 className='routes'>
                  <span>
                    { activeRoutes }
                  </span>
                  {` / `}{ totalRoutes }
                </h2>

                {
                  activeRoutes > 0
                    ? <h4 className='paidStatus'>PAID</h4>
                    : <h4 className='unpaidStatus'>UNPAID</h4>
                }
              </div>
              : null
          }

          {
            isp?.firebaseId || status !== 'NO STATUS'
              ? <div className='crmEntry'>
                {
                  isp?.firebaseId
                    ? <h4 className='prevAssoc'>Associated</h4>
                    : null
                }

                {
                  status !== 'NO STATUS'
                    ? <PipelineStatus
                      text={displayName}
                      backgroundColor={displayColor}
                      fontSize='12px'
                    />
                    : null
                }
              </div>
              : null
          }
        </div>
      </div>
    </Element>
  );
}
