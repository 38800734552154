import React from 'react';
import moment from 'moment-timezone';

import Icon from '../../Icon/Icon';
import InputText from '../../Page/InputText';
import RecipientItem from '../Recipients/RecipientItem';
import firebaseProvider from '@utils/firebase';
import { LiveIspList } from '@reducers/liveIspList';
import { SalesCampaignCollection } from '@reducers/salesCampaignCollections';
import { ConsoleCollectionsService } from '@services';
import './Collection.css';

enum OpenState {
  STATIONS = 'stations',
  ISPS = 'isps',
  DEFAULT = '',
}

type Props = {
  collection: SalesCampaignCollection;
  editable?: boolean;
  selectable: boolean;
  selected: boolean;
  select: () => void;
  profiles: LiveIspList;
}

type State = {
  name: string;
  open: OpenState;
}

export default class Collection extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props);

    this.state = {
      name: props.collection.name,
      open: OpenState.DEFAULT
    }

    this.updateName = this.updateName.bind(this);
    this.delete = this.delete.bind(this);
    this.open = this.open.bind(this);
  }

  open (e: React.SyntheticEvent, type: OpenState) {
    e.stopPropagation();

    this.setState({ open: type || OpenState.DEFAULT });
  }

  async updateName () {
    try {
      const { name } = this.state;
      const { collection } = this.props;

      const collectionsService = new ConsoleCollectionsService(firebaseProvider);

      if (!name) {
        return this.setState({ name: collection.name });
      }

      await collectionsService.updateCollectionName(collection, name);
    }
    catch (err) {
      console.log(err);

      this.setState({ name: this.props.collection.name });
    }
  }

  async delete () {
    const { collection } = this.props;

    const collectionsService = new ConsoleCollectionsService(firebaseProvider);

    await collectionsService.removeCollection(collection);
  }

  render () {
    const { collection, editable, select, selectable, selected, profiles } = this.props;
    const { open } = this.state;

    const timestamp = collection.createdAt;

    return (
      <div
        className={`Collection ${selectable ? 'selectable' : ''} ${selected ? 'active' : ''}`}
        onClick={select || undefined}>
        <div className={`summary ${open ? 'open' : null}`}>
          <div className='row'>
            <div className='col pad' style={{ flex: 1 }}>
              <div className='row'>
                {
                  !editable
                    ? <p style={{ fontWeight: 400 }}>{collection.name}</p>
                    : <div
                      className='name-wrapper'>
                      <InputText
                        type='text'
                        value={this.state.name}
                        onChange={(e) => this.setState({ name: e.target.value })}
                        onBlur={this.updateName}
                        inputStyle={{
                          border: 'none',
                          backgroundColor: 'inherit',
                          margin: '5px 0',
                          padding: 0,
                          fontWeight: 400
                        }}
                      />
                    </div>
                }
              </div>

              <div className='row'>
                <p
                  onClick={(e) => this.open(e, open === OpenState.ISPS ? OpenState.DEFAULT : OpenState.ISPS)}
                  className='recipient small spaced'>{(collection.isps || []).length} ISPs</p>
                <p
                  onClick={(e) => this.open(e, open === OpenState.STATIONS ? OpenState.DEFAULT : OpenState.STATIONS)}
                  className='recipient small spaced'>{(collection.stations || []).length} Stations</p>
              </div>
            </div>

            <div className='col pad'>
              <p className='small'>created {moment(timestamp).fromNow()}</p>
            </div>

            {
              editable
                ? <div className='col pad delete'>
                  <div onClick={this.delete}>
                    <Icon name='close' size={25} color='#999' />
                  </div>
                </div>
                : null
            }
          </div>
        </div>

        {
          open && collection
            ? <div className='recipient-list'>
              {
                collection[open]
                ? collection[open]?.map(id => {
                  const type = {
                    [OpenState.STATIONS]: 'STATION',
                    [OpenState.ISPS]: 'ISP',
                  }[open];

                  const label = {
                    [OpenState.STATIONS]: id,
                    [OpenState.ISPS]: profiles[id]
                      ? profiles[id].CSPName
                      : id,
                  }[open];

                  return (
                    <RecipientItem
                      item={{
                        type,
                        label
                      }}
                    />
                  )
                }) ?? null
                : null
              }
            </div>
            : null
        }
      </div>
    );
  }
}
