import React from 'react';

import './TemplateList.css';
import TemplateOption from './TemplateOption';
import InputText from '../../../Page/InputText';
import firebaseProvider from '../../../../utils/firebase';

export default class TemplateList extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      addTemplateId: null
    };

    this.addTemplate = this.addTemplate.bind(this);
  }

  async addTemplate () {
    const { addTemplateId } = this.state;
    const database = firebaseProvider.getConsoleDefaultDB();
    const ref = database.ref('emailTemplates');
    const templatesSnap = await ref.once('value');
    const templates = templatesSnap.val() || [];

    if (addTemplateId) {
      templates.push((addTemplateId || '').trim());

      await ref.set(templates);
    }

    this.setState({ addTemplateId: null });
  }

  render () {
    const { templates, activeTemplate } = this.props;

    return (
      <div className='TemplateList'>
        <div className='add-template'>
          <InputText
            type='text'
            value={this.state.addTemplateId || ''}
            onChange={(e) => {
              console.log(e);
              this.setState({ addTemplateId: e.target.value });
            }}
            inputStyle={{
              border: 'none',
              padding: '5px'
            }}
            placeholder='Template Id'
          />

          <div className='add-btn' onClick={this.addTemplate}>
            <p>Add</p>
          </div>
        </div>

        <div className='scrollable'>
          {
            templates.map((template, i) => {
              return (
                <TemplateOption
                  key={`templateOption-${i}`}
                  selected={activeTemplate && activeTemplate.id === template.id}
                  template={template}
                  onClick={() => this.props.selectTemplate(template)}
                />
              );
            })
          }
        </div>
      </div>
    );
  }
}
