import React from 'react';
import { Link } from 'react-router-dom'
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';

import Icon from '../Icon/Icon';

interface Props {
  color?: string;
  value?: boolean | number | string | null;
  icon?: string;
  iconSize?: number;
  label?: string;
  onPress?: (event?: React.SyntheticEvent) => void;
  CustomIcon?: (string | JSX.Element | null)[] | JSX.Element | JSX.Element[] | number | string | string[] | undefined | null;
}

export default function ButtonDetail ({color, value, icon, iconSize, label, onPress}: Props) {
  const WrapComponent = onPress
    ? TouchableOpacity
    : View;
  return (
    <WrapComponent className='ButtonDetail' style={{flex: 1}} onPress={onPress}>
      <View style={styles.container}>
        <View style={styles.iconWrap}>
          { icon
            ? <Icon name={icon} size={iconSize || 24} color={value ? color : '#999'} />
            : <Text style={{...styles.num, color: value ? color || 'black' : '#999'}}>{ typeof value !== 'boolean' ? value || '-' : '-'}</Text>
          }
        </View>

        <Text style={{...styles.label, color: onPress && value && color ? color : '#999' }}>{ label || '' }</Text>
    </View>
    </WrapComponent>
  );
}

interface LinkProps extends Omit<Props, 'onPress'> {
  link: string | null;
  linkState?: { [key: string]: any };
  external?: boolean;
}

export function LinkDetail ({color, value, icon, iconSize, label, link, linkState, external}: LinkProps) {

  const contents = (
    <View style={styles.container}>
      <View style={styles.iconWrap}>
        { icon
          ? <Icon name={icon} size={iconSize || 24} color={value ? color : '#999'} />
          : <Text style={{...styles.num, color: value ? color || 'black' : '#999'}}>{ typeof value !== 'boolean' ? value || '-' : '-' }</Text>
        }
      </View>

      <Text style={{...styles.label, color: value && color ? color : '#999' }}>{ label || '' }</Text>
    </View>
  )

  if (!link) return (
    <View className='LinkDetail' style={styles.link}>
      { contents }
    </View>
  ) 

  else if (external) return (
    <a href={link} style={styles.link}>
      { contents }
    </a>
  )

  else return ( 
    <Link className='LinkDetail' to={!linkState ? link : { pathname: link, state: linkState }} style={styles.link}>
      { contents }
    </Link>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    width: '100%',
    padding: 10,
  },
  iconWrap: {
    height: 28,
    justifyContent: 'center',
    alignItems: 'center',
  },
  num: {
    fontWeight: 500,
    fontSize: 20,
    textAlign: 'center',
  },
  label: {
    fontSize: 12,
    color: '#999',
    textAlign: 'center',
  },
  link: {
    flex: 1,
    textDecoration: 'none',
  }
});
