import React from 'react';

import Spinner from '../common/Spinner';
import InputText from '../Page/InputText';
import './UpdateISPSubscription.css';
import api from '../../utils/api';

export default class UpdateISPSubscription extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      updatingTrial: false,
      updatedTrial: false,
      trialDays: null
    };

    this.extendTrial = this.extendTrial.bind(this);
  }

  async extendTrial () {
    const { ISPID } = this.props;
    const { trialDays } = this.state;
    const daysToExtendTrial = parseInt(trialDays);

    console.log(daysToExtendTrial);

    if (this.updatingTrial) {
      return;
    }

    if (Number.isNaN(daysToExtendTrial)) {
      return this.setState({
        updatingTrial: false,
        trialError: 'Incorrect input for days (not a number)'
      });
    }

    if (daysToExtendTrial <= 0) {
      return this.setState({
        trialError: 'Must extend trial at least 1 day'
      });
    }

    this.setState({ updatingTrial: true });

    await api.post('subscriptions/trial/extend', { ISPID, days: daysToExtendTrial });

    await this.props.loadSubscription();

    this.setState({ updatingTrial: false, updatedTrial: true });
  }

  render () {
    const { subscription } = this.props;
    const isTrialing = subscription && subscription.status === 'trialing';
    let hasAvailableUpdates = isTrialing;

    return (
      <div className='UpdateISPSubscription'>
        {
          isTrialing
            ? <div className='extendTrial'>
              {
                this.state.updatingTrial || this.state.updatedTrial
                  ? this.updatingTrial
                    ? <Spinner />
                    : <p>Extended the trial {this.state.trialDays} days.</p>
                  : <div className='extendTrialForm'>
                    <InputText
                      type='number'
                      min={0}
                      placeholder={'Days'}
                      max={30}
                      value={this.state.trialDays}
                      onChange={(e) => {
                        if (this.state.updatingTrial) {
                          return;
                        }

                        this.setState({ trialDays: parseInt(e.target.value) });
                      }}
                    />

                    <div
                      onClick={!!this.state.trialDays && !this.state.updatingTrial
                        ? this.extendTrial
                        : null
                      }
                      className={`updateButton ${!this.state.trialDays ? 'inactive' : ''}`}>
                      <p>Extend Trial</p>
                    </div>
                  </div>

              }
            </div>
            : null
        }

        {
          !hasAvailableUpdates
            ? <div className='defaultMessage'>
              <p>No updates can be taken from the console for this ISP.</p>
            </div>
            : null
        }
      </div>
    );
  }
}
