import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type DateString = string;
export type DailyCompleteUsage = {
  activeISPs: {
    [ispId: string]: {
      activeUsers: string[];
      ISPID: string;
    };
  };
  activeUsers: {
    [uid: string]: {
      ISPID: string;
      uid: string;
    };
  };
  summary: {
    totalBCs: number;
    totalDrivers: number;
    totalISPs: number;
    totalUsers: number;
  };
};

export type DailyUsage = {
  activeISPs: {
    [ispId: string]: true,
  };
  activeUsers: {
    [uid: string]: true,
  }
  summary: {
    totalBCs: number;
    totalDrivers: number;
    totalISPs: number;
    totalUsers: number;
  }
}

const defaultDailyCompleteUsage: DailyCompleteUsage = {
  activeISPs: {},
  activeUsers: {},
  summary: {
    totalBCs: 0,
    totalDrivers: 0,
    totalISPs: 0,
    totalUsers: 0,
  }
}

export type Usage = Record<DateString, DailyUsage | null>;

export enum Status {
  INITIAL = "INITIAL",
  UPDATED = "UPDATED",
  FAILED = "FAILED",
}

type UsageState = {
  status: Status;
  data: Usage;
  today: DailyCompleteUsage;
  updatedAt: number | null;
};

const initialState: UsageState = {
  status: Status.INITIAL,
  data: {},
  today: {...defaultDailyCompleteUsage },
  updatedAt: null,
};

const usageSlice = createSlice({
  name: "dailyOperationsUsage",
  initialState: initialState,
  reducers: {
    updateTodayUsage: (
      state,
      action: PayloadAction<{ todayUsage: DailyCompleteUsage | null }>
    ) => {
      const { todayUsage } = action.payload;

      state.today = todayUsage || {...defaultDailyCompleteUsage};
      state.status = Status.UPDATED;
    },
    updateUsage: (
      state,
      action: PayloadAction<{ usage: Usage }>
    ) => {
      const { usage } = action.payload;

      state.data = {
        ...state.data,
        ...usage,
      }

      state.status = Status.UPDATED;
    },
    updateDailyUsage: (
      state,
      action: PayloadAction<{ date: string; dailyUsage: DailyUsage | null }>
    ) => {
      const { date, dailyUsage } = action.payload;

      state.data[date] = dailyUsage;
      state.status = Status.UPDATED;
    },
    updateUsageUpdatedAt: (state, action: PayloadAction<{ updatedAt: number | null }>) => {
      const { updatedAt } = action.payload;

      state.updatedAt = updatedAt;
    },
    resetUsage: (state) => {
      state.data = {};
      state.today = {...defaultDailyCompleteUsage};
      state.updatedAt = null;
      state.status = Status.INITIAL;
    },
  },
});

export const { updateTodayUsage, updateUsage, updateDailyUsage, updateUsageUpdatedAt, resetUsage } = usageSlice.actions;

export default usageSlice.reducer;
