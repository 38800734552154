import React from 'react';
import ScrollView from '../Page/ScrollView';
import Section from '../Page/Section';
import Icon from '../Icon/Icon';
import HDOnly from '../Station/HDOnly';
import CoLocation from '../Station/CoLocation';
import {useHDOnlyStations, useCoLocationStations} from '@hooks/redux'
import './StationLists.css';

type Props = {
  goToStationPage: (stationId: string) => void;
}

export default function StationLists ({ 
  goToStationPage, 
}: Props) {
  const coLocationStations = useCoLocationStations();
  const hdOnlyStations = useHDOnlyStations();
  
  return (
    <Section>
      <div className='StationLists'>
        <div className='list-wrapper'>
          <div className='list-header'>
            <h4>Co-Lo</h4>

            <p>{Object.keys(coLocationStations || {}).length} Stations</p>
          </div>

          <ScrollView direction='vertical' height='400px'>
            {
              Object.keys(coLocationStations || {})
                .filter(stationId => {
                  const station = coLocationStations[stationId];

                  return station.hasCoLocation === true;
                })
                .map(stationId => {
                  const station = coLocationStations[stationId];

                  return (
                    <div
                      className='station-item'
                      onClick={() => goToStationPage(stationId)}>
                      <div className='station-info'>
                        <Icon name='city' size={20} color='#006DAB' />
                        <p>{stationId}</p>
                      </div>

                      <CoLocation station={station} editable={false} />
                    </div>
                  );
                })
            }
          </ScrollView>
        </div>

        <div className='list-wrapper'>
          <div className='list-header'>
            <h4>HD Only</h4>

            <p>{Object.keys(hdOnlyStations || {}).length} Stations</p>
          </div>

          <ScrollView direction='vertical' height='400px'>
            {
              Object.keys(hdOnlyStations || {})
                .filter(stationId => {
                  const station = hdOnlyStations[stationId];

                  return station.hdOnly === true;
                })
                .map(stationId => {
                  const station = hdOnlyStations[stationId];

                  return (
                    <div
                      className='station-item'
                      onClick={() => goToStationPage(stationId)}>
                      <div className='station-info'>
                        <Icon name='city' size={20} color='#006DAB' />
                        <p>{stationId}</p>
                      </div>

                      <HDOnly station={station} editable={false} />
                    </div>
                  );
                })
            }
          </ScrollView>
        </div>
      </div>
    </Section>
  );
}
