import React from 'react';
import './ReasonsForCancellation.css';

export type Feedback = Partial<Record<string, string | boolean>>;

type Props = {
  feedback?: Feedback
  reasons?: Record<string, boolean>;
  comment?: string | boolean;
}
export default function ReasonsForCancellation ({ feedback, reasons, comment = false }: Props) {
  const reasonLabels = Object.keys(reasons ?? {});
  const feedbackLabels = Object.keys(feedback ?? {});

  const hasNoFeedback = !reasonLabels.length && !feedbackLabels.length && !comment;

  if (hasNoFeedback) {
    return (
      <div className='ReasonsForCancellation defaultMessage'>
        <p>No reason for cancellation was given</p>
      </div>
    )
  }

  return (
    <div className='ReasonsForCancellation'>
      {
        (reasonLabels.length || feedbackLabels.length) &&
        <div className='reasonsWrap'>
          <React.Fragment>
          {
            reasonLabels.map((reason, i) => {
              return (
                <div key={i} className='reason'>
                  <p>{reason}</p>
                </div>
              )
            })
          }
          {
            feedbackLabels.map((reason, i) => {
              return (
                <div key={i} className='reason'>
                  {/* @ts-ignore */}
                  <p>{reason}{typeof feedback?.[reason] === 'string' ? `: ${feedback[reason]}` : ''}</p>
                </div>
              )
            })
          }
          </React.Fragment>
        </div>
      }
      { comment && 
        <p className='comment'>
          <span className='label'>Comments: </span>
          {comment}
        </p> 
      }
    </div>
  )
}
