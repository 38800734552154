import React from 'react';

import './Send.css';
import InputSelect from '../../Page/InputSelect';
import InputText from '../../Page/InputText';
import Spinner from '../../common/Spinner';
import Icon from '../../Icon/Icon';
import api from '../../../utils/api';
import firebaseProvider from '../../../utils/firebase';

export default class Send extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      sending: false,
      userType: null,
      excludeInactives: false,
      error: false,
      success: false
    };

    this.send = this.send.bind(this);
    this.resolveSend = this.resolveSend.bind(this);
  }

  async send () {
    try {
      this.setState({ sending: true });

      const message = {
        recipients: this.props.recipients,
        messageType: this.props.messageType,
        messageSpecs: this.props.messageSpecs,
        messageFilters: {
          userType: this.state.userType,
          excludeInactives: this.state.excludeInactives
        }
      }
      const consoleDB = firebaseProvider.getConsoleDefaultDB();
      const HistoryRef = consoleDB.ref(this.props.historyPath);
      const key = await HistoryRef.push().key;

      await HistoryRef.child(key).update({
        messageName: this.props.messageName,
        sentByUID: this.props.user.uid,
        message,
        sentAt: Date.now()
      });
      this.resolveSend(key)

      await api.post(`message`, {
        message,
        historyPath: `${this.props.historyPath}/${key}`
      });

    }
    catch (err) {
      console.log(err);
      this.setState({ sending: false, error: true });
    }
  }

  resolveSend (key) {
    const consoleDB = firebaseProvider.getConsoleDefaultDB();
    const HistoryRef = consoleDB.ref(this.props.historyPath);

    HistoryRef.child(key).on('value', snap => {
      const data = snap.val();

      if (data && data.success === true) {
        this.setState({ success: true, sending: false }, () => {
          if (this.props.markFinished) {
            this.props.markFinished();
          }
        });

        HistoryRef.child(key).off();
      }
      else if (data && data.success === false) {
        this.setState({ error: true, sending: false }, () => {
          if (this.props.markFinished) {
            this.props.markFinished();
          }
        });

        HistoryRef.child(key).off();
      }
    })
  }

  render () {
    const { sending, success, error } = this.state;

    let totalStations = 0;
    let totalISPs = 0;
    // let totalUsers = 0;

    for (const recipient of this.props.recipients) {
      if (recipient.recipientType === 'STATION') {
        totalStations += 1;
      }
      else if (recipient.recipientType === 'ISP') {
        totalISPs += 1;
      }
      else if (recipient.recipientType === 'USER') {
        // totalUsers += 1; // doesn't do anything...
      }
    }

    return (
      <div className='Send'>
        <div className='header'>
          <h3>Review</h3>

          <div className={`send-btn ${error ? 'error' : ''}`} onClick={!sending && !success ? this.send : undefined}>
            {
              this.state.sending
                ? <Spinner color='white' />
                : success
                  ? <Icon name='check' color='white' size={20} />
                  : error
                    ? <Icon name='close' color='white' size={20} />
                    : <p>Send</p>
            }
          </div>
        </div>

        <div className='summary'>
          <h5>This message will be sent to:</h5>
          <p>{totalStations} stations</p>
          <p>{totalISPs} isps</p>
        </div>

        <div className='filters'>
          <h5>Additional Filters</h5>

          <div className='field'>
            <InputSelect
              id='userType'
              label='User Type'
              type='text'
              value={this.state.userType}
              onChange={(e) => {
                const val = e.target.value;

                this.setState({ userType: val === 'All' ? null : val });
              }}
              options={[
                { value: null, name: 'All' },
                { value: 'owner', name: 'AO/BC' },
                { value: 'driver', name: 'Driver' }
              ]}
            />
          </div>

          <div className='field'>
            <InputText
              id='excludeInactives'
              type='checkbox'
              label='Exclude users who have never logged in.'
              value={this.state.excludeInactives}
              checked={this.state.excludeInactives}
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'flex-end',
                alignItems: 'center',
                height: '30px'
              }}
              inputStyle={{ width: '25px' }}
              labelStyle={{ marginLeft: '5px', margin: '0' }}
              onChange={() => this.setState(prevState => {
                return { excludeInactives: !prevState.excludeInactives };
              })}
            />
          </div>
        </div>
      </div>
    );
  }
}
