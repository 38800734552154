/**
 * Rounds number to decimal or integer value
 */
export function roundNumber (val: number, decimals = 1): number {
  if (typeof val !== 'number') {
    if (!Number.isNaN(Number(val))) {
      return roundNumber(Number(val), decimals);
    }
    
    return 0;
  }

  const asDecimal = Number(val?.toFixed(decimals ?? 0));

  if (asDecimal % 1 !== 0) return asDecimal;
  else return Math.floor(asDecimal);
}

/**
 * Simplifies number presentation. E.g. 25125 -> '2.5k'
 */
export function simplifyNumber (val: number, decimals = 1) {
  if (Math.abs(val) > 10000) {
    return roundNumber(val / 1000) + 'k';
  } else if (Math.abs(val) >= 1000) {
    return roundNumber(val / 1000, 2) + 'k';
  } else {
    return roundNumber(val, decimals);
  }
}