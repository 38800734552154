import React, { Fragment } from "react";
import Icon from "../../Icon/Icon";
import FirebaseLink from "../../common/FirebaseLink";
import api from "../../../utils/api";

import "./EnabledIsp.css";

export default class EnabledIsp extends React.Component {
  constructor(props) {
    super(props);

    this.state = { crmId: null, crmIdLoaded: false };

    this.getCrmId = this.getCrmId.bind(this);
  }

  componentDidMount() {
    this.getCrmId();
  }

  componentDidUpdate() {
    this.getCrmId();
  }

  getCrmId() {
    if (!this.state.crmIdLoaded) {
      api
        .getCrmIdForFirebaseId(this.props.isp.ISPID)
        .then(res => {
          this.setState({ crmId: res.id, crmIdLoaded: true });
        })
        .catch(err => {
          console.log(err);
          this.setState({ ...this.state, crmIdLoaded: false });
        });

      this.setState({ ...this.state, crmIdLoaded: true });
    }
  }

  render() {
    const {isp, history} = this.props;
    const crmId = this.state.crmId;


    if (isp) {
      return (
        <div
          to={this.state.crmId}
          className="EnabledIsp"
          onClick={crmId && (() => history.push(`/isps/${crmId}`))}
        >
          <div className="iconWrap">
            {isp.hasOwnProperty('pickupsEnabled') ? (
              <div className="delivery">
                <Icon
                  name="arrow-up-bold-box"
                  size={30}
                  color={isp.pickupsEnabled ? "#006DAB" : "#DDD"}
                />
              </div>
            ) : null}

            {isp.hasOwnProperty('deliveriesEnabled') ? (
              <div className="delivery">
                <Icon
                  name="arrow-down-bold-box"
                  size={30}
                  color={isp.deliveriesEnabled ? "#168E32" : "#DDD"}
                />
              </div>
            ) : null}
          </div>

          <div className="entryBody">
            <h2 className="ispTitle">
              {isp.entityName || isp.CSPName}
              {isp.firebaseId && <FirebaseLink path={`ISP/${isp.ISPID}`} />}
            </h2>

            <div className="stationWrap">
              <Icon name="city" size={20} color="#999" />

              <h4>
                <span>
                  {isp.terminal || (isp.station && isp.station.stationID)}
                </span>
              </h4>
            </div>

            {isp.ownerName ? (
              <div className="owner">
                <Icon name="account" size={20} color="#999" />
                <h4>
                  <span>{isp.ownerName}</span>
                </h4>
              </div>
            ) : null}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}