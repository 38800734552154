import React from "react";
import Map from "../Map/Map";
import StationMarker from "../Stations/StationMarker";
import StationISPList from "./StationISPList";
import { isISPPaid } from "../../utils/isp";
import "./StationSummary.css";

export default function StationSummary({ station, isps = [], liveISPList }) {
  const totalIsps = isps.length;
  let paidIsps = 0;

  for (const isp of isps) {
    const paid = isISPPaid(liveISPList[isp.firebaseId]);

    if (paid === true) {
      paidIsps += 1;
    }
  }

  return (
    <div className="StationSummary">
      <div className="left">
        <p className="fraction">
          {paidIsps} / {totalIsps}
        </p>
        <p className="fractionTitle">ISPs Subscribed</p>

        {!!totalIsps && !isNaN(totalIsps) && (
          <p className="fractionPercent">
            {((paidIsps / totalIsps) * 100).toFixed(2)} %
          </p>
        )}
      </div>

      <div className="middle">
        <StationISPList
          height={"300px"}
          station={station}
          liveISPList={liveISPList}
        />
      </div>

      <div className="right">
        <Map
          height="100%"
          zoom={6}
          snapToChildren
          defaultCenter={{
            lat: station.latitude ? parseFloat(station.latitude) : null,
            lng: station.longitude ? parseFloat(station.longitude) : null,
          }}
        >
          <StationMarker zoom={6} station={station} key={station.terminal} />
        </Map>
      </div>
    </div>
  );
}
