import { createSlice } from '@reduxjs/toolkit';
import { Appearance } from '@components/Native/react-native-compatibility';

type SharedColors = {
  red: string;
  blue: string;
  green: string;
  orange: string;
  purple: string;
}

type CustomColors = {
  base: string;
  baseHighlight: string;
  border: string;
  fullCont: string;
  ultraHighCont: string;
  highCont: string;
  medCont: string;
  lowCont: string;
  highlight: string;
}

export type ColorPack = SharedColors & CustomColors;
export type ColorPackKey = 
| 'light'
| 'brown'
| 'tracker'
| 'xena'
| 'black'
| 'pavement'
| 'contrast';

const darkModePacks: ColorPackKey[] = ['black', 'pavement'];

export type ColorPacks = Record<ColorPackKey, ColorPack>;

const sharedColors: SharedColors = {
  red: '#B90C0F',
  blue: '#006DAA',
  green: '#168E32',
  orange: '#EA7D14',
  purple: '#9E2DB6'
};

const colorPacks: ColorPacks = {
  light: {
    base: '#F5F5F5',
    baseHighlight: '#FFFFFF',
    border: '#DDDDDD',
    fullCont: '#000000',
    ultraHighCont: '#111111',
    highCont: '#444444',
    medCont: '#777777',
    lowCont: '#999999',
    highlight: '#FCEE9F',
    ...sharedColors
  },
  brown: {
    base: '#210E03',
    baseHighlight: '#33130A',
    border: '#511E0E',
    fullCont: '#FFFFFF',
    ultraHighCont: '#F9E4DE',
    highCont: '#F9E4DE',
    medCont: '#EDCFC8',
    lowCont: '#CE9789',
    highlight: '#FCEE9F',
    ...sharedColors,
    blue: '#FFB500',
    green: '#FFB500',
  },
  tracker: {
    base: '#061C49',
    baseHighlight: '#06205B',
    border: '#0A2966',
    fullCont: '#FFFFFF',
    ultraHighCont: '#DEE8FC',
    highCont: '#C3D1ED',
    medCont: '#9EADCC',
    lowCont: '#8697B7',
    highlight: '#FCEE9F',
    ...sharedColors,
    blue: '#FACD00',
    green: '#FACD00',
  },
  xena: {
    base: '#232F3E',
    baseHighlight: '#293747',
    border: '#2E4154',
    fullCont: '#FFFFFF',
    ultraHighCont: '#FFFFFF',
    highCont: '#FFFFFF',
    medCont: '#9EADCC',
    lowCont: '#8697B7',
    highlight: '#FCEE9F',
    ...sharedColors,
    blue: '#146EB4',
    green: '#FF9900',
  },
  black: {
    base: '#000000',
    baseHighlight: '#151515',
    border: '#444444',
    fullCont: '#FFFFFF',
    ultraHighCont: '#EEEEEE',
    highCont: '#E6E6E6',
    medCont: '#AAAAAA',
    lowCont: '#999999',
    highlight: '#54460A',
    ...sharedColors,
    red: '#D6252E'
  },
  pavement: {
    base: '#333333',
    baseHighlight: '#444444',
    border: '#666666',
    fullCont: '#FFFFFF',
    ultraHighCont: '#EAEAEA',
    highCont: '#EEEEEE',
    medCont: '#CCCCCC',
    lowCont: '#999999',
    highlight: '#54460A',
    ...sharedColors,
    blue: '#0F82B5',
    purple: '#B14BC9'
  },
  contrast: {
    base: '#F5F5F5',
    baseHighlight: '#FFFFFF',
    border: '#DDDDDD',
    fullCont: '#000000',
    ultraHighCont: '#000000',
    highCont: '#000000',
    medCont: '#333333',
    lowCont: '#333333',
    highlight: '#FCEE9F',
    ...sharedColors
  }
};

export type SystemColorMode = 'light' | 'dark';

export type ColorModeState = {
  colorMode: ColorPackKey;
  colorPack: ColorPack;
  systemColorMode: SystemColorMode;
  isDarkMode: boolean;
};

type Action<P> = { 
  payload: P, 
  type: string 
};

const initialState: ColorModeState = {
  colorMode: 'light',
  colorPack: colorPacks.light,
  systemColorMode: Appearance.getColorScheme() || 'light',
  isDarkMode: false,
};

const colorModeSlice = createSlice({
  name: 'colorMode',
  initialState: initialState,
  reducers: {
    setColorPack: (state: ColorModeState, action: Action<string>) => {
      const packName = action.payload as ColorPackKey;

      if (!colorPacks[packName]) {
        console.error('Invalid color pack selection');
        return;
      }

      state.colorMode = packName;
      state.colorPack = colorPacks[packName];
      state.isDarkMode = darkModePacks.includes(packName);
      state.systemColorMode = Appearance.getColorScheme() || 'light';
    },
    updateSystemColorScheme: (state: ColorModeState, action: Action<SystemColorMode>) => {
      const systemColorMode = action.payload || 'light';
      state.systemColorMode = systemColorMode;
    }
  }
})

export const { 
  setColorPack,
  updateSystemColorScheme
} = colorModeSlice.actions

export default colorModeSlice.reducer;
export const availableColorPacks = {
  light: {
    title: 'Light',
    pack: colorPacks.light
  },
  black: {
    title: 'Black',
    pack: colorPacks.black
  },
  pavement: {
    title: 'Pavement',
    pack: colorPacks.pavement
  },
  contrast: {
    title: 'Contrast',
    pack: colorPacks.contrast
  }
};