import React, { useState } from "react";
import InputText from "../Page/InputText";
import { SeverityIconButton } from "./SeverityIcon";
import Button from "../Page/Button";
import Icon from '../Icon/Icon';
// @ts-ignore
import Markdown from "react-remarkable";
import "./StatusEventForm.css";
import {ServiceEventSeverity, ServiceStatusEvent} from "@packageroute/core-app/dist/models/service_status";

type WithEdit = {
  event: ServiceStatusEvent;
  editing: true;
  onCreate?: never;
};

type WithCreate = {
  event?: never;
  editing?: never | false;
  onEdit?: never;
};

type Props = {
  onSubmit: (event: ServiceStatusEvent) => void;
  onClose: () => void;
} & (WithCreate | WithEdit);

const buttonOptions = [
  ServiceEventSeverity.NO_IMPACT,
  ServiceEventSeverity.MINOR_IMPACT,
  ServiceEventSeverity.SIGNIFICANT_IMPACT,
  ServiceEventSeverity.CRITICAL_IMPACT,
];

export default function StatusEventForm({ event, editing, onSubmit, onClose }: Props) {
  const [markdownPreview, setMarkdownPreview] = useState(false);
  const { valid, editingEvent, setSeverity, setTitle, setDescription, submit } =
    useStatusEventForm(onSubmit, event);

  return (
    <form className="StatusEventForm" onSubmit={(e) => e.preventDefault()}>
      <div className='close-button-wrap'>
        <div className='close-button' onClick={onClose}>
          <Icon name='close' color='#ccc' size={25} />
        </div>
      </div>

      <div className="severity-buttons-wrap">
        {buttonOptions.map((severity) => {
          return (
            <SeverityIconButton
              severity={severity}
              selected={severity === editingEvent.severity}
              onClick={() => setSeverity(severity)}
            />
          );
        })}
      </div>

      <InputText
        label="Title"
        placeholder="Event title..."
        value={editingEvent.title}
        onChange={(e) => setTitle(e.target.value)}
      />

      {markdownPreview ? (
        <div className='markdown markdown-preview-wrap'>
          <Markdown style={{ width: '100%', height: '100%' }} options={{ html: true }}>
            {editingEvent.descriptionMarkdown || '### You can use markdown here...'}
          </Markdown>
        </div>
      ) : (
        <InputText
          label="Description"
          placeholder="### You can use markdown here..."
          value={editingEvent.descriptionMarkdown}
          onChange={(e) => setDescription(e.target.value)}
          style={{ marginTop: 10}}
          multiline={20}
        />
      )}

      <div className='markdown-toggle-button-wrap'>
        <Button 
          color="#0477B4"
          onClick={() => setMarkdownPreview(bool => !bool)}
        >{markdownPreview ? 'Edit' : 'Preview'}</Button>

        {valid &&
          <Button 
            color="#178E32"
            onClick={submit}
          >{editing ? 'Submit' : 'Create'}</Button>
        }
      </div>
    </form>
  );
}

function useStatusEventForm(
  onSubmit: (event: ServiceStatusEvent) => void,
  initialEvent?: ServiceStatusEvent
) {
  const [hasChanged, setHasChanged] = useState(false);
  const [event, setEvent] = useState<ServiceStatusEvent>({
    id: "placeholder",
    severity: ServiceEventSeverity.NO_IMPACT,
    title: "",
    descriptionMarkdown: "",
    startedAt: Date.now(),
    ...initialEvent,
  });

  const validTitle = event.title.length > 5;
  const validDescription = event.descriptionMarkdown.length > 5;

  return {
    editingEvent: event,
    setSeverity: (severity: ServiceEventSeverity) => {
      setHasChanged(true);
      setEvent((e) => ({ ...e, severity }))
    },
    setTitle: (title: string) => {
      setHasChanged(true);
      setEvent((e) => ({ ...e, title }))
    },
    setDescription: (description: string) => {
      setHasChanged(true);
      setEvent((e) => ({ ...e, descriptionMarkdown: description }))
    },
    submit: () => onSubmit(event),
    valid: hasChanged && validTitle && validDescription,
  };
}