import {combineReducers} from 'redux';
import dateRangeReducer, {
  receiveReportDateRange,
  resetDateRange,
  DateRangeType,
} from './dateRange';

const reportsReducer = combineReducers({
  dateRange: dateRangeReducer,
});

export type {ReportDateRange} from './dateRange';
export type ReportsState = ReturnType<typeof reportsReducer>;
export default reportsReducer;

export {
  receiveReportDateRange,
  resetDateRange,
  DateRangeType,
};
