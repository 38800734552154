import React, {ChangeEvent, FormEvent} from 'react';
import {Link} from 'react-router-dom';
import styles from './CustomersAtRiskPage.module.css';
import ConsoleApiInitializer from '@services/ConsoleApiInitializer';
import {ConsoleCustomersAtRiskService, CustomerAtRisk} from "@services/ConsoleCustomersAtRiskService";

interface Filter {
  field: string;
  operator: string;
  value: string;
}

interface State {
  isps: CustomerAtRisk[];
  sortBy: string;
  isLoading: boolean;
  filters: Filter[];
}

class CustomersAtRiskPage extends React.Component<{}, State> {
  private customersAtRiskService: ConsoleCustomersAtRiskService;

  constructor(props: {}) {
    super(props);
    this.state = {
      isps: [],
      sortBy: '',
      isLoading: false,
      filters: [{field: 'days_used', operator: 'lt', value: '10'}],
    };

    this.fetchIsps = this.fetchIsps.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.addFilter = this.addFilter.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleFilterFieldChange = this.handleFilterFieldChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.customersAtRiskService = new ConsoleCustomersAtRiskService(ConsoleApiInitializer);
  }

  componentDidMount(): void {
    this.fetchIsps();
  }
  async fetchIsps(): Promise<void> {
    try {
      this.setState({isLoading: true});
      const response = await this.customersAtRiskService.searchCustomers(
        this.state.filters
      );

      this.setState({isps: response});
    } catch (error) {
      console.error('Error fetching ISPs:', error);
    } finally {
      this.setState({isLoading: false});
    }
  }

  handleSort(sortBy: string): void {
    if (this.state.sortBy === sortBy) {
      this.setState({isps: [...this.state.isps].reverse()});
    } else {
      this.setState({
        sortBy: sortBy,
        isps: [...this.state.isps].sort((a, b) =>
          (a as any)[sortBy] > (b as any)[sortBy] ? 1 : -1
        ),
      });
    }
  }

  addFilter(): void {
    this.setState(prevState => ({
      filters: [...prevState.filters, {field: '', operator: '', value: ''}],
    }));
  }

  removeFilter(index: number): void {
    this.setState(prevState => ({
      filters: prevState.filters.filter((_, i) => i !== index),
    }));
  }


  handleFilterChange(
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    index: number,
    prop: keyof Filter
  ): void {
    const newFilters = [...this.state.filters];
    newFilters[index][prop] = event.target.value;
    this.setState({filters: newFilters});
  }

  // Add this function to your CustomersAtRiskPage class
  handleFilterFieldChange(event: ChangeEvent<HTMLSelectElement>, index: number): void {
    const newFilters = [...this.state.filters];
    newFilters[index].field = event.target.value;
    this.setState({filters: newFilters});
  }

  handleSubmit(event: FormEvent): void {
    event.preventDefault();
    this.fetchIsps();
  }


  render(): JSX.Element {
    const operators = [
      {label: '>=', value: 'gte'},
      {label: '>', value: 'gt'},
      {label: '<', value: 'lt'},
      {label: '<=', value: 'lte'},
      {label: '=', value: 'eq'},
    ];

    return (
      <div className={styles.container}>
        <header className={styles.header}>
          <h1>Customers At Risk</h1>
          <form className={styles.filter} onSubmit={this.handleSubmit}>
            <div className={styles.filtersWrapper}>

              <div className={styles.filtersContainer}>
                {this.state.filters.map((filter, index) => (
                  <div key={index} className={styles.filterRow}>
                    <select
                      value={filter.field}
                      onChange={(event) => this.handleFilterFieldChange(event, index)}
                      className={`${styles.filterField} ${styles.select}`}
                    >
                      <option value="">Field</option>
                      <option value="days_used">Days Used</option>
                      <option value="total_paid_routes">Total Paid Routes</option>
                      <option value="total_routes">Total Routes</option>
                      <option value="total_active_drivers">Total Active Drivers</option>
                    </select>
                    <select
                      className={styles.select}
                      value={filter.operator}
                      onChange={event => this.handleFilterChange(event, index, 'operator')}
                    >
                      <option value="">Operator</option>
                      {operators.map((op, i) => (
                        <option key={i} value={op.value}>{op.label}</option>
                      ))}
                    </select>
                    <input
                      className={styles.input}
                      type="text"
                      placeholder="Value"
                      value={filter.value}
                      onChange={event => this.handleFilterChange(event, index, 'value')}
                    />
                    <button className={styles.button} type="button" onClick={() => this.removeFilter(index)}>Remove</button>
                  </div>
                ))}
              </div>

              <div className={styles.buttonsContainer}>
                <button type="button" className={`${styles.button} ${styles.addFilterButton}`}
                        onClick={this.addFilter}>Add Filter
                </button>
                <button type="submit" className={`${styles.button} ${styles.submitButton}`}>Submit</button>
              </div>
            </div>

          </form>
        </header>
        {this.state.isLoading ? (
          <div className={styles.loading}>Loading...</div>
        ) : (
          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead>
              <tr>
                <th onClick={() => this.handleSort('entity_name')}>ISP</th>
                <th onClick={() => this.handleSort('days_used')}>Days Used</th>
                <th onClick={() => this.handleSort('total_paid_routes')}>Total Paid Routes</th>
                <th onClick={() => this.handleSort('total_live_work_areas')}>Total Live Routes</th>
                <th onClick={() => this.handleSort('total_routes')}>Total Routes</th>
                <th onClick={() => this.handleSort('total_active_drivers')}>Total Active Drivers</th>
                <th onClick={() => this.handleSort('paid_route_percentage')}>Paid Route Percentage</th>
                <th onClick={() => this.handleSort('paid_live_work_areas_percentage')}>Paid Live Work Areas Percentage</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              {this.state.isps.map(isp => (
                <tr key={isp.id}>
                  <td>{isp.entity_name}</td>
                  <td>{isp.days_used}</td>
                  <td>{isp.total_paid_routes}</td>
                  <td>{isp.total_live_work_areas}</td>
                  <td>{isp.total_routes}</td>
                  <td>{isp.total_active_drivers}</td>
                  <td>{isp.paid_route_percentage}%</td>
                  <td>{isp.paid_live_work_areas_percentage}%</td>
                  <td>
                    <Link to={`/isps/${isp.id}`} className={styles.link}>
                      View Details
                    </Link>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

export {CustomersAtRiskPage};
