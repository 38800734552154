import React, { CSSProperties } from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';

import Icon from '../Icon/Icon';

interface BadgeProps {
  icon?: string;
  color?: string;
  onPress?: (event?: React.SyntheticEvent) => void;
  style?: CSSProperties;
  iconSize?: number;
  children?: (string | JSX.Element | null)[] | JSX.Element | JSX.Element[] | number | string | string[] | undefined | null;
  rightMargin?: boolean;
}

export default function Badge (props: BadgeProps) {
  const { icon, color, onPress, style, iconSize, children, rightMargin } = props;
  const BodyComponent = onPress ? TouchableOpacity : View;
  const marginRight = rightMargin ? 5 : 0;

  return (
    <BodyComponent style={[styles.container, { marginRight }, style]} onPress={onPress}>
      { children
        ? children
        : <Icon name={icon} size={iconSize || 17} color={color || '#999'} style={{marginTop: 2}} />
      }
    </BodyComponent>
  )
}

interface BadgeGroupWrapper {
  children?: (string | JSX.Element | null)[] | JSX.Element | JSX.Element[] | number | string | string[] | undefined | null;
  style?: CSSProperties;
}

export function BadgeGroupWrapper ({ children, style }: BadgeGroupWrapper) {
  if (!children) return null;

  return (
    <View style={[styles.groupWrapper, style]}>
      { children }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 20,
    height: 20,
    alignItems: 'center',
    justifyContent: 'center'
  },
  groupWrapper: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  }
});
