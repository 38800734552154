import React from "react";

import InputSwitch from "../../Page/InputSwitch";
import Section from "../../Page/Section";
import SectionTitle from "../../Page/SectionTitle";
import EnabledIspsList from "./EnabledIspsList";

import api from "../../../utils/api";
import { isEmpty, isUndefined } from "../../../utils/typeCheck";

import "./ManualManifest.css";

export default class ManualManifest extends React.Component {
  constructor(props) {
    super(props);

    this.setAllowManualManifest = this.setAllowManualManifest.bind(this);
  }

  setAllowManualManifest(enable) {
    const enabled = enable ? "enable" : "disable";
    api
      .patch(`tasks/manifest/${enabled}`)
      .then(res => this.props.fetchManualManifestList())
      .catch(err => console.log(err));
  }

  render() {
    const { liveISPList, manualManifestList, history } = this.props;

    return (
      <div className="ManualManifest">
        <Section title="Manual Manifest">
          <SectionTitle title="Global Manual Delivery and Pickup Manifests" />

          <Section>
            <InputSwitch
              locked={isUndefined(manualManifestList)}
              enabled={
                manualManifestList.hasOwnProperty("disabled") &&
                !manualManifestList.disabled
              }
              onYes={() => this.setAllowManualManifest(true)}
              onNo={() => this.setAllowManualManifest(false)}
              yesLabel={"Enable"}
              noLabel={"Disable"}
            />
          </Section>

          <Section title="Actived ISPs">
            <EnabledIspsList
              history={history}
              isps={manualManifestList && manualManifestList.isps}
              liveISPList={liveISPList}
            />
          </Section>
        </Section>
      </div>
    );
  }
}
