
const isUndefined = val => typeof val === 'undefined';
const isNull = val => val === null;
const isBoolean = val => typeof val === 'boolean' || val instanceof Boolean;
const isNumber = val => typeof val === 'number' || val instanceof Number;
const isBigInt = val => typeof val === 'bigint';
const isString = val => typeof val === 'string' || val instanceof String;
const isSymbol = val => typeof val === 'symbol'
const isObject = (val) => Object.prototype.toString.call(val) === '[object Object]';
const isArray = val => Array.isArray(val);
const isFunction = val => typeof val === 'function';
const isMap = val => val instanceof Map;
const isSet = val => val instanceof Set;


const isEmpty = val => {
  if (isArray(val)) {
    return val.length === 0 ? true : false;
  } else if (isObject(val)) {
    return Object.keys(val).length === 0 ? true : false;
  } else if (isString(val)) {
    return val.length === 0 ? true : false;
  } else if (isNull(val)) {
    return true;
  } else if (isUndefined(val)) {
    return true;
  } else if (isMap(val)) {
    return val.size === 0 ? true : false;
  } else if (isSet(val)) {
    return val.size === 0 ? true : false;
  }
  
  throw new Error('Unrecognized type');
}

export {
  isEmpty,
  isUndefined,
  isNull,
  isBoolean,
  isNumber,
  isBigInt,
  isString,
  isSymbol,
  isObject,
  isArray,
  isFunction,
  isMap,
  isSet,
}