import React from 'react';

import './ServiceManagement.css';
import HighQualityGeocoding from './HighQualityGeocoding';
import StationChange from './StationChange';
import ManifestReset from './ManifestReset';

export default class ServiceManagement extends React.Component {
  render () {
    return (
      <div>
        <div class='isp-service-mgt-row'>
          <HighQualityGeocoding
            profile={this.props.profile}
            operationId={this.props.profile.ISPID}
          />
        </div>

        <div class='isp-service-mgt-row'>
          <StationChange
            profile={this.props.profile}
            loadLatestProfile={this.props.loadLatestProfile}
          />
        </div>

        <div class='isp-service-mgt-row'>
          <ManifestReset
            profile={this.props.profile}
          />
        </div>
      </div>
    )
  }
}