import React from 'react';

import './Dash.css';
import History from '../History/History';

export default function Dash (props) {
  return (
    <div className='Dash'>
      <div className='section'>
        <History
          consoleUsers={props.consoleUsers}
          history={props.history}
        />
      </div>
    </div>
  );
}
