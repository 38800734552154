import React, { CSSProperties } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';

type Children = (string | JSX.Element | null)[] | JSX.Element | JSX.Element[] | number | string | string[] | undefined | null;

export type TabContent = { [tabId: string]: Children; };
export type Tabs<C extends TabContent> = { [P in keyof C]: Tab };
export interface Tab {
  text: string;
  path?: string;
  useBasePath?: boolean;
  disabled?: boolean;
  tabColor?: string;
}

interface TabbedCardProps<T extends {}, C extends TabContent> {
  tabId: keyof C;
  tabs: Tabs<C>;
  tabContent: C;
  history?: RouteComponentProps<T>;
  basePath?: string;
  onTabUpdate?: (tabId: keyof C) => void;
  tabStyle?: CSSProperties;
  rightSideContent?: any;
  scrollRef?: (ref: React.RefObject<HTMLDivElement>) => void;
  style?: React.CSSProperties;
}

interface TabbedCardState<C extends TabContent> {
  activeTabId: keyof C;
}

class TabbedCard<T extends {}, C extends TabContent> extends React.Component<TabbedCardProps<T, C>, TabbedCardState<C>> {
  constructor (props: TabbedCardProps<T, C>) {
    super(props);

    this.state = {
      activeTabId: props.tabId
    };

    this.onTabPress = this.onTabPress.bind(this);
  }

  componentDidUpdate (prevProps: TabbedCardProps<T, C>) {
    const { tabId } = this.props;
    const { tabId: prevTabId } = prevProps;

    if (tabId !== prevTabId) this.onTabPress(tabId);
  }

  onTabPress (id: keyof C) {
    this.setState({ activeTabId: id }, () => this.props.onTabUpdate?.(id));
  }

  render () {
    const { tabs, tabContent, basePath, history, tabStyle, rightSideContent, scrollRef, style } = this.props;
    const { activeTabId } = this.state;

    return (
      <React.Fragment>
        <TabMenuState<T, C>
          tabs={tabs}
          activeTabId={activeTabId}
          onTabPress={this.onTabPress}
          basePath={basePath}
          history={history}
          rightSideContent={rightSideContent}
          style={style}
        />

        <View scrollRef={scrollRef} style={{ ...tabbedCardStyles.tabContentWrap, ...tabStyle }}>
          { (tabContent || {})[activeTabId] || null }
        </View>
      </React.Fragment>
    );
  }
}

export { TabbedCard };

const tabbedCardStyles = StyleSheet.create({
  tabContentWrap: {
    width: '100%',
    flex: 1,
  }
})

interface MenuProps<T extends {}, C extends TabContent> {
  tabs: Tabs<C>;
  activeTabId: keyof C;
  onTabPress: (id: keyof C) => void;
  basePath?: string;
  history?: RouteComponentProps<T>;
  rightSideContent?: any;
  style?: React.CSSProperties;
}

class TabMenuLink<T extends {}, C extends TabContent> extends React.Component<MenuProps<T, C>> {
  render () {
    const { tabs, basePath, history, onTabPress, activeTabId, rightSideContent, style } = this.props;

    return (
      <TabMenu<T, C>
        type='link'
        tabs={tabs}
        activeTabId={activeTabId}
        onTabPress={onTabPress}
        basePath={basePath}
        history={history}
        rightSideContent={rightSideContent}
        style={style}
      />
    );
  }
}

export { TabMenuLink };

class TabMenuState<T extends {}, C extends TabContent> extends React.Component<MenuProps<T, C>> {
  render () {
    const { tabs, activeTabId, onTabPress, basePath, history, rightSideContent, style } = this.props;

    return (
      <TabMenu<T, C>
        type='state'
        tabs={tabs}
        activeTabId={activeTabId}
        onTabPress={onTabPress}
        basePath={basePath}
        history={history}
        rightSideContent={rightSideContent}
        style={style}
      />
    );
  }
}

export { TabMenuState };

interface TabMenuProps<T extends {}, C extends TabContent> extends MenuProps<T, C> {
  type: string;
  style?: React.CSSProperties;
}

export default class TabMenu<T extends {}, C extends TabContent> extends React.Component<TabMenuProps<T, C>> {
  renderStateTabs () {
    const { tabs, activeTabId, onTabPress } = this.props;
    const results = [];

    for (const key in tabs) {
      const tab = tabs[key];
      const { text, tabColor, disabled } = tab;

      results.push(
        <TabItemState
          key={key}
          id={key}
          activeTabId={activeTabId}
          onTabPress={onTabPress}
          text={text}
          tabColor={tabColor}
          disabled={disabled}
        />
      );
    }

    return results;
  }

  renderLinkTabs () {
    const { tabs, basePath, history } = this.props;
    const results = [];

    for (const key in (tabs || {})) {
      const tab = tabs[key];

      const { text, path, useBasePath } = tab;
      const to = `${basePath}${path}`;

      const { pathname = '' } = history?.location ?? {};

      const isActive = useBasePath
        ? pathname === to
        : pathname.indexOf(to) > -1;

      results.push(
        <TabItemLink
          key={key}
          to={to}
          text={text}
          isActive={isActive}
        />
      );
    }

    return results;
  }

  render () {
    const { type, rightSideContent } = this.props;

    return (
      <View style={tabMenuStyles.container}>
        { type === 'state'
          ? this.renderStateTabs()
          : this.renderLinkTabs()
        }

        { rightSideContent || null }
      </View>
    );
  }
}

const tabMenuStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: 40,
  }
});

interface TabItemStateProps<C extends TabContent> {
  id: string; 
  text?: string;
  activeTabId: keyof C; 
  onTabPress: (id: keyof C) => void;
  disabled?: boolean;
  tabColor?: string;
  scrollRef?: (ref: React.RefObject<HTMLDivElement>) => void;
}

function TabItemState<C extends TabContent> ({ id, text, activeTabId, onTabPress, tabColor, disabled = false, scrollRef }: TabItemStateProps<C>) {
  const isActive = !!activeTabId && id === activeTabId;
  let pressHandler;

  if (!disabled) {
    pressHandler = () => onTabPress(id);
  }
  return (
    <TouchableOpacity onPress={pressHandler} scrollRef={scrollRef}>
      <TabItem
        text={text}
        isActive={isActive}
        tabColor={tabColor}
      />
    </TouchableOpacity>
  );
}

interface TabItemLinkProps {
  text: string;
  to: string;
  isActive?: boolean;
}

function TabItemLink ({ text, to, isActive }: TabItemLinkProps) {
  // <Link to={to} replace component={TouchableOpacity}>
  return (
    <Link to={to} style={{ textDecoration: 'none' }}>
      <TabItem
        text={text}
        isActive={isActive}
      />
    </Link>
  )
}

interface TabItemProps {
  text?: string;
  isActive?: boolean;
  tabColor?: string;
}

function TabItem ({ text, isActive, tabColor }: TabItemProps) {
  return (
    <View style={tabItemStyles.container}>
      <Text 
        style={{
          ...tabItemStyles.text,
          ...(isActive ? tabItemStyles.textActive : {}),
          ...(tabColor ? { color: tabColor } : {}),
        }}
      >{ text || '' }</Text>
    </View>
  )
}

const tabItemStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingBottom: 5,
    paddingTop: 10,
    paddingHorizontal: 8
  },
  text: {
    fontSize: 20,
    color: '#999'
  },
  textActive: {
    color: '#000'
  }
});
