import React from 'react';
import moment from 'moment';
import './Card.css';

export default function Card ({ card }) {
  const expMoment = card
  ? moment(`${card.exp_year}${card.exp_month}`, 'YYYYM')
    .add(1, 'month').subtract(1, 'day')
  : null;
  const isExpired = expMoment && !moment().isBefore(expMoment);

  return (
    <div
      className={`Card ${isExpired ? 'expired' : ''}`}
    >
      <div className='iconWrap'>
        <i className='mdi mdi-credit-card' />
      </div>

      <div className='name'>{ card.name }</div>

      <div>{ card.brand } ***{ card.last4 } </div>

      <div style={{marginLeft: 20}}>
        Expires {expMoment.format('MM / YYYY')}
      </div>
    </div>
  );
}
