const isUndefined = (val: unknown): val is undefined  => typeof val === 'undefined';
const isNull = (val: unknown): val is null => val === null;
const isBoolean = (val: unknown): val is boolean => typeof val === 'boolean' || val instanceof Boolean;
const isNumber = (val: unknown): val is number => (typeof val === 'number' || val instanceof Number) && !isNaN(Number(val));
const isNaNumber = (val: unknown): val is number => (typeof val === 'number' || val instanceof Number) && isNaN(Number(val));
const isBigInt = (val: unknown): val is bigint => typeof val === 'bigint';
const isString = (val: unknown): val is string => typeof val === 'string' || val instanceof String;
const isSymbol = (val: unknown): val is symbol => typeof val === 'symbol'
const isObject = (val: unknown): val is object => Object.prototype.toString.call(val) === '[object Object]';
const isArray = (val: unknown): val is [] => Array.isArray(val);
const isFunction = (val: unknown): val is Function => typeof val === 'function';
const isMap = (val: unknown): boolean => val instanceof Map;
const isSet = (val: unknown): boolean => val instanceof Set;

const isNone = (val: unknown): boolean => isUndefined(val) || isNull(val);
const isVal = (val: unknown): boolean => !isUndefined(val) && !isNull(val);

const isIterable = (val: any): boolean => {
  if (isNone(val)) return false;

  return typeof val[Symbol.iterator] === 'function';
}

const getTypeOf = (val: unknown): string => {
  if (isUndefined(val))  return 'undefined';
  if (isNull(val))       return 'null';
  if (isString(val))     return 'string';
  if (isBoolean(val))    return 'boolean';
  if (isNumber(val))     return 'number';
  if (isNaNumber(val))   return 'NaN';
  if (isArray(val))      return 'array';
  if (isObject(val))     return 'object';
  if (isFunction(val))   return 'function';
  if (isBigInt(val))     return 'bigint';
  if (isSymbol(val))     return 'symbol';
  if (isMap(val))        return 'map';
  if (isSet(val))        return 'set';

  throw new Error('Unrecognized type');
}

const isEnumerable = (val: unknown): boolean => {
  switch (getTypeOf(val)) {
    case 'null': return false;
    case 'undefined': return false;
    case 'boolean': return false;
    case 'number': return false;
    case 'bigint': return false;
    case 'NaN': return false;
    case 'function': return false;
    case 'symbol': return false;
    default: return true;
  }
}

const isEmpty = (val: any): boolean => {
  if (isString(val) || isArray(val)) {
    return val.length === 0;
  } else if (isSet(val) || isMap(val)) {
    return val.size === 0;
  } else if (isObject(val)) {
    return Object.keys(val).length === 0;
  } else if (isNone(val)) {
    return true;
  } else {
    throw new Error('Unrecognized type');
  }
}

const isNotEmpty = (val: any): boolean => !isEmpty(val);

export {
  getTypeOf,
  isIterable,
  isEnumerable,
  isVal,
  isNone,
  isEmpty,
  isNotEmpty,
  isUndefined,
  isNull,
  isBoolean,
  isNumber,
  isNaNumber,
  isBigInt,
  isString,
  isSymbol,
  isObject,
  isArray,
  isFunction,
  isMap,
  isSet,
};