import { Dispatch } from "@reduxjs/toolkit";
import {
  updatePermissions,
  resetPermissions as _resetPermissions,
  Permissions,
} from "@reducers/permissions";
import firebaseProvider from '@utils/firebase';
import bugsnagClient from "@utils/bugsnag";

export async function fetchPermissions (dispatch: Dispatch) {
  try {
    const database = firebaseProvider.getConsoleDefaultDB();

    const ref = database.ref('permissions');

    const snap = await ref.once('value');

    const permissions: Permissions = snap.val() ?? {};
    
    dispatch(updatePermissions({ permissions }));

    return permissions;
  } catch (err) {
    console.error(err);

    bugsnagClient.notify(err as Error);
  }
}

export async function resetPermissions (dispatch: Dispatch) {
  try {
    dispatch(_resetPermissions());
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err as Error);

    throw err;
  }
}
