import React from "react";
// @ts-ignore
import Markdown from "react-remarkable";
import SeverityIcon from './SeverityIcon';
import {IconButton} from '../Icon/Icon';
import moment from 'moment';
import "./StatusEventPreview.css";
import '../Knowledge/Articles/markdown.css';
import {ServiceStatusEvent} from "@packageroute/core-app/dist/models/service_status";

type Props = {
  event: ServiceStatusEvent;
  onEdit: (event: ServiceStatusEvent) => void;
  onDelete: (eventId: string) => void;
  onSelect: (eventId: string) => void;
}

export default function StatusEventPreview ({ event, onEdit, onDelete, onSelect }: Props) {
  const isDefault = event.id === 'default';

  return (
    <div className='StatusEventPreview'>
      <div className='top-row'>
        <p>{event.id === 'default' ? '' : moment(event.startedAt).format('MM/DD/YY hh:mm a')}</p>

        <div className='action-buttons-wrap'>
          <IconButton
            name='pencil'
            color='#0477B4'
            onClick={() => onEdit(event)}
          />
          <IconButton
            name='check-circle'
            color='#178E32'
            onClick={() => onSelect(event.id)}
          />
          <IconButton
            name='delete'
            color='#B90C0E'
            onClick={() => onDelete(event.id)}
          />
        </div>
      </div>
      <div className='title-wrap'>
        <SeverityIcon severity={event.severity} />
        <h2>{event.title || ''}</h2>
      </div>
      <div className='markdown'>
        <Markdown options={{ html: true }}>
          {event.descriptionMarkdown}
        </Markdown>
      </div>
    </div>
  )
}