import React from 'react';

import './ArticleCard.css';

export default function ArticleCard (props) {
  const { article } = props;
  const { title, readingTime } = article;

  return (
    <div
      className={`ArticleCard card ${props.onClick ? 'clickable' : ''}`}
      onClick={props.onClick || null}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p style={{ marginRight: '5px' }}>{title}</p>
        <p style={{ fontSize: '12px', color: '#006DAB' }}>{readingTime}</p>
      </div>

      {props.children}
    </div>
  );
}
