import React from 'react';

import './Recipients.css';
import CollectionsList from '../Collections/CollectionsList';
import AdditionalRecipients from './AdditionalRecipients';

export default class Recipients extends React.Component {
  render () {
    return (
      <div className='Recipients'>
        <div className='column'>
          <h3>Collections</h3>

          <CollectionsList
            selectable
            select={this.props.toggleCollection}
            selectedCollections={this.props.selectedCollections}
            collections={this.props.collections}
            profiles={this.props.profilesw}
            users={this.props.users}
            fetchUsers={this.fetchUsers}
          />
        </div>

        <div className='column'>
          <h3>Add Recipients</h3>

          <AdditionalRecipients
            toggleRecipient={this.props.toggleRecipient}
            addRecipients={this.props.addRecipients}
          />
        </div>
      </div>
    );
  }
}
