import React from 'react';
import './ApiTestTool.css';
import pr from '@packageroute/types-firebase';
import api from '../../utils/api';
import Button from '../Page/Button';
import Modal from '../Page/Modal';
import { SpinnerWithPadding } from '../common/Spinner';
import Icon from '../Icon/Icon';
import {RawManifestData, RawManifestEntry, RawStatusEntry} from '../../ConsoleApi.Types';

type WorkArea = pr.isp.profile.work_areas.WorkArea;
type WorkAreas = pr.isp.profile.work_areas.WorkAreas;

type RawStatusData = RawStatusEntry[];

type Props = {
  contactId: string;
  stationIds?: Record<string, any>; 
  workAreas?: WorkAreas;
  contactName: string;
  hideButtons?: boolean;
}

type State = {
  contactId: string;
  testError?: null | boolean;
  testErrorMessage?: string | null;
  testSucceeded?: null | boolean;
  loading?: boolean;
  rawManifestData?: RawManifestData | null;
  rawStatusData?: RawStatusData | null;
  serviceAreaId?: string | null;
  clearServiceAreaModalOpen?: boolean;
}

export default class ApiTestTool extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props);

    this.state = {
      contactId: props.contactId
    };

    this.testApi = this.testApi.bind(this);
    this.closeTest = this.closeTest.bind(this);
    this.clearServiceArea = this.clearServiceArea.bind(this);
    this.closeClearServiceAreaModal = this.closeClearServiceAreaModal.bind(this);
    this.confirmClearServiceArea = this.confirmClearServiceArea.bind(this);
  };

  componentDidUpdate (prevProps: Props) {
    if (this.props.contactId && !prevProps.contactId && !this.state.contactId) {
      this.setState({ contactId: this.props.contactId });
    }
  }

  testApi (contactIn?: string, callback?: (success: boolean) => void) {
    this.setState({
      testError: null,
      testSucceeded: null,
      loading: true
    }, () => {
      const { contactId } = this.state;

      const id = contactId || contactIn || null;

      if (!id) {
        this.setState({
          testError: true,
          testErrorMessage: 'No contact id',
          loading: false,
        })
      } else {
        api.getApiDataForId(id)
        .then(res => {
          const { data } = res;
          const authorized = data.hasOwnProperty('status') || data.hasOwnProperty('manifest');

          if (callback && (data.manifest || data.status)) {
            callback(true);
          }

          this.setState({
            testSucceeded: authorized,
            loading: false,
            rawManifestData: data.manifest,
            rawStatusData: data.status
          });
        })
        .catch(err => {
          const response = err.response || {};
          const data = response.data || {};

          console.error(err);
          
          this.setState({
            testError: true,
            testErrorMessage: data.errorMessage || 'An error occured.',
            loading: false
          });
        });
      }
    });
  }

  closeTest (callback?: () => void) {
    this.setState({
      testSucceeded: null,
      loading: false,
      rawManifestData: null,
      rawStatusData: null
    }, () => callback?.());
  }

  clearServiceArea (serviceAreaId: string) {
    this.setState({ clearServiceAreaModalOpen: true, serviceAreaId, testError: null, testErrorMessage: null });
  }

  closeClearServiceAreaModal () {
    this.setState({ clearServiceAreaModalOpen: false, serviceAreaId: null });
  }

  confirmClearServiceArea () {
    if (this.state.serviceAreaId) {
      api.clearServiceArea(this.state.serviceAreaId)
        .then(() => {
          const { rawStatusData } = this.state;
  
          const newRawStatus = (rawStatusData || []).map((entry) => {
            if (entry.serviceAreaData) {
              delete entry.serviceAreaData;
            }
  
            return entry;
          });
  
          this.setState({ clearServiceAreaModalOpen: false, serviceAreaId: null, rawStatusData: newRawStatus });
        })
        .catch(error => {
          this.setState({ clearServiceAreaModalOpen: false, serviceAreaId: null, testError: true, testErrorMessage: error.message })
        })
    }
  }

  render () {
    const { stationIds, workAreas, contactName, hideButtons } = this.props;
    const { contactId, testError, testSucceeded, loading, rawManifestData, rawStatusData, clearServiceAreaModalOpen } = this.state;

    return loading
      ? <SpinnerWithPadding />
      : (
      <div className='ApiTestTool'>
        { !hideButtons && !!clearServiceAreaModalOpen &&
          <ClearServiceAreaModal
            closeClearServiceAreaModal={this.closeClearServiceAreaModal}
            confirmClearServiceArea={this.confirmClearServiceArea}
          />
        }

        {
          !hideButtons &&
          <div className='buttonWrap'>
            <div>
              <div className='contactId'><span>ID: </span>{contactId}</div>

              { !!contactName &&
                <div className='contactId'><span>{contactName}</span></div>
              }
            </div>
            <Button onClick={() => this.testApi()} color='#006DAB'>Test</Button>

            { !!(rawManifestData || rawStatusData) &&
              <Button onClick={() => this.closeTest()} color='#B90C0F'>Clear</Button>
            }
          </div>
        }



        { testError
          ? <div className='testStatus error'>{this.state.testErrorMessage}</div>
          : testSucceeded
            ? <div className='testStatus success'>Authorized.</div>
            : testSucceeded === false
              ? <div className='testStatus error'>NOT AUTHORIZED</div>
              : null
        }

        {
          !!(rawManifestData || rawStatusData) &&
            <ApiTestTable
              stationIds={stationIds}
              workAreas={workAreas}
              rawManifestData={rawManifestData}
              rawStatusData={rawStatusData}
              clearServiceArea={this.clearServiceArea}
            />
        }
      </div>
    )
  }
}

type ApiTestManifestRowProps = {
  workArea: WorkArea;
  id: string;
  manifest: RawManifestEntry;
  stationIds?: Record<string, any>;
}

function ApiTestManifestRow (props: ApiTestManifestRowProps) {
  const { id, manifest, stationIds, workArea } = props;

  const hasStationMatch = (() => {
    if (!stationIds) return null;

    for (const stationId of manifest.stationIds) {
      if (stationIds[stationId]) {
        return true;
      }
    }

    return false;
  })();

  let rowClassName = 'ApiTestManifestRow';

  if (hasStationMatch) {
    rowClassName += ` active`
  } else if (hasStationMatch === false) {
    rowClassName += ` inactive`
  }

  return (
    <tr className={rowClassName}>
      <td>{id} {!!(workArea || {}).paid && <Icon name='credit-card' color='#168e32' size={13} />}</td>
      <td className={hasStationMatch ? 'active' : undefined}>{manifest.stationIds.join(', ')}</td>
      <td>{manifest.total}</td>
      <td>{manifest.deliveries}</td>
      <td>{manifest.pickups}</td>
    </tr>
  )
}

type ApiTestStatusRowProps = {
  entry: RawStatusEntry;
  stationIds?: Record<string, any>;
  clearServiceArea: (serviceAreaId: string) => void;
}

function ApiTestStatusRow ({ entry, stationIds, clearServiceArea }: ApiTestStatusRowProps) {
  const hasStationMatch = stationIds && stationIds[entry.stationId];

  let rowClassName = 'ApiTestStatusRow';

  if (hasStationMatch) {
    rowClassName += ` active`
  } else if (hasStationMatch === false) {
    rowClassName += ` inactive`
  }

  return (
    <tr className={rowClassName}>
      <td>{entry.workAreaId}</td>
      <td className={hasStationMatch ? 'active' : undefined}>{entry.stationId}</td>
      <td>{entry.contractName}</td>
      <td>{entry.serviceAreaId}{
        entry.serviceAreaData
          ? <span onClick={() => clearServiceArea(entry.serviceAreaId)}>
              <Icon name='close' color='#FC0000' size={18} />
            </span>
          : <Icon name='check' color='#999' size={12} />
      }</td>
      <td>{entry.driverName}</td>
      <td>{entry.fedexId || ''}</td>
      <td>{entry.completedDeliveries}</td>
      <td>{entry.completedPickups}</td>
    </tr>
  )
}

type ApiTestTableProps = {
  rawManifestData?: RawManifestData | null;
  rawStatusData?: RawStatusData | null;
  stationIds?: Record<string, any>;
  workAreas?: WorkAreas;
  clearServiceArea: (serviceAreaId: string) => void;
}

function ApiTestTable (props: ApiTestTableProps) {
  const { rawManifestData, rawStatusData, stationIds, workAreas, clearServiceArea } = props;

  return (
    <div className='ApiTestTable'>
      <h5>Manifest Data</h5>

      <table>
        <thead>
          <th>Work Area</th>
          <th>Stations</th>
          <th>Stops</th>
          <th>Deliveries</th>
          <th>Pickups</th>
        </thead>

        <tbody>
          {
            rawManifestData && 
            Object
            .keys(rawManifestData)
            .sort((a, b) => a < b ? -1 : 1)
            .map(key => {
              const manifest = rawManifestData[key];

              return (
                <ApiTestManifestRow
                  key={key}
                  id={key}
                  manifest={manifest}
                  stationIds={stationIds}
                  workArea={(workAreas || {})[key]}
                />
              )
            })
          }
        </tbody>
      </table>

      { !!workAreas &&
        <MissingWorkAreas
          rawManifestData={rawManifestData}
          workAreas={workAreas}
        />
      }

      <h5>Status Data</h5>

      <table>
        <thead>
          <th>Work Area</th>
          <th>Station</th>
          <th>Contract Name</th>
          <th>Service Area</th>
          <th>Driver Name</th>
          <th>Fedex ID</th>
          <th>Deliveries</th>
          <th>Pickups</th>
        </thead>

        <tbody>
          {
            rawStatusData && rawStatusData
              .sort((a, b) => {
                if (!a.stationId) return 1;
                if (!b.stationId) return -1;
                
                if (a.stationId === b.stationId) {
                  return a.workAreaId < b.workAreaId ? -1 : 1;
                }

                return a.stationId < b.stationId ? -1 : 1;
              })
              .map(entry => {
                return (
                  <ApiTestStatusRow
                    entry={entry}
                    stationIds={stationIds}
                    clearServiceArea={clearServiceArea}
                  />
                )
              })
          }
        </tbody>
      </table>
    </div>
  )
}

type MissingWorkAreasProps = {
  workAreas?: WorkAreas;
  rawManifestData?: RawManifestData | null;
}
function MissingWorkAreas ({ workAreas = {}, rawManifestData }: MissingWorkAreasProps) {
  const missingWorkAreas = [];
  let hasMissingPaidArea = false;

  for (const key in workAreas) {
    if (!(rawManifestData || {})[key]) {
      const { paid } = workAreas[key];

      if (paid && !hasMissingPaidArea) {
        hasMissingPaidArea = true;
      }

      missingWorkAreas.push({ id: key, paid })
    }
  }

  if (!missingWorkAreas.length) return null;

  return (
    <React.Fragment>
      <h5 className={hasMissingPaidArea ? 'issue' : undefined}>Missing Work Areas</h5>

      <div className='missingAreaWrapper'>
        { missingWorkAreas.map(({id, paid}) =>
          <div className={`missingArea${paid ? ' paid' : ''}`}>{id}</div>
        )}
      </div>
    </React.Fragment>
  )
}

type ClearServiceAreaModalProps = {
  closeClearServiceAreaModal: () => void;
  confirmClearServiceArea: () => void;
}

function ClearServiceAreaModal (props: ClearServiceAreaModalProps) {
  const { closeClearServiceAreaModal, confirmClearServiceArea } = props;
  return (
    <Modal onBackClick={closeClearServiceAreaModal}>
      <div className='confirm-delete'>
        <p>Are you sure you want to clear this service area?</p>

        <div className='confirm-buttons'>
          <Button color='rgb(22, 142, 50)' onClick={closeClearServiceAreaModal}>
            <p>NO</p>
          </Button>

          <Button
            color='red'
            onClick={confirmClearServiceArea}>
            <p>YES</p>
          </Button>
        </div>
      </div>
    </Modal>
  );
}