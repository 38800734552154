import {combineReducers} from 'redux';

import dailyActiveUsers from './dailyActiveUsers';
import dailyOperationsActivity from './dailyOperationsActivity';
import dailyOperationsUsage from './dailyOperationsUsage';
import totalOperationsAndStations from './totalOperationsAndStations';

const metricsReducer = combineReducers({
  dailyActiveUsers,
  dailyOperationsActivity,
  dailyOperationsUsage,
  totalOperationsAndStations,
});

export default metricsReducer;
