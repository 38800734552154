import React from 'react';

import './Category.css';
import ArticleCard from '../Cards/ArticleCard';
import CategoryCard from '../Cards/CategoryCard';
import Icon from '../../Icon/Icon';
import Spinner from '../../common/Spinner';

// FEATURES
// remove article from category
// view all articles in category
// delete category (if empty)

export default function Category (props) {
  const {
    articles,
    category,
    open
  } = props;

  return (
    <div className={`Category card ${props.operatingOn === true ? 'highlight' : ''}`}>
      <div className='info'>
        <CategoryCard category={category} articles={articles} />

        {
          articles.length
            ? <div className='btn' onClick={() => props.openCategory(category)}>
              <Icon name={open ? 'chevron-down' : 'chevron-right'} color='black' />
            </div>
            : <div
              className={!props.operatingOn ? 'btn' : ''}
              onClick={!props.operatingOn ? props.deleteCategory : null}>
              {
                props.operatingOn
                  ? <Spinner color='black' />
                  : <Icon name='close' />
              }
            </div>
        }
      </div>

      {
        open
          ? <div className='articles'>
            {
              articles.map(article => {
                return (
                  <ArticleCard
                    article={article}
                    onClick={() => props.history.push(`/knowledge/articles/${article.id}`)}
                  />
                )
              })
            }
          </div>
          : null
      }
    </div>
  );
}
