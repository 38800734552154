import React from 'react';
import './SalesLists.css';
import {RouteComponentProps} from 'react-router';
import {connect, ConnectedProps} from 'react-redux';
import ISPModal from '../ISPModal/ISPModal';
import pr from '@packageroute/types-firebase';
import CreateList from './CreateList';
import List from './List/List';
import TagPopout from '../TagPopout/TagPopout';
import Modal from '../Page/Modal';
import Button from '../Page/Button';
import withPermissions from '../Permissions/withPermissions';
import {SalesListItem} from '@reducers/salesLists';
import {RootState} from '@reducers';
import * as selectors from '@selectors';
import * as actions from '@actions';

const { localStorage } = window;

const SALES_LIST_ORDER_KEY = "salesListsOrder";

enum Spectrum {
  LEFT = 'left',
  RIGHT = 'right',
}

type Profile = pr.isp.profile.Profile;
type DateTimeStamp = string;

type Contact = {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  preferredMethod: string | null;
  preferredTime: string | null;
  role: null;
}

type TaggedISP = {
  id: number;
  entityId: string | string;
  additionalRoutes: null;
  groundRoutes: null;
  hdRoutes: null;
  suppRoutes: null;
  city: string | null;
  entityName: string;
  firebaseId: string | null;
  notInterested: null;
  numEntities: null;
  primary: Contact[] | null;
  profile: Profile | null;
  
  projectedClose: null;
  projectedConversionDate: null;
  qualified: DateTimeStamp | null;
  qualifiedDescription: null;
  referred: DateTimeStamp | null;
  signup: DateTimeStamp | null;
  state: string | null;
  timeZone: string | null;
  churned: DateTimeStamp| null;
  lead: DateTimeStamp | null;
  trial: DateTimeStamp | null;
  initialContact: DateTimeStamp | null;
  createdAt: DateTimeStamp;
  updatedAt: DateTimeStamp;
}

type Tag = {
  id: number;
  dueDate: null;
  name: string;
  reason: null;
  createdAt: string;
  updatedAt: string;
  isp: TaggedISP[];
  /**
   * Note: not actually seeing this in the data, but it's required in the code
   */
  station: any[];
}

enum Type {
  ISP = 'isp',
  TAG = 'tag',
}

type Props = {
  history: RouteComponentProps['history'];
} & PropsFromRedux;

type State = {
  activeTag: null | Tag;
  activeList: null | SalesListItem;
  activeType: null | Type;
  attemptingDelete: boolean;
  listToDelete: null | string;
  draggingListId: null | number;
  draggingOverId: null | number;
  listsOrder: null | number[];
  spectrum?: Spectrum;
  viewOptions?: boolean;
}

class SalesLists extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props);

    this.state = {
      activeTag: null,
      activeList: null,
      activeType: null,
      attemptingDelete: false,
      listToDelete: null,
      draggingListId: null,
      draggingOverId: null,
      listsOrder: null
    }

    this.setActiveItem = this.setActiveItem.bind(this);
    this.deleteList = this.deleteList.bind(this);
    // this.startDrag = this.startDrag.bind(this);
    // this.endDrag = this.endDrag.bind(this);
    // this.dragOver = this.dragOver.bind(this);
    this.dropList = this.dropList.bind(this);
    this.updateListOrder = this.updateListOrder.bind(this);
    this.fetchSalesLists = this.fetchSalesLists.bind(this);
  }

  componentDidUpdate () {
    const {listsOrder} = this.state;

    const storedOrder = localStorage.getItem(SALES_LIST_ORDER_KEY);

    const firstOrder: number[] = JSON.parse(storedOrder ?? '[]');

    if (!firstOrder && !listsOrder && this.props.salesLists) {
      const nextOrder: number[] = [];

      for (const list of this.props.salesLists) {
        nextOrder.push(list.id);
      }

      this.setState({ listsOrder: nextOrder }, () => {
        localStorage.setItem(SALES_LIST_ORDER_KEY, JSON.stringify(nextOrder));
      })
    }
    else if (firstOrder && !listsOrder) {
      this.setState({ listsOrder: firstOrder });
    }
  }

  fetchSalesLists () {
    const {dispatch} = this.props;

    actions.salesLists.fetchSalesLists(dispatch);
  }

  updateListOrder (addListId?: number) {
    const { listsOrder, draggingListId, draggingOverId, spectrum } = this.state;
    const nextListsOrder: number[] = [];
    // const overIndex = listsOrder.indexOf(draggingOverId);

    if (!draggingListId || !draggingOverId) return;

    for (const listId of listsOrder ?? []) {
      if (listId !== draggingListId && listId !== draggingOverId) {
        nextListsOrder.push(listId);

        continue;
      }

      if (listId === draggingOverId && spectrum === Spectrum.RIGHT) {
        nextListsOrder.push(draggingOverId);
        nextListsOrder.push(draggingListId);
      }
      else if (listId === draggingOverId && spectrum === Spectrum.LEFT) {
        nextListsOrder.push(draggingListId);
        nextListsOrder.push(draggingOverId);
      }
    }

    if (addListId) {
      nextListsOrder.push(addListId);
    }

    this.setState({ listsOrder: nextListsOrder, draggingListId: null, draggingOverId: null }, () => {
      localStorage.setItem(SALES_LIST_ORDER_KEY, JSON.stringify(nextListsOrder));
    });
  }

  setActiveItem (list: SalesListItem, tag: Tag, type: Type) {
    this.setState({ activeList: list, activeTag: tag, activeType: type });
  }

  async deleteList (id: string) {
    const { dispatch } = this.props;
    const { attemptingDelete } = this.state;

    if (attemptingDelete === false) {
      return this.setState({ attemptingDelete: true, listToDelete: id });
    }

    await actions.salesLists.deleteSalesListItem(dispatch, id);

    this.setState({ viewOptions: false, listToDelete: null, attemptingDelete: false });
  }

  // Never implemented
  // startDrag (e, id) {
  //   this.setState({ draggingListId: id });
  // }

  // endDrag (e) {
  //   this.setState({ draggingListId: null, draggingOverId: null });
  // }

  // dragOver (e, id) {
  //   const { listsOrder, draggingListId, draggingOverId } = this.state;

  //   if (draggingOverId !== id) {
  //     this.setState({ draggingOverId: id });
  //   }

  //   console.log(e.nativeEvent);

  //   if (draggingOverId !== draggingListId) {
  //     const mouseOffset = e.nativeEvent.offsetX;
  //     const elWidth = e.nativeEvent.target.clientWidth;
  //     let fraction = mouseOffset / elWidth;
  //     let spectrum = fraction > 0.5 ? 'right' : 'left';

  //     const indexA = listsOrder.indexOf(draggingListId);
  //     const indexB = listsOrder.indexOf(draggingOverId);

  //     if (indexB - indexA === 1 && fraction < 0.5) {
  //       spectrum = null;
  //     }
  //     else if (indexB - indexA === 1 && fraction > 0.5) {
  //       spectrum = 'right';
  //     }
  //     else if (indexA - indexB === 1 && fraction < 0.5) {
  //       spectrum = 'left';
  //     }
  //     else if (indexA - indexB === 1 && fraction > 0.5) {
  //       spectrum = null;
  //     }

  //     this.setState({ spectrum });
  //   }

  // }

  /**
   * May or may not be in use...?
   * @param e 
   */
  dropList () {
    this.updateListOrder();
  }

  render () {
    const { salesLists } = this.props;
    const { draggingOverId, listsOrder, draggingListId, spectrum, attemptingDelete, listToDelete } = this.state;

    return (
      <div className='SalesLists'>
        <div className='header'>
          <h1>Sales Dev</h1>

          <CreateList
            fetchSalesLists={this.fetchSalesLists}
            updateListOrder={this.updateListOrder}
          />
        </div>


        <div className='board-wrapper'>
          <div className='board'>
            {
              salesLists
                .slice()
                .sort((a, b) => {
                  if (!listsOrder) return 1;

                  const indexA = listsOrder.indexOf(a.id);
                  const indexB = listsOrder.indexOf(b.id);

                  return indexA - indexB;
                })
                .map((list, i) => {
                  return (
                    <React.Fragment>
                      {
                        draggingOverId && draggingOverId === list.id && draggingListId !== list.id && spectrum === 'left'
                          ? <div
                            className={`List dragover`}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={e => this.dropList()}
                          />
                          : null
                      }

                      <List
                        draggingListId={this.state.draggingListId}
                        // startDrag={this.startDrag}
                        // endDrag={this.endDrag}
                        // dragOver={this.dragOver}
                        usage={this.props.usage}
                        user={this.props.user}
                        liveISPList={this.props.liveISPList}
                        activeList={this.state.activeList}
                        deleteList={this.deleteList}
                        list={list}
                        fetchSalesLists={this.fetchSalesLists}
                        setActiveItem={this.setActiveItem}
                        index={i}
                        key={`list + ${list.id} + ${i}`}
                      />

                      {
                        draggingOverId && draggingOverId === list.id && draggingListId !== list.id && spectrum === 'right'
                          ? <div
                            className={`List dragover`}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={e => this.dropList()}
                          />
                          : null
                      }
                    </React.Fragment>
                  );
                })
            }
          </div>
        </div>

        {
          (attemptingDelete && listToDelete)
            ? <Modal>
              <div className='confirm-delete'>
                <p>Are you sure you want to delete this list?</p>

                <div className='confirm-buttons'>
                  <Button color='rgb(22, 142, 50)' onClick={() => this.setState({ attemptingDelete: false })}>
                    <p>ABORT</p>
                  </Button>

                  <Button
                    color='red'
                    onClick={() => this.deleteList(listToDelete)}>
                    <p>Yes, Please Delete</p>
                  </Button>
                </div>
              </div>
            </Modal>
            : null
        }

        {
          this.state.activeList && this.state.activeTag && this.state.activeType === Type.ISP && !this.state.attemptingDelete
            ? <ISPModal
              history={this.props.history}
              tag={this.state.activeTag}
              list={this.state.activeList}
              profile={(this.props.liveISPList || {})[this.state.activeTag?.isp?.[0]?.firebaseId ?? '']}
              // @ts-ignore
              setActiveItem={this.setActiveItem}
              usage={this.props.usage}
              collections={this.props.collections}

            />
            : null
        }

        {
          this.state.activeList && this.state.activeTag && this.state.activeType === Type.TAG && !this.state.attemptingDelete
            ? <TagPopout
              tag={this.state.activeTag}
              item={this.state.activeTag.hasOwnProperty('isp')
                ? this.state.activeTag.isp[0]
                : this.state.activeTag.station[0]
              }
              type={this.state.activeType.hasOwnProperty('isp')
                ? 'isps'
                : 'stations'
              }
              activeType={this.state.activeType}
              setActiveTag={this.setActiveItem}
              updateProps={() => {}}
            />
            : null
        }
      </div>
    );
  }
}

function mapStateToProps (state: RootState) {
  return {
    collections: selectors.salesCampaignCollections.selectSalesCampaignCollections(state),
    consoleUser: selectors.consoleUsers.selectConsoleUser(state),
    liveISPList: selectors.liveIspList.selectLiveIspList(state),
    salesLists: selectors.salesLists.selectSalesLists(state),
    usage: selectors.metrics.dailyOperationsUsage.selectDailyOperationsUsage(state),
    user: selectors.firebaseUser.selectFirebaseUser(state),
  };
}

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(withPermissions(SalesLists));