import { useAppSelector } from "../store";
import {selectDashboardDailyMetrics} from '@selectors/metrics';
import {
  selectTodaysDailyActiveUsersMetric
} from '@selectors/metrics/dailyActiveUsers';
import {
  selectDailyOperationsUsage,
  selectDailyOperationsUsageUpdatedAt,
} from "@selectors/metrics/dailyOperationsUsage";
import {
  selectDailyOperationsActivity,
  selectDailyOperationsActivityUpdatedAt,
} from "@selectors/metrics/dailyOperationsActivity";

export function useDailyOperationsUsage() {
  return useAppSelector(selectDailyOperationsUsage);
}

export function useDailyOperationsUsageUpdatedAt() {
  return useAppSelector(selectDailyOperationsUsageUpdatedAt);
}

export function useTodayDailyActiveUsersMetric() {
  return useAppSelector(selectTodaysDailyActiveUsersMetric);
}

export function useDailyOperationsActivity() {
  return useAppSelector(selectDailyOperationsActivity);
}

export function useDailyOperationsActivityUpdatedAt() {
  return useAppSelector(selectDailyOperationsActivityUpdatedAt);
}

export function useDashboardMetricData () {
  return useAppSelector(selectDashboardDailyMetrics)
}