import React from 'react';
import StationAdvocateListItem from '../Station/StationAdvocateListItem';
import {useStationsWithAdvocates, useUsers, useReferrals} from '@hooks/redux';
import './StationAdvocatesList.css';

type Props = {
  goToStationPage: (stationId: string) => void;
}

export default function StationAdvocatesList ({ goToStationPage }: Props) {
  const stationsWithAdvocates = useStationsWithAdvocates();
  const users = useUsers();
  const referrals = useReferrals();

  return (
    <div className='StationAdvocatesList'>
      {
        Object.keys(stationsWithAdvocates || {})
          .filter(stationId => {
            const station = stationsWithAdvocates[stationId];
            const hasAdvocates = Object.keys(station.advocates || {})
              .reduce((acc, userId) => {
                const advocate = station.advocates?.[userId];

                if (advocate && advocate.isAdvocate === true) {
                  acc = true;
                }

                return acc;
              }, false);

            return hasAdvocates;
          })
          .map(stationId => {
            const station = stationsWithAdvocates[stationId];

            return (
              <StationAdvocateListItem
                goToStationPage={goToStationPage}
                station={station}
                users={users}
                referrals={referrals}
              />
            );
          })
      }
    </div>
  );
}
