import { Dispatch } from "@reduxjs/toolkit";
import * as dailyActiveUsers from './dailyActiveUsers';
import * as dailyOperationsActivity from './dailyOperationsActivity';
import * as dailyOperationsUsage from './dailyOperationsUsage';
import * as totalOperationsAndStations from './totalOperationsAndStations';
export {
  dailyActiveUsers,
  dailyOperationsActivity,
  dailyOperationsUsage,
  totalOperationsAndStations,
}

export function unsubscribeAndResetMetrics (dispatch: Dispatch) {
  dailyActiveUsers.clearDailyActiveUsers(dispatch);
  dailyOperationsActivity.clearDailyActivity(dispatch);
  dailyOperationsUsage.clearDailyUsage(dispatch);
  totalOperationsAndStations.clearTotalOperationsAndStations(dispatch);
}