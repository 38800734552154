import React from 'react';

import './TransferBillingModal.css';

import InputSelect from '../Page/InputSelect';
import Modal from '../Page/Modal';
import api from '../../utils/api';
import Spinner from '../common/Spinner';
import Icon from '../Icon/Icon';
import StripeLink from '../common/StripeLink';

export default class TransferBillingModal extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      loading: true,
      existingDataIssues: []
    };

    this.fetchBillingDataForCSP = this.fetchBillingDataForCSP.bind(this);
    this.onNewOwnerChange = this.onNewOwnerChange.bind(this);
    this.submitOwnerSwap = this.submitOwnerSwap.bind(this);
  }

  componentDidMount () {
    const { profile } = this.props;

    if (profile && profile.ISPID) {
      this.fetchBillingDataForCSP(profile.ISPID);
    }
  }

  componentDidUpdate (oldProps) {
    if (!(oldProps.profile || !oldProps.profile.ISPID) && this.props.profile && this.props.profile.ISPID) {
      this.fetchBillingDataForCSP(this.props.profile.ISPID);
    }
  }

  fetchBillingDataForCSP (ISPID) {
    api.getISPCustomerData(ISPID)
      .then(existingData => {
        const existingDataIssues = validateDataCoherence(existingData);
        this.setState({ existingData, loading: false, existingDataIssues });
      })
      .catch(err => {
        this.setState({ errorMessage: err.message || 'An error with no message ocurred', loading: false });
      })
  };

  submitOwnerSwap () {
    const { profile } = this.props;
    const { newOwnerData } = this.state;
    const { uid, FedExId, first, last } = newOwnerData;
    const { ISPID } = profile;

    if (!uid) {
      return this.setState({ errorMessage: 'No UID found for new owner!' });
    } else if (!ISPID) {
      return this.setState({ errorMessage: 'No ISPID found for new owner!' });
    } else if (!FedExId) {
      return this.setState({ errorMessage: 'No FedExId found for new owner!' });
    } else if (!first) {
      return this.setState({ errorMessage: 'No first name found for new owner!' });
    } else if (!last) {
      return this.setState({ errorMessage: 'No last name found for new owner!' });
    }

    this.setState({ loading: true }, () => {
      api.submitOwnerSwap({ newOwnerUID: uid, ISPID })
        .then((res) => {
          this.props.stopTransferBilling();
          this.props.loadLatestProfile();
        })
        .catch(err => {
          this.setState({ errorMessage: err.message || 'An error with no message ocurred', loading: false });
        })
    });
  }

  onNewOwnerChange (e) {
    const { profile } = this.props;
    const { newDataIssues } = this.state;
    const val = e.target.value;

    const driverEntry = profile.drivers[val];
    const { uid } = driverEntry || {};

    this.setState({ loading: true, selectedNewOwner: val, newDataIssues: null }, () => {
      api.getCustomerDataForUser(uid)
        .then(data => {
          const dataIssues = data.customerId
            ? ['User already has a customer account. (contact devs)', ...(newDataIssues || [])]
            : newDataIssues;

          this.setState({ newOwnerData: data, loading: false, newDataIssues: dataIssues });
        })
        .catch(err => {
          const { message } = err;

          this.setState({ loading: false, newDataIssues: [message || 'An error fetching new customer data occurred', ...newDataIssues] })
        })
    });
  }

  render () {
    const { profile,  stopTransferBilling } = this.props;
    const { loading, existingData, existingDataIssues, newOwnerData, selectedNewOwner, newDataIssues } = this.state;

    return (
      <Modal onBackClick={stopTransferBilling}>
        <div
          class='TransferBillingModal'
        >
          <div>
            <p style={{ fontWeight: '500', fontSize: '20px', marginBottom: '10px', textAlign: 'center' }}>CSP Billing Transfer</p>
            <p style={{ textAlign: 'center' }}>This tool is used to transfer ownership of a customer account, its subscription, and 'King' of the CSP in Firebase.</p>
          </div>

          { loading
            ? <div className='spinnerWrap'>
                <Spinner color='#006DAB' />
              </div>
            : <div className='body'>
              { this.state.errorMessage &&
                <p className='errorMessage'>{this.state.errorMessage || null}</p>
              }

              <div className='mainContainer'>
                <BillingDataView
                  data={existingData}
                  existing
                />

                <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                  <Icon name='arrow-right-bold' size={50} color={!existingDataIssues.length && newOwnerData && !newDataIssues ? '#168E32' : '#FC0000'} />
                </div>

                <div style={{flex: 1, display: 'flex', flexDirection: 'column', height: '100%'}}>
                  {!existingDataIssues || !existingDataIssues.length &&
                    <NewOwnerPicker
                      profile={profile}
                      onChange={this.onNewOwnerChange}
                      selectedNewOwner={selectedNewOwner}
                    />
                  }

                  <div style={{flex: 1}}>
                    { newOwnerData
                      ? <BillingDataView
                        data={existingData}
                        newOwnerData={newOwnerData}
                        issues={newDataIssues}
                      />
                      : existingDataIssues.length
                        ? existingDataIssues.map(issue =>
                          <p className='errorMessage'>{issue}</p>
                        )
                        : <p>Select a target owner to preview changes</p>
                    }
                  </div>
                </div>
              </div>

              { newOwnerData && (!newDataIssues || !newDataIssues.length) &&
                <div onClick={this.submitOwnerSwap} className='submitButton'>Submit</div>
              }
            </div>
          }
        </div>
      </Modal>
    )
  }
}

function NewOwnerPicker ({ profile, onChange, selectedNewOwner }) {
  return (
    <div className='NewOwnerPicker'>
      <InputSelect
        value={selectedNewOwner || (profile || {}).contactId}
        selectStyle={{ flex: 1, height: 40 }}
        labelStyle={{margin: 0}}
        options={Object.keys((profile || {}).drivers || {}).map(fedExId => {
          const driver = profile.drivers[fedExId];
          const { first, last, type, active, uid } = driver;

          if (active === false || type === 'driver' || !uid) return null;

          return {
            name: `${fedExId} - ${first} ${last}`,
            value: fedExId
          };
        }).filter(e => e)}
        onChange={onChange}
      />
    </div>
  );
}

function BillingDataView ({ data, existing, issues, newOwnerData }) {
  return (
    <div className='BillingDataView'>
      { existing &&
        <h4 className='existingEntry'>
          Existing
        </h4>
      }
      <CSPDataView
        data={data}
        newOwnerData={newOwnerData}
      />

      <OwnerDataView
        data={data}
        newOwnerData={newOwnerData}
      />

      <CustomerDataView
        data={data}
        newOwnerData={newOwnerData}
      />

      { (issues || []).map(issue =>
        <p className='errorMessage'>{issue}</p>
      )}
    </div>
  );
}

function CSPDataView ({ data, newOwnerData }) {
  const { subscriptionData, ownerUID } = data;
  const subscriptionId = subscriptionData?.subscription?.id;
  const subscriptionCustomerId = subscriptionData?.subscription?.customer.id;
  const hasIssue = !subscriptionData || !subscriptionCustomerId

  return (
    <div className='dataView CSPDataView'>
      <h3 className={hasIssue ? 'issue' : 'good'}>CSP Subscription Data {
        hasIssue
          ? <Icon name='close' size={20} color='#FC0000' />
          : <Icon name='check' size={20} color='#168E32' />
      }</h3>
      <p className={subscriptionCustomerId ? 'good' : 'issue'}><span>CustomerId:</span> {subscriptionCustomerId} <StripeLink type='cus' id={subscriptionCustomerId}/></p>
      <p><span>SubscriptionId:</span> {subscriptionId} {subscriptionId && <StripeLink type='sub' id={subscriptionId}/>}</p>
      <p className={newOwnerData ? 'new' : 'good'}><span>OwnerUID:</span> {newOwnerData ? newOwnerData.uid : ownerUID}</p>
    </div>
  );
}

function OwnerDataView ({ data, newOwnerData }) {
  const { existingOwner, subscriptionData, ownerUID } = data;
  const { FedExId, first, last, customerId, uid } = newOwnerData || existingOwner;
  const subscriptionCustomerId = subscriptionData?.subscription?.customer.id;
  const hasIssue = !newOwnerData && (!customerId || !uid || customerId !== subscriptionCustomerId || uid !== ownerUID);

  return (
    <div className='dataView OwnerDataView'>
      <h3 className={hasIssue ? 'issue' : 'good'}>Owner Data {
        hasIssue
          ? <Icon name='close' size={20} color='#FC0000' />
          : <Icon name='check' size={20} color='#168E32' />
      }</h3>
      <p className={newOwnerData ? 'new' : uid && uid === ownerUID ? 'good' : 'issue'}><span>UID:</span> {uid}</p>
      <p className={newOwnerData ? 'new' : (customerId && customerId === subscriptionCustomerId) ? 'good' : 'issue'}>
        <span>CustomerId:</span> 
        {newOwnerData 
          ? subscriptionCustomerId 
          : customerId} 
        {!!(newOwnerData ? subscriptionCustomerId : customerId) && 
          <StripeLink type='sub' id={newOwnerData ? subscriptionCustomerId : customerId} />
        } 
      </p>
      <p className={newOwnerData ? 'new' : 'good'}><span>FedExId:</span> {FedExId}</p>
      <p className={newOwnerData ? 'new' : 'good'}><span>Name:</span> {first} {last}</p>
    </div>
  );
}

function CustomerDataView ({ data, newOwnerData }) {
  const { customerData, existingOwner, subscriptionData } = data;
  const processedNewData = newOwnerData
    ? (() => {
      const id = customerData.id;
      const metadata = {
        FedExId: newOwnerData.FedExId,
        first: newOwnerData.first,
        last: newOwnerData.last
      };
      const name = `${newOwnerData.first} ${newOwnerData.last}`;
      const email = newOwnerData.email;
      const description = `${newOwnerData.FedExId} - ${name}`;

      return { id, metadata, name, email, description };
    })()
    : null;

  const subscriptionCustomerId = subscriptionData?.subscription?.customer.id;

  const { id, metadata, name, email, description } = processedNewData || customerData;
  const hasIssue = !newOwnerData && (!id || id !== existingOwner.customerId || id !== subscriptionCustomerId);

  return (
    <div className='dataView CustomerDataView'>
      <h3 className={hasIssue ? 'issue' : 'good'}>Stripe Customer Data {
        hasIssue
          ? <Icon name='close' size={20} color='#FC0000' />
          : <Icon name='check' size={20} color='#168E32' />
      }</h3>
      <p className={newOwnerData ? 'new' : id && id === existingOwner.customerId && id === subscriptionCustomerId ? 'good' : 'issue'}><span>CustomerId:</span> {id} <StripeLink type='cus' id={id}/></p>
      <p className={newOwnerData ? 'new' : 'good'}><span>Name:</span> {name}</p>
      <p className={newOwnerData ? 'new' : 'good'}><span>Email:</span> {email}</p>
      <p className={newOwnerData ? 'new' : 'good'}><span>Description:</span> {description}</p>
      <p className={newOwnerData ? 'new' : 'good'}><span>Metadata First:</span> {metadata.first}</p>
      <p className={newOwnerData ? 'new' : 'good'}><span>Metadata Last:</span> {metadata.last}</p>
      <p className={newOwnerData ? 'new' : metadata.FedExId === existingOwner.FedExId}><span>Metadata FedExId:</span> {metadata.FedExId}</p>
    </div>
  );
}


function validateDataCoherence (data) {
  const errors = [];
  const {
    subscriptionData,
    ownerUID,
    existingOwner,
    customerData
  } = data;

  if (!subscriptionData) {
    errors.push('No subscription data found! (double check then contact dev)');
  } else {
    if (!subscriptionData?.subscription?.customer?.id) {
      errors.push('No customer id found in subscription data! (double check then contact dev)');
    }
  }

  if (!ownerUID) {
    errors.push('Owner UID found in profile for operation! (double check then contact dev)');
  }

  if (!existingOwner) {
    errors.push('No data for existing owner could be found! (double check then contact dev)');
  } else {
    if (!existingOwner.customerId) {
      errors.push('Existing owner does not have a customer id! (double check then contact dev)');
    }

    if (!existingOwner.uid) {
      errors.push('Existing owner does not have a UID! (double check then contact dev)');
    }

    if (existingOwner.uid !== ownerUID) {
      errors.push('Existing owner data UID does not match CSP profile UID! (double check then contact dev)');
    }

    if (!existingOwner.customerId || existingOwner.customerId !== subscriptionData?.subscription?.customer?.id) {
      errors.push('Customer ID in owner user data DOES NOT match customer ID in subscription data! (double check then contact dev)');
    }
  }

  if (!customerData) {
    errors.push('No customer data was retrieved for the CSP! (double check then contact dev)');
  } else {
    if (!customerData.id || customerData.id !== existingOwner?.customerId) {
      errors.push('Customer ID in customer data DOES NOT match customer ID in owner user data! (double check then contact dev)');
    }

    if (!customerData.id || customerData.id !== subscriptionData?.subscription?.customer?.id) {
      errors.push('Customer ID in customer data DOES NOT match customer ID in subscription data! (double check then contact dev)');
    }

    const activeSubscriptions = customerData.subscriptions.data.filter(subscription => subscription.plan.active);

    if (activeSubscriptions.length > 1) {
      errors.push('Customer has multiple subscriptions. Transferring multiple is not currently supported. (contact dev)');
    }
  }

  return errors;
}