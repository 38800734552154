import {IConsoleApiInitializer} from "@services/ConsoleApiInitializer";
import {SubscriptionData} from "@api";
import ConsoleApiService from "@services/ConsoleApiService";

interface Filter {
  field: string;
  operator: string;
  value: string;
}

export interface CustomerAtRisk {
  id: string;
  firebase_id: string;
  entity_name: string;
  total_routes: number;
  total_paid_routes: number;
  total_active_drivers: number;
  total_authorized_users: number;
  total_vehicles: number;
  days_used: number;
  paid_route_percentage: number;
  total_live_work_areas: number;
  total_paid_work_areas: number;
  paid_live_work_areas_percentage: number;
}



export class ConsoleCustomersAtRiskService {
  private api: ConsoleApiService;
  constructor(consoleApiInitializer: IConsoleApiInitializer) {
    this.api = consoleApiInitializer.initialize();
  }

  async searchCustomers (filters: Filter[]): Promise<CustomerAtRisk[]> {
    return await this.api.get<CustomerAtRisk[]>(`customers_at_risk`, this.buildFilterParams(filters));
  }

  private buildFilterParams(filters: Filter[]): Record<string, string> {
    const params: Record<string, string> = {};
    for (let filter of filters) {
      const key = `${filter.field}_${filter.operator}`;
      const value = filter.value;
      params[key] = value;
    }

    return params;
  }

}
