import React from 'react';

import './ContactIdChange.css';

import InputSelect from '../Page/InputSelect';
import api from '../../utils/api';
import ApiTestTool from './ApiTestTool';

export default class ContactIdChange extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
    }

    this.submitContactIdChange = this.submitContactIdChange.bind(this);
  }

  async submitContactIdChange () {
    const { profile } = this.props;
    const { testedValue } = this.state;
    const { ISPID } = profile;

    this.setState({ loading: false, errorMessage: null }, async () => {
      if (!ISPID) {
        this.setState({ errorMessage: 'No ISPID could be found. Contact devs.' });
  
        return;
      }
  
      if (!testedValue) {
        this.setState({ errorMessage: 'No contact ID found to change to. Contact devs.' });
  
        return;
      }
  
      try {
        this.setState({ loading: true });
  
        const res = await api.post(`service-management/contact-id-change`, { operationId: profile.ISPID, newContactId: testedValue });
  
        await this.props.loadLatestProfile();
        
        this.setState({ loading: false });
        this.props.close();
      }
      catch (err) {
        const message = err.message || 'An error occurred';
  
        this.setState({ loading: false, errorMessage: message });
      }
    })
  };

  render () {
    const { profile, stationId } = this.props;
    
    return (
      <div
        class='ContactIdChange'
      >
        <div>
          <p style={{ fontWeight: '500', fontSize: '20px', marginBottom: '10px', textAlign: 'center' }}>Contact ID Change</p>

          <p style={{ color: '#666', marginTop: '15px' }}>Select a BC and confirm that the correct station data is flowing before submitting</p>

          <p style={{ color: '#666', marginTop: '30px' }}>WARNING: changing the contact ID used for requests will immediately impact the manifest data being represented in the app. Please validate that the ID you are submitting is correct.</p>


          <p style={{ color: '#666', marginTop: '15px' }}>NOTE: This only changes the ID used for requests. It will NOT change the customer, king, or any other billing preferences.</p>
        </div>

        {
          <p style={{ color: 'red', textAlign: 'center', marginTop: 10 }}>{this.state.errorMessage || null}</p>
        }

        <div className='testTableWrapper'>
          <ApiTestTool
            stationIds={stationId ? { [stationId]: true } : null}
            workAreas={(profile || {}).WorkAreas}
            hideButtons
            ref={ref => { this.testToolRef = ref; }}
          />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>

          <InputSelect
            value={(profile || {}).contactId}
            selectStyle={{ flex: 1 }}
            options={Object.keys((profile || {}).drivers || {}).map(fedExId => {
              const driver = profile.drivers[fedExId];
              const { first, last, type, active } = driver;

              if (active === false) return null;

              return {
                name: `${fedExId} - ${first} ${last}`,
                value: fedExId
              };
            }).filter(e => e)}
            onChange={e => {
              const val = e.target.value;

              this.setState({ testCompletedSuccessfully: false }, () => {
                this.testToolRef.closeTest(() => {
                  this.testToolRef.testApi(val, succeeded => {
                    if (succeeded) {
                      this.setState({ testCompletedSuccessfully: true, testedValue: val });
                    }
                  });
                })
              })
            }}
          />

          {
            this.state.testCompletedSuccessfully
              ? <div
                class='submit-btn'
                style={{
                  height: '100%',
                  marginLeft: '10px',
                  borderRadius: '5px',
                  padding: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'rgb(22, 142, 50)'
                }}
                onClick={this.submitContactIdChange}>
                <p style={{ color: 'white', fontWeight: '500' }}>Submit</p>
              </div>
              : null
          }
        </div>
      </div>
    )
  }
} 