import { AppDispatch, AppGetState, AppThunkDispatch  } from "@store";
import {
  updateTodayActivity,
  updateActivity,
  updateActivityUpdatedAt,
  updateDailyActivity,
  resetDailyActivity,
} from "@reducers/metrics/dailyOperationsActivity";
import { ConsoleActivityService, consoleApiInitializer} from '@services';
import firebaseProvider from '@utils/firebase';
import bugsnagClient from '@utils/bugsnag';
import {getToday} from '@utils/dates';
import {createBatches} from '@utils/batch';

export function subscribeTodaysActivity (dispatch: AppDispatch) {
  return new Promise((resolve, reject) => {
    try {
      const consoleActivityService = new ConsoleActivityService(firebaseProvider, consoleApiInitializer);

      const today = getToday();
      
      consoleActivityService.subscribeDate(today, dailyActivity => {
        dispatch(updateDailyActivity({ date: today, dailyActivity }));
        dispatch(updateTodayActivity({ todayActivity: dailyActivity }));
  
        resolve(dailyActivity);
      });
    } catch (err) {
      console.error(err);
      bugsnagClient.notify(err as Error);

      reject(err);
    }
  })
}

function unsubscribeTodaysActivity () {
  const database = firebaseProvider.getConsoleDefaultDB();
  const today = getToday();

  const ref = database.ref(`activity/data/${today}`);

  ref.off();
}

export function subscribeActivityUpdatedAt (dispatch: AppDispatch) {
  return new Promise((resolve, reject) => {
    try {
      const consoleActivityService = new ConsoleActivityService(firebaseProvider, consoleApiInitializer);

      consoleActivityService.subscribeUpdatedAt(updatedAt => {
        dispatch(updateActivityUpdatedAt({ updatedAt }));
  
        resolve(updatedAt);
      });
    } catch (err) {
      console.error(err);
      bugsnagClient.notify(err as Error);

      reject(err);
    }
  })
}

export async function fetchActivityUpdatedAt (dispatch: AppDispatch) {
  const consoleActivityService = new ConsoleActivityService(firebaseProvider, consoleApiInitializer);

  const updatedAt = await consoleActivityService.getUpdatedAt();

  dispatch(updateActivityUpdatedAt({ updatedAt }));
}

function unsubscribeActivityUpdatedAt () {
  const consoleActivityService = new ConsoleActivityService(firebaseProvider, consoleApiInitializer);

  consoleActivityService.unsubscribeUpdatedAt();
}

export function fetchPreviousDaysActivity (dates: string[], forceUpdate?: boolean) {
  return async (dispatch: AppThunkDispatch, getState: AppGetState) => {
    const consoleActivityService = new ConsoleActivityService(firebaseProvider, consoleApiInitializer);

    const state = getState();
    
    const existingTodaysActivity = state.metrics.dailyOperationsActivity.data;

    const filteredDates = dates.filter(date => !existingTodaysActivity[date]);

    const datesToFetch = forceUpdate ? dates : filteredDates;
    
    const MAX_PER_BATCH = 20;
    const batchedDates = createBatches(datesToFetch, MAX_PER_BATCH);

    batchedDates.map(async (dates) => {
      const activity = await consoleActivityService.getDates(dates);
  
      dispatch(updateActivity({ activity }));
    });
  }
}

export function clearDailyActivity(dispatch: AppDispatch) {
  unsubscribeTodaysActivity();
  unsubscribeActivityUpdatedAt();
  
  dispatch(resetDailyActivity());
}
