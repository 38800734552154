import React, { useEffect, useState } from 'react';
import { isp } from '@packageroute/types-firebase';
import moment from 'moment';
import { ProductTransferOwnershipService, consoleApiInitializer } from '@services';
import { OperationTransfer, mapOperationTransferDtoToDomain } from '@models';
import { useUser, useColorPack } from '@hooks/redux';
import { StripeLink } from '@common';
import './TransferHistoryList.css';

type Props = {
  profile: isp.profile.Profile;
}

export default function TransferHistoryList ({ profile }: Props) {
  const { transfers } = useOperationTransferHistory(profile.ISPID);

  return (
    <div className='TransferHistoryList'>
      {transfers.map(transfer => {
        return (
          <OperationTransferHistoryItem transfer={transfer} profile={profile} />
        )
      })}
    </div>
  );
}

type OperationTransferHistoryItemProps = {
  transfer: OperationTransfer,
  profile: isp.profile.Profile;
}

function OperationTransferHistoryItem ({ transfer, profile }: OperationTransferHistoryItemProps) {
  const colorPack = useColorPack();
  const formerOwner = useUser(transfer.formerOwner.uid);
  const newOwner = useUser(transfer.newOwner.uid);

  const transferDate = moment(transfer.metadata.transfer_attempted_at).format('M/D/YY h:ma');
  
  const formerOwnerLabel = formerOwner
    ? `${formerOwner.first} ${formerOwner.last}, ${formerOwner.FedExId}`
    : `FedEx ${transfer.formerOwner.fedexId}`;

  const newOwnerLabel = formerOwner
    ? `${newOwner?.first} ${newOwner?.last}, ${newOwner?.FedExId}`
    : `FedEx ${transfer.newOwner.fedexId}`;

  return (
    <div className='TransferHistoryListItem' style={{ borderBottomColor: colorPack.border }}>
      <div className='info'>
        {transfer.operationName}
      </div>

      <div className='info'>
        <p>Transferred {transferDate}</p>
      </div>

      <div className='owner-row'>
        <p className='owner-info'>{`Buyer: ${newOwnerLabel}`}</p>
        <div className='stripe-row'>
          <StripeLink showTitle type='cus' id={transfer.newOwner.customerId ?? ''} />
          <StripeLink showTitle type='sub' id={transfer.subscription.newSubscriptionId} />
        </div>
      </div>

      <div className='owner-row'>
        <p className='owner-info'>{`Seller: ${formerOwnerLabel}`}</p>
        <div className='stripe-row'>
          <StripeLink showTitle type='cus' id={transfer.formerOwner.customerId ?? ''} />
          <StripeLink showTitle type='sub' id={transfer.subscription.formerSubscriptionId} />
        </div>
      </div>
    </div>
  );
}

export function useOperationTransferHistory (operationId: string, ) {
  const [transfers, setTransfers] = useState<OperationTransfer[]>([])

  useEffect(() => {
    const ownershipTransferService = new ProductTransferOwnershipService(consoleApiInitializer);

    ownershipTransferService.getOperationTransferHistory(operationId).then(({transfers}) => {
      setTransfers(transfers.map(transfer => mapOperationTransferDtoToDomain(transfer)));
    }).catch(err => {
      console.error(err);;
    })
  }, [operationId]);

  return {
    transfers
  };
}