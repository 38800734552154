import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import PercentBar from '../common/PercentBar';

interface Props {
  color?: string;
  num?: number | null;
  denom?: number | null;
  useValue?: boolean;
  value?: number | string | null;
  label?: string;
  numValue?: number;
  denomValue?: number;
  overridePercentBar?: boolean;
}

export default function ProgressDetail ({color, num, denom, useValue, value, label, numValue, denomValue, overridePercentBar }: Props) {
  const percent = overridePercentBar
  ? (((numValue || 0) / (denomValue || 1)) * 100).toFixed(2)
  : useValue
    // @ts-ignore
    ? value || !isNaN(value)
      ? 100
      : 0
    : num && !isNaN(num) && denom && !isNaN(denom) && denom
      ? ((num / denom) * 100).toFixed(2)
      : 0;

  const numTooBig = (num && !isNaN(num)) && (denom && !isNaN(denom)) && !useValue && `${num || 0}${denom || 0}`.length > 6;

  return (
    <View style={{flex: 1}}>
      <View style={styles.container}>
        <Text style={styles.fraction}>
          { useValue
              ? <Text type='span' allowFontScaling={false} style={styles.num}>{ value || '-' }</Text>
              : <React.Fragment>
                <Text type='span' allowFontScaling={false} style={styles.num}>{ num && !isNaN(num) ? num : denom && !isNaN(denom) ? 0 : 'X' }</Text>
                { !numTooBig &&
                  <Text type='span' allowFontScaling={false} style={{color: '#999', marginLeft: 2}}>{ (denom && typeof denom === 'number' && !isNaN(denom)) ? `/ ${denom}` : '/ X' }</Text>
                }
              </React.Fragment>
            }
        </Text>

        <PercentBar
          color={color}
          height={4}
          percent={percent > 100 ? 100 : percent}
        />

        <Text allowFontScaling={false} style={styles.label}>{ numTooBig ? denom : label || '' }</Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    padding: 10,
  },
  fraction: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 15,
    marginBottom: 3
  },
  num: {
    fontWeight: 500,
    color: '#999',
    padding: 0,
  },
  label: {
    fontSize: 12,
    color: '#999',
    marginTop: 3
  }
});
