import React from 'react';
import FileSaver from 'file-saver';
import moment from 'moment-timezone';

import './EmailLists.css';
import InputSelect from '../Page/InputSelect';
import InputText from '../Page/InputText';
import api from '../../utils/api';
import Spinner from '../common/Spinner';

export default class EmailLists extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      userType: null,
      downloading: false,
      excludeInactives: false
    }

    this.getEmailList = this.getEmailList.bind(this);
  }

  async getEmailList () {
    this.setState({ downloading: true })

    try {
      const { userType, pipelineStage, excludeInactives } = this.state;
      const res = await api.post(this.props.endpoint, { userType, pipelineStage, excludeInactives });

      const blob = new Blob([res.data], { type: 'text/plain' });
      const file = new File([blob], `${moment().format('YYYY-MM-DD HHmm')}_${userType || 'all-types'}_${pipelineStage || 'all-stages'}_emails.csv`);

      FileSaver.saveAs(file);
    }
    catch (err) {
      console.log(err);
    }

    this.setState({ downloading: false });
  }

  render () {
    const { userType, pipelineStage, downloading, excludeInactives } = this.state;

    return (
      <div className='EmailLists'>
        <div className='fields'>
          <div className='fields-top'>
            <div className='field'>
              <InputSelect
                id='userType'
                label='User Type'
                type='text'
                value={userType}
                onChange={(e) => this.setState({ userType: e.target.value })}
                options={[
                  { value: null, name: 'Both' },
                  { value: 'owner', name: 'AO/BC' },
                  { value: 'driver', name: 'Driver' }
                ]}
              />
            </div>

            <div className='field'>
              <InputSelect
                id='pipelineStage'
                label='PipelineStage'
                type='text'
                value={pipelineStage}
                onChange={(e) => this.setState({ pipelineStage: e.target.value })}
                options={[
                  { value: null, name: 'Both' },
                  { value: 'trial', name: 'Trial' },
                  { value: 'signup', name: 'Signup' }
                ]}
              />
            </div>
          </div>

          <div className='fields-bottom'>
            <InputText
              id='excludeInactives'
              type='checkbox'
              label='Exclude users who have never logged in.'
              value={excludeInactives}
              checked={excludeInactives}
              style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-end', alignItems: 'center', height: '30px'  }}
              inputStyle={{ width: '25px' }}
              labelStyle={{ marginLeft: '5px', margin: '0' }}
              onChange={() => this.setState(prevState => {
                return { excludeInactives: !prevState.excludeInactives };
              })}
            />
          </div>
        </div>

        <div className='download'>
          <div
            onClick={this.getEmailList}
            className='email-btn green'>
            {
              downloading
                ? <Spinner />
                : <span>Download</span>
            }
          </div>
        </div>
      </div>
    );
  }
}
