import React from 'react';
import {RouteComponentProps} from 'react-router';
import ReferredUser from '../Signups/Referrals/ReferredUser';
import {Users} from '@reducers/users';
import {Referral} from '@reducers/referrals';
import {LiveIspList} from '@reducers/liveIspList';
import './UserReferrals.css';

type Props = {
  history: RouteComponentProps['history'];
  users: Users;
  referrals?: Referral | null;
  liveISPList: LiveIspList;
}

export default class UserReferrals extends React.Component<Props> {
  constructor (props: Props) {
    super(props);

    this.goToUserPage = this.goToUserPage.bind(this);
  }

  goToUserPage (uid: string) {
    const user = this.props.users[uid];

    this.props.history.push(`/users/${user!.FedExId}`);
  }

  render () {
    const { users, referrals, liveISPList } = this.props;

    if (!users || !referrals) {
      return (
        <div style={{ padding: '20px' }}>
          <p>This user has not referred anyone.</p>
        </div>
      );
    }

    return (
      <React.Fragment>
        {
          Object.keys(referrals.pendingInvites || {}).map((uid) => {
            const referredUser = users[uid];

            if (!referredUser) return null;

            const isps = Object.keys(referredUser.ISPS || {}).map(ISPID => {
              return liveISPList && liveISPList[ISPID];
            }).filter(profile => !!profile);

            return (
              <ReferredUser
                user={referredUser}
                goToUserPage={this.goToUserPage}
                isps={isps}
                statusLabel='PENDING'
                color='#006DAB'
              />
            );
          })
        }

        {
          Object.keys(referrals?.completedInvites || {}).map(uid => {
            const referredUser = users[uid];
            const invite = referrals?.completedInvites?.[uid];

            if (!referredUser) return null;

            const isps = Object.keys(referredUser.ISPS || {}).map(ISPID => {
              return liveISPList && liveISPList[ISPID];
            }).filter(profile => !!profile);

            return (
              <ReferredUser
                user={referredUser}
                goToUserPage={this.goToUserPage}
                completedAt={invite && invite.completedAt}
                isps={isps}
                statusLabel='COMPLETE'
                color='#168E32'
              />
            );
          })
        }
      </React.Fragment>
    );
  }
}
