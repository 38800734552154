import React, {useState} from 'react';
import moment from 'moment-timezone';
import { XAxis, YAxis, LineChart, Line, ResponsiveContainer, CartesianGrid, Tooltip } from 'recharts';
import ActiveISPList from './ActiveISPList';
import { useLiveIspList, useDailyOperationsUsage, useTodayDailyActiveUsersMetric, useDailyOperationsUsageUpdatedAt } from '@hooks/redux';
import { TooltipIcon } from '@common/Icon';
import './UsageSummary.css';

type Props = {
  setActiveISP: (ispId: string) => void;
}

const dailyActiveUsersMarkdown = `# Daily Active Users %
Recent users / Monthly users

## Recent Users
users who have touched app at least once in last 7 days

## Monthly Users
users who have touched app at least once in last 28 days`;

export default function UsageSummary ({ setActiveISP }: Props) {
  const liveISPList = useLiveIspList();
  const usage = useDailyOperationsUsage();
  const dailyUsage = useTodayDailyActiveUsersMetric();
  const usageUpdatedAt = useDailyOperationsUsageUpdatedAt();
  const [activeDate, setActiveDate] = useState(moment.tz('America/Los_Angeles').format('YYYY-MM-DD'));

  const lastXDays = getLastXDates(30);
  const activeDateUsage = usage[activeDate];
  const summary = activeDateUsage?.summary;

    return (
      <div className='UsageSummary'>
        <div className='snapshot'>
          <div className='fraction'>
            <p
              style={{ fontSize: 14 }}
            >
              <span 
                style={{ color: '#004A60', fontSize: '18px' }}
              >
                {`${Math.round(dailyUsage * 100)}%`}
              </span>
              {" Daily Active Users"}
            </p>
            
            <TooltipIcon 
              markdown={dailyActiveUsersMarkdown} 
              hoverContentStyle={{ width: 250 }} 
            />
          </div>

          <div className='date'>
            <div 
              style={{
                display: 'flex',
                alignItems: 'center',
                flex: 1
              }}>
              <p style={{ fontSize: '18px' }}>
                {
                  moment(activeDate, 'YYYY-MM-DD').calendar(null, {
                    sameDay: '[Today]',
                    nextDay: '[Tomorrow]',
                    nextWeek: 'dddd',
                    lastDay: '[Yesterday]',
                    lastWeek: '[Last] dddd',
                    sameElse: 'M-DD-YYYY'
                  })
                }
              </p>
              
              <p><span style={{ color: '#004A60', fontSize: '18px' }}>{summary?.totalUsers ?? 0}</span> Users</p>

              <p><span style={{ color: '#9f043b', fontSize: '18px' }}>{summary?.totalDrivers ?? 0}</span> Drivers</p>

              <p><span style={{ color: 'rgb(22, 142, 50)', fontSize: '18px' }}>{summary?.totalBCs ?? 0}</span> BCs</p>

              <p><span style={{ color: '#EA7D14', fontSize: '18px' }}>{summary?.totalISPs ?? 0}</span> ISPs</p>
            </div>

            <div 
              style={{
                display: 'flex',
                alignItems: 'center'
              }}>
              <p style={{ fontSize: '12px' }}>Updated at: {usageUpdatedAt ? moment.tz(usageUpdatedAt, 'America/Los_Angeles').format('h:mm a') : ''}</p>
            </div>
          </div>
        </div>

        <div className='body'>
          <div className='list'>
            <ActiveISPList
              usage={usage}
              profiles={liveISPList}
              setActiveISP={setActiveISP}
              activeDate={activeDate}
            />
          </div>

          <div className='chart'>
            {
              usage
                ? <ResponsiveContainer width='100%' height='95%'>
                    <LineChart
                      onClick={(e: any) => {
                        console.log(e);
                        if (e.activeLabel) {
                          const nextDate = moment(
                            `${e.activeLabel}-${moment().format('YYYY')}`, 'MMM-D-YYYY'
                          ).format('YYYY-MM-DD');

                          setActiveDate(nextDate);
                        }
                      }}
                      data={lastXDays.map(date => {
                        const dailyUsage = usage[date];
                        const summary = dailyUsage?.summary;

                        return {
                          name: moment(date, 'YYYY-MM-DD').format('MMM-D'),
                          'BCs': summary?.totalBCs || 0,
                          'Drivers': summary?.totalDrivers || 0,
                          'ISPs': summary?.totalISPs || 0,
                          'Users': summary?.totalUsers || 0
                        };
                      })}
                      margin={{ top: 5, right: 30, left: 0, bottom: 5 }}>
                      <XAxis dataKey='name' padding={{ left: 10, right: 10 }} />
                      <YAxis axisLine={false} tickLine={false} />
                      <Tooltip
                        itemSorter={(item) => {
                          const { dataKey, payload } = item;

                          return payload[dataKey ?? ''];
                        }}
                      />
                      <CartesianGrid vertical={false} />
                      <Line
                        activeDot={{ stroke: 'rgb(22, 142, 50)', strokeWidth: 3, r: 4 }}
                        type='monotone'
                        dataKey='BCs'
                        dot={false}
                        stroke='rgb(22, 142, 50)'
                        strokeWidth={1}
                      />
                      <Line
                        activeDot={{ stroke: '#9f043b', strokeWidth: 3, r: 4 }}
                        type='monotone'
                        dataKey='Drivers'
                        dot={false}
                        stroke='#9f043b'
                        strokeWidth={1}
                      />
                      <Line
                        activeDot={{ stroke: '#004A60', strokeWidth: 3, r: 4 }}
                        type='monotone'
                        dataKey='Users'
                        dot={false}
                        stroke='#004A60'
                        strokeWidth={1}
                      />
                      <Line
                        activeDot={{ stroke: '#EA7D14', strokeWidth: 3, r: 4 }}
                        type='monotone'
                        dataKey='ISPs'
                        dot={false}
                        stroke='#EA7D14'
                        strokeWidth={1}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                : null
            }
          </div>
        </div>
      </div>
    );
  }


function getLastXDates (numDates: number) {
  const days = [];

  for (let i = numDates; i >= 0; i--) {
    const today = moment().tz('America/Los_Angeles');

    days.push(today.subtract(i, 'days').format('YYYY-MM-DD'));
  }

  return days;
}
