import { createSelector } from "reselect";
import { RootState } from "@reducers";
import { Status } from '@reducers/subscriptions/canceled';
import moment from "moment-timezone";
import { selectTodayOperationsActivity } from "../metrics/dailyOperationsActivity";
import { selectTimeZone } from "../baseSelectors";

const _selectCanceledSubscriptions = (state: RootState) =>
  state.subscriptions.canceled.data;

const selectCanceledSubscriptionsLoaded = (state: RootState) =>
  state.subscriptions.canceled.status === Status.UPDATED;

const selectCanceledSubscriptions = createSelector(
  [_selectCanceledSubscriptions, selectTodayOperationsActivity, selectTimeZone],
  (cancelledSubscriptions, todayOperationsActivity, timeZone) => {
    const cancellations = cancelledSubscriptions.filter((sub) => {
      const operationId = sub.metadata.ISPID;
      const cancelledAt = sub.canceled_at;

      if (!cancelledAt || sub.status !== "canceled") {
        return false;
      }

      const cancelledAtMoment = moment.tz(cancelledAt * 1000, timeZone);

      const isPaidOperation = todayOperationsActivity?.paidISPs?.[operationId];
      const operationActivity = todayOperationsActivity?.liveISPs?.[operationId];

      const updatedAt = operationActivity?.updatedAt ?? 0;
      const updatedAtMoment = moment.tz(updatedAt, timeZone);

      const wasUpdatedAfterCancellation = updatedAtMoment.isAfter(cancelledAtMoment);

      const isStillPaidOperation = wasUpdatedAfterCancellation && (operationActivity?.paidRoutes ?? 0) > 0;
      
      return !isPaidOperation || (isPaidOperation && !isStillPaidOperation);
    });

    return cancellations;
  }
);

export { selectCanceledSubscriptions, selectCanceledSubscriptionsLoaded };
