import React from 'react';
import moment from 'moment-timezone';
import Icon from '../Icon/Icon';
import FirebaseLink from '../common/FirebaseLink';
import ConsoleLink from '../common/ConsoleLink';
import Button from '../Page/Button';
import StationAdvocate from '../User/StationAdvocate';
import ApiTestTool from './ApiTestTool';
import './UserEntry.css';

export default function UserEntry (props) {
  const {
    isAdvocate,
    user,
    profile,
    isAccountOwner,
    activeUser,
    openUser,
    usage,
    allowBillingForUser,
    removeBillingForUser,
    currentOperation
  } = props;

  const formatDate = (date) => {
    return moment(date).calendar(null, {
      sameDay: '[Today], hh:mm a',
      lastDay: '[Yesterday], hh:mm a',
      lastWeek: '[Last] dddd',
      sameElse: 'M-DD-YYYY'
    })
  }
  const active = !!((usage || {}).activeUsers || {})[user.uid];
  const devices = user.devices || {}
  const lastDeviceId = user.metadata && user.metadata.lastDeviceId;
  const lastVersion = user.metadata && user.metadata.lastVersion;

  const { browser, browserVersion, lastAppVersion, os, osVersion, platform } = user.metadata && user.metadata.lastWebVersion || {};
  const lastWebVersion = lastAppVersion;
  const lastBrowserVersion = (browser && browserVersion) ? `${browser} v${browserVersion}` : browser ? browser : null;
  const lastOSVersion = (os && osVersion) ? `${os} v${osVersion}` : os ? os : null;
  const lastPlatform = platform || null;

  const lastDevice = devices[lastDeviceId];
  const lastLogin =
    (user.metadata &&
      user.metadata.lastLogin &&
      formatDate(user.metadata.lastLogin)) ||
    null;
  const lastWebLogin =
    (user.metadata &&
      user.metadata.lastWebLogin &&
      formatDate(user.metadata.lastWebLogin)) ||
    null;
  const workAreas = Object.values(profile.WorkAreas || {});
  const assignedWorkAreas = [];

  const stationId = (profile && profile.station && profile.station.stationID) ?? 'No station Id';

  for (const workArea of workAreas) {
    if (!assignedWorkAreas.includes(workArea.workArea) && workArea.driver === user.FedExId) {
      assignedWorkAreas.push(workArea.workArea);
    }
    
    if (!assignedWorkAreas.includes(workArea.workArea) && workArea.alternateDrivers && workArea.alternateDrivers[user.FedExId]) {
      assignedWorkAreas.push(workArea.workArea);
    }
  }

  return (
    <div className={`UserEntry ${user.authorized !== true ? 'fade' : ''} `}>
      <div className='top' onClick={user.uid ? () => openUser(user.uid) : null}>
        <div className='identity'>
          <div className='activeLightWrap'>
            <div className={`activeLight${active ? ' active' : ''}`} />
          </div>

          <div className='nameWrap'>
            <h3 className='userName' style={{ marginRight: '5px' }}>
              {capitalizeFirst(user.first)} {capitalizeFirst(user.last)}
            </h3>

            {!!user.FedExId && <div className='userId'>{user.FedExId}</div> }
          </div>

          {
            user.authorized !== true
              ? <div className='authorization'>
                {
                  user.inviteSent
                    ? <Icon name='email' color='yellow' size={15} />
                    : <Icon name='close-circle-outline' color='red' size={15} />
                }
                <span style={{ marginLeft: '5px', fontSize: '12px', color: 'red' }}>
                  {
                    user.inviteSent
                      ? 'Invite Sent'
                      : 'Authorized'
                  }
                </span>
              </div>
              : null
          }

          {
            profile && (!currentOperation || profile.ISPID !== currentOperation.ISPID)
              ? <div className='currentOp'>
                <Icon name='eye' color='white' size='12px' />
                <p>{currentOperation ? currentOperation.CSPName : 'Unknown Operation'}</p>
              </div>
              : null
          }
        </div>

        <div className='misc'>
          {
            isAdvocate
              ? <StationAdvocate editable={false} isAdvocate />
              : null
          }

          {
            assignedWorkAreas.map(workAreaId => 
              <div className='waWrap'>
                <p>{workAreaId}</p>
              </div>
            )
          }

          { isAccountOwner &&
            <div className='iconWrap' style={{ marginLeft: '10px', marginRight: '10px' }}>
              <Icon name='crown' color='#168E32' size={25} />
            </div>
          }

          { user && user.allowBilling &&
            <div className='iconWrap' style={{ marginLeft: '10px', marginRight: '10px' }}>
              <Icon name='credit-card' color='#006DAB' size={25} />
            </div>
          }

          <div className='iconWrap' style={{ marginLeft: '10px', marginRight: '10px' }}>
            {
              user.Type === 'owner'
                ? <div className='bcLabel'>BC</div>
                : <Icon className='driverLabel' name='truck' color='#FF9200' size={25} />
            }
          </div>
        </div>

        {
          user.uid
            ? <FirebaseLink path={`Users/${user.uid}`} />
            : null
        }

        {
          user.FedExId
            ? <ConsoleLink
              replace
              to={`/users/${user.FedExId}`}
            />
            : null
        }
      </div>

      {
        user.uid && (activeUser === user.uid)
          ? <div className='bottom'>
            <div className='contact'>
              {
                user.phone
                  ? <div className='info'>
                    <Icon name='phone' size={20} color='black' />

                    <p>{user.phone}</p>
                  </div>
                  : null
              }

              {
                user.email
                  ? <div className='info'>
                    <Icon name='email' size={20} color='black' />

                    <p>{user.email}</p>
                  </div>
                  : null
              }

              {
                allowBillingForUser && removeBillingForUser && user.authorized === true &&
                <div className='billingToggleWrap' style={{marginTop: 10, marginBottom: 10}}>
                  {
                    user.allowBilling
                      ? <Button onClick={() => removeBillingForUser(user.uid)} color='#B90C0F'>Remove Billing</Button>
                      : <Button onClick={() => allowBillingForUser(user.uid)} color='#006DAB'>Allow Billing</Button>
                  }
                </div>
              }

            </div>

            <div className='app-data'>
              {
                lastDevice && lastDevice.metadata
                  ? <React.Fragment>
                    <div className='info'>
                      <Icon name='phone-in-talk' size={20} color='black' />

                      <p>{lastDevice.metadata.carrier}</p>
                    </div>
                    <div className='info'>
                      <Icon
                        name={
                          lastDevice.metadata.make === 'Apple'
                            ? 'apple'
                            : lastDevice.metadata.make === 'Android'
                              ? 'android'
                              : ''
                        }
                        size={20}
                        color='black'
                      />

                      <p>{lastDevice.metadata.make}</p>
                    </div>
                    <div className='info'>
                      <Icon name='cellphone-link' size={20} color='black' />

                      <p>{lastDevice.metadata.model}</p>
                    </div>
                  </React.Fragment>
                  : null
              }

              {
                lastVersion
                  ? <React.Fragment>
                    <div className='info'>
                      <Icon name='label' size={20} color='black' />

                      <p>Last Native Version: v{lastVersion}</p>
                    </div>
                  </React.Fragment>
                  : null
              }
              {

                lastVersion
                  ? <React.Fragment>
                    <div className='info'>
                      <p>Last Login: {lastLogin}</p>
                    </div>
                  </React.Fragment>
                  : null
              }


              <React.Fragment>
                { lastBrowserVersion &&
                  <div className='info'>
                    <Icon
                      name={
                        browser === 'Chrome'
                        ? 'google-chrome'
                        : browser === 'Safari'
                        ? 'apple-safari'
                        : browser === 'Opera'
                        ? 'opera'
                        : browser === 'Firefox'
                        ? 'firefox'
                        : browser === 'Edge'
                        ? 'edge'
                        : browser === 'Internet Explorer' || browser === 'Explorer'
                        ? 'internet-explorer'
                        : 'help-circle-outline'
                      }
                      size={20}
                      color='black'
                    />
                    <p>{lastBrowserVersion}</p>
                  </div>
                }

                { lastOSVersion &&
                  <div className='info'>
                    <Icon
                      name={
                        os === 'macOS'
                        ? 'apple'
                        : os === 'Windows'
                        ? 'windows'
                        : os === 'Linux'
                        ? 'linux'
                        : os === 'Android'
                        ? 'android'
                        : os === 'iOs'
                        ? 'apple-ios'
                        : os === 'Chrome OS'
                        ? 'google-chrome'
                        : 'help-circle-outline'
                      }
                      size={20}
                      color='black'
                    />

                    <p>{lastOSVersion}</p>
                  </div>
                }
                { lastPlatform &&
                  <div className='info'>
                    <Icon
                      name={
                        lastPlatform === "mobile"
                        ? "cellphone"
                        : lastPlatform === "tablet"
                        ? "tablet-ipad"
                        : lastPlatform === "desktop"
                        ? "laptop"
                        : "help-circle-outline"
                      }
                      size={20}
                      color='black'
                    />

                    <p>{lastPlatform}</p>
                  </div>
                }
              </React.Fragment>

              {
                lastWebVersion
                  ? <React.Fragment>
                    <div className='info'>
                      <Icon name='label' size={20} color='black' />

                      <p>Last Web Version: v{lastWebVersion}</p>
                    </div>
                  </React.Fragment>
                  : null
              }
              {

                lastWebLogin
                  ? <React.Fragment>
                    <div className='info'>
                      <p>Last Web Login: {lastWebLogin}</p>
                    </div>
                  </React.Fragment>
                  : null
              }
            </div>
          </div>
          : null
      }

      { !!user.uid && (activeUser === user.uid) &&
        <ApiTestTool
          contactId={user.FedExId}
          workAreas={(profile || {}).WorkAreas}
          stationIds={stationId ? { [stationId]: true } : null}
        />
      }

      {
        // activeUser === user.uid
        //   ? <div className='actions'>
        //     <div style={{ width: '300px' }}>
        //       <AddToCollection
        //         users={[user.uid]}
        //         collections={collections}>
        //         <div className='option'>
        //           <p>Add to collection</p>
        //         </div>
        //       </AddToCollection>
        //     </div>
        //
        //   </div>
        //   : null
      }
    </div>
  );
}

function capitalizeFirst (text) {
  if (!text) return text;

  return text.slice(0, 1).toUpperCase() + text.slice(1);
}
