import moment from 'moment-timezone';

const DATE_KEY = 'YYYY-MM-DD';

/**
 * Starting with n days ago, create list counting forward to today
 * 
 * inclusive of today
 * 
 * [-n, ..., today - 1, today]
 * @param numDates 
 * @param timeZone 
 * @returns 
 */
export function getLastNDates (numDates: number, timeZone='America/Los_Angeles') {
  const days = [];

  for (let i = numDates; i >= 0; i--) {
    const today = moment.tz(timeZone).startOf('day');

    days.push(today.subtract(i, 'days').format(DATE_KEY));
  }

  return days;
}

/**
 * Starting with yesterday, creates list of dates
 * 
 * [yesterday, y-1, y-2, y-3, ..., -n]
 * @param daysAgo 
 * @param timeZone 
 * @returns 
 */
export function getDaysAgo (daysAgo: number, timeZone: string): string[] {
  const dates: string[] = [];

  for (let i = 1; i <= daysAgo; i++) {
    const date = moment.tz(timeZone).subtract(i, 'days').format(DATE_KEY);

    dates.push(date);
  }

  return dates;
}

export function getThisWeek (today: string, timeZone: string): string[] {
  const todayDate = moment.tz(today, DATE_KEY, timeZone).format(DATE_KEY);

  const startOfWeekDate = getStartOfWeek(todayDate, timeZone, MomentDayNum.SAT);
  const endOfWeekDate = getEndOfWeek(todayDate, timeZone, MomentDayNum.SAT);
  
  const startOfWeek = moment(startOfWeekDate, DATE_KEY);
  const endOfWeek = moment(endOfWeekDate, DATE_KEY);
  
  const days: string[] = [];

  let day = startOfWeek.clone();

  while (day.isSameOrBefore(endOfWeek, 'day')) {
    days.push(day.format(DATE_KEY));

    day.add(1, 'day');
  }

  return days;
}

export function getLastFourWeeks (today: string, timeZone: string): string[] {
  const todayDate = moment.tz(today, DATE_KEY, timeZone).format(DATE_KEY);

  const startOfWeekDate = getStartOfWeek(todayDate, timeZone, MomentDayNum.SAT);
  const endOfWeekDate = getEndOfWeek(todayDate, timeZone, MomentDayNum.SAT);
  
  const startOfWeek = moment(startOfWeekDate, DATE_KEY);
  const endOfWeek = moment(endOfWeekDate, DATE_KEY);

  const fourWeeksAgo = startOfWeek.clone().subtract(3, 'weeks');
  
  const days: string[] = [];

  let day = fourWeeksAgo.clone();

  while (day.isSameOrBefore(endOfWeek, 'day')) {
    days.push(day.format(DATE_KEY));

    day.add(1, 'day');
  }

  return days;
}

export function getLastFourWeeksGroupedByWeek (today: string, timeZone: string): string[][] {
  const todayDate = moment.tz(today, DATE_KEY, timeZone).format(DATE_KEY);

  const startOfWeekDate = getStartOfWeek(todayDate, timeZone, MomentDayNum.SAT);

  const startOfWeek = moment(startOfWeekDate, DATE_KEY);
  const twoWeeksAgo = startOfWeek.clone().subtract(7 * 1, 'days');
  const threeWeeksAgo = startOfWeek.clone().subtract(7 * 2, 'days');
  const fourWeeksAgo = startOfWeek.clone().subtract(7 * 3, 'days');
  
  const weekStartDays = [
    fourWeeksAgo,
    threeWeeksAgo,
    twoWeeksAgo,
    startOfWeek,
  ]

  return weekStartDays.map(startDay => {
    let day = startDay.clone();
    const days: string[] = [];

    while (days.length < 7) {
      days.push(day.format(DATE_KEY));
  
      day.add(1, 'day');
    }

    return days;
  })
}

/**
 * Moment day -> day number mappings
 */
export enum MomentDayNum {
  SUN = 0,
  MON = 1,
  TUE = 2,
  WED = 3,
  THU = 4,
  FRI = 5,
  SAT = 6,
}

export function getStartOfWeek (date: string, timeZone: string, startOfWeekNum = MomentDayNum.SAT) {
  const today = moment.tz(date, DATE_KEY, timeZone);
  const startDay = today.clone().day(startOfWeekNum);

  if (startDay.isSame(today, 'day')) {
    return date;
  }

  if (startDay.isAfter(today, 'day')) {
    return startDay.subtract(7, 'days').format(DATE_KEY);
  }

  else {
    return startDay.format(DATE_KEY);  
  }
}

export function getEndOfWeek (date: string, timeZone: string, startOfWeekNum = MomentDayNum.SAT) {
  const today = moment.tz(date, DATE_KEY, timeZone);
  const startDay = today.clone().day(startOfWeekNum);

  if (startDay.isSame(today, 'day')) {
    return startDay.add(6, 'days').format(DATE_KEY);
  }

  if (startDay.isAfter(today, 'day')) {
    return startDay.subtract(1, 'day').format(DATE_KEY);
  }

  else {
    return startDay.add(6, 'days').format(DATE_KEY);  
  }
}

export function groupDatesByWeek (dates: string[], timeZone: string): string[][] {
  const dateGroups: Record<string, string[]> = {};

  for (const date of dates) {
    const weekStartDate = getStartOfWeek(date, timeZone);
    
    if (!dateGroups[weekStartDate]) dateGroups[weekStartDate] = [];

    dateGroups[weekStartDate].push(date);
  }

  return Object
    .keys(dateGroups)
    .sort((a, b) => a < b ? -1 : 1)
    .reduce<string[][]>((acc, weekStartDate) => {
      const dates = dateGroups[weekStartDate];

      const sortedDates = dates.sort((a, b) => a < b ? -1 : 1);

      acc.push(sortedDates);
      
      return acc;
    }, []);
}