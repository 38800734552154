import {configureStore} from '@reduxjs/toolkit';
import {AnyAction, Action} from 'redux';
import thunkMiddleware, {ThunkAction, ThunkDispatch} from 'redux-thunk';
import rootReducer, {RootState as IRootState} from './reducers';
import {devToolsEnhancer} from 'redux-devtools-extension';


const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleWare) =>
    getDefaultMiddleWare({ immutableCheck: false, serializableCheck: false }).prepend(thunkMiddleware),
  devTools: false,
  enhancers: [devToolsEnhancer({ name: 'PackageRoute Console', traceLimit: 1 })]
});

export default store;

export type RootState = IRootState;

/**
 * Includes Redux-Thunk typings
 *
 * const thunkDispatch = this.props.dispatch as AppDispatch
 *
 * thunkDispatch(myThunkAction(...))
 */
export type AppDispatch = typeof store.dispatch;
export type AppGetState = typeof store.getState;
export type AppThunkDispatch = ThunkDispatch<RootState, unknown, Action<any>>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>