import { Dispatch } from "@reduxjs/toolkit";
import {
  updateUsers,
  updateUser,
  resetUsers,
  Users,
  User
} from "@reducers/users";
import firebaseProvider from '@utils/firebase';
import bugsnagClient from "@utils/bugsnag";

export async function fetchUsers(dispatch: Dispatch, userIds: string[]) {
  try {
    const database = firebaseProvider.getConsoleDefaultDB();
    const ref = database.ref('users/data');

    const userRequests = userIds.map<Promise<[string, User | null]>>(async (uid) => {
      const snap = await ref.child(uid).once('value')
      
      const user: User | null = snap.val();

      return [uid, user];
    });

    const userEntries = await Promise.all(userRequests);

    const users = userEntries.reduce<Users>((acc, [uid, user]) => {
      acc[uid] = user ?? null;

      return acc;
    }, {});

    dispatch(updateUsers({ users }));

    return users;
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err as Error);
  }
}

export async function fetchUser(dispatch: Dispatch, userId: string) {
  try {
    const database = firebaseProvider.getConsoleDefaultDB();
    const ref = database.ref(`users/data/${userId}`);

    const snap = await ref.once('value')
    
    const user: User | null = snap.val();

    dispatch(updateUser({ userId, user }));

    return user;
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err as Error);
  }
}

/**
 * Clears sales data state
 */
export function clearUsers(dispatch: Dispatch) {
  dispatch(resetUsers());
}
