import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import pr from '@packageroute/types-firebase';

type StationAdvocates = pr.stations.advocates.StationAdvocates;

/**
 * ISO8601 format
 * 
 * e.g. 2019-06-29T10:36:18.404Z
 */
export type DateTimeStamp = string;

/**
 * Station data is taken from Console SQL database
 */
export type ConsoleStation = {
  id: number;
  terminal: string;
  terminalName: string;
  fullAddress: string | null;
  address1: string;
  city: string;
  state: string;
  postalCode: string;
  latitude: string;
  longitude: string;
  regionNumber: string;
  region2: string | null;
  region4: string | null;
  telephone: string | null;
  division2: string | null;
  division4: string | null;
  divisionCode: string | null;
  divisionName: string | null;
  flexTime: string | null;
  sortTime: string | null;
  dispatchTime: string | null;
  hdOnly: boolean;
  hasCoLocation: boolean;
  coLocationNumber: string | null;
  advocates: StationAdvocates | null;
  createdAt: DateTimeStamp;
  updatedAt: DateTimeStamp;
}

export type StationId = string;
export type Stations = Record<StationId, ConsoleStation>;

export enum Status {
  INITIAL = "INITIAL",
  UPDATED = "UPDATED",
  FAILED = 'FAILED',
}

type StationsState = {
  status: Status;
  data: Stations;
  stationIdMappings: Record<string, number>;
};

const initialState: StationsState = {
  status: Status.INITIAL,
  data: {},
  stationIdMappings: {},
};

const consoleStationsSlice = createSlice({
  name: "consoleStations",
  initialState: initialState,
  reducers: {
    updateStation: (
      state,
      action: PayloadAction<{ stationId: string, station: ConsoleStation }>
    ) => {
      const { stationId, station } = action.payload;

      state.data[stationId] = station;
      state.stationIdMappings[stationId] = station.id;

      state.status = Status.UPDATED;
    },
    updateStations: (
      state,
      action: PayloadAction<{ stations: Stations }>
    ) => {
      const { stations } = action.payload;

      for (const stationId in stations) {
        const station = stations[stationId];

        state.data[stationId] = station;
        state.stationIdMappings[stationId] = station.id;
      }

      state.status = Status.UPDATED;
    },
    removeStation: (state, action: PayloadAction<{ stationId: string }>) => {
      const { stationId } = action.payload;

      delete state.data[stationId];
      delete state.stationIdMappings[stationId];
    },
    resetStations: (state) => {
      state.data = {};
      state.stationIdMappings = {};
      state.status = Status.INITIAL;
    },
  },
});

export const { updateStation, updateStations, removeStation, resetStations } =
  consoleStationsSlice.actions;

export default consoleStationsSlice.reducer;
