import React from 'react';
import './PageWrapper.css';

export default function PageWrapper ({ children, ...rest }) {
  return (
    <div className='PageWrapper' {...rest}>
      { children }
    </div>
  );
}
