import React from "react";
import Icon from "../Icon/Icon";
import Button from "../Page/Button";
import {ServiceEventSeverity} from "@packageroute/core-app/dist/models/service_status";

type Props = {
  severity: ServiceEventSeverity;
};

const iconConfig: Record<ServiceEventSeverity, { name: string; color: string }> = {
  [ServiceEventSeverity.NO_IMPACT]: { name: "check-circle", color: "#178E32" },
  [ServiceEventSeverity.MINOR_IMPACT]: { name: "alert-circle", color: "#0477B4" },
  [ServiceEventSeverity.SIGNIFICANT_IMPACT]: { name: "alert", color: "#FFA502" },
  [ServiceEventSeverity.CRITICAL_IMPACT]: { name: "alert-octagon", color: "#B90C0E" },
};

export default function SeverityIcon({ severity }: Props) {
  const icon = iconConfig[severity];
  return <Icon name={icon.name} color={icon.color} size={25} />;
}

type ButtonProps = {
  onClick: () => void;
  selected: boolean;
} & Props;

export function SeverityIconButton({
  severity,
  onClick,
  selected,
}: ButtonProps) {
  return (
    <Button
      onClick={onClick}
      style={{ 
        flex: 1, 
        border: "solid 1px #ddd", 
        padding: 5, 
        margin: 5,
        backgroundColor: selected ? 'white' : '#eee'
      }}
      color="white"
    >
      <SeverityIcon severity={severity} />
    </Button>
  );
}
