/**
 * Used to parse number into comma separated string
 * 
 * 10000000 -> (10) (000) (000) -> 10,000,000
 */
 const groupByTripleDigits = /\B(?=(\d{3})+(?!\d))/g;

 export function formatNumberAsCommaSeparated (num: number) {
  if (!num) return 0;

  return num.toString().replace(groupByTripleDigits, ',')
 }