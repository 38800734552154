import React from 'react';

import './PreviewEmail.css';

export default function PreviewEmail (props) {
  const { template, templateData } = props;
  const regexp = new RegExp(/{{{?(#[a-z]+ )?[a-z]*}?}}/ig);
  let htmlContent = template ? template.versions[0].html_content : '';

  const matches = htmlContent.match(regexp) || [];

  for (const match of matches) {
    for (const key in templateData) {
      if (match.includes(key) && templateData[key]) {
        htmlContent = htmlContent.replace(match, templateData[key]);

        break;
      }
    }
  }

  return (
    <div className='PreviewEmail'>
      <h3>Email Preview</h3>

      <div className='preview'>
        {
          template
            ? <iframe srcDoc={htmlContent} title="email-preview" />
            : <p>No email template selected.</p>
        }
      </div>
    </div>
  );
}
