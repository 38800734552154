import moment from 'moment-timezone';
import Stripe from 'stripe';
import { LiveIspList } from '@reducers/liveIspList';

type Subscription = Stripe.subscriptions.ISubscription;

/**
 * Determines if subscription is an active subscription used by an
 * operation which we have operation data for.
 */
export function isConversion (subscription: Subscription, liveISPList: LiveIspList, timeLimit?: number): boolean {
  const { status, metadata, trial_end, created } = subscription;
  const { ISPID } = metadata;

  if (!liveISPList[ISPID]) return false;

  if (status !== 'active') {
    return false;
  }

  const profile = liveISPList[ISPID];

  const createdAt = profile.metadata ? profile.metadata.createdAt: null;
  const timeLimitMoment = moment(timeLimit);

  if (createdAt) {
    const createdAtmoment = moment(createdAt)

    if (createdAtmoment.add(1, 'month').isBefore(timeLimit)) return false;
  }

  const start = trial_end || created;

  if (timeLimit) {
    const isAfterTimeLimit = moment.tz(start * 1000, 'America/Los_Angeles').isAfter(timeLimitMoment);
  
    return isAfterTimeLimit;
  }

  return true;
}

type IsCancellationFilterOptions = {
  excludeCancelledOutsideTrial?: boolean;
  includeCancelledWithinTrial?: boolean;
  timeLimit?: number;
}
/**
 * Determines if subscriptin is a cancellation within provided time period.
 * 
 * Will return false if ISP is not in activeISPList, however the ISP list
 * is not an accurate determination of which ISPs are active with PackageRoute,
 * nor should we be determining if an ISP cancelled based upon the ISP list.
 * 
 * Likely was used to filter out ISPs which we have no displayable data for.
 */
export function isCancellation (subscription: Subscription, options?: IsCancellationFilterOptions): boolean {
  const {
    excludeCancelledOutsideTrial,
    includeCancelledWithinTrial,
    timeLimit
  } = options ?? {};

  if (!subscription) return false;

  const canceledAt = subscription.canceled_at;
  const trialEndAt = subscription.trial_end;
  const ispId = subscription.metadata.ISPID;

  if (!ispId || !canceledAt) return false;

  if (subscription.status !== 'canceled') {
    return false;
  }

  const canceledAtUnix = canceledAt * 1000;
  const canceledAtMoment = moment.tz(canceledAtUnix, 'America/Los_Angeles');

  if (trialEndAt) {
    const trialEndAtUnix = trialEndAt * 1000;
    const trialEndAtMoment = moment.tz(trialEndAtUnix, 'America/Los_Angeles');

    if (!includeCancelledWithinTrial && trialEndAtMoment.isAfter(canceledAtMoment)) {
      return false;
    }

    if (excludeCancelledOutsideTrial && canceledAtMoment.isAfter(trialEndAtMoment)) {
      return false;
    }
  }

  if (timeLimit) {
    const timeLimitMoment = moment(timeLimit);
    const isAfterTimeLimit = canceledAtMoment.isAfter(timeLimitMoment);

    return isAfterTimeLimit;
  }

  return true;
}