import React from "react";
import PageWrapper from "../Page/PageWrapper";
import PageTitle from "../Page/PageTitle";
import Section from "../Page/Section";
import api from "../../utils/api";
import { Severity, NoticeObject } from "../Page/Notice";
import { PortalModal } from "../Page/Modal";
import NoticeModal from "../Page/NoticeModal";
import withPermissions from "../Permissions/withPermissions";
import StatusEventForm from "./StatusEventForm";
import StatusEventPreview from "./StatusEventPreview";
import Button from "../Page/Button";
import "./ServiceStatus.css";
import {ServiceStatusEvent, ServiceStatusObject} from "@packageroute/core-app/dist/models/service_status";

type Props = {
  // location: RouteComponentProps["location"];
  // history: RouteComponentProps["history"];
};

type State = {
  loading: boolean;
  data: null | ServiceStatusObject;
  createEvent?: boolean;
  focusedEvent: ServiceStatusEvent | null;
  notice?: null | NoticeObject;
};

class ServiceStatus extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      data: null,
      focusedEvent: null,
      notice: null,
    };

    this.setActiveEvent = this.setActiveEvent.bind(this);
    this.addEvent = this.addEvent.bind(this);
    this.editEvent = this.editEvent.bind(this);
    this.updateEvent = this.updateEvent.bind(this);
    this.endEvent = this.endEvent.bind(this);
    this.deleteEvent = this.deleteEvent.bind(this);
    this.restoreDefault = this.restoreDefault.bind(this);
    this.addNotice = this.addNotice.bind(this);
    this.clearNotice = this.clearNotice.bind(this);
    this.toggleCreateEvent = this.toggleCreateEvent.bind(this);
    this.clearEditEvent = this.clearEditEvent.bind(this);
  }

  async componentDidMount() {
    this.fetchStatusData();
  }

  componentDidUpdate() {}

  async fetchStatusData() {
    this.setState({ loading: true });

    const res = await api.request<ServiceStatusObject>("service-status/data");

    this.setState({ data: res.data || null, loading: false });
  }

  async restoreDefault () {
    await this.setActiveEvent('default');
  }

  async setActiveEvent(eventId: string) {
    const res = await api.patch(`service-status/event/${eventId}/active`);

    this.setState({ data: res.data });
  }

  toggleCreateEvent() {
    this.setState((prev) => ({ ...prev, createEvent: !prev.createEvent }));
  }

  editEvent(event: ServiceStatusEvent) {
    this.setState({ focusedEvent: event, createEvent: false });
  }

  clearEditEvent() {
    this.setState((prev) => ({ ...prev, focusedEvent: null }));
  }

  async addEvent(createdEvent: ServiceStatusEvent) {
    const event: ServiceStatusEvent = {
      ...createdEvent,
      id: `${createdEvent.severity}_${Date.now()}`,
      startedAt: Date.now(),
    };

    const res = await api.post(`service-status/event`, {event});

    this.setState({ data: res.data, focusedEvent: null, createEvent: false });
  }

  async updateEvent(updatedEvent: ServiceStatusEvent) {
    if (!updatedEvent) {
      return this.addNotice(
        "error",
        "Unable to edit event",
        "Please select event first"
      );
    }

    const event: ServiceStatusEvent = {
      ...updatedEvent,
      updatedAt: Date.now(),
    };

    const res = await api.patch(`service-status/event/${event.id}`, {event});

    this.setState({ data: res.data });
    this.clearEditEvent();
  }

  async endEvent() {
    const { focusedEvent } = this.state;

    if (!focusedEvent) {
      return this.addNotice(
        "error",
        "Unable to edit event",
        "Please select event first"
      );
    }

    const event: ServiceStatusEvent = {
      ...focusedEvent,
      endedAt: Date.now(),
    };

    const res = await api.patch(`service-status/event/${event.id}`, {event});

    this.setState({ data: res.data });
    this.clearEditEvent();
  }

  async deleteEvent(eventId: string) {
    const res = await api.deleteRequest(`service-status/event/${eventId}`);

    this.setState({ data: res.data });
  }

  addNotice(
    severity: Severity,
    title: string,
    description?: string,
    loading?: boolean
  ) {
    this.setState({
      notice: {
        severity,
        title,
        description,
        loading,
      },
    });
  }

  clearNotice() {
    this.setState({ notice: null });
  }

  render() {
    const { loading, notice, focusedEvent, createEvent, data } = this.state;

    if (loading) {
      return (
        <NoticeModal
          notice={{
            loading: true,
            severity: "info",
            title: "Loading Status...",
            description: "Please wait a second.",
          }}
          clear={() => {}}
        />
      );
    }

    return (
      <div className="ServiceStatus">
        <PageWrapper>
          <PageTitle>Service Status Message</PageTitle>

          <NoticeModal notice={notice} clear={this.clearNotice} />

          <PortalModal
            centerFullScreen
            visible={!!focusedEvent}
            onRequestClose={this.clearEditEvent}
          >
            {!!focusedEvent ? (
              <div style={{ maxWidth: 600, width: "100%" }}>
                <StatusEventForm
                  editing
                  event={focusedEvent}
                  onSubmit={this.updateEvent}
                  onClose={this.toggleCreateEvent}
                />
              </div>
            ) : null}
          </PortalModal>

          <PortalModal
            centerFullScreen
            visible={!!createEvent}
            onRequestClose={this.toggleCreateEvent}
          >
            <div style={{ maxWidth: 600, width: "100%" }}>
              <StatusEventForm onSubmit={this.addEvent} onClose={this.toggleCreateEvent} />
            </div>
          </PortalModal>

          {!!data && (
            <Section 
              title={"Current Status" + (data.eventId === 'default' ? ": Default" : "")}
              topContent={
                <div className='actions-wrap'>
                  { data && data.eventId !== "default" && 
                    <Button style={{ marginRight: 10 }} onClick={this.restoreDefault} color='#0477B4'>Restore Default</Button>
                  }
                  <Button onClick={this.toggleCreateEvent} color='#178E32'>Create Message</Button>
                </div>
              }
            >
              <StatusEventPreview
                event={
                  data.eventId === "default"
                    ? data.default
                    : data.events.find((e) => e.id === data.eventId)!
                }
                onEdit={this.editEvent}
                onDelete={this.deleteEvent}
                onSelect={this.setActiveEvent}
              />
            </Section>
          )}

          {!!data && data.eventId !== 'default' &&
            <Section title={"Default Status"}>
              <StatusEventPreview
                event={data.default}
                onEdit={this.editEvent}
                onDelete={this.deleteEvent}
                onSelect={this.setActiveEvent}
              />
            </Section>
          }

          {!!data && !!data.events.length && (
            <Section title="Previous Statuses">
              {data.events
                .sort((a, b) => (a.startedAt > b.startedAt ? -1 : 1))
                .map((event, i, arr) => {
                  if (event.id === data.eventId) return null;

                  const hasActive = data.eventId !== 'default';
                  
                  return (
                    <React.Fragment>
                      <StatusEventPreview 
                        event={event} 
                        onEdit={this.editEvent} 
                        onDelete={this.deleteEvent}
                        onSelect={this.setActiveEvent}
                      />
                      {(arr.length > i + (hasActive ? 2 : 1)) &&
                        <div className='border-line' />
                      }
                    </React.Fragment>
                  );
                })}
            </Section>
          )}
        </PageWrapper>
      </div>
    );
  }
}

export default withPermissions(ServiceStatus);
