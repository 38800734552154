import React from 'react';

import './HDOnly.css';
import Icon from '../Icon/Icon';
import api from '../../utils/api';

export default class HDOnly extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      locked: true
    };

    this.updateHDOnly = this.updateHDOnly.bind(this);
  }

  async updateHDOnly () {
    const { station } = this.props;
    const { terminal, hdOnly } = station;

    if (!terminal) return null;

    this.setState({ locked: true });

    await api.patch(`stations/hd-only/${terminal}`, { hdOnly: !hdOnly });

    this.props.fetchStation();
  }

  render () {
    const { station, editable = true } = this.props;

    return (
      <div className='HDOnly'>
        {
          editable
            ? <div
              className='locked'
              onClick={() => this.setState(prevState => {
                return { locked: !prevState.locked };
              })}>
              <Icon color='black' name={this.state.locked ? 'lock-outline' : 'lock-open-outline'} size='18px' />
            </div>
            : null
        }

        <p
          onClick={editable && !this.state.locked ? this.updateHDOnly : null}
          className={`text ${station.hdOnly === true ? 'active' : ''} ${!this.state.locked ? 'unlocked' : ''}`}>
          HD Only
        </p>
      </div>
    );
  }
}
