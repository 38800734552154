import React from 'react';
import './SubscriptionsPage.css';
import { Route } from 'react-router-dom';

import PageWrapper from '../Page/PageWrapper';
import Section from '../Page/Section';
import PageTitle from '../Page/PageTitle';
// import Spinner from '../common/Spinner';
import api from '../../utils/api';
import { BalanceHistorySummary, FullBalanceHistory, TransactionDetails } from './BalanceHistory';
import CurrencyIcon from './CurrencyIcon';
import withPermissions from '../Permissions/withPermissions';

class SubscriptionsPage extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      balanceHistory: [],
      balanceHistoryIncomplete: true
    };

    this.loadBalance = this.loadBalance.bind(this);
    this.loadBalanceHistory = this.loadBalanceHistory.bind(this);
    this.loadMoreBalanceHistory = this.loadMoreBalanceHistory.bind(this);
  }

  componentDidMount () {
    this.loadBalance();
    this.loadBalanceHistory({ limit: 5 });
  }

  loadBalance () {
    api.getStripeBalance()
      .then(balanceObj => {
        this.setState({
          balanceObj,
          balanceRetrievedAt: Date.now()
        })
      })
      .catch(err => console.log(err));
  }

  loadBalanceHistory (options) {
    api.getStripeBalanceHistory(options)
      .then(transactions => {
        const { data, has_more } = transactions;

        this.setState({
          balanceHistory: [...this.state.balanceHistory, ...data],
          balanceHistoryIncomplete: has_more
        })
      })
  }

  loadMoreBalanceHistory () {
    const { balanceHistory } = this.state;
    const lastEntry = balanceHistory[balanceHistory.length - 1];
    const options = {
      limit: 100
    };

    if (lastEntry) {
      options.startingAfter = lastEntry.id;
    }
    console.log(options)
    this.loadBalanceHistory(options)
  }

  render () {
    const { balanceObj, balanceHistory, balanceHistoryIncomplete } = this.state;
    return (
      <div className='SubscriptionsPage'>
        <Route exact path='/subscriptions' render={props =>
          <SubscriptionsLanding
            balanceObj={balanceObj}
            balanceHistory={balanceHistory}
            balanceHistoryIncomplete={balanceHistoryIncomplete}

            {...props}
          />
        } />

        <Route exact path='/subscriptions/balance/history' render={props =>
          <FullBalanceHistory
            balanceHistory={balanceHistory}
            balanceHistoryIncomplete={balanceHistoryIncomplete}
            loadMoreBalanceHistory={this.loadMoreBalanceHistory}

            {...props}
          />
        } />

        <Route path='/subscriptions/balance/history/:transactionId' render={props =>
          <TransactionDetails
            {...props}
          />
        } />
      </div>
    );
  }
}

function SubscriptionsLanding (props) {
  const { balanceObj, balanceHistory, balanceHistoryIncomplete } = props;

  return (
    <PageWrapper>
      <PageTitle>Subscriptions</PageTitle>

      <Section loading={!balanceObj}>
        { balanceObj
          ? balanceObj.livemode
            ? <div className='isLiveBanner live'>LIVE ENVIRONMENT</div>
            : <div className='isLiveBanner test'>TEST ENVIRONMENT</div>
          : null
        }
      </Section>

      <Section title='Balance' loading={!balanceObj}>
        <Balances
          balanceObj={balanceObj}
        />
      </Section>

      <BalanceHistorySummary
        balanceHistory={balanceHistory}
        balanceHistoryIncomplete={balanceHistoryIncomplete}
      />
    </PageWrapper>
  );
}

function Balances (props) {
  const { balanceObj } = props;

  if (!balanceObj) {
    return <div style={{height: 100}} />;
  }

  const { available, pending } = balanceObj;

  return (
    <div className='Balances'>
      { (available || []).map(balance => <Balance key={balance.currency} balance={balance} />)}
      { (pending || []).map(balance => <Balance key={balance.currency} balance={balance} pending />)}
    </div>
  );
}

function Balance ({ balance, pending }) {
  const { amount, currency } = balance;

  if (!amount && pending) return null;

  const dollarValue = amount / 100;

  return (
    <div className='Balance'>
      <CurrencyIcon currency={currency} />

      <div className='body'>{!!pending &&
        <div className='pending'>ADDITIONAL PENDING</div>
      }</div>

      <div className='amount'>{ dollarValue % 1 === 0 ? dollarValue.toFixed(2) : dollarValue }</div>
    </div>
  );
}

export default withPermissions(SubscriptionsPage);
