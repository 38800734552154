import React from 'react';

import './MsgOptionBar.css';

export default function MsgOptionBar (props) {
  const { activeOption, options, onClick } = props;

  return (
    <div className='MsgOptionBar'>
      {
        options.map((option, i) => {
          return (
            <div
              key={`MsgOptionBar-${i}`}
              className={`option ${activeOption === option.id ? 'active' : ''}`}
              onClick={() => onClick(option.id)}>
              <p>{option.name}</p>
            </div>
          );
        })
      }
    </div>
  );
}
