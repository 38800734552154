import React, { CSSProperties } from 'react';
import moment from 'moment-timezone';

interface DateHeaderProps {
  style?: CSSProperties;
  compact?: boolean;
}

const MonthsOfYear: React.FC<DateHeaderProps> = ({
  style = {},
  children = [],
  compact = false
}) => {
  const months = moment[compact ? "monthsShort" : "months"]();

  return (
    <header className="MonthsOfYear" style={style}>
      {months.map((month, i) => {
        return <SpanLabel key={i}>{month}</SpanLabel>;
      })}
    </header>
  );
};

export { MonthsOfYear };

interface MonthOfYear extends DateHeaderProps {
  dateOfMonth: moment.Moment;
}

const MonthOfYear: React.FC<MonthOfYear> = ({
  dateOfMonth,
  style = {},
  compact = false
}) => {
  const months = moment[compact ? "monthsShort" : "months"]();

  return (
    <header className="MonthOfYear" style={style}>
      <SpanLabel>{months[dateOfMonth.month()]}</SpanLabel>
    </header>
  );
};
export { MonthOfYear };

const DaysOfWeek: React.FC<DateHeaderProps> = ({
  style = {},
  children = [],
  compact = false
}) => {
  const weekdays = moment[compact ? "weekdaysMin" : "weekdaysShort"]();
  return (
    <header className="DaysOfWeek" style={style}>
      {weekdays.map((day, i) => {
        return <SpanLabel key={i}>{day}</SpanLabel>;
      })}
    </header>
  );
};
export { DaysOfWeek };

interface SpanLabelProps {
  style?: CSSProperties;
  children: string;
  compact?: boolean;
  active?: boolean;
}

const SpanLabel: React.FC<SpanLabelProps> = ({
  style = {},
  children = "",
  compact = false,
  active = false
}) => {
  return (
    <div className="SpanLabel" style={style}>
      <p>{children}</p>
    </div>
  );
};
export { SpanLabel };