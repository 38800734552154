import React from 'react';
import pr from '@packageroute/types-firebase';
import WorkAreaEntry from './WorkAreaEntry';
import api from '../../utils/api';
import Refresh from '../Page/Refresh';
import Section from '../Page/Section';
import Notice, { Severity } from '../Page/Notice';
import FirebaseLink from '../common/FirebaseLink';
import {WorkAreasResponse} from '../../ConsoleApi.Types';
import './WorkAreas.css';

type Profile = pr.isp.profile.Profile;

type Props = {
  profile?: Profile | null;
  loadLatestProfile: () => void;
  openWorkArea: (workAreaId: string) => void;
  activeWorkArea?: string | null;
}

type State = {
  routeSummaries: WorkAreasResponse | null;
  refreshedAt: number | null;
  notice: null | { severity: Severity, title: string, description?: string };
  loading?: boolean;
}

export default class WorkAreas extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props);

    this.state = {
      routeSummaries: null,
      refreshedAt: null,
      notice: null,
    };

    this.fetchRouteData = this.fetchRouteData.bind(this);
    this.refreshRoutes = this.refreshRoutes.bind(this);
  }

  componentDidMount () {
    try {
      this.fetchRouteData();
    }
    catch (err) {
      console.log(err);
    }
  }

  async refreshRoutes () {
    try {
      this.setState({ loading: true });
      await this.props.loadLatestProfile();
      await this.fetchRouteData();

      this.setState({ loading: false });
    }
    catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  }

  async fetchRouteData () {
    const { profile } = this.props;
    const ISPID = (profile || {}).ISPID;


    if (!ISPID) return;

    try {
      const res = await api.request<WorkAreasResponse>(`isps/routes/${ISPID}`);

      this.setState({ refreshedAt: Date.now(), routeSummaries: res.data });
    }
    catch(err) {
      console.error(err);

      this.setState({ 
        refreshedAt: Date.now(), 
        routeSummaries: null, 
        notice: {
          severity: 'error',
          title: 'Failed to Load Route Data',
        }
      })
    }
  }

  render () {
    const { routeSummaries, loading, refreshedAt, notice } = this.state;
    const { profile, openWorkArea, activeWorkArea } = this.props;


    if (!profile) return null;

    return (
      <Section
        title={(
          <React.Fragment>
            Work Areas<FirebaseLink path={`ISP/${profile.ISPID}/Profile/WorkAreas`} />
          </React.Fragment>
        )}
        topContent={(
          <Refresh loading={loading} refresh={this.refreshRoutes} refreshedAt={refreshedAt} />
        )}
        empty={!profile}>
        {notice &&
          <Notice
            severity={notice.severity}
            title={notice.title}
            description={notice.description}
            clear={() => this.setState({ notice: null })}
          />
        }
        {
          (() => {
            const { paid, unpaid } = Object.keys(profile?.WorkAreas || {}).reduce<{ paid: JSX.Element[], unpaid: JSX.Element[] }>((acc, key) => {
              const workArea = profile.WorkAreas[key];
              const route = (routeSummaries || {})[key] || {};
              const { paid } = workArea;

              const entry = (
                <WorkAreaEntry
                  summary={route.summary}
                  workArea={workArea}
                  profile={profile}
                  key={key}
                  activeWorkArea={activeWorkArea}
                  openWorkArea={openWorkArea}
                />
              );

              if (paid) acc.paid.push(entry);
              else acc.unpaid.push(entry);

              return acc;
            }, { paid: [], unpaid: [] });

            return (
              <React.Fragment>
                { paid }
                { !!unpaid.length &&
                  <div className='workAreaDivider'>
                    <p>Unpaid</p>
                  </div>
                }
                { unpaid }
              </React.Fragment>
            )
          })()
        }
      </Section>
    );
  }
}
