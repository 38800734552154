import React from 'react';
import moment from 'moment-timezone';
import Icon from '../Icon/Icon';
import Spinner from '../common/Spinner';
import './Refresh.css';

type Props = {
  loading: boolean | null | undefined;
  refreshedAt: number | null | undefined;
  refresh: () => void;
}

export default function Refresh ({ loading, refreshedAt, refresh }: Props) {
  return (
    <div className='Refresh'>
      {
        refreshedAt && !loading
          ? <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '5px', fontSize: '12px', color: '#999' }}>
              Refreshed at {moment(refreshedAt).tz('America/Los_Angeles').format('HH:mm a')}
            </span>
          </div>
          : null
      }

      <div
        className={`action ${loading ? 'disabled' : ''}`}
        onClick={!loading ? refresh : undefined}>
        {
          loading
            ? <Spinner size={25} color='#999' />
            : <Icon name='refresh' size={25} color='#999' />
        }
      </div>
    </div>
  )
}
