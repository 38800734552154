import React from 'react';
import moment from 'moment';
import CalendarList from './CalendarList';
import TrialItem from './TrialItem';
import ScrollView from '../Page/ScrollView';
import {LiveIspProfile} from '@reducers/liveIspList';
import {TrialSubscription} from '@reducers/subscriptions/trials';
import {
  useLiveIspList,
  useTrialSubscriptions,
} from '@hooks/redux';

import './TrialWeek.css';

type DaysLeftEntry = {
  daysLeft: number;
  label: string;
  items: DaysLeftItem[];
}

type DaysLeftItem = {
  id: string | null;
  subscription: TrialSubscription;
  profile: LiveIspProfile;
  wasTouched: boolean;
  week: string;
}

type Props = {
  week: string;
  setFocusedISP: (ispId: string | null) => void;
  minDays: number;
  maxDays: number;
  trialingTags: any;
}

export default function TrialWeek ({
  week, 
  setFocusedISP, 
  minDays, 
  maxDays, 
  trialingTags,
}: Props) {
  const liveISPList = useLiveIspList();
  const trials = useTrialSubscriptions();

  const now = Date.now();

  if (!trials) return null;

  const thisWeeksTrials = trials.filter(subscription => {
    const { trial_end } = subscription;

    if (!trial_end) return false;

    const diff = moment(trial_end * 1000).tz('America/Los_Angeles').diff(moment(now).tz('America/Los_Angeles'), 'days');

    if (diff >= minDays && diff <= maxDays) {
      return true;
    }

    return false;
  });
  const totalValue = thisWeeksTrials.reduce((acc, subscription) => {
    const { quantity } = subscription;

    if (quantity == null) return acc;

    return acc += (quantity * 20);
  }, 0);
  const routes = thisWeeksTrials.reduce((acc, subscription) => {
    if (!subscription || !subscription.metadata) return acc;

    const { ISPID } = subscription.metadata;
    const profile = liveISPList[ISPID];

    if (!profile) return acc;

    for (const workAreaId in profile.WorkAreas) {
      if (profile.WorkAreas[workAreaId].paid === true) {
        acc.paid += 1;
      }

      acc.total += 1;
    }

    return acc;
  }, { paid: 0, total: 0 });

  return (
    <React.Fragment>
      <div className='cohort'>
        <p style={{ fontSize: '12px' }}>Trials ending between {moment.tz('America/Los_Angeles').add(minDays, 'days').format('MMM Do')} and {moment.tz('America/Los_Angeles').add(maxDays, 'days').format('MMM Do')}.</p>

        <p style={{ fontSize: '12px' }}>
          <span style={{ margin: '0 5px', color: 'rgb(22, 142, 50)' }}>${totalValue}</span>
          <span style={{ margin: '0 5px' }}>
            <span style={{ color: '#006DAB' }}>{routes.paid}</span>
            {` / `}
            <span>{routes.total}</span>
          </span>
          <span style={{ margin: '0 5px' }}>{thisWeeksTrials.length} ISP</span>
        </p>
      </div>

      <ScrollView direction='vertical' height='700px'>
        <CalendarList
          labelColor='#EA7D14'
          data={Object.values(thisWeeksTrials.reduce<Record<number, DaysLeftEntry>>((acc, subscription) => {
            const { trial_end, metadata } = subscription;
            const { ISPID } = metadata;
            const daysLeft = trial_end
              ? moment(trial_end * 1000).tz('America/Los_Angeles').diff(moment(now).tz('America/Los_Angeles'), 'days')
              : 0;

            if (!acc.hasOwnProperty(daysLeft)) {
              const label = `${daysLeft} days left`;

              acc[daysLeft] = {
                daysLeft: daysLeft,
                label,
                items: []
              };
            }

            if (!liveISPList || !liveISPList[ISPID]) return acc;

            acc[daysLeft].items.push({
              id: ISPID || null,
              subscription: subscription,
              profile: liveISPList[ISPID],
              wasTouched: trialingTags[ISPID] && trialingTags[ISPID].includes(week),
              week: week
            });

            return acc;
          }, {})).sort((a, b) => {
            return b.daysLeft - a.daysLeft;
          })}
          onItemClick={setFocusedISP}
          ListItem={TrialItem}
        />
      </ScrollView>
    </React.Fragment>
  );
}
