import React from "react";

import Map from "../Map/Map";
import WorkAreaMarker from "../Markers/WorkAreaMarker";

import "./UserProfile.css";

const blankUser = {
  Type: "",
  FedExId: "",
  customerId: "",
  email: "",
  phone: ""
};

const formatPhonenumber = phone => {
  phone = String(phone);
  if (phone.length === 10)
    return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`;
  else if (phone.length === 11)
    return `+${phone.slice(0, 1)} (${phone.slice(1, 4)}) ${phone.slice(
      4,
      7
    )}-${phone.slice(7)}`;
  else return phone;
};

const UserProfile = props => {
  const { Type, FedExId, email, phone, inviteCode, invitedBy } = props.user || blankUser;
  const { workAreas } = props;

  return (
    <div className="UserProfile">
      <div className="section">
        <div className="left info">
          <div className="data">
            <p>Type</p>
            <p>{Type || "N/A"}</p>
          </div>

          <div className="data">
            <p>FedExId</p>
            <p>{FedExId || "N/A"}</p>
          </div>

          <div className="data">
            <p>Phone</p>
            <p>{formatPhonenumber(phone) || "N/A"}</p>
          </div>

          <div className="data">
            <p>Email</p>
            <p>{email || "N/A"}</p>
          </div>

          <div className="data">
            <p>Invite Code</p>
            <p>{inviteCode || 'N/A'}</p>
          </div>

          <div className="data">
            <p>Referred By</p>
            <p>{invitedBy || 'N/A'}</p>
          </div>
        </div>

        <div className="right">
          {Object.keys(workAreas).length > 0 ? (
            <Map
              height="100%"
              width="100%"
              snapToChildren
              defaultCenter={(workAreas => {
                let latAcc = 0;
                let lngAcc = 0;
                let count = 0;

                for (const key in workAreas) {
                  const workArea = workAreas[key];

                  if (workArea.location) {
                    const { lat, lng } = workArea.location;
                    count++;
                    latAcc += lat;
                    lngAcc += lng;
                  }
                }

                const center = {
                  lat: count ? latAcc / count : 0,
                  lng: count ? lngAcc / count : 0
                };

                return center;
              })(workAreas)}
            >
              {(workAreas => {
                const result = [];
                for (const key in workAreas) {
                  const workArea = workAreas[key];
                  result.push(<WorkAreaMarker workArea={workArea} key={key} />);
                }

                return result;
              })(workAreas)}
            </Map>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
