import React from "react";
import Details from "./Details";
import Icon from "../../Icon/Icon";

import moment from "moment-timezone";


import "./Record.css";

export default class Record extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showDetails: false };
    
    this.toggleShowDetails = this.toggleShowDetails.bind(this);
  }

  toggleShowDetails() {
    this.setState({ showDetails: !this.state.showDetails });
  }

  render() {
    const { clickable, selected, pushKey, item, user } = this.props;
    return (
      <div className='Record' onClick={this.toggleShowDetails}>
        <div className="summary">
          <div
            className={`item ${clickable ? "clickable" : ""}`}
            onClick={
              clickable
                ? () =>
                    this.setState({
                      activeUserList: !selected ? pushKey : null
                    })
                : null
            }
          >
            <div className="status row">
              <Icon
                name={
                  item.success
                    ? "checkbox-marked-circle-outline"
                    : "close-circle-outline"
                }
                size={20}
                color={item.success ? "rgb(22, 142, 50)" : "rgb(185, 12, 15)"}
              />

              <p
                style={{
                  color: item.success ? "rgb(22, 142, 50)" : "rgb(185, 12, 15)",
                  marginLeft: "5px"
                }}
              >
                {item.success ? "Sent" : "Failed"}
              </p>
            </div>

            <div className="description col">
              <p>{item.messageName}</p>

              {user ? <p className="small">Sent by {user.firstName}</p> : null}
            </div>

            <div className="timestamp col">
              <p className="small">
                {moment(item.finishedAt)
                  .tz("America/Los_Angeles")
                  .format("MM/DD/YYYY hh:mm a")}
              </p>

              <p className="small">
                {moment(item.finishedAt).diff(moment(item.sentAt), "seconds")}s
              </p>
            </div>
          </div>
        </div>
        <Details display={this.state.showDetails} item={item}/>
      </div>
    );
  }
}
