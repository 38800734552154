import React from "react";
import moment from "moment-timezone";
import pr from '@packageroute/types-firebase';
import Stripe from 'stripe';
import Icon from "../Icon/Icon";
import DriversFraction from './DriversFraction';
import WorkAreasFraction from './WorkAreasFraction';
import { LiveIspProfile } from '@reducers/liveIspList';
import "./SignupInfo.css";

type Subscription = Stripe.subscriptions.ISubscription;

type Props = {
  profile?: LiveIspProfile;
  subscription: Subscription;
}

export default function SignupInfo(props: Props) {
  const { profile, subscription } = props;

  const ending = moment(subscription.trial_end! * 1000).calendar(null, {
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    nextWeek: "MMM Do",
    lastDay: "[Yesterday]",
    lastWeek: "MMM Do",
    sameElse: "MMM Do"
  });
  const alreadyEnded = moment(subscription.trial_end! * 1000).isBefore(moment());

  return (
    <div className="SignupInfo">
      <div className="info trial" style={{ minWidth: "50px" }}>
        <p style={{ color: "#EA7D14" }}>
          {subscription.trial_end ? (
            <span>
              {`Trial `}
              {alreadyEnded ? "ended " : "ends "}
              {ending}
            </span>
          ) : null}
        </p>
      </div>

      <div
        className="info credit"
        style={{ minWidth: "20px", justifyContent: "center" }}
      >
        {/* @ts-ignore */}
        {subscription.customer && subscription.customer.default_source ? (
          <Icon name="credit-card" size={14} color="#006DAB" />
        ) : null}
        {subscription.default_source ? (
          <Icon name="credit-card" size={14} color="#006DAB" />
        ) : null}
      </div>

      <div className="info subscriptionQty" style={{ minWidth: "35px" }}>
        <p style={{ color: "rgb(22, 142, 50)", margin: 0 }}>
          ${(subscription.quantity ?? 0) * 20}
        </p>
      </div>

      {!!profile &&
        <div className="info workAreas" style={{ minWidth: "70px" }}>
          <WorkAreasFraction profile={profile} />
        </div>
      }

      {!!profile && 
        <div
          className="info driversAndAuthorizedUsers"
          style={{ minWidth: "90px" }}
        >
          <DriversFraction profile={profile} />
        </div>
      }

      <div className="info subscribed" style={{ minWidth: "100px" }}>
        <p>{moment(subscription.created * 1000).format("MM/DD hh:mma")}</p>
      </div>
    </div>
  );
}
