import React from 'react';

import './CreateNotification.css';
import MessagingSteps from '../MessagingSteps';
import Send from '../Send/Send';
import Recipients from '../Recipients/Recipients';
import CreateMessage from './CreateMessage/CreateMessage';
import PreviewNotification from './PreviewNotification';
import shared from '../shared';

export default class CreateNotification extends React.Component {
  constructor (props) {
    super(props);

    const steps = [
      { id: 'MESSAGE', order: 1, name: 'Create Notification', icon: 'message-text' },
      { id: 'RECIPIENTS', order: 2, name: 'Recipients', icon: 'account-box' },
      { id: 'SEND', order: 3, name: 'Send', icon: 'send' }
    ];

    const draft = props.draft;

    this.state = {
      recipientsStepIdx: 1,
      steps: steps,
      activeStep: (draft && draft.activeStep) || steps[0],
      completedSteps: (draft && draft.completedSteps) || [],
      selectedCollections: (draft && draft.selectedCollections) || [],
      addRecipients: (draft && draft.addRecipients) || [],
      draft: (draft && draft.draftId) || null,
      syncedAt: (draft && draft.updatedAt) || null,
      notificationBody: null,
      notificationTitle: null,
      finished: false
    };

    this.setActiveStep = shared.setActiveStep.bind(this);
    this.markStepComplete = shared.markStepComplete.bind(this);
    this.unmarkStepComplete = shared.unmarkStepComplete.bind(this);
    this.toggleRecipient = shared.toggleRecipient.bind(this);
    this.toggleCollection = shared.toggleCollection.bind(this);
    this.validateRecipients = shared.validateRecipients.bind(this);
    this.combineRecipients = shared.combineRecipients.bind(this);
    this.markFinished = shared.markFinished.bind(this);
    this.updateNotification = this.updateNotification.bind(this);
    this.reset = this.reset.bind(this);
  }

  reset () {
    this.setState({
      activeStep: this.state.steps[0],
      completedSteps: [],
      selectedCollections: [],
      addRecipients: [],
      notificationBody: null,
      notificationTitle: null,
      externalLinkUrl: null,
      finished: false
    });
  }

  updateNotification (field, val) {
    this.setState({ [field]: val }, () => {
      if (this.state.notificationBody && this.state.notificationTitle) {
        this.markStepComplete(this.state.steps[0]);
      }
      else {
        this.unmarkStepComplete(this.state.steps[0]);
      }
    });
  }

  render () {
    const { activeStep, steps } = this.state;

    return (
      <div className='CreateNotification'>
        <MessagingSteps
          activeStep={this.state.activeStep}
          setActiveStep={this.setActiveStep}
          steps={this.state.steps}
          completedSteps={this.state.completedSteps}
          finished={this.state.finished}
          reset={this.reset}
        />

        <div className='body'>
          {
            activeStep.id === steps[0].id
              ? <React.Fragment>
                <PreviewNotification
                  title={this.state.notificationTitle}
                  body={this.state.notificationBody}
                />

                <CreateMessage
                  updateNotification={this.updateNotification}
                  notificationTitle={this.state.notificationTitle}
                  notificationBody={this.state.notificationBody}
                  externalLinkUrl={this.state.externalLinkUrl}
                />
              </React.Fragment>
              : null
          }

          {
            activeStep.id === steps[1].id
              ? <Recipients
                toggleCollection={this.toggleCollection}
                toggleRecipient={this.toggleRecipient}
                collections={this.props.collections}
                selectedCollections={this.state.selectedCollections}
                addRecipients={this.state.addRecipients}
                profiles={this.props.profiles}
                users={this.props.users}
                fetchUsers={this.props.fetchUsers}
              />
              : null
          }

          {
            activeStep.id === steps[2].id
              ? <React.Fragment>
                <PreviewNotification
                  title={this.state.notificationTitle}
                  body={this.state.notificationBody}
                />

                <Send
                  user={this.props.user}
                  historyPath='notificationHistory'
                  messageType='NOTIFICATION'
                  recipients={this.combineRecipients()}
                  messageName={this.state.notificationTitle}
                  messageSpecs={{
                    title: this.state.notificationTitle,
                    body: this.state.notificationBody,
                    data: {
                      externalLinkUrl: this.state.externalLinkUrl
                    }
                  }}
                  markFinished={this.markFinished}
                />
              </React.Fragment>
              : null
          }
        </div>
      </div>
    )
  }
}
