import { useAppSelector } from './store';
import { RootState } from '@reducers';

const selectColorMode = (state: RootState) => state.colorMode;
const selectColorPack = (state: RootState) => state.colorMode.colorPack;

export function useColorMode () {
  return useAppSelector(selectColorMode);
}

export function useColorPack () {
  return useAppSelector(selectColorPack);
}
