import React from 'react';
import './UploadTarget.css';

import Button from './Button';

export default class UploadTarget extends React.Component {
  constructor (props) {
    super(props);

    this.state = {};

    this.onDragOver = this.onDragOver.bind(this);
    this.setDrag = this.setDrag.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.chooseFile = this.chooseFile.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
  }

  onDrop (e) {
    e.preventDefault();

    this.setDrag(false);

    if (e.dataTransfer.items) {
      const files = [];

      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        if (e.dataTransfer.items[i].kind === 'file') {
          const file = e.dataTransfer.items[i].getAsFile();

          files.push(file);
        }
      }

      this.props.submitFiles(files);
    }
  }

  onDragOver (e) {
    e.preventDefault();

    this.setDrag(true);
  }

  setDrag (isHovering) {
    this.setState({ isHovering });
  }

  chooseFile (e) {
    e.preventDefault();
    this.fileInput.click();
  }

  onChangeFile (e) {
    const { files } = this.fileInput;
    const fileArr = [];

    if (!files.length) return;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      fileArr.push(file);
    }

    this.props.submitFiles(fileArr);
  }

  render () {
    const { isUploading, title, height, chooseMode } = this.props;
    const { isHovering } = this.state;

    if (chooseMode) {
      return (
        <div>
          <Button
            color='#006DAB'
            onClick={this.chooseFile} type='file'>
            Browse
          </Button>

          <input
            type='file'
            id='file'
            ref={ref => { this.fileInput = ref; }}
            style={{ display: 'none' }}
            onChange={this.onChangeFile}
            accept={this.props.acceptedFiles || '.md'}
          />
        </div>
      )
    }

    return (
      <form
        style={{ height: height || 400 }}
        className='UploadTarget'
        onSubmit={e => e.preventDefault()}
      >
        <div
          className='file-target'
          onDrop={isUploading ? () => {} : this.onDrop}
          onDragOver={this.onDragOver}
          onDragEnter={() => this.setDrag(true)}
          onDragLeave={() => this.setDrag(false)}
          onDragEnd={() => this.setDrag(false)}
        >
          {
            isUploading
              ? <div>
                <h2>Uploading file(s)...</h2>
              </div>
              : <div className={isHovering ? 'hovering' : ''}>
                <i className='mdi mdi-cloud-upload upload-icon' />

                { !isHovering && <h2>{ title }</h2>}

                { !isHovering && <Button color='#006DAB' onClick={this.chooseFile} type='file'>Browse</Button> }

                <input
                  type='file'
                  id='file'
                  ref={ref => { this.fileInput = ref; }}
                  style={{ display: 'none' }}
                  onChange={this.onChangeFile}
                  accept='.md'
                />
              </div>
          }
        </div>
      </form>
    );
  }
}
