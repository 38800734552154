import React from 'react';
import './RelatedOperations.css';
import FirebaseLink from '../common/FirebaseLink';
import Section from '../Page/Section';
import IspEntry from '../Search/IspEntry';
import api from '../../utils/api';

export default class RelatedOperations extends React.Component {
  constructor (props) {
    super(props);

    this.state = {};

    this.load = this.load.bind(this);
  }

  componentDidMount () {
    if (this.props.subscriptionData && this.props.liveISPList) {
      this.load(this.props.subscriptionData, this.props.liveISPList);
    }
  }

  componentWillUpdate(newProps) {
    if (!(this.props.subscriptionData && this.props.liveISPList) && (newProps.subscriptionData && newProps.liveISPList)) {
      this.load(newProps.subscriptionData, newProps.liveISPList)
    }
  }

  async load (subscriptionData, liveISPList) {
    const { activeIsp } = this.props;
    const { subscription } = (subscriptionData || {});
    const { customer } = (subscription || {});
    const { subscriptions } = (customer || {});
    const { data } = (subscriptions || {});

    const relatedSubs = (data || []).filter(op => op.metadata.ISPID !== activeIsp);

    const relatedOps = relatedSubs.map(sub => {
      const { ISPID } = sub.metadata;

      if (!liveISPList || !liveISPList[ISPID]) {
        return { ISPID, partial: true };
      } else {
        return liveISPList[ISPID];
      }
    });

    const updatedRelatedOps = relatedOps.map(async (op) => {
      const operation = {...op};

      const crmEntry = await api.getCrmIdForFirebaseId(operation.ISPID);

      const { id } = (crmEntry || { id: 'notAssociated' });

      operation.crmId = id;

      return operation;
    })

    const operationsWithCrmId = await Promise.all(updatedRelatedOps);

    this.setState({
      relatedOps: operationsWithCrmId,
    })
  }

  render () {
    const { liveISPList, history } = this.props;
    const { relatedOps } = this.state;

    if (!relatedOps || !relatedOps.length) return null;

    return (
      <div className='RelatedOperations'>
        <Section title='Related Operations'>
          {
            relatedOps.map(isp => {
              const { ISPID, partial } = isp;

              if (partial) {
                return (
                  <div className='unkownRelatedOp'>{ISPID} <FirebaseLink path={`ISP/${ISPID}`} /></div>
                );
              } else {
                const ispProfile = liveISPList[ISPID];
                return (
                  <IspEntry
                    history={history}
                    key={`isp ${ISPID}`}
                    isp={{}}
                    to={`/isps/${isp.crmId}`}
                    profile={ispProfile}
                  />
                );
              }
            })
          }
        </Section>
      </div>
    );
  }
}
