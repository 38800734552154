import React from "react";
import { 
  GlobalState, 
  FirebaseFeatureFlag,
  FirebaseFeatureFlags 
} from '@packageroute/biz/lib/featureFlags/FeatureFlagsAdmin';
import pr from '@packageroute/types-firebase';
import PageWrapper from "../Page/PageWrapper";
import PageTitle from "../Page/PageTitle";
import Section from '../Page/Section';
import Button from '../Page/Button';
import InputText from '../Page/InputText';
import Spinner from '../common/Spinner';
import withPermissions from '../Permissions/withPermissions';
import OnOff from '../ServiceManagement/Buttons/OnOff';
import MultiToggle from '../ServiceManagement/Buttons/MultiToggle';
import api from '../../utils/api';
import "./FeatureFlags.css";

type Profile = pr.isp.profile.Profile;
type LiveISPList = Record<string, Profile>

type FeatureFlagDescription = {
  featureKey: string; 
  name: string;
}

type Props = {
  liveISPList: LiveISPList;
}

type State = {
  loading: boolean;
  featureFlags: FirebaseFeatureFlags;
  creating: boolean;
  errorText: string;
}

class FeatureFlags extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      featureFlags: {},
      errorText: '',
      loading: true,
      creating: false,
    }

    this.loadFeatureFlags = this.loadFeatureFlags.bind(this);
    this.toggleCreating = this.toggleCreating.bind(this);
    this.createFeatureFlag = this.createFeatureFlag.bind(this);
    this.renameFeatureFlag = this.renameFeatureFlag.bind(this);
    this.deleteFeatureFlag = this.deleteFeatureFlag.bind(this);
    this.setFeatureFlagState = this.setFeatureFlagState.bind(this);
    this.setOperationState = this.setOperationState.bind(this);
  }

  componentDidMount () {
    this.loadFeatureFlags();
  }

  loadFeatureFlags (): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        this.setState({ loading: true }, () => {
          api.getFeatureFlags()
          .then(featureFlags => {
            this.setState({ 
              featureFlags: featureFlags || {}, 
              loading: false 
            }, () => resolve())
          })
          .catch((err) => {
            console.warn('Unable to load feature flags', err);
            this.setState({ 
              loading: false, 
              errorText: 'Unable to load feature flags' 
            }, () => reject());
          })
        })
      }

      catch (err) {
        reject(err);
      }
    })
  }

  toggleCreating () {
    this.setState({ creating: !this.state.creating });
  }

  createFeatureFlag ({ featureKey, name }: FeatureFlagDescription) {
    api.createFeatureFlag(featureKey, name)
    .then(() => {
      this.loadFeatureFlags()
      .then(() => this.toggleCreating());
    })
    .catch(() => this.setState({ errorText: 'Unable to create feature flag' }));
  }

  deleteFeatureFlag (featureKey: string) {
    api.deleteFeatureFlag(featureKey)
    .then(() => {
      this.loadFeatureFlags()
    })
    .catch(() => this.setState({ errorText: 'Unable to delete feature flag' }));
  }

  renameFeatureFlag ({ featureKey, name }: FeatureFlagDescription) {
    api.changeFeatureFlagName(featureKey, name)
    .then(() => this.loadFeatureFlags())
    .catch(() => this.setState({ errorText: 'Unable to rename feature flag' }));
  }

  setFeatureFlagState (featureKey: string, state: GlobalState) {
    api.changeFeatureFlagGlobalState(featureKey, state)
    .then(() => this.loadFeatureFlags())
    .catch(() => this.setState({ errorText: 'Unable to change feature flag state' }));
  }

  setOperationState (featureKey: string, operationId: string, enabled: boolean) {
    api.changeFeatureFlagOperationState(featureKey, operationId, enabled)
    .then(() => this.loadFeatureFlags())
    .catch(() => this.setState({ errorText: 'Unable to change operation state' }));
  }

  render() {
    const { featureFlags, errorText, loading, creating } = this.state;
    const { liveISPList } = this.props;

    const noFeatureFlagsCreated = !Object.keys(featureFlags).length;

    return (
      <div className="FeatureFlags">
        <PageWrapper>
          <PageTitle 
            secondTitle='DEV ONLY'
            rightContent={<CreateButton onClick={this.toggleCreating} style={{ marginRight: 0 }} />}
          >Feature Flags</PageTitle>
            {
              !!errorText &&
              <div className='error-text-wrap'>
                <p>{`Error: ${errorText}`}</p>
              </div>
            }

            {
              loading &&
              <div className='loading-wrap'>
                <Spinner color='#bbb' size={40} />
              </div>
            }

            {
              (creating || (!loading && noFeatureFlagsCreated)) &&
              <Section title='Create New Feature Flag'>
                <div className='edit-form-wrap'>
                  <EditForm onCreate={this.createFeatureFlag} cancel={this.toggleCreating} />
                </div>
              </Section>
            }

            {
              Object.keys(featureFlags).sort().map(featureKey => {
                const featureFlag = featureFlags[featureKey];
                
                return (
                  <FeatureFlagController 
                    key={featureKey}
                    featureKey={featureKey} 
                    featureFlag={featureFlag} 
                    liveISPList={liveISPList}  
                    loadFeatureFlags={this.loadFeatureFlags} 
                    renameFeatureFlag={this.renameFeatureFlag}
                    deleteFeatureFlag={this.deleteFeatureFlag}
                    setFeatureFlagState={this.setFeatureFlagState}
                    setOperationState={this.setOperationState}
                  />
                )
              })
            }
        </PageWrapper>
      </div>
    );
  }
}

type FeatureFlagControllerProps = {
  featureKey: string;
  featureFlag: FirebaseFeatureFlag;
  liveISPList: LiveISPList;
  loadFeatureFlags: () => void;
  setFeatureFlagState: (featureKey: string, state: GlobalState) => void;
  renameFeatureFlag: (flag: FeatureFlagDescription) => void;
  deleteFeatureFlag: (key: string) => void;
  setOperationState: (featureKey: string, operationId: string, enabled: boolean) => void;
}

type FeatureFlagControllerState = {
  showOperations: boolean;
  loading: boolean;
  editing: boolean;
}

class FeatureFlagController extends React.Component<FeatureFlagControllerProps, FeatureFlagControllerState> {
  constructor (props: FeatureFlagControllerProps) {
    super(props);

    this.state = {
      loading: false,
      editing: false,
      showOperations: props.featureFlag.global === 'per_operation',
    }

    this.toggleEditing = this.toggleEditing.bind(this);
    this.toggleShowOperations = this.toggleShowOperations.bind(this);
  }

  toggleEditing () {
    const { editing } = this.state;
    console.log('EDITING', editing);
    this.setState({ editing: !editing });
  }

  toggleShowOperations () {
    const { showOperations } = this.state;

    this.setState({ showOperations: !showOperations });
  }

  render () {
    const { featureKey, featureFlag, liveISPList, setFeatureFlagState, renameFeatureFlag, deleteFeatureFlag, setOperationState } = this.props;
    const { loading, editing, showOperations  } = this.state;
    const { name, global, operations = {} } = featureFlag;

    const hasOperations = !!Object.keys(operations).length;

    return (
      <Section 
        key={featureKey}
        className='FeatureFlagController'
        title={featureKey} 
        subtitle={name} 
        loading={loading} 
      > 
        <div className='button-wrap'>
          <MultiToggle
            toggles={[
              {
                key: 'all',
                label: hasOperations ? 'All' : 'On',
                color: '#0477B4',
                onClick: () => setFeatureFlagState(featureKey, 'all')
              },
              !!(hasOperations || global === 'per_operation')
              ? {
                  key: 'per_operation',
                  label: 'ISP',
                  color: '#178E32',
                  onClick: () => setFeatureFlagState(featureKey, 'per_operation')
                }
              : null,
              {
                key: 'none',
                label: hasOperations ? 'None' : 'Off',
                color: '#B90C0E',
                onClick: () => setFeatureFlagState(featureKey, 'none')
              },
            ]}
            activeKey={global}
          />
          <div className='edit-buttons-wrap'>
            {
              !(showOperations) && !(hasOperations && global === 'per_operation') &&
              <Button 
                color='#0477B4' 
                onClick={this.toggleShowOperations} 
              >
                {hasOperations ? "Show ISPS" : "Add ISPS"}
              </Button>
            }
            <EditButton onClick={this.toggleEditing} />
          </div>
        </div>

        {
          editing &&
          <div className='edit-form-wrap'>
            <EditForm 
              key={featureKey}
              editing 
              featureKey={featureKey} 
              name={name} 
              onEdit={(edits) => {
                renameFeatureFlag(edits);
                this.toggleEditing();
              }}
              delete={() => deleteFeatureFlag(featureKey)} 
            />
          </div>
        }
        
        {
          ((hasOperations && global === 'per_operation') || showOperations) &&
          <div>
            <FeatureFlagOperationsList 
              featureKey={featureKey}
              operations={operations} 
              liveISPList={liveISPList} 
              setOperationState={setOperationState}
            />
          </div>
        }
      </Section>
    )
  }
}

type EditingModeProps = {
  editing: true;
  delete: () => void;
  onEdit: (feature: { featureKey: string, name: string }) => void;
  onCreate?: never | ((feature: { featureKey: string, name: string }) => void);
}

type CreateModeProps = {
  editing?: never | false;
  onEdit?: never | ((feature: { featureKey: string, name: string }) => void);
  onCreate: (feature: { featureKey: string, name: string }) => void;
}

type EditFormProps = {
  featureKey?: string;
  name?: string;
  editing?: boolean;
  cancel?: () => void;
} & (CreateModeProps | EditingModeProps)

type EditFormState = {
  featureKey: string;
  name: string;
  errorText: string;
}

class EditForm extends React.Component<EditFormProps, EditFormState> {
  constructor (props: EditFormProps) {
    super(props);

    this.state = { 
      featureKey: props.featureKey || '',
      name: props.name || '',
      errorText: '',
    }

    this.submitChanges = this.submitChanges.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  submitChanges () {
    const { editing, onEdit, onCreate } = this.props;
    const { featureKey, name } = this.state;
    
    const valid = this.validate();

    if (valid) {
      if (editing) onEdit?.({ featureKey, name });
      else onCreate?.({ name, featureKey });
    }
  }

  hasInvalidCharacters (str: string, noSpaces: boolean = false) {
    const invalidCharacters = noSpaces 
      ? /[.,#$\[\]\/ ]/gi 
      : /[.,#$\[\]\/]/gi; 

    return invalidCharacters.test(str);
  }

  validate () {
    const { featureKey, name } = this.state;

    if (!featureKey) {
      this.setState({ errorText: 'Missing feature key' })
      return false;
    } else if (this.hasInvalidCharacters(featureKey, true)) {
      this.setState({ errorText: "Feature key contains invalid characters: .,#$[]/ or a space"})
      return false;
    }

    if (!name) {
      this.setState({ errorText: 'Missing name' })
      return false;
    }else if (this.hasInvalidCharacters(featureKey)) {
      this.setState({ errorText: "Name contains invalid characters: .,#$[]/"})
      return false;
    }

    return true;
  }

  handleChange (changeEvent: React.ChangeEvent<{ name: string, value: string }>) {
    const { target } = changeEvent;
    const { name, value } = target;

    // @ts-ignore
    this.setState({ [name]: value });
  }

  render () {
    const { editing } = this.props;
    const { featureKey, name, errorText } = this.state;

    return (
      <div 
        className='FeatureFlagEditForm'
      > 
        {
          editing && 
          <div className='delete-button-wrap'>
            <Button onClick={this.props.delete} color='#B90C0E' style={{ maxHeight: 40, marginLeft: 10, marginRight: 10 }}>
              Delete
            </Button>
          </div>
        }

        {
          !!errorText &&
          <div className='error-text-wrap'>
            <p>{`Error: ${errorText}`}</p>
          </div>
        }

        <div className='input-wrap'>
          <InputText
            label='Feature Key'
            name='featureKey'
            value={featureKey}
            onChange={this.handleChange}
            disabled={editing}
            style={{ paddingRight: 10 }}
          />

          <InputText
            label='Name'
            name='name'
            value={name}
            onChange={this.handleChange}
            style={{ paddingRight: 10 }}
          />
        </div>

        <div className='button-wrap'>
          {this.props.cancel &&
            <Button onClick={this.props.cancel} color='#B90C0E' style={{ maxHeight: 40, marginLeft: 10, marginRight: 10 }}>
              Cancel
            </Button>
          }
          <Button onClick={this.submitChanges} color='#178E32' style={{ maxHeight: 40, marginLeft: 10, marginRight: 10 }}>
            {editing ? 'Save Changes' : 'Create'}
          </Button>
        </div>
      </div>
    )
  }
}

type FeatureFlagsOperationsListProps = {
  operations: Record<string, boolean>;
  liveISPList: Record<string, Profile>;
  featureKey: string;
  setOperationState: (featureKey: string, operationId: string, enabled: boolean) => void;
}

type FeatureFlagsOperationsListState = {
  searchText: string;
}

class FeatureFlagOperationsList extends React.Component<FeatureFlagsOperationsListProps, FeatureFlagsOperationsListState> {
  constructor (props: FeatureFlagsOperationsListProps) {
    super(props);

    this.state = {
      searchText: ''
    }

    this.handleSearch = this.handleSearch.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
  }

  handleSearch (changeEvent: { target: { value: string }}) {
    const { target } = changeEvent;
    const { value } = target;

    this.setState({ searchText: value });
  }

  clearSearch () {
    this.setState({ searchText: '' });
  }

  getRegisteredOperations () {
    const { operations = {}, liveISPList = {} } = this.props;

    const operationIds = Object.keys(operations);

    return operationIds.map(operationId => liveISPList[operationId])
  }

  getAllOperations () {
    const { liveISPList = {} } = this.props;

    return this.filterOperations(Object.values(liveISPList));
  }

  filterOperations (mappedOperations: Profile[]) {
    const { searchText } = this.state;

    return mappedOperations.filter(operation => {
      const { CSPName, station, contactId } = operation;
      const { stationID } = station || {};
      const matcher = new RegExp(searchText, 'gi');

      if (matcher.test(CSPName)) return true;
      if (matcher.test(stationID)) return true;
      if (matcher.test(contactId)) return true;
      return false;
    })
  }

  sortOperations (mappedOperations: Profile[]) {
    return mappedOperations.sort((a, b) => {
      const { CSPName: aName } = a;
      const { CSPName: bName } = b;

      return aName < bName ? -1 : 1;
    })
  }

  renderOperations () {
    const { searchText } = this.state;
    const { setOperationState, featureKey } = this.props;

    const source = searchText
      ? this.getAllOperations()
      : this.getRegisteredOperations();
    
    const sorted = this.sortOperations(source);

    return sorted.map(operation => {
      if (!operation) return null;

      const { ISPID } = operation;

      const enabled = this.props.operations[ISPID];

      return (
        <FeatureFlagOperation 
          operation={operation} 
          enabled={!!enabled} 
          onClick={(enabled) => setOperationState(featureKey, ISPID, enabled)}   
        />
      )
    })
  }

  render () {
    const { liveISPList } = this.props;

    const liveISPListLoaded = !!Object.keys(liveISPList || {}).length;

    return (
      <div className='FeatureFlagOperationsList'>
        <div className='search-input-wrap'>
          <InputText
            value={this.state.searchText}
            label='Search'
            placeholder='Search by ISP name, station id, or FedEx id'
            onChange={this.handleSearch}
          />

          <Button color='#B90C0E' style={{ minHeight: 42 }} onClick={this.clearSearch}>Clear</Button>
        </div>
        <div className='scroll-list'>
          {
            liveISPListLoaded
            ? this.renderOperations()
            : <div className='operations-loading-wrap'>
                <Spinner color='#bbb' size={40} />
              </div>
          }
        </div>
      </div>
    )
  }
}

type FeatureFlagOperationProps = {
  operation: Profile;
  enabled: boolean;
  onClick: (enabled: boolean) => void;
}

function FeatureFlagOperation ({ operation, enabled, onClick }: FeatureFlagOperationProps) {
  const { CSPName, station } = operation;
  const { stationID } = station ?? { stationID: 'Unknown station' };
  return (
    <div className='FeatureFlagOperation' style={{ borderTop: 'solid 1px #ddd' }}>
      <div className='name-wrap'>
        <p style={{ marginRight: 10 }}>{CSPName}</p>
        <p>{stationID}</p>
      </div>
      <OnOff
        on={!!enabled}
        turnOn={() => onClick(true)}
        turnOff={() => onClick(false)}
        disabled={false}
      />
    </div>
  )
}

type EditButtonProps = {
  onClick: () => void;
  style?: React.CSSProperties;
}

function EditButton ({ onClick, style}: EditButtonProps) {
  return (
    <Button style={{ backgroundColor: '#178E32', ...style }} onClick={onClick}>
      Edit
    </Button>
  )
}


type CreateButtonProps = {
  onClick: () => void;
  style?: React.CSSProperties;
}

function CreateButton ({ onClick, style }: CreateButtonProps) {
  return (
    <Button style={{ backgroundColor: '#0477B4', ...style }} onClick={onClick}>
      Add Feature Flag
    </Button>
  )
}



export default withPermissions(FeatureFlags);
