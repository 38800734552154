import React from 'react';
import './StationEntry.css';
// import { Link } from 'react-router-dom';

import Button from '../Page/Button';
import Icon from '../Icon/Icon';
import HDOnly from '../Station/HDOnly';
import CoLocation from '../Station/CoLocation';

export default function StationEntry ({ station, matches, onClick, deselect, selected }) {
  return (
    <div className='StationEntry' onClick={onClick}>
      <div className='iconWrap'>
        <Icon name='city' size={30} color='#006DAB' />
      </div>

      <h3 className={`id${matches && matches.terminal ? ' match' : ''}`}>{ station.terminal }</h3>

      <h3 className={`city${matches && matches.city ? ' match' : ''}`}>
        { station.city }
      </h3>

      <h3>, </h3>

      <h3 className={`state${matches && matches.state ? ' match' : ''}`}>
        { station.state }
      </h3>

      <div style={{ flex: 1 }} />

      <CoLocation station={station} editable={false} />

      <HDOnly station={station} editable={false} />

      {
        selected === true
          ? <Button
            onClick={(e) => {
              e.preventDefault();
              deselect(station);
            }}
            style={{ backgroundColor: 'red' }}>
            Remove
          </Button>
          : null
      }
    </div>
  );
}
