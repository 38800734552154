import moment from 'moment';
import {DayOfWeek} from '@reporting/Reporting.Types';

// Defining DATE_KEY locally, as it isn't accessible
// when running tests.
const DATE_KEY = 'YYYY-MM-DD';

export function getDateRangeData(startDate: string, endDate: string) {
  const dates = [];
  const dateIndexes: Record<string, number> = {};

  const current = moment(startDate, window.DATE_KEY);
  const end = moment(endDate, window.DATE_KEY);

  while (current.isSameOrBefore(end)) {
    const date = current.format(window.DATE_KEY);

    dates.push(date);
    dateIndexes[date] = dates.length - 1;

    current.add(1, 'day');
  }

  return {dates, dateIndexes};
}

/**
 * Creates array of dates inclusive of end date
 * @param startDate string
 * @param endDate string
 * @returns string[]
 */
export function enumerateDateRange(startDate: string, endDate: string) {
  const days: string[] = [];

  const currentMoment = moment(startDate, window.DATE_KEY);
  const endMoment = moment(endDate, window.DATE_KEY);

  while (currentMoment.isSameOrBefore(endMoment)) {
    days.push(currentMoment.format(window.DATE_KEY));

    currentMoment.add(1, 'day');
  }

  return days;
}

/**
 * Get date range of equal length which precedes start and end dates
 * 
 * Start days should be same day of week as original start day.
 * End days should be same day of week as original end day.
 * Range length should be equal.
 */
export function getPrecedingDateRange(
  startDate: string,
  endDate: string,
): {startDate: string; endDate: string} {
  const startMoment = moment(startDate, DATE_KEY);
  const endMoment = moment(endDate, DATE_KEY);

  const startDayOfWeek = startMoment.day();
  const endDayOfWeek = endMoment.day();

  const rangeLength = endMoment.diff(startMoment, 'days');
  const previousWeekNewEndDateOffSet = (7 - endDayOfWeek) + startDayOfWeek;
  const sameWeekNewEndDateOffSet = (0 - endDayOfWeek) + startDayOfWeek;

  const previousWeekNewEndMoment = startMoment.clone().subtract(previousWeekNewEndDateOffSet, 'days');
  const sameWeekNewEndMoment = startMoment.clone().subtract(sameWeekNewEndDateOffSet, 'days');
  const newEndMoment = sameWeekNewEndMoment.isBefore(startMoment, 'day')
    ? sameWeekNewEndMoment
    : previousWeekNewEndMoment;
  const newStartMoment = newEndMoment.clone().subtract(rangeLength, 'days');

  const newStartDate = newStartMoment.format(DATE_KEY);
  const newEndDate = newEndMoment.format(DATE_KEY);

  return {
    startDate: newStartDate,
    endDate: newEndDate,
  };
}

/**
 * Ensure that date range is complete set of weeks.
 *
 * If date range begins/ends during a week rather than at the start and end of
 * a week, then capturing complete sets of labor data is impossible as overtime
 * hours cannot be correctly calculated.
 */
export function getFullWeeklyPeriods(
  startDate: string,
  endDate: string,
  weeklyStartDay: DayOfWeek,
): {startDate: string; endDate: string} {
  const startMoment = moment(startDate, window.DATE_KEY);
  const endMoment = moment(endDate, window.DATE_KEY);

  let weeklyStartMoment = startMoment.clone().day(weeklyStartDay);
  let weeklyEndMoment = endMoment
    .clone()
    .day(weeklyStartDay)
    .subtract(1, 'day');

  if (weeklyStartMoment.isAfter(startMoment))
    weeklyStartMoment = weeklyStartMoment.subtract(1, 'week');
  if (weeklyEndMoment.isBefore(endMoment))
    weeklyEndMoment = weeklyEndMoment.add(1, 'week');

  const newStartDate = weeklyStartMoment.format(window.DATE_KEY);
  const newEndDate = weeklyEndMoment.format(window.DATE_KEY);

  return {
    startDate: newStartDate,
    endDate: newEndDate,
  };
}

/**
 * Segments date range into arrays of 7 days.
 *
 * Ensures that date range is composed of whole weeks, even if start and end dates
 * are not at start and end of week.
 */
export function getWeeklySegments(
  startDate: string,
  endDate: string,
  weeklyStartDay: DayOfWeek,
) {
  const {startDate: weeklyStartDate, endDate: weeklyEndDate} =
    getFullWeeklyPeriods(startDate, endDate, weeklyStartDay);

  let days = enumerateDateRange(weeklyStartDate, weeklyEndDate);
  const weeks: string[][] = [];

  if (days.length % 7 !== 0) {
    console.log(days);
    throw Error('Invalid dates range');
  }

  const DAYS_IN_WEEK = 7;
  while (days.length) {
    weeks.push(days.splice(0, DAYS_IN_WEEK));
  }

  return weeks;
}
