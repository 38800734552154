import React from 'react';

import './Notifications.css';
import MsgNav from '../MsgNav';
import CreateNotification from './CreateNotification';
import Dash from '../Dash/Dash';
import firebaseProvider from '../../../utils/firebase';

export default class Notifications extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      mode: 'DASH',
      notificationsHistory: {}
    };

    this.setMode = this.setMode.bind(this);
    this.setHistoryListener = this.setHistoryListener.bind(this);
  }

  componentDidMount () {
    this.setHistoryListener();
  }

  componentWillUnmount () {
    const consoleDB = firebaseProvider.getConsoleDefaultDB();
    const HistoryRef = consoleDB.ref('notificationHistory');

    HistoryRef.off();

    for (const pushKey in (this.state.notificationsHistory || {})) {
      HistoryRef.child(pushKey).off();
    }
  }

  setMode (mode) {
    this.setState({ mode: mode });
  }

  setHistoryListener () {
    const consoleDB = firebaseProvider.getConsoleDefaultDB();
    const HistoryRef = consoleDB.ref(`notificationHistory`);

    HistoryRef
      .orderByChild('finishedAt')
      .limitToLast(100)
      .on('child_added', snap => {
        HistoryRef.child(snap.key).on('value', snap => {
          this.setState(prevState => {
            return {
              notificationsHistory: {
                ...prevState.notificationsHistory,
                [snap.key]: snap.val()
              }
            };
          });
        });

        this.setState(prevState => {
          return {
            notificationsHistory: {
              ...prevState.notificationsHistory,
              [snap.key]: snap.val()
            }
          }
        });
      });
  }

  render () {
    return (
      <div className='messenger'>
        {
          this.state.mode === 'DASH'
            ? <Dash
              title='Console Notifications History'
              consoleUsers={this.props.consoleUsers}
              history={this.state.notificationsHistory}
            />
            : null
        }

        {
          this.state.mode === 'CREATE'
            ? <CreateNotification
              user={this.props.user}
              collections={this.props.collections}
              profiles={this.props.profiles}
              users={this.props.users}
              fetchUsers={this.props.fetchUsers}
            />
            : null
        }

        <MsgNav
          opts={[
            { icon: 'speedometer', mode: 'DASH' },
            { icon: 'send', mode: 'CREATE' }
          ]}
          onClickOpt={this.setMode}
          mode={this.state.mode}
        />
      </div>
    );
  }
}
