import React from 'react';
import config from '../../config.json';
import './FirebaseLink.css';

export default function FirebaseLink ({ path }) {
  const url = config.productFirebaseDatabaseUrl + path;

  return (
    <a
      href={url}
      className='FirebaseLink'
      target='_blank'
      rel="noreferrer"
    >
			<i className='mdi mdi-database-search' />
		</a>
	);
}
