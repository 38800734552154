import { Dispatch } from "@reduxjs/toolkit";
import {
  updateFirebaseUser,
  resetFirebaseUser,
  FirebaseUser,
} from "@reducers/firebaseUser";

export async function setFirebaseUser(dispatch: Dispatch, firebaseUser: FirebaseUser) {
  try {
    dispatch(updateFirebaseUser({ firebaseUser }));
  } catch (err) {
    console.error(err);
  }
}

export async function clearFirebaseUser (dispatch: Dispatch) {
  try {
    dispatch(resetFirebaseUser());
  } catch (err) {
    console.error(err);

    throw err;
  }
}
