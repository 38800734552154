import React from 'react';
import './SearchInput.css';
import Section from '../Page/Section';

type Option = {
  label: string;
  key: string;
  enabled?: boolean;
}

type TSearchOption = {
  enabled?: boolean;
  options: Option[];
}

type TSearchOptions = Record<string, TSearchOption>;

type WithSearchOptions = {
  showOptions: true;
  searchOptions: TSearchOptions;
  toggleOption: (setKey: string, key: string) => void;
}

type WithoutSearchOptions = {
  showOptions?: never | false;
  searchOptions?: never;
  toggleOption?: never;
}

type Props = {
  value?: string | number;
  placeholder?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  sectionTitle?: string;
} & (WithSearchOptions | WithoutSearchOptions);

export default function SearchInput (props: Props) {
  const { value, placeholder, onChange, sectionTitle, showOptions, searchOptions, toggleOption } = props;

  return (
    <div className='SearchInput'>
      
      <Section
        title={sectionTitle}
        style={{ marginBottom: value ? 0 : 30 }}
        topContent={!!(showOptions && searchOptions && toggleOption)
          ? <SearchOptions 
              searchOptions={searchOptions} 
              toggleOption={toggleOption} 
            />
          : null
        }
      >
        <input
          type='text'
          value={value}
          onChange={onChange}
          placeholder={placeholder || 'Enter search terms...'}
        />
      </Section>
    </div>
  );
}

type SearchOptionsProps = {
  searchOptions: TSearchOptions;
  toggleOption: (setKey: string, key: string) => void;
}

function SearchOptions ({ searchOptions = {}, toggleOption }: SearchOptionsProps) {
  return (
    <div className='SearchOptions'>
      {
        Object.keys(searchOptions).reduce<JSX.Element[]>((acc, setkey) => {
          const { enabled, options } = searchOptions[setkey];

          if (!enabled) return acc;
          else if (!options) return acc;
          else {
            const renderedOptions = options.map((option, i) => {
              return (
                <SearchOption 
                  key={`${setkey}_${i}`} 
                  setkey={setkey} 
                  option={option} 
                  toggleOption={toggleOption} 
                />
              );
            });

            return [...acc, ...renderedOptions];
          }
        }, [])
        
      }
    </div>
  )
}

type SearchOptionProps = {
  setkey: string;
  option: Option;
  toggleOption: (setKey: string, key: string) => void;
}

function SearchOption ({ setkey, option, toggleOption }: SearchOptionProps) {
  const { label, key, enabled, } = option;
  return (
    <div 
      className={'SearchOption' + (enabled ? ' enabled' : '')} 
      onClick={() => toggleOption(setkey, key)}
    >
      <p>{label}</p>
    </div>
  )
}