import React from "react";
import moment from "moment-timezone";
import pr from "@packageroute/types-firebase";
import Icon from "../Icon/Icon";
import { LiveIspProfile } from "@reducers/liveIspList";

type Drivers = pr.isp.profile.drivers.Drivers;

type Props = {
  profile: LiveIspProfile;
  color?: string;
};

export default function DriversFraction(props: Props) {
  const { profile, color } = props;

  if (!profile) return null;

  const commitmentRatio =
    profile && profile.drivers && profile.authorizedUsersCount
      ? Number(profile.authorizedUsersCount) /
        Object.keys(profile.drivers || {}).length
      : 0.5;

  const commitmentColor =
    commitmentRatio > 0.6
      ? "#168e32"
      : commitmentRatio < 0.3
      ? "#AB001D"
      : "#999";

  const { authorizedUsersCount = 0, drivers, timeZone } = profile;
  const daysSinceLastLogin = 2;
  const totalDrivers = Object.values(drivers ?? {}).length;
  const { totalWithLogin, totalWithRecentLogin } =
    drivers && timeZone
      ? driversWithAppLogin(drivers, timeZone, daysSinceLastLogin)
      : { totalWithLogin: 0, totalWithRecentLogin: 0 };

  return (
    <p className="drivers">
      <span style={{ color: color }}>
        {`${totalWithRecentLogin ? totalWithRecentLogin + " / " : ""}${
          totalWithLogin ? totalWithLogin + " / " : "0 / "
        }${authorizedUsersCount + " / " + totalDrivers} `}
      </span>
      <Icon name="account-multiple" color={commitmentColor} size={15} />
    </p>
  );
}

function driversWithAppLogin(
  drivers: Drivers,
  timeZone = "America/Los_Angeles",
  daysSinceLastLogin = 1
) {
  let totalWithLogin = 0;
  let totalWithRecentLogin = 0;

  for (const fedexId in drivers) {
    const driver = drivers[fedexId];

    if (driver.hasOwnProperty("metadata")) {
      const lastLogin = driver.metadata?.lastLogin;

      totalWithLogin += 1;

      if (lastLogin) {
        const recentCutoffDateTime = moment()
          .tz(timeZone)
          .subtract(daysSinceLastLogin, "days");
        const lastLoginDateTime = moment(lastLogin).tz(timeZone);

        if (lastLoginDateTime.isSameOrAfter(recentCutoffDateTime)) {
          totalWithRecentLogin += 1;
        }
      }
    }
  }

  return { totalWithLogin, totalWithRecentLogin };
}
