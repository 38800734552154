import React from 'react';

import './StationAdvocateListItem.css';
import Icon from '../Icon/Icon';

export default function StationAdvocateListItem (props) {
  const { station, users, referrals } = props;

  const stationAdvocates = Object.values(station.advocates || {}).filter(advocate => {
    return advocate.isAdvocate === true;
  }).map(advocate => {
    return advocate.userId;
  });
  const stationReferrals = Object.values(referrals || {}).filter(referral => {
    return stationAdvocates.includes(referral.uid);
  });
  const pendingReferrals = stationReferrals.reduce((acc, referral) => {
    if (referral.pendingInvites) {
      for (const uid in referral.pendingInvites) {
        acc.push(uid);
      }
    }

    return acc;
  }, []);
  const completeReferrals = stationReferrals.reduce((acc, referral) => {
    if (referral.completedInvites) {
      for (const uid in referral.completedInvites) {
        acc.push(uid);
      }
    }

    return acc;
  }, []);

  return (
    <div className='StationAdvocateListItem' onClick={() => props.goToStationPage(station.terminal)}>
      <div className='info'>
        <Icon name='city' size={20} color='#006DAB' />
        <p style={{ marginLeft: '5px' }}>{station.terminal}</p>
      </div>

      <div className='status'>
        <div className='advocate'>
          <p>
            {stationAdvocates.length} Advocate{stationAdvocates.length === 1 ? '' : 's'}
          </p>
        </div>

        <div className='pending'>
          <p
            style={{ color: '#168E32', fontSize: '12px' }}>
            {pendingReferrals.length} PENDING
          </p>
        </div>

        <div className='complete'>
          <p style={{ color: '#006DAB', fontSize: '12px' }}>
            {completeReferrals.length} COMPLETE
          </p>
        </div>
      </div>
    </div>
  );
}
