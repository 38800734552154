import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider as ReduxProvider } from 'react-redux';
import ErrorBoundary from './components/BugSnag/BugSnag';
import CrashPlaceHolder from './components/BugSnag/CrashPlaceHolder';

import store from './store';

ReactDOM.render(
  <ReduxProvider store={store}>
    <ErrorBoundary FallbackComponent={CrashPlaceHolder}>
      <App />
    </ErrorBoundary>
  </ReduxProvider>, 
  document.getElementById('root')
);
