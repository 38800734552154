import { RootState } from "@reducers";
import { Stations } from "@reducers/stations/productStations";
import { createSelector } from "reselect";

const selectProductStations = (state: RootState) =>
  state.stations.productStations.data;
const selectStationWithAdvocatesStationIds = (state: RootState) =>
  state.stations.productStations.categories.hasAdvocates;
const selectHdOnlyStationIds = (state: RootState) =>
  state.stations.productStations.categories.isHdOnly;
const selectCoLocationStationIds = (state: RootState) =>
  state.stations.productStations.categories.hasCoLocation;

function mapStationIdsToStations(
  stations: Stations,
  stationIds: Record<string, true>
) {
  const matchinStations: Stations = {};

  for (const stationId in stationIds) {
    if (stations[stationId]) {
      matchinStations[stationId] = stations[stationId];
    }
  }

  return matchinStations;
}

const selectStationsWithAdvocates = createSelector(
  [selectProductStations, selectStationWithAdvocatesStationIds],
  mapStationIdsToStations
);
const selectHdOnlyStations = createSelector(
  [selectProductStations, selectHdOnlyStationIds],
  mapStationIdsToStations
);
const selectCoLocationStations = createSelector(
  [selectProductStations, selectCoLocationStationIds],
  mapStationIdsToStations
);

export {
  selectProductStations,
  selectStationsWithAdvocates,
  selectHdOnlyStations,
  selectCoLocationStations,
};
