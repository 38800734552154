import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import deviceInfo from 'react-native-device-info';

export type Viewport = {
  width: number;
  height: number;
  orientation: 'portrait' | 'landscape';
  hideSplitView: boolean;
  isLandscape: boolean;
  isTablet: boolean;
};

export type State = {
  viewport: Viewport;
};

const initialState: State = {
  viewport: {
    width: 0,
    height: 0,
    orientation: 'portrait',
    hideSplitView: false,
    isLandscape: false,
    isTablet: false,
  }
};

const viewportSlice = createSlice({
  name: 'viewport',
  initialState: initialState,
  reducers: {
    updateViewport: (state, action: PayloadAction<{ height: number, width: number }>) => {
      const currentViewport = state.viewport;
      const newViewport = action.payload;
      const { width, height } = newViewport;

      const orientation = width < height ? 'portrait' : 'landscape';
      const isLandscape = orientation === 'landscape';
      const isTablet = _isDeviceTablet(deviceInfo);

      const viewport: Viewport = {
        ...currentViewport,
        width,
        height,
        orientation,
        isLandscape,
        isTablet
      };
      
      state.viewport = viewport;
    },
    setHideSplitView: (state, action: PayloadAction<boolean>) => {
      const hideSplitView = action.payload;

      state.viewport.hideSplitView = hideSplitView
    }
  }
})

function _isDeviceTablet(_deviceInfo: typeof deviceInfo) {
  return _deviceInfo.isTablet();
}

export const { 
  updateViewport,
  setHideSplitView
} = viewportSlice.actions

export default viewportSlice.reducer;