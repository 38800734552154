import React from 'react';
import { View, StyleSheet } from 'react-native';
import { mergeStyles, Styles } from '@native/utils';
import {useColorPack} from '@hooks/redux';

interface CardProps {
  children?: any;
  padded?: boolean;
  style?: Styles;
  scrollRef?: (ref: React.RefObject<HTMLDivElement>) => void;
}

export default function Card ({ children = [], padded = false, style = {}, scrollRef }: CardProps) {
  const colorPack = useColorPack();

  return (
    <View 
      className='Card'
      scrollRef={scrollRef}
      style={[
        styles.card,
        { borderColor: colorPack.border, backgroundColor: colorPack.baseHighlight },
        (padded ? styles.padded : {}),
        mergeStyles(style),
      ]}
    >
      { children }
    </View>
  );
}

interface CardGroupWrapperProps {
  children: any;
  style?: Styles;
}

export function CardGroupWrapper ({ children, style }: CardGroupWrapperProps) {
  return (
    <View 
      className='CardGroupWrapper'
      style={[styles.wrapper, mergeStyles(style)]}
    >
      { children }
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    marginHorizontal: 10
  },
  card: {
    borderWidth: 1,
    borderStyle: 'solid',
    marginBottom: 15,
    margin: 0,
    padding: 0,
  },
  padded: {
    padding: 10,
    margin: 10,
  }
});
