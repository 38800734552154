import React from 'react';
import moment from 'moment-timezone';

import './AddToCollection.css';
import Icon from '../../Icon/Icon';
import firebaseProvider from '../../../utils/firebase';

export default class AddToCollection extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      selectOption: false
    }

    this.addToCollection = this.addToCollection.bind(this);
  }

  async addToCollection (collection) {
    const { collectionId } = collection;

    const { isps, users, stations } = this.props;
    const consoleDB = firebaseProvider.getConsoleDefaultDB();
    const CollectionRef = consoleDB.ref(`collections/${collectionId}`);
    const nextISPs = [
      ...(collection.isps || []),
      ...(isps || [])
    ];
    const nextUsers = [
      ...(collection.users || []),
      ...(users || [])
    ];
    const nextStations = [
      ...(collection.stations || []),
      ...(stations || [])
    ];

    await CollectionRef.update({
      isps: nextISPs,
      users: nextUsers,
      stations: nextStations
    });

    this.setState({ finished: true });
  }

  render () {
    return (
      <div
        className='AddToCollection'
        onClick={() => this.setState(prevState => {
          return { selectOption: !prevState.selectOption }
        })}>
        {
          this.state.finished
            ? <Icon name='check' size={15} color='black' />
            : null
        }

        {this.props.children}

        <div className={`choices ${this.state.selectOption ? 'select' : ''}`}>
          {
            Object.keys(this.props.collections).map((pushKey) => {
              const collection = this.props.collections[pushKey];

              return (
                <div
                  key={pushKey}
                  className='collection'
                  onClick={(e) => this.addToCollection(collection)}>
                  <p>{collection.name}</p>

                  <p>{moment(collection.createdAt).fromNow()}</p>
                </div>
              );
            })
          }
        </div>
      </div>
    );
  }
}
