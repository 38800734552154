import { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../store';
import { selectUsers, selectUser } from '@selectors/users';
import { fetchUser } from '@actions/users';

export function useUsers () {
  return useAppSelector(selectUsers);
}

export function useUser (userFirebaseId: string) {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => selectUser(state, userFirebaseId));

  useEffect(() => {
    if (!user) {
      fetchUser(dispatch, userFirebaseId);
    }
  }, [userFirebaseId, dispatch]);

  return user;
}
