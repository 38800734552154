import React from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';

import Icon from '../Icon/Icon';

interface Props {
  color?: string;
  icon: string;
  iconSize?: number;
  label?: string;
  onPress: (event?: React.SyntheticEvent) => void;
  disabled?: boolean;
}

export default function LabeledButton ({color, icon, iconSize, label, onPress, disabled }: Props) {
  const activeColor = disabled ? '#999' : color;

  return (
    <View style={{flex: 1}}>
      <View style={styles.container}>
        <TouchableOpacity onPress={disabled ? () => {} : onPress}>
          <Icon name={icon} size={iconSize || 25} color={activeColor} />
        </TouchableOpacity>

        <Text
          allowFontScaling={false}
          style={{...styles.label, color: activeColor || '#999'}}
        >
          { label || '' }
          </Text>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    width: '100%',
    paddingVertical: 10,
    paddingHorizontal: 5
  },
  label: {
    fontSize: 12,
    color: '#999',
    marginTop: 3
  }
});
