import React, { CSSProperties } from 'react';
import './Button.css';

interface Props {
  className?: string;
  onPress?: (event: React.SyntheticEvent) => void;
  onClick?: (event: React.SyntheticEvent) => void;
  clickHandler?: (event: React.SyntheticEvent) => void;
  disabled?: boolean;
  color?: string;
  children?: (string | JSX.Element | null)[] | JSX.Element | JSX.Element[] | number | string | string[] | undefined | null;
  label?: string;
  type?: 'submit' | 'reset' | 'button';
  style?: CSSProperties;

  /**
   * Unused props, added for mobileApp compatibility
   * Need to implement
   */
  icon?: string;
  /**
   * Unused props, added for mobileApp compatibility
   * Need to implement
   */
  isCommunity?: boolean;
}

export default function Button (props: Props) {
  const { className = '', onClick = null, onPress = null, clickHandler = null, disabled = false, color, children = null, label = '', style = {}, type } = props;
  const addProps = type ? { type } : {};

  const handleClick = clickHandler 
    ? clickHandler 
    : onPress
      ? onPress
      : onClick 
        ? onClick 
        : () => console.log('Missing Clickhandler');

  return (
    <button
      className={`Button_OLD ${className}`}
      onClick={handleClick}
      style={{
        backgroundColor: disabled ? '#CCC' : (color || '#222'),
        ...style
      }}
      disabled={disabled}
      {...addProps}
    >{ children ? children : label }</button>
  );
}
