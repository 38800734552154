import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Stripe from 'stripe';

export type ConvertedSubscription = Stripe.subscriptions.ISubscription;
export type ConvertedSubscriptions = ConvertedSubscription[];

export enum Status {
  INITIAL = 'INITIAL',
  UPDATED = 'UPDATED',
  FAILED = 'FAILED',
}

type ConvertedState = {
  status: Status;
  data: ConvertedSubscriptions;
}

const initialState: ConvertedState = {
  status: Status.INITIAL,
  data: [],
};

const convertedSlice = createSlice({
  name: 'convertedSubscriptions',
  initialState: initialState,
  reducers: {
    updateConverted: (state, action: PayloadAction<{ subscriptions: ConvertedSubscriptions }>) => {
      const { subscriptions } = action.payload;

      state.data = subscriptions;
      state.status = Status.UPDATED;
    },
    updateConvertedFailed: (state) => {
      state.status = Status.FAILED;
    },
    resetConverted: (state) => {
      state.data = [];
      state.status = Status.INITIAL;
    }
  }
});

export const { 
  updateConverted,
  updateConvertedFailed,
  resetConverted,
} = convertedSlice.actions

export default convertedSlice.reducer;

