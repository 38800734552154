import moment from 'moment-timezone';
import {LiveIspProfile} from '@reducers/liveIspList';
import {Usage} from '@reducers/metrics/dailyOperationsUsage';

export function getISPActivity (
  ispProfile: LiveIspProfile | undefined | null
) {
  let total = 0;
  let paid = 0;

  if (!ispProfile || !ispProfile.WorkAreas) return { total, paid };

  const workAreas = ispProfile.WorkAreas;

  for (const workAreaId in workAreas) {
    const workArea = workAreas[workAreaId];

    total += 1;

    if (workArea.paid) {
      paid += 1;
    }
  }

  return { total, paid };
}

export function isISPPaid (
  ispProfile: LiveIspProfile | undefined | null
) {
  const { paid } = getISPActivity(ispProfile);

  if (paid > 0) return true;

  return false;
}


export function getLastActiveDate (ISPID: string, usage: Usage) {
  if (!usage || !ISPID) return null;

  for (let i = 0; i < 90; i++) {
    const date = moment.tz('America/Los_Angeles').subtract(i, 'days').format('YYYY-MM-DD');
    const usageDate = usage?.[date] ?? null;

    if (usageDate && usageDate.activeISPs && usageDate.activeISPs[ISPID]) {
      return date;
    }
  }

  return null;
}
