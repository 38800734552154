import React from "react";
import { PortalModal } from "./Modal";
import Button from "../Page/Button";

type Props = {
  message: string;
  description?: string;
  display?: boolean;
  cancelLabel?: string;
  confirmLabel?: string;
  close: () => void;
  confirm: () => void;
};

export default function ConfirmationModal ({
  message,
  description,
  display,
  cancelLabel,
  confirmLabel,
  close,
  confirm,
}: Props) {
  return (
    <PortalModal
      visible={display}
      centerFullScreen
      isMobile={false}
      onRequestClose={close}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: 20,
          backgroundColor: "white",
          borderRadius: 10,
        }}
      >
        <div style={{ fontSize: 25 }}>{message}</div>
        {description && <div style={{ fontSize: 15, marginTop: 5 }}>{description}</div>}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 20,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <Button style={{ marginRight: 10 }} onClick={close} color="#B90C0E">
            {cancelLabel || 'Cancel'}
          </Button>
          <Button style={{ marginLeft: 10 }} onClick={confirm} color="#0477B4">
            {confirmLabel || 'Confirm'}
          </Button>
        </div>
      </div>
    </PortalModal>
  );
}
