import React from "react";
import { useColorPack } from "@hooks/redux";
import { SubscriptionPlanContract } from '@models';
import { getContractStatusText } from './utils';
import "./ContractSummary.css";

type Props = {
  contract: SubscriptionPlanContract;
  pricePerRoute: number;
};

export default function ContractStatusDescription({
  contract,
  pricePerRoute
}: Props) {
  const colorPack = useColorPack();

  const { title, subTitle, secondaryText, contractTerms, color } = getContractStatusText(
    contract,
    pricePerRoute,
    colorPack
  );


  return (
    <div className='ContractStatusDescription'>
      <div className="contract-terms">
        <p>
          <strong style={{ color: colorPack.blue }}>{title.toUpperCase()}</strong>
          <span>{contractTerms}</span>
        </p>
      </div>

      <div className="contract-status">
        <p>
          {subTitle && <strong style={{ color }}>{subTitle.toUpperCase()}</strong>}
          <span>{secondaryText}</span>
        </p>
      </div>
    </div>
  );
}
