import { useAppSelector } from '../store';
import { 
  canceled,
  cancellingAtPeriodEnd,
  converted,
  pastDue,
  trials,
 } from '@selectors/subscriptions';

export {
  useIsConversion,
} from './isType';

export function useCancelledSubscriptions () {
  return useAppSelector(canceled.selectCanceledSubscriptions);
}

export function useCancelledSubscriptionsLoaded () {
  return useAppSelector(canceled.selectCanceledSubscriptionsLoaded);
}

export function useCancellingAtPeriodEndSubscriptions () {
  return useAppSelector(cancellingAtPeriodEnd.selectCancellingAtPeriodEndSubscriptions);
}

export function useCancellingAtPeriodEndSubscriptionsLoaded () {
  return useAppSelector(cancellingAtPeriodEnd.selectCancellingAtPeriodEndSubscriptionsLoaded);
}

export function useConvertedSubscriptions () {
  return useAppSelector(converted.selectConvertedSubscriptions);
}

export function useConvertedSubscriptionsLoaded () {
  return useAppSelector(converted.selectConvertedSubscriptionsLoaded);
}

export function useTrialConversionRate () {
  return useAppSelector(converted.selectTrialConversionRate);
}

export function usePastDueSubscriptions () {
  return useAppSelector(pastDue.selectPastDueSubscriptions);
}

export function usePastDueSubscriptionsLoaded () {
  return useAppSelector(pastDue.selectPastDueSubscriptionsLoaded);
}

export function useTrialSubscriptions () {
  return useAppSelector(trials.selectTrialSubscriptions);
}

export function useTrialSubscriptionsLoaded () {
  return useAppSelector(trials.selectTrialSubscriptionsLoaded);
}

export function useTrialDailyTotals () {
  return useAppSelector(trials.selectTrialDailyTotals);
}

export function useConvertedDailyTotals () {
  return useAppSelector(converted.selectConvertedDailyTotals);
}
