import { useCallback } from 'react';
import { useAppSelector } from "../store";
import { selectLiveIspList, selectLiveIsp } from "@selectors/liveIspList";

export function useLiveIspList() {
  return useAppSelector(selectLiveIspList);
}

export function useLiveIsp(ispId: string) {
  return useAppSelector(state => selectLiveIsp(state, ispId))
}

export function useIsActiveIsp () {
  const liveIspList = useLiveIspList();

  return useCallback((ispId: string) => {
    return !!liveIspList[ispId];
  }, [liveIspList]);
}