import React from "react";
import {Icon} from "@common";
import { useColorPack } from "@hooks/redux";
import "./TransferOwnershipModal.css";


type Props = {
  label: string;
  required?: boolean;
  error?: string;
  satisfied: boolean;
};

export default function SatisfiedCondition({
  label,
  required,
  satisfied,
  error,
}: Props) {
  const colorPack = useColorPack();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Icon
          name={satisfied ? "check" : "close"}
          color={
            satisfied
              ? colorPack.green
              : required
              ? colorPack.red
              : colorPack.medCont
          }
          size={25}
        />
        <p>{label}</p>
      </div>

      {!satisfied && error && (
        <div
          style={{
            marginLeft: 40,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <p
            style={{
              color: required ? colorPack.red : colorPack.medCont,
              fontSize: 14,
            }}
          >
            {error}
          </p>
        </div>
      )}
    </div>
  );
}
