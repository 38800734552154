import React from 'react';

import './List.css';
import ISPCard from './ISPCard';
import StationCard from './StationCard';
import Icon from '../../Icon/Icon';
import ListOptions from './ListOptions';
import api from '../../../utils/api';
import {Spinner} from '@common';

export default class List extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      viewOptions: false,
      loading: true,
    }

    this.fetchListItems = this.fetchListItems.bind(this);
  }

  componentDidMount () {
    this.fetchListItems();
  }

  componentDidUpdate (prevProps) {
    const { id } = this.props.list;

    if (prevProps.activeList && prevProps.activeList.id === id && this.props.activeList === null) {
      this.fetchListItems();
    }
  }

  fetchListItems () {
    const { id } = this.props.list;

    api.request(`sales-lists/${id}`)
      .then(res => {
        this.setState({ ispItems: res.data.ispItems, stationItems: res.data.stationItems, loading: false, });
      })
      .catch(err => console.log(err));
  }

  render () {
    const { loading, ispItems, stationItems } = this.state;

    let cards = [];

    if (ispItems) {
      cards = cards.concat(ispItems);
    }

    if (stationItems) {
      cards = cards.concat(stationItems);
    }

    return (
      <React.Fragment>
        <div className={`List ${this.props.draggingListId === this.props.list.id ? 'hide' : ''}`}>
          <div className='top'>
            <div className='name'>
              <h2>{this.props.list.name}</h2>
            </div>

            <div
              onClick={() => this.setState(prevState => {
                return { viewOptions: !prevState.viewOptions };
              })}
              className='options-trigger'>
              <Icon name='view-agenda' size={15} color='#999' />
            </div>
          </div>

          <div className='wrapper'>
            <div className='cards'>
              {
                cards.length
                  ? cards
                    .sort((a, b) => {
                      return a.createdAt > b.createdAt;
                    })
                    .map((item, i) => {
                      const type = item.hasOwnProperty('isp')
                        ? 'isp'
                        : 'station';

                      if (type === 'isp') {
                        return (
                          <ISPCard
                            key={'isp-card' + i}
                            item={item}
                            list={this.props.list}
                            usage={this.props.usage}
                            setActiveItem={this.props.setActiveItem}
                            liveISPList={this.props.liveISPList}
                          />
                        );
                      }

                      if (type === 'station') {
                        return (
                          <StationCard
                            key={'station-card' + i}
                            item={item}
                            list={this.props.list}
                            setActiveItem={this.props.setActiveItem}
                          />
                        );
                      }

                      return null;
                    })
                  : <div className='default'>
                      {loading
                        ? <div className='spinner-wrap'><Spinner size={35} /></div>
                        : <p>Nothing has been added yet...</p>
                      }
                    </div>
              }
            </div>

          </div>

          {
            this.state.viewOptions
              ? <ListOptions
                user={this.props.user}
                ispItems={ispItems}
                stationItems={stationItems}
                deleteList={(id) => {
                  this.props.deleteList(id);
                  this.setState({ viewOptions: false });
                }}
                list={this.props.list}
              />
              : null
          }
        </div>
      </React.Fragment>
    )
  }
}
