import React from 'react';
import './InputLocation.css';

import { GoogleMap, Marker, StandaloneSearchBox } from '@react-google-maps/api';

import Map from '../Map/Map';

type Props = {
  value?: { latitude?: number, longitude?: number };
  name: string;
  onChange: (event: { target: { name: string, value: { latitude: number, longitude: number }}}) => void;
  height?: number;
}

type State = {
  location: { 
    lat: number | null, 
    latitude: number | null, 
    lng: number | null,
    longitude: number | null 
  };
}

export default class InputLocation extends React.Component<Props, State> {
  searchBoxRef: any;
  mapRef: any;

  constructor (props: Props) {
    super(props);

    const {
      latitude,
      longitude,
    } = props.value || { latitude: null, longitude: null };

    const lat = latitude ? parseFloat(`${latitude}`) : null;
    const lng =  longitude ? parseFloat(`${longitude}`) : null;

    this.state = {
      location: { latitude: lat, lat, lng, longitude: lng },
    };

    this.onPlacesChanged = this.onPlacesChanged.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onPlacesChanged () {
    try {
      const [ place ] = this.searchBoxRef?.getPlaces();
      const { location } = place.geometry;
  
      const sterilized = this.sterilizeLocation(location);
  
      if (sterilized) this.mapRef.panTo(sterilized);
    } catch (err) {
      console.log(err);
    }
  }

  onClick ({ latLng }: { latLng: { lat: () => number, lng: () => number }}) {
    const { name } = this.props;

    this.setState({
      location: {
        lat: latLng.lat(),
        latitude: latLng.lat(),
        lng: latLng.lng(),
        longitude: latLng.lng()
      }
    }, () => {
      if (this.props.onChange) {
        const latitude = latLng.lat();
        const longitude = latLng.lng();

        this.props.onChange({
          target: {
            name,
            value: { latitude, longitude }
          }
        });
      }
    });
  }

  sterilizeLocation (location?: { latitude?: number | null, lat?: number | null, longitude?: number | null, lng?: number | null }) {
    try {
      if (!location) return undefined;
      if (!(location.latitude && location.lat)) return undefined;
      if (!(location.longitude && location.lng)) return undefined;
      
      return {
        lat: parseFloat(`${location.latitude || location.lat}`),
        lng: parseFloat(`${location.longitude || location.lng}`)
      }
    } catch (err) {
      console.log(err);
    }
  }

  render () {
    const { height } = this.props;
    const { location } = this.state;

    return (
      <div className='InputLocation'>
        <div style={{ display: 'flex' }}>
          <label style={{flex: 1}}>Location</label>

          {
            !!location &&
            <label style={{ color: '#006DAB' }}>lat: {location.latitude}, lng: {location.longitude}</label>
          }
        </div>

        <div style={{height: 5}} />

        <Map
          height={height || 400}
          saveRef={(ref: GoogleMap) => { this.mapRef = ref; }}
          onClick={this.onClick}
          defaultCenter={this.sterilizeLocation(location)}
        >
          {
            !!location &&
            <Marker
              position={this.sterilizeLocation(location)!}
            />
          }

          <StandaloneSearchBox
            onLoad={ref => this.searchBoxRef = ref}
            onPlacesChanged={this.onPlacesChanged}
          >
            <input
              type='text'
              placeholder='Search locations...'
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `240px`,
                height: `30px`,
                marginTop: `10px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                zIndex: 3
              }}
            />
          </StandaloneSearchBox>
        </Map>
      </div>
    );
  }
}
