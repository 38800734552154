import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type DateString = string;
type OperationId = string;
type Timestamp = number;

/**
 * The signups data set is a record of the operations created
 * on a specific date. The Profile.metadata.createdAt property
 * is used to determine which date an operation is created on.
 */
export type Signups = Record<DateString, SignupDate>;
export type SignupDate = Record<OperationId, Timestamp>;

export enum Status {
  INITIAL = 'INITIAL',
  UPDATED = 'UPDATED',
}

type SignupsState = {
  status: Status;
  data: Signups;
}

const initialState: SignupsState = {
  status: Status.INITIAL,
  data: {},
};

const signupsSlice = createSlice({
  name: 'signups',
  initialState: initialState,
  reducers: {
    updateSignups: (state, action: PayloadAction<{ signups: Signups }>) => {
      const { signups } = action.payload;

      state.data = {
        ...state.data,
        ...signups
      }

      state.status = Status.UPDATED;
    },
    updateSignupsDate: (state, action: PayloadAction<{ date: string, signups: SignupDate}>) => {
      const { date, signups } = action.payload;

      state.data = {
        ...state.data,
        [date]: signups,
      }

      state.status = Status.UPDATED;
    },
    resetSignups: (state) => {
      state.data = {};
      state.status = Status.INITIAL;
    }
  }
});

export const { 
  updateSignups,
  updateSignupsDate,
  resetSignups,
} = signupsSlice.actions

export default signupsSlice.reducer;
