import React from 'react';

import './ListOptions.css';
import ExportAsCollection from '../../Messaging/Collections/ExportAsCollection';

export default function ListOptions (props) {
  return (
    <div className='SalesListOptions'>
      <div className='title'>
        <h3>List Actions</h3>
      </div>

      <div className='list'>
        <div className='option' onClick={() => props.deleteList(props.list.id)}>
          <p>Delete List...</p>
        </div>

        <div className='option'>
          <ExportAsCollection
            user={props.user}
            name={props.list.name}
            stations={(props.stationItems || [])
              .filter(item => {
                return item && item.station && item.station[0] && item.station[0].terminal;
              })
              .map(item => {
                return item.station[0].terminal;
              })}
            isps={(props.ispItems || [])
              .filter(item => {
                return item.isp && item.isp[0] && !!item.isp[0].firebaseId;
              })
              .map(item => {
                return item.isp[0].firebaseId;
              })}>
            <p>Export as collection...</p>
          </ExportAsCollection>
        </div>
      </div>
    </div>
  );
}
