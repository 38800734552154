import React from 'react';
import './StripeLink.css';

const types = {
  cus: {
    baseUrl: 'customers',
    title: 'Customer'
  },
  sub: {
    baseUrl: 'subscriptions',
    title: 'Subscription'
  }
}

type Props = {
  id: string;
  type: keyof typeof types;
  showTitle?: boolean;
}

export default function StripeLink ({ id, type, showTitle }: Props) {
  const { baseUrl, title } = types[type];
  const url = `https://dashboard.stripe.com/${baseUrl}/${id}`;

  return (
    <a
      href={url}
      className='StripeLink'
      target='_blank'
      rel="noreferrer"
    >
			{/* <i className='mdi mdi-credit-card-settings' /> */}
      <div className='icon'>S</div>
      { !!showTitle && <p>{ title }</p> }
		</a>
	);
}
