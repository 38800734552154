import React from 'react';
import './ContactIdTools.css';
import Button from '../Page/Button';
import { taskTypes } from '@packageroute/tasks';
import api from '../../utils/api';
import ApiTestTool from './ApiTestTool';
import ContactIdChange from './ContactIdChange';
import Modal from '../Page/Modal';

export default class ContactIdTools extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      rawManifestData: null,
      rawStatusData: null,
      manifestTaskForISP: null,
    };

    this.testApi = this.testApi.bind(this);
    this.getManifestTaskForISP = this.getManifestTaskForISP.bind(this);
    this.toggleManifestTaskForISP = this.toggleManifestTaskForISP.bind(this);
  }

  componentDidMount () {
    this.getManifestTaskForISP();
  }

  async getManifestTaskForISP () {
    const { profile } = this.props;
    const { ISPID } = profile;

    const res = await api.request(`tasks/manifest/isps/${ISPID}`);
    const name = res.data;

    this.setState({ manifestTaskForISP: name });
  }

  async toggleManifestTaskForISP () {
    const { profile } = this.props;
    const { ISPID } = profile;

    await api.post(`tasks/manifest/isps/${ISPID}`);
    await this.getManifestTaskForISP();
  }

  testApi () {
    this.setState({
      testError: null,
      testSucceeded: null,
      loading: true
    }, () => {
      const { profile } = this.props;
      const { contactId } = profile;

      api.getApiDataForId(contactId)
        .then(res => {
          const { data } = res;
          const authorized = data.hasOwnProperty('status') || data.hasOwnProperty('manifest');

          this.setState({
            testSucceeded: authorized,
            loading: false,
            rawManifestData: data.manifest,
            rawStatusData: data.status
          });
        })
        .catch(err => {
          const response = err.response || {};
          const data = response.data || {};

          this.setState({
            testError: true,
            testErrorMessage: data.errorMessage || 'An error occured.',
            loading: false
          });
        });
    });
  }

  render () {
    const { profile, manualUploadsEnabled, toggleManualUpload } = this.props;
    const { testError, testSucceeded, loading, rawManifestData, rawStatusData, changeContactId } = this.state;

    if (!profile) {
      return (
        <div className='ispDetails'>
          <p>No profile information available.</p>
        </div>
      );
    }

    const { contactId, drivers, station, WorkAreas } = profile;
    const contactAccount = drivers && contactId
      ? drivers[contactId]
      : null;
    const contactName = contactAccount
      ? `${contactAccount.first} ${contactAccount.last}`
      : null
    const stationId = station && station.stationID;

    return (
      <div className='ContactIdTools'>
        <ApiTestTool
          contactId={contactId}
          contactName={contactName}
          stationIds={stationId ? { [stationId]: true } : null}
          workAreas={WorkAreas}
        />

        { changeContactId &&
          <Modal onBackClick={() => this.setState({ changeContactId: false })}>
            <ContactIdChange
              profile={profile}
              loadLatestProfile={this.props.loadLatestProfile}
              close={() => this.setState({ changeContactId: false })}
            />
          </Modal>
        }

        { !loading &&
          <div className='buttonWrap'>
            <Button
              color='#006DAB'
              onClick={() => this.setState({ changeContactId: true })}>
              Change Contact ID
            </Button>

            {
              manualUploadsEnabled
                ? <Button onClick={toggleManualUpload} color='#FC0000'>Disable Manual Uploads</Button>
                : <Button onClick={toggleManualUpload} color='#168E32'>Enable Manual Uploads</Button>
  }

            {
              this.state.manifestTaskForISP
                ? <div className='statusTask'>
                  <Button
                    color={this.state.manifestTaskForISP === 'FX_API_COMBINED_MANIFEST' ? 'green' : '#FC0000'}
                    onClick={this.toggleManifestTaskForISP}>
                    Manifest via Operation
                  </Button>

                  <Button
                    color={this.state.manifestTaskForISP === 'FX_API_MANIFEST_FILTERS' ? 'green' : '#FC0000'}
                    onClick={this.toggleManifestTaskForISP}>
                    Manifest via Filters
                  </Button>
                </div>
                : null
            }
          </div>
        }
      </div>
    );
  }
}
