import { useAppSelector } from '../store';
import { 
  selectCoLocationStations,
  selectHdOnlyStations,
  selectProductStations,
  selectStationsWithAdvocates,
} from '@selectors/stations/productStations';

export function useProductStations () {
  return useAppSelector(selectProductStations);
}

export function useHDOnlyStations () {
  return useAppSelector(selectHdOnlyStations);
}

export function useCoLocationStations () {
  return useAppSelector(selectCoLocationStations);
}

export function useStationsWithAdvocates () {
  return useAppSelector(selectStationsWithAdvocates);
}
