import {createSelector} from 'reselect';
import {RootState} from '@reducers';
import {selectFirebaseUser} from '../firebaseUser';
import {Status} from '@reducers/consoleUsers';

const selectConsoleUsers = (state: RootState) => state.consoleUsers.data;
const selectConsoleUsersLoaded = (state: RootState) => state.consoleUsers.status === Status.UPDATED;

const selectConsoleUser = createSelector(
  [selectConsoleUsers, selectFirebaseUser],
  (consoleUsers, firebaseUser) => {
    if (firebaseUser && consoleUsers[firebaseUser.uid]) {
      return consoleUsers[firebaseUser.uid];
    }

    return null;
  }
)
export {
  selectConsoleUsers,
  selectConsoleUser,
  selectConsoleUsersLoaded,
}