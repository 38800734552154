import React from 'react';
import pr from '@packageroute/types-firebase';
import UpdateISPSubscription from './UpdateISPSubscription';
import CustomerSummary from './CustomerSummary';
import Bill from './Bill/Bill';
import { Transaction } from './BalanceHistory';
import ReasonsForCancellation from './ReasonsForCancellation';
import {SubscriptionData} from '../../utils/api';
import {SubscriptionPlanContract} from '@models';
import ContractSummary from './ContractSummary/ContractSummary';
import {getPerRoutePricingByPlanType} from './pricing';
import './IspSubscription.css';

type Profile = pr.isp.profile.Profile;

const sections = ['Next Bill', 'Payment History', 'Update Subscription'];

type Props = {
  ISPID: string;
  loadSubscription: () => void;
  liveISPList: Record<string, Profile>;
  latestProfile?: Profile;
  subscriptionData: SubscriptionData | null | undefined;
  subscriptionContract?: SubscriptionPlanContract | null;
  showISPName?: boolean;
}

type State = {
  activeSection: string;
  sections: string[];
  hasCancellationFeedback: boolean;
}

export default class IspSubscription extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props);

    this.state = {
      activeSection: 'Next Bill',
      sections,
      hasCancellationFeedback: false
    };
  }

  componentDidMount () {
    const { latestProfile } = this.props;

    if (latestProfile && latestProfile.feedback && latestProfile.feedback.subscriptionCancellation) {
      this.hasCancellationFeedback();
    }
  }

  componentDidUpdate () {
    const { hasCancellationFeedback } = this.state;
    const { latestProfile } = this.props;

    if (!hasCancellationFeedback && latestProfile && latestProfile.feedback && latestProfile.feedback.subscriptionCancellation) {
      this.hasCancellationFeedback();
    }
  }

  hasCancellationFeedback () {
    this.setState({
      sections: [...sections, 'Cancellation Reason'],
      hasCancellationFeedback: true,
    })
  }

  render () {
    const { subscriptionData, ISPID, loadSubscription, liveISPList, showISPName, latestProfile, subscriptionContract } = this.props;
    const { activeSection: setSection, sections: allSections } = this.state;

    let activeSection = setSection;
    let sections = allSections;

    if (!subscriptionData) {
      const { feedback } = latestProfile || {};
      const { subscriptionCancellation } = feedback || {};

      if (!subscriptionCancellation) return null;
      else {
        activeSection = 'Cancellation Reason'
        sections = [activeSection];
      }
    }

    const { subscription, nextInvoice, paymentHistory } = subscriptionData || {};

    return (
      <div className='IspSubscription'>
        {!!(showISPName && latestProfile) &&
          <div className='IspName'>
            {latestProfile.CSPName} <span>{latestProfile?.station?.stationID}</span>
          </div>
        }
        <div className='sectionButtons'>{
          sections.map(section =>
            <div
              key={section}
              className={`sectionButton${section === activeSection ? ' active' : ''}`}
              onClick={() => this.setState({ activeSection: section })}
            >{ section }</div>
          )
        }</div>

        <div className='sectionWindow'>{(() => {
          switch (activeSection) {
            case 'Next Bill':
              return (
                <React.Fragment>
                  { subscriptionContract && 
                    <ContractSummary 
                      subscriptionContract={subscriptionContract} 
                      pricePerRoute={getPerRoutePricingByPlanType(subscriptionContract.contractPlan)}
                    />
                  }
                  
                  <CustomerSummary subscription={subscription} />

                  {subscription && !subscription.cancel_at_period_end &&
                    <Bill operationId={ISPID} nextInvoice={nextInvoice} subscription={subscription} />
                  }
                </React.Fragment>
              );
            case 'Card':
              return (
                null
              );
            case 'Payment History':
              return !!paymentHistory && !!paymentHistory.data && !!(paymentHistory.data.length > 0)
                ? paymentHistory.data.map(invoice =>
                    <Transaction
                      transaction={invoice}
                      thisSubscriptionId={(subscription || {}).id}
                      liveISPList={liveISPList}
                    />
                  )
                : <div className='noData' style={{textAlign: 'center', paddingBottom: 20}}>No Payments Found</div>
            case 'Update Subscription':
              return (
                <UpdateISPSubscription
                  ISPID={ISPID}
                  subscription={subscription}
                  loadSubscription={loadSubscription}
                />
              )
            case 'Cancellation Reason':
              const { latestProfile } = this.props;
              const { feedback } = latestProfile || {};
              const { subscriptionCancellation } = feedback || {};
              const { feedback: cancellationFeedback } = subscriptionCancellation || {};
              const {
                comment = '',
                reasons = {},
                permissionToContactCustomer = false,
                ...otherFeedback
              } = cancellationFeedback ?? {};

              return (
                <ReasonsForCancellation
                  feedback={otherFeedback}
                  comment={comment}
                  reasons={reasons}
                />
              )
            default:
              return null;
          }
        })()}</div>
      </div>
    );
  }
}
