import React from 'react';

import SearchInput from '../Search/SearchInput';
import SearchResults from '../Search/SearchResults';
import StationEntry from '../Search/StationEntry';
// import Section from '../Page/Section';
import api from '../../utils/api';

export default class AssocaiteStation extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      stationID: props.station ? props.station.id : null,
      terminal: props.station ? props.station.terminal : null,
      searchText: '',
      results: {},
      searching: false
    }

    this.onChange = this.onChange.bind(this);
    this.search = this.search.bind(this);
  }

  onChange ({ target }) {
    const { searchTimeout } = this.state;

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const timeoutID = setTimeout(this.search, 1000);

    this.setState({
      searchTimeout: timeoutID,
      result: {},
      selectedISP: null,
      searchText: target.value,
      searching: true
    });
  }

  async search () {
    try {
      const { searchText } = this.state;

      if (!searchText) return;

      const results = await api.searchStations(searchText.toString());

      this.setState({ results, searching: false });
    }
    catch(err) {
      this.setState({ searching: false });
    }
  }

  render () {
    return (
      <div className='AssociateStation'>
        <div className='search'>
          <h5 style={{ margin: 0, fontSize: '0.85em', fontWeight: 300, marginBottom: '5px', color: '#666' }}>Search Stations</h5>
          <SearchInput
            value={this.state.searchText}
            onChange={this.onChange}
            placeholder={'Station name, station id, city, state'}
          />

          {
            this.state.searchText
              ? <SearchResults searching={this.state.searching}>
                {(() => {
                  const searchResults = [];
                  const { stations = [] } = this.state.results;

                  const exp = new RegExp(this.state.searchText, 'i');

                  for (const station of stations) {
                    const matches = {};
                    const matchFields = ['terminal', 'city', 'state', 'terminalName'];

                    for (const field of matchFields) {
                      if (station[field] && station[field].match(exp)) {
                        matches[field] = true;
                      }
                    }

                    searchResults.push(
                      <StationEntry
                        station={station}
                        matches={matches}
                        onClick={() => {
                          this.setState({ searchText: '' }, () => {
                            this.props.selectStation(station);
                          })
                        }}
                        key={'station' + station.id}
                      />
                    );
                  }

                  return searchResults.length ? searchResults : null;
                })()}
              </SearchResults>
              : null
          }
        </div>
      </div>
    )
  }
}
