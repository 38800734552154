import moment from 'moment';

/**
 * Formats date string into readable format
 * 
 * Defaults to M/D & M/D/YY formats (e.g. 3/29 & 3/29/20)
 * @param date - date string in YYYY-MM-DD format
 * @param format - format for all dates which are same year as today
 * @param differentYearFormat - format string for all dates which are not same year as today
 * @returns 
 */
export function formatReadableDate(date: string, format: string = 'M/D', differentYearFormat: string = 'M/D/YY') {
  const nowMoment = moment();
  const dateMoment = moment(date, window.DATE_KEY);

  if (dateMoment.isSame(nowMoment, 'year')) {
    return dateMoment.format(format);
  } else {
    return dateMoment.format(differentYearFormat);
  }
}

const momentCalendarFormats = {
  sameDay: '[Today]',
  nextDay: '[Tomorrow]',
  nextWeek: 'MMM Do',
  lastDay: '[Yesterday]',
  lastWeek: 'MMM Do',
  sameElse: 'MMM Do'
};

export function formatCalendarDate(date: string) {
  return moment(date, 'YYYY-MM-DD').calendar(null, momentCalendarFormats);
}
