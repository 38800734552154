import React, {useState, useCallback} from 'react';
import { View, StyleSheet } from 'react-native';
import { Styles, mergeStyles } from '@native/utils';

type Props = {
  children: (props: { height: number, width: number }) => React.ReactNode;
  style?: Styles;
}

export default function OnLayoutView ({
  style,
  children,
}: Props) {
  const {height, width, ref} = useOnLayout();

  return (
    <div 
      className='OnLayoutView' 
      ref={ref} 
      style={mergeStyles([
        styles.fill, 
        mergeStyles(style)
      ])}
    >
      <View style={styles.fill}>
        { (width && height)
          ? children({ height, width }) 
          : <div style={styles.fill} />
        }
      </View>
    </div>
  )
}

const styles = StyleSheet.create({
  fill: { 
    display: 'flex',
    width: '100%', 
    height: '100%', 
    flex: 1,
  }
})

const defaultDimensions = {
  height: 0,
  width: 0,
}

function useOnLayout () {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  
  const ref = useCallback((node: any) => {

    /**
     * repetitions are unfortunately necessary in order to ensure that
     * the OnLayoutView component expands ot the full size of the component.
     * 
     * Otherwise it just uses the initial height that it fills on mount which
     * might be a fraction of the available space.
     */
    timeoutLoop(() => {
      if (node) {
        const { height, width } = node?.getBoundingClientRect() ?? defaultDimensions;

        setHeight(height);
        setWidth(width);
      }
    }, 6, 10);    
  }, []);

  return {
    ref,
    height, 
    width
  };
}

function timeoutLoop(cb: () => void, repetitions = 1, intervalMs = 10) {
  for (let i = 0; i < repetitions; i++) {
    setTimeout(cb, i * intervalMs);
  }
}