import { Dispatch } from '@reduxjs/toolkit';
import * as cancellationReasons from './cancellationReasons';
import * as consoleUsers from './consoleUsers';
import * as featureFlags from './featureFlags';
import * as firebaseUser from './firebaseUser';
import * as liveIspList from './liveIspList';
import * as metrics from './metrics';
import * as permissions from './permissions';
import * as referrals from './referrals';
import * as reports from './reports';
import * as salesCampaignCollections from './salesCampaignCollections';
import * as salesLists from './salesLists';
import * as signups from './signups';
import * as subscriptions from './subscriptions';
import * as stations from './stations';
import * as users from './users';

function unsubscribeAndResetAppUserData (dispatch: Dispatch) {
  consoleUsers.resetConsoleUsers(dispatch);
  firebaseUser.clearFirebaseUser(dispatch);
  featureFlags.resetFeatureFlags(dispatch);
  permissions.resetPermissions(dispatch);
}

function unsubscribeAndResetAppData (dispatch: Dispatch) {
  liveIspList.unsubscribeLiveIspList(dispatch);
  metrics.unsubscribeAndResetMetrics(dispatch);
  referrals.clearReferrals(dispatch);
  reports.clearDateRange(dispatch);
  salesCampaignCollections.unsubscribeSalesCampaignCollections(dispatch);
  salesLists.clearSalesLists(dispatch);
  signups.clearSignups(dispatch);
  subscriptions.clearSubscriptions(dispatch);
  users.clearUsers(dispatch);
  cancellationReasons.clearReasons(dispatch);
}

export {
  cancellationReasons,
  consoleUsers,
  featureFlags,
  firebaseUser,
  liveIspList,
  metrics,
  permissions,
  referrals,
  reports,
  salesCampaignCollections,
  salesLists,
  signups,
  stations,
  subscriptions,
  users,
  unsubscribeAndResetAppUserData,
  unsubscribeAndResetAppData,
}