import {Dispatch} from '@reduxjs/toolkit';
import { AppThunkDispatch } from '@store';
import { receiveReportDateRange, resetDateRange, DateRangeType } from '@reducers/reports/dateRange';

/**
 * Loads requested manifest reporting data
 * @param dispatch Dispatch
 * @param {string | null } date
 */
export function updateDateRange (
  startDate: string, 
  endDate: string, 
  type: DateRangeType,
) {
  return async (dispatch: AppThunkDispatch) => {
    dispatch(receiveReportDateRange({ startDate, endDate, type }));
  }
}

export function clearDateRange (dispatch: Dispatch) {
  dispatch(resetDateRange());
}