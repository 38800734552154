import React from 'react';

import './ListOptions.css';
import ExportAsCollection from '../Messaging/Collections/ExportAsCollection';

export default function ListOptions (props) {
  return (
    <div
      className='PipelineListOptions'
      style={{ left: `(props.left * 20)%` }}>
      <div className='title'>
        <h3>List Actions</h3>
      </div>

      <div className='list'>
        <div className='option'>
          <ExportAsCollection
            user={props.user}
            name={props.name}
            isps={(props.ispItems || [])
              .filter(item => {
                return !!item.firebaseId
              })
              .map(item => {
                return item.firebaseId;
              })}>
            <p>Export as collection...</p>
          </ExportAsCollection>
        </div>
      </div>
    </div>
  );
}
