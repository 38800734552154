import React from 'react';
import { Link } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import Icon from '../Icon/Icon';
import StationsMap from './StationsMap';
import StationsSearchList from './StationsSearchList';
import {RootState} from '@reducers';
import {ConsoleStation} from '@reducers/stations/consoleStations';
import * as selectors from '@selectors';

import './StationsSummary.css';

type Props = {} & PropsFromRedux;

type State = {
  focusedStation: ConsoleStation | null;
  numToShow: number;
}

class StationsSummary extends React.PureComponent<Props, State> {
  constructor (props: Props) {
    super(props);

    this.state = {
      numToShow: 10,
      focusedStation: null
    };

    this.setMapRef = this.setMapRef.bind(this);
    this.showMore = this.showMore.bind(this);
    this.setFocusedStation = this.setFocusedStation.bind(this);
  }

  // @ts-ignore
  setMapRef (ref) {
    // @ts-ignore
    this.map = ref;
  }

  showMore () {
    this.setState({
      numToShow: this.state.numToShow + 10
    });
  }

  async setFocusedStation (station: ConsoleStation | null) {
    this.setState({ focusedStation: station });

    const lat = station?.latitude;
    const lng = station?.longitude;
    const lngLat = [parseFloat(lng ?? ''), parseFloat(lat ?? '')];

    // @ts-ignore
    if (this.map && lat && lng) {
      // @ts-ignore
      this.map.setZoom(7);
      // @ts-ignore
      this.map.panTo(lngLat);
    }
  }

  render () {
    const { stations, coLocationStations } = this.props;
    const liveStations = this.props.activity ? (this.props.activity.liveStations || {}) : {};
    let activeCount = stations.reduce<number>((acc, station) => {
      if ((this.props.activeStations || {}).hasOwnProperty(station.terminal)) {
        acc += 1;
      }

      return acc;
    }, 0);
    const totalStations = stations.reduce<number>((acc, station) => {
      const id = station.terminal;

      if (coLocationStations && coLocationStations.hasOwnProperty(id)) {
        return acc;
      }

      acc += 1;

      return acc;
    }, 0);

    return (
      <div className='StationsSummary'>
        <div className='top'>
          <div className='left'>
            <p className='fraction'>
              { activeCount } / { totalStations }
            </p>
          </div>

          <div className='right'>
            {
              this.state.focusedStation
                ? <StationShort station={this.state.focusedStation} />
                : null
            }
          </div>
        </div>

        <div className='body'>
          <div className='list'>
            <StationsSearchList
              stations={stations}
              liveStations={liveStations}
              liveISPList={this.props.liveISPList}
              focusedStation={this.state.focusedStation}
              activeStations={this.props.activeStations}
              setFocusedStation={this.setFocusedStation}
            />
          </div>

          <div className='map'>
            <StationsMap
              setMapRef={this.setMapRef}
              setFocusedStation={this.setFocusedStation}
              activeStations={this.props.activeStations}
              focusedStation={this.state.focusedStation}
              stations={stations}
              liveStations={liveStations}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps (state: RootState) {
  return {
    activity: selectors.metrics.dailyOperationsActivity.selectDailyOperationsActivity(state),
    liveISPList: selectors.liveIspList.selectLiveIspList(state),
    activeStations: selectors.stations.activeStations.selectActiveStations(state),
    coLocationStations: selectors.stations.productStations.selectCoLocationStations(state),
    stations: selectors.stations.consoleStations.selectConsoleStationsList(state),
  }
}

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(StationsSummary);

function StationShort ({ station }: { station: ConsoleStation }) {
  return (
    <Link className='StationShort' to={`/stations/${station.id}`}>
      <div>
        <p>{ station.terminal }</p>

        <p>{ station.city }, { station.state }</p>
      </div>


      <Icon name='arrow-right' color='black' size={30} />
    </Link>
  );
}
