import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import type {AppDispatch, AppThunkDispatch} from '@store';
import type {RootState} from '@reducers';

/**
 * A hook for accessing Redux's dispatch function
 *
 * with typings for Redux-Thunk dispatch & App store state
 */
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useThunkAppDispatch = () => useDispatch<AppThunkDispatch>();

/**
 * A hook for accessing Redux's state store
 *
 * Accepts a selector function as an argument
 *
 * Includes typings for app store state.
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
