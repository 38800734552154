import React from 'react';
import './InputTextArea.css';
import Icon from '../Icon/Icon';

export default function InputText (props) {
  const {
    label,
    value,
    onChange,
    onFocus = () => {},
    onBlur = () => {},
    placeholder,
    style,
    name,
    id,
    required,
    disabled,
    inputStyle,
    labelStyle,
    valid
  } = props;

  return (
    <div className='InputTextArea' style={style}>
      { props.children }
      {
        !!label &&
        <label htmlFor={id} style={labelStyle}>
          { label }
          {
            required === true
              ? <div>
                <Icon
                  name={valid ? 'checkbox-marked-circle-outline' : 'close-circle-outline'}
                  size={12}
                  color={valid ? 'rgb(22, 142, 50)' : 'rgb(185, 12, 15)'}
                />
              </div>
              : null
          }
        </label>
      }

      <textarea
        name={name}
        type={'text'}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        required={required}
        disabled={disabled}
        style={inputStyle}
      />
    </div>
  );
}
