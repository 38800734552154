import React from 'react';
import Markdown from 'react-remarkable';
import readingTime from 'reading-time';

import './markdown.css';
import './Article.css';
import InputText from '../../Page/InputText';
import InputSelect from '../../Page/InputSelect';
import ArticleCard from '../Cards/ArticleCard';
import CategoryCard from '../Cards/CategoryCard';
import UploadTarget from '../../Page/UploadTarget';
import Button from '../../Page/Button';
import Icon from '../../Icon/Icon';
import api from '../../../utils/api';

export default class Article extends React.Component {
  constructor (props) {
    super(props);

    const id = props.match.params.articleId;
    const isNew = id === 'new';
    const article = props.data.articles[id];
    const topArticle = props.data.top_articles.includes(id);

    this.state = {
      id: article ? article.id : '',
      title: article ? article.title : '',
      tempTitle: article ? article.title : '',
      categories: article ? article.categories : [],
      relatedArticles: article ? article.relatedArticles : [],
      readingTime: article ? article.readingTime : null,
      markdown: null,
      topArticle,
      isNew
    };

    this.addCategory = this.addCategory.bind(this);
    this.removeCategory = this.removeCategory.bind(this);
    this.addRelatedArticle = this.addRelatedArticle.bind(this);
    this.removeRelatedArticle = this.removeRelatedArticle.bind(this);
    this.updateTitle = this.updateTitle.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.createNewArticle = this.createNewArticle.bind(this);
    this.getMarkdown = this.getMarkdown.bind(this);
    this.deleteArticle = this.deleteArticle.bind(this);
  }

  componentDidMount () {
    if (!this.state.isNew) {
      this.getMarkdown(this.state.id);
    }
  }

  async getMarkdown (id) {
    const res = await api.request(`knowledge/article/${id}`);

    this.setState({ markdown: res.data });
  }

  addCategory (e) {
    e.preventDefault();

    const category = e.target.value;

    if (!category) return;

    if (this.state.isNew === true) {
      this.setState(prevState => {
        const nextCategories = [...prevState.categories];

        if (!nextCategories.includes(category)) {
          nextCategories.push(category);
        }
        else {
          return {};
        }

        return { categories: nextCategories };
      });
    }
    else {
      const nextCategories = [...this.state.categories];

      if (!nextCategories.includes(category)) {
        nextCategories.push(category);
      }
      else {
        return;
      }

      const updates = { categories: nextCategories };

      const res = api.patch(`knowledge/article/${this.state.id}`, updates);

      this.props.updateData(res.data);
      this.setState({ categories: nextCategories });
    }
  }

  removeCategory (category) {
    if (this.state.isNew === true) {
      this.setState(prevState => {
        const nextCategories = [...prevState.categories];
        const index = nextCategories.indexOf(category);

        if (index < 0) {
          return {};
        }

        nextCategories.splice(index, 1);

        return { categories: nextCategories };
      });
    }
    else {
      const nextCategories = [...this.state.categories];
      const index = nextCategories.indexOf(category);

      if (index < 0) {
        return;
      }

      nextCategories.splice(index, 1);

      const updates = { categories: nextCategories };

      const res = api.patch(`knowledge/article/${this.state.id}`, updates);

      this.props.updateData(res.data);
      this.setState({ categories: nextCategories });
    }
  }

  updateTitle (tempTitle) {
    if (this.state.isNew !== true) {
      const updates = { title: tempTitle };

      const res = api.patch(`knowledge/article/${this.state.id}`, updates);

      this.props.updateData(res.data);
    }

    this.setState(prevState => {
      const title = tempTitle;

      return {
        title,
        id: !prevState.isNew ? prevState.id : tempTitle.toLowerCase().replace(/ /g, '-'),
        tempTitle: title
      };
    });
  }

  addRelatedArticle (e) {
    e.preventDefault();

    const articleId = e.target.value;

    if (!articleId) return;

    if (this.state.isNew === true) {
      this.setState(prevState => {
        const nextRelatedArticles = [...prevState.relatedArticles];

        if (!nextRelatedArticles.includes(articleId)) {
          nextRelatedArticles.push(articleId);
        }

        return { relatedArticles: nextRelatedArticles };
      });
    }
    else {
      const nextRelatedArticles = [...this.state.relatedArticles];

      nextRelatedArticles.push(articleId);

      const updates = { relatedArticles: nextRelatedArticles };

      const res = api.patch(`knowledge/article/${this.state.id}`, updates);

      this.props.updateData(res.data);
      this.setState({ relatedArticles: nextRelatedArticles });
    }
  }

  removeRelatedArticle (articleId) {
    if (this.state.isNew === true) {
      this.setState(prevState => {
        const nextRelatedArticles = [...prevState.relatedArticles];
        const index = nextRelatedArticles.indexOf(articleId);

        nextRelatedArticles.splice(index, 1);

        return { relatedArticles: nextRelatedArticles };
      });
    }
    else {
      const nextRelatedArticles = [...this.state.relatedArticles];
      const index = nextRelatedArticles.indexOf(articleId);

      nextRelatedArticles.splice(index, 1);

      const updates = { relatedArticles: nextRelatedArticles };

      const res = api.patch(`knowledge/article/${this.state.id}`, updates);

      this.props.updateData(res.data);
      this.setState({ relatedArticles: nextRelatedArticles });
    }
  }

  uploadFile (files) {
    if (!files) return this.props.addError('No files found.');
    if (files.length > 1) return this.props.addError('Only 1 file allowed per article.');

    const file = files[0];

    const fileReader = new FileReader();

    fileReader.onloadend = this.handleFile;

    fileReader.readAsText(file);
  }

  async handleFile (e) {
    const { target } = e;
    const { result } = target;

    const stats = readingTime(result);

    if (!this.state.isNew) {
      await api.patch(`knowledge/article/${this.state.id}/markdown`, { markdown: result });
    }

    this.setState({ markdown: result, readingTime: stats.text });
  }

  async createNewArticle (e) {
    const {
      id,
      title,
      categories,
      relatedArticles,
      readingTime,
      markdown
    } = this.state;
    const article = { id, title, categories, relatedArticles, readingTime };

    const res = await api.post('knowledge/article', { article, markdown });

    this.props.updateData(res.data);
    this.props.history.push(`/knowledge/articles/${id}`);
    this.setState({ isNew: false });
  }

  async deleteArticle () {
    const { id } = this.state;

    const res = await api.deleteRequest(`knowledge/article/${id}`);

    this.props.updateData(res.data);
    this.props.history.push('/knowledge/articles');
  }

  async toggleTopArticle () {
    const { id } = this.state;
    const res = await api.post(`knowledge/top_article/${id}`);

    const top_articles = res.data.top_articles;

    this.setState({ topArticle: top_articles.includes(id) });
    this.props.updateData(res.data);
  }

  render () {
    const { data } = this.props;
    const { id } = this.state;
    const article = data.articles[id];

    return (
      <div className='Article'>
        <div className='info'>
          <div className='row'>
            <h4 style={{ padding: '10px' }}>URL</h4>

            <div style={{ padding: '10px', backgroundColor: 'white', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <p>
                {
                  article && article.title
                    ? <a
                      style={{ textDecoration: 'none', color: 'black' }}
                      href={`https://app.packageroute.com/help/${this.state.id}`}>
                      https://app.packageroute.com/help/{this.state.id}
                    </a>
                    : 'Available once article is created.'
                }
              </p>

              <div className='copy' onClick={async () => {
                const clipboard = navigator.clipboard;

                await clipboard.writeText(`https://app.packageroute.com/help/${this.state.id}`);
              }}>
                <Icon name='content-copy' color='#666' />
              </div>
            </div>
          </div>

          {
            !this.state.isNew
              ? <div className='row'>
                <h4 style={{ padding: '10px' }}>Top Article</h4>

                <div style={{ padding: '10px', backgroundColor: 'white', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <InputText
                    id='top_article'
                    type='checkbox'
                    value={this.state.topArticle}
                    label={this.state.topArticle ? 'This is a top article' : 'Make this a top article.'}
                    checked={this.state.topArticle}
                    style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-end', alignItems: 'center', height: '30px' }}
                    inputStyle={{ width: '25px' }}
                    labelStyle={{ marginLeft: '5px', margin: '0' }}
                    onChange={() => this.toggleTopArticle()}
                  />
                </div>
              </div>
              : null
          }

          <div className='row'>
            <h4 style={{ padding: '10px' }}>Title</h4>

            <div
              style={{ display: 'flex', alignItems: 'center' }}>
              <InputText
                value={this.state.tempTitle}
                placeholder='Add a title'
                onChange={(e) => this.setState({ tempTitle: e.target.value })}
                inputStyle={{
                  border: 'none'
                }}
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  marginRight: '5px'
                }}
              />

              {
                this.state.tempTitle && this.state.tempTitle !== this.state.title
                  ? <Button
                    color='#168E32'
                    onClick={() => this.updateTitle(this.state.tempTitle)}>
                    {this.state.isNew && !this.state.title ? 'Add' : 'Update'}
                  </Button>
                  : null
              }
            </div>
          </div>

          <div className='row knowledge-section'>
            <div className='list'>
              <div className='header'>
                <h4>Categories</h4>

                <div className='select'>
                  <InputSelect
                    onChange={(e) => this.addCategory(e)}
                    value=''
                    options={[{value: '', name: 'Select Categories'}].concat(data.categories.map(category => {
                      return { value: category, name: category };
                    }))}
                  />
                </div>
              </div>

              {
                this.state.categories.length
                  ? this.state.categories.map((category, i) => {
                    return (
                      <div className='data-row' key={i}>
                        <CategoryCard category={category} />

                        <div className='remove-btn' onClick={() => this.removeCategory(category)}>
                          <Icon name='close' color='red' />
                        </div>
                      </div>
                    );
                  })
                  : <p style={{ padding: '10px' }}>
                    No categories added yet.
                  </p>
              }
            </div>
          </div>

          <div className='row knowledge-section'>
            <div className='list'>
              <div className='header'>
                <h4>Related Articles</h4>

                <div className='select'>
                  <InputSelect
                    onChange={(e) => this.addRelatedArticle(e)}
                    value=''
                    options={[{ value: '', name: 'Select Related Articles' }].concat(Object.values(data.articles)
                      .filter(article => {
                        if (this.state.id === article.id) return false;

                        return !this.state.relatedArticles.includes(article.id);
                      })
                      .map(article => {
                        return { value: article.id, name: article.title };
                      })
                    )}
                  />
                </div>
              </div>

              {
                this.state.relatedArticles.length
                  ? this.state.relatedArticles.map(id => {
                    const article = data.articles[id];

                    return (
                      <ArticleCard article={article}>
                        <div className='remove-btn' onClick={() => this.removeRelatedArticle(id)}>
                          <Icon name='close' color='red' />
                        </div>
                      </ArticleCard>
                    );
                  })
                  : <p style={{ padding: '10px' }}>
                    No related articles added yet.
                  </p>
              }
            </div>
          </div>

          {
            this.state.isNew && this.state.markdown && this.state.title
              ? <Button
                color='#168E32'
                onClick={this.createNewArticle}>
                Create New Article
              </Button>
              : !this.state.isNew
                ? <Button
                  color='red'
                  onClick={() => this.setState({ confirmDelete: true })}>
                  Delete Article
                </Button>
                : null
          }
        </div>

        <div className='markdown'>
          <div className='header'>
            <h3>Markdown</h3>

            {
              this.state.markdown
                ? <div style={{ display: 'flex', alignItems: 'center' }}>
                  <a
                    download={`${this.state.id}.md`}
                    href={URL.createObjectURL(new Blob([this.state.markdown], { type: 'text/plain' }))}
                    style={{ marginRight: '5px', textDecoration: 'none', color: '#444', padding: '7px 15px', fontSize: '0.9em' }}>
                    Download
                  </a>
                  <UploadTarget submitFiles={this.uploadFile} chooseMode />
                </div>
                : null
            }
          </div>

          <div className='content'>
            {
              !this.state.markdown
                ? <UploadTarget submitFiles={this.uploadFile} />
                : <Markdown options={{ html: true }}>{this.state.markdown}</Markdown>
            }
          </div>
        </div>

        {
          this.state.confirmDelete
            ? <div style={{ flex: 1, position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'white', border: '1px solid #ddd', borderRadius: '3px', padding: '10px' }}>
                <Button
                  color='#168E32'
                  onClick={() => this.setState({ confirmDelete: false })}>
                  Cancel
                </Button>

                <Button
                  color='red'
                  onClick={this.deleteArticle}>
                  Confirm Delete
                </Button>
              </div>
            </div>
            : null
        }
      </div>
    )
  }
}
