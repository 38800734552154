import React from 'react';
import moment from 'moment-timezone';

import highQualityGeocodingCSPHOC from './HighQualityGeocodingCSPHOC';
import Spinner from '../../common/Spinner';
import OnOff from '../Buttons/OnOff';

class HighQualityGeocodingCSP extends React.Component {
  render () {
    const { loading, highQualityCSPSetting, profile } = this.props;
    const isOn = highQualityCSPSetting && !highQualityCSPSetting.isExpired();

    return (
      <div 
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          minHeight: '50px',
          width: '100%'
        }}>
        <div>
          <p>{profile ? profile.CSPName : `Unknown / ${highQualityCSPSetting.operationId}`}</p>
        </div>

        <div 
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}>
          {
            !loading && isOn && !!highQualityCSPSetting.expiresAt &&
            <div style={{ padding: '10px' }}>
              <p>Expires {moment(highQualityCSPSetting.expiresAt).format('MMM Do')}</p>
            </div>
          }

          <div>
            {
              loading
                ? <Spinner />
                : <OnOff
                  on={isOn}
                  turnOn={this.props.enableHighQualityGeocoding}
                  turnOff={this.props.disableHighQualityGeocoding}
                  disabled={false}
                />
            }
          </div>
        </div>
      </div>
    )
  }
}

export default highQualityGeocodingCSPHOC(HighQualityGeocodingCSP);