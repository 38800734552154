import React, { CSSProperties } from 'react';

import './animations.css';

interface SlideOutProps {
  children?: JSX.Element | JSX.Element[];
  style?: CSSProperties;
  className?: string;
  visible?: boolean;
  duration?: string | number;
  from: number;
  to: number;
}

function SlideOut({ className = '', visible = false, duration = .3, children = [], style = {}, from, to }: SlideOutProps) {
  return (
    <div
      className={`Expander ${className}`}
      style={{
        height: "fitContents",
        overflow: "hidden",
        // backgroundColor: "transparent",
        // opacity: visible ? 1 : 0,
        transition: `${duration}s`,
        transform: `translateX(${visible ? to : from}px)`,
        ...style
      }}
    >
      { children }
    </div>
  );
};

interface SlideUpProps {
  children?: JSX.Element | JSX.Element[];
  style?: CSSProperties;
  className?: string;
  visible?: boolean;
  duration?: string | number;
  from: number;
  to: number;
}

function SlideUp ({ className = '', style = {}, children = [], from, to, duration = .3, visible = false }: SlideUpProps) {
  return (
    <div
      className={`SlideUp ${className}`}
      style={{
        ...style,
        height: to - from,
        overflow: 'hidden',
        // backgroundColor: 'transparent',
        transition: `${duration}s`,
        transform: `translateY(${visible ? from : to}px)`,
      }}
    >
      { children }
    </div>
  );

};

interface ExpanderProps {
  children?: JSX.Element | JSX.Element[];
  style?: CSSProperties;
  className?: string;
  visible?: boolean;
  duration?: string;
  onPress?: (event: React.SyntheticEvent) => void;
}
function Expander ({ children = [], style = {}, className = '', visible = false, duration = '.3s', onPress }: ExpanderProps) {
  return (
    <div
      className={`Expander ${className}`}
      onClick={(e) => onPress && onPress(e)}
      // @ts-ignore
      style={{
        height: 'fit-contents',
        overflow: 'hidden',
        backgroundColor: 'transparent',
        opacity: visible ? 1 : 0,
        transition: String(duration),
        transform: `translateY(${visible ? 0: '-100%'})`,
        boxShadow: '5px 2px 5px rgba(0, 0, 0, .1)',
        ...style
      }}
    >
      { children }
    </div>
  );
};


export {
  SlideOut,
  SlideUp,
  Expander
};
