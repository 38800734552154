import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Stripe from 'stripe';

export type CanceledSubscription = Stripe.subscriptions.ISubscription;
export type CanceledSubscriptions = CanceledSubscription[];

export enum Status {
  INITIAL = 'INITIAL',
  UPDATED = 'UPDATED',
  FAILED = 'FAILED',
}

type CanceledState = {
  status: Status;
  data: CanceledSubscriptions;
}

const initialState: CanceledState = {
  status: Status.INITIAL,
  data: [],
};

const canceledSlice = createSlice({
  name: 'canceledSubscriptions',
  initialState: initialState,
  reducers: {
    updateCanceled: (state, action: PayloadAction<{ subscriptions: CanceledSubscriptions }>) => {
      const { subscriptions } = action.payload;

      state.data = subscriptions;
      state.status = Status.UPDATED;
    },
    updateCanceledFailed: (state) => {
      state.status = Status.FAILED;
    },
    resetCanceled: (state) => {
      state.data = [];
      state.status = Status.INITIAL;
    }
  }
});

export const { 
  updateCanceled,
  updateCanceledFailed,
  resetCanceled,
} = canceledSlice.actions

export default canceledSlice.reducer;

