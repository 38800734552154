import React from 'react';
import moment from 'moment-timezone';

import highQualityGeocodingCSPHOC from '../../ServiceManagement/HighQualityGeocoding/HighQualityGeocodingCSPHOC';
import OnOff from '../../ServiceManagement/Buttons/OnOff';
import Spinner from '../../common/Spinner';

class HighQualityGeocoding extends React.Component {
  constructor (props) {
    super(props);
  }

  render () {
    const { profile, loading, highQualityCSPSetting } = this.props;
    const isOn = (highQualityCSPSetting && !highQualityCSPSetting.isExpired()) || false;
    
    return (
      <div 
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        <div>
          <p>High Quality Geocoding</p>
        </div>

        <div 
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}>
          {
            !loading && isOn && !!highQualityCSPSetting.expiresAt &&
            <div style={{ padding: '10px' }}>
              <p>Expires {moment(highQualityCSPSetting.expiresAt).format('MMM Do')}</p>
            </div>
          }

          <div>
            {
              loading
                ? <Spinner />
                : <OnOff
                  on={isOn}
                  turnOn={this.props.enableHighQualityGeocoding}
                  turnOff={this.props.disableHighQualityGeocoding}
                  disabled={false}
                />
            }
          </div>
        </div>
      </div>
    )
  }
}

export default highQualityGeocodingCSPHOC(HighQualityGeocoding);