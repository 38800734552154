import React from "react";
import Stripe from "stripe";
import SignupInfo from "./SignupInfo";
import Icon from "../Icon/Icon";
import { LiveIspProfile } from "@reducers/liveIspList";
import "./TrialItem.css";

type Subscription = Stripe.subscriptions.ISubscription;

type Props = {
  id: string | null;
  profile: LiveIspProfile;
  subscription: Subscription;
  onClick: () => void;
  wasTouched: boolean;
  week: string;
};

export default function TrialItem(props: Props) {
  const { id, profile, subscription, onClick, wasTouched, week } = props;

  return (
    <div key={id} className="TrialItem" onClick={onClick}>
      <div
        className="cspName"
        style={{ display: "flex", alignItems: "center" }}
      >
        <span>{profile.CSPName}</span>
        <span style={{ color: "#444", fontSize: "12px", marginLeft: "5px" }}>
          {profile?.station?.stationID ?? 'No station Id'}
        </span>

        {wasTouched ? (
          <span
            style={{
              display: "flex",
              color: "rgb(22, 142, 50)",
              marginLeft: "10px",
              fontSize: "12px",
            }}
          >
            <Icon name="check" color="rgb(22, 142, 50)" size={12} />

            <span style={{ marginLeft: "3px" }}>{week}</span>
          </span>
        ) : null}
      </div>

      <SignupInfo profile={profile} subscription={subscription} />
    </div>
  );
}
