import React, { useState, useCallback } from 'react';
import styled from "styled-components";
import { InputText, Notice,  } from "@components/Page";
import { Spinner } from "@common";
import { Button } from "@common";
import {DeleteUserConfirmationModal} from './DeleteUserConfirmationModal';
import consoleApiInitializer from '../../services/ConsoleApiInitializer'
import ConsoleUserService, { UserDeletionFailedError, UserDeletionFailedWithoutSendingEmailError } from '../../services/ConsoleUserService'

type Props = {
  userUid: string;
  onDeletion: () => void;
}

const DeleteUserContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
`

const InfoTextWrap = styled.div`
  margin-bottom: 10px;
`

const ErrorText = styled.p`
  margin-bottom: 10px;
  font-size: 16px;
  color: #B90C0E;
  font-weight: 500;
`

const SuccessText = styled.p`
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`
export default function DeleteUser ({ userUid, onDeletion }: Props) {
  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [deleting, setDeleting] = useState(false);
    
  const handleDeleteUser = async (shouldSendEmailNotices: boolean = true) => {
    try {
      if (deleting || success) return;

      setSuccess(false);
      setDeleting(true);
      setShowConfirmationModal(false);
      setErrorMessage([]);

      const service = new ConsoleUserService(consoleApiInitializer);

      await service.deleteUser(userUid, shouldSendEmailNotices)

      setSuccess(true);

      setTimeout(() => onDeletion(), 3000);
    } catch (err) {
      if (err instanceof UserDeletionFailedWithoutSendingEmailError) {
        setErrorMessage([`User deletion failed before sending confirmation email`])
      } else if (err instanceof UserDeletionFailedError) {
        setErrorMessage([
          `User deletion failed AFTER sending confirmation email. Do NOT send email in reattempt.`,
          `Please send screenshot of error to developers`,
          err.message,
        ])
      } else if (err instanceof Error) {
        setErrorMessage([
          `User deletion failed for unhandled reason`,
          err.message,
        ])
      } else {
        setErrorMessage([
          `User deletion failed for unknown reason: ${err}`,
        ])
      }
    } finally {
      setDeleting(false);
    }
  }

  return (
    <DeleteUserContainer>
      <InfoTextWrap>
        {success && <SuccessText>User successfully deleted! Reloading...</SuccessText>}

        {errorMessage.map((line) => {
          return (
            <ErrorText>{line}</ErrorText>
          )
        })}
      </InfoTextWrap>

      <Button color="#B90C0E" onPress={() => setShowConfirmationModal(true)}>
        Delete User
      </Button>

      {showConfirmationModal &&
        <DeleteUserConfirmationModal 
          deleteUser={handleDeleteUser}
          deleting={deleting}
          close={() => setShowConfirmationModal(false)}
        />
      }
    </DeleteUserContainer>
  )
}