import React from "react";
import CancellationReasonInfo from "./CancellationReasonInfo";
import { LiveIspProfile } from "@reducers/liveIspList";
import { IspCancellationReason } from "@reducers/cancellationReasons";
import "./CancellationReasonItem.css";
import "./CancellationItem.css";

type Props = {
  id: string | null;
  profile: LiveIspProfile | null;
  cancellationReason: IspCancellationReason;
  onClick: () => void;
};

export default function CancellationReasonItem(props: Props) {
  const { id, profile, cancellationReason, onClick } = props;
  
  return (
    <div key={id} className="CancellationItem" onClick={onClick}>
      <p className="cspName">
        {profile?.CSPName ?? 'No ISP name'}{" "}
        <span style={{ color: "#444", fontSize: "12px" }}>
          {profile?.station?.stationID ?? 'No station Id'}
        </span>
      </p>

      <CancellationReasonInfo profile={profile} cancellationReason={cancellationReason} />

      <div style={{ flexDirection: 'column', marginTop: 5 }}>
        {Object.keys(cancellationReason.feedback ?? {}).map((reason, i) => {
          const comment = cancellationReason.feedback[reason];
          
          return (
            <p className='reason'>{typeof comment === 'boolean' ? reason : `${reason}: ${comment}`}</p>
          )
        })
        }
      </div>
    </div>
  );
}
