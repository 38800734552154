import React from 'react';

import UserStationItem from './UserStationItem';
import './UserStations.css';
import api from '../../utils/api';

export default class UserStations extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      stations: []
    }

    this.fetchStations = this.fetchStations.bind(this);
  }

  componentDidMount () {
    const { isps } = this.props;

    this.fetchStations();
  }

  componentDidUpdate (prevProps) {
    if (!this.state.stations.length && prevProps.isps !== this.props.isps) {
      this.fetchStations();
    }
  }

  async fetchStations () {
    const { isps } = this.props;
    const promises = [];

    for (const profile of isps) {
      if (profile.station && profile.station.stationID) {
        const stationId = profile.station.stationID;

        promises.push(new Promise(async (resolve, reject) => {
          const res = await api.request(`stations/firebase/${stationId}`);

          resolve(res.data);
        }));
      }
    }

    const stations = await Promise.all(promises);

    this.setState({ stations });
  }

  render () {
    const { stations } = this.state;
    const { user, activeStations } = this.props;
    const canAddAdvocate = !!activeStations;

    return (
      <div className='UserStations'>
        {
          stations && stations.map(station => {
            if (!station) return null;

            const stationId = station.terminal;
            const activeStation = (activeStations || {})[stationId];

            return (
              <UserStationItem
                fetchStations={this.fetchStations}
                stationId={stationId}
                station={station}
                user={user}
                activeStation={activeStation}
                editable={canAddAdvocate}
              />
            )
          })
        }
      </div>
    );
  }
}
