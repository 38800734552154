import { CancellationReasons } from "@reducers/cancellationReasons";
import ConsoleApiService from './ConsoleApiService';
import {IConsoleApiInitializer} from "./ConsoleApiInitializer";

export default class ProductCancellationReasonsService {
  private api: ConsoleApiService;

  constructor(consoleApiInitializer: IConsoleApiInitializer) {
    this.api = consoleApiInitializer.initialize();
  }

  async get(): Promise<CancellationReasons> {
    try {
      const path = this._getApiPath();
  
      const response = await this.api.get<{ reasons: CancellationReasons }>(path);

      return response.reasons;
    } catch (err) {
      console.error(err);

      throw err;
    }
  }

  private _getApiPath() {
    return `subscriptions/cancellation-reasons`;
  }
}
