import React from "react";
import moment from "moment-timezone";

import Record from "./Record";

import Icon from "../../Icon/Icon";
import "./History.css";

export default class History extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeUserList: null
    };
  }

  render() {
    const { activeUserList } = this.state;
    const { history, consoleUsers } = this.props;

    return (
      <div className="History">
        <div className="labels">
          <div className="status label">
            <p>Status</p>
          </div>

          <div className="description label">
            <p>Description</p>
          </div>

          <div className="timestamp label">
            <p>Date/Time</p>
          </div>
        </div>

        <div className="list">
          <div className="wrapper">
            {Object.keys(history)
              .sort((a, b) => {
                const aItem = history[a];
                const bItem = history[b];

                return bItem.finishedAt - aItem.finishedAt;
              })
              .map(pushKey => {
                const item = history[pushKey];
                const user = consoleUsers && consoleUsers[item.sentByUID];
                const clickable = !!item.response;
                const selected = activeUserList === pushKey;

                return (
                  <Record
                    key={pushKey}
                    pushKey={pushKey}
                    item={item}
                    user={user}
                    clickable={clickable}
                    selected={selected}
                  />
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}
