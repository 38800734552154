import {useEffect, useState} from "react";
import {FineGrainedPermission, Permission} from "@reducers/permissions";
import {useHistory} from "react-router-dom";
import {
    useConsoleUser,
    useConsoleUsersLoaded,
    usePermissions as usePermissionsSets,
    usePermissionsLoaded
} from "@hooks/redux";
import {ConsoleUser} from "@reducers/consoleUsers";

export default function usePermissions(permissionsSetKey: string, shouldRedirect?: boolean) {
    const [hasPermission, setHasPermission] = useState(false);
    const [fineGrainedPermissions, setFineGrainedPermissions] = useState<FineGrainedPermission[]>([])
    const history = useHistory();

    const consoleUser = useConsoleUser();
    const consoleUserLoaded = useConsoleUsersLoaded();
    const permissionSets = usePermissionsSets();
    const permissionsLoaded = usePermissionsLoaded();

    useEffect(() => {
        const hasPermissions = getHasPermissions(
            consoleUser,
            permissionSets[permissionsSetKey]
        );

        const probhibitedFromAccessingPage =
            consoleUserLoaded && permissionsLoaded && !hasPermissions;

        if (probhibitedFromAccessingPage && shouldRedirect) {
            history.push("prohibited");
        } else {
            setHasPermission(hasPermissions);
        }
        setFineGrainedPermissions(getFineGrainedPermissions(consoleUser, permissionSets[permissionsSetKey]))
    }, [
        permissionsSetKey,
        shouldRedirect,
        consoleUser,
        consoleUserLoaded,
        permissionSets,
        permissionsLoaded,
    ]);

    return {
        loading: !(consoleUserLoaded && permissionsLoaded),
        hasPermission,
        fineGrainedPermissions
    };
}

function getHasPermissions(
    consoleUser: ConsoleUser | null,
    permissionSet: Permission | undefined
) {
    if (!consoleUser || !permissionSet) return false;

    const allowedPermissions = permissionSet.permissions;
    const { permissions } = consoleUser!;

    // if user has super user permission, allow by default
    if (permissions.includes("superUser")) {
        return true;
    }

    // find if the user has 1 matching permission
    for (const permission of allowedPermissions) {
        if (permissions.includes(permission)) {
            return true;
        }
    }

    return false;
}

function getFineGrainedPermissions(
    consoleUser: ConsoleUser | null,
    permissionSet:  Permission | undefined,
): FineGrainedPermission[] {
    if (!consoleUser || !permissionSet || !permissionSet?.fineGrainedPermissions) return [];

    const { permissions } = consoleUser;

    if (permissions.includes('superUser')) {
        return Object.keys(permissionSet.fineGrainedPermissions) as FineGrainedPermission[];
    }

    return Object.keys(permissionSet.fineGrainedPermissions)
        .filter(key => permissionSet.fineGrainedPermissions[key as FineGrainedPermission]
            .some(permission => permissions.includes(permission))
        ) as FineGrainedPermission[];
}