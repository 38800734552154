import { Dispatch } from "@reduxjs/toolkit";
import {
  updateConsoleUsers,
  resetConsoleUsers as _resetConsoleUsers,
  ConsoleUsers,
} from "@reducers/consoleUsers";
import firebaseProvider from '@utils/firebase';

export async function fetchConsoleUsers (dispatch: Dispatch) {
  try {
    const database = firebaseProvider.getConsoleDefaultDB();

    const ref = database.ref('consoleUsers');

    const snap = await ref.once('value');

    const consoleUsers: Omit<ConsoleUsers, 'uid'> = snap.val() ?? {};
    
    const consoleUsersWithUid = Object.entries(consoleUsers).reduce<ConsoleUsers>((acc, [uid, user]) => {
      user.uid = uid;

      acc[uid] = user;

      return acc;
    }, {});

    dispatch(updateConsoleUsers({ consoleUsers: consoleUsersWithUid }));

    return consoleUsers;
  } catch (err) {
    console.error(err);
  }
}

export async function resetConsoleUsers (dispatch: Dispatch) {
  try {
    dispatch(_resetConsoleUsers());
  } catch (err) {
    console.error(err);

    throw err;
  }
}
