import React from "react";

import ScrollView from "../../Page/ScrollView";
import EnabledIsp from "./EnabledIsp";

import { arrayify } from "../../../utils/formatting";
import { isEmpty, isUndefined } from "../../../utils/typeCheck";

import "./EnabledIspsList.css";

export default function EnabledIspsList({isps, liveISPList, history}) {
  let ISPlist = null;

  if (!isEmpty(isps) && !isEmpty(liveISPList)) {
    ISPlist = arrayify(isps, "ISPID")
      .filter(isp => !isUndefined(liveISPList[isp.ISPID]))
      .map(isp => ({...liveISPList[isp.ISPID], ...isp}))
      .sort((a, b) => (a.CSPName < b.CSPName ? -1 : 1))
      .map(isp => <EnabledIsp key={isp.ISPID} isp={isp} history={history} />);
  }

  return <ScrollView direction={'vertical'} height={'500px'}>{ISPlist}</ScrollView>;
}
