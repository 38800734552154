import React from 'react';
import { HighQualityCSP } from '@packageroute/biz/lib/service-settings/geocoding';

import api from '../../../utils/api';

export default function HighQualityGeocodingCSPHOC (Component) {
  return class extends React.Component {
    constructor (props) {
      super(props);

      const { highQualityCSP } = props;

      this.state = {
        highQualityCSPSetting: highQualityCSP || null
      }

      this.fetchHighQualityCSPSetting = this.fetchHighQualityCSPSetting.bind(this);
      this.enableHighQualityGeocoding = this.enableHighQualityGeocoding.bind(this);
      this.disableHighQualityGeocoding = this.disableHighQualityGeocoding.bind(this);
    }

    async componentDidMount () {
      if (!this.state.highQualityCSPSetting) {
        await this.fetchHighQualityCSPSetting();
      }
    }

    async fetchHighQualityCSPSetting () {
      try {
        this.setState({ loading: true });

        const { operationId } = this.props;      
        const res = await api.request(`service-management/high-quality-geocoding/csps/${operationId}`);
        const data = res.data;

        if (!data) return;

        this.setState({ highQualityCSPSetting: new HighQualityCSP(data), loading: false });
      }
      catch (err) {
        console.error(err);

        this.setState({ loading: false });
      }
    }

    async enableHighQualityGeocoding () {
      try {
        const { operationId } = this.props;

        await api.post(`service-management/high-quality-geocoding/csps/${operationId}`);

        await this.fetchHighQualityCSPSetting();
      }
      catch (err) {
        console.error(err);
      }
    }

    async disableHighQualityGeocoding () {
      try {
        const { operationId } = this.props;

        await api.deleteRequest(`service-management/high-quality-geocoding/csps/${operationId}`);

        await this.fetchHighQualityCSPSetting();
      }
      catch (err) {
        console.error(err);
      }
    }

    render () {
      return (
        <Component
          enableHighQualityGeocoding={this.enableHighQualityGeocoding}
          disableHighQualityGeocoding={this.disableHighQualityGeocoding}
          {...this.props}
          {...this.state}
        />
      )
    }
  }
}