import React from 'react';

import Icon from '../Icon/Icon';
import { View } from 'react-native';
import { isEmpty } from '../../utils/typeGuards';
import { capMinMax } from '../../utils/functional';

import './Swiper.css';

export default class Swiper extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      index: this.props.initialIndex || 0,
      layouts: [],
      updating: false,
      scrollTo: 0
    }

    this.swiperRef = React.createRef();

    window.scrollBy = this.swiperRef;
  }

  componentDidMount () {
    const {swiperRef, props} = this;

    if (swiperRef && props.setSwiperRef && !isEmpty(swiperRef)) {
      props.setSwiperRef(this);
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { props, state, scrollBy } = this;

    if (prevProps.index !== props.index) {
      const diff = props.index - prevProps.index;

      scrollBy(diff, false);
    }
  }

  // FIXME: This is currently turned off because it results in a runaway
  // loop which may go all the way to the end then refuse to return to prior
  // items. It's a feedback look between css properties like scroll-snap-align
  // scrollTo and momentum based scrolling. Haven't figured it out :P

  // Find item currently in focus based upon scroll position
  // and element widths, if the index is updated, notify subscriber
  // onScrollUpdateActiveIndex = (e) => {
  //   const { layouts = [], index, updating } = this.state;
  //   const { vertical } = this.props;

    
  //   const scrollPosition = `scroll${vertical ? 'Top' : 'Left'}`;
  //   const scrolledBy = e.target[scrollPosition];
    
  //   e.persist();
    
  //   const layoutOffsets = [];
  //   layouts.forEach(({ index, offset }) => layoutOffsets[index] = offset);
    
  //   let offsetSum = 0, indexOfItem = null;
  //   for (let i = 0; i < layoutOffsets.length; i++) {
  //     if (scrolledBy >= offsetSum && scrolledBy < offsetSum + layoutOffsets[i]) {
  //       indexOfItem = i;
  //       break;
  //     } else if (scrolledBy >= offsetSum + layoutOffsets[i]) {
  //       offsetSum += layoutOffsets[i];
  //     } 
  //   }
  //   console.log('updating scroll', index, indexOfItem, indexOfItem === index, updating);

  //   if (updating) {
  //     if (indexOfItem === index) {
  //       this.setState({ updating: false })
  //     }
  //   } else if (index !== indexOfItem){
  //     this.props.onIndexChanged(indexOfItem);
  //   }
  // }

  // Need to get the dimensions of each element in order to 
  // calculate the current index in focus based upon scroll position
  // Forced into an odd solution by setState batching wierdness
  renderSwipeables = () => {
    const { renderItems = [], render = () => null, vertical = false } = this.props;

    const renderedItems = renderItems.map((item, i) => {
      return (
        <View 
          key={i}
          snapOnScroll={!this.state.updating}
          scrollSnapAlign='start'
          style={{
            [vertical ? 'width': 'height']: 'fit-content'
          }}
          onLayout={({ nativeEvent }) => {
            const { height, width } = nativeEvent.layout;

            this.setState((prevState) => {
              return {
                ...prevState,
                layouts: [
                  ...prevState.layouts, 
                  { index: i, offset: vertical ? height : width }
                ]
              }
            })
          }}
        >{render(item)}</View>
      )
    })

    return renderedItems;
  }

  scrollBy = (diff, animated) => {
    const { updating, scrollTo } = this.state;

    if (!updating && this.swiperRef) {
      this.setState({ updating: true }, () => {
        console.log('scrollBy!')
        const { vertical } = this.props;
        const { index, layouts } = this.state;
        const nextIndex = capMinMax(0, layouts.length - 1)(index + diff);
        
        let newScrollTo = 0;
        for (let i = 0; i <= nextIndex; i++) {
          newScrollTo += layouts[i].offset;
        }

        console.log('newScrollTo', newScrollTo, nextIndex);

        // const offsetBy = newScrollTo - scrollTo 
        // this.swiperRef.current.scrollBy({
        this.swiperRef.current.scrollTo({
          top: vertical ? newScrollTo : 0,
          left: vertical ? 0 : newScrollTo,
          behavior: animated ? 'smooth' : 'auto'
        })

        this.setState({ index: nextIndex, scrollTo: newScrollTo });
      })
    }
  }

  render () {
    const {
      style = {}, 
      className = '',
      vertical = false, 
      setSwiperRef, 
      loadMinimal = false, 
      loadMinimalSize = 12, 
      initialIndex = 0,
      index = 0,
      onIndexChanged = () => {}, 
      loadMinimalLoader = null, 
      showsPagination = false, 
      renderItems = [], 
      render = () => null
    } = this.props;

    const { updating } = this.state;

    const averageItemSize =
      this.state.layouts.reduce((sum, { offset }) => (sum += offset), 0)
      / renderItems.length;

    return (
      <div className='SwiperWrap' style={style}>
        <div 
          className='Left SwiperOverlay'
          onClick={() => onIndexChanged(Math.max(index - 1, 0))}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [`margin${vertical ? 'Top' : 'Left'}`]: 5,
            [`padding${vertical ? 'Bottom' : 'Right'}`]: 5,
            height: !vertical ? (style.height || '100%') : `calc((100% - ${averageItemSize}px) / 2)`,
            width: vertical ? (style.width || '100%') : `calc((100% - ${averageItemSize}px) / 2)`,
            background: `linear-gradient(90deg, rgba(245, 245, 245, .8) 80%, rgba(245, 245, 245, 0) 100%)`,
            [`border${vertical ? 'Right' : 'Bottom'}`]: 'solid 1px #DDD',
            ...(vertical ? { top: 0 } : { left: 0 })
          }}   
        >
          <Icon name='chevron-double-left' size={40} color={index === 0 ? '#DDD' : 'black'} />
        </div>
        <div 
          className='Right SwiperOverlay'
          onClick={() => onIndexChanged(Math.min(index + 1, renderItems.length - 1))} 
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            [`margin${vertical ? 'Bottom' : 'Right'}`]: 5,
            [`padding${vertical ? 'Top' : 'Left'}`]: 5,
            height: !vertical ? (style.height || '100%') : `calc((100% - ${averageItemSize}px) / 2)`,
            width: vertical ? (style.width || '100%') : `calc((100% - ${averageItemSize}px) / 2)`,
            background: `linear-gradient(270deg, rgba(245, 245, 245, .8) 80%, rgba(245, 245, 245, 0) 100%)`,
            [`border${vertical ? 'Left' : 'Bottom'}`]: 'solid 1px #DDD',
            ...(vertical ? { bottom: 0 } : { right: 0 })
          }}     
        >
          <Icon name='chevron-double-right' size={40} color={index === renderItems.length - 1 ? '#DDD' : 'black'} />
        </div>
        <div 
          className={`Swiper ${className}`}
          ref={this.swiperRef}
          style={{
            pointerEvents: 'none',
            display: 'flex',
            width: averageItemSize || style.width || 300,
            [`margin${vertical ? 'Top' : 'Left'}`]: 'auto',
            [`margin${vertical ? 'Bottom' : 'Right'}`]: 'auto',
            flexDirection: vertical ? 'column' : 'row',
            [`overflow${vertical ? 'Y' : 'X'}`]: 'scroll',
            scrollBehavior: 'smooth',
            scrollSnapType: `${vertical ? 'y' : 'x'} mandatory`,
          }}
          // Turned this off because I'm getting some really weird
          // interactions between the css scroll-snap properties
          // and on scroll, and I can't tell the difference between
          // a programmatic scroll, a user initiated scroll, and
          // a scroll caused by the momentum of the runnaway cycle
          // onScoll={this.onScrollUpdateActiveIndex}
        >
          {renderItems.length > 0 ? 
            this.renderSwipeables()
            : loadMinimal && loadMinimalLoader ?
              loadMinimalLoader
              : null
          }
        </div>
      </div>
    );
  }
}
