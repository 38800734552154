import React from "react";
import { StripeLink } from "@common";
import { OperationTransfer } from "@models";
import "./TransferOwnershipModal.css";

type Props = {
  transfer: OperationTransfer;
}
export default function StripeChanges ({ transfer }: Props) {
  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
        <p style={{ minWidth: 120 }}>Former Owner</p>
        <StripeLink type='cus' id={transfer.formerOwner.customerId!} showTitle />
        <StripeLink type='sub' id={transfer.subscription.formerSubscriptionId} showTitle />
      </div>
      
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <p style={{ minWidth: 120 }}>New Owner</p>
        <StripeLink type='cus' id={transfer.newOwner.customerId!} showTitle />
        <StripeLink type='sub' id={transfer.subscription.newSubscriptionId} showTitle />
      </div>
    </div>
  )
}