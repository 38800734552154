import React from 'react';

import './style/form.css';
import moment from 'moment';
import InputText from '../../Page/InputText';
import InputRadio from '../../Page/InputRadio';
import InputDate from '../../Page/InputDate';
import InputSelect from '../../Page/InputSelect';
import Button from '../../Page/Button';
import salesCycleStatus, { levelsMap, getLevelName } from '../../../utils/salesCycleStatus';

export default class BusinessForm extends React.Component {
  constructor (props) {
    super(props);

    const isp = props.isp || {};



    this.state = {
      nextISP: {
        referred: isp.referred || null,
        referredDescription: isp.referredDescription || null,
        additionalRoutes: isp.totalRoutes || null,
        city: isp.city || null,
        state: isp.state || null,
        groundRoutes: isp.groundRoutes || null,
        hdRoutes: isp.hdRoutes || null,
        suppRoutes: isp.suppRoutes || null,
        projectedConversionRate: isp.projectedConversionRate || null,
        numEntities: isp.numEntities || null,
        initialContact: isp.initialContact || null,
        notInterested: isp.notInterested || null,
        churned: isp.churned || null,
        lead: isp.lead || null,
        signup: isp.signup || null,
        trial: isp.trial || null,
        qualified: isp.qualified || null,
        qualifiedDescription: isp.qualifiedDescription,
        projectedClose: isp.projectedClose,
        timeZone: isp.timeZone || null
      },
      errorMessage: null
    };

    this.updateField = this.updateField.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
  }

  submitForm (e) {
    try {
      e.preventDefault();

      const { isp } = this.props;

      const nextISP = {
        ...this.state.nextISP
      };

      const {
        projectedConversionRate,
        groundRoutes,
        hdRoutes,
        suppRoutes,
        additionalRoutes
      } = nextISP

      if (projectedConversionRate && (isNaN(parseInt(projectedConversionRate, 10)) || parseInt(projectedConversionRate, 10) > 100 || parseInt(projectedConversionRate, 10) < 0)) {
        throw new Error('Projected Conversion Rate must be an integer between 0 and 100.');
      }

      if ((groundRoutes && isNaN(parseInt(groundRoutes, 10))) || (hdRoutes && isNaN(parseInt(hdRoutes, 10))) || (suppRoutes && isNaN(parseInt(suppRoutes, 10))) || (additionalRoutes && isNaN(parseInt(additionalRoutes, 10)))) {
        throw new Error(`Routes #'s must be integers`);
      }

      for (const prop in nextISP) {
        if (this.state.nextISP[prop] === isp[prop]) {
          delete nextISP[prop];
        }
      }

      if (!Object.keys(nextISP).length) {
        return;
      }

      console.log(nextISP);

      this.props.updateISP(nextISP, (err) => {
        if (err) {
          throw err;
        }
        else {
          this.props.toggleProp('editingBusiness');
        }
      })
    }
    catch(err) {
      this.setState({ errorMessage: err.message || 'Something went wrong.' });
    }
  }

  updateField (name, { target }) {
    let { value } = target;

    if ((name === 'timeZone' || name === 'projectedClose') && value === '') {
      value = null;
    }

    this.setState(prevState => {
      const nextISP = {
        ...prevState.nextISP,
        referredDescription: null,
        [name]: value
      }

      return { nextISP };
    });
  }

  updateStatus (name) {
    const timestamp = moment().toISOString();
    const level = levelsMap[name];

    this.setState(prevState => {
      const nextStatus = {};
      const lastISP = prevState.nextISP;
      const prevItem = lastISP[name];

      if (prevItem) {
        nextStatus[name] = null;
      }
      else if (!prevItem) {
        for (let i = 0; i <= level; i++) {
          const itemName = getLevelName(i);

          if (!lastISP[itemName]) {
            nextStatus[itemName] = timestamp;
          }
        }
      }

      const nextISP = {
        ...prevState.nextISP,
        ...nextStatus
      }

      return { nextISP };
    })
  }

  render () {
    const status = salesCycleStatus(this.state.nextISP);
    const today = moment().format('YYYY-MM-DD');
    let projectedClose;

    if (this.state.nextISP.projectedClose) {
      projectedClose = moment(this.state.nextISP.projectedClose).format('YYYY-MM-DD');
    }

    return (
      <div className='Form'>
        <form onSubmit={this.submitForm}>
          <div className='inputs'>
            <div className='section column'>
              <div className='status'>
                <h3>Sales Cycle Status</h3>

                <div className='box'>
                  <div className='input-container status-checkbox'>
                    <div style={{ width: '50%' }}>
                      <InputText
                        id='lead'
                        type='checkbox'
                        label='Lead'
                        value={this.state.nextISP.lead}
                        checked={!!this.state.nextISP.lead}
                        disabled={levelsMap['lead'] < status}
                        style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-end', alignItems: 'center', height: '30px'  }}
                        inputStyle={{ width: '25px' }}
                        labelStyle={{ marginLeft: '5px', margin: '0' }}
                        onChange={() => this.updateStatus('lead')}
                      />
                    </div>

                    <div className='timestamp'>
                      {
                        this.state.nextISP.lead
                        ? <span>Started on {moment(this.state.nextISP.lead).format('MM-DD-YYYY')}</span>
                        : null
                      }
                    </div>
                  </div>
                  <div className='input-container status-checkbox'>
                    <div style={{ width: '50%' }}>
                      <InputText
                        id='initialContact'
                        type='checkbox'
                        label='Initial Contact'
                        value={this.state.nextISP.initialContact}
                        checked={!!this.state.nextISP.initialContact}
                        disabled={levelsMap['initialContact'] < status}
                        style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-end', alignItems: 'center', height: '30px'  }}
                        inputStyle={{ width: '25px' }}
                        labelStyle={{ marginLeft: '5px', margin: '0' }}
                        onChange={() => this.updateStatus('initialContact')}
                      />
                    </div>

                    <div className='timestamp'>
                      {
                        this.state.nextISP.initialContact
                          ? <span>Started on {moment(this.state.nextISP.initialContact).format('MM-DD-YYYY')}</span>
                          : null
                      }
                    </div>
                  </div>

                  <div className='input-container status-checkbox column'>
                    <div className='qualified'>
                      <div style={{ width: '50%' }}>
                        <InputText
                          id='qualified'
                          type='checkbox'
                          label='Qualified'
                          value={this.state.nextISP.qualified}
                          checked={!!this.state.nextISP.qualified}
                          disabled={levelsMap['qualified'] < status}
                          style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-end', alignItems: 'center', height: '30px'  }}
                          inputStyle={{ width: '25px' }}
                          labelStyle={{ marginLeft: '5px', margin: '0' }}
                          onChange={() => this.updateStatus('qualified')}
                        />
                      </div>

                      <div className='timestamp'>
                        {
                          this.state.nextISP.qualified
                          ? <span>Started on {moment(this.state.nextISP.qualified).format('MM-DD-YYYY')}</span>
                          : null
                        }
                      </div>
                    </div>

                    <div className='input-container status-checkbox'>
                      <InputText
                        id='qualifiedDescription'
                        label={null}
                        placeholder='Qualifications description'
                        value={this.state.nextISP.qualifiedDescription}
                        onChange={(val) => this.updateField('qualifiedDescription', val)}
                      />
                    </div>
                  </div>


                  <div className='input-container status-checkbox'>
                    <div style={{ width: '50%' }}>
                      <InputText
                        id='trial'
                        type='checkbox'
                        label='Trial'
                        value={this.state.nextISP.trial}
                        checked={!!this.state.nextISP.trial}
                        disabled={levelsMap['trial'] < status}
                        style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-end', alignItems: 'center', height: '30px'  }}
                        inputStyle={{ width: '25px' }}
                        labelStyle={{ marginLeft: '5px', margin: '0' }}
                        onChange={() => this.updateStatus('trial')}
                      />
                    </div>

                    <div className='timestamp'>
                      {
                        this.state.nextISP.trial
                        ? <span>Started on {moment(this.state.nextISP.trial).format('MM-DD-YYYY')}</span>
                        : null
                      }
                    </div>
                  </div>

                  <div className='input-container status-checkbox'>
                    <div style={{ width: '50%' }}>
                      <InputText
                        id='signup'
                        type='checkbox'
                        label='Signed Up'
                        value={this.state.nextISP.signup}
                        checked={!!this.state.nextISP.signup}
                        disabled={levelsMap['signup'] < status}
                        style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-end', alignItems: 'center', height: '30px'  }}
                        inputStyle={{ width: '25px' }}
                        labelStyle={{ marginLeft: '5px', margin: '0' }}
                        onChange={() => this.updateStatus('signup')}
                      />
                    </div>
                    <div className='timestamp'>
                      {
                        this.state.nextISP.signup
                          ? <span>Started on {moment(this.state.nextISP.signup).format('MM-DD-YYYY')}</span>
                          : null
                      }
                    </div>
                  </div>

                  <div className='input-container status-checkbox'>
                    <div style={{ width: '50%' }}>
                      <InputText
                        id='notInterested'
                        type='checkbox'
                        label='Not Interested'
                        value={this.state.nextISP.notInterested}
                        checked={!!this.state.nextISP.notInterested}
                        style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-end', alignItems: 'center', height: '30px' }}
                        inputStyle={{ width: '25px' }}
                        labelStyle={{ marginLeft: '5px', margin: '0' }}
                        onChange={() => {
                          const e = {
                            target: { value: moment().toISOString() }
                          };

                          if (this.state.nextISP.notInterested) {
                            e.target.value = null;
                          }

                          this.updateField('notInterested', e);
                        }}
                      />
                    </div>
                  </div>

                  <div className='input-container status-checkbox'>
                    <div style={{ width: '50%' }}>
                      <InputText
                        id='churned'
                        type='checkbox'
                        label='Churned'
                        value={this.state.nextISP.churned}
                        checked={!!this.state.nextISP.churned}
                        style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-end', alignItems: 'center', height: '30px' }}
                        inputStyle={{ width: '25px' }}
                        labelStyle={{ marginLeft: '5px', margin: '0' }}
                        onChange={() => {
                          const e = {
                            target: { value: moment().toISOString() }
                          };

                          if (this.state.nextISP.churned) {
                            e.target.value = null;
                          }

                          this.updateField('churned', e);
                        }}
                      />
                    </div>

                    <div className='timestamp'>
                      {
                        this.state.nextISP.churned
                          ? <span>As of {moment(this.state.nextISP.churned).format('MM-DD-YYYY')}</span>
                          : null
                      }
                    </div>
                  </div>

                  <div className='input-container status-checkbox' style={{ justifyContent: 'center', marginTop: '10px' }}>
                    <div style={{ width: '50%' }}>
                      <InputDate
                        id='projectedClose'
                        name='projectedClose'
                        label='Projected Close Date'
                        min={today}
                        value={projectedClose}
                        onChange={(val) => this.updateField('projectedClose', val)}
                      />
                    </div>
                  </div>
                </div>
              </div>


              <div className='input-container'>
                <InputText
                  id='numEntities'
                  label='Number of Entities'
                  value={this.state.nextISP.numEntities}
                  onChange={(val) => this.updateField('numEntities', val)}
                />
              </div>

              <div className='input-container'>
                <InputRadio
                  label='Referred By'
                  onCheckedChange={(val) => this.updateField('referred', val)}
                  onTextChanged={(val) => this.updateField('referredDescription', val)}
                  checkedItem={this.state.nextISP.referred}
                  text={this.state.nextISP.referredDescription}
                  options={[
                    { value: 'Email', name: 'Email' },
                    { value: 'Another ISP', name: 'Another ISP', withText: true },
                    { value: 'MyGroundBiz', name: 'MyGroundBiz' },
                    { value: 'Web Search', name: 'Web Search' },
                    { value: 'Other', name: 'Other', withText: true }
                  ]}
                />
              </div>
            </div>

            <div className='section column'>
              <div className='input-container'>
                <InputText
                  id='city'
                  label='City'
                  value={this.state.nextISP.city}
                  onChange={(val) => this.updateField('city', val)}
                />
              </div>

              <div className='input-container'>
                <InputText
                  id='state'
                  label='State'
                  value={this.state.nextISP.state}
                  onChange={(val) => this.updateField('state', val)}
                />
              </div>

              <div className='input-container'>
                <InputText
                  id='ground'
                  label='# of Ground Routes'
                  type='number'
                  value={this.state.nextISP.groundRoutes || 0}
                  onChange={(val) => this.updateField('groundRoutes', val)}
                />
              </div>

              <div className='input-container'>
                <InputText
                  id='hdRoutes'
                  label='# of HD Routes'
                  type='number'
                  value={this.state.nextISP.hdRoutes || 0}
                  onChange={(val) => this.updateField('hdRoutes', val)}
                />
              </div>

              <div className='input-container'>
                <InputText
                  id='supp'
                  label='# of Supplemental Routes'
                  type='number'
                  value={this.state.nextISP.suppRoutes || 0}
                  onChange={(val) => this.updateField('suppRoutes', val)}
                />
              </div>

              <div className='input-container'>
                <InputText
                  id='additionalRoutes'
                  label='# of Additional Routes'
                  type='number'
                  value={this.state.nextISP.additionalRoutes || 0}
                  onChange={(val) => this.updateField('additionalRoutes', val)}
                />
              </div>

              <div className='input-container'>
                <InputText
                  id='projectedConversionRate'
                  label='Project Conversion Rate'
                  type='number'
                  value={this.state.nextISP.projectedConversionRate || 0}
                  min='0'
                  max='100'
                  onChange={(val) => this.updateField('projectedConversionRate', val)}
                />
              </div>

              <div className='input-container'>
                <InputSelect
                  id='timeZone'
                  label='Time Zone'
                  type='text'
                  value={this.state.nextISP.timeZone}
                  onChange={(e) => this.updateField('timeZone', e)}
                  options={[
                    { value: '', name: 'None' },
                    { value: 'America/Juneau', name: '(GMT-09:00) Juneau' },
                    { value: 'America/Los_Angeles', name: '(GMT-08:00) Los Angeles' },
                    { value: 'America/Tijuana', name: '(GMT-08:00) Tijuana' },
                    { value: 'America/Denver', name: '(GMT-07:00) Denver' },
                    { value: 'America/Phoenix', name: '(GMT-07:00) Phoenix' },
                    { value: 'America/Chihuahua', name: '(GMT-07:00) Chihuahua' },
                    { value: 'America/Mazatlan', name: '(GMT-07:00) Mazatlan' },
                    { value: 'America/Chicago', name: '(GMT-06:00) Chicago' },
                    { value: 'America/Regina', name: '(GMT-06:00) Regina' },
                    { value: 'America/Mexico_City', name: '(GMT-06:00) Mexico City' },
                    { value: 'America/Monterrey', name: '(GMT-06:00) Monterrey' },
                    { value: 'America/Guatemala', name: '(GMT-06:00) Guatemala' },
                    { value: 'America/New_York', name: '(GMT-05:00) New York' },
                    { value: 'America/Indiana/Indianapolis', name: '(GMT-05:00) Indianapolis, Indiana' },
                    { value: 'America/Halifax', name: '(GMT-04:00) Halifax' },
                    { value: 'America/St_Johns', name: '(GMT-03:30) St. John’s' }
                  ]}
                />
              </div>
            </div>
          </div>

          <div className='submit column'>
            {
              this.state.errorMessage
                ? <p style={{ color: 'red' }}>{this.state.errorMessage}</p>
                : null
            }

            <Button
              onClick={this.submitForm}
              disabled={
                (() => {
                  for (const prop in this.state.nextISP) {
                    if (this.state.nextISP[prop] !== (this.props.isp[prop] || null)) {
                      return false;
                    }
                  }

                  return true;
                })()
              }>
              Submit
            </Button>
          </div>
        </form>
      </div>
    );
  }
}
