import React from 'react';
import {StyleSheet, View} from 'react-native';
import {connect, ConnectedProps} from 'react-redux';
import moment from 'moment-timezone';
import {RootState} from '@reducers';
import {InputSelect} from '@common';
import {getRangeFromType, getDateLabel} from './utils';
import {DateRangeType} from '@reducers/reports';
import {DayOfWeek} from '@reporting/Reporting.Types';
import * as selectors from '@selectors';
import CustomDateRangePicker from './CustomDateRangePicker';

type ComponentProps = {
  onDateRangeChange: (
    startDate: string,
    endDate: string,
    type: DateRangeType,
  ) => void;
};

type Props = ComponentProps & PropsFromRedux;

type State = {
  startDate: string;
  endDate: string;
  showDateRangePicker: boolean;
  selectedRangeType: DateRangeType;
  showCustomRangePicker: boolean;
  dropdownLabel: string;
};

const optionLabels: Record<DateRangeType, string> = {
  [DateRangeType.CUSTOM]: 'Custom',
  [DateRangeType.WEEK]: 'Week',
  [DateRangeType.WEEK_TO_DATE]: 'Week to Date',
  [DateRangeType.FOUR_WEEKS]: '4 Weeks',
  [DateRangeType.FOUR_WEEKS_TO_DATE]: '4 Weeks to Date',
  [DateRangeType.MONTH]: 'Month',
  [DateRangeType.MONTH_TO_DATE]: 'Month to Date',
};

type Option = {
  key: DateRangeType,
  label: string,
  value: DateRangeType,
}

const options: Option[] = [
  { 
    key: DateRangeType.WEEK, 
    label: optionLabels[DateRangeType.WEEK], 
    value: DateRangeType.WEEK 
  },
  { 
    key: DateRangeType.WEEK_TO_DATE, 
    label: optionLabels[DateRangeType.WEEK_TO_DATE], 
    value: DateRangeType.WEEK_TO_DATE 
  },
  { 
    key: DateRangeType.FOUR_WEEKS, 
    label: optionLabels[DateRangeType.FOUR_WEEKS], 
    value: DateRangeType.FOUR_WEEKS 
  },
  { 
    key: DateRangeType.FOUR_WEEKS_TO_DATE, 
    label: optionLabels[DateRangeType.FOUR_WEEKS_TO_DATE], 
    value: DateRangeType.FOUR_WEEKS_TO_DATE 
  }, 
  { 
    key: DateRangeType.MONTH, 
    label: optionLabels[DateRangeType.MONTH], 
    value: DateRangeType.MONTH 
  },
  { 
    key: DateRangeType.MONTH_TO_DATE, 
    label: optionLabels[DateRangeType.MONTH_TO_DATE], 
    value: DateRangeType.MONTH_TO_DATE 
  },
  { 
    key: DateRangeType.CUSTOM, 
    label: optionLabels[DateRangeType.CUSTOM], 
    value: DateRangeType.CUSTOM 
  }
]


class DateRangePicker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const {startDate, endDate, rangeType, timeZone} = props;

    this.state = {
      startDate: startDate,
      endDate: endDate,
      showDateRangePicker: false,
      selectedRangeType: rangeType,
      showCustomRangePicker: false,
      dropdownLabel: getDateLabel(startDate, endDate, timeZone),
    };

    this.toggleDateRangePicker = this.toggleDateRangePicker.bind(this);
    this.toggleCustomRangeModal = this.toggleCustomRangeModal.bind(this);
    this.onSetRange = this.onSetRange.bind(this);
    this.onCustomDateChange = this.onCustomDateChange.bind(this);
  }

  componentDidUpdate(prevProps: Props) {
    const {
      startDate: prevStartDate,
      endDate: prevEndDate,
      rangeType: prevRangeType,
    } = prevProps;
    const {startDate, endDate, rangeType, timeZone} = this.props;

    if (
      startDate !== prevStartDate ||
      endDate !== prevEndDate ||
      rangeType !== prevRangeType
    ) {
      this.setState({
        startDate,
        endDate,
        selectedRangeType: rangeType,
        dropdownLabel: getDateLabel(startDate, endDate, timeZone),
      });
    }
  }

  toggleDateRangePicker() {
    this.setState({showDateRangePicker: !this.state.showDateRangePicker});
  }

  onSetRange(type: DateRangeType) {
    const {startDate: currentStartDate, endDate: currentEndDate} = this.state;
    const {timeZone} = this.props;

    if (type === DateRangeType.CUSTOM) {
      this.toggleCustomRangeModal();
    } else {
      const today = moment.tz(timeZone).format(window.DATE_KEY);

      const {startDate, endDate} = getRangeFromType(
        type,
        currentStartDate,
        currentEndDate,
        today,
        DayOfWeek.Saturday,
      );

      const dropdownLabel = getDateLabel(startDate, endDate, timeZone);

      this.props.onDateRangeChange(startDate, endDate, type);

      this.setState({
        selectedRangeType: type,
        startDate,
        endDate,
        dropdownLabel,
      });
    }
  }

  toggleCustomRangeModal() {
    const {showCustomRangePicker, startDate, endDate, selectedRangeType} =
      this.state;
    const {timeZone} = this.props;

    let dropdownLabel = '';

    // InputSelect doesn't close on value change unless the label value is changed
    // This is a cheap hack to force it to close
    if (!showCustomRangePicker) {
      dropdownLabel = 'Select date range';
    } else {
      dropdownLabel = getDateLabel(
        startDate,
        endDate,
        timeZone,
      );
    }

    this.setState({
      showCustomRangePicker: !showCustomRangePicker,
      dropdownLabel,
      selectedRangeType: DateRangeType.CUSTOM,
    });
  }

  onCustomDateChange(startDate: string, endDate: string) {
    const {timeZone} = this.props;
    const selectedRangeType = DateRangeType.CUSTOM;

    const dropdownLabel = getDateLabel(
      startDate,
      endDate,
      timeZone,
    );

    this.setState({showCustomRangePicker: false, selectedRangeType, startDate, endDate, dropdownLabel});
    this.props.onDateRangeChange(startDate, endDate, selectedRangeType);
  }

  render() {
    const { colorPack,  } = this.props;
    const {
      selectedRangeType,
      showCustomRangePicker,
      dropdownLabel,
    } = this.state;
    
    return (
      <React.Fragment>
        <View
          style={styles.pickerWrapper}>
          <InputSelect
            isCommunity
            value={selectedRangeType}
            placeholder="Select date range"
            onValueChange={this.onSetRange}
            icon="calendar"
            label={optionLabels[selectedRangeType]}
            valueName={dropdownLabel}
            inline
            closeOnSelect
            useValueForPicker
            wrapStyle={{height: 50, flex: 1}}
            pickerStyle={{
              color: colorPack.fullCont
            }}
            options={options}
            />
        </View>

        <CustomDateRangePicker
          visible={showCustomRangePicker}
          weekStartDay={DayOfWeek.Saturday}
          onDismiss={this.toggleCustomRangeModal}
          onDateRangeSelection={this.onCustomDateChange}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: RootState) {
  const {startDate, endDate, type: rangeType} = state.reports.dateRange;
  return {
    ...state.colorMode,
    timeZone: state.timeZone.data,
    startDate,
    endDate,
    rangeType,
    featureFlags: selectors.baseSelectors.selectFeatureFlags(state),
  };
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DateRangePicker);

const styles = StyleSheet.create({
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    width: '100%',
    height: 60,
    paddingHorizontal: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  paddingWrapper: {
    flexDirection: 'row',
    width: '100%',
  },
  labelWrapper: {
    width: 90,
    height: 60,
    borderRightWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  rangeWrapper: {
    height: 60,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: 'white',
    fontSize: 14,
  },
  pickerWrap: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 5,
  },
  pickerWrapper: {
    minWidth: 350,
  },
  dateSeparatorLine: {flex: 1, width: '50%', borderRightWidth: 1},
  dateSeparatorToWrapper: {
    flex: 1,
    minHeight: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  pickerOptionRow: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 10,
    borderLeftWidth: 1,
    borderRightWidth: 1,
  },
  flexCell: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  datePicker: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    width: '100%',
  },
  datePickerWrap: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 10,
    flex: 1,
    padding: 10,
  },
  datePickerText: {
    textAlign: 'center'
  },
});
