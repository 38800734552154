import React from 'react';

import './style/CRM.css';
import Business from './Business';
import Contacts from './Contacts';
// import Notes from './Notes';
import SalesTagger from '../../SalesTagger/SalesTagger';
// import Section from '../../Page/Section';
import api from '../../../utils/api';

export default class CRM extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      editingBusiness: false,
      editingContact: false,
      ispsSalesLists: []
    }

    this.toggleProp = this.toggleProp.bind(this);
    this.fetchISPLists = this.fetchISPLists.bind(this);
    this.addSalesTag = this.addSalesTag.bind(this);
    this.removeSalesTag = this.removeSalesTag.bind(this);
    this.createRef = this.createRef.bind(this);
  }

  componentDidMount () {
    this.fetchISPLists();
  }

  toggleProp (type) {
    if (type && type.includes('editing')) {
      this.crmRef.scrollIntoView();
    }

    this.setState(prevState => {
      return { [type]: !prevState[type] };
    })
  }

  fetchISPLists () {
    const { id } = this.props.isp;

    api.request(`sales-lists/isps/${id}`)
      .then(res => {
        this.setState({ ispsSalesLists: res.data });
      })
      .catch(err => console.log(err));
  }

  addSalesTag (tagId) {
    const { id } = this.props.isp;

    api.post(`sales-lists/${tagId}/isps`, { ispId: id })
      .then(data => {
        this.fetchISPLists();
      })
  }

  removeSalesTag (tagId) {
    api.deleteRequest(`sales-lists/isps/${tagId}`)
      .then(data => {
        this.fetchISPLists();
      })
  }

  createRef (ref) {
    this.crmRef = ref;
  }

  render () {
    return (
      <div className='CRM' ref={this.createRef}>
        <Business
          toggleProp={this.toggleProp}
          editingBusiness={this.state.editingBusiness}
          isp={this.props.isp}
          updateISP={this.props.updateISP}
        />

        <SalesTagger
          tags={this.props.salesLists}
          activeTags={this.state.ispsSalesLists}
          addSalesTag={this.addSalesTag}
          removeSalesTag={this.removeSalesTag}
          setActiveTag={this.props.setActiveTag}
          isISP
        />

        <Contacts
          toggleProp={this.toggleProp}
          editingContact={this.state.editingContact}
          isp={this.props.isp}
          contacts={this.props.contacts}
          loadContacts={this.props.loadContacts}
          updateContact={this.props.updateContact}
          createContact={this.props.createContact}
          deleteContact={this.props.deleteContact}
        />
      </div>
    )
  }
}
