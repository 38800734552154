import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import pr from "@packageroute/types-firebase";
import { RouteComponentProps } from "react-router-dom";
import TagPopout from "../TagPopout/TagPopout";
import PageWrapper from "../Page/PageWrapper";
import PageTitle from "../Page/PageTitle";
import Section from "../Page/Section";
import Icon from "../Icon/Icon";
import IspSummary from "./IspSummary";
import WorkAreas from "./WorkAreas";
import AssociateISP from "./AssociateISP";
import AddISP from "../ISPsDash/AddISP";
import DeleteISP from "./DeleteISP";
import CRM from "./CRM/CRM";
import api, { SubscriptionData } from "../../utils/api";
import IspGhostTools from "../GhostAccounts/IspGhostTools";
import IspSubscription from "../Payment/IspSubscription";
import FirebaseLink from "../common/FirebaseLink";
import ContactIdTools from "./ContactIdTools";
import RelatedOperations from "../Payment/RelatedOperations";
import StripeLink from "../common/StripeLink";
import Users from "./Users";
import Notes from "./CRM/Notes";
import StationLink from "../common/StationLink";
import ServiceManagement from "./ServiceManagement/ServiceManagement";
import withPermissions, {
  PermissionSetKey,
} from "../Permissions/withPermissions";
import IconLink from "../common/IconLink";
import TransferBillingModal from "./TransferBillingModal";
import { LiveIspList } from "@reducers/liveIspList";
import { ConsoleUser } from "@reducers/consoleUsers";
import { SalesListItem } from "@reducers/salesLists";
import { Usage } from "@reducers/metrics/dailyOperationsUsage";
import IspVedrFeatureManagement from "./Vedr/IspVedrFeatureManagement";
import {
  consoleApiInitializer,
  ProductSubscriptionContractService,
} from "@services";
import { SubscriptionPlanContract, SubscriptionPlanType } from "@models";
import ContractModal from "../Payment/ContractModal/ContractModal";
import TransferOwnershipModal from "./OwnershipTransfer/TransferOwnershipModal";
import TransferHistoryList from "./TransferHistoryList";
import bugsnagClient from "@utils/bugsnag";
import "./Isp.css";
import { ISPPageFineGrainedPermissions } from "@reducers/permissions";
import usePermissions from "@components/Permissions/usePermissions";
import { useFeatureFlags } from "@src/hooks/redux";

type LatestProfile = pr.isp.profile.Profile;
type User = pr.users.User;
type StationAdvocates = pr.stations.advocates.StationAdvocates;
type HydratedUser = User & {
  allowBilling: boolean;
  authorized: boolean;
  inviteSent: boolean;
};
export type ConsoleISPWithoutMetadata = {
  additionalRoutes: number | null;
  churned: string | null;
  city: string;
  entityId: string;
  entityName: string;
  firebaseId: string;
  groundRoutes: number;
  hdRoutes: number;
  id: number;
  initialContact: string;
  lead: string | null;
  notInterested: string | null;
  numEntities: number;
  projectedClose: string;
  projectedConversionRate: number;
  qualified: string;
  qualifiedDescription: string;
  referred: string;
  referredDescription: string;
  signup: string;
  state: string;
  suppRoutes: number;
  timeZone: string;
  trial: string;
};

type IspStation = {
  id: string | null;
  address1: string | null;
  city: string | null;
  state: string | null;
  division2: string | null;
  division4: string | null;
  divisionCode: string | null;
  divisionName: string | null;
  fullAddress: string | null;
  latitude: string | null;
  longitude: string | null;
  postalCode: string | null;
  region2: string | null;
  region4: string | null;
  regionNumber: string | null;
  telephone: string | null;
  terminal: string | null;
  terminalName: string | null;
};

type CamelizedStation = {
  id: number;
  terminal: string;
  terminalName: string;
  fullAddress: string | null;
  address1: string;
  city: string;
  state: string;
  postalCode: string;
  latitude: string;
  longitude: string;
  regionNumber: string;
  region2: string | null;
  region4: string | null;
  telephone: string | null;
  division2: string | null;
  division4: string | null;
  divisionCode: string | null;
  divisionName: string | null;
  flexTime: string | null;
  sortTime: string | null;
  dispatchTime: string | null;
  createdAt: string;
  updatedAt: string;
};

type HydratedStation = {
  hdOnly: boolean;
  hasCoLocation: boolean;
  coLocationNumber: string | null;
  advocates: StationAdvocates | null;
} & CamelizedStation;

type NextContact = {
  phone: string | null;
  secondaryPhone: string | null;
  email: string | null;
  secondaryEmail: string | null;
  firstName: string | null;
  lastName: string | null;
  role: string | null;
  preferredMethod: string | null;
  preferredTime: string | null;
  appleId: string | null;
  playId: string | null;
  owner: boolean;
};

type Props = {
  consoleUser: ConsoleUser;
  salesLists: SalesListItem[];
  usage: Usage;
  history: RouteComponentProps<{ id: string }>['history'];
  match: RouteComponentProps<{ id: string }>['match'];
  fetchManualManifestList: () => void;
  liveISPList: LiveIspList;
  fetchSalesLists: () => void;
};

const Isp = ({
  consoleUser,
  salesLists,
  usage,
  history,
  match,
  fetchManualManifestList,
  liveISPList,
  fetchSalesLists,
}: Props) => {
  const [activeWorkArea, setActiveWorkArea] = useState<string | null>(null);
  const [isp, setIsp] = useState<ConsoleISPWithoutMetadata | null>(null);
  const [contacts, setContacts] = useState<any | null>(null);
  const [stations, setStations] = useState<HydratedStation[]>([]);
  const [activeTag, setActiveTag] = useState<string | null>(null);
  const [manualUploadsEnabled, setManualUploadsEnabled] = useState<
    boolean | undefined
  >(undefined);
  const [transferBillingOpen, setTransferBillingOpen] =
    useState<boolean>(false);
  const [transferOwnershipModalOpen, setTransferOwnershipModalOpen] =
    useState<boolean>(false);
  const [reloadingProfile, setReloadingProfile] = useState<boolean>(false);
  const [latestProfile, setLatestProfile] = useState<LatestProfile | null>(
    null
  );
  const [subscription, setSubscription] = useState<SubscriptionData | null>(
    null
  );
  const [users, setUsers] = useState<HydratedUser[]>([]);
  const [usageStats, setUsageStats] = useState<number | null>(null);
  const [subscriptionContract, setSubscriptionContract] =
    useState<SubscriptionPlanContract | null>(null);
  const [showContractModal, setShowContractModal] = useState<boolean>(false);

  const { fineGrainedPermissions } = usePermissions(PermissionSetKey.ISP);
  const featureFlags = useFeatureFlags();

  useEffect(() => {
    loadIsp();
    loadContacts();
    loadStations();
  }, [match.params.id]);

  useEffect(() => {
    if (isp) {
      loadUsers();
      loadIspUsageStats();
      loadLatestProfile();
      loadSubscription(isp.firebaseId);
      loadManualUploadStatus(isp.firebaseId);
      loadSubscriptionContract();
    }
  }, [isp]);

  const handleSetActiveTag = (tag: string | null) => {
    setActiveTag(tag);
  };

  const updateISP = (
    updates: Partial<ConsoleISPWithoutMetadata>,
    callback: (err?: Error) => void
  ) => {
    const { id } = match.params;

    const BASE_TEN = 10;
    const ispId = parseInt(id, BASE_TEN);

    api
      .updateISP(ispId, updates)
      .then((data) => {
        setIsp((data as ConsoleISPWithoutMetadata[])[0]);

        if (callback && typeof callback === 'function') {
          callback();
        }
      })
      .catch((err) => {
        if (callback && typeof callback === 'function') {
          callback(err);
        }
      });
  };

  const createContact = (
    contact: NextContact,
    callback: (err?: Error) => void
  ) => {
    const { id } = match.params;

    const BASE_TEN = 10;
    const ispId = parseInt(id, BASE_TEN);

    api
      .createContact(ispId, contact)
      .then((data) => {
        loadContacts();

        if (callback && typeof callback === 'function') {
          callback();
        }
      })
      .catch((err) => {
        if (callback && typeof callback === 'function') {
          callback(err);
        }
      });
  };

  const updateContact = (
    contactID: string,
    updates: Record<string, any>,
    callback: (err?: Error) => void
  ) => {
    api
      .updateContact(contactID, updates)
      .then((data) => {
        loadContacts();

        if (callback && typeof callback === 'function') {
          callback();
        }
      })
      .catch((err) => {
        if (callback && typeof callback === 'function') {
          callback(err);
        }
      });
  };

  const loadUsers = async () => {
    try {
      const { firebaseId } = isp || {};

      if (!firebaseId) return;

      const { data } = await api.request<HydratedUser[]>(
        `isps/${firebaseId}/users`
      );

      setUsers(data);
    } catch (err) {
      console.log(err);
    }
  };

  const loadStations = async () => {
    try {
      const { id } = match.params;

      const BASE_TEN = 10;
      const ispId = parseInt(id, BASE_TEN);

      const response = await api.request<IspStation[]>(
        `isps/${ispId}/stations`
      );

      const ispStations = response.data;

      const promises = ispStations.map(async (ispStation) => {
        const response = await api.request<HydratedStation[]>(
          `stations/${ispStation.id}`
        );

        const station = response.data[0];

        return station;
      });

      const stations = await Promise.all(promises);

      setStations(stations);
    } catch (err) {
      console.error(err);

      bugsnagClient.notify(err as Error);
    }
  };

  const loadSubscriptionContract = async () => {
    try {
      if (!isp) {
        throw new Error('Isp must be loaded first');
      }

      const service = new ProductSubscriptionContractService(
        consoleApiInitializer.initialize()
      );

      const { subscriptionContract } = await service.getSubscriptionContract(
        isp.firebaseId
      );

      setSubscriptionContract(subscriptionContract);
    } catch (err) {
      console.error(err);
    }
  };

  const loadIspUsageStats = async () => {
    try {
      const { firebaseId } = isp || {};

      if (!firebaseId) return;

      const response = await api.getIspUsageStatistics(firebaseId);

      const monthlyUsage = response.monthly_usage;

      setUsageStats(monthlyUsage);
    } catch (err) {
      console.error(err);

      bugsnagClient.notify(err as Error);
    }
  };

  const changeSubscriptionType = async (
    subscriptionPlanType: SubscriptionPlanType, contractUri: string | null, contractSignedDate: string | null, minimumContractedRoutes: number | null, minimumSharedContractedRoutes: number | null
  ) => {
    try {
      if (!isp) {
        throw new Error('Isp must be loaded first');
      }

      const service = new ProductSubscriptionContractService(
        consoleApiInitializer.initialize()
      );

      const { subscriptionContract } = await service.changeSubscriptionType(
        isp.firebaseId,
        subscriptionPlanType,
        contractSignedDate,
        contractUri,
        minimumContractedRoutes,
        minimumSharedContractedRoutes
      );

      setSubscriptionContract(subscriptionContract);

      await loadSubscription(isp.firebaseId);
    } catch (err) {
      console.error(err);

      throw new Error('Change subscription failed');
    }
  };

  const toggleContractModal = () => {
    setShowContractModal(!showContractModal);
  };

  const deleteContact = (
    contactID: string,
    callback: (err?: Error) => void
  ) => {
    api
      .deleteContact(contactID)
      .then((data) => {
        loadContacts();

        if (callback && typeof callback === 'function') {
          callback();
        }
      })
      .catch((err) => {
        if (callback && typeof callback === 'function') {
          callback(err);
        }
      });
  };

  const loadIsp = async () => {
    const { id } = match.params;

    const BASE_TEN = 10;
    const ispId = parseInt(id, BASE_TEN);

    const response = await api.request<ConsoleISPWithoutMetadata[]>(
      `isps/${ispId}`
    );

    const isps = response.data;

    const isp = isps[0];

    if (!isp) throw new Error('Failed to load isp');

    setIsp(isp);
  };

  const loadManualUploadStatus = async (firebaseId: string) => {
    try {
      const { enabled } = await api.getManualUploadStatus(firebaseId);

      setManualUploadsEnabled(enabled);
    } catch (err) {
      console.error(err);
    }
  };

  const toggleManualUpload = async () => {
    try {
      const { firebaseId } = isp ?? {};

      if (!firebaseId) {
        console.error('No firebase id, cannot toggle manual upload');
        return;
      }

      const { enabled } = await api.toggleManualUpload(
        firebaseId,
        !manualUploadsEnabled
      );

      setManualUploadsEnabled(enabled);

      fetchManualManifestList();
    } catch (err) {
      console.error(err);
    }
  };

  const loadContacts = async () => {
    try {
      const { id } = match.params;

      const BASE_TEN = 10;
      const ispId = parseInt(id, BASE_TEN);

      const contacts = await api.getISPContacts(ispId);

      setContacts(contacts);
    } catch (err) {
      console.error(err);
    }
  };

  const loadLatestProfile = async () => {
    const { firebaseId } = isp ?? {};

    if (!firebaseId) {
      return;
    }

    setReloadingProfile(true);
    api
      .request(`isps/latest/profile/${firebaseId}`)
      .then((res) => {
        setLatestProfile(res.data as LatestProfile);
      })
      .catch((err) => console.error(err))
      .finally(() => setReloadingProfile(false));
  };

  const loadSubscription = async (firebaseId: string | null) => {
    try {
      if (!firebaseId) {
        console.error('Cannot fetch subscription, firebaseId does not exist');

        return;
      }

      const subscription = await api.getISPSubscription(firebaseId);

      setSubscription(subscription);
    } catch (err) {
      console.error(err);
    }
  };

  const openWorkArea = (workAreaId: string) => {
    setActiveWorkArea(activeWorkArea === workAreaId ? null : workAreaId);
  };

  const getSubscribedRoutesTotal = () => {
    if (!latestProfile) return 0;

    const workAreas = latestProfile.WorkAreas || {};

    let sum = 0;

    for (const workAreaId in workAreas) {
      const { paid } = workAreas[workAreaId];

      if (paid) sum++;
    }

    return sum;
  };

  const allowBillingForUser = async (uid: string, removeAccess: boolean) => {
    try {
      const { firebaseId } = isp ?? {};

      if (!firebaseId) {
        console.error('Cannot allow billing for user, no isp.firebaseId');

        return;
      }

      await api.allowBillingForUser(firebaseId, uid, removeAccess);

      const newUsers = users.map((user) => {
        if (user.uid !== uid) return user;

        user.allowBilling = !removeAccess;

        return user;
      });

      setUsers(newUsers);
    } catch (err) {
      console.error(err);
      bugsnagClient.notify(err as Error);
    }
  };

  const removeBillingForUser = (uid: string) => {
    allowBillingForUser(uid, true);
  };

  const startTransferBilling = () => {
    setTransferBillingOpen(true);
  };

  const stopTransferBilling = () => {
    setTransferBillingOpen(false);
  };

  const toggleTransferOwnershipModalVisibility = () => {
    setTransferOwnershipModalOpen(!transferOwnershipModalOpen);
  };

  const { id } = match.params;
  const BASE_TEN = 10;
  const ispId = parseInt(id, BASE_TEN);
  const profile = !latestProfile
    ? isp && isp.firebaseId && liveISPList
      ? liveISPList[isp.firebaseId]
      : null
    : latestProfile;
  const timeZone = profile ? profile.timeZone : isp && isp.timeZone;
  const { metadata } = profile || {};
  const createdAt = (metadata || {}).createdAt;
  const signupDate = createdAt
    ? moment(createdAt)
        .tz(timeZone || 'America/Los_Angeles')
        .format('MMM Do, YYYY')
    : null;

  return isp ? (
    <div className='Isp'>
      <PageWrapper>
        <PageTitle
          secondTitle={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span
                style={{ fontSize: '16px', color: 'black', fontWeight: 300 }}
              >
                {isp.city ? isp.city : ''}
                {isp.state ? `, ${isp.state}` : null}
              </span>
              <span
                style={{
                  marginLeft: '10px',
                  fontSize: '16px',
                  color: 'black',
                  fontWeight: 300,
                }}
              >
                {stations.map((station) => (
                  <StationLink station={station.terminal} />
                ))}
              </span>
            </div>
          }
          rightContent={
            timeZone ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {signupDate ? (
                  <p style={{ marginRight: '15px' }}>
                    Signed Up At: {signupDate}
                  </p>
                ) : null}
                <p>Local Time: {moment.tz(timeZone).format('h:mm a z')}</p>
              </div>
            ) : null
          }
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {isp.entityName}

            <FirebaseLink path={`ISP/${isp.firebaseId}`} />
          </div>
        </PageTitle>

        <Section loading={!profile}>
          {profile && (
            <IspSummary isp={isp} profile={profile} usageStats={usageStats} />
          )}
        </Section>

        <Section title='Subscription' style={{ marginBottom: 10 }}>
          {latestProfile !== null &&
              <IspSubscription
                  ISPID={isp && isp.firebaseId}
                  subscriptionData={subscription}
                  subscriptionContract={subscriptionContract}
                  loadSubscription={() =>
                      loadSubscription(isp ? isp.firebaseId : null)
                  }
                  liveISPList={liveISPList}
                  latestProfile={latestProfile}
              />
          }
        </Section>

        <div className='stripeLinkWrap'>
          {!!subscription &&
            !!subscription.subscription &&
            typeof subscription.subscription.customer !== 'string' && (
              <React.Fragment>
                <StripeLink
                  type='sub'
                  id={subscription.subscription.id}
                  showTitle
                />
                <StripeLink
                  type='cus'
                  id={subscription.subscription.customer.id || 'No customer id'}
                  showTitle
                />
              </React.Fragment>
            )}

          <IconLink
            icon='check'
            color='#FC0000'
            title='Transfer Billing'
            onClick={startTransferBilling}
          />

          <IconLink
            icon='check'
            color='#FC0000'
            title='Transfer Ownership'
            onClick={toggleTransferOwnershipModalVisibility}
          />

          <IconLink
            icon='file'
            color='#006DAA'
            title={subscriptionContract ? 'Edit Contract' : 'Add Contract'}
            onClick={toggleContractModal}
          />
        </div>

        {latestProfile && (
          <Section title='Transfer History' style={{ marginBottom: 10 }}>
            <TransferHistoryList profile={latestProfile} />
          </Section>
        )}

        {showContractModal && (
          <ContractModal
            close={toggleContractModal}
            submitContractChange={changeSubscriptionType}
            subscribedRoutesCount={getSubscribedRoutesTotal()}
            currentContractType={subscriptionContract?.contractPlan ?? null}
            currentContractSignedDate={subscriptionContract?.signedDate ?? null}
            currentContractUri={
              subscriptionContract?.contractDocumentUri ?? null
            }
            minimumContractedRoutes={subscriptionContract?.minimumContractedRoutes ?? null}
            minimumSharedContractedRoutes={subscriptionContract?.minimumSharedContractedRoutes ?? null}
          />
        )}

        {transferBillingOpen && (
          <TransferBillingModal
            profile={profile}
            stopTransferBilling={stopTransferBilling}
            loadLatestProfile={loadLatestProfile}
          />
        )}

        {transferOwnershipModalOpen && latestProfile && (
          <TransferOwnershipModal
            latestProfile={latestProfile}
            loadLatestProfile={loadLatestProfile}
            onClose={toggleTransferOwnershipModalVisibility}
          />
        )}

        <RelatedOperations
          subscriptionData={subscription}
          liveISPList={liveISPList}
          activeIsp={(isp || {}).firebaseId}
          history={history}
        />

        <Section title='API' loading={!profile} empty={!profile}>
          {!reloadingProfile && (
            <ContactIdTools
              profile={profile}
              manualUploadsEnabled={manualUploadsEnabled}
              toggleManualUpload={toggleManualUpload}
              loadLatestProfile={loadLatestProfile}
            />
          )}
        </Section>

        <Section title='Service Management' loading={!profile} empty={!profile}>
          <ServiceManagement
            profile={profile}
            loadLatestProfile={loadLatestProfile}
          />
        </Section>

        <Section title='Profile' loading={!profile} empty={!profile}>
          <Profile profile={profile} />
        </Section>

        <Users
          users={users}
          profile={profile}
          liveISPList={liveISPList}
          usage={
            (usage || {})[moment.tz('America/Los_Angeles').format('YYYY-MM-DD')]
          }
          allowBillingForUser={allowBillingForUser}
          removeBillingForUser={removeBillingForUser}
          loadUsers={loadUsers}
          stations={stations}
        />

        {!!profile && (
          <WorkAreas
            profile={profile}
            openWorkArea={openWorkArea}
            activeWorkArea={activeWorkArea}
            loadLatestProfile={loadLatestProfile}
          />
        )}

        {isp?.firebaseId ? (
          <IspVedrFeatureManagement operationId={isp.firebaseId} />
        ) : (
          <div className='noAssoc'>
            <h1>CSP must be associated to with operation</h1>
          </div>
        )}

        <Section title='CRM'>
          <CRM
            ispId={ispId}
            isp={isp}
            consoleUser={consoleUser}
            profile={profile}
            setActiveTag={handleSetActiveTag}
            contacts={contacts}
            loadContacts={loadContacts}
            updateISP={updateISP}
            updateContact={updateContact}
            createContact={createContact}
            deleteContact={deleteContact}
            salesLists={salesLists}
            fetchSalesLists={fetchSalesLists}
          />
        </Section>

        <Notes consoleUser={consoleUser} ispId={ispId} />

        <AddISP
          edit
          history={history}
          isp={isp}
          profile={profile}
          stations={stations}
        />

        <AssociateISP isp={isp} profile={profile} />

        <Section title='Ghost Account'>
          {ispId ? (
            <IspGhostTools
              users={users}
              operationId={isp ? isp.firebaseId : null}
              entityName={isp ? isp.entityName : null}
              loadUsers={loadUsers}
            />
          ) : (
            <div className='noAssoc'>
              <h1>CSP must be associated to ghost in</h1>
            </div>
          )}
        </Section>

        {fineGrainedPermissions.includes(ISPPageFineGrainedPermissions.deleteIsp) && (
          <Section title='Delete ISP'>
            <DeleteISP isp={isp} history={history} profile={profile} />
          </Section>
        )}

        {activeTag ? (
          <TagPopout
            tag={activeTag}
            item={isp}
            type='isps'
            setActiveTag={setActiveTag}
            updateProps={fetchSalesLists}
          />
        ) : null}
      </PageWrapper>
    </div>
  ) : (
    <NotFound ispId={ispId} />
  );
};

function NotFound({ ispId }: { ispId: number }) {
  return (
    <div className='Isp'>
      <PageWrapper>
        <Section>
          <div className='notFound'>
            <Icon size={150} color='#B90C0F' name='cancel' />

            <h1>
              No operation with id '<span>{ispId}</span>' found
            </h1>
          </div>
        </Section>
      </PageWrapper>
    </div>
  );
}

function Profile({ profile }: { profile: LatestProfile | null }) {
  const noProfileInfo =
    !profile ||
    (!profile.Address1 &&
      !profile.State &&
      !profile.City &&
      !profile.Telephone);

  return !noProfileInfo ? (
    <div className='ispDetails'>
      <Icon name='city' size={25} color='#999' />

      <div className='detailsBody'>
        <h2 className='address'>{profile.Address1}</h2>
        <div className='address2'>
          {profile.City}, {profile.State}
        </div>
      </div>

      <p className='division'>{profile.Telephone}</p>
    </div>
  ) : (
    <div className='ispDetails'>
      <p>No profile information available.</p>
    </div>
  );
}

export default withPermissions(Isp);
