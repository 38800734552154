import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type StationId = string;
type OperationId = string;

export type SalesCampaignCollection = {
  collectionId: string;
  createdByUID: string;
  name: string;
  stations?: StationId[];
  isps?: OperationId[];
  shared?: boolean;
  createdAt: number;
}

export type SalesCampaignCollections = Record<string, SalesCampaignCollection>;

export enum Status {
  INITIAL = 'INITIAL',
  UPDATED = 'UPDATED',
}

type SalesCampaignCollectionsState = {
  status: Status;
  data: SalesCampaignCollections;
}

const initialState: SalesCampaignCollectionsState = {
  status: Status.INITIAL,
  data: {},
};

const salesCampaignCollectionsSlice = createSlice({
  name: 'salesCampaignCollections',
  initialState: initialState,
  reducers: {
    updateSalesCampaignCollections: (state, action: PayloadAction<{ collections: SalesCampaignCollections }>) => {
      const { collections } = action.payload;

      state.data = {
        ...state.data,
        ...collections
      }

      state.status = Status.UPDATED;
    },
    resetSalesCampaignCollections: (state) => {
      state.data = {};
      state.status = Status.INITIAL;
    }
  }
});

export const { 
  updateSalesCampaignCollections,
  resetSalesCampaignCollections,
} = salesCampaignCollectionsSlice.actions

export default salesCampaignCollectionsSlice.reducer;
