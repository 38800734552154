import { Dispatch } from "@reduxjs/toolkit";
import {
  updateCancellingAtPeriodEnd,
  updateCancellingAtPeriodEndFailed,
  resetCancellingAtPeriodEnd,
  CancellingAtPeriodEndSubscriptions,
} from "@reducers/subscriptions/cancellingAtPeriodEnd";
import api from "@api";
import bugsnagClient from "@utils/bugsnag";

export async function fetchCancellingAtPeriodEndSubscriptions(dispatch: Dispatch) {
  try {
    const response = await api.request<CancellingAtPeriodEndSubscriptions>(`subscriptions/cancel-at-period-end`);

    const subscriptions = response.data ?? [];

    dispatch(updateCancellingAtPeriodEnd({ subscriptions }));
  } catch (err) {
    console.error(err);
    bugsnagClient.notify(err as Error);

    dispatch(updateCancellingAtPeriodEndFailed());
  }
}

export function clearCancellingAtPeriodEndSubscriptions(dispatch: Dispatch) {
  dispatch(resetCancellingAtPeriodEnd());
}
