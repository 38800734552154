import React from 'react';
import { useJsApiLoader, GoogleMap } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import defaultStyle from './GoogleMapStyle.json';

const containerStyle = {
  width: '400px',
  height: '400px'
};

type Props = {
  defaultCenter?: {
    latitude?: number;
    longitude?: number;
    lat: number;
    lng: number;
  };
  region?: {
    latitude?: number;
    longitude?: number;
    lat: number;
    lng: number;
  };
  saveRef?: any;
  onClick?: any;
  children: any;
  options?: any;
  zoom?: number;
  onZoomChanged?: () => void;
  height?: number;
  width?: number;
  // snapToChildren?: boolean;
}

const defaultLocation = { 
  lat: 47.598592, 
  latitude: 47.598592, 
  lng: -122.328339,
  longitude: -122.328339 
};

export default function Map({
  children,
  onClick,
  saveRef,
  height,
  width,
  defaultCenter,
  zoom,
  options,
  onZoomChanged,
}: Props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCPmlRPJb6s86f9JrTPXCSmodCnY7oiKrQ",
    libraries: ['places'],
  })

  const { latitude, longitude, lat, lng } = defaultCenter || defaultLocation;

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // @ts-ignore
    if (window?.google?.maps) {
      // @ts-ignore
      const bounds = new window.google.maps.LatLngBounds();
      map.fitBounds(bounds);
      setMap(map)
    }
  // @ts-ignore
  }, [window?.google?.maps])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (
    <GoogleMap
      onClick={onClick}
      onLoad={map => {
        onLoad(map);
        saveRef?.(map)
      }}
      onUnmount={onUnmount}
      mapContainerStyle={{
        height: height || containerStyle.height,
        width: width || containerStyle.width,
      }}
      center={{ lat: latitude || lat, lng: longitude || lng }}
      zoom={zoom || 13}
      options={{ styles: defaultStyle, ...(options || {}) }}
      onZoomChanged={onZoomChanged || (() => {})}
    >
      {children}
    </GoogleMap>
  ) : <></>
}

// export default class Map extends React.Component<Props> {
//   render() {
//     const {defaultCenter, zoom, onClick, children, options, onZoomChanged, height, width, libraries} = this.props;

//     const { latitude, longitude, lat, lng } = defaultCenter || defaultLocation;

//     return (
//       <LoadScript
//         googleMapsApiKey="AIzaSyCXlBSHC8lzKVfRT9AzVBKNY1nE-7iJEMk"
//         libraries={libraries || []}
//       >
//         <GoogleMap
//           onClick={onClick}
//           onLoad={map => this.props.saveRef?.(map)}
//           mapContainerStyle={{
//             height: height || containerStyle.height,
//             width: width || containerStyle.width,
//           }}
//           center={{ lat: latitude || lat, lng: longitude || lng }}
//           zoom={zoom || 13}
//           options={{ styles: defaultStyle, ...(options || {}) }}
//           onZoomChanged={onZoomChanged || (() => {})}
//         >
//           {children}
//         </GoogleMap>
//       </LoadScript>
//     )
//   }
// }


// const MapWrap = withScriptjs(withGoogleMap((props: Props) => {
//   const { defaultCenter, children, saveRef, onClick, options } = props;
//   const { latitude, longitude, lat, lng } = defaultCenter || defaultLocation;

//   return (
//     <GoogleMap
//       onClick={onClick}
//       ref={saveRef || null}
//       defaultZoom={props.zoom || 13}
//       defaultCenter={{ lat: latitude || lat, lng: longitude || lng }}
//       defaultOptions={{ styles: defaultStyle, ...(options || {}) }}
//       onZoomChanged={props.onZoomChanged || (() => {})}
//     >
//       { children }
//     </GoogleMap>
//   );
// }));

// type MapProps = {
//   height: number;
// }
// export default class Map extends React.Component<MapProps> {
//   render () {
//     const { height, ...rest } = this.props;
//     return (
//       <MapWrap
//         googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyC-j0pD993SUEmiDB5IARSuo_AVk6vU4zQ&v=3.exp&libraries=geometry,drawing,places'
//         loadingElement={<div className='map' style={{ height: `100%` }} />}
//         containerElement={<div className='map' style={{ height: height || '100%' }} />}
//         mapElement={<div className='map' style={{ height: `100%` }} />}
//         {...rest}
//       />
//     );
//   }
// }
