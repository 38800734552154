import { SalesCampaignCollections, SalesCampaignCollection } from "@reducers/salesCampaignCollections";
import { FirebaseDatabase, FirebaseProvider } from "@utils/firebase";

export default class ConsoleCollectionsService {
  private db: FirebaseDatabase;

  constructor(firebaseProvider: FirebaseProvider) {
    this.db = firebaseProvider.getConsoleDefaultDB();
  }

  async get(): Promise<SalesCampaignCollections> {
    const path = this._getCollectionsPath();

    const ref = this.db.ref(path);

    const snap = await ref.once("value");

    const collections = snap.val();

    if (!collections) {
      throw new Error("No collections found");
    }

    return collections;
  }

  subscribe(callback: (collections: SalesCampaignCollections | null) => void) {
    const path = this._getCollectionsPath();

    const ref = this.db.ref(path);

    ref.on("value", (snap) => {
      const collections: SalesCampaignCollections | null = snap.val();

      callback(collections);
    });
  }

  unsubscribe() {
    const path = this._getCollectionsPath();

    const ref = this.db.ref(path);

    ref.off();
  }

  async createCollection (createdByUID: string, name: string) {
    if (!createdByUID || typeof createdByUID !== 'string') {
      throw new Error(`Invalid parameter createdByUID, expected string, received ${createdByUID}, typeof ${typeof createdByUID}`);
    }

    if (!name || typeof name !== 'string') {
      throw new Error(`Invalid parameter name, expected string, received ${name}, typeof ${typeof name}`);
    }

    const path = this._getCollectionsPath();

    const ref = this.db.ref(path);

    const pushKey = await ref.push().key;

    if (!pushKey) {
      throw new Error('Failed to create push key');
    }

    await ref.child(pushKey).set({
      collectionId: pushKey,
      name,
      createdByUID,
      createdAt: Date.now(),
    })
  }

  async updateCollectionName (collection: SalesCampaignCollection, name: string) {
    if (!name || typeof name !== 'string') {
      throw new Error(`Invalid parameter name, expected string, received ${name}, typeof ${typeof name}`);
    }

    if (!collection || !collection.collectionId || typeof collection.collectionId !== 'string') {
      throw new Error(`Invalid parameter collection, expected collection object, received ${collection}, typeof ${typeof collection}`);
    }

    const path = this._getCollectionPath(collection.collectionId);

    const ref = this.db.ref(path);

    await ref.update({ name });
  }

  async removeCollection (collection: SalesCampaignCollection) {
    if (!collection || !collection.collectionId || typeof collection.collectionId !== 'string') {
      throw new Error(`Invalid parameter collection, expected collection object, received ${collection}, typeof ${typeof collection}`);
    }

    const path = this._getCollectionPath(collection.collectionId);

    const ref = this.db.ref(path);

    await ref.remove();
  }

  private _getCollectionsPath() {
    return "collections";
  }

  private _getCollectionPath(collectionId: string) {
    return `collections/${collectionId}`;
  }
}
