import React from 'react';
import moment from 'moment-timezone';
import Stripe from 'stripe';
import CalendarList from './CalendarList';
import CancellationReasonItem from './CancellationReasonItem';
import ScrollView from '../Page/ScrollView';
import { LiveIspProfile } from '@reducers/liveIspList';
import { IspCancellationReason } from '@reducers/cancellationReasons';
import {
  useCancellationReasons,
  useLiveIspList,
} from '@hooks/redux';
import './CancelAtPeriodEnd.css';

type DaysAgo = {
  daysAgo: string;
  label: string;
  items: DaysAgoItem[];
}

type DaysAgoItem = {
  id: string | null;
  cancellationReason: IspCancellationReason;
  profile: LiveIspProfile;
}

type Props = {
  setFocusedISP: (ispId: string | null) => void;
  lastNDays: number;
}

export default function CancellationReasons ({ setFocusedISP, lastNDays }: Props) {
  const cancellationReasons = useCancellationReasons();
  const liveISPList = useLiveIspList();

  if (!Object.keys(cancellationReasons).length) return null;

  const totalCancelled = Object.values(cancellationReasons).reduce<number>((acc, cancellationsOnDate) => {
    const ispIds = Object.keys(cancellationsOnDate)

    acc += ispIds.length;

    return acc;
  }, 0);

  const totalRoutesCancelled = Object.values(cancellationReasons).reduce<number>((acc, cancellationsOnDate) => {
    const ispIds = Object.keys(cancellationsOnDate)

    for (const ispId of ispIds) {
      acc += cancellationsOnDate[ispId]?.subscribedRoutes ?? 0;
    }

    return acc;
  }, 0);

  return (
    <React.Fragment>
      <div className='cohort'>
        <p style={{ fontSize: '12px' }}>{`ISPs canceling in the next ${lastNDays} days`}</p>

        <p style={{ fontSize: '12px' }}>
          <span style={{ margin: '0 5px' }}>
            <span style={{ color: '#006DAB' }}>{totalRoutesCancelled}</span>
          </span>
          <span style={{ margin: '0 5px' }}>{totalCancelled} ISP</span>
        </p>
      </div>

      <ScrollView direction='vertical' height='700px'>
        <CalendarList
          labelColor='#666'
          data={Object.values(Object.entries(cancellationReasons).sort(([aDate], [bDate]) => {
            return aDate > bDate ? -1 : 1;
          }).reduce<Record<string, DaysAgo>>((acc, [date, cancellationsOnDate]) => {
            const ispIds = Object.keys(cancellationsOnDate)

            for (const ispId of ispIds) {
              const daysAgo = moment(date, 'YYYY-MM-DD').fromNow();

              if (!acc.hasOwnProperty(daysAgo)) {
                const label = daysAgo;
  
                acc[daysAgo] = {
                  daysAgo: daysAgo,
                  label,
                  items: []
                };
              }

              if (!cancellationsOnDate[ispId]?.feedback) {
                continue;
              }

              acc[daysAgo].items.push({
                id: ispId || null,
                cancellationReason: cancellationsOnDate[ispId],
                profile: liveISPList[ispId] || null
              });
            }

            return acc;
          }, {}))}
          onItemClick={setFocusedISP}
          ListItem={CancellationReasonItem}
        />
      </ScrollView>
    </React.Fragment>
  );
}
