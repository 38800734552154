import React, {useMemo} from 'react';
import { XAxis, LineChart, Line, ResponsiveContainer, Tooltip, BarChart, Bar } from 'recharts';
import moment from 'moment-timezone';
import { useToday, useTimeZone } from '@hooks/redux';
import {
  getLastFourWeeks,
  getLastFourWeeksGroupedByWeek,
} from '@utils/dates';
import './SignupsChart.css';

type DailyTotals = Record<string, number>;

type Props = {
  dataKey: string;
  totals: DailyTotals;
  color: string;
}

const FORMATTED_DATE_KEY = 'ddd MMM-D';

export default function SignupsChart ({ dataKey, totals, color }: Props) {
  const today = useToday();
  const timeZone = useTimeZone();

  const dailyData = useMemo(() => {
    const lastFourWeeks = getLastFourWeeks(today, timeZone);

    return lastFourWeeks.map(date => {
      const dailyTotal = totals[date];

      const dayMoment = moment.tz(date, DATE_KEY, timeZone);
      const formattedDate = dayMoment.format(FORMATTED_DATE_KEY);
  
      return {
        name: formattedDate,
        [dataKey]: dailyTotal ?? 0,
      };
    });
  }, [totals]);

  const weeklyData = useMemo(() => {
    const lastFourWeeksGrouped = getLastFourWeeksGroupedByWeek(today, timeZone)

    return lastFourWeeksGrouped.map((weekDays, i, arr) => {
      let totalSignups = 0;
  
      for (const date of weekDays) {
        const dailyTotal = totals[date];
        
        totalSignups += dailyTotal ?? 0;
      }
  
      const label = getWeeklyLabel(weekDays);
  
      return {
        name: label,
        [dataKey]: totalSignups,
      }
    });
  }, []);

  return (
    <div className='SignupsChart'>
      <ResponsiveContainer width='100%' height='50%'>
        <LineChart
          data={dailyData}
          margin={{ top: 5, right: 30, left: 0, bottom: 5 }}>
          <Tooltip itemStyle={{ zIndex: 1000 }} />
          <XAxis dataKey='name' hide />
          <Line
            activeDot={{ stroke: color, strokeWidth: 3, r: 4 }}
            type='monotone'
            dataKey={dataKey}
            dot={false}
            stroke={color}
            strokeWidth={1}
          />
        </LineChart>
      </ResponsiveContainer>

      <ResponsiveContainer width='100%' height='50%'>
        <BarChart data={weeklyData}>
          <Tooltip />
          <XAxis dataKey='name' hide />
          <Bar dataKey={dataKey} fill={color} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

function getWeeklyLabel (dates: string[]) {
  const startDate = moment(dates[0], 'YYYY-MM-DD').format('MMM-D');
  const endDate = moment(dates[dates.length - 1], 'YYYY-MM-DD').format('MMM-D');

  return `${startDate} - ${endDate}`;
};