import { Dispatch } from "@reduxjs/toolkit";
import {
  updateCancellationReasons,
  resetCancellationReasons,
} from "@reducers/cancellationReasons";
import {ProductCancellationReasonsService, consoleApiInitializer} from '@services';

export async function fetchCancellationReasons (dispatch: Dispatch) {
  try {
    const service = new ProductCancellationReasonsService(consoleApiInitializer);

    const reasons = await service.get();
    
    dispatch(updateCancellationReasons({ reasons }));

    return reasons;
  } catch (err) {
    console.error(err);
  }
}

export async function clearReasons (dispatch: Dispatch) {
  try {
    dispatch(resetCancellationReasons());
  } catch (err) {
    console.error(err);

    throw err;
  }
}
