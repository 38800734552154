import React from 'react';
import './Section.css';

import moment from 'moment';

import Icon from '../Icon/Icon';

type Props = {
  className?: string;
  children?: any;
  title?: string | any;
  subtitle?: string;
  topContent?: any;
  bottomContent?: any;
  style?: React.CSSProperties;
  loading?: boolean;
  empty?: boolean;
}
export default function Section (props: Props) {
  const { className = '', children, title, subtitle, topContent, bottomContent, style, loading, empty } = props;

  if (empty === true) return null;

  return (
    <div className={"Section" + (className ? ` ${className}` : '')} style={style}>
      {!!(title || topContent) && (
        <div className="top">
          {!!title ? (
            !!subtitle ? (
              <h2 className="title">
                {title}
                <span className="subtitle"> {subtitle}</span>
              </h2>
            ) : (
              <h2 className="title">{title}</h2>
            )
          ) : null}
          {topContent}
        </div>
      )}

      <div className="body">{loading ? <LoadingBody /> : children}</div>

      {!!bottomContent && <div className="bottom">{bottomContent}</div>}
    </div>
  );
}

type SectionReloadProps = {
  onReload: () => void;
  empty?: boolean;
  timestamp?: number;
  hideReload?: boolean;
} & Props
export function SectionWithReload ({ onReload, empty, timestamp, hideReload, ...rest }: SectionReloadProps) {
  if (empty === true) return null;

  return (
    <Section
      topContent={
        !hideReload &&
        <div className='reloadContent' onClick={onReload}>
          <div className='reloadStamp'>
            {
              timestamp
                ? moment(timestamp).fromNow()
                : null
            }
          </div>

          <Icon name='reload' color='#006DAB' size={25} />
        </div>
      }
      {...rest}
    />
  );
}

function LoadingBody () {
  return (
    <div className='LoadingBody'>
    </div>
  );
}
