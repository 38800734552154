import React, { Component } from 'react';

import { View, PickerDropdown, TextInput } from 'react-native';

import Icon from '../Icon/Icon';

import './InputSelect.css';

export default class InputSelect extends Component {
  constructor (props) {
    super(props);

    this.state = {};

    this.close = this.close.bind(this);
  }

  componentWillUpdate (newProps) {
    if (newProps.valueName !== this.props.valueName) {
      this.setState({ customDone: false });
      if (this.props.closeOnSelect) {
        this.setState({ pickerOpen: false, customDone: false });
      }
    }
  }

  close () {
    this.setState({ pickerOpen: false });
  }

  render () {
    const {
      label = '',
      placeholder,
      keyboardType,
      value,
      valueName,
      onValueChange,
      icon,
      isCommunity, 
      onChangeText,
      options = [],
      iconBackground,
      marginHorizontal = 20,
      labelMargin,
      inline,
      useValueForPicker,
      useClickAway = false,
      style,
      noIcon,
      borderless,
      wrapStyle,
      inputStyle,
      labelStyle,
      dropdownStyle = {},
      dropdownItemStyle = {}
    } = this.props;

    const isCustom = valueName === 'custom';
    const { pickerOpen } = this.state;

    return (
      <React.Fragment> 
        { useClickAway && pickerOpen && <div className='InputSelectClickAway' onClick={() => this.setState({ pickerOpen: false })} /> }

        <View
          className='InputSelect'
          style={{
            marginLeft: marginHorizontal, 
            marginRight: marginHorizontal,
            ...(inline ? {
              width: `calc(100% - ${marginHorizontal * 2}px`,
            } : {}), 
            ...style}}
          onPress={
            isCustom && !this.state.customDone
              ? null
              : () => { this.setState({ pickerOpen: !pickerOpen }); }
          }>
          
          {
            label && !inline
              ? <p className='InputLabel' style={labelStyle}>{ label }</p>
              : null
          }

          <div className='InputWrap' style={{
            ...(inline ? {
              backgroundColor: 'white',
              // borderBottom: 'solid 1px #DDD',
              alignItems: 'center',
              // paddingRight: 20
            } : {}),
            ...wrapStyle
          }}>
            {
              !inline && !noIcon &&
              <div className='LockIconWrap' style={{
                ...(
                  value ?
                    iconBackground ?
                      {backgroundColor: iconBackground }
                    : {backgroundColor: '#168E32'}
                  : {backgroundColor: '#EEE'}    
                )
              }}>
                <Icon name={icon || 'check'} size={20} color='white' />
              </div>
            }
              <TextInput
                value={isCustom ? value : `${inline && label ? `${label}:  ` : ''}${(valueName || value)}`}
                editable={!inline && isCustom && !this.state.customDone}
                onChangeText={onChangeText}
                onBlur={() => { this.setState({customDone: true}); }}
                style={{
                  flex: 1,
                  height: 26,
                  color: 'black',
                  backgroundColor: '#EEE',
                  ...(!borderless
                  ? {
                    border: 'solid 1px #DDD',
                    borderLeft: 'none',
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: pickerOpen ? 0 : 5,
                  } : { border : 'none', backgroundColor: 'none' }),
                  
                  outline: 'none',
                  margin: 0,
                  textAlign: 'center',
                  ...(inline ? {
                    ...(!borderless
                    ? {
                      borderLeft: 'solid 1px #DDD',
                      backgroundColor: 'white',
                      borderTopLeftRadius: 5,
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      borderBottomLeftRadius: pickerOpen ? 0 : 5,
                      borderRight: 'none'
                    } : { border: 'none', backgroundColor: 'rgba(0, 0, 0, 0)' }),
                    fontSize: 16
                  } : {}), 
                  ...inputStyle,
                }}
                placeholder={placeholder || label || 'Input'}
                placeholderTextColor='#999'
                autoCorrect={false}
                keyboardType={keyboardType || 'default'}
              />

            { inline && !noIcon &&
              <div 
                style={{
                  display: 'flex',
                  height: 26,
                  width: 26,
                  justifyContent: 'center',
                  alignItems: 'center',
                  ...(!borderless
                  ? {
                    border: 'solid 1px #DDD', 
                    borderLeft: 'none',
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: pickerOpen ? 0 : 5 
                  } : { border: 'none'})
                }}
              >
                <Icon name='chevron-down' size={20} color='#999' />
              </div>
            }
          </div>

          {
            pickerOpen
              ? 
                <PickerDropdown
                  selectedValue={useValueForPicker ? value : valueName}
                  onValueChange={onValueChange}
                  mode='dropdown'
                  open={true}
                  options={options}
                  style={dropdownStyle}
                  itemStyle={dropdownItemStyle}
                />
              
              : null
          }
        </View>
      </React.Fragment>
    );
  }
}
