import React from 'react';

import Icon from '../Icon/Icon';
import InputText from '../Page/InputText';
import './SalesTagger.css';

export default class SalesTagger extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      searchText: '',
      searching: false
    }
  }

  handleAddition (tagId) {
    const { activeTags } = this.props;
    const activeTagIDs = (activeTags || []).map(tag => tag.id);

    if (activeTagIDs.includes(tagId)) {
      return;
    }

    this.setState({ searchText: '', searching: false }, () => {
      this.props.addSalesTag(tagId);
    })
  }

  handleRemoval (tagId) {
    this.props.removeSalesTag(tagId);
  }

  render () {
    const activeTagIDs = (this.props.activeTags || []).map(tag => tag.salesListId);

    return (
      <div className='SalesTagger'>
        <div className='active'>
          {
            this.props.activeTags.length
              ? this.props.activeTags.map(tag => {
                return (
                  <div className='tag'>
                    <div className='remove-tag' onClick={() => this.handleRemoval(tag.id)}>
                      <Icon name='close' size={15} color='black' />
                    </div>

                    <div className='tag-body' onClick={() => this.props.setActiveTag(tag)}>
                      <p>{tag.name}</p>
                    </div>
                  </div>
                )
              })
              : <div className='default'>
                <p>{`This ${this.props.isISP ? 'isp' : 'station'} is not tagged to any Sales Dev Lists`}</p>
              </div>
          }
        </div>

        <div className='new'>
          <div style={{ width: '50%', height: '45px', display: 'flex', alignItems: 'flex-end' }}>
            <InputText
              value={this.state.searchText}
              placeholder='Tag this ISP to one or multiple Sales Dev lists'
              onChange={(e) => this.setState({ searchText: e.target.value })}
              onFocus={(e) => this.setState({ searching: true })}
            />
          </div>

          <div className='matches' style={{ width: '50%' }}>
            {
              (this.props.tags || []).map(tag => {
                if (!this.state.searching) {
                  return null;
                }

                if (this.state.searchText && !(tag.name.toLowerCase()).includes(this.state.searchText.toLowerCase())) {
                  return null;
                }

                if (activeTagIDs.includes(tag.id)) {
                  return null;
                }

                return (
                  <div className='option' onClick={() => this.handleAddition(tag.id)}>
                    <p>{tag.name}</p>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    );
  }
}
