import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import { 
  GlobalState, 
  FirebaseFeatureFlags 
} from '@packageroute/biz/lib/featureFlags/FeatureFlagsAdmin';
import pr from '@packageroute/types-firebase';
import firebaseProvider from '@utils/firebase';
import Stripe from 'stripe';
import {SuccessfulApiTestResponse} from '../ConsoleApi.Types';
import {ConsoleIspsService} from "@services/ConsoleIspsService";
import ConsoleApiInitializer from "@services/ConsoleApiInitializer";

const url = '/api/';
const ispService = new ConsoleIspsService(ConsoleApiInitializer);

function toggleManualUpload (ISPID: string, enabled: boolean): Promise<{ enabled: boolean}> {
  return new Promise((resolve, reject) => {
    patch<{ enabled: boolean }>(`tasks/disabled/manifest/${ISPID}/`, { ISPID, enabled })
      .then((res) => {
        const { data } = res;

        resolve(data);
      })
      .catch(reject);
  });
}

function getManualUploadStatus (firebaseId: string): Promise<{ enabled: boolean }> {
  return new Promise((resolve, reject) => {
    request<{ enabled: boolean }>(`tasks/disabled/manifest/${firebaseId}/`)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
}

type UserData = pr.users.User;
function getUserData (uid: string): Promise<UserData | null> {
  return new Promise((resolve, reject) => {
    request<UserData | null>(`users/${uid}/`)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
}


function getUserDataByFedExId (FedExId: string) {
  return new Promise((resolve, reject) => {
    request(`users/FedExId/${FedExId}/`)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
}

function allowBillingForUser (firebaseId: string, uid: any, removeAccess: any) {
  return new Promise((resolve, reject) => {
    post(`isps/firebase-id/${firebaseId}/users/allowbilling`, { uid, removeAccess })
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  })
}

function getCrmIdForFirebaseId (firebaseId: string): Promise<{ id: string }> {
  return new Promise((resolve, reject) => {
    request<{ id: string }>(`isps/firebase-id/${firebaseId}/`)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
}

function getLatestIspProperty<T>(property: string, firebaseId: string): Promise<T> {
  return new Promise((resolve, reject) => {
    request<T>(`isps/latest/${property}/${firebaseId}/`)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
}

function getApiDataForId (id: string): Promise<SuccessfulApiTestResponse> {
  return new Promise((resolve, reject) => {
    request<SuccessfulApiTestResponse>(`fedexapi/${id}/test/`)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
}

function getStripeBalanceHistoryTransaction (id: string) {
  return new Promise((resolve, reject) => {
    request(`subscriptions/balance/history/${id}`)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
}

function getStripeBalanceHistory (options: any) {
  const queryString = Object.entries(options || {}).reduce((acc, param) => {
    const [ key, value ] = param;
    return acc + `${acc.length ? '&' : '?'}${key}=${value}`
  }, '')

  return new Promise((resolve, reject) => {
    request(`subscriptions/balance/history/${queryString}`)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
}

function getStripeBalance () {
  return new Promise((resolve, reject) => {
    request(`subscriptions/balance`)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
}

function clearInvoiceItem (
  ISPID: string, 
  invoiceItemId: string
) {
  return new Promise((resolve, reject) => {
    deleteRequest(`subscriptions/${ISPID}/invoice${invoiceItemId}`)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
}

function getGhostAccount (id?: string) {
  return new Promise((resolve, reject) => {
    const path = id
      ? `ghostaccounts/${id}`
      : 'ghostaccounts';

    request(path)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
}

export type SubscriptionData = {
  subscription: Stripe.subscriptions.ISubscription;
  nextInvoice: Stripe.invoices.IInvoice;
  paymentHistory: Stripe.IList<Stripe.charges.ICharge>;
}

async function getISPSubscription (id: string): Promise<SubscriptionData | null> {
  return await ispService.getISPSubscription(id);
}

async function getIspUsageStatistics (id: string): Promise<{ monthly_usage: number }> {
  return await ispService.getIspUsageStatistics(id);
}

function getUserSubscriptionData (uid: string): Promise<Record<string, SubscriptionData>> {
  return new Promise((resolve, reject) => {
    request<Record<string, SubscriptionData>>(`users/${uid}/subscriptions`)
      .then(res => resolve(res.data))
      .catch(reject);
  });
}

async function getISPCustomerData (id: string) {
  return await ispService.getISPCustomerData(id);
}

async function createUserStripeCustomerAccount (uid: string): Promise<pr.users.User> {
  try {
    const response = await post<pr.users.User>(`users/${uid}/customer-account`);

    return response.data;
  } catch (err) {
    console.error(err);

    throw err;
  }
}

function getCustomerDataForUser (uid: string) {
  return new Promise((resolve, reject) => {
    request(`users/${uid}/billing`)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
}

function submitOwnerSwap (data: any) {
  return new Promise((resolve, reject) => {
    post(`isps/${data.ISPID}/customer/swap`, data)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
}

function toggleGhostAccountLink(ghost: any, operationData: any, newLinkStatus: any) {
  return new Promise((resolve, reject) => {
    post(`ghostaccounts/toggle`, { ghost, operationData, newLinkStatus})
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  })
}

function getFirebaseAuthToken () {
  return new Promise((resolve, reject) => {
    const currentUser = firebaseProvider.getConsoleAuth().currentUser;

    currentUser?.getIdToken()
      .then(token => resolve(token))
      .catch(err => reject(err));
  });
}

function getCRMEntry (ISPID: string) {
  return new Promise((resolve, reject) => {
    request(`CRM/${ISPID}`)
      .then((res) => {
        console.log(res.data);

        resolve(res.data);
      })
      .catch(reject);
  })
}

function createFeedback(ISPID: string, feedback: any) {
  return new Promise((resolve, reject) => {
    post(`CRM/${ISPID}/feedback`, feedback)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  })
}

export type ISPNote = {
  id: number;
  ispId: number;
  note: string;
  username: string;
  createdAt: string;
  updatedAt: string;
};

export function getISPNotes (ispSqlId: number): Promise<ISPNote[]> {
  return new Promise((resolve, reject) => {
    request<ISPNote[]>(`notes/isps/${ispSqlId}`)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
}

function createNote (note: Partial<ISPNote>) {
  return new Promise((resolve, reject) => {
    post(`notes/`, note)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  })
}

function updateNote (noteID: number, note: any): Promise<ISPNote[]> {
  return new Promise((resolve, reject) => {
    patch(`notes/${noteID}`, note)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
}

function deleteNote (noteID: number) {
  return new Promise((resolve, reject) => {
    deleteRequest(`notes/${noteID}`)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  })
}

function createContact (ispId: number, contact: any) {
  return new Promise((resolve, reject) => {
    post(`contacts/isps/${ispId}`, contact)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
}

function updateContact (contactID: string, updates: Record<string, any>) {
  return new Promise((resolve, reject) => {
    patch(`contacts/${contactID}`, updates)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
}

function deleteContact (contactID: string) {
  return new Promise((resolve, reject) => {
    deleteRequest(`contacts/${contactID}`)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  })
}

async function updateISP (id: number, updates: Record<string, any>) {
  return await ispService.updateISP(id, updates);
}

/**
 * Updates CSPName in Firebase operation profile
 */
async function updateISPName (firebaseId: string, updatedName: string) {
  return await ispService.updateISPName(firebaseId, updatedName);
}
async function deleteISP (id: string) {
  return await ispService.deleteISP(id);
}

function submitNewStation (station: any) {
  return new Promise((resolve, reject) => {
    post('stations', { ...station })
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
}

function deleteStation (id: string) {
  return new Promise((resolve, reject) => {
    deleteRequest(`stations/${id}`)
      .then((res) => {
        const { data } = res;

        resolve(data);
      })
      .catch(reject);
  });
}

function updateStation (id: string, updates: Record<string, any>) {
  return new Promise((resolve, reject) => {
    patch(`stations/${id}`, { ...updates })
      .then((res) => {
        const { data } = res;

        resolve(data);
      })
      .catch(reject);
  });
}

function createStationISP (entry: any) {
  return new Promise((resolve, reject) => {
    post(`isps/stations`, entry)
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
}

function deleteStationISP (ispId: string, stationId: string | number) {
  return new Promise((resolve, reject) => {
    if (!stationId || !ispId) {
      return reject(new Error('No station or isp id was provided.'));

    }

    deleteRequest(`isps/${ispId}/stations/${stationId}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  })
}

function getISPContacts (id: number) {
  return new Promise((resolve, reject) => {
    request(`contacts/isps/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  });
}

function submitNewISP(isp: any) {
  return new Promise((resolve, reject) => {
    post(`isps`, isp)
      .then(res => {
        resolve(res.data);
      })
      .catch(reject);
  });
}

function login (username: string, password: string) {
  return new Promise((resolve, reject) => {
    post('token', { username, password })
      .then(response => {
        resolve(response);
      })
      .catch(reject);
  });
}

function checkToken () {
  return new Promise((resolve, reject) => {
    request('token')
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  });
}

function search (searchText: string) {
  return new Promise((resolve, reject) => {
    request(`search/${searchText}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  });
}

function searchUsers (searchText: string) {
  return new Promise((resolve, reject) => {
    request(`search/users/${searchText}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  });
}

function searchUserByProperty (property: string, searchText: string) {
  return new Promise((resolve, reject) => {
    request(`search/users/${property}/${searchText}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  });
}

function searchStations (searchText: string, stationID?: string) {
  return new Promise((resolve, reject) => {
    request(`search/stations/${searchText}${stationID ? `/${stationID}` : ''}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  });
}

function searchISPs (searchText: string) {
  return new Promise((resolve, reject) => {
    request(`search/isps/${searchText}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  });
}

function searchContacts (searchText: string) {
  return new Promise((resolve, reject) => {
    request(`search/contacts/${searchText}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  });
}

function getFeatureFlags (): Promise<FirebaseFeatureFlags> {
  return new Promise((resolve, reject) => {
    request<FirebaseFeatureFlags>('feature-flags')
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  })
}

function createFeatureFlag (flagKey: string, name: string) {
  return new Promise((resolve, reject) => {
    post(`feature-flags/${flagKey}`, { name })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  })
}

function changeFeatureFlagName (flagKey: string, name: string) {
  return new Promise((resolve, reject) => {
    patch(`feature-flags/${flagKey}/name/${name}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  })
}

function changeFeatureFlagGlobalState (flagKey: string, state: GlobalState) {
  return new Promise((resolve, reject) => {
    patch(`feature-flags/${flagKey}/state/${state}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  })
}

function changeFeatureFlagOperationState (flagKey: string, operationId: string, enabled: boolean) {
  return new Promise((resolve, reject) => {
    patch(`feature-flags/${flagKey}/operations/${operationId}`, { enabled })
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  })
}

function deleteFeatureFlag (flagKey: string) {
  return new Promise((resolve, reject) => {
    deleteRequest(`feature-flags/${flagKey}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  })
}

function clearServiceArea (serviceAreaId: string) {
  return new Promise((resolve, reject) => {
    deleteRequest(`serviceareas/${serviceAreaId}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(reject);
  })
}

function logout () {
  return axios.delete(url + 'token');
}

async function request<T>(path: string): Promise<AxiosResponse<T>> {
  const authToken = await getFirebaseAuthToken();
  const apiRequest: AxiosRequestConfig = {
    method: 'get',
    url: url + path,
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  };

  return axios(apiRequest);
}

async function post<T = any> (path: string, body?: any): Promise<AxiosResponse<T>> {
  const authToken = await getFirebaseAuthToken();
  const apiRequest: AxiosRequestConfig = {
    method: 'post',
    url: url + path,
    data: body,
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  };

  return axios(apiRequest);
}

async function patch<T = any>(path: string, body?: any): Promise<AxiosResponse<T>> {
  const authToken = await getFirebaseAuthToken();
  const apiRequest: AxiosRequestConfig = {
    method: 'patch',
    url: url + path,
    data: body,
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  };

  return axios(apiRequest);
}

async function deleteRequest (path: string, body?: any) {
  const authToken = await getFirebaseAuthToken();
  const apiRequest: AxiosRequestConfig = {
    method: 'delete',
    data: body,
    url: url + path,
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  };

  return axios(apiRequest);
}


export default {
  request,
  post,
  patch,
  deleteRequest,
  updateISP,
  login,
  logout,
  checkToken,
  submitNewStation,
  deleteStation,
  updateStation,
  getCRMEntry,
  createContact,
  createFeedback,
  search,
  searchUsers,
  searchUserByProperty,
  searchStations,
  searchISPs,
  getISPContacts,
  updateContact,
  deleteContact,
  createNote,
  updateNote,
  getISPNotes,
  deleteNote,
  submitNewISP,
  createStationISP,
  deleteStationISP,
  deleteISP,
  updateISPName,
  searchContacts,
  getGhostAccount,
  toggleGhostAccountLink,
  getISPSubscription,
  getISPCustomerData,
  getIspUsageStatistics,
  getCustomerDataForUser,
  createUserStripeCustomerAccount,
  getStripeBalance,
  getStripeBalanceHistory,
  getStripeBalanceHistoryTransaction,
  clearInvoiceItem,
  getApiDataForId,
  getCrmIdForFirebaseId,
  getLatestIspProperty,
  allowBillingForUser,
  getUserData,
  getUserDataByFedExId,
  getManualUploadStatus,
  toggleManualUpload,
  getFeatureFlags,
  createFeatureFlag,
  changeFeatureFlagName,
  changeFeatureFlagGlobalState,
  changeFeatureFlagOperationState,
  deleteFeatureFlag,
  clearServiceArea,
  submitOwnerSwap,
  getUserSubscriptionData
};
