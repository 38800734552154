import React from 'react';
import './FirebaseTextLink.css';
import config from '../../config.json';

export default function FirebaseTextLink ({ path, text }) {
  const url = config.productFirebaseDatabaseUrl + path;

  return (
    <a
      href={url}
      className='FirebaseTextLink'
      target='_blank'
      rel="noreferrer"
    >
      <div className='icon'>F</div>
      <p>{text}</p>
		</a>
	);
}
