import React from 'react';
import './IconLink.css';

const types = {
  cus: {
    baseUrl: 'customers',
    title: 'Customer'
  },
  sub: {
    baseUrl: 'subscriptions',
    title: 'Subscription'
  }
}

export default function IconLink ({ onClick, title, color, icon }) {
  return (
    <a
      href='#'
      className='IconLink'
      onClick={onClick}
    >
			<i
        className={`icon mdi mdi-${ icon }`}
        style={{ backgroundColor: color }}
      />

      { !!title && <p style={{ color }}>{ title }</p> }
		</a>
	);
}
