import React from "react";
import pr from "@packageroute/types-firebase";
import "./ActiveISPItem.css";

type Profile = pr.isp.profile.Profile;

type Props = {
  profile: Profile;
  onClick: () => void;
};

export default function ISPItem({ profile, onClick }: Props) {
  return (
    <div key={profile.ISPID} className="ActiveISPItem" onClick={onClick}>
      <p>
        {profile.CSPName}{" "}
        <span style={{ color: "#444", fontSize: "12px" }}>
          {profile?.station?.stationID ?? "No station Id"}
        </span>
      </p>
    </div>
  );
}
