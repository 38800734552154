import React from 'react';

import './TemplateOption.css';
import Icon from '../../../Icon/Icon';

export default function TemplateOption (props) {
  const { template } = props;
  const activeVersion = template.versions[0];

  return (
    <div className='TemplateOption' onClick={props.onClick}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <p>{template.name}</p>

        {
          props.selected === true
            ? <p style={{ color: 'rgb(22, 142, 50)', fontSize: '12px', marginLeft: '10px' }}>
              <Icon name='check' color='rgb(22, 142, 50)' size={12} /> Selected
            </p>
            : null
        }
      </div>

      <p style={{ fontSize: '12px' }}><span>Subject:</span> {activeVersion.subject}</p>
    </div>
  );
}
