import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import pr from "@packageroute/types-firebase";

export type StationId = string;
export type Station = pr.stations.Station;
export type Stations = Record<StationId, Station>;

export enum Status {
  INITIAL = "INITIAL",
  UPDATED = "UPDATED",
  FAILED = 'FAILED',
}

type StationCategories = {
  isHdOnly: Record<StationId, true>;
  hasCoLocation: Record<StationId, true>;
  hasAdvocates: Record<StationId, true>;
}

type StationsState = {
  status: Status;
  data: Stations;
  categories: StationCategories;
};

const initialState: StationsState = {
  status: Status.INITIAL,
  data: {},
  categories: {
    isHdOnly: {},
    hasCoLocation: {},
    hasAdvocates: {},
  }
};

const productStations = createSlice({
  name: "productStations",
  initialState: initialState,
  reducers: {
    updateStations: (
      state,
      action: PayloadAction<{ stations: Stations }>
    ) => {
      const { stations } = action.payload;

      for (const stationId in stations) {
        const station = stations[stationId];

        state.data[stationId] = station;

        if (station.hasCoLocation) state.categories.hasCoLocation[stationId] = true;
        if (station.hasAdvocates) state.categories.hasAdvocates[stationId] = true;
        if (station.hdOnly) state.categories.isHdOnly[stationId] = true;
      }

      state.status = Status.UPDATED;
    },
    updateStation: (
      state,
      action: PayloadAction<{ stationId: string, station: Station }>
    ) => {
      const { stationId, station } = action.payload;

      state.data[stationId] = station;

      if (station.hasCoLocation) state.categories.hasCoLocation[stationId] = true;
      if (station.hasAdvocates) state.categories.hasAdvocates[stationId] = true;
      if (station.hdOnly) state.categories.isHdOnly[stationId] = true;
      
      state.status = Status.UPDATED;
    },
    removeStation: (state, action: PayloadAction<{ stationId: string }>) => {
      const { stationId } = action.payload;

      delete state.data[stationId];

      delete state.categories.isHdOnly[stationId];
      delete state.categories.hasCoLocation[stationId];
      delete state.categories.hasAdvocates[stationId];

      state.status = Status.UPDATED;
    },
    resetStations: (state) => {
      state.data = {};
      state.categories = {
        isHdOnly: {},
        hasCoLocation: {},
        hasAdvocates: {},
      }
      state.status = Status.INITIAL;
    },
  },
});

export const { updateStations, updateStation, removeStation, resetStations } =
  productStations.actions;

export default productStations.reducer;
