import React from 'react';
import {RouteComponentProps} from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import ScrollView from '../../Page/ScrollView';
import ReferredUser from './ReferredUser';
import api from '@utils/api';
import { RootState } from '@reducers';
import * as selectors from '@selectors';

import './Referrals.css';

export enum ReferralStatus {
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
}

type Props = {
  history: RouteComponentProps['history'];
} & PropsFromRedux;

type State = {
  status: ReferralStatus;
}

class Referrals extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props);

    this.state = {
      status: ReferralStatus.PENDING
    };

    this.goToISPPage = this.goToISPPage.bind(this);
    this.goToUserPage = this.goToUserPage.bind(this);
  }

  async goToISPPage (ISPID: string) {
    const res = await api.request<{ id: string }>(`isps/firebase-id/${ISPID}`);
    const isp = res.data;

    if (!isp || !isp.id) return;

    this.props.history.push(`isps/${isp.id}`);
  }

  goToUserPage (uid: string) {
    try {
      const user = this.props.users[uid];
  
      if (!user) {
        throw new Error(`No user for record ${uid}`);
      }

      this.props.history.push(`/users/${user.FedExId}`);
    } catch (err) {
      console.error(err);
    }
  }

  render () {
    const { users, liveISPList, referrals, stationsWithAdvocates } = this.props;
    const advocates = Object.keys(stationsWithAdvocates || {}).reduce<Record<string, boolean>>((acc, stationId) => {
      const station = stationsWithAdvocates[stationId];
      const advocates = station.advocates || {};

      for (const userId in advocates) {
        const advocate = advocates[userId];

        if (advocate.isAdvocate === true) {
          acc[userId] = true;
        }
      }

      return acc;
    }, {});
    const pendingReferrals = Object.keys(referrals || {}).reduce<{ uid: string, referral: any, inviteCode: string }[]>((acc, inviteCode) => {
      const referral = referrals[inviteCode];
      const referredUsers: string[] = [];

      if (referral.pendingInvites) {
        for (const uid in referral.pendingInvites) {
          if (!referredUsers.includes(uid)) {
            referredUsers.push(uid);
          }
        }
      }

      for (const uid of referredUsers) {
        acc.push({
          uid,
          referral,
          inviteCode
        });
      }

      return acc;
    }, []);
    const completedReferrals = Object.keys(referrals || {}).reduce<{ uid: string, referral: any, inviteCode: string }[]>((acc, inviteCode) => {
      const referral = referrals[inviteCode];
      const referredUsers: string[] = [];

      if (referral.completedInvites) {
        for (const uid in referral.completedInvites) {
          if (!referredUsers.includes(uid)) {
            referredUsers.push(uid);
          }
        }
      }

      for (const uid of referredUsers) {
        acc.push({
          uid,
          referral,
          inviteCode
        });
      }

      return acc;
    }, []);

    if (!liveISPList || !users) return null;

    const { status } = this.state;

    return (
      <div className='Referrals'>
        <div className='options'>
          <div
            onClick={() => this.setState({ status: ReferralStatus.PENDING })}
            style={{ backgroundColor: status === ReferralStatus.PENDING ? '#006DAB' : 'inherit' }}
            className={`Group ${status === ReferralStatus.PENDING ? 'active' : ''}`}>
            <p>Pending</p>
          </div>

          <div
            onClick={() => this.setState({ status: ReferralStatus.COMPLETE })}
            style={{ backgroundColor: status === ReferralStatus.COMPLETE ? '#168E32' : 'inherit' }}
            className={`Group ${status === ReferralStatus.COMPLETE ? 'active' : ''}`}>
            <p>Complete</p>
          </div>
        </div>

        <ScrollView height='350px' direction='vertical'>
          {
            status === ReferralStatus.PENDING && pendingReferrals
              .filter(({ uid }) => users[uid])
              .map(({ uid, referral }, i) => {
                const user = users[uid];
                const referrer = users[referral.uid];
                const referredByAdvocate = !!advocates[referral.uid];
                const isps = Object.keys(user?.ISPS || {}).map(ISPID => liveISPList[ISPID]).filter(profile => !!profile);

                return (
                  <ReferredUser
                    key={`{status}_${i}`}
                    user={user}
                    referrer={referrer}
                    referredByAdvocate={referredByAdvocate}
                    goToUserPage={this.goToUserPage}
                    isps={isps}
                    statusLabel={ReferralStatus.PENDING}
                    color='#006DAB'
                  />
                );
              })
          }

          {
            status === ReferralStatus.COMPLETE && completedReferrals
              .filter(({ uid }) => users[uid])
              .sort((a, b) => {          
                return getCompletionDate(a) > getCompletionDate(b) ? -1 : 1;
              })
              .map(({ uid, referral }, i) => {
                const user = users[uid];
                const referrer = users[referral.uid];
                const referredByAdvocate = !!advocates[referral.uid];
                const invite = referral.completedInvites[uid];
                const isps = Object.keys(user?.ISPS || {}).map(ISPID => liveISPList[ISPID]).filter(profile => !!profile);

                return (
                  <ReferredUser
                    key={`{status}_${i}`}
                    user={user}
                    referrer={referrer}
                    referredByAdvocate={referredByAdvocate}
                    completedAt={invite.completedAt}
                    goToUserPage={this.goToUserPage}
                    isps={isps}
                    statusLabel={ReferralStatus.COMPLETE}
                    color='#168E32'
                  />
                );
              })
          }
        </ScrollView>
      </div>
    );
  }
}

function mapStateToProps (state: RootState) {
  return {
    users: selectors.users.selectUsers(state),
    liveISPList: selectors.liveIspList.selectLiveIspList(state),
    referrals: selectors.referrals.selectReferrals(state),
    stationsWithAdvocates: selectors.stations.productStations.selectStationsWithAdvocates(state),
  }
}

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Referrals);

function getCompletionDate (completedReferral: any) {
  const { uid, referral } = completedReferral;
  const invite = referral.completedInvites[uid];

  return invite.completedAt;
}