export default function salesCycleStatus (isp = {}) {
  if (isp.notInterested) return 'NOT INTERESTED';
  if (isp.churned) return 5;
  if (isp.signup) return 4;
  if (isp.trial) return 3;
  if (isp.qualified) return 2;
  if (isp.initialContact) return 1;
  if (isp.lead) return 0;

  return 'NO STATUS';
}

export const levelsMap = {
  lead: 0,
  initialContact: 1,
  qualified: 2,
  trial: 3,
  signup: 4,
  churned: 5
};

export function getLevelName (level) {
  return {
    '0': 'lead',
    '1': 'initialContact',
    '2': 'qualified',
    '3': 'trial',
    '4': 'signup',
    '5': 'churned',
  }[level];
}

export function getDisplayName (level) {
  return {
    '0': 'Lead',
    '1': 'Contact',
    '2': 'Qualified',
    '3': 'Trial',
    '4': 'Signup',
    '5': 'Churned',
    'NO STATUS': 'No Status',
    'NOT INTERESTED': 'Not Interested'
  }[level];
}
export function getDisplayColor (level) {
  return {
    '0': 'transparent',
    '1': 'rgb(185, 12, 15)',
    '2': '#006DAB',
    '3': '#EA7D14',
    '4': 'rgb(22, 142, 50)',
    '5': '#616161',
    'NO STATUS': 'transparent',
    'NOT INTERESTED': 'transparent'
  }[level];
}
