import React from 'react';
import ReactDOM from 'react-dom';
import {mergeStyles, Styles} from './utils';
import './Modal.css';

type Props = {
  onBackClick?: () => void;
  style?: React.CSSProperties;
  children?: any;
}

export default function Modal (props: Props) {
  const clickProps = props.onBackClick
    ? { onClick: props.onBackClick }
    : {};
  return (
    <div className='Modal' style={props.style || {}}>
      <div { ...clickProps } className='modalBackground' />

      {props.children}
    </div>
  );
}

interface BetterModalProps {
  // children?: JSX.Element | JSX.Element[] | null;
  children?: (string | JSX.Element | null)[] | JSX.Element | JSX.Element[] | number | string | string[] | undefined | null;
  className?: string;
  isMobile?: boolean;
  centerFullScreen?: boolean;
  onRequestClose: () => void;
  passthrough?: boolean;
  style?: Styles;
  visible?: boolean;
  wrapStyle?: Styles;
  clickAwayStyle?: Styles;
}

export function PortalModal ({
  children = null,
  className = '',
  isMobile = true,
  centerFullScreen = false,
  onRequestClose,
  passthrough = false,
  style = {},
  visible = false,
  wrapStyle = {},
  clickAwayStyle = {}
}: BetterModalProps) {


  if (!visible) return null;

  if (passthrough) return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );

  const modalComponent = (
    <div className='ModalWrap' style={mergeStyles(wrapStyle)}>
      <div 
        className={`PortalModal ${className} ${visible ? 'show' : 'hide'}  ${isMobile ? 'mobile' : ''}`}
        style={{
          ...(isMobile ? {
            height: '100%', //`calc(100vh - ${NAV.HEADER.MOBILE.height}`
          } : {}),
          ...mergeStyles(style)
        }}
        // onClick={() => setShowModal(false)}
      >
        <div>
          {children}
        </div>
      </div>
      <div 
        className={`ModalClickAway ${visible ? 'show' : 'hide'}`} 
        style={mergeStyles(clickAwayStyle)}
        onClick={(e) => {
          e.stopPropagation();
          onRequestClose()
        }}
      />
    </div>
  )

  if (centerFullScreen) return (
    <FullScreenPortal key='modal'>
      { modalComponent }
    </FullScreenPortal>
  )

  else return modalComponent
}

const modalRoot = document.getElementById('portal-root');

type FullscreenProps = {
  children?: any;
}
export class FullScreenPortal extends React.Component<FullscreenProps> {
  el: HTMLDivElement;

  constructor(props: FullscreenProps) {
    super(props);

    this.el = document.createElement('div');
  }
  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    if (modalRoot) {
      modalRoot.appendChild(this.el);
    }
  }

  componentWillUnmount() {
    if (modalRoot) {
      modalRoot.removeChild(this.el);
    }
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.el
    );
  }
}