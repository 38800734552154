import { Dispatch } from "@reduxjs/toolkit";
import {
  updateSalesCampaignCollections,
  resetSalesCampaignCollections,
} from "@reducers/salesCampaignCollections";
import firebaseProvider from '@utils/firebase';
import { ConsoleCollectionsService } from '@services';
import bugsnagClient from "@utils/bugsnag";

export async function subscribeSalesCampaignCollections(dispatch: Dispatch) {
  return new Promise((resolve, reject) => {
    try {
      const collectionsService = new ConsoleCollectionsService(firebaseProvider);
  
      collectionsService.subscribe(collections => {
        dispatch(updateSalesCampaignCollections({ collections: collections ?? {} }));

        resolve(collections);
      })
    } catch (err) {
      console.error(err);
      bugsnagClient.notify(err as Error);

      reject(err);
    }
  })
}

export function unsubscribeSalesCampaignCollections (dispatch: Dispatch) {
  try {
    const collectionsService = new ConsoleCollectionsService(firebaseProvider);

    collectionsService.unsubscribe();

    dispatch(resetSalesCampaignCollections());
  } catch (err) {
    console.error(err);

    throw err;
  }
}
