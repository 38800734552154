import React, { CSSProperties } from 'react';
import { View, StyleSheet } from 'react-native';

interface Props {
  children?: any;
  bottomBorder?: boolean;
  style?: CSSProperties;
}

export default function DetailRow ({ children, bottomBorder, style = {} }: Props) {
  return (
    <View 
      className='DetailRow'
      style={{
        ...styles.container,
        borderBottomWidth: bottomBorder ? 1 : 0,
        ...style
      }}
    >
      { children }
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: '#DDD',
    borderWidth: 0,
    borderStyle: 'solid',
    position: 'relative'
  }
});
