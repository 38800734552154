import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TotalOperationsAndStations = {
  isps: number;
  stations: number;
}

export enum Status {
  INITIAL = 'INITIAL',
  PENDING = 'PENDING',
  UPDATED = 'UPDATED',
  FAILED = 'FAILED'
}

export type State = {
  data: TotalOperationsAndStations;
  status: Status;
};

const initialState: State = {
  data: {
    isps: 0,
    stations: 0,
  },
  status: Status.INITIAL,
};

const totalOperationsAndStationsSlice = createSlice({
  name: 'totalOperationsAndStations',
  initialState: initialState,
  reducers: {
    updateTotalsList: (state, action: PayloadAction<{ totalOperationsAndStations: TotalOperationsAndStations }>) => {
      const { totalOperationsAndStations } = action.payload;

      state.status = Status.UPDATED;
      state.data = totalOperationsAndStations;
    },
    updateTotalsFailed: (state) => {
      state.status = Status.FAILED;
    },
    resetTotalsList: (state) => {
      state.status = Status.INITIAL;
      state.data.isps = 0;
      state.data.stations = 0;
    }
  }
})

export const { 
  updateTotalsList,
  updateTotalsFailed,
  resetTotalsList
} = totalOperationsAndStationsSlice.actions

export default totalOperationsAndStationsSlice.reducer;