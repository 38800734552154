import ConsoleApiService from "./ConsoleApiService";
import pr from '@packageroute/types-firebase';

export type IspProfile = pr.isp.profile.Profile;

export type ActiveIspSearchResult = { pushKey: string, profile: IspProfile };
export type ActiveIspSearchResults = {
  activeISPs: ActiveIspSearchResult[]
}

export class ProductIspProfileService {
  constructor(private api: ConsoleApiService) {}

  /**
   * 
   * @param operationId 
   * @returns 
   */
  async searchActiveIsps(searchText: string): Promise<ActiveIspSearchResults> {
    try {
      const { activeISPs } = await this.api.get<ActiveIspSearchResults>(`search/isps/active/${searchText}`);
  
      return { activeISPs };
    } catch (err) {
      console.error(err);

      return { activeISPs: [] };
    }
  }
}
