import React from 'react';
import {Styles, mergeStyles} from './utils';
import View from './View';

type Props = {
  style?: Styles;
  className?: string;
  children?: any;
}

export default function SafeAreaView ({style = {}, className = '', children = null}: Props) {

  return (
    <View 
      className={`SafeAreaView ${className}`}
      style={mergeStyles(style)}
    >
      {children}
    </View>
  );
}