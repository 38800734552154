import { useEffect, useState, useCallback } from 'react';
import { Vendor, Subscription, Schedule } from '@packageroute/vedr-service-model-typescript';
import { ConsoleVedrManagementService, consoleApiInitializer, LytxAuthorizationCredentials, VendorAuthorization } from '@services';
import { useLiveIsp } from '@hooks/redux';

const defaultCreds = {
  eventsSoapApiUrl: '',
  eventsSoapApiUsername: '',
  eventsSoapApiPassword: '',
  driverSoapApiDispatchUrl: '',
  driverSoapApiDispatchUsername: '',
  driverSoapApiDispatchPassword: '',
  apiKey: 'NOT_REQUIRED_YET',
}

export function useVedrFeatureManagement (operationId: string) {
  const liveIsp = useLiveIsp(operationId);
  const [authorizationSettings, setVendorAuthorizationSettings] = useState<VendorAuthorization | null>(null);
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [credentials, setCredentials] = useState<LytxAuthorizationCredentials>(defaultCreds)

  useEffect(() => {
    const vedrService = new ConsoleVedrManagementService(consoleApiInitializer); 

    setLoading(true);

    vedrService.getServiceStatus(operationId, Vendor.Lytx).then(({ schedules, subscription }) => {
      setSchedules(schedules);
      setSubscription(subscription);
      setLoading(false);
      setLoaded(true);
    })
    .catch((err) => {
      console.error(err);

      setLoading(false);
      setLoaded(true);
    });

    vedrService.getVendorAuthorization(operationId, Vendor.Lytx)
    .then(({ vendor_authorization }) => {
      setVendorAuthorizationSettings(vendor_authorization);
    })
    .catch(err => console.error(err));
  }, [operationId]);

  const markAuthorizationRequestAsSent = useCallback(async () => {
    try {
      const vedrService = new ConsoleVedrManagementService(consoleApiInitializer); 

      const { vendor_authorization } = await vedrService.setVendorAuthorizationAsRequested(operationId, Vendor.Lytx);

      setVendorAuthorizationSettings(vendor_authorization);
    } catch (err) {
      console.error(err);
    }
  }, [operationId]);

  const submitLytxCredentials = useCallback(async () => {
    if (!liveIsp.timeZone) {
      return setErrorMessage('Timezone required');
    }

    if (!validateCredentials(credentials)) {
      return setErrorMessage('Please complete credentials. Missing data.')
    }

    setErrorMessage("");

    const vedrService = new ConsoleVedrManagementService(consoleApiInitializer); 

    try {
      if (!subscription) {
        const { schedules, subscription } = await vedrService.initializeService(operationId, liveIsp.timeZone, Vendor.Lytx, credentials)    
    
        setSchedules(schedules);
        setSubscription(subscription);
      } else {
        const { subscription } = await vedrService.updateSubscriptionCredentials(operationId, Vendor.Lytx, credentials)    
        setSubscription(subscription);
      }
      
      setCredentials(defaultCreds);
    } catch (err: any) {
      setErrorMessage(err?.message || 'Vedr service initialization failed');
    }
  }, [operationId, liveIsp, subscription, credentials]);

  const cancelLytxService = useCallback(async () => {
    try {
      setErrorMessage('');
      const vedrService = new ConsoleVedrManagementService(consoleApiInitializer); 

      await vedrService.cancelService(operationId, Vendor.Lytx)
    } catch (err: any) {
      setErrorMessage(err.message);
    }

  }, [operationId])

  return {
    loaded,
    loading,
    liveIsp,
    vendorAuthorizationSettings: authorizationSettings,
    subscription,
    schedules,
    errorMessage,
    credentials,
    setCredentials,
    credentialsAreValid: validateCredentials(credentials),
    submitLytxCredentials,
    cancelLytxService,
    markAuthorizationRequestAsSent
  }
}

function validateCredentials (credentials: LytxAuthorizationCredentials) {
  return !!(
    credentials &&
    (
      credentials.eventsSoapApiUrl && 
      !isEventFileStreamingUrl(credentials.eventsSoapApiUrl)
    ) &&
    credentials.eventsSoapApiUsername &&
    credentials.eventsSoapApiPassword &&
    (
      credentials.driverSoapApiDispatchUrl && 
      !isEventFileStreamingUrl(credentials.driverSoapApiDispatchUrl)
    ) &&
    credentials.driverSoapApiDispatchUsername &&
    credentials.driverSoapApiDispatchPassword &&
    credentials.apiKey
  )
}

/**
 * Lytx supplies us with 3 urls, one for Dispatch, one for Events, and one for Event file streaming.
 * 
 * The Event filestreaming url has been previously entered as the events URL, causing significant errors
 * in the vedr service.
 * 
 * This function identifies that url, allowing us to invalidate it as an entry
 */
export function isEventFileStreamingUrl (url: string): boolean {
  return url.includes("EventFileStreamingService");
}