import React from "react";
import { mergeStyles, Styles } from './utils';

import "./TouchableOpacity.css";

interface Props {
  className?: string;
  flex?: boolean;
  style?: Styles;
  children?: (string | JSX.Element | null)[] | JSX.Element | JSX.Element[] | number | string | string[] | undefined | null;
  pointerEvents?: 'box-none' | 'none' | 'auto' | null;
  onClick?: ((event?: React.SyntheticEvent) => void) | null;
  onPress?: ((event?: React.SyntheticEvent) => void) | null;
  onMouseEnter?: (event?: React.MouseEvent) => void;
  onMouseOver?: (event?: React.MouseEvent) => void;
  onMouseLeave?: (event?: React.MouseEvent) => void;
  scrollRef?: (ref: React.RefObject<HTMLDivElement>) => void;
}

interface State {
  pressed: boolean;
  pressStartTime: number | null;
  refSet: boolean;
}

export default class TouchableOpacity extends React.Component<Props, State> {
  ViewRef: React.RefObject<HTMLDivElement>; 
  settingRef: boolean;

  constructor(props: Props) {
    super(props);

    this.state = {
      pressed: false,
      pressStartTime: null,
      refSet: false,
    };

    this.settingRef = false;
    this.ViewRef = React.createRef<HTMLDivElement>();
  }

  componentDidMount () {
    if (this.props.scrollRef && !this.settingRef) this.setRef();
  }

  componentDidUpdate () {
    if (this.props.scrollRef && !this.settingRef) this.setRef();
  }

  setRef = () => {
    this.settingRef = true;
    if (!this.state.refSet && this.ViewRef) {

      this.setState({ refSet: true }, () => {
        this.settingRef = false;
        this.props.scrollRef?.(this.ViewRef);
      })
    }
  }


  click = (e: React.SyntheticEvent) => {
    e.persist();
    
    const { onClick, onPress } = this.props;

    if (onPress) onPress(e);
    else if (onClick) onClick(e);
  }
  

  render() {
    const {
      className = "",
      flex = false,
      style = {},
      children = null,
      pointerEvents = null,
      onMouseEnter,
      onMouseOver,
      onMouseLeave,
      onClick,
      onPress
    } = this.props;

    const { click, state } = this;
    const clickable = !!(onClick || onPress);
    
    return (
      <div
        className={`TouchableOpacity ${className} ${
          state.pressed ? "pressed" : ""
        } ${clickable ? 'clickable' : ''}`}
        // @ts-ignore
        style={{
          ...(flex ? { display: 'flex' } : {}),
          ...mergeStyles(style),
          ...(pointerEvents ? { pointerEvents: pointerEvents === 'box-none' ? 'none' : pointerEvents } : {}),
        }}
        onClick={click}
        onMouseEnter={onMouseEnter}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        ref={this.ViewRef}
      >
        {children}
      </div>
    );
  }
}