import { RootState } from "@reducers";

const selectDailyOperationsUsage = (state: RootState) =>
  state.metrics.dailyOperationsUsage.data;

const selectTodayOperationsUsage = (state: RootState) =>
  state.metrics.dailyOperationsUsage.today;
  
const selectDailyOperationsUsageUpdatedAt = (state: RootState) =>
  state.metrics.dailyOperationsUsage.updatedAt;

export {
  selectDailyOperationsUsage,
  selectTodayOperationsUsage,
  selectDailyOperationsUsageUpdatedAt,
};
