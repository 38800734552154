import React from "react";
import { isp } from "@packageroute/types-firebase";
import Modal from "../../Page/Modal";
import { Spinner, Button } from "@common";
import { useColorPack } from "@hooks/redux";
import TransferOverview from './TransferOverview';
import NewOwnerPicker from './NewOwnerPicker';
import { useTransferOwnership } from './useTransferOwnership';
import "./TransferOwnershipModal.css"

type Props = {
  onClose: () => void;
  latestProfile: isp.profile.Profile;
  loadLatestProfile: () => Promise<void>;
};

export default function TransferOwnershipModal({
  latestProfile,
  loadLatestProfile,
  onClose,
}: Props) {
  const colorPack = useColorPack();
  const {
    loading,
    submitting,
    errorMessage,
    transferPreview,
    completedTransfer,
    newOwnerUid,
    setSelectedOwnerUid,
    initializeTransfer,
  } = useTransferOwnership(latestProfile, loadLatestProfile);

  const transferCanBeSubmitted = !!transferPreview?.newOwner?.uid;
  const transferWasSubmitted = !!completedTransfer;

  return (
    <Modal onBackClick={onClose}>
      <div
        className="TransferOwnershipModal"
        style={{
          backgroundColor: colorPack.baseHighlight,
          borderColor: colorPack.border,
          borderRadius: 10,
        }}
      >
        <div className='title-wrap'>
          <p className='title'>Ownership Transfer</p>

          {errorMessage && (
            <p className="error-message" style={{ color: colorPack.red }}>{errorMessage}</p>
          )}
        </div>

        <div className='content-wrap'>
          {loading ? (
            <div className='status-wrap'>
              <p style={{ marginBottom: 10 }}>
                {submitting
                  ? "Submitting Transfer"
                  : "Generating Transfer Preview"}
              </p>
              <Spinner size={30} />
            </div>
          ) : (
            <React.Fragment>
              <NewOwnerPicker
                profile={latestProfile}
                selectedOwnerUid={newOwnerUid}
                onNewOwnerSelectionChange={setSelectedOwnerUid}
              />

              <TransferOverview
                transfer={completedTransfer || transferPreview}
                isPreview={!completedTransfer}
              />

              {transferCanBeSubmitted && (
                <Button
                  color={colorPack.green}
                  onClick={initializeTransfer}
                  disabled={loading || transferWasSubmitted}
                >
                  Submit
                </Button>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </Modal>
  );
}
