import React from "react";
import Icon from '../Icon/Icon';
import { ScrollView } from 'react-native';
import "./Picker.css";

export type Option<V> = {
  key: string | number;
  value: V;
  label: string | number;
}

type Props<V> = {
  open?: boolean;
  options: Option<V>[];
  selectedValue: V | undefined | null;
  className?: string;
  dropdownHeight?: number;
  dropdownStyle?: React.CSSProperties;
  enabled?: boolean;
  itemStyle?: React.CSSProperties;
  label?: string;
  style?: React.CSSProperties;
  clickaway?: boolean;
  clickawayStyle?: React.CSSProperties;
  dropdownIcon?: string;
  hideIcon?: boolean;
  onValueChange: (value: V) => void;

  /**
   * unused prop, remains only for compatibility with RN
   */
  mode?: string;
}

type State = {
  showDropdown?: boolean;
  selectedIndex: number;
}

class Picker<V> extends React.Component<Props<V>, State> {
  constructor(props: Props<V>) {
    super(props);

    this.state = {
      showDropdown: !!props.open,
      selectedIndex: props.options.findIndex(({ value }) => String(value) === String(props.selectedValue))
    }

  }

  onClick = (value: V, selectedIndex: number) => {
    const { options, onValueChange } = this.props;


    onValueChange(options[selectedIndex].value);

    this.setState({ selectedIndex, showDropdown: false });
  }
  
  render () {
    const {
      className = "",
      dropdownHeight,
      dropdownStyle = {},
      enabled = true,
      itemStyle = {},
      label = null,
      // mode = "dialog",
      // onValueChange,
      // open,
      options = [],
      // prompt,
      selectedValue,
      style = {},
      clickaway = false,
      clickawayStyle = {},
      dropdownIcon = 'chevron-down',
      hideIcon = false,
    } = this.props;

    const { onClick, state: { selectedIndex, showDropdown } } = this;

    return (
      <React.Fragment>
      { clickaway && showDropdown && <div className='PickerClickAwayOverlay' style={clickawayStyle} onClick={() => this.setState({ showDropdown: false })} /> }
      <div className={`Picker ${className}`} style={style}>
        <div className='selectorWrap' onClick={() => this.setState({ showDropdown: !showDropdown })}>
          <PickerItem
            className="Selected"
            style={itemStyle}
            selected
            label={(options[selectedIndex] || {}).label || ''}
          />
          { !hideIcon && <Icon name={dropdownIcon} size={14} /> }
        </div>
        { showDropdown && 
          <PickerDropdown<V>
            label={label}
            selectedValue={selectedValue}
            onClick={onClick}
            enabled={enabled}
            style={{ maxHeight: dropdownHeight, ...dropdownStyle }}
            itemStyle={itemStyle}
            options={options}
            open={showDropdown}
          />
        }
      </div>
      </React.Fragment>
    );
  }
}

type DropDownProps<V> = {
  className?: string;
  label?: string | null;
  options?: Option<V>[];
  selectedValue: V | undefined | null;
  open?: boolean;
  enabled?: boolean;
  onClick: (value: V, index: number) => void;
  style?: React.CSSProperties;
  itemStyle?: React.CSSProperties;
  onValueChange?: (value: V, index: number) => void;
}
export function PickerDropdown<V>({ 
  className = '', 
  label = '', 
  options = [], 
  selectedValue, 
  open = false, 
  enabled = true, 
  onClick = () => {}, 
  style = {}, 
  itemStyle = {}, 
  onValueChange 
}: DropDownProps<V>) {
  const clickHandler = onValueChange || onClick;
  return (
    <div className={`PickerDropdown ${className}`}>
      {label && <label>{label}</label>}
      <ScrollView id={className} className={`Picker ${className}`} style={style}>
        { open && options.map((option, i) => {
          if (!option) return null;

          const {key, value, label} = option;
          
          return (
            <PickerItem 
              key={key || i} 
              style={itemStyle} 
              label={label} 
              selected={String(selectedValue) === String(value)} 
              onClick={() => clickHandler(value, i)}
              />
            )
        })}
      </ScrollView>
    </div>
  )
}

type PickerItemProps = {
  className?: string;
  style?: React.CSSProperties;
  label?: string | number;
  selected?: boolean;
  onClick?: () => void;
  color?: string;
}

function PickerItem({ 
  className = '', 
  style, 
  label = '', 
  selected = false, 
  onClick,
  color,
}: PickerItemProps) {
  return (
    <p 
      className={`PickerItem ${className} ${selected ? 'selected' : ''}`} 
      style={{...style, ...(color ? { color } : {})}} 
      // value={value || ''} 
      onClick={onClick}
    >
      {label || ''}
    </p>
  );
};

export default Picker;
export { PickerItem };
