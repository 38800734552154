import firebase from './firebase';
import config from './config';

const { creds, shards } = config;

if (!shards) throw Error('Missing firebase shards configuration');

const { 
  featureFlagsURL,
} = shards;

const DEFAULT_APP_URL = 'defaultDB';

class FirebaseProvider {
  private _initializedApps: { [url: string]: firebase.app.App };

  constructor () {
    this._initializedApps = {};

    this._onUserChange = this._onUserChange.bind(this);
  }

  private _getCredentials (url?: string) {
    return {
      ...creds,
      ...( url ? { databaseURL: url } : {}),
    }
  }

  private _getCurrentUser () {
    if (!this._initializedApps[DEFAULT_APP_URL]) throw Error('Unable to retrieve current user, default app not initialized');

    const defaultApp = this._initializedApps[DEFAULT_APP_URL];

    const currentUser = defaultApp.auth().currentUser;

    return currentUser;
  }

  private _appIsInitialized (url?: string) {
    return !!this._initializedApps[url || DEFAULT_APP_URL];
  }

  private _getInitializedApp (url?: string) {
    return this._initializedApps[url || DEFAULT_APP_URL];
  }

  private _initializeApp (url?: string, name?: string) {
    if (this._appIsInitialized(url)) return this._getInitializedApp(url);

    const credentials = this._getCredentials(url);

    const initializedApp = firebase.initializeApp(credentials, name);

    this._initializedApps[url || DEFAULT_APP_URL] = initializedApp;

    if (url) {
      const currentUser = this._getCurrentUser();

      if (!currentUser) return initializedApp;

      // In order for auth rules to be applied properly, the shard
      // must be provided with the current user. This imposes a limitation
      // as any shard requiring auth rules must be initialized after the default database
      // has been initialized and the user has logged in.
      initializedApp.auth().updateCurrentUser(currentUser);
    } else {
      this._watchCurrentUser();
    }

    return initializedApp
  }

  private _getDB (url?: string, name?: string) {
    const initializedApp = this._initializeApp(url, name);

    return initializedApp.database();
  }

  private _watchCurrentUser () {
    if (!this._initializedApps[DEFAULT_APP_URL]) throw Error('Unable to watch current user, default app not initialized');

    const defaultApp = this._initializedApps[DEFAULT_APP_URL];
    
    defaultApp.auth().onAuthStateChanged(this._onUserChange)
  }

  private async _onUserChange (user: firebase.User | null) {
    if (!user) return;

    for (const shardUrl in this._initializedApps) {
      if (shardUrl === DEFAULT_APP_URL) continue;

      const app = this._initializedApps[shardUrl];

      /**
       * If updateCurrentUser is provided with a null user, it throws an error.
       * This error cannot be caught at this location.
       * 
       * Ignoring this error is preferrable to handling the removal
       * of the initialized app, as once the app is initialized, 
       * deleting it using app.delete() then re-initializing it generates
       * a duplicate-app error, however we have no way of knowing
       * which apps are already initialized.
       */
      await app.auth().updateCurrentUser(user);
    }
  }

  public getConsoleDefaultApp () {
    return this._initializeApp();
  }

  /**
   * Get default remoteConfig
   */
  public getConsoleRemoteConfig () {
    const app = this._initializeApp();

    return app.remoteConfig();
  }

  /**
   * Get Firebase analytics
   */
  public getAnalytics () {
    const app = this._initializeApp();

    return app.analytics();
  }

  /**
   * Get Firebase auth
   */
  public getConsoleAuth () {
    const app = this._initializeApp();

    return app.auth();
  }

  /**
   * Get Firebase storage
   */
  public getStorage () {
    const app = this._initializeApp();

    return app.storage();
  }

  /**
   * Subscribe to connection state to Firebase default database
   * @param callback Function
   */
  public subscribeFirebaseConnection (callback: (connected: boolean) => void) {
    const defaultDb = this.getConsoleDefaultDB();

    defaultDb.ref('.info/connected').on('value', snap => {
      const connected = snap.val();

      callback(connected);
    });
  }

  /**
   * Get default firebase database
   */
  public getConsoleDefaultDB () {
    return this._getDB();
  }

  /**
   * Get Feature Flag database shard
   */
  public getConsoleFeatureFlagDB () {
    if (!featureFlagsURL) throw Error('Missing Firebase FeatureFlag url config');

    return this._getDB(featureFlagsURL, 'featureFlags');
  }
}

export { FirebaseProvider }
export default new FirebaseProvider();