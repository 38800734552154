import React from 'react';

import './Change.css';

export default function Change (props) {
  return (
    <div 
      class='change-btn' 
      onClick={props.onChange}
      style={{
        backgroundColor: props.color || 'rgb(22, 142, 50)'
      }}
      >
      <p>{props.label || 'Change'}</p>
    </div>
  )
}