import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Permission = {
  permissions: string[];
  fineGrainedPermissions: Record<FineGrainedPermission, string[]>;
};

export type Permissions = Record<string, Permission>;

export enum ISPPageFineGrainedPermissions {
  deleteIsp = "deleteIsp",
}

export type FineGrainedPermission = ISPPageFineGrainedPermissions;

export enum Status {
  INITIAL = 'INITIAL',
  PENDING = 'PENDING',
  UPDATED = 'UPDATED',
  FAILED = 'FAILED'
}

export type State = {
  data: Permissions;
  status: Status;
};

const initialState: State = {
  data: {},
  status: Status.INITIAL,
};

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: initialState,
  reducers: {
    updatePermissions: (state, action: PayloadAction<{ permissions: Permissions }>) => {
      const { permissions } = action.payload;

      state.status = Status.UPDATED;
      state.data = permissions;
    },
    resetPermissions: (state) => {
      state.status = Status.INITIAL;
      state.data = {};
    }
  }
})

export const { 
  updatePermissions,
  resetPermissions
} = permissionsSlice.actions

export default permissionsSlice.reducer;