import React from 'react';

import Icon from '../../Icon/Icon';
import './style/SalesCycle.css';
import salesCycleStatus, { getDisplayColor, getDisplayName } from '../../../utils/salesCycleStatus';
import moment from 'moment';

export default class SalesCycle extends React.Component {

  render() {
    const { isp } = this.props;
    const status = salesCycleStatus(isp);
    const statusName = getDisplayName(status);
    const statusColor = getDisplayColor(status);
    const nextStatusName = getDisplayName(status + 1);
    const notInterested = !!isp.notInterested;

    return (
      <div className='SalesCycle'>
        {
          !notInterested &&
          <p style={{ backgroundColor: statusColor, border: '1px solid #DDD', padding: '2px 4px', color: statusColor !== 'transparent' ? 'white' : 'black', fontSize: '12px', borderRadius: '1px' }}>{statusName}</p>
        }

        {
          isp.projectedClose && !notInterested
            ? <Icon name='arrow-right' color='#666' size={12} />
            : null
        }

        {
          isp.projectedClose && !notInterested
            ? <p>{moment(isp.projectedClose).format('MM-DD-YYYY')}</p>
            : null
        }

        {
          nextStatusName && !notInterested
            ? <Icon name='arrow-right' color='#666' size={12} />
            : null
        }

        {
          nextStatusName && !notInterested
            ? <p>{nextStatusName}</p>
            : null
        }

        {
          notInterested
            ? <p>Not Interested</p>
            : null
        }
      </div>
    )
  }
}
