import React from 'react';
import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken = 'pk.eyJ1IjoiZW1pbGVwciIsImEiOiJjam5rbzZkZDExYzhqM3BvMzd2OXNjb3RtIn0.4Xuy775aCS6K7pnJjcBhnQ';

export default class Map extends React.Component {
  constructor (props) {
    super(props);

    this.state = {};
  }

  componentDidMount () {
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/emilepr/cjyiu0vfr0xf51cmpv91981qq',
      center: [this.props.region.longitude, this.props.region.latitude],
      zoom: this.props.zoom || 12
    });

    map.on('load', () => {
      this.props.setMapRef(map);
    })

  }

  render () {
    return (
      <div
        ref={el => { this.mapContainer = el; }}
        style={{ position: 'absolute', width: '100%', top: 0, bottom: 0 }}
      />
    );
  }
}
