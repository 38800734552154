import { CSSProperties } from 'react';
import { isString } from '../../utils/typeGuards';

interface ExtendedCSSProperties extends CSSProperties {
  [CSSShortHandKey: string]: any;
}

interface IStyleSheet {
  [style: string]: ExtendedCSSProperties;
}

export interface NativeCompatibleCSSSstyleSheets extends pr.ObjectWithStringKeys {
  [StyleName: string]: NativeCompatibleCSSProperties;
}

export interface NativeCompatibleCSSProperties extends CSSProperties {
  paddingVertical?: string | number;
  paddingHorizontal?: string | number;
  marginVertical?: string | number;
  marginHorizontal?: string | number;
}

export interface CSSStyleSheets extends pr.ObjectWithStringKeys {
  [StyleName: string]: CSSProperties;
}

export default class StyleSheet {
  static create(styles: IStyleSheet) {
  
    const newStyle: { [key: string]: CSSProperties } = {}
    for (const styleKey in styles) {
      newStyle[styleKey] = reactNativeShorthandCompatibility(styles[styleKey]);
    }

    return newStyle;
  }

  static flatten (styles: CSSProperties[]): CSSProperties {
    return Object.assign({}, ...styles)
  }

  static get hairlineWidth() {
    return 1;
  }

  static get absoluteFill(): CSSProperties {
    return {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    }
  }

  static get absoluteFillObject () {
    return this.absoluteFill;
  }
}

function reactNativeShorthandCompatibility (style: ExtendedCSSProperties): CSSProperties {
  const copiedStyle = {...style}

  if (copiedStyle.hasOwnProperty('borderColor')) {
    const borderColor = removeAndReturn(copiedStyle, 'borderColor');

    const borderValue = (width: string | number) => `solid ${width} ${borderColor}`;
    replace(copiedStyle, 'borderWidth', 'border', borderValue);
    replace(copiedStyle, 'borderTopWidth', 'borderTop', borderValue);
    replace(copiedStyle, 'borderBottomWidth', 'borderBottom', borderValue);
    replace(copiedStyle, 'borderLeftWidth', 'borderLeft', borderValue);
    replace(copiedStyle, 'borderRightWidth', 'borderRight', borderValue);
  }

  const paddingValue = (padding: string | number) => padding;
  replace(copiedStyle, 'paddingVertical', ['paddingTop', 'paddingBottom'], paddingValue);
  replace(copiedStyle, 'paddingHorizontal', ['paddingLeft', 'paddingRight'], paddingValue);

  const marginValue = (margin: string | number) => margin;
  replace(copiedStyle, 'marginVertical', ['marginTop', 'marginBottom'], marginValue);
  replace(copiedStyle, 'marginHorizontal', ['marginLeft', 'marginRight'], marginValue);

  if (copiedStyle.hasOwnProperty('fontWeight')) {
    if (isNumberString(copiedStyle.fontWeight)) {
      copiedStyle.fontWeight = Number(copiedStyle.fontWeight);
    }
  }

  return copiedStyle;
}

function removeAndReturn(object: pr.ObjectWithStringKeys, propertyName: string) {
  const value = object[propertyName];
  delete object[propertyName];

  return value;
}

function replace(
  object: pr.ObjectWithStringKeys,
  oldPropertyName: string,
  newPropertyNames: string | string[],
  cb: (val: string | number) => string | number
) {
  if (!object.hasOwnProperty(oldPropertyName)) return;

  const oldPropertyValue = removeAndReturn(object, oldPropertyName);
  const newPropertyValue = cb(oldPropertyValue);

  if (Array.isArray(newPropertyNames)) {
    for (const newPropertyName of newPropertyNames) {
      object[newPropertyName] = newPropertyValue;
    }
  } else {
    object[newPropertyNames] = newPropertyValue;
  }
}

function isNumberString (str?: any): boolean {
  return !!str &&  isString(str) && !isNaN(Number(str));
}