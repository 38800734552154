import { Dispatch } from "@reduxjs/toolkit";
import {
  updateSalesLists,
  updateSalesListsFailed,
  resetSalesLists,
  SalesListItem,
} from "@src/store/reducers/salesLists";
import api from "@api";

/**
 * Requests sales lists
 * @param dispatch Dispatch
 */
export async function fetchSalesLists(dispatch: Dispatch) {
  try {
    const response = await api.request<SalesListItem[]>(`sales-lists`);

    const salesLists = response.data ?? [];

    dispatch(updateSalesLists({ salesLists }));
  } catch (err) {
    console.error(err);

    dispatch(updateSalesListsFailed());
  }
}

export async function deleteSalesListItem(dispatch: Dispatch, itemId: string | number) {
  try {
    await api.deleteRequest(`sales-lists/${itemId}`);

    await fetchSalesLists(dispatch);
  } catch (err) {
    console.error(err);

    throw err;
  }
}

/**
 * Clears sales data state
 */
export function clearSalesLists(dispatch: Dispatch) {
  dispatch(resetSalesLists());
}
