import React from 'react';
import pr from '@packageroute/types-firebase';
import IspEntry from '../Search/IspEntry';
import Section from '../Page/Section';
import SearchInput from '../Search/SearchInput';
import SearchResults from '../Search/SearchResults';
import api from '../../utils/api';
import { ConsoleISPWithoutMetadata } from './Isp';
import { ProductIspProfileService, ActiveIspSearchResult, consoleApiInitializer } from '@services';
import './AssociateISP.css';

type Props = {
  profile: pr.isp.profile.Profile | null;
  isp: ConsoleISPWithoutMetadata;
}

type State = {
  selectedISP: null | ActiveIspSearchResult;
  searchTimeout: null | NodeJS.Timeout;
  searchText: string;
  results: ActiveIspSearchResult[];
  searching: boolean;
}

export default class AssociateISP extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props);

    this.state = {
      selectedISP: null,
      searchTimeout: null,
      results: [],
      searching: false,
      searchText: '',
    }

    this.onChange = this.onChange.bind(this);
    this.search = this.search.bind(this);
    this.associateISP = this.associateISP.bind(this);
    this.disassociateISP = this.disassociateISP.bind(this);
    this.selectISP = this.selectISP.bind(this);
  }

  selectISP (isp: ActiveIspSearchResult) {
    this.setState({ selectedISP: isp });
  }

  onChange ({ target }: React.ChangeEvent<HTMLInputElement>) {
    const { searchTimeout } = this.state;

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const timeoutID = setTimeout(this.search, 1000);

    this.setState({
      searchTimeout: timeoutID,
      results: [],
      selectedISP: null,
      searchText: target.value,
      searching: true
    });
  }

  async search () {
    try {
      const { searchText } = this.state;

      if (!searchText) return;

      const ispProfileService = new ProductIspProfileService(consoleApiInitializer.initialize());

      const { activeISPs } = await ispProfileService.searchActiveIsps(searchText);

      this.setState({ results: activeISPs, searching: false });
    }
    catch(err) {
      this.setState({ results: [], searching: false });
    }
  }

  associateISP () {
    const { isp } = this.props;
    const { selectedISP } = this.state;

    if (selectedISP) {
      api.updateISP(isp.id, { firebaseId: selectedISP.pushKey })
        .then((data) => {
          window.location.reload();
        });
    }
  }

  disassociateISP () {
    const { isp } = this.props;

    api.updateISP(isp.id, { firebaseId: null })
      .then(data => {
        window.location.reload();
      });
  }

  render () {
    const { searchText, selectedISP } = this.state;
    const { isp } = this.props;

    return (
      <Section title='Associate ISP'>
        <div className='AssociateISP'>
          <div className='status'>
            <div>
              <h4>CRM</h4>
              <p>{isp.entityName}</p>
            </div>

            <div>
              <h4>Firebase</h4>

              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <p>
                  {
                    this.props.profile
                      ? this.props.profile.CSPName
                      : selectedISP
                        ? selectedISP.profile.CSPName
                        : 'No Association'
                  }
                </p>

                {
                  isp.firebaseId
                    ? <p>{isp.firebaseId}</p>
                    : null
                }
              </div>
            </div>

            <div>
              <button
                onClick={
                  isp.firebaseId
                    ? this.disassociateISP
                    : this.associateISP
                }
                style={{
                  backgroundColor:
                    isp.firebaseId
                      ? 'red'
                      : selectedISP
                        ? 'green'
                        : 'gray',
                  opacity: !isp.firebaseId && !selectedISP ? 0.5 : 1
                }}>
                <p>
                  {
                    isp.firebaseId
                      ? 'Disassociate'
                      : 'Associate'
                  }
                </p>
              </button>
            </div>
          </div>

          {
            !isp.firebaseId &&
            <div className='search'>
              <SearchInput
                value={searchText}
                onChange={this.onChange}
                placeholder={'ISP Name, station ID, or ISP Firebase ID'}
              />

              {
                searchText
                  ? <SearchResults searching={this.state.searching}>
                    {
                      searchText
                        ? (() => {                  
                          const activeISPs = this.state.results;
                          const searchResults = [];

                          for (const isp of activeISPs) {
                            const { pushKey, profile } = isp;

                            searchResults.push(
                              <IspEntry
                                key={`isp ${pushKey}`}
                                isp={{}}
                                onClick={() => this.selectISP(isp)}
                                profile={profile}
                              />
                            );
                          }

                          return searchResults.length ? searchResults : null;
                        })()
                        : [
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px' }}>
                            <p>Search to find active ISPs in our Firebase database.</p>
                          </div>
                        ]
                      }
                  </SearchResults>
                  : null
              }
            </div>
          }
        </div>
      </Section>
    );
  }
}
