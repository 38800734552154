import { useAppSelector } from '../store';
import { 
  selectSignups,
  selectTotalSignups,
  selectSignupsLoaded,
  selectSignupsDailyTotals
 } from '@selectors/signups';

export function useSignups () {
  return useAppSelector(selectSignups);
}

export function useTotalSignups () {
  return useAppSelector(selectTotalSignups);
}

export function useSignupsLoaded () {
  return useAppSelector(selectSignupsLoaded)
}

export function useSignupsDailyTotals () {
  return useAppSelector(selectSignupsDailyTotals);
}