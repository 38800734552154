import { CSSProperties } from 'react';

export type Styles = (CSSProperties | null | undefined)[] | CSSProperties;

export function mergeStyles (styles: Styles | null | undefined): CSSProperties {
  if (Array.isArray(styles)) {
    return styles.reduce<CSSProperties>((acc, style) => {
      if (style) {
        return { ...acc, ...style };
      } else {
        return acc;
      }
    }, {})
  }

  return styles || {};
}