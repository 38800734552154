import React from 'react';

import './ExportAsCollection.css';
import firebaseProvider from '../../../utils/firebase';
import Icon from '../../Icon/Icon';

export default class ExportAsCollection extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      creating: false,
      finished: false
    }

    this.exportAsCollection = this.exportAsCollection.bind(this);
  }

  async exportAsCollection () {
    try {
      this.setState({ creating: true });
      const database = firebaseProvider.getConsoleDefaultDB();
      const { users, isps, stations } = this.props;
      const CollectionsRef = database.ref('collections');
      const pushKey = CollectionsRef.push().key;

      await CollectionsRef.child(pushKey).set({
        collectionId: pushKey,
        name: this.props.name,
        users: users || null,
        isps: isps || null,
        stations: stations || null,
        shared: !!this.props.shared,
        createdByUID: this.props.user.uid,
        createdAt: Date.now()
      });

      this.setState({ creating: false, finished: true });
    }
    catch (err) {
      console.log(err);

      this.setState({ creating: false });
    }
  }

  render () {
    return (
      <div className='ExportAsCollection' onClick={!this.state.finished && this.exportAsCollection}>
        {
          this.state.finished
            ? <Icon name='check' size={15} color='black' />
            : null
        }

        {this.props.children}
      </div>
    );
  }
}
