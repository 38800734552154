import React from 'react';

import './CreateEmail.css';
import MessagingSteps from '../MessagingSteps';
import SelectTemplate from './SelectTemplate/SelectTemplate';
import PreviewEmail from './PreviewEmail';
import TemplateData from './TemplateData/TemplateData';
import Recipients from '../Recipients/Recipients';
import Send from '../Send/Send';
import api from '../../../utils/api';
import firebaseProvider from '../../../utils/firebase';
// import DraftSetting from '../Drafts/DraftSetting';
import shared from '../shared';

export default class CreateEmail extends React.Component {
  constructor (props) {
    super(props);

    const steps = [
      { id: 'TEMPLATE', order: 1, name: 'Select Template', icon: 'image-filter-none' },
      { id: 'TEMPLATE_INFO', order: 2, name: 'Customize', icon: 'information-outline' },
      { id: 'RECIPIENTS', order: 3, name: 'Recipients', icon: 'account-box' },
      { id: 'SEND', order: 4, name: 'Send', icon: 'send' }
    ];

    const draft = props.draft;

    this.state = {
      recipientsStepIdx: 2,
      templateDefinitions: {},
      availableTemplates: [],
      includedTemplates: [],
      steps: steps,
      activeTemplate: (draft && draft.activeTemplate) || null,
      activeStep: (draft && draft.activeStep) || steps[0],
      completedSteps: (draft && draft.completedSteps) || [],
      templateData: (draft && draft.templateDate) || {},
      selectedCollections: (draft && draft.selectedCollections) || [],
      addRecipients: (draft && draft.addRecipients) || [],
      draft: (draft && draft.draftId) || null,
      syncedAt: (draft && draft.updatedAt) || null,
      finished: false
    };

    this.fetchTemplates = this.fetchTemplates.bind(this);
    this.setIncludedTemplatesListener = this.setIncludedTemplatesListener.bind(this);
    this.setActiveStep = shared.setActiveStep.bind(this);
    this.selectTemplate = this.selectTemplate.bind(this);
    this.markStepComplete = shared.markStepComplete.bind(this);
    this.unmarkStepComplete = shared.unmarkStepComplete.bind(this);
    this.updateTemplateData = this.updateTemplateData.bind(this);
    this.toggleRecipient = shared.toggleRecipient.bind(this);
    this.toggleCollection = shared.toggleCollection.bind(this);
    this.validateRecipients = shared.validateRecipients.bind(this);
    this.markFinished = shared.markFinished.bind(this);
    this.createDraft = this.createDraft.bind(this);
    this.syncDraft = this.syncDraft.bind(this);
    this.combineRecipients = shared.combineRecipients.bind(this);
    this.reset = this.reset.bind(this);
  }

  reset () {
    this.setState({
      activeTemplate: null,
      activeStep: this.state.steps[0],
      completedSteps: [],
      templateData: {},
      selectedCollections: [],
      addRecipients: [],
      finished: false
    });
  }

  async createDraft () {
    const { user } = this.props;
    const consoleDB = firebaseProvider.getConsoleDefaultDB();
    const EmailDraftRef = consoleDB.ref('drafts/email');
    const key = await EmailDraftRef.push({ createdByUID: user.uid }).key;

    this.setState({ draft: key }, () => {
      this.syncDraft();
    });
  }

  syncDraft () {
    const { activeStep, completedSteps, templateData, selectedCollections, addRecipients, activeTemplate, draft } = this.state;

    if (!draft) return;

    const consoleDB = firebaseProvider.getConsoleDefaultDB();
    const DraftRef = consoleDB.ref(`drafts/email/${draft}`);
    const updatedAt = Date.now();

    DraftRef.update({
      activeStep: activeStep || null,
      completedSteps: completedSteps || null,
      templateData: templateData || null,
      selectedCollections: selectedCollections || null,
      addRecipients: addRecipients || null,
      activeTemplate: activeTemplate || null,
      updatedAt: updatedAt
    });

    this.setState({ syncedAt: updatedAt });
  }

  async componentDidMount () {
    await this.setIncludedTemplatesListener();
    await this.fetchTemplates();
  }

  componentWillUnmount () {
    const database = firebaseProvider.getConsoleDefaultDB();
    const ref = database.ref('emailTemplates');

    ref.off();
  }

  selectTemplate (template) {
    this.setState({
      activeTemplate: template,
      completedSteps: [this.state.steps[0].order],
      templateData: {}
    }, () => {
      const { templateDefinitions, activeTemplate } = this.state;

      const definition = templateDefinitions[activeTemplate.id];

      if (!definition.fields || !definition.fields.length) {
        this.markStepComplete(this.state.steps[1]);
      }
    });
  }

  async setIncludedTemplatesListener () {
    const database = firebaseProvider.getConsoleDefaultDB();
    const ref = database.ref('emailTemplates');
    const templatesSnap = await ref.once('value');
    const templates = templatesSnap.val();

    this.setState({ includedTemplates: templates }, () => {
      ref.on('value', snap => {
        this.setState({ includedTemplates: Object.values(snap.val() || {}) });
      });
    });
  }

  async fetchTemplates () {
    const res = await api.request(`emails/templates`);

    const allTemplates = (res.data || {}).templates;
    const promises = [];

    for (const template of allTemplates) {
      promises.push(new Promise(async (resolve, reject) => {
        const res = await api.request(`emails/template/${template.id}`);

        resolve(res.data);
      }));
    }

    const templates = await Promise.all(promises);

    const templateDefinitions = {};
    const regexp = new RegExp(/{{?([a-z]+ )?[a-z]+.[a-z]*}?}/gi);

    for (const template of templates) {
      const html = template.versions[0].html_content;
      const matches = (html.match(regexp) || []).filter(match => {
        return !match.includes('{{else}}') && !match.includes('{{elseif}}');
      });

      templateDefinitions[template.id] = {
        id: template.id,
        fields: matches.reduce((acc, match) => {
          const field = match.replace('{{', '').replace('}}', '');

          acc.push(field);

          return acc;
        }, [])
      }
    }

    this.setState({
      availableTemplates: templates,
      templateDefinitions
    });
  }

  updateTemplateData (prop, value) {
    const { activeTemplate, templateDefinitions } = this.state;
    const templateDefinition = templateDefinitions[activeTemplate.id];
    const fields = templateDefinition.fields;

    this.setState(prevState => {
      const nextTemplateData = {
        ...prevState.templateData,
        [prop]: value
      }

      return {
        templateData: nextTemplateData
      };
    }, () => {
      const { templateData } = this.state;

      let allComplete = true;

      for (const field of fields) {
        if (!templateData[field]) {
          allComplete = false;
        }
      }

      const step = this.state.steps[1];

      if (allComplete === true) {
        this.markStepComplete(step);
      }
      else {
        this.unmarkStepComplete(step);
      }
    });
  }

  render () {
    const { steps, activeStep, activeTemplate, includedTemplates, availableTemplates } = this.state;

    const templates = availableTemplates.filter(template => includedTemplates.includes(template.id));

    return (
      <div className='CreateEmail'>
        <MessagingSteps
          activeStep={this.state.activeStep}
          setActiveStep={this.setActiveStep}
          steps={this.state.steps}
          completedSteps={this.state.completedSteps}
          finished={this.state.finished}
          reset={this.reset}
        />

        <div className='body'>
          {
            activeStep.id === steps[0].id
              ? <React.Fragment>
                <PreviewEmail
                  templateData={this.state.templateData}
                  template={activeTemplate}
                />

                <SelectTemplate
                  activeTemplate={this.state.activeTemplate}
                  selectTemplate={this.selectTemplate}
                  templates={templates}
                />
              </React.Fragment>
              : null
          }

          {
            activeStep.id === steps[1].id
              ? <React.Fragment>
                <PreviewEmail
                  templateData={this.state.templateData}
                  template={activeTemplate}
                />

                <TemplateData
                  templateData={this.state.templateData}
                  template={activeTemplate}
                  templateDefinition={activeTemplate && this.state.templateDefinitions[activeTemplate.id]}
                  updateTemplateData={this.updateTemplateData}
                />
              </React.Fragment>
              : null
          }

          {
            activeStep.id === steps[2].id
              ? <Recipients
                toggleCollection={this.toggleCollection}
                toggleRecipient={this.toggleRecipient}
                collections={this.props.collections}
                selectedCollections={this.state.selectedCollections}
                addRecipients={this.state.addRecipients}
                profiles={this.props.profiles}
                users={this.props.users}
                fetchUsers={this.fetchUsers}
              />
              : null
          }

          {
            activeStep.id === steps[3].id
              ? <React.Fragment>
                <PreviewEmail
                  templateData={this.state.templateData}
                  template={activeTemplate}
                />

                <Send
                  user={this.props.user}
                  historyPath='emailHistory'
                  messageType='EMAIL'
                  recipients={this.combineRecipients()}
                  messageName={this.state.activeTemplate.versions[0].name}
                  messageSpecs={{
                    templateId: this.state.activeTemplate.id,
                    templateData: this.state.templateData
                  }}
                  markFinished={this.markFinished}
                />
              </React.Fragment>
              : null
          }
        </div>
      </div>
    );
  }
}
