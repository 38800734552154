import ConsoleApiService from './ConsoleApiService';
import {IConsoleApiInitializer} from "./ConsoleApiInitializer";
import { OperationTransferDTO } from "../models";

export type TransferOwnershipResponse = {
  transfer: OperationTransferDTO;
};

export type OwnershipTransferHistoryResponse = {
  transfers: Record<string, OperationTransferDTO[]>;
};

export type OperationOwnershipTransferHistoryResponse = {
  transfers: OperationTransferDTO[];
};

type TransferOwnershipRequestBody = {
  newOwnerUid: string, 
  consoleUserUid: string, 
  isPreview: boolean
}

export class ProductTransferOwnershipService {
  private api: ConsoleApiService;

  constructor(consoleApiInitializer: IConsoleApiInitializer) {
    this.api = consoleApiInitializer.initialize();
  }

  async transfer(operationId: string, newOwnerUid: string, consoleUserUid: string, isPreview: boolean): Promise<TransferOwnershipResponse> {
    try {
      const path = this._getApiPath(operationId);

      const body: TransferOwnershipRequestBody = {
        newOwnerUid,
        consoleUserUid,
        isPreview,
      }
  
      console.log(path, body);
      
      const response = await this.api.post<TransferOwnershipResponse>(path, body);

      return {
        transfer: response.transfer
      }
    } catch (err) {
      console.error(err);

      throw err;
    }
  }

  async getTransferHistory () {
    const path = this._getApiPath();

    const response = await this.api.get<OwnershipTransferHistoryResponse>(path);

    return {
      transfers: response.transfers
    }
  }

  async getOperationTransferHistory (operationId: string) {
    const path = this._getApiPath(operationId);

    const response = await this.api.get<OperationOwnershipTransferHistoryResponse>(path);

    return {
      transfers: response.transfers
    }
  }

  private _getApiPath(operationId?: string) {
    return 'ownership-transfers/' + (operationId ? `${operationId}/` : '');
  }
}
