import React from 'react';
import './PageTitle.css';

type Props = {
  children: any;
  secondTitle?: any;
  rightContent?: any;
}

export default function PageTitle ({ children, secondTitle, rightContent }: Props) {
  return (
    <div className='PageTitle'>
      <div>
        <h1>{ children }</h1>

        { !!secondTitle &&
          <h1>{ secondTitle }</h1>
        }
      </div>

      { rightContent }
    </div>
  );
}
