import React from 'react';

import './RecipientsList.css';
// import Icon from '../../Icon/Icon'
import RecipientItem from './RecipientItem';

export default function RecipientsList (props) {
  return (
    <div className={`RecipientsList ${props.border ? 'border' : ''}`}>
      <div className='wrapper'>
        {
          props.list
            ? props.list.map((item, i) => {
              let selected = false;

              for (const recipient of props.activeList) {
                if (recipient.id === item.id) {
                  selected = true;
                }
              }

              return (
                <RecipientItem
                  key={`recipientItem-${i}`}
                  onClick={() => props.onItemClick(item)}
                  selected={selected}
                  item={item}
                />
              );
            })
            : null
        }
      </div>
    </div>
  );
}
