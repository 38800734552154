import React from 'react';

import './MsgNav.css';
import Icon from '../Icon/Icon';

export default function MsgNav (props) {
  const { opts, mode } = props;

  return (
    <div className='MsgNav'>
      {
        opts.map((opt, i) => {
          return (
            <div key={`MsgNav-${i}`} className={`opt ${mode === opt.mode ? 'active' : ''}`}onClick={() => props.onClickOpt(opt.mode)}>
              <Icon name={opt.icon} size={20} color={'black'} />
            </div>
          );
        })
      }
    </div>
  );
}
