import React from 'react';
import * as turf from '@turf/turf';

import './StationsMap.css';
import Map from '../Mapbox/Map';
import Geometries from '../Mapbox/Geometries';

export default class StationsMap extends React.PureComponent {
  constructor (props) {
    super(props);

    this.state = {
      zoom: 4
    };

    this.sourceId = 'stations_map';
    this.stationMarkersId = 'station_markers';
    this.setMapRef = this.setMapRef.bind(this);
    this.defaultSource = {
      id: this.sourceId,
      data: {
        type: 'geojson',
        data: this.buildGeojson()
      }
    };

    this.buildGeojson = this.buildGeojson.bind(this);
    this.resetGeojsonSource = this.resetGeojsonSource.bind(this);
  }

  componentDidUpdate (prevProps) {
    if (this.map && (!prevProps.stations || !prevProps.stations.length) && (this.props.stations && this.props.stations.length)) {
      this.resetGeojsonSource();
    }

    if (this.map && !Object.keys(prevProps.activeStations || {}).length && Object.keys(this.props.activeStations || {}).length) {
      this.resetGeojsonSource();
    }

    if (this.map && prevProps.focusedStation !== this.props.focusedStation) {
      this.resetGeojsonSource();
    }
  }

  resetGeojsonSource () {
    try {
      const nextGeojson = this.buildGeojson();
      const source = this.map.getSource(this.sourceId);
  
      source.setData(nextGeojson);
    } catch (error) {
      
    }
  }

  setMapRef (map) {
    this.map = map;

    if (this.props.setMapRef) {
      this.props.setMapRef(map);
    }
  }

  buildGeojson () {
    const { activeStations, stations, focusedStation } = this.props;

    return {
      type: 'FeatureCollection',
      features: stations
        .filter(station => {
          return !Number.isNaN(parseFloat(station.longitude)) && !Number.isNaN(parseFloat(station.latitude));
        })
        .map(station => {
          const focused = focusedStation && focusedStation.id === station.id;
          const active = (activeStations || {}).hasOwnProperty(station.terminal);
          const position = [parseFloat(station.longitude), parseFloat(station.latitude)];
          const properties = {
            station: station,
            stationNum: station.terminal,
            color: focused ? '#76E593' : active ? '#828BFF' : '#f5f5f5',
            textColor: focused ? 'white' : active ? 'white' : '#999999',
            strokeColor: focused ? 'white' : active ? 'white' : '#dddddd',
            strokeWidth: focused ? 2 : active ? 2 : 1,
          };

          return turf.point(position, properties);
        })
    };
  }

  render () {
    const { stations, liveStations, activeStations } = this.props;

    return (
      <div className='StationsMap'>
        <Map
          zoom={3}
          region={{
            latitudeDelta: 0.0922,
            longitudeDelta: 0.0421,
            latitude: 39.8097343,
            longitude: -98.5556199
          }}
          setMapRef={this.setMapRef}
        />

        {
          this.map &&
          <React.Fragment>
            <Geometries
              map={this.map}
              source={this.defaultSource}
              layers={[
                {
                  id: this.stationMarkersId,
                  type: 'circle',
                  source: this.sourceId,
                  filter: ['==', '$type', 'Point'],
                  layout: {

                  },
                  paint: {
                    'circle-color': ['get', 'color'],
                    'circle-stroke-width': 2,
                    'circle-stroke-color': ['get', 'strokeColor'],
                    'circle-radius': [
                      'interpolate',
                      ['linear'],
                      ['zoom'],
                      1, 4,
                      2, 4,
                      3, 4,
                      4, 4,
                      5, 18,
                      6, 18,
                      7, 18,
                      8, 35,
                      9, 35,
                      10, 35,
                      11, 35,
                      12, 50,
                    ]
                  }
                },
                {
                  id: 'station_label',
                  type: 'symbol',
                  source: this.sourceId,
                  minzoom: 5,
                  layout: {
                    'text-field': `{stationNum}`,
                    'text-size': [
                      'interpolate',
                      ['linear'],
                      ['zoom'],
                      5,
                      12,
                      8,
                      18,
                      12,
                      24
                    ]
                  },
                  paint: {
                    'text-color': ['get', 'textColor']
                  },
                  filter: ['has', 'stationNum']
                }
              ]}
              onClickLayer={e => {
                e.preventDefault();

                const markerFeatures = e.features && e.features.filter(feature => {
                  return feature.layer.id === this.stationMarkersId;
                });
                const markerFeature = markerFeatures[0];

                if (!markerFeature) return;

                const { properties } = markerFeature;

                const station = JSON.parse(properties.station);

                this.props.setFocusedStation(station);
              }}
              onMouseEnterLayer={e => {
                const canvas = this.map.getCanvas();

                canvas.style.cursor = 'pointer';
              }}
              onMouseLeaveLayer={e => {
                const canvas = this.map.getCanvas();

                canvas.style.cursor = '';
              }}
            />
          </React.Fragment>
        }
      </div>
    );
  }
}
