import { Dispatch } from "@reduxjs/toolkit";
import {
  updateStations,
  // removeStation,
  resetStations,
  ConsoleStation,
  Stations
} from "@reducers/stations/consoleStations";
import BugsnagClient from '@utils/bugsnag';
import api from "@api";

export async function fetchConsoleStations(dispatch: Dispatch) {
  try {
    const response = await api.request<ConsoleStation[]>('stations');

    const stationsArr = response.data ?? [];

    const stations = stationsArr.reduce<Stations>((acc, station) => {
      acc[station.terminal] = station;

      return acc;
    }, {})

    dispatch(updateStations({ stations }));

    return stations;
  } catch (err) {
    console.error(err);
    BugsnagClient.notify(err as Error);
  }
}

export async function fetchConsoleStation(dispatch: Dispatch, stationId: string) {
  try {
    const response = await api.request<ConsoleStation[]>(`stations/${stationId}`);

    const stationsArr = response.data ?? [];

    const stations = stationsArr.reduce<Stations>((acc, station) => {
      acc[station.terminal] = station;

      return acc;
    }, {})

    dispatch(updateStations({ stations }));

    return stations;
  } catch (err) {
    console.error(err);
    BugsnagClient.notify(err as Error);
  }
}

export async function fetchConsoleStationByTerminalId(dispatch: Dispatch, stationId: string) {
  try {
    const response = await api.request<ConsoleStation[]>(`stations/firebase/${stationId}`);

    const stationsArr = response.data ?? [];

    const stations = stationsArr.reduce<Stations>((acc, station) => {
      acc[station.terminal] = station;

      return acc;
    }, {})

    dispatch(updateStations({ stations }));

    return stations;
  } catch (err) {
    console.error(err);
    BugsnagClient.notify(err as Error);
  }
}


export function clearConsoleStations(dispatch: Dispatch) {
  dispatch(resetStations());
}
