import React from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';

import './style/shared.css';
import './style/business.css';
import SectionTitle from '../../Page/SectionTitle';
import SectionButton from '../../Page/SectionButton';
import BusinessForm from './BusinessForm';
import SalesCycle from './SalesCycle';

export default function Business (props) {
  const { isp } = props;
  const breakdownData = [
    { name: 'Ground', value: isp.groundRoutes || 0 },
    { name: 'HD', value: isp.hdRoutes || 0 },
    { name: 'Supp', value: isp.suppRoutes || 0 },
    { name: 'Additional Routes', value: isp.additionalRoutes || 0 }
  ];
  const totalRoutes = (isp.groundRoutes || 0) + (isp.hdRoutes || 0) + (isp.suppRoutes || 0) + (isp.additionalRoutes || 0);
  const projectedConversion = (((!Number.isNaN(parseInt(isp.projectedConversionRate, 10)) ? isp.projectedConversionRate : 100) / 100) * totalRoutes || 0) / totalRoutes || 0;
  const totalData = [
    { name: 'Projected Conversion', value: projectedConversion * 100 },
    { name: 'Not Converted', value: (1 - projectedConversion) * 100 }
  ];
  const breakdownColors = [
    '#00B02C',
    '#EA7D14',
    '#613498'
  ];
  const totalColors = [
    '#5B68FF',
    '#303566'
  ];

  return (
    <div className={`Business${props.editingBusiness ? ' expand' : ''}`} style={props.editingBusiness ? { height: '900px' } : null}>
      <SectionTitle title='Business' titleContent={
        <SalesCycle isp={isp} />
      }>
        <SectionButton
          style={{ backgroundColor: props.editingBusiness ? '#B90C0F' : 'white' }}
          onClick={() => props.toggleProp('editingBusiness')}>
          {
            props.editingBusiness
              ? <p style={{ color: 'white' }}>Abort</p>
              : <p>Edit</p>
          }
        </SectionButton>
      </SectionTitle>

      {
        !props.editingBusiness
          ? <div className='section' style={{ flexDirection: 'column' }}>
            <div className='section'>
              {
                isp.groundRoutes || isp.hdRoutes || isp.suppRoutes || totalRoutes || isp.estimatedOpportunity
                  ? <div className='section' style={{ flex: 3 }}>
                    {
                      isp.groundRoutes || isp.hdRoutes || isp.suppRoutes
                        ? <div className='data'>
                          <h4>Number of Routes</h4>

                          <PieChart width={250} height={250}>
                            <Pie
                              data={breakdownData}
                              innerRadius={40}
                              outerRadius={80}
                              label>
                              {
                                breakdownData.map((entry, index) => {
                                  return <Cell fill={breakdownColors[index]} />
                                })
                              }
                            </Pie>
                            <Tooltip />
                          </PieChart>
                        </div>
                        : null
                    }

                    {
                      totalRoutes > 0 || isp.projectedConversionRate
                        ? <div className='data'>
                          <h4>Total Opportunity</h4>

                          <PieChart width={250} height={250}>
                            <Pie
                              data={totalData}
                              innerRadius={40}
                              outerRadius={80}
                              label>
                              {
                                totalData.map((entry, index) => {
                                  return <Cell fill={totalColors[index]} />
                                })
                              }
                            </Pie>
                            <Tooltip />
                          </PieChart>
                        </div>
                        : null
                    }
                  </div>
                  : <div className='section' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <p style={{ textAlign: 'center' }}>Edit the number of routes to see a breakdown.</p>
                  </div>
              }

              <div className='section' style={{ flexDirection: 'column', borderLeft: '1px solid #DDD', flex: 2 }}>
                <div className='data'>
                  <h4>Est.Opportunity</h4>

                  <div className='headerText'>
                    <p>${((totalRoutes || 0) * projectedConversion) * 20}</p>
                  </div>

                </div>

                <div className='data'>
                  <h4 style={{ marginBottom: '10px' }}>Misc.</h4>

                  <div className='misc'>
                    <p>
                      <span># of Entities: </span>
                      {isp.numEntities || 'N/A'}
                    </p>
                  </div>

                  <div className='misc'>
                    <p>
                      <span>Referred By: </span>
                      {isp.referred || 'N/A'}
                      {
                        isp.referredDescription && isp.referred
                          ? `: ${isp.referredDescription}`
                          : ''
                      }
                    </p>
                  </div>

                  <div className='misc'>
                    <p>
                      <span>Qualifications: </span>
                      {
                        isp.qualifiedDescription
                          ? `: ${isp.qualifiedDescription}`
                          : 'N/A'
                      }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : <BusinessForm
            isp={props.isp}
            updateISP={props.updateISP}
            toggleProp={props.toggleProp}
          />
      }
    </div>
  )
}
