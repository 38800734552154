import React from 'react';
import InputText from '../../Page/InputText';
import InputSelect from '../../Page/InputSelect';
import Button from '../../Page/Button';
import './style/form.css';

type Contact = {
  id: number;
  phone: string;
  secondaryPhone: string;
  email: string;
  secondaryEmail: string;
  firstName: string;
  lastName: string;
  role: string;
  preferredMethod: string;
  preferredTime: string;
  appleId: string;
  playId: string;
  owner: boolean;
}

type NextContact = {
  phone: string | null;
  secondaryPhone: string | null;
  email: string | null;
  secondaryEmail: string | null;
  firstName: string | null;
  lastName: string | null;
  role: string | null;
  preferredMethod: string | null;
  preferredTime: string | null;
  appleId: string | null;
  playId: string | null;
  owner: boolean;
}

type Props = {
  isNew?: boolean;
  contact?: Partial<Contact>;
  updateContact?: (contactId: number, contact: Partial<NextContact>, callback: (err?: Error) => void) => void;
  createContact?: (contact: Partial<NextContact>, callback: (err?: Error) => void) => void;
  deleteContact?: (contactId: number, callback: (err?: Error) => void) => void;
  toggleProp: (prop: string) => void;
  endNewContact: () => void;
}

type State = {
  nextContact: NextContact;
  errorMessage: null | string;
  attemptDelete: boolean;
}

export default class ContactForm extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props);

    const contact = !props.isNew && props.contact ? props.contact : {};

    console.log(contact);

    this.state = {
      nextContact: {
        phone: contact.phone || null,
        secondaryPhone: contact.secondaryPhone || null,
        email: contact.email || null,
        secondaryEmail: contact.secondaryEmail || null,
        firstName: contact.firstName || null,
        lastName: contact.lastName || null,
        role: contact.role || null,
        preferredMethod: contact.preferredMethod || null,
        preferredTime: contact.preferredTime || null,
        appleId: contact.appleId || null,
        playId: contact.playId || null,
        owner: contact.owner || false
      },
      errorMessage: null,
      attemptDelete: false
    }

    this.updateField = this.updateField.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.deleteContact = this.deleteContact.bind(this);
  }

  submitForm (e: React.SyntheticEvent<HTMLButtonElement | HTMLFormElement>) {
    e.preventDefault();

    const { contact, updateContact, createContact, isNew, toggleProp, endNewContact } = this.props;
    const {} = this.state;

    const nextContact: NextContact = {
      ...this.state.nextContact
    }

    if (this.props.contact) {
      for (const key in nextContact) {
        const contactKey = key as keyof NextContact;
        if (this.props.contact[contactKey] === nextContact[contactKey]) {
          delete nextContact[contactKey];
        }
      }
    }

    if (!Object.keys(nextContact).length) {
      return;
    }

    if (contact && !isNew) {
      if (!updateContact) {
        throw new Error('updateContact callback required to update contact');
      }

      updateContact(contact.id!, nextContact, (err) => {
        if (err) {
          this.setState({ errorMessage: err.message || 'Something went wrong.' })
        }
        else {
          toggleProp('editingContact');
        }
      });
    }
    else if (isNew === true) {
      if (!createContact) {
        throw new Error('createContact callback required to create contact');
      }

      createContact(nextContact, (err) => {
        if (err) {
          this.setState({ errorMessage: err.message || 'Something went wrong.' })
        }
        else {
          toggleProp('editingContact');
          endNewContact();
        }
      });
    }
  }

  deleteContact () {
    const { deleteContact, contact } = this.props;
    const { attemptDelete } = this.state;

    if (!attemptDelete) {
      return this.setState({ attemptDelete: true })
    }

    if (!deleteContact) {
      throw new Error('callback deleteContact must exist to delete a contact');
    }

    if (!contact || !contact.id) {
      throw new Error('Cannot delete contact that does not exist');
    }
    
    deleteContact(contact.id, (err) => {
      if (err) {
        this.setState({ errorMessage: err.message || 'Something went wrong.', attemptDelete: false })
      }
      else {
        this.props.toggleProp('editingContact');
      }
    });
  }

  updateField (name: keyof NextContact, value: string | number | boolean) {
    this.setState(prevState => {
      const nextContact = {
        ...prevState.nextContact,
        [name]: value
      }

      return { nextContact };
    });
  }

  render () {
    const { contact, isNew } = this.props;
    const { nextContact, } = this.state;

    return (
      <div className='Form'>
        <form onSubmit={this.submitForm}>
          <div className='inputs'>
            <div className='section column'>
              <div className='input-container'>
                <InputText
                  id='firstName'
                  value={nextContact.firstName ?? ''}
                  label={'First Name'}
                  onChange={(e) => this.updateField('firstName', e.target.value)}
                />
              </div>

              <div className='input-container'>
                <InputText
                  id='lastName'
                  value={nextContact.lastName ?? ''}
                  label={'Last Name'}
                  onChange={(e) => this.updateField('lastName', e.target.value)}
                />
              </div>

              <div className='input-container'>
                <InputText
                  type='tel'
                  id='cellphone'
                  pattern='[0-9]{3}-[0-9]{3}-[0-9]{4}'
                  value={nextContact.phone ?? ''}
                  label={'Phone'}
                  onChange={(e) => this.updateField('phone', e.target.value)}
                />
              </div>

              <div className='input-container'>
                <InputText
                  type='tel'
                  id='secondaryPhone'
                  pattern='[0-9]{3}-[0-9]{3}-[0-9]{4}'
                  value={nextContact.secondaryPhone ?? ''}
                  label={'Secondary Phone'}
                  onChange={(e) => this.updateField('secondaryPhone', e.target.value)}
                />
              </div>

              <div className='input-container'>
                <InputText
                  type='email'
                  id='email'
                  value={nextContact.email ?? ''}
                  label={'Email'}
                  onChange={(e) => this.updateField('email', e.target.value)}
                />
              </div>

              <div className='input-container'>
                <InputText
                  type='email'
                  id='secondaryEmail'
                  value={nextContact.secondaryEmail ?? ''}
                  label={'Secondary Email'}
                  onChange={(e: React.SyntheticEvent<HTMLInputElement>) => {
                    // @ts-ignore
                    this.updateField('secondaryEmail', e.target.value)
                  }}
                />
              </div>
            </div>

            <div className='section column'>
              <div className='input-container'>
                <InputSelect
                  id='Role'
                  label='Role'
                  value={nextContact.role}
                  onChange={(e: React.SyntheticEvent<HTMLSelectElement>) => {
                    // @ts-ignore
                    const val: number = parseInt(e.target.value, 10);

                    this.updateField('role', val);

                    if (val === 1) {
                      this.updateField('owner', true);
                    }
                    else if (nextContact.owner === true) {
                      this.updateField('owner', false);
                    }
                  }}
                  options={[
                    { value: 0, name: 'None'},
                    { value: 1, name: 'AO' },
                    { value: 2, name: 'BC' },
                    { value: 3, name: 'Driver' }
                  ]}
                />
              </div>

              <div className='input-container'>
                <InputSelect
                  id='preferredMethod'
                  label='Preferred Contact Method'
                  value={nextContact.preferredMethod}
                  onChange={(e: React.SyntheticEvent<HTMLSelectElement>) => {
                    // @ts-ignore
                    this.updateField('preferredMethod', parseInt(e.target.value, 10))
                  }}
                  options={[
                    { value: 0, name: 'None'},
                    { value: 1, name: 'Text message' },
                    { value: 2, name: 'Phone call' },
                    { value: 3, name: 'Email' }
                  ]}
                />
              </div>

              <div className='input-container'>
                <InputSelect
                  id='preferredTime'
                  label='Preferred Contact Time'
                  value={nextContact.preferredTime}
                  onChange={(e: React.SyntheticEvent<HTMLSelectElement>) => {
                    // @ts-ignore
                    this.updateField('preferredTime', parseInt(e.target.value, 10))
                  }}
                  options={[
                    { value: 0, name: 'None'},
                    { value: 1, name: 'Morning' },
                    { value: 2, name: 'Late morning' },
                    { value: 3, name: 'Afternoon' },
                    { value: 4, name: 'Evening' }
                  ]}
                />
              </div>

              <div className='input-container'>
                <InputText
                  id='appleID'
                  value={nextContact.appleId ?? ''}
                  label={'AppleID'}
                  onChange={(e) => {
                    this.updateField('appleId', e.target.value)
                  }}
                />
              </div>

              <div className='input-container'>
                <InputText
                  id='playID'
                  value={nextContact.playId ?? ''}
                  label={'Google Play ID'}
                  onChange={(e) => {
                    this.updateField('playId', e.target.value)
                  }}
                />
              </div>
            </div>
          </div>

          <div className='submit column'>
            {
              this.state.errorMessage
                ? <p style={{ color: 'red' }}>{this.state.errorMessage}</p>
                : null
            }

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              {
                this.props.contact && !this.props.isNew
                  ? <Button
                    type='button'
                    style={{ backgroundColor: 'red', margin: 0, marginRight: '10px' }}
                    onClick={this.deleteContact}
                  >
                    {
                      this.state.attemptDelete === true
                        ? 'Confirm'
                        : 'Delete'
                    }
                  </Button>
                  : null
              }

              <Button style={{ margin: 0 }} onClick={this.submitForm} disabled={
                (() => {
                  if (contact && !isNew) {
                    for (const key in nextContact) {
                      const contactKey = key as keyof NextContact;
                      if (contact[contactKey] !== (nextContact[contactKey] || null)) {
                        return false
                      }
                    }
                  }
                  else {
                    for (const key in nextContact) {
                      const contactKey = key as keyof NextContact;
                      
                      if (nextContact[contactKey]) {
                        return false;
                      }
                    }
                  }

                  return true;
                })()
              }>
                Submit
              </Button>

            </div>
          </div>
        </form>
      </div>
    )
  }
}
