import React from "react";
import Bugsnag, { Client } from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { __DEV__ } from "../globalConstants";

const version = require("../version.json");
const config = require("../config.json");

if (!version || typeof version !== "string") {
  throw new Error("Missing valid app version");
}

if (!config) {
  throw new Error("Missing configuration file");
}

if (!config.bugSnagApiKey || typeof version !== "string") {
  throw new Error("Configuration file missing valid bugsnag api key");
}

const bugsnagClient = Bugsnag.start({
  apiKey: config.bugSnagApiKey,
  appVersion: version,
  appType: "client",
  releaseStage: __DEV__ ? "development" : config.releaseStage,
  enabledReleaseStages: ["production", "staging"],
  metadata: {
    company: {
      name: "PackageRoute",
      country: "us",
    },
  },
  logger: {
    error: console.error,
    warn: console.warn,
    debug: console.log,
    info: console.log,
  },
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React);

export type BugsnagClient = Client;
export { ErrorBoundary };
export default bugsnagClient;
