import React from 'react';
import './GhostAccounts.css';

import firebaseProvider from '../../utils/firebase';

import PageWrapper from '../Page/PageWrapper';
import PageTitle from '../Page/PageTitle';
import Section from '../Page/Section';
import withPermissions from '../Permissions/withPermissions';

import api from '../../utils/api'

class GhostAccounts extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      accounts: []
    };

    this.requestAccountData = this.requestAccountData.bind(this);
    this.unlinkOperation = this.unlinkOperation.bind(this);
  }

  componentDidMount () {
    firebaseProvider.getConsoleDefaultDB().ref('/ghostAccounts').on('child_added', snap => {
      const id = snap.key;

      this.requestAccountData(id)
        .then(account => {
          this.setState({ accounts: [account, ...this.state.accounts] })
        })

    })
  }

  requestAccountData(FedExId) {
    return api.getGhostAccount(FedExId)
      .catch(err => {
        console.log(err);
        return {};
      })
  }

  unlinkOperation (operationData, user) {
    if (window.confirm('Are you sure you want to unlink from this operation?')) {
      const { uid, ISP, ISPS, FedExId } = user;
      const ghost = { uid, ISP, ISPS, FedExId };

      return api.toggleGhostAccountLink(ghost, operationData, false)
        .then(() => window.location.reload())
        .catch(err => console.log(err));
    }
  }

  render () {
    const { accounts } = this.state;
    return (
      <div className='GhostAccounts'>
        <PageWrapper>
          <PageTitle>Manage Ghost Accounts</PageTitle>
          { accounts.map(user =>
            <Section key={user.FedExId}>
              <Account user={user} unlinkOperation={this.unlinkOperation} />
            </Section>
          ) }
        </PageWrapper>
      </div>
    );
  }
}

function Account ({ user, unlinkOperation }) {
  return (
    <div className='Account'>
      <h2><strong>{user.FedExId}</strong> {user.first} {user.last}</h2>

      {(() => {
        const result = [];
        const set = (user.ISPS || {});

        for (const key in set) {
          const operation = set[key];

          result.push(
            <Operation
              key={key}
              id={key}
              operation={operation}
              userId={user.FedExId}
              unlinkOperation={() => unlinkOperation({operationId: key, operationName: operation.name}, user)}
            />
          );
        }

        return result;
      })()}
    </div>
  );
}

function Operation ({ id, operation, unlinkOperation }) {
  return (
    <div className='Operation'>
      <h3>{ operation.name }</h3>

      <div className='closeIconWrap' onClick={unlinkOperation}>
        <i className='mdi mdi-close' />
      </div>
    </div>
  );
}

export default withPermissions(GhostAccounts);
