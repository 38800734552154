import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Stripe from 'stripe';

export type PastDueSubscription = Stripe.subscriptions.ISubscription;
export type PastDueSubscriptions = PastDueSubscription[];

export enum Status {
  INITIAL = 'INITIAL',
  UPDATED = 'UPDATED',
  FAILED = 'FAILED',
}

type PastDueState = {
  status: Status;
  data: PastDueSubscriptions;
}

const initialState: PastDueState = {
  status: Status.INITIAL,
  data: [],
};

const pastDueSlice = createSlice({
  name: 'pastDueSubscriptions',
  initialState: initialState,
  reducers: {
    updatePastDue: (state, action: PayloadAction<{ subscriptions: PastDueSubscriptions }>) => {
      const { subscriptions } = action.payload;

      state.data = subscriptions;
      state.status = Status.UPDATED;
    },
    updatePastDueFailed: (state) => {
      state.status = Status.FAILED;
    },
    resetPastDue: (state) => {
      state.data = [];
      state.status = Status.INITIAL;
    }
  }
});

export const { 
  updatePastDue,
  updatePastDueFailed,
  resetPastDue,
} = pastDueSlice.actions

export default pastDueSlice.reducer;

