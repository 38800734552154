import moment from "moment";
import {
  SubscriptionPlanType,
  mapSubscriptionPlanTypeToEnum,
} from "./subscriptionPlanType";

type ISO8601 = string;

export type SubscriptionPlanContractDto = {
  tenantId: string;
  id: string;
  contractType: string;
  contractPlan: string;
  contractDocumentUri: string;
  signedDate: ISO8601;
  startDate: ISO8601;
  endDate: ISO8601;
  minimumContractedRoutes: number | null;
  minimumSharedContractedRoutes: number | null;
};

export enum ContractStatus {
  EXPIRING = "EXPIRING",
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  CANCELLING_BEFORE_CONTRACT_END = "CANCELLING_BEFORE_CONTRACT_END",
  CANCELLED_BEFORE_CONTRACT_END = "CANCELLED_BEFORE_CONTRACT_END",
}

export class SubscriptionPlanContract {
  constructor(
    public id: string,
    public tenantId: string,
    public contractType: string,
    public contractPlan: SubscriptionPlanType,
    public contractDocumentUri: string,
    public signedDate: moment.Moment,
    public startDate: moment.Moment,
    public endDate: moment.Moment,
    public minimumContractedRoutes: number | null,
    public minimumSharedContractedRoutes: number | null,
  ) {}

  get status(): ContractStatus {
    if (this.contractHasExpired) {
      return ContractStatus.CANCELLED;
    } else if (this.contractIsExpiring) {
      return ContractStatus.EXPIRING;
    } else {
      return ContractStatus.ACTIVE;
    }
  }

  get contractHasExpired() {
    return this.getContractWillExpireWithin(0, 'days');
  }

  get contractIsExpiring() {
    return this.getContractWillExpireWithin(1, "months");
  }

  private getContractWillExpireWithin(
    amount: number,
    unit: "days" | "months"
  ): boolean {
    const timeUntilEnd = this.getTimeUntilEnd(unit);

    return timeUntilEnd <= amount;
  }

  private getTimeUntilEnd(unit: "days" | "months"): number {
    const today = moment();

    return this.endDate.diff(today, unit);
  }
}

export function mapSubscriptionPlanContractDtoToDomain(
  dto: SubscriptionPlanContractDto
): SubscriptionPlanContract {
  return new SubscriptionPlanContract(
    dto.id,
    dto.tenantId,
    dto.contractType,
    mapSubscriptionPlanTypeToEnum(dto.contractPlan),
    dto.contractDocumentUri,
    moment(dto.signedDate),
    moment(dto.startDate),
    moment(dto.endDate),
    dto.minimumContractedRoutes || null,
    dto.minimumSharedContractedRoutes || null,
  );
}
