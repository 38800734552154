// @ts-ignore
import { LightenDarkenColor } from 'lighten-darken-color';

/**
 * Lighten hexadecimal color
 * @param hex hexadecimal color string
 * @param lightenBy factor to lighten by. 0 = none, 1 = white
 * @returns hex color string
 */
export function lightenHexColor(hex: string, lightenBy: number = 0.3) {
  return LightenDarkenColor(hex, lightenBy * 100);
}
