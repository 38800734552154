import React from "react";
import Spinner from "../common/Spinner";

type Props = {
  label?: string;
  style?: React.CSSProperties;
}
export default function PageSpinner({ label, style }: Props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: 'column',
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        padding: "0 0 150px 0",
        width: '100%',
        height: '100%',
        ...style
      }}
    >
      <Spinner size={40} color="#444" />
      {!!label && <p style={{ marginTop: 30 }}>{label}</p>}
    </div>
  );
}
