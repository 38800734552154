import React from "react";
import { useLocation } from "react-router-dom";
import "./NavItem.css";
import { NavLink } from "react-router-dom";
import withPermissions from "@components/Permissions/withPermissions";
import Icon from "../Icon/Icon";

type Props = {
  to: string;
  icon: string;
  title: string;
};

export default function NavItem({ to, icon, title }: Props) {
  const location = useLocation();

  let active = false;

  if (location.pathname === "/" && title === "Dashboard") {
    active = true;
  } else if (title !== "Dashboard") {
    active = location.pathname.includes(to);
  }

  return (
    <NavLink to={to} className="NavLink" exact>
      <div className="NavItem" style={{ opacity: active ? 1 : 0.5 }}>
        <Icon name={icon} color="white" size={25} />
      </div>

      <div className="callout">{title}</div>
    </NavLink>
  );
}

const SHOULD_REDIRECT = true;
export const NavItemWithPermissions = withPermissions(NavItem, !SHOULD_REDIRECT);