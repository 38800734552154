import React from "react";
import Icon from "../Icon/Icon";

import "./Device.css";

import { titleize } from "../../utils/formatting";


const Device = ({ deviceInfo, active }) => {
  const { carrier, make, model, lastAppVersion, ip = 'no ip address' } = deviceInfo.metadata;
  return (
    <div className={`Device ${active ? "active" : ""}`}>
      <Icon
        name={
          (make && make.toLowerCase() === "apple") ? "cellphone" : "cellphone-android"
        }
        color={active ? "#006DAB" : "#000"}
        size="50px"
      />
      <div className="info">
        <p className="detail model">{model}</p>
        <p className="detail make">{titleize(make)}</p>
        <p className="detail carrier">{carrier}</p>
        <p className="detail lastAppVersion">v{lastAppVersion}</p>
        {/* <p className="detail ip">{ip}</p> */}
      </div>
    </div>
  );
};

export default Device;

// deviceInfo {
//   os: "Android"|"iOs"|"Windows"|"Chrome OS"|"macOS"|"Linux"|"Roku"|...?
//   osVersion: "00.00"|"00.00"|"0.0.0"|...?
//   browser: "Chrome"|"Safari"|"Opera"|"Firefox"|...?
//   browserVersion: "00.00"|"00.00"|"0.0.0"|...?
//   platform: "mobile"|"tablet"|"desktop"|...?
// }

const WebAppDevice = ({ deviceInfo, active }) => {;
  const { os, osVersion, browser, browserVersion, platform, lastAppVersion, ip } = deviceInfo.metadata;

  const deviceType = platform === "mobile" 
                      ? "cellphone" 
                      : platform === "tablet" 
                      ? "tablet-ipad"
                      : platform === "desktop"
                      ? "laptop"
                      : "help-circle-outline"
  
  return (
    <div className={`Device ${active ? "active" : ""}`}>
      <Icon
        name={deviceType}
        color={active ? "#179939" : "#000"}
        size="50px"
      />
      <div className="info">
        <p className="detail browser">{titleize(browser)}</p>
        <small className="detail browser version">{browserVersion}</small>
        <p className="detail os">{titleize(os)}</p>
        <small className="detail os version">{osVersion}</small>
        <p className="detail lastAppVersion">v{lastAppVersion}</p>
        { ip && <a className="detail ip" target="_blank" href={`https://whatismyipaddress.com/ip/${ip}`}>{ip}</a> }
      </div>
    </div>
  );
};

export { WebAppDevice };
