import {createSelector} from 'reselect';
import * as dailyActiveUsers from './dailyActiveUsers';
import * as dailyOperationsActivity from './dailyOperationsActivity';
import * as dailyOperationsUsage from './dailyOperationsUsage';
import * as totalOperationsAndStation from './totalOperationsAndStation';
import {roundNumber} from '@utils/numbers';

type DateString = string;
type DashboardDateData = {
  dailyActiveUsers: number;
  weeklyActiveUsers: number;
  totalRoutes: number;
  liveRoutes: number;
  paidRoutes: number;
  totalPaidRoutes: number;
  totalDeliveries: number;
  totalPickups: number;
  totalDeliveryPkgs: number;
  totalPickupPkgs: number;
  totalPaidDeliveries: number;
  totalPaidPickups: number;
  totalPaidDeliveryPkgs: number;
  totalPaidPickupPkgs: number;
  totalBCs: number;
  totalDrivers: number;
  totalISPs: number;
  totalUsers: number;
}
type DashboardDataKey = keyof DashboardDateData;

type DashBoardData = Record<DateString, DashboardDateData>;
const selectDashboardDailyMetrics = createSelector(
  [
    dailyActiveUsers.selectDailyActiveUsers,
    dailyOperationsActivity.selectDailyOperationsActivity,
    dailyOperationsUsage.selectDailyOperationsUsage,
  ],
  (dailyActiveUsers, activity, usage): DashBoardData => {
    const data: DashBoardData = {};

    const sharedDates = getUniqueElementsFromArrays(
      Object.keys(dailyActiveUsers),
      Object.keys(activity),
      Object.keys(usage),
    );

    for (const date of sharedDates) {
      const dateActiveUsers = dailyActiveUsers[date];
      const dateActivity = activity[date];
      const dateUsage = usage[date];

      data[date] = {
        dailyActiveUsers: roundNumber((dateActiveUsers?.daily ?? 0) * 100),
        weeklyActiveUsers: roundNumber((dateActiveUsers?.weekly ?? 0) * 100),
        totalRoutes: dateActivity?.totalRoutes ?? 0,
        liveRoutes: dateActivity?.liveRoutes ?? 0,
        paidRoutes: dateActivity?.paidRoutes ?? 0,
        totalPaidRoutes: dateActivity?.totalPaidRoutes ?? 0,
        totalDeliveries: dateActivity?.totalDeliveries ?? 0,
        totalPickups: dateActivity?.totalPickups ?? 0,
        totalDeliveryPkgs: dateActivity?.totalDeliveryPkgs ?? 0,
        totalPickupPkgs: dateActivity?.totalPickupPkgs ?? 0,
        totalPaidDeliveries: dateActivity?.totalPaidDeliveries ?? 0,
        totalPaidPickups: dateActivity?.totalPaidPickups ?? 0,
        totalPaidDeliveryPkgs: dateActivity?.totalPaidDeliveryPkgs ?? 0,
        totalPaidPickupPkgs: dateActivity?.totalPaidPickupPkgs ?? 0,
        totalBCs: dateUsage?.summary?.totalBCs ?? 0, 
        totalDrivers: dateUsage?.summary?.totalDrivers ?? 0, 
        totalISPs: dateUsage?.summary?.totalISPs ?? 0, 
        totalUsers: dateUsage?.summary?.totalUsers ?? 0,
      }
    }


    return data;
  }
)

export {
  selectDashboardDailyMetrics,
  dailyActiveUsers,
  dailyOperationsActivity,
  dailyOperationsUsage,
  totalOperationsAndStation,
}


function getUniqueElementsFromArrays<T> (arr1: T[], arr2: T[], arr3: T[]): T[] {
  const unique = new Set([...arr1, ...arr2, ...arr3]);

  return [...unique];
}