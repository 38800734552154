import { Dispatch } from "@reduxjs/toolkit";
import {
  updateReferrals,
  updateReferralsFailed,
  resetReferrals,
  Referrals,
} from "@reducers/referrals";
import api from "@api";

export async function fetchReferrals(dispatch: Dispatch): Promise<Referrals> {
  try {
    const response = await api.request<Referrals>(`referrals`);

    const referrals = response.data ?? {};

    dispatch(updateReferrals({ referrals }));

    return referrals;
  } catch (err) {
    console.error(err);

    dispatch(updateReferralsFailed());

    return {};
  }
}

export function clearReferrals(dispatch: Dispatch) {
  dispatch(resetReferrals());
}
