import React from 'react';
import './IspGhostTools.css';

import firebaseProvider from '../../utils/firebase';

import api from '../../utils/api';
import Button from '../Page/Button';

export default class IspGhostTools extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      accounts: [],
      error: '',
    };

    this.toggleLink = this.toggleLink.bind(this);
    this.doToggleLink = this.doToggleLink.bind(this);
  }

  componentDidMount () {
    firebaseProvider.getConsoleDefaultDB().ref('/ghostAccounts').on('child_added', snap => {
      const id = snap.key;

      this.requestAccountData(id)
        .then(account => {
          this.setState({ accounts: [account, ...this.state.accounts] })
        })
        
    })
  }

  requestAccountData(FedExId) {
    return api.getGhostAccount(FedExId)
      .catch(err => {
        console.log(err);
        return {};
      })
  }

  toggleLink (rawGhost, newLinkStatus) {
    const { operationId, entityName, loadUsers } = this.props;

    this.doToggleLink(rawGhost, {operationId: operationId, operationName: entityName}, newLinkStatus)
      .then(() => {
        loadUsers()
      })
  }

  doToggleLink (rawGhost, operationData, newLinkStatus) {
    const { uid, ISP, ISPS, FedExId } = rawGhost;
    const ghost = { uid, ISP, ISPS, FedExId };

    return api.toggleGhostAccountLink(ghost, operationData, newLinkStatus)
      .catch(err => {
        console.log(err)
        this.setState({ error: 'unable to link account' });
      });
  }

  render () {
    const { users } = this.props;
    const { accounts, error } = this.state;

    if (!accounts) return null;

    const userLookup = (users || []).reduce((acc, usr) => {
      acc[usr.FedExId] = true;
      return acc;
    }, {});

    return (
      <div className='IspGhostTools'>
        { accounts.map((ghost, i) =>
          <Ghost
            key={i}
            ghost={ghost}
            isLinked={userLookup[ghost.FedExId]}
            toggleLink={this.toggleLink}
          />
        ) }
        { error &&
          <div className='ghostError'>
            <p>{error}</p>
          </div>
        }
      </div>
    );
  }
}

function Ghost ({ ghost, isLinked, toggleLink }) {
  const { FedExId, first, last } = ghost;
  return (
    <div className='Ghost'>
      <h3><strong>{ FedExId }</strong> { first } { last }</h3>

      {
        isLinked
        ? <Button color='#B90C0F' onClick={() => toggleLink(ghost, false)}>Unlink</Button>
        : <Button color='#006DAB' onClick={() => toggleLink(ghost, true)}>Link</Button>
      }
    </div>
  );
}