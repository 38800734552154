
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import pr from '@packageroute/types-firebase';

export type User = pr.users.User;
export type Users = Record<string, User | null>;

export enum Status {
  INITIAL = 'INITIAL',
  PENDING = 'PENDING',
  UPDATED = 'UPDATED',
  FAILED = 'FAILED'
}

export type State = {
  data: Users;
  status: Status;
};

const initialState: State = {
  data: {},
  status: Status.INITIAL,
};

const usersSlice = createSlice({
  name: 'users',
  initialState: initialState,
  reducers: {
    updateUsers: (state, action: PayloadAction<{ users: Users }>) => {
      const { users } = action.payload;

      state.data = {
        ...state.data,
        ...users
      };
      
      state.status = Status.UPDATED;
    },
    updateUser: (state, action: PayloadAction<{ userId: string, user: User | null }>) => {
      const { userId, user } = action.payload;

      state.data[userId] = user;
      state.status = Status.UPDATED;
    },
    resetUsers: (state) => {
      state.status = Status.INITIAL;
      state.data = {};
    }
  }
})

export const { 
  updateUsers,
  updateUser,
  resetUsers
} = usersSlice.actions

export default usersSlice.reducer;