import React from 'react';

import './Assets.css';
import Icon from '../../Icon/Icon';
import UploadTarget from '../../Page/UploadTarget';
import api from '../../../utils/api';

export default class Assets extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      activeIMG: null
    }

    this.uploadFile = this.uploadFile.bind(this);
    this.handleFile = this.handleFile.bind(this);
  }

  async componentDidMount () {
    if (!this.props.assets.length) {
      this.setState({ fetching: true });

      await this.props.fetchAssets();

      this.setState({ fetching: false });
    }
  }

  uploadFile (files) {
    if (!files) return this.props.addError('No files found.');
    if (files.length > 1) return this.props.addError('Only 1 file allowed per article.');

    const file = files[0];

    const { name } = file;

    this.setState({ newImgName: name }, () => {
      const fileReader = new FileReader();

      fileReader.onloadend = this.handleFile;

      fileReader.readAsDataURL(file);
    })
  }

  async handleFile (e) {
    const { newImgName } = this.state;
    const { target } = e;
    const { result } = target;

    await api.post(`knowledge/assets`, {
      file: result,
      name: newImgName
    });

    this.props.fetchAssets();
  }

  render () {
    return (
      <div className='Assets'>
        <div className='header'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <UploadTarget
              submitFiles={this.uploadFile}
              chooseMode
              acceptedFiles='image/*'
            />
          </div>
        </div>

        <div className='content'>
          {
            this.props.assets.length
              ? this.props.assets.map(url => {
                return (
                  <div className='img-wrapper' onClick={() => this.setState({ activeIMG: url })}>
                    <img src={url} />
                  </div>
                );
              })
              : <p>No assets uploaded yet.</p>
          }
        </div>

        {
          this.state.activeIMG
            ? <div className='active'>
              <div className='active-content'>
                <div className='top'>
                  <div className='close' onClick={() => this.setState({ activeIMG: null })}>
                    <Icon name='close' color='black' />
                  </div>
                </div>

                <div className='active-img'>
                  <img src={this.state.activeIMG} />
                </div>

                <div className='img-info'>
                  <p style={{ maxWidth: '75%', overflowWrap: 'break-word' }}>
                    {this.state.activeIMG}
                  </p>

                  <div className='copy' onClick={async () => {
                    const clipboard = navigator.clipboard;

                    await clipboard.writeText(this.state.activeIMG);
                  }}>
                    <Icon name='content-copy' color='#666' />
                  </div>
                </div>
              </div>
            </div>
            : null
        }
      </div>
    );
  }
}
