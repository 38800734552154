import React from 'react';

import './CreateMessage.css';
import InputText from '../../../Page/InputText';

export default class CreateMessage extends React.Component {
  render () {
    return (
      <div className='CreateMessage'>
        <h3>Notification</h3>

        <div className='input-wrapper'>
          <div className='field'>
            <InputText
              label='Title'
              type='text'
              value={this.props.notificationTitle}
              required
              valid={!!this.props.notificationTitle}
              onChange={(e) => {
                const val = e.target.value;

                this.props.updateNotification('notificationTitle', val);
              }}
            />
          </div>

          <div className='field'>
            <InputText
              label='Message'
              type='text'
              value={this.props.notificationBody}
              required
              valid={!!this.props.notificationBody}
              onChange={(e) => {
                const val = e.target.value;

                this.props.updateNotification('notificationBody', val);
              }}
            />
          </div>

          <div className='field'>
            <InputText
              label='External Link URL'
              placeholder='https://www.packageroute.com'
              type='text'
              value={this.props.externalLinkUrl}
              required
              valid={!!this.props.externalLinkUrl}
              onChange={(e) => {
                const val = e.target.value;

                this.props.updateNotification('externalLinkUrl', val);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
