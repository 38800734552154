import React from 'react';
import './StationMarker.css';
import { Marker } from '@react-google-maps/api';

type Props = {
  station: { id: string, terminal: string, longitude?: number, latitude?: number };
  onClick?: () => void;
  zoomScale: number;
  active?: boolean;
  focused?: boolean;
}

export default class StationMarker extends React.PureComponent<Props> {
  render () {
    const { station, onClick, zoomScale, active, focused } = this.props;
    const { latitude, longitude } = station;

    // @ts-ignore
    if (isNaN(parseFloat(latitude)) || isNaN(parseFloat(longitude))) {
      return null;
    }

    const scale = zoomScale * (focused ? 1.5 : 1);

    return (
      <Marker
        key={station.id}
        onClick={onClick}
        icon={{
          path: 'M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0',
          scale: scale,
          fillOpacity: 1,
          fillColor: focused ? '#76E593' : active ? '#828BFF' : '#f5f5f5',
          strokeWeight: scale * 2,
          strokeColor: active ? 'white' : '#444'
        }}
        label={
          zoomScale >= 6
            ? {
              text: station.terminal,
              fontSize: station.terminal.length > 3 ? '10px' : '12px',
              color: active ? 'white' : 'black'
            }
            : undefined
        }
        position={{
          // @ts-ignore
          lat: parseFloat(latitude),
          // @ts-ignore
          lng: parseFloat(longitude)
        }}
      />
    );
  }
}
