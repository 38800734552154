import React from 'react';

import Button from '../Page/Button';
import api from '../../utils/api';
import {IspDeletionModal} from "@components/ISPModal/IspDeletionModal";

export default class DeleteISP extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      attemptConsoleDelete: false,
      showProductDeletionModal: false,
      deletingISP: false,
      deleteIspError: null,
    }

    this.deleteConsoleISP = this.deleteConsoleISP.bind(this);
    this.deleteProductISP = this.deleteProductISP.bind(this);
  }

  deleteConsoleISP () {
    const { isp } = this.props;

    if (this.state.attemptConsoleDelete === true) {
      api.deleteISP(isp.id)
        .then(data => {
          this.props.history.push('/isps');
        })
        .catch(err => {
          this.setState({ message: err.message || 'Something went wrong.' });
        })
    }
    else {
      this.setState({ message: 'Are you sure you want to delete this ISP from the console? This action cannot be un-done.', attemptConsoleDelete: true });
    }
  }

  /**
   * Will delete the ISP from the *product* Firebase database, cancel the stripe subscription
   * and remove ISP reference from all ISP authorized users. This should never be used unless
   * really, *really* needed.
   * @returns void
   */
  async deleteProductISP () {

    try {
      this.setState({ deletingISP: true });
      const { profile } = this.props;

      if (!profile || !profile.ISPID) {
        throw new Error('ISP not found.');
      }

      await api.deleteRequest(`isps/firebase-id/${profile.ISPID}`);

      this.setState({ deletingISP: false, showDeletionModal: false, deleteIspError: null})
    } catch (err) {
      console.error(err);
      const message = err.message || 'Could not delete ISP. Something went wrong.';
      this.setState({ deletingISP:false,  deleteIspError: message})
    }
  }

  render () {
    const { profile } = this.props;

    return (
      <div style={{ padding: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        {
          this.state.message
            ? <p style={{ marginBottom: '10px' }}>{this.state.message}</p>
            : null
        }

        <Button
            onClick={() => this.setState({showDeletionModal: true})}
            color='red'>
          <p>
            Delete ISP
          </p>
        </Button>

        <Button
          onClick={this.deleteConsoleISP}
          color='red'>
          <p>
            {
              this.state.attemptedDelete
                ? 'Confirm'
                : 'Delete from Console'
            }
          </p>
        </Button>

        {this.state.showDeletionModal &&
          <IspDeletionModal
          close={() => this.setState({showDeletionModal: false})}
          deleteFirebaseIsp={() => this.deleteProductISP()}
          deleting={this.state.deletingISP}
          error={this.state.deleteIspError}
          profile={profile}
          />
        }
      </div>
    );
  }
}
