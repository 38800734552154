import React from 'react';

import './AdditionalRecipients.css';
import RecipientsSearch from './RecipientsSearch';
import RecipientsList from './RecipientsList';

export default function AdditionalRecipients (props) {
  return (
    <div className='AdditionalRecipients'>
      <RecipientsList
        list={props.addRecipients}
        activeList={props.addRecipients}
        onItemClick={props.toggleRecipient}
        emptyLabel='No additional recipients have been added.'
        border
      />

      <RecipientsSearch
        toggleRecipient={props.toggleRecipient}
        addRecipients={props.addRecipients}
      />
    </div>
  );
}
