import React from "react";
import { SubscriptionPlanContract } from "@models";
import ContractStatusIcon from "./ContractStatusIcon";
import ContractStatusDescription from "./ContractStatusDescription";
import ContractStartAndEndDates from "./ContractStartAndEndDates";
import { useColorPack } from '@hooks/redux';
import "./ContractSummary.css";

type Props = {
  subscriptionContract: SubscriptionPlanContract;
  pricePerRoute: number;
};

export default function ContractSummary({ subscriptionContract, pricePerRoute }: Props) {
  const colorPack = useColorPack();

  return (
    <div className="ContractSummary" style={{ borderTopWidth: 1, borderTopColor: colorPack.border }}>
      <ContractStatusIcon contractStatus={subscriptionContract.status} />

      <ContractStatusDescription contract={subscriptionContract} pricePerRoute={pricePerRoute} />

      <ContractStartAndEndDates
        startDate={subscriptionContract.startDate}
        endDate={subscriptionContract.endDate}
      />
    </div>
  );
}
