import { RootState } from "@reducers";
import { Status } from "@reducers/subscriptions/pastDue";

const selectPastDueSubscriptions = (state: RootState) =>
  state.subscriptions.pastDue.data;
  
const selectPastDueSubscriptionsLoaded = (state: RootState) =>
  state.subscriptions.pastDue.status === Status.UPDATED;

export { selectPastDueSubscriptions, selectPastDueSubscriptionsLoaded };
