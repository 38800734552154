import React from 'react';
import Stripe from 'stripe';
import moment from 'moment-timezone';
import CalendarList from './CalendarList';
import ConversionItem from './ConversionItem';
import ScrollView from '../Page/ScrollView';
import {LiveIspProfile} from '@reducers/liveIspList';
import {
  useLiveIspList,
  useIsConversion,
  useConvertedSubscriptions,
} from '@hooks/redux';
import {formatDaysAgoLabel} from './utils';
import './Conversions.css';

type Subscription = Stripe.subscriptions.ISubscription;

type DaysAgo = {
  daysAgo: number;
  label: string;
  items: DaysAgoItem[];
}

type DaysAgoItem = {
  id: string | null;
  subscription: Subscription;
  profile: LiveIspProfile;
}

type Props = {
  setFocusedISP: (ispId: string | null) => void;
  lastNDays: number;
}

export default function Conversions ({ setFocusedISP, lastNDays }: Props) {
  const conversions = useConvertedSubscriptions();
  const isConversion = useIsConversion();
  const liveISPList = useLiveIspList();

  if (!conversions) return null;

  const timeLimit = moment.tz('America/Los_Angeles').subtract(lastNDays, 'days').startOf('day').valueOf();
  const subscriptions = (conversions || []).filter(sub => isConversion(sub, timeLimit));
  const totalValue = subscriptions.reduce((acc, sub) => {
    const { quantity } = sub;

    acc = acc += ((quantity ?? 0) * 20);

    return acc;
  }, 0);
  const routes = (conversions || []).reduce((acc, subscription) => {
    if (!subscription || !subscription.metadata) return acc;

    const { ISPID } = subscription.metadata;
    const profile = liveISPList[ISPID];

    if (!profile) return acc;

    for (const workAreaId in profile.WorkAreas) {
      if (profile.WorkAreas[workAreaId].paid === true) {
        acc.paid += 1;
      }

      acc.total += 1;
    }

    return acc;
  }, { paid: 0, total: 0 });
  const now = Date.now();

  return (
    <React.Fragment>
      <div className='cohort'>
        <p style={{ fontSize: '12px' }}>{`Conversions in the last ${lastNDays} days.`}</p>

        <p style={{ fontSize: '12px' }}>
          <span style={{ margin: '0 5px', color: 'rgb(22, 142, 50)' }}>${totalValue}</span>
          <span style={{ margin: '0 5px' }}>
            <span style={{ color: '#006DAB' }}>{routes.paid}</span>
            {` / `}
            <span>{routes.total}</span>
          </span>
          <span style={{ margin: '0 5px' }}>{subscriptions.length} ISP</span>
        </p>
      </div>

      <ScrollView direction='vertical' height='700px'>
        <CalendarList
          labelColor='rgb(22, 142, 50)'
          data={Object.values(subscriptions.reduce<Record<number, DaysAgo>>((acc, sub) => {
            const { trial_end, created, metadata } = sub;
            const start = trial_end ? trial_end : created;
            const { ISPID } = metadata;
            const daysAgo = moment(now).diff(moment(start * 1000), 'days');

            if (!acc.hasOwnProperty(daysAgo)) {
              const label = formatDaysAgoLabel(daysAgo);

              acc[daysAgo] = {
                daysAgo: daysAgo,
                label,
                items: []
              };
            }

            const profile = liveISPList[ISPID] || null;
            
            if (profile) {
              acc[daysAgo].items.push({
                id: ISPID || null,
                subscription: sub,
                profile
              });
            }

            return acc;
          }, {}))}
          onItemClick={setFocusedISP}
          ListItem={ConversionItem}
        />
      </ScrollView>
    </React.Fragment>
  );
}
