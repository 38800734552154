import { AppDispatch, AppGetState, AppThunkDispatch  } from "@store";
import moment from 'moment';
import {
  updateSignups,
  updateSignupsDate,
  resetSignups,
  Signups,
  SignupDate,
} from "@reducers/signups";
import firebaseProvider from '@utils/firebase';
import bugsnagClient from "@utils/bugsnag";

export function subscribeTodaysSignups (dispatch: AppDispatch) {
  return new Promise((resolve, reject) => {
    try {
      const database = firebaseProvider.getConsoleDefaultDB();
      const today = getToday()
    
      const ref = database.ref(getSignupsFirebasePath(today));
    
      ref.on('value', (snap) => {
        const signups: SignupDate = snap.val() ?? {};
    
        dispatch(updateSignupsDate({ date: today, signups }));

        resolve(signups);
      });
    } catch (err) {
      console.error(err)
      bugsnagClient.notify(err as Error);

      reject(err);
    }
  })
}

function unsubscribeTodaysSignups() {
  const database = firebaseProvider.getConsoleDefaultDB();
  const today = getToday();

  const ref = database.ref(getSignupsFirebasePath(today));

  ref.off();
}

export function fetchPreviousDaysSignups (dates: string[], forceUpdate?: boolean) {
  return async (dispatch: AppThunkDispatch, getState: AppGetState) => {
    const database = firebaseProvider.getConsoleDefaultDB();
  
    const state = getState();

    const currentSignups = state.signups.data;

    const filteredDates = dates.filter(date => !currentSignups[date]);

    const datesToFetch = forceUpdate ? dates : filteredDates;
  
    const dailySignups = datesToFetch.map<Promise<[string, SignupDate | null]>>(async date => {
      const ref = database.ref(getSignupsFirebasePath(date));
    
      const snap = await ref.once('value');
      
      const signupDate: SignupDate | null = snap.val();
  
      return [date, signupDate];
    })
  
    const signupDays = await Promise.all(dailySignups);
  
    const signups = signupDays.reduce<Signups>((acc, [date, signupDate]) => {
      acc[date] = signupDate ?? {};
  
      return acc;
    }, {})
  
    dispatch(updateSignups({ signups }));
  }
}

export function clearSignups(dispatch: AppDispatch) {
  unsubscribeTodaysSignups();
  
  dispatch(resetSignups());
}

function getToday () {
  return moment.tz('America/Los_Angeles').format('YYYY-MM-DD');
}

function getSignupsFirebasePath (date: string) {
  return `signups/data/${date}`
}
