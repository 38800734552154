import React from 'react';

import './StationAdvocate.css';
import Icon from '../Icon/Icon';
import api from '../../utils/api';

export default class StationAdvocate extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      locked: true
    };

    this.addAdvocate = this.addAdvocate.bind(this);
    this.removeAdvocate = this.removeAdvocate.bind(this);
  }

  async addAdvocate () {
    const { station, user, editable } = this.props;
    const { terminal } = station;

    if (!terminal || !user || !editable) return null;

    this.setState({ locked: true });

    await api.post(`stations/advocate/${terminal}`, { userId: user.uid });

    this.props.updateStationData();
  }

  async removeAdvocate () {
    const { station, user, editable } = this.props;
    const { terminal } = station;

    if (!terminal || !user || !editable) return null;

    await api.deleteRequest(`stations/advocate/${terminal}`, { userId: user.uid });

    this.props.updateStationData();
  }

  render () {
    const { isAdvocate, editable = true } = this.props;

    return (
      <div className='StationAdvocate'>
        {
          editable
            ? <div
              className='locked'
              onClick={() => this.setState(prevState => {
                return { locked: !prevState.locked };
              })}>
              <Icon color='black' name={this.state.locked ? 'lock-outline' : 'lock-open-outline'} size='18px' />
            </div>
            : null
        }

        <p
          onClick={editable && !this.state.locked
            ? isAdvocate
              ? () => this.removeAdvocate()
              : () => this.addAdvocate()
            : null
          }
          className={`text ${isAdvocate === true ? 'active' : ''} ${!this.state.locked ? 'unlocked' : ''}`}>
          Station Advocate
        </p>
      </div>
    );
  }
}
