import React from 'react';
import { OverlayView } from '@react-google-maps/api';
import './WorkAreaMarker.css';

const getPixelPositionOffset = (width: number, height: number) => ({
  x: -(width / 2),
  y: -(height / 2)
});

type Props = {
  workArea: {
    location?: {
      latitude: number;
      longitude: number;
    };
    type?: string;
    workArea: string;
  }
}
export default function WorkAreaMarker ({ workArea }: Props) {
  if (!workArea.location) return null;

  return (
    <OverlayView
      position={{
        lat: workArea.location.latitude,
        lng: workArea.location.longitude,
      }}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={getPixelPositionOffset}
    >
      <div
        className='WorkAreaMarker'
        style={{
          backgroundColor: workArea.type
          ? workArea.type === 'ground'
            ? '#168E32'
            : '#ED8914'
          : '#555'
        }}
      >
        { workArea.workArea }
      </div>
    </OverlayView>
  );
}
