import React from 'react';
import moment from 'moment-timezone';

import './SalesPipeline.css';
import PipelineList from './PipelineList';
import api from '../../utils/api';
import ISPModal from '../ISPModal/ISPModal';
import TagPopout from '../TagPopout/TagPopout';
// import InputSlider from '../Page/InputSlider';
import withPermissions from '../Permissions/withPermissions';

class SalesPipeline extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      pipelineLists: {},
      activeISP: null,
      activeTag: null,
      activeType: null,
      activeList: null,
      filterType: 'inactive',
      filtersOn: false,
      numDays: 7,
      percent: 75,
      loadingPipelineLists: true,
    };

    this.fetchPipelineLists = this.fetchPipelineLists.bind(this);
    this.setActiveItem = this.setActiveItem.bind(this);
    this.filterISP = this.filterISP.bind(this);
  }

  componentDidMount () {
    this.fetchPipelineLists();
  }

  setActiveItem (item, type, tag = null) {
    this.setState({ activeISP: item, activeType: type, activeTag: tag });
  }

  fetchPipelineLists () {
    api.request('pipeline-lists')
      .then(res => {
        this.setState({ pipelineLists: res.data, loadingPipelineLists: false })
      });
  }

  filterISP (isp) {
    if (this.state.filtersOn === false) return true;

    const { numDays, percent, filterType } = this.state;
    const { firebaseId } = isp;
    const { usage } = this.props;
    let activityCount = 0;

    if (!usage) return true;

    for (let i = 0; i < numDays; i++) {
      const date = moment().subtract(i, 'days').format('YYYY-MM-DD');

      if (usage[date] && usage[date].activeISPs && usage[date].activeISPs.hasOwnProperty(firebaseId)) {
        activityCount += 1;
      }
    }

    if (filterType === 'inactive') {
      return activityCount <= ((percent / 100) * numDays);
    }

    if (filterType === 'active') {
      return activityCount >= ((percent / 100) * numDays);
    }

    return true;
  }

  render () {
    const {loadingPipelineLists, pipelineLists} = this.state;
    const leadsLen = (pipelineLists['leads'] || []).length;
    const contactedLen = (pipelineLists['contacted'] || []).length;
    const qualifiedLen = (pipelineLists['qualified'] || []).length;
    const trialLen = (pipelineLists['trial'] || []).length;
    const signupLen = (pipelineLists['signup'] || []).length;
    const contactedOffset = contactedLen - leadsLen;
    const qualifiedOffset = qualifiedLen - contactedLen;
    const trialOffset = trialLen - qualifiedLen;
    const signupOffset = signupLen - trialLen;
    const { filtersOn, filterType, numDays, percent } = this.state;

    return (
      <div className='SalesPipeline'>
        <div className='filters'>
          <div className='filter-section'>
            <div
              className={`filter ${!filtersOn ? 'off' : 'active'}`}
              onClick={() => this.setState(prevState => {
                return { filtersOn: !prevState.filtersOn };
              })}>
              <p>Filters {filtersOn ? 'On' : 'Off'}</p>
            </div>
          </div>

          <div className='filter-section'>
            <div
              onClick={() => this.setState({ filterType: 'active' })}
              className={`filter ${filtersOn && filterType === 'active' ? '' : 'off'}`}>
              <p>Active</p>
            </div>

            <div
              onClick={() => this.setState({ filterType: 'inactive' })}
              className={`filter ${filtersOn && filterType === 'inactive' ? '' : 'off'}`}>
              <p>Inactive</p>
            </div>
          </div>

          <div className='filter-section'>
            <div
              onClick={() => this.setState({ numDays: 3 })}
              className={`filter ${filtersOn && numDays === 3 ? '' : 'off'}`}>
              <p>3 days</p>
            </div>
            <div
              onClick={() => this.setState({ numDays: 7 })}
              className={`filter ${filtersOn && numDays === 7 ? '' : 'off'}`}>
              <p>7 days</p>
            </div>
            <div
              onClick={() => this.setState({ numDays: 14 })}
              className={`filter ${filtersOn && numDays === 14 ? '' : 'off'}`}>
              <p>14 days</p>
            </div>
            <div
              onClick={() => this.setState({ numDays: 30 })}
              className={`filter ${filtersOn && numDays === 30 ? '' : 'off'}`}>
              <p>30 days</p>
            </div>
          </div>

          <div className='filter-section'>
            <div
              onClick={() => this.setState({ percent: 75 })}
              className={`filter ${filtersOn && percent === 75 ? '' : 'off'}`}>
              <p>75%</p>
            </div>
            <div
              onClick={() => this.setState({ percent: 50 })}
              className={`filter ${filtersOn && percent === 50 ? '' : 'off'}`}>
              <p>50%</p>
            </div>
            <div
              onClick={() => this.setState({ percent: 25 })}
              className={`filter ${filtersOn && percent === 25 ? '' : 'off'}`}>
              <p>25%</p>
            </div>
            <div
              onClick={() => this.setState({ percent: 0 })}
              className={`filter ${filtersOn && percent === 0 ? '' : 'off'}`}>
              <p>0%</p>
            </div>
          </div>
        </div>

        <div className='filters-summary'>
          <p>
            {
              filtersOn
                ? filterType === 'inactive'
                  ? `ISPs who have been active for ${percent === 0 ? '' : 'less than'} ${percent}% of the last ${numDays} days.`
                  : `ISPs who have been active for more than ${percent}% of the last ${numDays} days.`
                : 'Filters are off.'
            }
          </p>
        </div>

        <div className='lists'>
          <PipelineList
            status={0}
            loadingData={loadingPipelineLists}
            data={(pipelineLists['leads'] || []).filter(this.filterISP)}
            liveISPList={this.props.liveISPList}
            usage={this.props.usage}
            setActiveItem={this.setActiveItem}
            offset={0}
            user={this.props.user}
          />

          <PipelineList
            status={1}
            loadingData={loadingPipelineLists}
            data={(pipelineLists['contacted'] || []).filter(this.filterISP)}
            liveISPList={this.props.liveISPList}
            usage={this.props.usage}
            setActiveItem={this.setActiveItem}
            offset={contactedOffset}
            user={this.props.user}
          />

          <PipelineList
            status={2}
            loadingData={loadingPipelineLists}
            data={(pipelineLists['qualified'] || []).filter(this.filterISP)}
            liveISPList={this.props.liveISPList}
            usage={this.props.usage}
            setActiveItem={this.setActiveItem}
            offset={qualifiedOffset}
            user={this.props.user}
          />

          <PipelineList
            status={3}
            loadingData={loadingPipelineLists}
            data={(pipelineLists['trial'] || []).filter(this.filterISP)}
            liveISPList={this.props.liveISPList}
            usage={this.props.usage}
            setActiveItem={this.setActiveItem}
            offset={trialOffset}
            user={this.props.user}
          />

          <PipelineList
            status={4}
            loadingData={loadingPipelineLists}
            data={(pipelineLists['signup'] || []).filter(this.filterISP)}
            liveISPList={this.props.liveISPList}
            usage={this.props.usage}
            setActiveItem={this.setActiveItem}
            offset={signupOffset}
            user={this.props.user}
          />

          <PipelineList
            status={5}
            loadingData={loadingPipelineLists}
            data={(pipelineLists['churned'] || []).filter(this.filterISP)}
            liveISPList={this.props.liveISPList}
            usage={this.props.usage}
            setActiveItem={this.setActiveItem}
            offset={signupOffset}
            user={this.props.user}
          />
        </div>

        {
          this.state.activeISP && this.state.activeType === 'isp'
            ? <ISPModal
                history={this.props.history}
                list={this.state.activeList}
                usage={this.props.usage}
                profile={(this.props.liveISPList || {})[this.state.activeISP.firebaseId]}
                setActiveItem={this.setActiveItem}
                collections={this.props.collections}
              />
            : null
        }

        {
          this.state.activeISP && this.state.activeType === 'tag'
            ? <TagPopout
                tag={this.state.activeTag}
                isp={this.state.activeISP}
                setActiveTag={this.setActiveItem}
                updateProps={() => {}}
              />
            : null
        }
      </div>
    )
  }
}

export default withPermissions(SalesPipeline);
