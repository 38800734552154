import { SubscriptionPlanType } from '../subscription/subscriptionPlanType';

type ISO8601 = string;

export type OperationOwnerDto = {
  fedex_id: string;
  uid: string;
  customer_id: string | null;
};

type SubscriptionDescriptionDto = {
  former_subscription_id: string;
  new_subscription_id: string | null;
  total_paid_routes: number;
  is_under_annual_contract: boolean;
  subscription_plan_type: SubscriptionPlanType;
  plan_id: string;
};

export type InitiatedTransferMetadata = {
  is_preview?: boolean | never;
  transfer_attempted_at: ISO8601;
  transferred_by_console_user: string;
};

export type PreviewTransferMetadata = {
  is_preview: true;
} & Omit<InitiatedTransferMetadata, 'is_preview'>;

export type CompletedTransferMetadata = {
  transfer_succeeded_at: ISO8601;
} & InitiatedTransferMetadata;

export type FailedTransferMetaData = {
  transfer_failed_error: string;
  transfer_failed_at: ISO8601;
} & InitiatedTransferMetadata;

export type TransferMetadata =
  | InitiatedTransferMetadata
  | PreviewTransferMetadata
  | CompletedTransferMetadata
  | FailedTransferMetaData;

export type OperationTransferDTO = {
  id: string;
  operation_id: string;
  operation_name: string;
  former_owner: OperationOwnerDto;
  new_owner: OperationOwnerDto;
  subscription: SubscriptionDescriptionDto;
  metadata: TransferMetadata;
};

export class OperationOwner {
  constructor (
    public uid: string,
    public fedexId: string,
    public customerId: string | null,
  ) {}
}

export class SubscriptionDescription {
  constructor (
    public formerSubscriptionId: string,
    public newSubscriptionId: string,
    public totalPaidRoutes: number,
    public isUnderAnnualContract: boolean,
    public subscriptionPlanType: SubscriptionPlanType,
    public planId: string,
  ) {}
}

export class OperationTransfer {
  constructor (
    public id: string | null,
    public operationId: string,
    public operationName: string,
    public formerOwner: OperationOwner,
    public newOwner: OperationOwner,
    public subscription: SubscriptionDescription,
    public metadata: TransferMetadata,
  ) {}
}

export function mapOperationTransferDtoToDomain (dto: OperationTransferDTO): OperationTransfer {
  return new OperationTransfer(
    dto.id,
    dto.operation_id,
    dto.operation_name,
    mapOperationOwnerDtoToDomain(dto.former_owner),
    mapOperationOwnerDtoToDomain(dto.new_owner),
    mapSubscriptionDescriptionDtoToDomain(dto.subscription),
    mapMetadata(dto.metadata),
  )
}

export function mapOperationTransferDomainToDto (domain: OperationTransfer): OperationTransferDTO {
  return {
    id: domain.id ?? '',
    operation_id: domain.operationId,
    operation_name: domain.operationName,
    former_owner: mapOperationOwnerDomainToDto(domain.formerOwner),
    new_owner: mapOperationOwnerDomainToDto(domain.newOwner),
    subscription: mapSubscriptionDescriptionDomainToDto(domain.subscription),
    metadata: mapMetadata(domain.metadata),
  }
}

function mapOperationOwnerDtoToDomain (dto: OperationOwnerDto): OperationOwner {
  return new OperationOwner(
    dto.uid,
    dto.fedex_id,
    dto.customer_id,
  );
}

function mapOperationOwnerDomainToDto (domain: OperationOwner): OperationOwnerDto {
  return {
    uid: domain.uid,
    fedex_id: domain.fedexId,
    customer_id: domain.customerId,
  }
}

function mapSubscriptionDescriptionDtoToDomain (dto: SubscriptionDescriptionDto): SubscriptionDescription {
  return new SubscriptionDescription(
    dto.former_subscription_id,
    dto.new_subscription_id ?? '',
    dto.total_paid_routes,
    dto.is_under_annual_contract,
    dto.subscription_plan_type,
    dto.plan_id,
  )
}

function mapSubscriptionDescriptionDomainToDto (domain: SubscriptionDescription): SubscriptionDescriptionDto {
  return {
    former_subscription_id: domain.formerSubscriptionId,
    new_subscription_id: domain.newSubscriptionId,
    total_paid_routes: domain.totalPaidRoutes,
    is_under_annual_contract: domain.isUnderAnnualContract,
    subscription_plan_type: domain.subscriptionPlanType,
    plan_id: domain.planId,
  }
}

function mapMetadata (data: TransferMetadata): TransferMetadata {
  const metadata: TransferMetadata = {
    transfer_attempted_at: data.transfer_attempted_at,
    transferred_by_console_user: data.transferred_by_console_user,
  }

  if (data.is_preview) {
    const previewMetadata = {
      ...metadata,
      is_preview: true,
    } as PreviewTransferMetadata;

    return previewMetadata;
  } else if ('transfer_succeeded_at' in data) {
    const completedMetadata = metadata as CompletedTransferMetadata;
    
    completedMetadata.transfer_succeeded_at = data.transfer_succeeded_at;

    return completedMetadata;
  } else if ('transfer_failed_error' in data) {
    const failedMetadata = metadata as FailedTransferMetaData;

    failedMetadata.transfer_failed_error = data.transfer_failed_error;
    failedMetadata.transfer_failed_at = data.transfer_failed_at;

    return failedMetadata;
  }

  return metadata;
}