import { useAppSelector } from "../store";
import {
  selectPermissions,
  selectPermissionsLoaded,
} from "@selectors/permissions";

export function usePermissions() {
  return useAppSelector(selectPermissions);
}

export function usePermissionsLoaded() {
  return useAppSelector(selectPermissionsLoaded);
}
