import React from 'react';
import './Spinner.css';

type Props = {
  color?: string;
  size?: number;
}

export default function Spinner ({ color, size = 20 }: Props) {
  return (
    <div className='Spinner' style={{
      borderTopColor: color,
      width: size,
      height: size,
      borderTopWidth: size / 6,
      borderBottomWidth: size / 6
    }} />
  );
}

type PropsWithPadding = {
  padding?: number;
  color?: string;
  size?: number;
}

export function SpinnerWithPadding ({ padding = 25, color, size }: PropsWithPadding) {
  return (
    <div style={{ padding, display: 'flex', justifyContent: 'center' }}>
      <Spinner
        size={size}
        color={color || 'rgb(0, 109, 171)'}
      />
    </div>
  );
}
