function toggleRecipient (recipient) {
  const { addRecipients } = this.state;
  let nextRecipients = [...addRecipients];
  let index = -1;

  for (let i = 0; i < addRecipients.length; i++) {
    if (addRecipients[i].id === recipient.id) {
      index = i;
    }
  }

  if (index >= 0) {
    nextRecipients = [
      ...nextRecipients.slice(0, index),
      ...nextRecipients.slice(index + 1)
    ];
  }
  else {
    nextRecipients.push(recipient);
  }

  this.setState({ addRecipients: nextRecipients }, () => {
    this.validateRecipients();
  });
}

function validateRecipients () {
  const { addRecipients, selectedCollections } = this.state;
  const step = this.state.steps[this.state.recipientsStepIdx];

  if (!addRecipients.length && !selectedCollections.length) {
    this.unmarkStepComplete(step);
  }
  else {
    this.markStepComplete(step);
  }
}

function markStepComplete (step) {
  this.setState(prevState => {
    const nextCompletedSteps = [...prevState.completedSteps];

    if (!nextCompletedSteps.includes(step.order)) {
      nextCompletedSteps.push(step.order);
    }

    return { completedSteps: nextCompletedSteps };
  }, () => {
    if (this.state.draft && this.syncDraft) {
      this.syncDraft();
    }
  });
}

function unmarkStepComplete (step) {
  this.setState(prevState => {
    const index = prevState.completedSteps.indexOf(step.order);

    if (index < 0) {
      return {};
    }

    const nextCompletedSteps = [
      ...prevState.completedSteps.slice(0, index),
      ...prevState.completedSteps.slice(index + 1)
    ];

    return { completedSteps: nextCompletedSteps };
  }, () => {
    if (this.state.draft && this.syncDraft) {
      this.syncDraft();
    }
  });
}

function setActiveStep (step) {
  this.setState({ activeStep: step }, () => {
    if (this.state.draft && this.syncDraft) {
      this.syncDraft();
    }
  });
}

function combineRecipients () {
  const { addRecipients, selectedCollections } = this.state;
  const { collections } = this.props;
  const recipients = [];

  for (const recipient of addRecipients) {
    recipients.push({
      id: recipient.id,
      recipientType: recipient.type
    });
  }

  for (const id of selectedCollections) {
    const collection = collections[id] || {};
    const { isps = [], stations = [], users = [] } = collection;

    if (id === 'ALL_ISP') {
      recipients.push({ id: 'ALL_ISP', recipientType: 'ALL_ISP' });
    }

    for (const ispid of isps) {
      recipients.push({ id: ispid, recipientType: 'ISP' });
    }

    for (const stationId of stations) {
      recipients.push({ id: stationId, recipientType: 'STATION' });
    }

    for (const uid of users) {
      recipients.push({ id: uid, recipientType: 'USER' });
    }
  }

  return recipients;
}

function toggleCollection (collectionId) {
  this.setState(prevState => {
    let nextCollections = [...prevState.selectedCollections];

    if (nextCollections.includes(collectionId)) {
      const index = nextCollections.indexOf(collectionId);

      nextCollections = [
        ...nextCollections.slice(0, index),
        ...nextCollections.slice(index + 1)
      ];
    }
    else {
      nextCollections.push(collectionId);
    }

    return { selectedCollections: nextCollections };
  }, () => {
    this.validateRecipients();
  });
}

function markFinished () {
  this.setState({ finished: true });
}

export default {
  toggleRecipient,
  validateRecipients,
  markStepComplete,
  unmarkStepComplete,
  setActiveStep,
  combineRecipients,
  toggleCollection,
  markFinished
}
