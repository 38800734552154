import React from 'react';

import './TemplateData.css';
import InputTextArea from '../../../Page/InputTextArea';

export default class TemplateData extends React.Component {

  render () {
    const { templateDefinition } = this.props;
    const fields = templateDefinition.fields;

    return (
      <div className='TemplateData'>
        <h3>Customize</h3>

        <div className='list'>
          <div className='scrollable'>
            {
              fields && fields.length
                ? fields.map(field => {
                  return (
                    <div className='input-wrapper'>
                      <InputTextArea
                        type={field}
                        label={field}
                        value={this.props.templateData[field]}
                        onChange={(e) => {
                          const val = e.target.value;

                          this.props.updateTemplateData(field, val);
                        }}
                        inputStyle={field
                          ? {
                            minHeight: '40px',
                            maxHeight: '400px',
                            overflowWrap: 'break-word',
                            resize: 'vertical'
                          }
                          : null
                        }
                        required
                        valid={!!this.props.templateData[field]}
                      />
                    </div>
                  );
                })
                : <p className='exception'>No customizations are necessary for this template.</p>
            }
          </div>
        </div>
      </div>
    );
  }
}
