import React from "react";
import moment from "moment";
import "./ContractSummary.css";

type Props = {
  startDate: moment.Moment;
  endDate: moment.Moment;
};

const DATE_FORMAT = "M/D/YY";

export default function ContractStartAndEndDates({
  startDate,
  endDate,
}: Props) {
  

  return (
    <div className="contract-dates">
      <p>{`${startDate.format(DATE_FORMAT)} - ${endDate.format(DATE_FORMAT)}`}</p>
    </div>
  );
}
