import React from 'react';

import './StationsSearchList.css';
import api from '../../utils/api';
import ScrollView from '../Page/ScrollView';
import Icon from '../Icon/Icon';

export default class StationsSearchList extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      ispsByStationId: null,
      ispsByTerminalId: null,
      searchText: null,
      sortByActiveISP: true
    }

    this.biuldISPsByStationId = this.buildISPsByStationId.bind(this);
    this.biuldISPsByTerminalId = this.buildISPsByTerminalId.bind(this);
  }

  componentDidMount () {
    this.buildISPsByStationId();
    this.buildISPsByTerminalId();
  }

  componentDidUpdate (prevProps) {
    if (Object.keys(prevProps.liveISPList || {}).length !== Object.keys(this.props.liveISPList || {}).length) {
      this.buildISPsByStationId();
    }
  }

  buildISPsByStationId () {
    const { liveISPList } = this.props;

    if (!liveISPList) return;

    const ispsByStationId = {};

    for (const ISPID in liveISPList) {
      const profile = liveISPList[ISPID];

      if (!profile.station || !profile.station.stationID) continue;
      
      const stationId = profile.station.stationID;

      if (!ispsByStationId.hasOwnProperty(stationId)) {
        ispsByStationId[stationId] = [];
      }

      ispsByStationId[stationId].push(ISPID);
    }

    this.setState({ ispsByStationId });
  }

  async buildISPsByTerminalId () {
    const isps = await this.getISPCounts();
    const ispsByTerminalId = {};

    for (const isp of isps) {
      const terminalId = isp.terminal;

      if (!terminalId) continue;

      if (!ispsByTerminalId.hasOwnProperty(terminalId)) {
        ispsByTerminalId[terminalId] = isp.numIsps;
      }
    }

    this.setState({ ispsByTerminalId });
  }

  async getISPCounts () {
    const res = await api.request('stations/isps/count');

    return res.data;
  }

  render () {
    const {
      stations,
    } = this.props;
    const {
      ispsByStationId,
      ispsByTerminalId,
      searchText,
      sortByActiveISP
    } = this.state;

    if (!ispsByTerminalId || !ispsByStationId) return null;

    return (
      <div className='StationsSearchList'>
        <div className='search'>
          <input
            type='text'
            placeholder={`By station number`}
            onChange={e => this.setState({ searchText: e.target.value })}
            value={this.state.searchText || ''}
          />

          <div
            className={`sort-by ${sortByActiveISP ? 'active' : ''}`}
            onClick={() => this.setState(prevState => {
              return { sortByActiveISP: !prevState.sortByActiveISP };
            })}>
            <p>Active ISP</p>
          </div>
        </div>

        <ScrollView direction='vertical' height='330px'>
          {
            stations && stations
              .filter(station => {
                if (!searchText) return true;

                return station.terminal === searchText;
              })
              .sort((a, b) => {
                const aSignedUpISPs = ispsByStationId[a.terminal]
                  ? ispsByStationId[a.terminal].length
                  : 0;
                const bSignedUpISPs = ispsByStationId[b.terminal]
                  ? ispsByStationId[b.terminal].length
                  : 0;

                if (sortByActiveISP === true) {
                  return bSignedUpISPs - aSignedUpISPs;
                }
                else {
                  return aSignedUpISPs - bSignedUpISPs;
                }
              })
              .map((station, i) => {
                const totalISPs = ispsByTerminalId[station.terminal]
                  ? ispsByTerminalId[station.terminal]
                  : 0;
                const signedUpISPs = ispsByStationId[station.terminal]
                  ? ispsByStationId[station.terminal].length
                  : 0;

                return (
                  <div
                    key={i}
                    onClick={() => this.props.setFocusedStation(station)}
                    className='station-item'>
                    <div className='station-info'>
                      <Icon name='city' size={20} color='#006DAB' />
                      <p>{station.terminal}</p>
                    </div>

                    <div className='station-isps'>
                      <p>
                        <span
                          style={{ color: 'rgb(22, 142, 50)' }}>
                          {signedUpISPs || 0}
                        </span>

                        <span>{` / ${totalISPs || 0} `}</span>

                        <span style={{ fontSize: '12px' }}>ISP</span>
                      </p>
                    </div>
                  </div>
                );
              })
          }
        </ScrollView>
      </div>
    );
  }
}
