import React from 'react';

import './Emails.css';
import Dash from '../Dash/Dash';
import CreateEmail from './CreateEmail';
import MsgNav from '../MsgNav';
import firebaseProvider from '../../../utils/firebase';

export default class Emails extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      mode: 'DASH',
      activeDraft: null,
      emailHistory: {}
    }

    this.setMode = this.setMode.bind(this);
    this.setDraftsListener = this.setDraftsListener.bind(this);
    this.createViaDraft = this.createViaDraft.bind(this);
  }

  componentDidMount () {
    // this.setDraftsListener();
    this.setHistoryListener();
  }

  componentWillUnmount () {
    const consoleDB = firebaseProvider.getConsoleDefaultDB();
    const HistoryRef = consoleDB.ref('emailHistory');

    HistoryRef.off();

    for (const pushKey in (this.state.emailHistory || {})) {
      HistoryRef.child(pushKey).off();
    }
  }

  setMode (mode) {
    this.setState({ mode: mode });
  }

  setHistoryListener () {
    const consoleDB = firebaseProvider.getConsoleDefaultDB();
    const HistoryRef = consoleDB.ref(`emailHistory`);

    HistoryRef
      .orderByChild('finishedAt')
      .limitToLast(100)
      .on('child_added', snap => {
        HistoryRef.child(snap.key).on('value', snap => {
          this.setState(prevState => {
            return {
              emailHistory: {
                ...prevState.emailHistory,
                [snap.key]: snap.val()
              }
            };
          });
        });

        this.setState(prevState => {
          return {
            emailHistory: {
              ...prevState.emailHistory,
              [snap.key]: snap.val()
            }
          }
        })
      });
  }

  setDraftsListener () {
    const consoleDB = firebaseProvider.getConsoleDefaultDB();
    const DraftsRef = consoleDB.ref('drafts/email');

    DraftsRef.on('value', snap => {
      this.setState({ drafts: snap.val() });
    });
  }

  createViaDraft (pushKey, draft) {
    this.setState({ activeDraft: { ...draft, draftId: pushKey } }, () => {
      this.setMode('CREATE');
    });
  }

  render () {
    return (
      <div className='messenger'>
        {
          this.state.mode === 'DASH'
            ? <Dash
              title='Console Email History'
              consoleUsers={this.props.consoleUsers}
              history={this.state.emailHistory}
            />
            : null
        }

        {
          this.state.mode === 'CREATE'
            ? <CreateEmail
              user={this.props.user}
              collections={this.props.collections}
              profiles={this.props.profiles}
              users={this.props.users}
              fetchUsers={this.props.fetchUsers}
            />
            : null
        }

        <MsgNav
          opts={[
            { icon: 'speedometer', mode: 'DASH' },
            { icon: 'send', mode: 'CREATE' }
          ]}
          onClickOpt={this.setMode}
          mode={this.state.mode}
        />
      </div>
    );
  }
}
