class AsyncStorage {
  private client: typeof localStorage;

  constructor (client: typeof localStorage) {
    this.client = client;
  }
  
  async getItem (key: string, callback?: (err: Error | undefined | null, result?: string | null) => void) {
    try {
      const item = this.client.getItem(key);

      callback?.(null, item);

      return item;
    } catch (err) {
      console.error(err);

      callback?.(err as Error);
    }
  }

  async setItem (key: string, value: string, callback?: (error?: Error) => void) {
    try {
      this.client.setItem(key, value);

      callback?.();
    } catch (err) {
      callback?.(err as Error);
    }
  }

  async removeItem (key: string, callback?: (error?: Error) => void) {
    try {
      this.client.removeItem(key);

      callback?.();
    } catch (err) {
      callback?.(err as Error);
    }
  }
}

export default new AsyncStorage(localStorage);