import React from 'react';

import { titleize } from '../../utils/formatting'
import './UserSearchEntry.css';
import Icon from '../Icon/Icon';

export default function UserSearchEntry (props) {
  const { user, matches = {}, onClick } = props;

  const iconType = {
    "driver": "truck",
    "owner": "clipboard-account",
  }

  return (
    <div
      onClick={onClick}
      className="UserSearchEntry"
    >
      <div className='iconWrap'>
        <Icon name='account' size={30} color='#006DAB' />
      </div>

      <div className='entryBody'>
        <div className='identity'>
          <h2 className='nameTitle'>
            <span className={matches && Object.keys(matches).length > 0 ? 'match' : ''} >
              {titleize(user.first)} {titleize(user.last)}
            </span>

            <span className={matches && matches.fedExId ? 'match' : ''}>
              {`, ${user.fedExId}`}
            </span>
          </h2>

          <div className='detailsWrap'>
            <div className='detail'>
              <Icon name={iconType[user.type]} size={15} color='#666' />
              <h4>
                <span className={matches && matches.first ? 'match' : ''}>
                  { titleize(user.type) }
                </span>
              </h4>
            </div>

            <div className='detail'>
              <Icon name='phone' size={15} color='#666' />
              <h4>
                <span className={matches && matches.phone ? 'match' : ''}>
                  { user.phone }
                </span>
              </h4>
            </div>

            <div className='detail'>
              <Icon name='email' size={15} color='#666' />
              <h4>
                <span className={matches && matches.email ? 'match' : ''}>
                  { user.email }
                </span>
              </h4>
            </div>
          </div>

          <div className='actions'>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}

