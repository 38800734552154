import React from "react";
import { useColorPack } from "@hooks/redux";
import { ContractStatus } from '@models';

import "./ContractSummary.css";


type Props = {
  contractStatus: ContractStatus;
};

export default function ContractStatusIcon ({ contractStatus }: Props) {
  const colorPack = useColorPack();

  let icon: string;
  let color: string;

  switch (contractStatus) {
    case ContractStatus.CANCELLED: {
      icon = "cancel";
      color = colorPack.red;

      break;
    }
    case ContractStatus.CANCELLING_BEFORE_CONTRACT_END: {
      icon = "calendar-clock";
      color = colorPack.orange;

      break;
    }
    case ContractStatus.EXPIRING: {
      icon = "calendar-clock";
      color = colorPack.red;

      break;
    }
    default: {
      icon = "currency-usd";
      color = colorPack.green;
    }
  }

  return (
    <div className="iconWrap" style={{ borderRightColor: colorPack.border, borderRightWidth: 1 }}>
      <i className={`mdi mdi-${icon}`} style={{ color: color }} />
    </div>
  );
}
