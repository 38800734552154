import React, { useState, useCallback } from "react";
import InputText from "../../Page/InputText";
import Spinner from "../../common/Spinner";
import firebaseProvider from "../../../utils/firebase";
import { ConsoleCollectionsService } from "@services";
import { useFirebaseUser } from "@hooks/redux";
import bugsnagClient from "@utils/bugsnag";
import "./CreateCollection.css";

type Props = {};

export default function CreateCollection({}: Props) {
  const { name, setName, updating, createCollection } = useCreateCollection();

  return (
    <div className="CreateCollection">
      <InputText
        type="text"
        placeholder="Collection name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        inputStyle={{
          backgroundColor: "#f5f5f5",
          width: "200px",
          borderRadius: "3px",
          border: "none",
        }}
      />

      <div
        className={`create-btn ${name ? "active" : ""}`}
        onClick={name ? createCollection : undefined}
      >
        {updating ? <Spinner color="white" size={25} /> : <p>Create</p>}
      </div>
    </div>
  );
}

function useCreateCollection() {
  const fireBaseUser = useFirebaseUser();
  const [name, setName] = useState("");
  const [updating, setUpdating] = useState(false);

  const createCollection = useCallback(async () => {
    try {
      setUpdating(true);
  
      if (fireBaseUser) {
        const collectionsService = new ConsoleCollectionsService(
          firebaseProvider
        );
  
        await collectionsService.createCollection(fireBaseUser.uid, name);
      }
  
      setName("");
      setUpdating(false);
    } catch (err) {
      console.error(err);
      bugsnagClient.notify(err as Error);
    }
  }, [name, fireBaseUser]);

  return {
    name,
    setName,
    updating,
    createCollection,
  };
}
