import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {FirebaseUser as IFirebaseUser} from '@utils/firebase';

export type FirebaseUser = IFirebaseUser;

export enum Status {
  INITIAL = 'INITIAL',
  PENDING = 'PENDING',
  UPDATED = 'UPDATED',
  FAILED = 'FAILED'
}

export type State = {
  data: FirebaseUser | null;
  status: Status;
};

const initialState: State = {
  data: null,
  status: Status.INITIAL,
};

const firebaseUserSlice = createSlice({
  name: 'firebaseUser',
  initialState: initialState,
  reducers: {
    updateFirebaseUser: (state, action: PayloadAction<{ firebaseUser: FirebaseUser }>) => {
      const { firebaseUser } = action.payload;

      state.status = Status.UPDATED;
      state.data = firebaseUser;
    },
    resetFirebaseUser: (state) => {
      state.status = Status.INITIAL;
      state.data = null;
    }
  }
})

export const { 
  updateFirebaseUser,
  resetFirebaseUser
} = firebaseUserSlice.actions

export default firebaseUserSlice.reducer;