import React from 'react';
import ReactDOM from 'react-dom'
import { Styles, mergeStyles } from './utils';

import './Modal.css';

interface Props {
  children?: (string | JSX.Element | null)[] | JSX.Element | JSX.Element[] | number | string | string[] | undefined | null;
  className?: string;
  isMobile?: boolean;
  centerFullScreen?: boolean;
  onRequestClose: () => void;
  passthrough?: boolean;
  style?: Styles;
  visible?: boolean;
  wrapStyle?: Styles;
  clickAwayStyle?: Styles;
}

export default function Modal ({
  children = null,
  className = '',
  isMobile = true,
  centerFullScreen = false,
  onRequestClose,
  passthrough = false,
  style = {},
  visible = false,
  wrapStyle = {},
  clickAwayStyle = {}
}: Props) {


  if (!visible) return null;

  if (passthrough) return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );

  const modalComponent = (
    <div className='NativeModalWrap' style={mergeStyles(wrapStyle)}>
      <div 
        className={`NativeModal ${className} ${visible ? 'show' : 'hide'}  ${isMobile ? 'mobile' : ''}`}
        style={{
          ...(isMobile ? {
            height: '100%', //`calc(100vh - ${NAV.HEADER.MOBILE.height}`,
            width: '100%',
          } : {}),
          ...mergeStyles(style)
        }}
        // onClick={() => setShowModal(false)}
      >
        {children}
      </div>
      <div 
        className={`ModalClickAway ${visible ? 'show' : 'hide'}`} 
        style={mergeStyles(clickAwayStyle)}
        onClick={(e) => {
          e.stopPropagation();
          onRequestClose()
        }}
      />
    </div>
  )

  if (centerFullScreen) return (
    <FullScreenPortal key='modal'>
      { modalComponent }
    </FullScreenPortal>
  )

  else return modalComponent
}

const modalRoot = document.getElementById('portal-root');

interface PortalProps {
  children?: (string | JSX.Element | null)[] | JSX.Element | JSX.Element[] | number | string | string[] | undefined | null;
}

export class FullScreenPortal extends React.Component<PortalProps> {
  private el: HTMLDivElement;

  constructor(props: Props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    modalRoot?.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot?.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.el
    );
  }
}
