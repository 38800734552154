import { Dispatch } from "@reduxjs/toolkit";
import {
  updateTotalsList,
  updateTotalsFailed,
  resetTotalsList,
  TotalOperationsAndStations,
} from "@reducers/metrics/totalOperationsAndStations";
import api from '@api';


export async function fetchTotalOperationsAndStations(dispatch: Dispatch) {
  try {
    const response = await api.request<TotalOperationsAndStations>('metrics/dashboard/totals');

    const totalOperationsAndStations = response.data;
    
    dispatch(updateTotalsList({ totalOperationsAndStations }));
  } catch (err) {
    console.error(err);

    dispatch(updateTotalsFailed());
  }
}


/**
 * Clears sales data state
 */
 export function clearTotalOperationsAndStations(dispatch: Dispatch) {
  dispatch(resetTotalsList());
}

