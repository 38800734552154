import React, { CSSProperties, useState, useEffect } from "react";
import moment from "moment-timezone";
import { Picker, StyleSheet, Option } from 'react-native';
import { RenderDateComponent, DateRange, Range, ClickOrTouch } from './calendarTypes';
import { rangeBounds, series } from './calendarUtils';
import { Year, Month } from './Period'

import { DATE_KEY } from '../../globalConstants';

import "./Calendar.css";

interface Props<BodyProps, HeaderProps, OverlayProps> {
  className?: string;
  initialDate: moment.Moment;
  initialDateRange?: DateRange;
  initialRange?: Range;
  isMobile?: boolean;
  onDateChange?: (event: React.SyntheticEvent, date: moment.Moment) => void;
  onDateClick?: (event: React.SyntheticEvent, date: moment.Moment) => void;
  onPeriodClick?: (event: React.SyntheticEvent, start: moment.Moment, end: moment.Moment) => void;
  onRangeChange?: (start: moment.Moment, end: moment.Moment) => void;
  picker?: boolean;
  preventRangeSelection?: boolean;
  renderDate?: RenderDateComponent<BodyProps>;
  renderDateProps?: BodyProps;
  renderDateHeader?: RenderDateComponent<HeaderProps>;
  renderDateHeaderProps?: HeaderProps;
  renderDateOverlay?: RenderDateComponent<OverlayProps>;
  renderDateOverlayProps?: OverlayProps;
  style?: CSSProperties;
  timeZone: string;
}

interface State {
  activeDate: moment.Moment;
  selectedRange: DateRange;
  mouseDown: boolean;
  range: Range;
  start: moment.Moment;
  current: moment.Moment;
  end: moment.Moment;
}

const rangePickerOptions: Option<Range>[] = [
  { key: 'month', label: 'Month', value: 'month' },
  { key: 'year', label: 'Year', value: 'year' },
]

const monthPickerOptions = moment
  .months()
  .map((month, i) => ({ key: month, label: month, value: i }));

const yearPickerOptions: Option<number>[] = series(
  2019,
  moment()
    .add(1, "year")
    .year()
).map((year, i) => {
  return { key: year, label: year, value: year };
})

class Calendar<BodyProps, HeaderProps, OverlayProps> extends React.PureComponent<Props<BodyProps, HeaderProps, OverlayProps>, State> {
  constructor (props: Props<BodyProps, HeaderProps, OverlayProps>) {
    super(props);
    
    const { initialDate, initialRange = 'month' } = props;

    const { start, current, end } = rangeBounds(initialDate, initialRange);

    this.state = {
      activeDate: props.initialDate,
      selectedRange: { 
        start: props.initialDateRange?.start || props.initialDate, 
        end: props.initialDateRange?.end ?? null 
      },
      mouseDown: false,
      range: initialRange,
      start,
      current,
      end
    };

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onRangeChange = this.onRangeChange.bind(this);
    this.onMonthChange = this.onMonthChange.bind(this);
    this.onYearChange = this.onYearChange.bind(this);
  }

  componentDidUpdate (prevProps: Props<BodyProps, HeaderProps, OverlayProps>, prevState: State) {
    this.handleRangeChange(prevState.range, this.state.range);
  }

  handleRangeChange (prevRange: Range, currentRange: Range) {
    if (prevRange !== currentRange) {
      const { activeDate } = this.state;

      this.updateRange(activeDate, currentRange)
    }
  }

  updateRange (date: moment.Moment, range: Range) {
    const { start, current, end } = rangeBounds(date, range);

      if (range === "month" /* || currentRange === "week" */) {
       this.props.onRangeChange?.(start, end);
      }

      this.setState({ start, current, end });
  }

  setActiveDate (activeDate: moment.Moment) {
    this.setState({ activeDate });
  }

  setSelectedRange (selectedRange: DateRange) {
    this.setState({ selectedRange });
  }

  setMouseDown (mouseDown: boolean) {
    this.setState({ mouseDown });
  }

  onMouseDown (event: ClickOrTouch, date: moment.Moment) {
    this.setMouseDown(true);
    if (event.shiftKey) {
      this.handleRange(date);
    } else {
      this.setSelectedRange({ start: date, end: date });
    }
  }

  onMouseUp (event: ClickOrTouch, date: moment.Moment) {
    const { selectedRange } = this.state;

    this.setMouseDown(false);

    if (!selectedRange.end || selectedRange.start.isSame(selectedRange.end, 'day')) {
      this.props.onDateClick?.(event, selectedRange.start.clone())
    } else {
      this.props.onPeriodClick?.(event, selectedRange.start, selectedRange.end)
    }
  }

  onMouseEnter (event: ClickOrTouch, date: moment.Moment) {
    if (this.state.mouseDown) {
      this.handleRange(date);
    }
  }

  onRangeChange (range: Range) {
    this.setState({ range });
  }

  onMonthChange (index: number) {
    const { activeDate: currentActiveDate, range } = this.state;

    const activeDate = currentActiveDate.clone().month(index);

    this.setState({ activeDate }, () => this.updateRange(activeDate, range));
  }

  onYearChange (index: number) {
    const { activeDate: currentActiveDate, range } = this.state;

    const activeDate = currentActiveDate.clone().year(index);

    this.setState({ activeDate }, () => this.updateRange(activeDate, range));
  }

  handleRange (date: moment.Moment) {
    const { preventRangeSelection } = this.props;
    const { start, end } = this.state.selectedRange;
    
    let newStart = null, newEnd = null;

    if (preventRangeSelection) {
      newStart = date.clone();
      newEnd = date.clone();
    } else {

      if (date.isSameOrBefore(start)) {
        newStart = date.clone();
  
        if (!end) {
          newEnd = start.clone();
        }
      }
  
      if (date.isSameOrAfter(start)) {
        newEnd = date.clone();
      }
    }

    if (newStart || newEnd) {
      const newRange = { start: newStart || start, end: newEnd || end }
      this.setSelectedRange(newRange)

      return newRange;
    }
  }

  render () {
    const { 
      className = "",
      isMobile = false,
      picker = false,
      renderDate,
      renderDateProps,
      renderDateHeader,
      renderDateHeaderProps,
      renderDateOverlay,
      renderDateOverlayProps,
      style = {},
      timeZone = "America/Los_Angeles",
    } = this.props;
    const { 
      activeDate,
      selectedRange,
      range,
      start,
      current,
      end
    } = this.state;
  
    let Range;
    if (range === "year") Range = Year;
    // else if (range === "week") Range = Week;
    else Range = Month;
  
    return (
      <div className={`Calendar ${className} ${picker ? 'picker' : ''} ${isMobile ? 'mobile' : ''}`} style={style}>
        <header className='Selectors'>
          <div className='SelectorRow'>
            { !picker && 
              <Picker
                selectedValue={range}
                clickaway
                options={rangePickerOptions}
                onValueChange={this.onRangeChange}
                clickawayStyle={styles.pickerClickawayStyle}
                itemStyle={styles.narrowPickerItemStyle}
              />
            }
            
            <Picker
              selectedValue={activeDate.month()}
              clickaway
              options={monthPickerOptions}
              onValueChange={this.onMonthChange}
              clickawayStyle={styles.pickerClickawayStyle}
              itemStyle={styles.widePickerItemStyle}
            />
  
  
            <Picker
              selectedValue={activeDate.year()}
              clickaway
              options={yearPickerOptions}
              onValueChange={this.onYearChange}
              clickawayStyle={styles.pickerClickawayStyle}
              itemStyle={styles.narrowPickerItemStyle}
            />
          </div>
        </header>
  
        <div className='PeriodWrap' >
          <Range
            selectedRange={selectedRange}
            timeZone={timeZone}
            start={start}
            current={current}
            end={end}
            renderDate={renderDate}
            renderDateProps={renderDateProps}
            renderDateHeader={renderDateHeader}
            renderDateHeaderProps={renderDateHeaderProps}
            renderDateOverlay={renderDateOverlay}
            renderDateOverlayProps={renderDateOverlayProps}
            onMouseDown={this.onMouseDown}
            onMouseEnter={this.onMouseEnter}
            onMouseUp={this.onMouseUp}
            viewMode={picker ? 'picker' : range }
          />
        </div>
      </div>
    );
  }
};

export default Calendar;

const styles = StyleSheet.create({
  pickerClickawayStyle: {
    zIndex: 5
  },
  narrowPickerItemStyle: {
    minWidth: 60
  },
  widePickerItemStyle: { 
    minWidth: 100 
  }
})