export enum Stage {
  ALL = 'All',
  WEEK_1 = 'Week 1',
  WEEK_2 = 'Week 2',
  PAST_DUE = 'Past Due',
  CONVERTED = 'Converted',
  CANCELED = 'Canceled',
  CANCEL_AT_PERIOD_END = 'Cancel At Period End',
  CANCELLATION_REASONS = 'Cancellation Reasons',
}

export type StageConfig = {
  id: Stage;
  label: string;
  color: string;
}

export const stages: StageConfig[] = [
  {
    id: Stage.ALL,
    label: 'All Signups',
    color: '#006DAB'
  },
  {
    id: Stage.WEEK_1,
    label: 'Week 1',
    color: '#EA7D14'
  },
  {
    id: Stage.WEEK_2,
    label: 'Week 2',
    color: '#EA7D14'
  },
  {
    id: Stage.PAST_DUE,
    label: 'Past Due',
    color: 'rgb(185, 12, 15)'
  },
  {
    id: Stage.CONVERTED,
    label: 'Converted',
    color: 'rgb(22, 142, 50)'
  },
  {
    id: Stage.CANCELED,
    label: 'Canceled',
    color: '#666'
  },
  {
    id: Stage.CANCEL_AT_PERIOD_END,
    label: 'Cancel At Period End',
    color: '#666'
  },
  {
    id: Stage.CANCELLATION_REASONS,
    label: 'Cancellation Reasons',
    color: '#9E2DB6'
  }
];