import React from 'react';
import { YAxis, XAxis, LineChart, Line, ResponsiveContainer, Tooltip } from 'recharts';

import './LightLineChart.css';

export default class LightLineChart extends React.Component {
  render () {
    return (
      <div className='LineChart'>
        <ResponsiveContainer
          width={this.props.width}
          height={this.props.height}>
          <LineChart
            data={this.props.data}>
            <XAxis dataKey='name' hide />
            {
              this.props.enableY === true
                ? <YAxis />
                : null
            }
            <Tooltip itemSorter={this.props.itemSorter || undefined} />
            {
              this.props.specs.map(spec => {
                return (
                  <Line {...spec} />
                );
              })
            }
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
