import React from 'react';

// import api from '../../utils/api';
import StationISPListItem from './StationISPListItem';
import Spinner from '../common/Spinner';
import ScrollView from '../Page/ScrollView';
import {connect, ConnectedProps} from 'react-redux';
import {RootState} from '@reducers';
import {ConsoleStation} from '@reducers/stations/consoleStations';
import * as selectors from '@selectors';
import * as actions from '@actions';
import './StationISPList.css';

type Props = {
  height: number;
  station: ConsoleStation;
} & PropsFromRedux;

type State = {
  fetching: boolean;
}

class StationISPList extends React.Component<Props, State> {
  constructor (props: Props) {
    super(props);

    this.state = {
      fetching: false,
    }

    this.init = this.init.bind(this);
    this.getAllISPs = this.getAllISPs.bind(this);
  }

  async componentDidMount () {
    await this.init();
  }

  async componentDidUpdate (prevProps: Props) {
    if (prevProps.station !== this.props.station) {
      await this.init();
    }
  }

  async init () {
    this.setState({ fetching: true }, async () => {
      await this.getAllISPs();

      this.setState({ fetching: false });
    });
  }

  async getAllISPs () {
    const { station, dispatch } = this.props;

    if (!station || !station.id) return null;

    await actions.stations.stationsIsps.fetchStationsIsps(dispatch, `${station.id}`);

    // const res = await api.request(`stations/isps/${station.id}`);
    // return res.data;
  }

  render () {
    const { fetching } = this.state;
    const { station, liveISPList, height, stationsIsps } = this.props;

    const isps = stationsIsps[station.id]

    return (
      <div className='StationISPList'>
        {
          !fetching && isps
            ? <ScrollView direction='vertical' height={height}>
              {
                liveISPList && isps
                  .filter(isp => {
                    return !!isp.firebaseId && !!liveISPList[isp.firebaseId];
                  }).map((isp, i) => {
                    const profile = liveISPList[isp.firebaseId];

                    return (
                      <StationISPListItem key={`withLiveISP-${i}`} profile={profile} isp={isp} />
                    );
                  })
              }

              {
                isps
                  .filter(isp => {
                    return !liveISPList || !isp.firebaseId;
                  })
                  .map((isp, i) => {
                    return (
                      <StationISPListItem key={`withoutLiveISP-${i}`} isp={isp} />
                    );
                  })
              }
            </ScrollView>
            : null
        }

        {
          !!fetching && 
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spinner />
          </div>
        }
      </div>
    );
  }
}

function mapStateToProps (state: RootState) {
  return {
    liveISPList: selectors.liveIspList.selectLiveIspList(state),
    stationsIsps: selectors.stations.stationsIsps.selectStationIspsLists(state),
  }
}

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(StationISPList);