import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Feedback = {
  [reason: string]: string | true;
}

export type IspCancellationReason = {
  FedExId: string;
  cancelledAt: number;
  feedback: Feedback;
  operationId: string;
  subscribedRoutes: number;
  subscriptionId: string;
  uid: string;
}

type IspsCancellationReasons = {
  [ispId: string]: IspCancellationReason
}

export type CancellationReasons = {
  [date: string]: IspsCancellationReasons;
}

export enum Status {
  INITIAL = 'INITIAL',
  PENDING = 'PENDING',
  UPDATED = 'UPDATED',
  FAILED = 'FAILED'
}

export type State = {
  data: CancellationReasons;
  status: Status;
};

const initialState: State = {
  data: {},
  status: Status.INITIAL,
};

const cancellationReasonsSlice = createSlice({
  name: 'cancellationReasons',
  initialState: initialState,
  reducers: {
    updateCancellationReasons: (state, action: PayloadAction<{ reasons: CancellationReasons }>) => {
      const { reasons } = action.payload;

      state.status = Status.UPDATED;
      state.data = reasons;
    },
    resetCancellationReasons: (state) => {
      state.status = Status.INITIAL;
      state.data = {};
    }
  }
})

export const { 
  updateCancellationReasons,
  resetCancellationReasons
} = cancellationReasonsSlice.actions

export default cancellationReasonsSlice.reducer;