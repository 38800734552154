import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import PageWrapper from '../Page/PageWrapper';
import PageTitle from '../Page/PageTitle';
import Search from '../Search/Search';

import './UsersDash.css';

type Props = {
  history: RouteComponentProps['history'];
}

export default function UsersDash({history}: Props) {
  return (
    <div className='UsersDash'>
      <PageWrapper>
        <PageTitle>Users</PageTitle>

        <Search
          history={history}
          usersOnly={true}
          title='Search Users'
          placeholder='First and Last Name, FedExId, Phone Number & Email'
        />
      </PageWrapper>
    </div>
  )
}