import React from 'react';

import './style/shared.css';
import './style/contacts.css';
import SectionTitle from '../../Page/SectionTitle';
import ContactForm from './ContactForm';
import InputSelect from '../../Page/InputSelect';
import InputText from '../../Page/InputText';
import SectionButton from '../../Page/SectionButton';
import { role, time, method } from '../../../utils/contactRules';
import api from '../../../utils/api';

export default class Contacts extends React.Component {
  constructor (props) {
    super(props);

    const contacts = props.contacts || [];
    const contactIndex = this.findActiveIndex(contacts);

    this.state = {
      activeIndex: contactIndex,
      newContact: false
    };

    this.togglePrimary = this.togglePrimary.bind(this);
  }

  findActiveIndex (contacts) {
    let contactIndex = 0;
    let foundPrimary = false;

    for (let i = 0; i < contacts.length; i++) {
      const contact = contacts[i];

      if (contact.primary === true) {
        contactIndex = i;
        foundPrimary = true;

        break;
      }
    }

    if (foundPrimary === false) {
      for (let i = 0; i < contacts.length; i++) {
        const contact = contacts[i];

        if (contact.owner === true) {
          contactIndex = i;

          break;
        }
      }
    }

    return contactIndex;
  }

  componentDidUpdate (prevProps) {
    if (prevProps.contacts !== this.props.contacts) {
      const nextActiveIndex = this.findActiveIndex(this.props.contacts);

      this.setState({ activeIndex: nextActiveIndex });
    }
  }

  togglePrimary (activeContact) {
    const { id } = this.props.isp;
    const isPrimary = activeContact.primary;
    let body = { primary: true };

    if (isPrimary) {
      body.primary = false;
    }

    api.patch(`contacts/isps/${id}/primary/${activeContact.id}`, body)
      .then(data => {
        this.props.loadContacts();
      });
  }

  render () {
    const { activeIndex } = this.state;
    let activeContact = null;

    if (this.props.contacts && this.props.contacts.length) {
      activeContact = this.props.contacts[activeIndex];
    }

    return (
      <div className={`Contacts${this.props.editingContact ? ' expand' : ''}`}>
        <SectionTitle
          title='People'
          titleContent={
            !this.state.newContact && this.props.contacts && this.props.contacts.length
              ? <div style={{ display: 'flex' }}>
                <InputSelect
                  id='person'
                  selectStyle={{ height: '25px', fontSize: '0.85em' }}
                  labelStyle={{ margin: 0 }}
                  value={activeIndex}
                  onChange={(e) => {
                    const val = parseInt(e.target.value, 10);

                    this.setState({ activeIndex: val });
                  }}
                  options={this.props.contacts.map((contact, i) => {
                    return {
                      value: i,
                      name: `${contact.firstName || ''} ${contact.lastName || ''}`
                    }
                  })}
                />

                <div className='header' style={{ marginLeft:'10px' }}>
                  <span>
                    {activeContact.owner === true ? 'Owner' : null}
                    {activeContact.owner === true && activeContact.role > 0 ? ' / ' : null}
                    {activeContact.role > 0 ? role[activeContact.role] : ''}
                  </span>
                </div>

                <div className='primary' style={{ marginLeft: '10px' }}>
                  <InputText
                    id='primary'
                    type='checkbox'
                    label='Primary'
                    value={activeContact.primary === true}
                    checked={activeContact.primary === true}
                    style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-end', alignItems: 'center', height: '30px'  }}
                    inputStyle={{ width: '25px', margin: 0 }}
                    labelStyle={{ marginLeft: '5px', margin: '0' }}
                    onChange={() => this.togglePrimary(activeContact)}
                  />
                </div>
              </div>
              : null
          }>

          <div style={{ display: 'flex' }}>
            {
              !this.props.editingContact || !activeContact
                ? <SectionButton
                  style={{ backgroundColor: 'white' }}
                  onClick={() => {
                    this.setState(prevState => {
                      return {
                        newContact: !prevState.newContact
                      };
                    }, () => {
                      this.props.toggleProp('editingContact');
                    });
                  }}>
                  {
                    this.props.editingContact
                      ? <p style={{ color: 'white' }}>Abort</p>
                      : <p>New</p>
                  }
                </SectionButton>
                : null
            }

            {
              activeContact
                ? <SectionButton
                  style={{ backgroundColor: this.props.editingContact ? '#B90C0F' : 'white', marginLeft: '5px' }}
                  onClick={() => this.setState({ newContact: false }, () => {
                    this.props.toggleProp('editingContact');
                  })}>
                  {
                    this.props.editingContact
                      ? <p style={{ color: 'white' }}>Abort</p>
                      : <p>Edit</p>
                  }
                </SectionButton>
                : null
            }
          </div>
        </SectionTitle>

        {
          !this.props.editingContact
            ? this.props.contacts && this.props.contacts.length
              ? <div className='section'>
                <div className='section info'>
                  <h4>Contact</h4>

                  <div className='data'>
                    <p>Phone</p>
                    <p>{activeContact.phone || 'N/A'}</p>
                  </div>

                  <div className='data'>
                    <p>Secondary Phone</p>
                    <p>{activeContact.secondaryPhone || 'N/A'}</p>
                  </div>

                  <div className='data'>
                    <p>Email</p>
                    <p>{activeContact.email || 'N/A'}</p>
                  </div>

                  <div className='data'>
                    <p>Secondary Email</p>
                    <p>{activeContact.secondaryEmail || 'N/A'}</p>
                  </div>

                  <div className='data'>
                    <p>Preferred Method</p>
                    <p>{method[activeContact.preferredMethod] || 'N/A'}</p>
                  </div>

                  <div className='data'>
                    <p>Preferred Time</p>
                    <p>{time[activeContact.preferredTime] || 'N/A'}</p>
                  </div>
                </div>

                <div className='section info'>
                  <h4>App Info</h4>

                  <div className='data'>
                    <p>Google Play ID</p>
                    <p>{activeContact.playId || 'N/A'}</p>
                  </div>

                  <div className='data'>
                    <p>Apple ID</p>
                    <p>{activeContact.appleId || 'N/A'}</p>
                  </div>
                </div>
              </div>
              : <div className='default'>
                <p>No contacts have been added for this ISP.</p>
              </div>
            : <ContactForm
              contact={activeContact}
              createContact={this.props.createContact}
              updateContact={this.props.updateContact}
              deleteContact={this.props.deleteContact}
              endNewContact={() => this.setState({ newContact: false })}
              toggleProp={this.props.toggleProp}
              isNew={this.state.newContact === true}
            />
        }
      </div>
    )
  }
}
