/**
 * Creates sets of batches according to the batch size
 *
 * [1, 2, 3, 4, 5] -> [[1, 2], [3, 4], [5]]
 * @param keys
 * @param batchSize
 * @returns
 */
export function createBatches<T>(items: T[], batchSize = 100): T[][] {
  const remainingItems = [...items];
  const batches: T[][] = [];

  while (remainingItems.length) {
    const batchKeys: T[] = [];

    const remainingLength =
      batchSize > remainingItems.length ? remainingItems.length : batchSize;

    for (let i = 0; i < remainingLength; i++) {
      const key = remainingItems[i];

      batchKeys.push(key);
    }

    batches.push(batchKeys);
    remainingItems.splice(0, batchSize);
  }

  return batches;
}
