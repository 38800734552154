import React from 'react';
import moment from 'moment-timezone';

import './ISPCard.css';
// import salesCycleStatus, { getDisplayName, getDisplayColor } from '../../utils/salesCycleStatus';
// import Icon from '../Icon/Icon';
import { getLastActiveDate } from '../../utils/isp';

export default class ISPCard extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      expandTag: false
    }
  }

  render () {
    const { groundRoutes = 0, hdRoutes = 0, suppRoutes = 0, additionalRoutes = 0 } = this.props.isp;

    const projectedConversionRate = !Number.isNaN(parseInt(this.props.isp.projectedConversionRate, 10))
      ? this.props.isp.projectedConversionRate
      : 100;
    const projectedAccountValue = (groundRoutes + hdRoutes + suppRoutes + additionalRoutes) * (projectedConversionRate / 100) * 20;
    const today = moment.tz('America/Los_Angeles').format('YYYY-MM-DD');
    const lastActiveDate = getLastActiveDate(this.props.isp.firebaseId, this.props.usage);

    return (
      <div
        className={`ISPCard ${this.props.cardClasses || ''}`}
        onClick={() => this.props.setActiveItem(this.props.isp, 'isp')}>
        <div className='card-container'>
          <div className='section'>
            <div className='isp-name'>
              <p>
                {this.props.isp.entityName}
              </p>
            </div>

            <div className='account-value'>
              <p>${projectedAccountValue || 0}</p>
            </div>
          </div>

          <div className='section'>
            <div className='isp-activity'>
              {
                lastActiveDate && lastActiveDate === today
                  ? <div style={{ height: '10px', width: '10px', borderRadius: '5px', backgroundColor: 'green' }}/>
                  : lastActiveDate
                    ? <p style={{ color: '#EA7D14' }}>{moment(lastActiveDate, 'YYYY-MM-DD').format('MM-DD-YYYY')}</p>
                    : null
              }
            </div>

            <div className='sales-dev'>
              {
                this.props.isp.tags && this.props.isp.tags.length
                  ? this.props.isp.tags.length < 2
                    ? this.props.isp.tags.map((tag, i) => {
                      if (i > 1) return null;

                      return (
                        <div className='tag' onClick={(e) => {
                          e.stopPropagation();

                          this.props.setActiveItem(this.props.isp, 'tag', tag);
                        }}>
                          <p>{tag.name}</p>
                        </div>
                      )
                    })
                    : !this.state.expandTag
                      ? <div
                        className='tag tag-circle'
                        onClick={(e) => {
                          e.stopPropagation();

                          this.setState({ expandTag: true });
                        }}>
                        <p>{this.props.isp.tags.length}</p>
                      </div>
                      : <div className='tag-section'>
                        {/* <span style={{ whiteSpace: 'none' }} onClick={() => this.setState({ expandTag: false })}>
                          <Icon name='close' size={10} color='black' />
                        </span> */}

                        {
                          this.props.isp.tags.map(tag => {
                            return (
                              <div className='tag' onClick={(e) => {
                                e.stopPropagation();

                                this.props.setActiveItem(this.props.isp, 'tag', tag);
                              }}>
                                <p>{tag.name}</p>
                              </div>
                            )
                          })
                        }
                      </div>
                  : null
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
