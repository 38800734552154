import {createSelector} from 'reselect';
import moment from 'moment-timezone';
import {selectTimeZone} from '@selectors/baseSelectors';

/**
 * Selects current day based upon timeZone
 */
const selectToday = createSelector([selectTimeZone], (timeZone) => {
  const today = moment.tz(timeZone).format(window.DATE_KEY);

  return today;
});

/**
 * Selects previous day based upon timeZone
 */
const selectYesterday = createSelector([selectTimeZone], (timeZone) => {
  const yesterday = moment
    .tz(timeZone)
    .subtract(1, 'day')
    .format(window.DATE_KEY);

  return yesterday;
});

export {selectToday, selectYesterday};
