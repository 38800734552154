import React from 'react';
import './BalanceHistory.css';
import { Link } from 'react-router-dom';

import moment from 'moment';

import Section from '../Page/Section';
import CurrencyIcon from './CurrencyIcon';
import PageWrapper from '../Page/PageWrapper';
import PageTitle from '../Page/PageTitle';
import Button from '../Page/Button';
import api from '../../utils/api';
import Card from './Card/Card';
import Bill from './Bill/Bill';
import Icon from '../Icon/Icon';

function BalanceHistorySummary ({ balanceHistory }) {
  return (
    <Section
      title='Balance History'
      loading={!balanceHistory}
      bottomContent={
        <Link to='/subscriptions/balance/history' className='fullSectionLink'>
          Full Balance History
        </Link>
      }
    >
      <div className='BalanceHistorySummary'>
        {
          balanceHistory.filter(inv => inv.paid).map(transaction =>
            <Transaction key={transaction.id} transaction={transaction} />
          )
        }
      </div>
    </Section>
  );
}

function FullBalanceHistory ({ balanceHistory, balanceHistoryIncomplete, loadMoreBalanceHistory }) {
  return (
    <div className='FullBalanceHistory'>
      <PageWrapper>
        <PageTitle>Balance History</PageTitle>

        <Section>
          {
            balanceHistory.filter(inv => inv.paid).map(transaction =>
              <Transaction key={transaction.id} transaction={transaction} />
            )
          }
        </Section>

        {!!balanceHistoryIncomplete &&
          <Button color='#006DAB' onClick={loadMoreBalanceHistory}>Load More</Button>
        }
      </PageWrapper>
    </div>
  );
}

class TransactionDetails extends React.Component {
  constructor (props) {
    super(props);

    this.state = {};
  }

  componentDidMount () {
    const { match } = this.props;
    const { transactionId } = match.params;
    
    api.getStripeBalanceHistoryTransaction(transactionId)
      .then(transaction => {
        this.setState({ transaction });
      });
  }

  render () {
    const { transaction } = this.state;

    return (
      <div className='TransactionDetails'>
        <PageWrapper>
          <PageTitle>Transaction Details</PageTitle>

          { !!transaction &&
            <Section>
              <Transaction transaction={transaction} />
              
              {!!transaction.charge &&
                <Card card={transaction.charge.source} />
              }
              
              {!!transaction.charge && !!transaction.charge.invoice &&
                <Bill nextInvoice={transaction.charge.invoice} />
              }
            </Section>
          }
        </PageWrapper>
      </div>
    );
  }
}

function Transaction ({ transaction, thisSubscriptionId, liveISPList }) {
  const { id, created, currency, description, amount, fee, net, type, balance_transaction, refunds, failure_code, failure_message, invoice } = transaction;
  const { subscription } = (invoice || {});
  const subscriptionId = (subscription || {}).id;
  const subscriptionMatches = !thisSubscriptionId || (subscriptionId && subscriptionId === thisSubscriptionId);
  const subscriptionISPID = !subscriptionMatches && ((subscription || {}).metadata || {}).ISPID;
  const operationData = subscriptionISPID && liveISPList && liveISPList[subscriptionISPID];

  console.log(operationData)

  const refundedValue = ((refunds || {}).data || []).length
    ? refunds.data.reduce((acc, refund) => {
      const { amount } = refund;
      acc += amount;
      return acc;
    }, 0)
    : null

  const failureText = failure_code && failure_message
    ? `FAILED: ${failure_message || ''} (${failure_code || 'X'})`
    : null;

  return (
    <div className={`Transaction${!subscriptionMatches ? ' otherSubscription' : ''}`}>
      <CurrencyIcon currency={currency} />

      <div className='body'>
        <Link to={`/subscriptions/balance/history/${balance_transaction || id}`} className='id'>{ id }</Link>

        <div className='description'>{type} { description ? ` - ${description}` : '' }</div>

        <div style={{display: 'flex', alignItems: 'center'}}>
          <div className='timestamp'>
            <div className='timeFrom'>{ moment(created * 1000).fromNow() }</div>
            <div className='exactDate'>{ moment(created * 1000).format('YYYY-M-D HH:mm:ss') }</div>
          </div>

          {
            !subscriptionMatches &&
              <div className='currentOp'>
                <Icon name='close' color='white' size='12px' />
                <p>{operationData ? operationData.CSPName : 'Unknown Operation'}</p>
              </div>
          }

          { !!failureText &&
            <div className='failureMessage'>{ failureText }</div>
          }
        </div>
      </div>

      <div className='valueWrap'>
        <div className={`value${amount < 0 ? ' negative' : ''}${fee ? ' sub' : ''}`}>{ _centsToDollars(amount) }</div>

        { !!fee && <div className='value sub'>{ _centsToDollars(0 - fee) }</div>}
        { !!refundedValue && <div className='value sub'>RF { _centsToDollars(0 - refundedValue) }</div>}
        { !!fee && <div className={`value${net < 0 ? ' negative' : ' net'}`}>{ _centsToDollars(net) }</div>}
      </div>
    </div>
  );
}

function _centsToDollars(cents) {
  return `${(cents / 100).toFixed(2)}`;
}

export {
  BalanceHistorySummary,
  FullBalanceHistory,
  TransactionDetails,
  Transaction
}
