import React from "react";
import { isp } from "@packageroute/types-firebase";
import InputSelect, { SelectOption } from "../../Page/InputSelect";
import "./TransferOwnershipModal.css";


type Props = {
  profile: isp.profile.Profile;
  selectedOwnerUid: string | null;
  onNewOwnerSelectionChange: (newOwnerFedExId: string) => void;
};

export default function NewOwnerPicker({
  profile,
  onNewOwnerSelectionChange,
  selectedOwnerUid,
}: Props) {
  const driversByUid = Object.values(
    profile.drivers
  ).reduce<isp.profile.drivers.Drivers>((acc, driver) => {
    if (driver.uid) {
      acc[driver.uid] = driver;
    }

    return acc;
  }, {});

  const selectionOptions = Object.keys(driversByUid)
    .map<SelectOption | null>((driverUid) => {
      const driver = driversByUid[driverUid];
      const { FedExId, first, last, active, uid } = driver;

      if (active === false || !uid || profile.ownerUID === uid) {
        return null;
      }

      return {
        name: `${FedExId} - ${first} ${last}`,
        value: uid,
      };
    })
    .filter((option): option is SelectOption => !!option);

  selectionOptions.unshift({ name: "none", value: "" });

  return (
    <div className="NewOwnerPicker">
      <InputSelect
        value={selectedOwnerUid || profile?.ownerUID || null}
        selectStyle={{ flex: 1, height: 40 }}
        labelStyle={{ margin: 0 }}
        options={selectionOptions}
        onChange={(e) => {
          const { target } = e;
          const { value } = target;

          onNewOwnerSelectionChange(value);
        }}
      />
    </div>
  );
}
