import React from "react";
import { Link } from "react-router-dom";

import Icon from "../Icon/Icon";

import "./IspLink.css";


export default function IspLink({ isp, activeIsp = false }) {
  return (
    <div
      className={`ispLink ${isp && isp.ISPID ? "clickable" : ""} ${activeIsp ? "active" : ""}`}
    >
      {isp && isp.ISPID ? (
        <Link to={`/isps/${isp.id}`}>
          <div className="icon">
            <Icon name="truck" size={20} />
          </div>

          <p>{isp && isp.CSPName}</p>
        </Link>
      ) : (
        <div className="descriptive">
          <Icon name="truck" size={20} color="#006DAB" />

          <p>{isp.CSPName}</p>
        </div>
      )}
    </div>
  );
}
