import React from 'react';

import { SlideUp } from '../common/animations';

import './ActionSheet.css';

export default class ActionSheet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: props.visible || false
    }
  }

  show = () => this.setState({ visible: true });

  hide = () => this.setState({ visible: false });

  toggle = () => this.setState({ visible: !this.state.visible });

  handlePress = (index) => {
    const { onClick, onPress } = this.props;
    
    const fn = onClick || onPress || (() => {});

    this.hide();
    
    fn(index);
  }
  
  render() {
    const {
      responsive = { isMobile: true },
      ref = null,
      className = '',
      style = {},
      headerStyle = {},
      bodyStyle = {},
      footerStyle = {},
      onClick = null,
      onPress = null,
      options = [],
      buttonStyle = {},
      cancelButtonIndex = null,
      cancelButtonStyle = {},
      destructiveButtonIndex = null,
      destructiveButtonStyle = {},
      title = '',
      message = '',
      tintColor = ''
    } = this.props;

    const actionOptions = options.map((option, i) => 
      <ActionOption 
        key={i}
        index={i}
        label={option} 
        isDestructive={destructiveButtonIndex === i}
        isCancel={cancelButtonIndex === i}
        style={buttonStyle}
        cancelStyle={cancelButtonStyle}
        destructiveStyle={destructiveButtonStyle}
        onPress={this.handlePress} 
      />
    );

    if (this.state.visible) {
      return (

        <SlideUp 
          className='ActionSheet' 
          style={style} 
          ref={ref} 
          visible={this.state.visible}
          from={80}
          to={300}
        >
          { title || message ? 
            <header style={headerStyle}>
              { title ? <h1>{title}</h1> : null }
              { message ? <p>{message}</p> : null }
            </header>
            : null
          }
          <nav style={bodyStyle}>
            {actionOptions}
          </nav>
        </SlideUp>
        
      )
    } else {
      return null;
    }
  }
}

function ActionOption({
  index,
  style = {},
  isCancel,
  cancelStyle,
  isDestructive,
  destructiveStyle,
  label = "",
  onPress = null
}) {

  return (
    <div
      className={
        `ActionOption ${
        isCancel ? "cancelOption" : ""
        } ${
        isDestructive ? "destructiveOption" : ""
        }`
      }
      style={{
        ...style,
        ...(isCancel ? cancelStyle : {}),
        ...(isDestructive ? destructiveStyle : {})
      }}
      onClick={() => onPress(index)}
    >
      <p>{label}</p>
    </div>
  );
}