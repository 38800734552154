import React from 'react';
import { mergeStyles, Styles } from './utils';
import './Text.css';

interface Props {
  className?: string;
  style?: Styles;
  allowFontScaling?: boolean;
  tag?: string;
  type?: string;
  children?: any;

  /**
   * Prop for native compatibility
   * Need to implement functionality
   */
  numberOfLines?: any;
  /**
   * Prop for native compatibility
   * Need to implement functionality
   */
  ellipsizeMode?: any;
}

export default function Text({
  className = "",
  style = {},
  allowFontScaling = false,
  tag = "",
  type = "",
  children = ''
}: Props) {
  const cn = `Text ${className}`;

  const mergedStyles = mergeStyles(style);
  
  switch (tag || type) {
    case "p": return <p className={cn} style={mergedStyles}>{children}</p>;
    case "span": return <span className={cn} style={mergedStyles}>{children}</span>;
    case "code": return <code className={cn} style={mergedStyles}>{children}</code>;
    case "h1": return <h1 className={cn} style={mergedStyles}>{children}</h1>;
    case "h2": return <h2 className={cn} style={mergedStyles}>{children}</h2>;
    case "h3": return <h3 className={cn} style={mergedStyles}>{children}</h3>;
    case "h4": return <h4 className={cn} style={mergedStyles}>{children}</h4>;
    case "h5": return <h5 className={cn} style={mergedStyles}>{children}</h5>;
    case "h6": return <h6 className={cn} style={mergedStyles}>{children}</h6>;
    default: return <p className={cn} style={mergedStyles}>{children}</p>;
  }
}