import { createSelector } from 'reselect';
import moment from 'moment-timezone';
import { RootState } from "@reducers";
import { Status, TrialSubscription } from "@reducers/subscriptions/trials";
import { selectTimeZone } from '../baseSelectors';

const selectTrialSubscriptions = (state: RootState) =>
  state.subscriptions.trials.data;
  
const selectTrialSubscriptionsLoaded = (state: RootState) =>
  state.subscriptions.trials.status === Status.UPDATED;

const selectTrialDailyTotals = createSelector(
  [selectTrialSubscriptions, selectTimeZone],
  (trials, timeZone) => {
    const trialsByDate = trials.map<[string, TrialSubscription]>(trial => {
      const startAt = trial.trial_start! * 1000;
      const startDate = moment.tz(startAt, timeZone).format(DATE_KEY);

      return [startDate, trial];
    });

    const dailyTotals = trialsByDate.reduce<Record<string, number>>((acc, [date, sub]) => {
      if (!acc[date]) acc[date] = 0;

      acc[date] += 1;

      return acc;
    }, {});

    return dailyTotals;
  }
);

export { selectTrialSubscriptions, selectTrialSubscriptionsLoaded, selectTrialDailyTotals };
