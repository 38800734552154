import React from 'react';

import Icon from '../Icon/Icon';
import './MessagingSteps.css';

export default function MessagingSteps (props) {
  const { steps, completedSteps, activeStep, finished } = props;
  const highestCompleted = completedSteps.reduce((acc, step) => {
    if (step > acc) {
      acc = step;
    }

    return acc;
  }, 0);

  return (
    <div className='MessagingSteps'>
      {
        steps.sort((a, b) => a.order - b.order).map((step, i) => {
          const enabled = step.order - 1 <= highestCompleted;
          const active = activeStep.id === step.id

          if (finished === true && step.id !== 'SEND') {
            return null;
          }

          return (
            <div
              key={`messaging-step-${i}`}
              className={`step ${active ? 'active' : ''} ${enabled ? 'enabled' : ''} ${finished ? 'complete' : ''}`}
              onClick={finished ? props.reset : enabled ? () => props.setActiveStep(step) : null}>
              <div className='progress'>
                {
                  completedSteps.includes(step.order)
                    ? <Icon
                      name='check'
                      size={20}
                      color={active ? 'white' : 'rgb(22, 142, 50)'}
                    />
                    : <Icon
                      name={finished ? 'refresh' : step.icon}
                      size={20}
                      color={
                        enabled
                          ? active
                            ? 'white'
                            : 'black'
                          : '#DDD'
                      }
                    />
                }
              </div>
              <p>{!finished ? step.name : 'Send Another'}</p>
            </div>
          );
        })
      }
    </div>
  );
}
