import React, { CSSProperties } from 'react';

import './TextInput.css';

interface Props {
  className?: string;
  label?: string | null;
  labelStyle?: CSSProperties;
  value?: string;
  onChangeText?: (value: string) => void;
  placeholder?: string;
  editable?: boolean;
  readOnly?: boolean;
  locked?: boolean;
  required?: boolean;
  onClick?: (event?: React.SyntheticEvent) => void;
  onFocus?: (event?: React.SyntheticEvent) => void;
  onBlur?: (event?: React.SyntheticEvent) => void;
  maxLength?: number;
  style?: CSSProperties;
  keyboardType?: string;
  textAlignVertical?: boolean;
  autoComplete?: boolean;
  autoFocus?: boolean;
  multiline?: boolean;
  numberOfLines?: number;
}

type InputEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

export default class TextInput extends React.Component<Props> {
  textInputRef?: React.RefObject<HTMLInputElement | HTMLTextAreaElement>;

  constructor(props: Props) {
    super(props);

    this.textInputRef = React.createRef();
  }

  handleChange = (e: InputEvent) => {
    e.preventDefault();

    try {
      if (this.props.onChangeText) {
        this.props.onChangeText(e.target.value);
      } else {
        console.warn('TextInput: Missing onChangeText handler')
      }
    } catch (err) {
      console.error(err);
    }
  };

  onClickHandler = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!this.props.readOnly && this.textInputRef && this.textInputRef.current)
      this.textInputRef.current.focus();

    if (this.props.onClick) this.props.onClick();
  };

  mapKeyboardTypeToInputType = (keyboardType: string) => {
    switch (keyboardType) {
      case "default":
        return "text";
      case "email-address":
        return "email";
      case "numeric":
      case "number-pad":
      case "decimal-pad":
        return "number";
      case "phone-pad":
        return "tel";
    }
  }

  render() {
    const {
      className = "",
      label = null,
      labelStyle = {},
      value = "",
      onChangeText = () => {},
      placeholder = "",
      editable = true,
      readOnly = false,
      locked = false,
      required = false,
      onFocus = () => {},
      onBlur = () => {},
      maxLength,
      style = {},
      keyboardType = "default",
      textAlignVertical = false,
      autoComplete = false,
      autoFocus = false,
      multiline = false,
      numberOfLines = 4,
    } = this.props;

    const type = this.mapKeyboardTypeToInputType(
      keyboardType
    );

    return (
      <React.Fragment>
        {label ? (
          <label
            className="TextInputLabel"
            htmlFor={`${className} TextInput`}
            style={labelStyle}
          >
            {label}
          </label>
        ) : null}
        { multiline ?
          <textarea
            id={`${className} TextInput`}
            className={`${className} TextInput`}
            type={type}
            // @ts-ignore
            style={{
              padding: 5,
              ...style,
              ...(textAlignVertical ? {verticalAlign: textAlignVertical} : {})
            }}
            name="textInput"
            value={value}
            disabled={
              !editable || readOnly || locked
            }
            onClick={this.onClickHandler}
            onFocus={onFocus}
            onBlur={onBlur}
            // @ts-ignore
            ref={this.textInputRef}
            maxLength={maxLength}
            rows={numberOfLines}
            wrap='soft'
            onChange={this.handleChange}
            placeholder={placeholder}
          />
          :
          <input
            id={`${className} TextInput`}
            className={`${className} TextInput`}
            type={type}
            style={style}
            name="textInput"
            value={value}
            disabled={
              !editable || readOnly || locked
            }
            onClick={this.onClickHandler}
            onFocus={onFocus}
            onBlur={onBlur}
            // @ts-ignore
            ref={this.textInputRef}
            maxLength={maxLength}
            onChange={this.handleChange}
            placeholder={placeholder}
          />
        }
      </React.Fragment>
    );
  }
}