import { createSlice, PayloadAction } from "@reduxjs/toolkit";


/**
 * StationsIsps data set is a collection of ISP summaries associated
 * with a station taken from the Console SQL database.
 */
 export type StationsIsps = Record<StationId, StationIsps>;
 export type StationIsps = Record<OperationId, StationIsp>;

/**
 * ISO8601 format
 * 
 * e.g. 2019-06-29T10:36:18.404Z
 */
export type DateTimeStamp = string;
export type OperationId = string;
export type StationId = string;

export type StationIsp = {
  id: number;
  entityId: string;
  firebaseId: string;
  timeZone: string;
  initialContact: DateTimeStamp;
  lead: DateTimeStamp;
  trial: DateTimeStamp;
  qualified: DateTimeStamp;
  signup: DateTimeStamp;
  createdAt: DateTimeStamp;
  updatedAt: DateTimeStamp;
}

export enum Status {
  INITIAL = "INITIAL",
  UPDATED = "UPDATED",
  FAILED = 'FAILED',
}

type StationsIspsState = {
  status: Status;
  data: {
    stations: StationsIsps;
    ispStations: Record<OperationId, StationId>;
  }
};

const initialState: StationsIspsState = {
  status: Status.INITIAL,
  data: {
    stations: {},
    ispStations: {},
  },
};

const stationsIspsSlice = createSlice({
  name: "stationsIsps",
  initialState: initialState,
  reducers: {
    updateStationsIsps: (
      state,
      action: PayloadAction<{ stationId: string, stationIsps: StationIsp[] }>
    ) => {
      const { stationId, stationIsps } = action.payload;

      for (const stationIsp of stationIsps) {
        state.data.stations = {
          ...state.data.stations,
          [stationId]: {
            ...state.data.stations[stationId],
            [stationIsp.firebaseId]: stationIsp,
          }
        };

        state.data.ispStations = {
          ...state.data.ispStations,
          [stationIsp.firebaseId]: stationId,
        }
      }

      state.status = Status.UPDATED;
    },
    removeStationsIsps: (state, action: PayloadAction<{ stationId: string }>) => {
      const { stationId } = action.payload;

      const isps = state.data.stations[stationId];

      if (isps) {
        for (const operationId in isps) {
          delete state.data.ispStations[operationId];
        }

        delete state.data.stations[stationId];
      }
    },
    resetStationsIsps: (state) => {
      state.data = {
        stations: {},
        ispStations: {},
      };
      state.status = Status.INITIAL;
    },
  },
});

export const { updateStationsIsps, removeStationsIsps, resetStationsIsps } =
  stationsIspsSlice.actions;

export default stationsIspsSlice.reducer;
