import React from 'react';

import './OnOff.css';

export default class OnOff extends React.Component {
  constructor (props) {
    super(props);
  }

  render () {
    const { disabled, on } = this.props;

    return (
      <div className='OnOff-btn'>
        <div
          className={`off-btn btn ${on === true ? 'hover' : 'active'}`}
          style={{
            marginRight: '2px'
          }}
          onClick={disabled || on === false ? null : this.props.turnOff}>
          <p>Off</p>
        </div>

        <div
          className={`on-btn btn ${on === false ? 'hover' : 'active'}`}
          style={{
            marginLeft: '2px'
          }}
          onClick={disabled || on === true ? null : this.props.turnOn}>
          <p>On</p>
        </div>
      </div>
    )
  }
}