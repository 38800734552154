import { useState, useEffect } from "react";
import { isp } from "@packageroute/types-firebase";
import { useConsoleUser } from "@hooks/redux";
import {
  ProductTransferOwnershipService,
  consoleApiInitializer,
} from "@services";
import { mapOperationTransferDtoToDomain, OperationTransfer } from "@models";

export function useTransferOwnership(
  profile: isp.profile.Profile,
  loadLatestProfile: () => Promise<void>
) {
  const consoleUser = useConsoleUser();
  const [transferPreview, setTransferPreview] =
    useState<OperationTransfer | null>();
  const [completedTransfer, setCompletedTransfer] =
    useState<OperationTransfer | null>();
  const [newOwnerUid, setNewOwnerUid] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (newOwnerUid && consoleUser?.uid) {
      const service = new ProductTransferOwnershipService(
        consoleApiInitializer
      );

      setLoading(true);
      setErrorMessage("");

      const IS_PREVIEW = true;
      service
        .transfer(profile.ISPID, newOwnerUid, consoleUser.uid, IS_PREVIEW)
        .then(({ transfer }) => {
          setTransferPreview(mapOperationTransferDtoToDomain(transfer));
          setLoading(false);
          setErrorMessage("");
        })
        .catch((err) => {
          console.log(JSON.stringify(err));

          if (err.statusCode === 404) {
            setErrorMessage("No user record found");
          } else {
            setErrorMessage(err.message);
          }

          setTransferPreview(null);
          setLoading(false);
        });
    }
  }, [profile.ISPID, newOwnerUid, consoleUser?.uid]);

  async function initializeTransfer() {
    if (!consoleUser?.uid) {
      throw new Error("Console user uid required");
    }

    try {
      const service = new ProductTransferOwnershipService(
        consoleApiInitializer
      );

      const IS_PREVIEW = false;

      if (!newOwnerUid) {
        throw new Error(
          "New owner must be selected before transfer can be started"
        );
      } else {
        setLoading(true);
        setSubmitting(true);

        const { transfer } = await service.transfer(
          profile.ISPID,
          newOwnerUid,
          consoleUser.uid,
          IS_PREVIEW
        );

        setCompletedTransfer(mapOperationTransferDtoToDomain(transfer));

        await loadLatestProfile();
      }
    } catch (err) {
      console.error(err);

      setErrorMessage((err as Error).message);
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  }

  function setSelectedOwnerUid(ownerUid: string) {
    setErrorMessage("");

    setNewOwnerUid(ownerUid);
  }

  return {
    loading,
    submitting,
    errorMessage,
    transferPreview,
    completedTransfer,
    newOwnerUid,
    setSelectedOwnerUid,
    initializeTransfer,
  };
}
