import { RootState } from "@reducers";
import { Status } from "@reducers/subscriptions/cancellingAtPeriodEnd";

const selectCancellingAtPeriodEndSubscriptions = (state: RootState) =>
  state.subscriptions.cancellingAtPeriodEnd.data;

const selectCancellingAtPeriodEndSubscriptionsLoaded = (state: RootState) =>
  state.subscriptions.cancellingAtPeriodEnd.status === Status.UPDATED;

export { selectCancellingAtPeriodEndSubscriptions, selectCancellingAtPeriodEndSubscriptionsLoaded };
